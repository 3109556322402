/**
 * WebAppPopup domain model.
 */
import {LoyaltyPopup} from "./loyalty-popup";

export class WebAppPopup {
  public id: string;
  public data_popup: string;
  public data_popup_delay: number;
  public show_install_popup: boolean;
  public install_popup_delay: number;
  public sponsors: LoyaltyPopup[];

  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }
}
