import {Component, OnInit} from '@angular/core';
import {MdDialog, Sort} from '@angular/material';

import {AuthService, DataTransferService, Pagination, PaginationService, PlaceService, WebApp} from '../../core';

import {ActivatedRoute, Router} from '@angular/router';
import {EventLocationPopupComponent} from '../../events/event-location-popup/event-location-popup.component';
import {PopupService} from '../../popup';
import {WebappService} from '../../core/services/webapp/webapp.service';
import {Observable} from "rxjs/Observable";
import {Subscription} from "rxjs/Subscription";


/**
 * Locations page component.
 */
@Component({
  selector: 'pb-webapp-view-page',
  templateUrl: './webapp-view-page.component.html',
  styleUrls: ['./webapp-view-page.component.scss']
})
export class WebappViewPageComponent implements OnInit {
  /**
   * .ctor
   * @param {AuthService} authService Auth service.
   * @param activatedRoute
   * @param {PlaceService} placeService Place service.
   * @param webappService
   * @param dialog
   * @param router
   * @param popupService
   * @param route
   * @param dataTransferService
   * @param {PaginationService} paginationService Pagination service.
   */
  constructor(private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private placeService: PlaceService,
              private webappService: WebappService,
              private dialog: MdDialog,
              private router: Router,
              private popupService: PopupService,
              private route: ActivatedRoute,
              private dataTransferService: DataTransferService,
              private paginationService: PaginationService) {
  }

  public reference: string;
  /**
   * A list of places.
   */
    // public places: Place[] = [];

  private routeSub: Subscription;

  /**
   * A list of sorted data.
   */
  private sortedData: WebApp[] = [];

  /**
   * Current saved sort method.
   */
  private currentSort: Sort;

  /**
   * Search string.
   */
  public searchString = '';

  /**
   * Pagination object from pagination service.
   */
  public pagination: Pagination = new Pagination();

  /**
   * Paged items.
   */
  public pagedItems: WebApp[];

  /**
   * Webapps list.
   */
  public webapps: WebApp[];

  /**
   * Current merchant Id.
   */
  private merchantId: number;

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {

    this.routeSub = Observable.combineLatest(this.activatedRoute.paramMap, this.authService.onUserChanged).subscribe(async tuple => {
      const [params, user] = tuple;
      if (user) {
        this.merchantId = user.merchantId;

        // this.states = await this.utilService.getStates();

        const webAppId = params.get('id');
        if (webAppId) {
          // alert('webAppId: ' + webAppId);
        }
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params.r) {
        this.reference = params.r; // --> Name must match wanted parameter
      }
    });

    const userSubscription = this.authService.onUserChanged.subscribe(async result => {
      const user = result;
      if (user) {
        this.merchantId = user.merchantId;

        this.webapps = await this.webappService.getWebApps()
        console.log(this.webapps);

        // this.places = (await this.placeService.getPlaces(this.merchantId)).sort(this.placeService.sortingFunc);

        this.adjustPlacesData(null, '', 1);
        if (userSubscription) {
          userSubscription.unsubscribe();
        }
      }
    });
  }

  /**
   * Invokes data sorting in table considering search string, makes pagination start from 1st page.
   */
  public sortDataChanged(sort: Sort): void {
    this.adjustPlacesData(sort, this.searchString, 1);
  }

  /**
   * Invokes string search in table considering table's sorting, makes pagination start from 1st page.
   */
  public searchStringChanged(event): void {
    this.adjustPlacesData(this.currentSort, event, 1);
  }

  /**
   * Shows new list of table items considering table's sorting and search.
   */
  public setPageClicked(page: number): void {
    this.adjustPlacesData(this.currentSort, this.searchString, page);
  }

  /**
   * Combines sorting, filtering and setting pagination methods.
   */
  private adjustPlacesData(sort: Sort, searchString: string, page: number): void {
    this.sortData(sort);
    this.sortedData = this.filterItems(this.sortedData, searchString);
    this.setPage(page);
  }

  /**
   * Sorts table.
   */
  private sortData(sort: Sort): void {
    this.currentSort = sort;
    const data = this.webapps.slice();
    if (!sort || !sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'id':
          return compare(+a.id, +b.id, isAsc);
        case 'name':
          return compare(a.name, b.name, isAsc);
        default:
          return 0;
      }
    });
  }

  /**
   * Searches for string in table.
   */
  private filterItems(items, searchString: string): WebApp[] {
    this.searchString = searchString;
    return items.filter(i => i.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 || searchString === '');
  }

  /**
   * Sets page.
   */
  private setPage(page: number): void {
    /**
     * Get pagination object from service.
     */
    this.pagination = this.paginationService.getPagination(this.sortedData.length, page);

    /**
     * Get current page of items.
     */
    this.pagedItems = this.sortedData.slice(this.pagination.startIndex, this.pagination.endIndex + 1);
  }

  public editWebAppClick(place: WebApp) {
    this.dataTransferService.set({place: place, merchantId: this.merchantId});

    let queryParams = null;
    if (this.reference) {
      queryParams = {r: this.reference.trim()};
    }
    this.router.navigate(['/webapps', place.id], {queryParams: queryParams});
  }

  private async reloadPlaces() {
    this.webapps = (await this.webappService.getWebApps());
    this.adjustPlacesData(null, '', 1);
    await this.popupService.info('Places updated.');
  }

  public onAddLocation(event: any): void {
    const dialogRef = this.dialog.open(EventLocationPopupComponent, {
      width: '900px',
      /**
       * global style for popup to remove paddings
       */
      panelClass: 'campaign-publish-success',
      disableClose: true,
      data: {
        merchantId: this.merchantId,
        onSuccess: (id) => {
          this.reloadPlaces();
        }
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        /*if (result === PopupResult.YES) {
          this.router.navigateByUrl('/create_campaign');
        } else if (result === PopupResult.NO) {
          this.router.navigateByUrl('/dashboard');
        }*/
      })
      .catch((err) => err);
  }
}

function compare(a: any, b: any, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
