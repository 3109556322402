import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth/auth.service';


/**
 * Holding company guard.
 */
@Injectable()
export class HoldingCompanyGuard implements CanActivate {
  constructor(private authService: AuthService) { }

  public canActivate(): Observable<boolean> {
    return this.authService.onUserChanged.map(({ isHolding }) => isHolding);
  }
}
