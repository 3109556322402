<div class="root" [class.big]="isBig">
  <div class="header" [class.transparent]="defaultText === value">
    <label [for]="name">{{label}}</label>
    <div class="row">
      <div class="note">
        {{note}}
      </div>
      <div class="reset" *ngIf="defaultText && !disabled" (click)="enableDefaultText()">
        Use Default
      </div>
    </div>
  </div>
  <div class="body" customModel="value">
    <textarea [(ngModel)]="value"
      [maxlength]="maxlength"
      [name]="name"
      [rows]="rows"
      [readonly]="readonly"
      [placeholder]="placeholder"
      [disabled]="disabled"></textarea>
    <ng-content></ng-content>
  </div>
</div>
