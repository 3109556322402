/**
 * Pagination domain model.
 */
export class Pagination {
  /**
   * .ctor
   * @param {any} initObject Initial object of predefined field values.
   */
  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }

  public totalItems: number;
  public currentPage: number;
  public pageSize: number;
  public totalPages: number;
  public startPage: number;
  public endPage: number;
  public startIndex: number;
  public endIndex: number;
  public pages: number;
}
