import {
  Component,
  Inject,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { MdDialogRef, MD_DIALOG_DATA } from "@angular/material";
import { Category } from "../../core";
import { PopupService } from "../../popup";
import { filter, map, tap } from "rxjs/operators";

@Pipe({ name: "keys", pure: false })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    return Object.keys(value); //.map(key => value[key]);
  }
}

/**
 * Target popup component.
 */
@Component({
  selector: "pb-covid-options-picker",
  templateUrl: "./covid-options-picker.component.html",
  styleUrls: ["./covid-options-picker.component.scss"],
})
export class CovidOptionsSelectionPopupComponent implements OnInit {
  // public defaultOptions = {
  //   instruction_1:
  //     "Elevated Sanitation + Heightened Frequency of Hand Washing & Glove Changes For Every Order",
  //   instruction_2:
  //     "Required Face Masks For Team Members & Vendors + Guests as required by state or local rules",
  //   instruction_3: "Hand Sanitization Stations",
  //   instruction_4:
  //     "Contact-Free & Cashless Payment + Cashier Shield as required by state or local rules",
  //   instruction_5: "Enforced Social Distancing ",
  //   instruction_6: "Enhanced Food Safety Protocols",
  //   instruction_7:
  //     "Daily Team Member Wellness Checks + Temperature Checks where required by state or local rules",
  // };
  // // public optionKeys = this.defaultOptions.map((o) => Object.keys(o)[0]);
  // public covidOptions = this.defaultOptions;

  public enabledOptions = [];

  private readonly defaultOptions = [
    "Elevated Sanitation + Heightened Frequency of Hand Washing & Glove Changes For Every Order",
    "Required Face Masks For Team Members & Vendors + Guests as required by state or local rules",
    "Hand Sanitization Stations",
    "Contact-Free & Cashless Payment + Cashier Shield as required by state or local rules",
    "Enforced Social Distancing ",
    "Enhanced Food Safety Protocols",
    "Daily Team Member Wellness Checks + Temperature Checks where required by state or local rules",
  ];
  // public optionKeys = this.defaultOptions.map((o) => Object.keys(o)[0]);
  public covidOptions = Object.assign([], this.defaultOptions);

  // public enabledOptions = {
  //   instruction_1: true,
  //   instruction_2: true,
  //   instruction_3: true,
  //   instruction_4: true,
  //   instruction_5: true,
  //   instruction_6: true,
  //   instruction_7: true,
  // };
  public defaultExtra: string =
    "At our business we practice all safety measures required by the CDC.";
  public extraCovidOptions: string = "";
  formError: string;
  savedOption: string = "";
  public editMode: boolean = false;

  constructor(
    private mdDialogRef: MdDialogRef<CovidOptionsSelectionPopupComponent>,
    @Inject(MD_DIALOG_DATA) public data: any
  ) {
    if (data.options) {
      if (data.options.length > 0) this.covidOptions = data.options;
      // Object.keys(this.covidOptions).map((o) => {
      //   if (this.covidOptions[o].length === 0) {
      //     this.covidOptions[o] = this.defaultOptions[o];
      //     this.enabledOptions[o] = false;
      //   }
      // });
    }
    if (data.extra) this.extraCovidOptions = data.extra;
  }

  /**
   * A list of business types.
   */
  @ViewChild("form")
  public form: NgForm;

  @ViewChild("infoContainer") private infoContainer: ElementRef;

  public async ngOnInit(): Promise<void> {
    this.form.statusChanges
      .pipe(filter(() => this.form.valid))
      .subscribe(() => this.formIsValid());

    this.enabledOptions = this.covidOptions.map((o) => false);
  }

  public close() {
    this.mdDialogRef.close();
  }

  public formIsValid(): void {
    this.formError = "";
  }

  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      if (this.covidOptions.length === 0) {
        this.formError = "Add at least one information.";
        return;
      }
      this.mdDialogRef.close({
        options: this.covidOptions,
        extra: this.extraCovidOptions,
      });
    } else {
      this.formError =
        "Please fill all the above informations or remove un-wanted items.";
    }
  }

  scrollToBottom(): void {
    try {
      this.infoContainer.nativeElement.scrollTop = this.infoContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public returnOptions(): void {
    var covidProtocols = Array();
    // Object.keys(this.enabledOptions).map((o) => {
    //   if (this.enabledOptions[o]) {
    //     covidProtocols.push(this.covidOptions[o]);
    //   } else {
    //     this.covidOptions[o] = "";
    //   }
    // });

    if (covidProtocols) {
      this.mdDialogRef.close({
        options: this.covidOptions,
        extra: this.extraCovidOptions,
      });
    } else {
      this.mdDialogRef.close();
    }
  }

  public resetOptions(): void {
    this.covidOptions = Object.assign([], this.defaultOptions);
    this.extraCovidOptions = this.defaultExtra;

    this.editMode = false;
    this.savedOption = "";
    for (var i = 0; i < this.enabledOptions.length; i++)
      this.enabledOptions[i] = false;
  }

  public addOption(event): void {
    event.preventDefault();
    this.editMode = true;

    for (var i = 0; i < this.enabledOptions.length; i++)
      this.enabledOptions[i] = false;

    this.covidOptions.push("");
    this.enabledOptions.push(true);
    setTimeout(() => {
      this.scrollToBottom();
    }, 500);
  }

  public removeOption(event, i): void {
    event.preventDefault();
    this.covidOptions.splice(i, 1);
    this.enabledOptions.splice(i, 1);
    this.formError = "";
  }

  public enableEdit(event, x): void {
    event.preventDefault();
    this.editMode = true;
    for (var i = 0; i < this.enabledOptions.length; i++)
      this.enabledOptions[i] = false;
    this.savedOption = this.covidOptions[x];
    this.enabledOptions[x] = !this.enabledOptions[x];
  }

  public cancelEdit(event, x): void {
    event.preventDefault();
    this.editMode = false;
    if (this.savedOption.length === 0) {
      this.covidOptions.splice(x, 1);
      this.enabledOptions.splice(x, 1);
      return;
    }
    this.covidOptions[x] = this.savedOption;
    this.enabledOptions[x] = !this.enabledOptions[x];
    this.savedOption = "";
    this.formError = "";
  }

  public saveEdit(event, x): void {
    event.preventDefault();
    this.editMode = false;
    if (this.covidOptions[x].length === 0) {
      this.formError = "Information cannot be empty.";
      return;
    }
    this.savedOption = "";
    this.enabledOptions[x] = !this.enabledOptions[x];
  }

  public trackByFn(index: any, item: any) {
    return index;
  }
}
