import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CreateCampaignComponent } from './create-campaign.component';
import { AppRoutingModule } from '../../app-routing.module';
import { PricingModule } from '../../shared/pricing/pricing.module';

/**
 * New message module.
 */
@NgModule({
  imports: [
    SharedModule,
    AppRoutingModule,
    PricingModule
  ],
  declarations: [
    CreateCampaignComponent,
  ],
  entryComponents: [
    CreateCampaignComponent
  ],
  exports: [
    CreateCampaignComponent,
  ],
})
export class CreateCampaignModule {
}
