<form>
  <section>
    <div body>
      <div class="green-box">
        <div class="green-bar"></div>
        <img src="/assets/images/ic_success_big.png" class="success-icon" />
        <div class="title">{{ title }}</div>
        <div class="message">{{ message }}</div>
        <div class="subtitle">{{ subtitle }}</div>
        <div class="actions">
          <button pb-button md-dialog-close class="popup-button-green"
            [md-dialog-close]="popupResult.FIRST">Add Location</button>
          <div style="width: 75px;"></div>
          <button pb-button md-dialog-close class="popup-button-green"
                  [md-dialog-close]="popupResult.SECOND">Add Campaign</button>
          <div style="width: 75px;"></div>
          <button pb-button md-dialog-close class="popup-button-green"
                  [md-dialog-close]="popupResult.THIRD">Messaging Center</button>
          <div style="width: 75px;"></div>
          <button pb-button md-dialog-close class="popup-button-blue"
            [md-dialog-close]="popupResult.NO">Not Now</button>
        </div>
      </div>

      <div style="height: 10px;"></div>

      <div class="blue-box" *ngIf="incompleteProfileItems.length > 0">
        <div class="blue-bar" [innerHTML]="incompleteItemsTitle"></div>
        <div *ngFor="let item of incompleteProfileItems;">
          <div class="incomplete-item">
            <img src="/assets/images/ic_{{item.icon}}.png" class="image-type" />
            <div class="title">
              {{ item.text }}
            </div>
            <button pb-button class="item-button-green" (click)="incompleItemClicked(item.name)">Update Now</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
