import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter, HostBinding,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioComponent } from '../components/radio/radio.component';
import { isTrueProperty } from '../../../utils/index';

/**
 * @name RadioGroup
 * @description
 * A radio group is a group of [radio buttons](../RadioButton). It allows
 * a user to select at most one radio button from a set. Checking one radio
 * button that belongs to a radio group unchecks any previous checked
 * radio button within the same group.
*/
@Directive({
    selector: '[pbRadioGroup]',
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: RadioGroupDirective, multi: true }],
})
export class RadioGroupDirective implements AfterContentInit {

  @HostBinding('attr.role')
  private role = 'radiogroup';

  /**
     * @internal
     */
    private _disabled = false;

    @ContentChildren(RadioComponent)
    private btnsQuery: QueryList<RadioComponent>;

    /**
     * @hidden
     */
    private _fn: Function;

    /**
     * @hidden
     */
     private _ids: number = -1;

    /**
     * @hidden
     */
    private _init = false;

    /**
     * @hidden
     */
    public value: any;

    /**
     * @hidden
     */
    public id: number;

    /**
     * @input {boolean} If true, the user cannot interact with any of the buttons in the group.
     */
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(val: boolean) {
        this._disabled = isTrueProperty(val);
    }

    /**
     * @output {any} Emitted when the selected button has changed.
     */
    @Output()
    public changeDispatcher: EventEmitter<RadioGroupDirective> = new EventEmitter<RadioGroupDirective>();

    constructor(private changeDetector: ChangeDetectorRef) {
        this.id = ++radioGroupIds;
    }

    /**
     * @hidden
     */
    public ngAfterContentInit(): void {
        this.btnsQuery.forEach(btn => {
            this.add(btn);
        });

        this.changeDetector.detectChanges();
    }

    /**
     * @hidden
     */
    public writeValue(val: any) {
        this.value = val;

        if (this._init) {
            this.onTouched();
            this.changeDispatcher.emit(val);
        }

        this._init = true;
    }

    /**
     * @hidden
     */
    public registerOnChange(fn: Function): void {
        this._fn = fn;
        this.onChange = (val: any) => {
            // onChange used when there's an formControlName
            fn(val);
            this.value = val;
            this.onTouched();
            this.changeDispatcher.emit(val.value);
        };
    }

    /**
     * @hidden
     */
    public registerOnTouched(fn: any) { this.onTouched = fn; }

    /**
     * @hidden
     */
    public add(button: RadioComponent): string {

        // listen for radiobutton select events
        button.changeDispatcher.subscribe((val: any) => {
            if (val && val.key) {
                const control = <RadioComponent>val.control;
                this.onChange(control.key);

                // Clearing others;
                this.btnsQuery.forEach(btn => {
                    if (btn.key === control.key) {
                        return;
                    }

                    btn.isChecked = false;
                    btn.clearState();
                });
            }
            // this radiobutton has been selected

        });

        return this.id + '-' + (++this._ids);
    }

    /**
     * @hidden
     */
    public onChange(val: any) {
        // onChange used when there is not an formControlName
        this.value = val;
        this.onTouched();
        this.changeDispatcher.emit(val);
        this.changeDetector.detectChanges();
    }

    /**
     * @hidden
     */
    public onTouched() { }
}

let radioGroupIds = -1;
