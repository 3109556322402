<header pb-page-header>
  <div left>Reports
  </div>
  <div right *ngIf="false">
    <button pb-button small class="button" disabled>+(coming soon)
      <!-- Export All -->
    </button>
  </div>
</header>

<div>
  <form class="new-report-form" #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted">
    <label for="report-title">Report Title</label>
    <pb-input id="report-title" name="report-title" placeholder="Report Title" class="item"
              required [(ngModel)]="newReportTitle" pbTooltip customElementQuery=".body"></pb-input>
    <label for="start-date">Start Date</label>
    <pb-datepicker id="start-date" aria-placeholder="Start Date" pbRequiredDateValidation name="start-date"
                   [(ngModel)]="newReportStartDate" (ngModelChange)="startDateChanged($event)" [maxInputDate]="maxDate">
    </pb-datepicker>
    <label for="end-date">End Date</label>
    <pb-datepicker id="end-date" aria-placeholder="End Date" pbRequiredDateValidation name="end-date"
                   [(ngModel)]="newReportEndDate" [maxInputDate]="maxDate" [minInputDate]="newReportStartDate">
    </pb-datepicker>
    <button pb-button small class="button">New Report</button>
  </form>
</div>

<table pb-table class="reports-table">
  <thead>
  <tr>
    <th width="24px"></th>
    <th width="280px">
      <div class="row">
        Report Title
        <pb-icon name="#iconArrow" class="iconArrow"></pb-icon>
      </div>
    </th>
    <th>
      Date Range
    </th>
    <th>Status</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of pagedItems;">
    <td>
      <!--<pb-checkbox [name]="item.id" [key]="item.id" mr="0"></pb-checkbox>-->
    </td>
    <td>
      <b>{{ item.name }}</b>
    </td>
    <td>{{ item.start | date: 'MM/dd/yyyy' }} - {{ item.end | date: 'MM/dd/yyyy' }}</td>
    <td *ngIf="item.status === 'pending'">Pending</td>
    <td *ngIf="item.status === 'completed'">
      <button pb-button small class="button" id="{{item.id}}" (click)="downloadReport($event, item)">Download</button>
    </td>
    <td *ngIf="item.status === 'completed'">
      <button type="button" style="padding: 0; background: none; border: none;" (click)="deleteReport($event, item)">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </button>
    </td>
  </tr>
  </tbody>
</table>

<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>

<p style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px; border: 1px solid #CCC; border-radius: 5px; padding-top: 20px; padding-bottom: 20px">
  Upon downloading this report, you accept the responsibility for safeguarding and
  maintaining the security of this information.
</p>
