import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BillingInformationComponent } from '../shared';
import { AuthService, UtilsService, PaymentService, Rates, CardInfo } from '../core';
import { PopupService } from '../popup';

@Component({
  selector: 'pb-registration-pay',
  templateUrl: './registration-pay.component.html',
  styleUrls: ['./registration-pay.component.scss']
})
export class RegistrationPayComponent implements OnInit {

  private merchantId: number;

  constructor(
    private popupService: PopupService,
    private paymentService: PaymentService,
    private router: Router,
    private authService: AuthService,
    private utilsService: UtilsService
  ) { }

  @ViewChild(BillingInformationComponent)
  public billingInformationForm: BillingInformationComponent;

  public editMode = true;

  public card: any = {
    name: null,
    number: null,
    cvc: null,
    month: null,
    year: null
  };

  public address: any = {
    billingAddress: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  };

  public states: string[] = [];

  public rates: Rates;


  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {

    const userSub = this.authService.onUserChanged.skipWhile(user => !user)
    .subscribe(async (user): Promise<void> => {
      this.merchantId = user.merchantId;

      this.states = (await this.utilsService.getStates())
      .map(state => state.code);

      this.states.push('Other');

      this.rates = await this.utilsService.getRates();

      if (userSub) {
        userSub.unsubscribe();
      }
    });

  }

  /**
   * Submitting card.
   * @param event Event.
   */
  public submitForm(event) {
    this.billingInformationForm.submitForm(event);
  }

  /**
   * Logging out user.
   */
  public async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigateByUrl('/login');
  }

  /**
   * Refreshing page
   */
  public refreshPage(): void {
    window.location.href = '/registration-pay';
  }

  /**
   * Handling submit.
   * @param event event on submit.
   */
  public async handleSubmit(event: any): Promise<void> {
    const model = new CardInfo();
    model.nonce = event.nonce;
    model.cardholderName = event.cardholderName;
    model.billingAddress = {
      streetAddress: event.address.billingAddress,
      locality: event.address.city,
      region: event.address.state,
      country_name: event.address.country
    };

    if (model.billingAddress.region === 'Other') {
      model.billingAddress.region = event.address.customState;
    }

    const spinner = this.popupService.spinner();

    try {
      await this.paymentService.addPayment(this.merchantId, model);
    }
    finally {
      spinner.close();
    }

    await this.router.navigateByUrl('/');
  }
}
