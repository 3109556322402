<div class="container">
  <div class="header d-flex j-between align-center">
    <img src="{{ brand.logo_image }}" class="auto-half" height="80%" >
    <!--<button pb-button class="login-button hidden-mobile" (click)="scrollToForm()">
      Login
    </button>-->
  </div>
  <div style="display: flex; flex-direction: row; justify-content: space-evenly" >
    <div class="eli-text pad-mobile">
      <div style="font-size: 36px; font-weight: bold;">BE DISCOVERED.</div>
      <p>
        <b>The London Local Guide</b> is the most advanced digital discovery guide
        available. We offer you a new way to engage with London’s millions of visitors
        and put your business directly in the palm of their hands.
      </p>
      <p>
        It’s easy to get started. Simply register your business using our self-service
        platform. Then you can create Mobile Ads or Event listings in only a few
        minutes.
      </p>
    </div>
    <div class="shop-image">
    </div>
  </div>
  <div class="view-guide">
    <div class="view-guide-text">See us in action!</div>
    <button pb-button class="login-button center-mobile" (click)="viewGuide()">
      View Guide
    </button>
  </div>
  <div style="display: flex; flex-direction: row; justify-content: space-evenly; margin-top: 10px;">
    <div class="smokym-form">
      <h1 style="letter-spacing: 0.05rem;">Let's Get Started!</h1>
      <div class="l_title">Create Advertiser Account</div>
      <pb-message caption="Account Created!" class="l_message"
                  text="Your account is ready to go. Welcome to Explore LI!"
                  *ngIf="showMessage"></pb-message>
      <form #signupForm="ngForm" (ngSubmit)="onSubmitSignup(signupForm)" [class.form-submitted]="signupForm.submitted"
            [pbServerValidation]="errorEmitter">
        <input
          [(ngModel)]="email"
          class="input rounded"
          inputTrim
          type="email"
          name="email"
          required="true"
          placeholder="Enter email address"
          #input
        />
        <span class="error-block" *ngIf="signupForm.form.get('email')">
          <span class="error-text" *ngIf="signupForm.form.get('email').invalid">
            {{ signupForm.form.get('email').errors[0] }}
          </span>
        </span>

        <pb-input name="referal" [(ngModel)]="referral" placeholder="Referral Code" pbTooltip hidden
                  customElementQuery=".body">
        </pb-input>
        <pb-input name="app" [(ngModel)]="brand.id" placeholder="Brand Code" pbTooltip hidden
                  customElementQuery=".body">
        </pb-input>

        <br>

        <div style="margin: 21px 0 11px 0; text-align: center; display: none;">
          Can't access your account? <a [routerLink]="['/forgot-password']" class="forgot-password-link">Password
          reminder</a>
        </div>

        <br>

        <button pb-button type="submit" class="login-button">
          <span class="btn-text">Create Account</span>
        </button>
      </form>

      <br>

      <div class="l_title">
        Already Have An Account?
      </div>
      <form #loginForm="ngForm" (ngSubmit)="onSubmitLogin(loginForm)" [class.form-submitted]="loginForm.submitted"
            [pbServerValidation]="errorEmitter">

        <input
          [(ngModel)]="email2"
          class="input rounded"
          inputTrim
          type="email"
          name="email2"
          required="true"
          placeholder="Enter email address"
          #input
        />
        <span class="error-block f-row d-flex j-between" *ngIf="loginForm.form.get('email2')">
          <span class="error-text" *ngIf="loginForm.form.get('email2').invalid">
            {{ loginForm.form.get('email2').errors[0] }}
          </span>
        </span>

        <input
          [(ngModel)]="password"
          class="input rounded"
          inputTrim
          type="password"
          name="password"
          required="true"
          placeholder="Enter password"
          #input
        />
        <span class="error-block f-row d-flex j-between" *ngIf="loginForm.form.get('password')">
          <span class="error-text" *ngIf="loginForm.form.get('password').invalid">
            {{ loginForm.form.get('password').errors[0] }}
          </span>
        </span>
        <br>
        <span class="error-block f-row d-flex j-between" *ngIf="loginError.length > 0">
          <span class="error-text">
            {{ loginError }}
          </span>
        </span>
        <br>

        <button pb-button type="submit" class="login-button">
          <span class="btn-text">Login</span>
        </button>

        <br>
        <div class="password-reminder">
          Trouble accessing your account?<br>
          <a [routerLink]="['/forgot-password']" style="font-size: 18px; color: black">Password
          Recovery</a>
        </div>
      </form>
    </div>
    <div class="hidden-mobile" style="width: 100%; display: flex; justify-content: center;">
      <img width="340"  src="assets/images/brands/londonlocalguide_phone.png" />
    </div>
  </div>
  <div class="mt-20 d-flex" style="justify-content: center;" >
    <img style="width: 70%" class="mobile-100" src="assets/images/brands/londonlocalguide_powered_by.png"  alt=""/>
  </div>
  <div style="margin-bottom: 200px"></div>
</div>
