import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "pb-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"]
})
export class TabComponent implements OnInit {
  constructor() {}

  @Input() header = "";
  @Input() active = false;
  @Input() disabled = false;

  public ngOnInit() {}
}
