<header pb-page-header>
  <div left>Web Applications</div>
  <div right>
    <div class="buttons">
    </div>
  </div>
</header>

<table pb-table mdSort (mdSortChange)="sortDataChanged($event)" *ngIf="webapps.length > 0">
  <thead>
    <tr>
      <th md-sort-header="name">
        <div class="row">
          Name
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let app of pagedItems;">
      <td>
        <a (click)="editWebAppClick(app)">
          {{app.name}}
        </a>
      </td>
    </tr>
  </tbody>
</table>

<div class="error" *ngIf="webapps.length != 0">
  * Web Apps assigned to you are displayed here.
</div>

<div class="error" *ngIf="webapps.length === 0">
  You are not assigned to any Web Apps.
</div>

<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
