import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output, SimpleChange,
} from '@angular/core';
import { Plans } from '../../../core/models/plans';
import { PromoCode } from '../../../core/models/promo-code';
import * as moment from 'moment';

@Component({
  selector: 'pb-event-cost-breakdown',
  templateUrl: './event-cost-breakdown.component.html',
  styleUrls: ['./event-cost-breakdown.component.scss'],
})
export class EventCostBreakdownComponent implements AfterViewInit {
  @Input()
  public campaignType: string;

  @Input()
  public disabled = true;

  @Input()
  public endDate = '';

  @Input()
  public premiumStateCount = 0;

  @Input()
  public premiumEmailStateCount = 0;

  @Input()
  public customLinkCount = 0;

  @Input()
  public campaignPlan: Plans;

  @Input()
  public promoCode: PromoCode;

  @Output()
  public publishCampaign = new EventEmitter<any>();

  @Input()
  public brand = null;

  @Input()
  public currency = '$';

  @Input()
  public currencyName = 'USD';

  public payButtonText = 'Publish Event & Pay Now';

  constructor(
    private cdref: ChangeDetectorRef
  ) {

  }

  ngAfterViewInit(): void {
    if (!this.brand) {
      return;
    }
    if (this.brand.isWhiteLabel()) {
      this.payButtonText = 'Click To Publish';
    }
  }

  public ngOnChanges(changes: SimpleChange): void {
    // this.currencyName = this.currency === '£' ? 'GBP' : 'USD';
  }

  public ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  public publish() {
    this.publishCampaign.emit();
  }

  public canStepDeactivate() {
    return true;
  }

  public getPlanName(): string {
    if (!this.campaignPlan) {
      return '...';
    }
    return this.campaignPlan.name === 'free' ? 'Local' : 'Featured';
  }

  public getSubscriptionFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.subscription_rate;
    return +fee.toFixed(2);
  }

  public getPremiumStatesFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.premium_state_fee;
    return +fee.toFixed(2);
  }

  public getPremiumStatesTotalFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +(
      this.campaignPlan.premium_state_fee * this.premiumStateCount
    );
    return +fee.toFixed(2);
  }

  public getPremiumEmailStatesFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.premium_email_fee;
    return +fee.toFixed(2);
  }

  public getPremiumEmailStatesTotalFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +(
      this.campaignPlan.premium_email_fee * this.premiumEmailStateCount
    );
    return +fee.toFixed(2);
  }

  public getCustomLinkFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.custom_link_fee;
    return +fee.toFixed(2);
  }

  public getCustomLinkTotalFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +(
      this.campaignPlan.custom_link_fee * this.customLinkCount
    );
    return +fee.toFixed(2);
  }

  public getTax(): string {
    if (!this.campaignPlan) {
      return '0.00'; // Ensure two decimal places with trailing zeros as a string
    }

    const tax_rate: number = +this.campaignPlan.tax_rate;
    const fee: number = this.getSubTotalAfterPromotion();

    // Calculate the tax amount without rounding
    const taxAmount: number = (tax_rate / 100) * fee;

    // Format the tax amount to always have two decimal places with trailing zeros
    const formattedTaxAmount: string = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(taxAmount);

    return formattedTaxAmount;
  }

  public getSubTotal(): string {
    if (!this.campaignPlan) {
      return '0.00';
    }
    return (
      this.getSubscriptionFee() +
      this.getPremiumStatesTotalFee() +
      this.getCustomLinkTotalFee() +
      this.getPremiumEmailStatesTotalFee()
    ).toFixed(2);
  }

  public getSubTotalAfterPromotion(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    return +(
      +this.getSubTotal() -
      this.getPromotionalSavings()
    ).toFixed(2);
  }

  public getTotal(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    return +(
      +this.getSubTotal() +
      +this.getTax() -
      this.getPromotionalSavings()
    ).toFixed(2);
  }

  public hasPromotion(): boolean {
    return !!this.promoCode;
  }

  public getPromotionPercentage(): number {
    if (!this.promoCode) return 0;
    if (this.promoCode.id === null) return 0;
    return this.promoCode.discount;
  }

  public getPromotionalSavings(): number {
    if (!this.promoCode) return 0;
    if (this.promoCode.id === null) return 0;
    return +(
      +(this.getSubTotal()) *
      +(this.promoCode.discount / 100)
    ).toFixed(2);
  }

  public getEventEndDate(): string {
    return moment(this.endDate).format('MMMM D, YYYY');
  }

  public showEnd(): boolean {
    return !this.brand.isWhiteLabel();
  }
}
