import * as moment from 'moment';

import { CouponDto } from './dto/coupon-dto';
import { PromotionDto } from './dto/promotion-dto';
import { OperationHoursDto } from './dto/operation-hours-dto';



import { CampaignStyleVm } from '../../campaign-style/campaign-style';
import { TargetMarketingVm } from '../../target-marketing/target-marketing';
import { RedemptionOptionsVm } from '../../redemption-options/redemption-options';
import { defaultRadiusesInMile, defaultRadiusesInKiloMeter, padRight } from '../../../../utils/index';
import { Coupon, Schedule, Promotion, Subscription } from '../../../core';
import { PromoCode } from '../../../core/models/promo-code';

const FOOT_PER_MILE = 5280;
const FOOT_PER_KM = 3280;

/**
 * It is class contain methods for mapping campaign models.
 */
export class CampaignMapper {
  /**
   * Convert the model schedule to the DTO operation hours.
   * @param schedule Schedule from target-marketing model.
   */
  private operationHoursMapper(schedule: Schedule): OperationHoursDto {
    const operationHours: OperationHoursDto = {
      monday_start: null,
      monday_end: null,
      tuesday_start: null,
      tuesday_end: null,
      wednesday_start: null,
      wednesday_end: null,
      thursday_start: null,
      thursday_end: null,
      friday_start: null,
      friday_end: null,
      saturday_start: null,
      saturday_end: null,
      sunday_start: null,
      sunday_end: null,
    };

    for (const day in schedule) {
      if (schedule.hasOwnProperty(day)) {
        const startTime = `${day}_start`;
        const endTime = `${day}_end`;

        operationHours[startTime] = schedule[day].start;
        operationHours[endTime] = schedule[day].end;
      }
    }

    return operationHours;
  }

  /**
   * Convert the coupon model to the DTO.
   * @param coupon Coupon model.
   * @param merchant Merchant model.
   */
  public convertCouponToDto(coupon: Coupon, merchant): CouponDto {
    const { promoCode, campaignPlan, subscription, campaignStyle, targetMarketing, redemptionOptions } = coupon;

    const gender = campaignStyle.gender !== 'B' ? campaignStyle.gender : null;
    const keywords = targetMarketing.keywords.split(',');
    // const radius = Math.floor(targetMarketing.radius.key * (merchant.address.country === 'Canada' ? FOOT_PER_KM : FOOT_PER_MILE));
    const radius = Math.floor(campaignStyle.radius.key * FOOT_PER_MILE);
    const inStoreInstruction = redemptionOptions.storeCouponInstructions;
    const contactPhone = targetMarketing.contactInformationPhone;
    const contactText = targetMarketing.contactInformationText;
    const contactEmail = targetMarketing.contactInformationEmail;
    const isRedeemable = redemptionOptions.isRedeemable;
    const onlineInstruction = redemptionOptions.onlineCouponInstructions;
    const terms = targetMarketing.termsAndConditionsText;
    const redemptionLimit = redemptionOptions.storeCouponLimit === 'custom'
      ? +redemptionOptions.storeCouponLimitCount
      : 0;
    const operationHours = this.operationHoursMapper(targetMarketing.schedule);
    const age = campaignStyle.ageRange === 'custom'
      ? {
        start: campaignStyle.customAgeRange.from,
        end: campaignStyle.customAgeRange.to
      } : {
        start: +campaignStyle.ageRange,
        end: null
      };

    const shopOptionsList = redemptionOptions.shopOptions;
    let shopOptions: { url_type: string, url: string, title: string }[] = [];
    shopOptionsList.map(opt => {
      let link: string = opt.url;
      if(!link) return;
      link = (link.indexOf('://') === -1) ? 'https://' + link : link;
      const title = redemptionOptions.shopOptionsList.find(s => s.url_type === opt.url_type);
      if(!title) return;
      shopOptions.push(Object.assign(opt, { url: link, title: title.title }));
    });

    let premium_email_states = <any[]>campaignStyle.premiumEmailStates.map(s => {
      return {'state': s}
    });
    if(premium_email_states.length === 0) {
      premium_email_states = <any[]>redemptionOptions.premiumEmailStates.map(s => {
        return {'state': s}
      });
    }

    const campaignDto: CouponDto = {
      categories: targetMarketing.interest,
      id: null,
      name: campaignStyle.campaignMessage,
      promo_code: promoCode,
      campaign_plan: campaignPlan,
      subscription: [subscription],
      campaign_title: campaignStyle.imageLabel,
      in_store: redemptionOptions.storeCouponCode,
      in_store_instruction: inStoreInstruction,
      online: redemptionOptions.onlineCouponCode,
      online_instruction: onlineInstruction,
      contact_phone: contactPhone,
      country_calling_code_phone: targetMarketing.contactInformationPhoneCode,
      country_calling_code_text: targetMarketing.contactInformationTextCode,
      contact_text: contactText,
      contact_email: contactEmail,
      deal_description: campaignStyle.dealDescription,
      rules: terms,
      terms: terms,
      places: campaignStyle.locations,
      likes: 0,
      cilcks: 0,
      impressions: 0,
      redemptions: 0,
      budget: 0,
      start: campaignStyle.dateRange.start,
      end: campaignStyle.dateRange.end,
      merchantId: merchant.id,
      keywords: keywords,
      operation_hours: operationHours,
      target_audience: {
        age_start: age.start,
        age_end: age.end,
        radius: radius,
        gender: gender,
        interest: targetMarketing.interest || merchant.categoryIds
      },
      image: campaignStyle.couponImage ? campaignStyle.couponImage : { image: null, id: -1, logo: null },
      video: campaignStyle.couponVideo ? campaignStyle.couponVideo : { video: null, id: -1, video_thumb: null },
      is_redeemable: isRedeemable,
      is_expired: false,
      in_store_redeem_limit: redemptionLimit,
      in_store_redeem_left: 0,
      online_redeem_limit: 0,
      online_redeem_left: 0,
      old_price: campaignStyle.priceLabelWas,
      new_price: campaignStyle.priceLabelIs,
      shop_options: shopOptions,
      in_store_image: '',
      in_store_type: redemptionOptions.inStoreCouponType,
      premium_states: <any[]>campaignStyle.premiumStates.map(s => {
        return {'state': s}
      }),
      premium_email_states: premium_email_states
    };

    return campaignDto;
  }

  /**
   * Convert the promotion model to the DTO.
   * @param promotion Promotion model.
   * @param merchant Merchant model.
   */
  public convertPromotionToDto(promotion: Promotion, merchant): PromotionDto {
    const { promoCode, campaignStyle, targetMarketing, redemptionOptions } = promotion;

    const keywords = targetMarketing.keywords.split(',');
    const operationHours = this.operationHoursMapper(targetMarketing.schedule);

    const promotionDto: PromotionDto = {
      categories: targetMarketing.interest,
      id: null,
      image: null,
      // logo: null,
      video: null,
      name: campaignStyle.campaignMessage,
      deal_description: campaignStyle.dealDescription,
      campaign_title: campaignStyle.imageLabel,
      start: campaignStyle.dateRange.start,
      end: campaignStyle.dateRange.end,
      isActive: true,
      merchantId: merchant.id,
      keywords: keywords,
      operation_hours: operationHours,
      places: campaignStyle.locations,
      isCompleted: false,
      target_audience: {
        age_start: null,
        age_end: null,
        radius: null,
        gender: null,
        interest: targetMarketing.interest || merchant.categoryIds
      },
      contact_email: targetMarketing.contactInformationEmail,
      contact_phone: targetMarketing.contactInformationPhone,
      contact_text: targetMarketing.contactInformationText,
      country_calling_code_phone: targetMarketing.contactInformationPhoneCode,
      country_calling_code_text: targetMarketing.contactInformationTextCode,
      campaign_plan: merchant.campaignPlan,
      subscription: merchant.subscription,
      promo_code: promoCode,
      premium_states: campaignStyle.premiumStates,
      premium_email_states: redemptionOptions.premiumEmailStates,
      terms: targetMarketing.termsAndConditionsText
    };

    return promotionDto;
  }

  /**
   * Convert DTO to the model.
   * @param dto Coupon DTO
   */
  public convertCouponDtoToModel(dto: CouponDto): Coupon {
    const coupon = {
      promoCode: this.getPromoCode(dto),
      campaignPlan: this.getCampaignPlan(dto),
      subscription: this.getSubscription(dto),
      campaignStyle: this.getCampaignStyle(dto),
      targetMarketing: this.getTargetMarketing(dto),
      redemptionOptions: this.getRedemptionOptions(dto)
    } as Coupon;

    return coupon;
  }

  public convertPromotionDtoToModel(dto: PromotionDto): Promotion {
    const redemptionOptions = new RedemptionOptionsVm();
    redemptionOptions.feature = 'promotion';
    return new Promotion({
      // campaignPlan: this.getCampaignPlan(dto),
      campaignStyle: this.getCampaignStyle(dto),
      targetMarketing: this.getTargetMarketing(dto),
      redemptionOptions: redemptionOptions
    });
  }

  /**
   * Convert DTO to the Campaign style model.
   * @param dto Coupon DTO.
   */
  private getCampaignPlan(dto: CouponDto | PromotionDto): number {
    return dto.campaign_plan;
  }

  private getSubscription(dto: CouponDto | PromotionDto): Subscription {
    return dto.subscription[0];
  }

  private getPromoCode(dto: CouponDto | PromotionDto): number {
    return dto.promo_code;
  }

  /**
   * Convert DTO to the Campaign style model.
   * @param dto Coupon DTO.
   */
  private getCampaignStyle(dto: CouponDto | PromotionDto): CampaignStyleVm {
    const model = new CampaignStyleVm();

    model.id = dto.id;
    model.campaignMessage = dto.name;
    model.imageLabel = dto.campaign_title;
    model.isCustomImage = dto.campaign_title.length === 0;
    model.couponImage = dto.image ? dto.image : { image: null, id: -1, logo: null };

    if (dto.old_price != null) {
      model.isShowPrice = true;
      model.priceLabelWas = dto.old_price;
      model.priceLabelIs = dto.new_price;
    }

    if (dto.target_audience.radius !== null) {
      const dtoRadiusInFeet = +dto.target_audience.radius;
      const { radius, radiusValue } = this.getRadius(dtoRadiusInFeet);

      model.customAgeRange.from = dto.target_audience.age_start;
      model.customAgeRange.to = dto.target_audience.age_end;
      model.ageRange = dto.target_audience.age_end ? 'custom' : `${dto.target_audience.age_start}`;
      model.gender = dto.target_audience.gender || 'B'; // If gender is 'null' it's mean 'Both'
      model.radius = {
        key: radius.miles,
        value: radiusValue,
      };
    }

    model.dateRange = {
      start: moment(dto.start).toDate(),
      end: moment(dto.end).toDate()
    };

    model.couponVideo = dto.video ? dto.video : { video: null, id: -1, video_thumb: null };
    model.dealDescription = dto.deal_description;
    dto.premium_states.map(s => model.premiumStates.push(s['state']));

    model.locations = dto.places;
    return model;
  }

  private getRadius(dtoRadiusInFeet) {
    const limitValueForOutputInFeet = 800;

    const radius_in_miles = defaultRadiusesInMile().find(defaultRadius => defaultRadius.feets === dtoRadiusInFeet);
    /*if(!radius_in_miles) {
      const radius_in_km = defaultRadiusesInKiloMeter().find(defaultRadius => defaultRadius.feets === dtoRadiusInFeet);
      const radiusValue = dtoRadiusInFeet > limitValueForOutputInFeet
        ? `${radius_in_km.miles} KM`
        : `${radius_in_km.feets} feet`;
      return { radius: radius_in_km, radiusValue: radiusValue };
    }*/

    const feetsPadded = padRight(radius_in_miles.pad, `${radius_in_miles.feets} feet`, `&nbsp;`);
    const milesPadded = padRight(radius_in_miles.pad, `${radius_in_miles.miles} miles`, `&nbsp;`);

    const radiusValue = dtoRadiusInFeet > limitValueForOutputInFeet
        ? `${milesPadded} / ${radius_in_miles.kms} km`
        : `${feetsPadded} / ${radius_in_miles.kms} km`;
    return { radius: radius_in_miles, radiusValue: radiusValue };
  }

  /**
   * Convert DTO to the Target marketing model.
   * @param dto Coupon DTO.
   */
  private getTargetMarketing(dto: CouponDto | PromotionDto): TargetMarketingVm {
    const model = new TargetMarketingVm();
    model.keywords = dto.keywords.join();
    model.interest = dto.categories;

    model.isDefineHours = this.getScheduleType(dto.operation_hours);

    model.schedule = {
      monday: {
        start: dto.operation_hours.monday_start,
        end: dto.operation_hours.monday_end
      },
      tuesday: {
        start: dto.operation_hours.tuesday_start,
        end: dto.operation_hours.tuesday_end
      },
      wednesday: {
        start: dto.operation_hours.wednesday_start,
        end: dto.operation_hours.wednesday_end
      },
      thursday: {
        start: dto.operation_hours.thursday_start,
        end: dto.operation_hours.thursday_end
      },
      friday: {
        start: dto.operation_hours.friday_start,
        end: dto.operation_hours.friday_end
      },
      saturday: {
        start: dto.operation_hours.saturday_start,
        end: dto.operation_hours.saturday_end
      },
      sunday: {
        start: dto.operation_hours.sunday_start,
        end: dto.operation_hours.sunday_end
      },
    };
    model.contactInformationPhone = dto.contact_phone;
    model.contactInformationPhoneCode = dto.country_calling_code_phone;
    model.contactInformationText = dto.contact_text;
    model.contactInformationTextCode = dto.country_calling_code_text;
    model.contactInformationEmail = dto.contact_email;
    model.termsAndConditionsText = dto.terms;

    return model;
  }

  private getScheduleType(operationHours: OperationHoursDto): string {
    const isDefaultStartTime = Object.keys(operationHours)
      .filter(k => k.search(/_start/i) !== -1)
      .every(k => operationHours[k] === '00:00:00');

    const isDefaultEndTime = Object.keys(operationHours)
      .filter(k => k.search(/_end/i) !== -1)
      .every(k => operationHours[k] === '23:59:00');

    if (isDefaultStartTime && isDefaultEndTime) {
      return 'all';
    }

    return 'define';
  }

  /**
   * Convert DTO to the Redemption options model.
   * @param dto Coupon DTO.
   */
  private getRedemptionOptions(dto: CouponDto): RedemptionOptionsVm {
    const model = new RedemptionOptionsVm();

    const couponLimit = dto.in_store_redeem_limit === 0
      ? 'unlimited'
      : 'custom';

    model.feature = 'coupon';
    model.isRedeemable = dto.is_redeemable;
    model.storeCouponCode = dto.in_store;
    model.onlineCouponCode = dto.online;
    model.storeCouponLimit = couponLimit;
    model.storeCouponLimitCount = dto.in_store_redeem_limit;
    model.storeCouponInstructions = dto.in_store_instruction;
    model.onlineCouponInstructions = dto.online_instruction;
    model.inStoreCouponType = <"text" | "qrcode" | "barcode">dto.in_store_type;

    model.inStoreImage = {
      base64: dto.in_store_image,
      blob: null,
      name: 'In Store Code'
    };

    model.shopOptions = dto.shop_options;
    while(model.shopOptions.length < 2) {
      model.shopOptions.push({ url_type: '', url: '', title: '' });
    }
    // dto.shop_options.map(opt => {
    //   model.enabledShopOptions[opt.url_type] = true;
    //   model.selectedShopOptions[opt.url_type] = opt.url;
    // });

    dto.premium_email_states.map(s => model.premiumEmailStates.push(s['state']));

    return model;
  }
}
