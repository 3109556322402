import { Address } from './address';
import { Schedule } from './schedule';
import { CovidOptions } from './covid-options';
import {MerchantApp} from './merchant-app';
import {WebApp} from "./web-app";

/**
 * Merchant domain model.
 */
export class Merchant {
  /**
   * .ctor
   * @param {any} initObject Initial object of predefined field values.
   */
  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }

  /**
   * Merchant identifier.
   */
  public id: number;

  /**
   * Merchant Legal name of business.
   */
  public name: string;

  /**
   * Mercahnt "doing business as" name of business.
   */
  public dbaName: string;

  /**
   * Mercahnt business type aka main category.
   */
  public businessType: string;

  /**
   * Merchant address.
   */
  public address: Address = new Address();

  /**
   * Covid Options.
   */
  public covidOptions: CovidOptions = new CovidOptions();

  /**
   * Primary contact (First name and last name).
   */
  public contact: string;
  public country_calling_code: string;

  /**
   * Name of contact.
   */
  public contactName: string;

  /**
   * Contact email.
   */
  public email: string;

  /**
   * Contact phone.
   */
  public phone: string;

  /**
   * About us description
   */
  public description: string;

  public url: string;

  public reviews_url: string;

  /**
   * Social network urls.
   */
  public socialUrls: { [key: string]: string };

  /**
   * Business logo url.
   */
  public logo: string;

  /**
   * Background image url.
   */
  public logoBackground: string;

  public images: any[];

  /**
   * A list of category identifiers.
   */
  public categoryIds: string[];

  /**
   * Hours of operations.
   */
  public operationHours = new Schedule();

  /**
   * Search keywords.
   */
  public keywords: string;

  /**
   * Free form additional information.
   */
  public freeForm: string;

  /**
   * Flag what a merchant is register.
   */
  public creationCompleted: boolean;

  /**
   * Payment due for merchant.
   */
  public paymentDue: Date;

  /**
   * Invoice amount for merchant.
   */
  public invoiceAmount: number;

  /**
   * Secondary contact name.
   */
  public secondaryContact: string;

  /**
   * Title of secondary contact.
   */
  public secondaryContactName: string;

  /**
   * Secondary contact email.
   */
  public secondaryEmail: string;

  /**
   * Secondary contact phone.
   */
  public secondaryPhone: string;

  /**
   * Flag for indication of force payment, related to billing information.
   */
  public forcePayment: boolean;

  /**
   * Personal id of merchant.
   */
  public personalId: string;

  /**
   * Referral code of merchant.
   */
  public referral: string;

  /**
   * Active or inactive user.
   */
  public isActive: boolean;

  public isAutoHeartEnabled: boolean;
  public isNonAutoHeartDashboard: boolean;

  /**
   * Holding or division.
   */
  public isHolding = false;

  /**
   * List of child companies..
   */
  public divisions: Division[];

  public app: MerchantApp = new MerchantApp();

  public webapps: WebApp[];

  /**
   * Parent company name.
   */
  public parentName: string;

  public isAutoHeartingEnabled(): boolean {
    return (this.categoryIds.includes('4d4b7105d754a06379d81259') || this.isAutoHeartEnabled === true);
  }

  public isAutoHeartingDashboard(): boolean {
    const autoHeartEnable = this.categoryIds.includes('4d4b7105d754a06379d81259') || this.isAutoHeartEnabled === true;
    return autoHeartEnable && !this.isNonAutoHeartDashboard;
  }

  public isHotel(): boolean {
    return this.categoryIds.includes('4d4b7105d754a06379d81259');
  }
}

export class Division {
  public id: number;
  public logo: string;
  public personalId: string;
  public dbaName: string;
  public name: string;

  /**
   * Division id comparer.
   */
  public static idComparer(a: Division, b: Division): number {
    if (a.id === b.id) {
      return 0;
    }
    if (a.id < b.id) {
      return -1;
    }
    return 1;
  }
}
