import { Pagination } from '../../models/pagination';

/**
 * Pagination service.
 */
export class PaginationService {
  public getPagination(totalItems: number, currentPage: number = 1, pageSize: number = 10): Pagination {
    /**
     * Calculates total pages.
     */
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    // tslint:disable-next-line:no-magic-numbers
    if (totalPages <= 10) {
      /**
       * If less than 10 total pages, then we show all
       */
      startPage = 1;
      endPage = totalPages;
    } else {
      /**
       * If more than 10 total pages, then calculate start and end pages.
       */
      // tslint:disable-next-line:no-magic-numbers
      if (currentPage <= 6) {
        // tslint:disable-next-line:no-magic-numbers
        startPage = 1;
        // tslint:disable-next-line:no-magic-numbers
        endPage = 10;
        // tslint:disable-next-line:no-magic-numbers
      } else if (currentPage + 4 >= totalPages) {
        // tslint:disable-next-line:no-magic-numbers
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        // tslint:disable-next-line:no-magic-numbers
        startPage = currentPage - 5;
        // tslint:disable-next-line:no-magic-numbers
        endPage = currentPage + 4;
      }
    }

    /**
     * Calculate start and end item indexes.
     */
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    /**
     * Create an array of pages to ng-repeat in the pager control.
     */
    const pages = Array.apply(null, {length: endPage - startPage  + 1}).map((r, i) => i + startPage);

    /**
     * Return object with all pagination properties required by the view
     */
    return new Pagination({
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    });
  }
}
