/**
 * State domain model.
 */
export class State {
  /**
   * State identifier.
   */
  public id: number;

  /**
   * State code.
   */
  public code: string;

  /**
   * State name.
   */
  public name: string;
}
