import {Subscription} from 'rxjs/Rx';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MdDialog} from '@angular/material';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';

import {
  AuthService,
  CategoriesService,
  DataTransferService,
  MerchantsCoupon,
  MerchantService,
  MessagesService,
  NewMessage,
  ServerError,
  UtilsService,
  WebappService,
} from '../../core';
import {PopupService} from '../../popup';
import {ImagesService} from "../../campaign/services/campaign-service/images.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {combineLatest} from "rxjs/observable/combineLatest";


declare var tinymce: any;

/**
 * New message component.
 */
@Component({
  selector: 'pb-add-data-popup-page',
  templateUrl: './add-data-popup-page.component.html',
  styleUrls: ['./add-data-popup-page.component.scss']
})
export class AddDataPopupPageComponent implements OnInit, AfterViewInit, OnDestroy {
  private routePreviousUrl: Subscription;
  public popupForm: FormGroup;
  logoFile: File | null = null;
  logoPreview: string | ArrayBuffer = '';
  destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private categoriesService: CategoriesService,
    private webappService: WebappService,
    private dialog: MdDialog,
    private popupService: PopupService,
    private merchantService: MerchantService,
    private messagesService: MessagesService,
    private authService: AuthService,
    private dataTransferService: DataTransferService,
    private router: Router,
    private route: ActivatedRoute,
    private imageService: ImagesService,
    private utilService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
    this.routePreviousUrl = this.router.events
      .filter(e => e instanceof NavigationEnd)
      .subscribe((v: NavigationEnd) => {
        localStorage['previousUrl'] = v.url;
      });
  }

  /**
   * A merchant id.
   */
  private merchantId: number;

  /**
   * A message to be sent.
   */
  public message: NewMessage = new NewMessage();

  public coupon: MerchantsCoupon;
  private routeSub: Subscription;

  private webAppId: string;
  public previewContent: SafeHtml;

  /**
   * @inheritdoc
   */

  public async ngOnInit(): Promise<void> {
    this.popupForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      logo: [''],
      title: [''],
      subtitle: [''],
      submit_button_text: ['', Validators.required],
      ask_phone: [false],
      phone_placeholder: [{ value: '', disabled: true }],
      ask_zip: [false],
      zip_placeholder: [{ value: '', disabled: true }],
      ask_email: [false],
      email_placeholder: [{ value: '', disabled: true }],
      ask_type: [false],
      user_type_question: [{ value: '', disabled: true }],
      i_agree: [false],
      i_agree_text: [{ value: '', disabled: true }],
      receive_newsletter: [false],
      receive_newsletter_text: [{ value: '', disabled: true }],
      is_enabled: [true]
    }, { validator: this.atLeastOneFieldRequired('logo', 'title') }); // Apply custom validator


    this.subscribeToCheckboxChanges();

    this.routeSub = combineLatest([
      this.activatedRoute.paramMap,
      this.authService.onUserChanged
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(async ([params, user]) => {
      if (user) {
        this.merchantId = user.merchantId;
        const spinner = this.popupService.spinner();
        this.webAppId = params.get('id');
        const popupId = params.get('popupId');

        if (popupId !== 'new') {
          const t_previewContent = await this.webappService.getDataPopupPreviewHTML(this.webAppId, popupId);
          this.previewContent = this.sanitizer.bypassSecurityTrustHtml(t_previewContent);
          this.webappService.getDataPopup(this.webAppId, popupId).then(data => {
            if (data.logo) {
              this.logoPreview = data.logo;
            } else {
              this.logoPreview = null;
            }
            this.popupForm.patchValue(data);
            this.initializeTinyMCE();
            spinner.close();
          }, error => {
            spinner.close();
          });
        } else {
          spinner.close();
        }
      }
    });
  }

  private atLeastOneFieldRequired(logoField: string, titleField: string): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: any } | null => {
      const logo = formGroup.get(logoField).value;
      const title = formGroup.get(titleField).value;
      return logo || title ? null : { atLeastOneRequired: true };
    };
  }

  private initializeTinyMCE(): void {
    setTimeout(() => {
      try {
        tinymce.init({
          height: "480",
          selector: '#title, #subtitle, #i_agree_text, #receive_newsletter_text',
          plugins: 'link paste table',
          toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link',
          setup: (editor) => {
            editor.on('change', () => {
              const editorId = editor.id;
              const content = editor.getContent();

              if (editorId === "title") {
                if (content.length > 0) {
                  this.popupForm.get("logo").setValidators([])
                } else {
                  this.popupForm.get("logo").setValidators([Validators.required])
                }
              }
              this.popupForm.patchValue({
                [editorId]: content
              });
            });
          }
        });
      } catch (e) {
        console.error('Error initializing TinyMCE', e);
      }
    }, 300);
  }

  public ngAfterViewInit(): void {
    this.initializeTinyMCE();
  }

  subscribeToCheckboxChanges(): void {
    this.popupForm.get('i_agree').valueChanges.subscribe(checked => {
      this.toggleField('i_agree_text', checked);
    });

    this.popupForm.get('receive_newsletter').valueChanges.subscribe(checked => {
      this.toggleField('receive_newsletter_text', checked);
    });

    this.popupForm.get('ask_phone').valueChanges.subscribe(checked => {
      this.toggleField('phone_placeholder', checked);
    });

    this.popupForm.get('ask_zip').valueChanges.subscribe(checked => {
      this.toggleField('zip_placeholder', checked);
    });

    this.popupForm.get('ask_email').valueChanges.subscribe(checked => {
      this.toggleField('email_placeholder', checked);
    });

    this.popupForm.get('ask_type').valueChanges.subscribe(checked => {
      this.toggleField('user_type_question', checked);
    });
  }

  toggleField(fieldName: string, enable: boolean): void {
    const field = this.popupForm.get(fieldName);
    if (enable) {
      field.enable();
    } else {
      field.disable();
    }
  }

  onPreview() {
  }

  /**
   * Handles on submit form event.
   */
  onSubmit($event: any): void {
    if (this.popupForm.valid) {
      // const spinner = this.popupService.spinner();
      this.webappService.updateDataPopup(this.webAppId, this.route.snapshot.params['popupId'], this.popupForm.value, this.logoFile)
        .then((res: any) => {
          const newId = res.id;
          this.webappService.getDataPopupPreviewHTML(this.webAppId, newId).then((t_previewContent) => {
            this.previewContent = this.sanitizer.bypassSecurityTrustHtml(t_previewContent);
          });
          // spinner.close();
          if (this.route.snapshot.params['popupId'] === 'new') {
            this.router.navigate([`/webapps/${this.webAppId}/data-popups/${res.id}`]);
          }
          if ($event.target.className === 'button') {
            // this.popupService.info('Data popup updated.');
            window.scrollTo(0, 0);
            alert('Data popup updated.');
          } else {
            this.router.navigate([`/webapps/${this.webAppId}/configure`]);
          }
        })
        .catch((error: ServerError) => {
          // spinner.close();
          this.handleServerError(error);
        });
    } else {
      console.log('Form is invalid', this.popupForm);
      this.popupService.info('Please fill in all required fields.')
      this.markFormGroupTouched(this.popupForm);
    }
  }

  markFormGroupTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control) {
        control.markAsTouched();
        control.markAsDirty();
        if (control instanceof FormGroup) {
          this.markFormGroupTouched(control);
        } else if (key === 'title' && control.value.length === 0) {
          control.setErrors({ required: true });
        } else if (key === 'subtitle' && control.value.length === 0) {
          control.setErrors({ required: true });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    tinymce.remove();

    this.destroy$.next();
    this.destroy$.complete();
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  public onFileChange(event: any, field: string) {
    if (event.target.files.length > 0) {
      if (field === 'logo') {
        this.popupForm.get("title").clearValidators()

        this.logoFile = event.target.files[0];
        this.popupForm.patchValue({
          logo: this.logoFile
        });
        this.popupForm.get('logo').updateValueAndValidity();

        const reader = new FileReader();
        reader.onload = () => {
          this.logoPreview = reader.result;
        };
        reader.readAsDataURL(this.logoFile);
      }
    } else {
      this.popupForm.get("title").setValidators([Validators.required])
    }
  }


  public onTitleChange($event: any) {
    console.warn($event)
  }

  /**
   * Invalidating controls on form.
   * @param serverError Server error returned by remote.
   */
  private handleServerError(serverError: ServerError) {
    const map = {
      title: 'title',
      message: 'message'
    };

    // this.errorHandlerService.invalidateForm(this.form, map, serverError);
  }
}
