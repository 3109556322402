/**
 * Object which contain Subscription information.
 */
export class Subscription {
  public id: number;
  public subscription_type: string;
  public subscription_value: string;
  public link_count: number;
  public link_rate: number;
  public state_rate: number;
  public state_count: number;
}

export const SUBSCRIPTION_PLAN: string = 'free';
export const SUBSCRIPTION_STATE: string = 'state';