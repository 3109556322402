import { RouterModule } from '@angular/router';
import { DragNDropDirective } from './components/drag-and-drop-area/draggable/drag-n-drop-directive';
import { ImageCropperModule } from 'ng2-img-cropper';
import { NgSelectizeModule } from 'ng-selectize';
import { CheckboxGroupDirective } from './directives/checkbox-group-directive';
import { RadioGroupDirective } from './directives/radio-group-directive';
import { CheckboxesValidationDirective } from './directives/checkboxes-validation.directive';
import { TimeRangeValidationDirective } from './directives/time-range-validation.directive';
import { DateRangeValidationDirective } from './directives/date-range-validation.directive';
import { InputTrimDirective } from './directives/input-trim.directive';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AgeRangeRequiredValidatorDirective } from './components/age-range/validators/age-range.validator';

import {
  MdDatepickerModule,
  MdNativeDateModule,
  MdDialogModule,
  MdTooltipModule,
  MdProgressSpinnerModule,
  MdSortModule,
} from '@angular/material';

import { TextMaskModule } from 'angular2-text-mask';
import { CustomFormsModule } from '@floydspace/ngx-validation';
import { InlineHrefDirective } from 'ng-inline-href';

import {
  SvgSpriteComponent,
  IconComponent,
  SectionComponent,
  SectionRequiredComponent,
  InputComponent,
  SelectComponent,
  CheckboxComponent,
  ButtonComponent,
  PageHeaderComponent,
  TextareaComponent,
  BrowseFileComponent,
  ToggleSelectorComponent,
  CheckboxGroupComponent,
  BrowseVideoFileComponent,
  DragAndDropAreaComponent,
  ScheduleComponent,
  TimepickerComponent,
  BillingInformationComponent,
  RadioComponent,
  StepComponent,
  TabComponent,
  TabsetComponent,
  AgeRangeComponent,
  DatepickerComponent,
  DateRangeComponent,
  FileNameComponent,
  BreadcrumbsComponent,
  ThumbnailComponent,
  ActivityComponent,
  StatItemComponent,
  MessageComponent,
  ImageLibraryComponent,
  ActivityInfoComponent,
  ActivityStatComponent,
  SearchComponent,
  PaginatorComponent,
  ComboboxComponent,
  CropperPopupComponent,
  BetterCropperPopupComponent,
  SelectizeDropdownComponent,
  InfoItemComponent,
  StatsComponent,
  ActivitiesPopupComponent,
  StreetNumberPopupComponent,
  ErrorMessageComponent,
  KeywordsComponent,
  WorkScheduleComponent,
  SwitchComponent,
  TermsOfUseComponent,
} from './components/public_api';

import {
  ServerValidationDirective,
  TooltipDirective,
  LimitDirective,
  NumberRangeValidationDirective,
  TextFormattingValidationDirective,
} from './directives/public_api';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { SimpleTooltipComponent } from './components/simple-tooltip/simple-tooltip.component';

import { AddressSelectizeComponent } from './components/address-selectize/address-selectize.component';

/**
 * Shared module.
 */
@NgModule({
  imports: [
    HttpModule,
    FormsModule,
    CustomFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    TextMaskModule,
    MdDatepickerModule,
    MdNativeDateModule,
    MdDialogModule,
    MdTooltipModule,
    MdProgressSpinnerModule,
    NgSelectizeModule,
    MdSortModule,
    RouterModule,
    ImageCropperModule,
  ],
  declarations: [
    SvgSpriteComponent,
    IconComponent,
    SectionComponent,
    SectionRequiredComponent,
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    ButtonComponent,
    PageHeaderComponent,
    TextareaComponent,
    BrowseFileComponent,
    ToggleSelectorComponent,
    CheckboxGroupComponent,
    BrowseVideoFileComponent,
    DragAndDropAreaComponent,
    ScheduleComponent,
    TimepickerComponent,
    BillingInformationComponent,
    RadioComponent,
    StepComponent,
    TabComponent,
    TabsetComponent,
    InlineHrefDirective,
    AgeRangeComponent,
    DatepickerComponent,
    DateRangeComponent,
    FileNameComponent,
    BreadcrumbsComponent,
    ThumbnailComponent,
    ActivityComponent,
    StatItemComponent,
    MessageComponent,
    ImageLibraryComponent,
    ActivityInfoComponent,
    ActivityStatComponent,
    SearchComponent,
    PaginatorComponent,
    CropperPopupComponent,
    BetterCropperPopupComponent,
    ServerValidationDirective,
    TooltipDirective,
    LimitDirective,
    ComboboxComponent,
    DateRangeValidationDirective,
    TimeRangeValidationDirective,
    CheckboxesValidationDirective,
    RadioGroupDirective,
    CheckboxGroupDirective,
    SwitchComponent,
    DragNDropDirective,
    SelectizeDropdownComponent,
    InfoItemComponent,
    StatsComponent,
    AgeRangeRequiredValidatorDirective,
    NumberRangeValidationDirective,
    TextFormattingValidationDirective,
    InputTrimDirective,
    ActivitiesPopupComponent,
    StreetNumberPopupComponent,
    ErrorMessageComponent,
    KeywordsComponent,
    WorkScheduleComponent,
    InfoTooltipComponent,
    SimpleTooltipComponent,
    TermsOfUseComponent,
    InfoTooltipComponent,
    AddressSelectizeComponent,
  ],
  entryComponents: [CropperPopupComponent, BetterCropperPopupComponent],
  exports: [
    HttpModule,
    FormsModule,
    CustomFormsModule,
    CommonModule,
    TextMaskModule,
    MdDatepickerModule,
    MdTooltipModule,
    MdDialogModule,
    MdSortModule,
    MdProgressSpinnerModule,
    SvgSpriteComponent,
    IconComponent,
    SectionComponent,
    SectionRequiredComponent,
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    ButtonComponent,
    PageHeaderComponent,
    TextareaComponent,
    BrowseFileComponent,
    ToggleSelectorComponent,
    CheckboxGroupComponent,
    BrowseVideoFileComponent,
    DragAndDropAreaComponent,
    ScheduleComponent,
    TimepickerComponent,
    BillingInformationComponent,
    RadioComponent,
    StepComponent,
    TabComponent,
    TabsetComponent,
    InlineHrefDirective,
    AgeRangeComponent,
    DatepickerComponent,
    DateRangeComponent,
    FileNameComponent,
    BreadcrumbsComponent,
    ThumbnailComponent,
    ActivityComponent,
    StatItemComponent,
    MessageComponent,
    ImageLibraryComponent,
    SwitchComponent,
    ActivityInfoComponent,
    ActivityStatComponent,
    SearchComponent,
    PaginatorComponent,
    CropperPopupComponent,
    BetterCropperPopupComponent,
    ServerValidationDirective,
    TooltipDirective,
    LimitDirective,
    ComboboxComponent,
    DateRangeValidationDirective,
    TimeRangeValidationDirective,
    CheckboxesValidationDirective,
    RadioGroupDirective,
    CheckboxGroupDirective,
    DragNDropDirective,
    SelectizeDropdownComponent,
    AddressSelectizeComponent,
    InfoItemComponent,
    StatsComponent,
    AgeRangeRequiredValidatorDirective,
    NumberRangeValidationDirective,
    TextFormattingValidationDirective,
    InputTrimDirective,
    ErrorMessageComponent,
    KeywordsComponent,
    WorkScheduleComponent,
    InfoTooltipComponent,
    SimpleTooltipComponent,
    TermsOfUseComponent,
  ],
})
export class SharedModule {}
