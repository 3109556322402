import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pb-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
  @Input() checked: boolean;
  @Output() checkedChange = new EventEmitter<boolean>();

  onChange() {
    this.checkedChange.emit(this.checked);
  }
}
