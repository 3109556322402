/**
 * Target audience view model.
 */
export class TargetAudience {
  /**
   * Start age.
   */
  public ageStart = 0;

  /**
   * End age.
   */
  public ageEnd: number = null;

  /**
   * Gender (both, male or female).
   */
  public gender: 'B' | 'M' | 'F' = 'B';

  /**
   * Interests (categories) ids.
   */
  public interests: string[] = [];

  /**
   * Radius.
   */
  public radius: string;
}
