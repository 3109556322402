import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const TRIM_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputTrimDirective),
  multi: true
};

@Directive({
  // tslint:disable-next-line
  selector: 'input[inputTrim]',
  providers: [TRIM_VALUE_ACCESSOR]
})
export class InputTrimDirective implements ControlValueAccessor {
  public _onChange(_: any) { }
  public _onTouched() { }
  public registerOnChange(fn: (value: any) => any): void { this._onChange = fn; }
  public registerOnTouched(fn: () => any): void { this._onTouched = fn; }
  constructor (
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) { }

  public writeValue(value: any): void {
    if (value) {
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
    }
  }

  @HostListener('change', ['$event.target'])
  public _onchange(element: HTMLInputElement) {
    const val = element.value.trim();
    this.writeValue(val);
    this._onChange(val);
  }
}
