import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pb-activity-stat',
  templateUrl: './activity-stat.component.html',
  styleUrls: ['./activity-stat.component.scss']
})
export class ActivityStatComponent {

  constructor() { }

  @Input()
  public data: any;
}
