import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import {HttpHeaders} from "@angular/common/http";

const TOKEN_STORAGE_KEY = 'token';

/**
 * Token storage service. Serves to keep authorization token in the local storage.
 */
@Injectable()
export class TokenStorageService {
  /**
   * Gets current token from the local storage.
   */
  public get token(): string {
    return localStorage.getItem(TOKEN_STORAGE_KEY);
  }

  /**
   * Sets given token to the local storage.
   */
  public set token(v: string) {
    if (v) {
      localStorage.setItem(TOKEN_STORAGE_KEY, v);
    } else {
      this.clearToken();
    }
  }

  /**
   * Clears token from the local storage.
   */
  public clearToken(): void {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  /**
   * Prepares authorization header with stored token.
   */
  public get baseHeaders(): Headers {
    return new Headers({
      Authorization: `token ${this.token}`
    });
  }

  public get baseHttpClientHeaders(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `token ${this.token}`);
  }
}
