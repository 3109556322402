import { NgForm } from '@angular/forms';
import { AuthService } from '../../../core';
import {
    AfterContentChecked,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { zip } from 'rxjs/operators';

const fieldsMap = {
  'number': 'card_number',
  'cvv': 'cvc',
  'expirationYear' : 'card_year',
  'expirationMonth' : 'card_month',
  'postalCode' : 'zip'
};

function handleBraintreeError(error: any) {
  const details = error.details;
  if (details) {
    const invalidFieldKeys = details.invalidFieldKeys;
    applyErrorStyle(invalidFieldKeys);
  } else if (error.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
    applyErrorStyle(Object.getOwnPropertyNames(fieldsMap));
  }
}

function applyErrorStyle(fields: string[]) {

  fields.forEach(field => {
    const name = fieldsMap[field];
    const element = document.getElementsByName(name);
    if (element.length > 0 && !element.item(0).classList.contains('braintree-hosted-fields-invalid')) {
      element.item(0).classList.add('braintree-hosted-fields-invalid');
    }
  });
}

function cleanErrorStyle() {
  // tslint:disable-next-line:forin
  for (const field in fieldsMap) {
    const name = fieldsMap[field];
    const element = document.getElementsByName(name);
    if (element.length > 0) {
      element.item(0).classList.remove('braintree-hosted-fields-invalid');
    }
  }
}

function createHostedFields(clientInstance, form = null, callback) {
  const braintree: any = (<any>window).braintree;
  const braintreeHostedFields = braintree.hostedFields.create({
    client: clientInstance,
    styles: {
      'input': {
        'width': '100%',
        'margin-top': '8px',
        'padding': '0 11px',
        'height': '34px',
        'line-height': '34px',
        'border': '1px solid #ccc',
        'border-radius': '3px',
        'font-size': '14px',
        'box-shadow': 'inset 0 1px 0 0 #ececec',
        'transition': '0.3s',
        'display': 'block',
        'font-family': 'Arial',
      },
      ':focus': {
        'color': 'black'
      },
      '::placeholder': {
        'font-size': '12px',
        'font-style': 'italic',
        'color' : '#878787'
      }
    },
    fields: {
      number: {
        selector: '[name="card_number"]',
        placeholder: 'XXXX XXXX XXXX XXXX'
      },
      expirationDate: {
        selector: '[name="card_year"]',
        placeholder: 'MM/YY'
      },
      postalCode: {
        selector: '[name="zip"]',
        placeholder: 'XXXXX'
      },
      cvv: {
        selector: '[name="cvc"]',
        placeholder: 'XXXX'
      }
    }
    // tslint:disable-next-line:only-arrow-functions
  }, function (err, hostedFieldsInstance) {
    // tslint:disable-next-line:only-arrow-functions
    const teardown = function (event) {

      // tslint:disable-next-line:only-arrow-functions
      if (hostedFieldsInstance) {

        cleanErrorStyle();

        // tslint:disable-next-line:only-arrow-functions
        hostedFieldsInstance.tokenize(function (error, payload) {
          if (error) {
            return;
          }
        }, false)
          // tslint:disable-next-line:only-arrow-functions
          .then(function (result) {
            if (callback) {
              callback(result.nonce);
            }
          })
          // tslint:disable-next-line:only-arrow-functions
          .catch(function (error) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            handleBraintreeError(error);
          });
      }
    };
    if (form) {
      form.addEventListener('submit', teardown, false);
    }
  });
}


@Component({
  selector: 'pb-billing-information',
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss']
})
export class BillingInformationComponent implements OnChanges, AfterContentChecked {
  constructor(
    private authService: AuthService
  ) { }

  public cardMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  @Input()
  public caption = 'Card Information';

  @Input()
  public isEditable = false;

  private isSubmitted = false;

  @Input()
  public card: any = {
    name: null,
    number: null,
    cvc: null,
    month: null,
    year: null
  };

  @Input()
  public address: any = {
    billingAddress: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    customState: null
  };

  @Input()
  public merchantId: number;

  public showCustomState = false;

  @Input()
  public states: string[] = [
  ];

  @Input()
  public usStates: string[] = [
  ];

  @Input()
  public canadaStates: string[] = [
  ];

  @Input()
  public country: string;

  @ViewChild(NgForm)
  public form: NgForm;

  private clientToken: string;

  private htmlForm: HTMLFormElement;

  @Output()
  public submitEmitter = new EventEmitter<any>();

  public countries: string[] = ["United States of America", "Canada"];

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
  }

  public ngAfterContentChecked(): void {
    if (this.address.state === 'Other') {
      this.address.state = 'Other';
      this.showCustomState = true;
    } else {
      this.showCustomState = false;
    }
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.isEditable) {
      const token = await this.authService.getPaymentToken(this.merchantId);
      this.clientToken = token.clientToken;

      const braintree: any = (<any>window).braintree;
      braintree.client.create({
        authorization: this.clientToken
      }, (error, clientInstance) => {
        if (error) {
          return;
        }

        const form: HTMLFormElement = document.querySelector('[id="card-form"]') as HTMLFormElement;

        this.htmlForm = form;

        createHostedFields(clientInstance, form, this.handleSubmit);
      });
    }
  }

  public submitForm(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    const submit = new Event('submit', {
      cancelable: true,
      bubbles: true,
      scoped: true
    });

    if (!this.form.valid) {
      return false;
    }

    this.htmlForm.dispatchEvent(submit);
  }

  private handleSubmit(nonce: string) {

    const self = (<any>window).cardComponent as BillingInformationComponent;
    if (self) {
      self.submitEmitter.emit({ nonce: nonce, address: self.address, cardholderName: self.card.name });
    }
  }

  public get state(): string {
    if (this.address.customState) {
      return this.address.customState;
    }

    return this.address.state;
  }

  public onSubmit(form: NgForm, event: Event) {

    this.isSubmitted = true;
    (<any>window).cardComponent = this;

    return false;
  }

  public stateChanged(event: any) {
    this.showCustomState = this.address.state === 'Other';
  }

  public countryChanged(event: any) {
    if(this.address.country === 'Canada') {
      this.states = this.canadaStates;
    } else {
      this.states = this.usStates;
    }
  }
}

