import {DomSanitizer} from '@angular/platform-browser';
import {Merchant, WebApp} from '../../core';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

/**
 * Web App info component.
 */
@Component({
  selector: 'pb-webapp-info',
  templateUrl: './webapp-info.component.html',
  styleUrls: ['./webapp-info.component.scss']
})
export class WebappInfoComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {
  }

  @Input()
  public merchant: Merchant;

  @Input()
  public webapps: WebApp[];

  @Input()
  public currentWebAppId: string;

  @Output()
  public selectedWebAppChange = new EventEmitter<Merchant>();

  public async ngOnInit(): Promise<void> {
  }

  public onSelectMerchant(id: string, isHolding: boolean): void {
    this.selectedWebAppChange.emit(new Merchant({id, isHolding}));
  }
}
