<header pb-page-header>
  <div left>Dashboard</div>
  <div right>

    <div class="info__text"><a *ngIf="shouldShowBookmarkLink" href="#" (click)="bookmarkPage($event)"
        title="bookmark this page">Don’t forget to bookmark our page</a> &nbsp; &nbsp; <b>{{ userEmail }}</b></div>
  </div>
</header>

<div class="row">
  <div class="date">
    Welcome
    <!-- {{invoiceStartDate | date: 'MMMM dd, y'}} - {{invoiceEndDate | date: 'MMMM dd, y'}} -->
  </div>
  <div class="info-container">
    <!-- <div class="info">
      <div class="info__text">Charges Last 30 days:</div>
      <div class="info__data">{{ monthCharges | currency:'USD':true }}</div>
    </div>
    <div class="info">
      <div class="info__text">Last Bill Date</div>
      <div class="info__data">{{lastPayDate | date: 'MMM d y'}}</div>
    </div> -->
  </div>
</div>

<div class="tiles-row" *ngIf="!isAutoHeartDashboard">
  <pb-stat-item cardtype="green" name="Campaigns & Events Opened" [icon]="'fa-eye'" [number]="allCampaignsViewCount"
    subtitle="The number of campaigns & events viewed."></pb-stat-item>
  <pb-stat-item cardtype="purple" name="Ecommerce Links" [icon]="'fa-hand-pointer'" [number]="getClickCount"
    subtitle="The total number of shopping links clicked inside a campaign."></pb-stat-item>
  <pb-stat-item cardtype="blue" name="Active # of Campaigns & Events" [icon]="'fa-bullhorn'" [number]="allCampaignsCount"
    subtitle="The number of active campaigns & events on your account."></pb-stat-item>
  <pb-stat-item cardtype="teal" name="Retail Redemptions" [icon]="'fa-barcode'" [number]="getReedemedCouponsCount"
    subtitle="The total number of campaigns & events redeemed.">
  </pb-stat-item>
  <pb-stat-item cardtype="pink" name="Total Followers" [icon]="'fa-heart'" [number]="getFollowersCount"
    subtitle="Your total number of followers gained over the life of your account."></pb-stat-item>
</div>

<div class="tiles-row" *ngIf="isAutoHeartDashboard">
  <pb-stat-item cardtype="pink" name="New Followers" [icon]="'fa-heart'" [number]="getNewFollowersCount"
    subtitle="New followers gained within the last 30 days."></pb-stat-item>
  <pb-stat-item cardtype="purple" name="Total Followers" [icon]="'fa-thumbs-up'" [number]="getFollowersCount"
    subtitle="Your total number of followers gained over the life of your account."></pb-stat-item>
  <pb-stat-item cardtype="blue" name="Opened Message" [icon]="'fa-eye'" [number]="openMessagesCount"
    subtitle="The total number of views for your active messages."></pb-stat-item>
  <pb-stat-item cardtype="teal" name="Ecommerce Links" [icon]="'fa-hand-pointer'" [number]="messageClickCount"
    subtitle="The total number of shopping links clicked inside a message.">
  </pb-stat-item>
  <pb-stat-item cardtype="green" name="Active # of Campaigns & Events" [icon]="'fa-bullhorn'" [number]="allCampaignsCount"
    subtitle="The number of active campaigns & events on your account."></pb-stat-item>
</div>

<div id="parent_section">
  <section class="container" id="section_{{isAutoHeartDashboard?'3':'1'}}">
    <div class="new-row">
      <div class="col-12">
        <h2>Campaigns / Events</h2>
        <div class="row small">
          <div class="sort-wrap">
            <pb-search [isSearchClosed]="false" [searchString]="searchString"
              (searchStringChange)="searchStringChanged($event)"></pb-search>
          </div>
          <div class="right">
            <div class="sort-wrap">
              <span class="sort-text">
                Filter by:
              </span>
              <pb-selectize-dropdown name="sortby" (ngModelChange)="filterByChanged($event)"
                [(ngModel)]="selectedFiltering" [items]="filtering" placeholder="Choose filtering" [maxItems]="1"
                required [openOnFocus]="true">
              </pb-selectize-dropdown>
            </div>
            <div class="sort-wrap">
              <span class="sort-text">
                Sort by:
              </span>
              <pb-selectize-dropdown name="sortby" (ngModelChange)="sortByChanged($event)" [(ngModel)]="selectedSorting"
                [items]="sortings" placeholder="Choose sorting" [maxItems]="1" required [openOnFocus]="true">
              </pb-selectize-dropdown>
            </div>
          </div>
        </div>
        <h4>Campaigns</h4>
        <div *ngFor="let item of pagedCampaignItems" class="campaign-item">
          <div class="logo" style.background-image="url({{item.logo || item.video}})"></div>
          <div class="campaign-logo">
            <div class="name">{{item.name}}</div>
            <div style="display: flex; margin-left: 5px;" *ngIf="(!item.ended)">
              <button class="button-link" *ngIf="!item.ended" (click)="editCoupon(item.id, 'Coupon')">Edit</button>
              <button class="button-link" *ngIf="!item.ended" (click)="openEndCouponPopup(item.id)">End Coupon</button>
            </div>
          </div>
          <div *ngIf="item.featured">
            <div style="padding: 5px; background-color: dodgerblue; font-size: 12px; color: white;">Featured Campaign
            </div>
          </div>
          <div *ngIf="!item.featured">
            Local Campaign
          </div>
          <div class="text">{{getDuration(item.startDate, item.endDate)}}
            <!-- <br>{{item.places.length}} Locations -->
          </div>
          <div class="campaign-stats">
            <div class="icon" title="Impressions">
              <pb-simple-tooltip description="Campaign views">
                <i class="fas fa-eye" aria-hidden="true"></i>
                <div class="">{{item.impressions}}</div>
              </pb-simple-tooltip>
            </div>
            <div class="icon" title="Likes">
              <pb-simple-tooltip description="Campaign followers">
                <i *ngIf="isAutoHeartDashboard" class="fas fa-heart" aria-hidden="true"></i>
                <pb-icon *ngIf="!isAutoHeartDashboard" name="#iconClicks" class="icon-small"
                         style="height: 17px; margin-right: 0px !important;"></pb-icon>
                <div class="">{{isAutoHeartDashboard ? item.likes : item.clicks}}</div>
              </pb-simple-tooltip>
            </div>
            <div class="icon" title="Redemptions">
              <pb-simple-tooltip description="Redemptions">
                <i class="fas fa-barcode" aria-hidden="true"></i>
                <div class="">{{item.redemptions}}</div>
              </pb-simple-tooltip>
            </div>
          </div>
          <div [ngClass]="!item.ended?'active':'ended'">
            <div class="round" [ngClass]="!item.ended?'green':'grey'"></div>{{getStatus(!item.ended)}}
          </div>
        </div>
        <pb-paginator [pagination]="pagination" (setPage)="setCampaignPageClicked($event)"></pb-paginator>
        <div class="card no-messages text-center" *ngIf="isNoCampaigns()">
          <i class="fas fa-bullhorn large" aria-hidden="true"></i>
          <p><strong>You don't have any active campaigns.</strong></p>
          <a [routerLink]='["/create_campaign"]'>Start a new campaign</a>
        </div><!-- card -->


        <h4>Events</h4>
        <div *ngFor="let item of eventsTableData" class="campaign-item">
          <div class="logo" style.background-image="url({{item.logo || item.video}})"></div>
          <div class="campaign-logo">
            <div class="name">{{item.name}}</div>
            <div style="display: flex; margin-left: 5px;" *ngIf="(!item.ended)">
              <button class="button-link" *ngIf="!item.ended" (click)="editEvent(item.id, 'Coupon')">Edit</button>
              <button class="button-link" *ngIf="!item.ended" (click)="openEndEventPopup(item.id)">End Event</button>
            </div>
          </div>

          <div class="text">{{getDuration(item.startDate, item.endDate)}}
            <!-- <br>{{item.places.length}} Locations -->
          </div>
          <div class="campaign-stats">
            <div class="icon" title="Impressions">
              <pb-simple-tooltip description="Event views">
                <i class="fas fa-eye" aria-hidden="true"></i>
                <div class="">{{item.impressions}}</div>
              </pb-simple-tooltip>
            </div>
            <div class="icon" title="Likes">
              <pb-simple-tooltip description="Event followers">
                <i *ngIf="isAutoHeartDashboard" class="fas fa-heart" aria-hidden="true"></i>
                <pb-icon *ngIf="!isAutoHeartDashboard" name="#iconClicks" class="icon-small"
                         style="height: 17px; margin-right: 0px !important;"></pb-icon>
                <div class="">{{isAutoHeartDashboard ? item.likes : item.clicks}}</div>
              </pb-simple-tooltip>
            </div>
          </div>
          <div [ngClass]="!item.ended?'active':'ended'">
            <div class="round" [ngClass]="!item.ended?'green':'grey'"></div>{{getStatus(!item.ended)}}
          </div>
        </div>
      </div><!-- .col -->
    </div><!-- .row -->
  </section>

  <section class="container" id="section_2">
    <div class="new-row">
      <div class="col-12">
        <h2>Messages</h2>
        <div *ngIf="isAutoHeartDashboard">
        <div *ngFor="let item of auto_messages" class="campaign-item ht-70" (click)="makeActive(item.viewUrl)">
          <div class="campaign-logo">
            <div class="name">{{item.title}}</div>
            <div style="display: flex; margin-left: 5px;">
              <button class="button-link" style="margin-top: 10px;" *ngIf="!item.ended"
                (click)="editAutoMessage(item.id)">Edit Message</button>
            </div>
          </div>
          <div class="text">
          </div>
          <div class="text">
            <div style="padding: 5px; background-color: dodgerblue; font-size: 12px; color: white;">Automated Message</div>
          </div>
          <div class="campaign-stats">
            <div class="icon" title="Impressions">
              <pb-icon name="#iconEye" class="icon-small"></pb-icon>
              <div class="text">{{item.views||0}}</div>
            </div>
            <div class="icon" title="Clicks">
              <pb-icon name="#iconClicks" class="icon-small"></pb-icon>
              <div class="text">{{item.clicks||0}}</div>
            </div>
          </div>
          <div [ngClass]="item.is_enabled?'active':'ended'">
            <div class="round" [ngClass]="item.is_enabled?'green':'grey'"></div>{{getMessageStatus(item.is_enabled)}}
          </div>
        </div>
        </div>
        <div *ngFor="let item of pagedMessageItems" class="campaign-item ht-70" (click)="makeActive(item.viewUrl)">
          <div class="campaign-logo">
            <div class="name">{{item.name}}</div>
            <div *ngIf="item.message_type === 'A'" style="display: flex; margin-left: 5px;">
              <button class="button-link" style="margin-top: 10px;" *ngIf="!item.ended"
                (click)="editAutoMessage(item.id)">Edit Message</button>
            </div>
          </div>
          <div class="text">
          </div>
          <div *ngIf="item.message_type === 'I'" class="text">{{item.startDate | date: 'MMMM d'}}
          </div>
          <div *ngIf="item.message_type === 'A'" class="text">
            <div style="padding: 5px; background-color: dodgerblue; font-size: 12px; color: white;">Automated Message
            </div>
          </div>
          <div class="campaign-stats">
            <div class="icon" title="Impressions">
              <pb-simple-tooltip description="Message views">
                <pb-icon name="#iconEye" class="icon-small"></pb-icon>
                <div class="text">{{item.views||0}}</div>
              </pb-simple-tooltip>
            </div>
            <div class="icon" title="Clicks">
              <pb-simple-tooltip description="CTA click thrus">
                <pb-icon name="#iconClicks" class="icon-small"></pb-icon>
                <div class="text">{{item.clicks||0}}</div>
              </pb-simple-tooltip>
            </div>
          </div>
          <div *ngIf="item.message_type === 'A'" [ngClass]="item.is_enabled?'active':'ended'">
            <div class="round" [ngClass]="item.is_enabled?'green':'grey'"></div>{{getMessageStatus(item.is_enabled)}}
          </div>
          <div *ngIf="item.message_type === 'I'" [ngClass]="'ended'">
            <div class="round grey"></div>Sent
          </div>
        </div>
        <pb-paginator [pagination]="messagePagination" (setPage)="setMessagePageClicked($event)"></pb-paginator>
        <div class="card no-messages text-center" *ngIf="isNoMessages()">
          <i class="fas fa-envelope large" aria-hidden="true"></i>
          <p><strong>You haven't sent any messages yet.</strong></p>
          <a [routerLink]='["/new-message"]'>Send a Message</a>
        </div><!-- card -->
      </div><!-- .col -->
    </div><!-- .row -->
  </section>
</div>

<!-- <div>
  <pb-tabset>
    <pb-tab header="Campaigns">
      <div class="row small">
        <div class="sort-wrap">
          <pb-search [isSearchClosed]="false" [searchString]="searchString"
            (searchStringChange)="searchStringChanged($event)"></pb-search>
        </div>
        <div class="right">
          <div class="sort-wrap">
            <span class="sort-text">
              Filter by:
            </span>
            <pb-selectize-dropdown name="sortby" (ngModelChange)="filterByChanged($event)"
              [(ngModel)]="selectedFiltering" [items]="filtering" placeholder="Choose filtering" [maxItems]="1" required
              [openOnFocus]="true">
            </pb-selectize-dropdown>
          </div>
          <div class="sort-wrap">
            <span class="sort-text">
              Sort by:
            </span>
            <pb-selectize-dropdown name="sortby" (ngModelChange)="sortByChanged($event)" [(ngModel)]="selectedSorting"
              [items]="sortings" placeholder="Choose sorting" [maxItems]="1" required [openOnFocus]="true">
            </pb-selectize-dropdown>
          </div>
        </div>
      </div>
      <div *ngFor="let item of pagedItems" class="campaign-item">
        <div class="logo" style.background-image="url({{item.logo || item.video}})"></div>
        <div class="campaign-logo">
          <div class="name">{{item.name}}</div>
          <div style="display: flex; margin-left: 5px;" *ngIf="(!item.ended)">
            <button class="button-link" *ngIf="!item.ended" (click)="editCoupon(item.id, 'Coupon')">Edit</button>
            <button class="button-link" *ngIf="!item.ended" (click)="openEndCouponPopup(item.id)">End Coupon</button>
          </div>
        </div>
        <div class="campaign-type">{{item.featured ? 'Featured': 'Local'}}</div>
        <div class="text">{{getDuration(item.startDate, item.endDate)}}
        </div>
        <div class="campaign-stats">
          <div class="icon" title="Impressions">
            <pb-icon name="#iconEye" class="icon-small"></pb-icon>
            <div class="text">{{item.impressions}}</div>
          </div>
          <div class="icon" title="Likes">
            <pb-icon name="#iconLike" class="icon-small"></pb-icon>
            <div class="text">{{item.likes}}</div>
          </div>
          <div class="icon" title="Redemptions">
            <pb-icon name="#iconRedemptions" class="icon-small" alt="ssss"></pb-icon>
            <div class="text">{{item.redemptions}}</div>
          </div>
        </div>
        <div [ngClass]="!item.ended?'active':'ended'">
          <div class="round" [ngClass]="!item.ended?'green':'grey'"></div>{{getStatus(!item.ended)}}
        </div>
      </div>
      <pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
      <div *ngIf="isNoCampaigns()">
        <pb-icon name="#iconCoupons" class="icon-medium"></pb-icon>
        <br>
        <div class="note">You haven’t added any Campaigns yet.</div>
      </div>
    </pb-tab>
    <pb-tab header="Messages">
      <div *ngFor="let item of messages" class="campaign-item ht-70" (click)="makeActive(item.viewUrl)">
        <div class="campaign-logo">
          <div class="name">{{item.name}}</div>
          <div *ngIf="item.message_type === 'A'" style="display: flex; margin-left: 5px;">
            <button class="button-link" style="margin-top: 10px;" *ngIf="!item.ended"
              (click)="editAutoMessage(item.id)">Edit</button>
          </div>
        </div>
        <div *ngIf="item.message_type === 'I'" class="text">{{item.startDate | date: 'MMMM d'}}
        </div>
        <div *ngIf="item.message_type === 'A'" class="text">
          <div style="color: cornflowerblue;">Automated Message</div>
        </div>
        <div class="campaign-stats">
          <div class="icon" title="Impressions">
            <pb-icon name="#iconEye" class="icon-small"></pb-icon>
            <div class="text">{{item.views||0}}</div>
          </div>
          <div class="icon" title="Clicks">
            <pb-icon name="#iconClicks" class="icon-small"></pb-icon>
            <div class="text">{{item.clicks||0}}</div>
          </div>
        </div>
        <div *ngIf="item.message_type === 'A'" [ngClass]="item.is_enabled?'active':'ended'">
          <div class="round" [ngClass]="item.is_enabled?'green':'grey'"></div>{{getMessageStatus(item.is_enabled)}}
        </div>
        <div *ngIf="item.message_type === 'I'" [ngClass]="'ended'">
        </div>
      </div>
      <div *ngIf="isNoMessages()">
        <pb-icon name="#iconCoupons" class="icon-medium"></pb-icon>
        <br>
        <div class="note">You haven't added any messages yet.</div>
      </div>
    </pb-tab>
  </pb-tabset>
</div> -->
