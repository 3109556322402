import {Merchant} from "../../../models/merchant";

/**
 * Merchant's coupon.
 */
export class MerchantsCoupon {
  /**
   * Id of coupon.
   */
  public id: number;

  /**
   * Flag fo completed coupon.
   */
  public isCompleted: boolean;

  /**
   * Image of coupon.
   */
  public image: string;

  /**
   * Logo of coupon.
   */
  // public logo: string;

  /**
   * QRCode of coupon.
   */
  public in_store_image: string;

  /**
   * Flag fo active coupon.
   */
  public isActive: boolean;
  public isRedeemable: boolean;
  /**
   * Campaign id.
   */
  public campaignId: number;

  /**
   * Name of coupon.
   */
  public name: string;

  /**
   * Code for online coupon.
   */
  public online: string;

  /**
   * Instructions for online coupon.
   */
  public onlineInstructions: string;

  /**
   * In store code.
   */
  public store: string;

  /**
   * In store instructions.
   */
  public inStoreInstructions: string;

  /**
   * Rules for coupon.
   */
  public rules: string;

  /**
   * Terms for coupon.
   */
  public terms: string;

  public operationHours: any;

  public targetAuidience: {
    ageStart: number;
    ageEnd: number;
    radius: number;
    gender: string;
    /**
     * Array of interest ids
     */
    interest: string[];
  };

  /**
   * Message for merchant's company.
   */
  public message: string;

  /**
   * Keywords for merchant's company.
   */
  public keywords: string[];

  public places: string[] | any;

  public startDate: string;
  public endDate: string;

  public startDateRaw: string;

  public endDateRaw: string;

  public merchantId: number;

  public impressions: number;
  public likes: number;
  public redemptions: number;

  public description: string;

  /**
   * Url for view coupon.
   */
  public viewUrl: string;
  public provider: string;

  public isExpired = false;

  public redeemInStoreLimit: number;

  public redeemInStoreLeft: number;

  public redeemOnlineLimit: number;

  public redeemOnlineLeft: number;

  /**
   * Old price of coupon.
   */
  public oldPrice: string;

  /**
   * New price of coupon.
   */
  public newPrice: string;

  public campaignPlan: number;
  public video: number;

  public hidden: boolean;
  public featured: boolean;
}


export class PaginatedMerchantsCoupon {
  public count: number;
  public next: string;
  public previous: string;
  public results: MerchantsCoupon[];
}

export class PaginatedMerchants {
  public count: number;
  public next: string;
  public previous: string;
  public results: Merchant[];
}
