import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Component for represent name of file and remove link.
 */
@Component({
  selector: 'pb-file-name',
  templateUrl: './file-name.component.html',
  styleUrls: ['./file-name.component.scss']
})
export class FileNameComponent {

  constructor() { }

  @Input()
  public name: string;

  @Input()
  public downloadUrl: string;

  @Input()
  public ableToRemove = false;

  @Output()
  public onRemove: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Hide name and link Dowland on create coupon and promotion page.
   */
  @Input()
  public hideNameAndDowland = true;

  private removeFile(): void {
    this.name = '';
    this.onRemove.emit();
  }
}
