import {ServerErrorMapper} from '../error-handler/mappers/server-error.mapper';

import {ReportDto, ReportsDto, SignupReportsDto} from './dtos/report.dto';
import {ReportMapper} from './mappers/report.mapper';
import {Observable} from 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {Http, ResponseContentType} from '@angular/http';

import {environment} from '../../../../environments/environment';
import {Report, SignupReport} from '../../models/report';
import {ServerError} from '../../models/server-error';
import {TokenStorageService} from '../token-storage.service';
import {AuthService} from '../auth/auth.service';
import {ServerErrorDto} from '../error-handler/dtos/server-error.dto';

const MERCHANT_URL_PART = 'merchants';
const REPORTS_URL_PART = 'reports';
const SIGNUP_REPORTS_URL_PART = 'signup-reports';

/**
 * Service for merchant's messages.
 */
@Injectable()
export class ReportsService {

  /**
   * .ctor
   */
  constructor(
    private http: Http,
    private tokenService: TokenStorageService,
    private userService: AuthService,
    private reportMapper: ReportMapper,
    private serverErrorMapper: ServerErrorMapper) {
  }

  /**
   * Retrieve list of reports.
   * @param merchantId Id of merchant.
   */
  public getReports(merchantId: number): Promise<Report[]> {
    return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${REPORTS_URL_PART}/`,
      {headers: this.tokenService.baseHeaders})
      .map(r => r.json() as ReportsDto)
      .map(r => r.results.map(report => this.reportMapper.mapToModel(report)))
      .toPromise();
  }

  /**
   * Retrieve report from remote endpoint.
   * @param merchantId Id of merchant.
   * @param reportId Id of report.
   */
  public getReport(merchantId: number, reportId: number): Promise<Report> {
    return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${REPORTS_URL_PART}/${reportId}/`,
      {headers: this.tokenService.baseHeaders})
      .map(r => r.json() as ReportDto)
      .map(r => this.reportMapper.mapToModel(r))
      .toPromise();
  }

  /**
   * Adding report to remote endpoint.
   * @param merchantId Merchant id for report.
   * @param start Start of report date.
   * @param end End of report date.
   */
  public addReport(merchantId: number, start: string, end: string, title: string): Promise<ServerError | null> {
    return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${REPORTS_URL_PART}/`, {
      name: title,
      start: start,
      end: end,
      report_date: new Date().toISOString(),
      places: []
    }, {headers: this.tokenService.baseHeaders})
      .map(r => {
        return null;
      })
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  /**
   * Deleting report by sending DELETE request.
   * @param merchantId Id of merchant.
   * @param reportId Id of report which will be deleted.
   */
  public deleteReport(merchantId: number, reportId: number): Promise<void> {
    return this.http.delete(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${REPORTS_URL_PART}/${reportId}/`,
      {headers: this.tokenService.baseHeaders})
      .map(r => null)
      .toPromise();
  }

  /**
   * Retrieve list of reports.
   * @param merchantId Id of merchant.
   * @param app
   */
  public getSignupReports(merchantId: number, app: string): Promise<SignupReport[]> {
    return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${SIGNUP_REPORTS_URL_PART}/?app=${app}`,
      {headers: this.tokenService.baseHeaders})
      .map(r => r.json() as SignupReportsDto)
      .map(r => r.results.map(report => this.reportMapper.mapToSignupReportModel(report)))
      .toPromise();
  }


  /**
   * Adding report to remote endpoint.
   * @param merchantId Merchant id for report.
   * @param app
   * @param start Start of report date.
   * @param end End of report date.
   * @param title
   */
  public addSignupReport(merchantId: number, app: string, start: string, end: string, title: string): Promise<ServerError | null> {
    return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${SIGNUP_REPORTS_URL_PART}/`, {
      name: title,
      start: start,
      app: app,
      end: end,
      report_date: new Date().toISOString(),
      places: []
    }, {headers: this.tokenService.baseHeaders})
      .map(r => {
        return null;
      })
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  public downloadSignupReport(merchantId: number, currentWebApp: string, id: number) {
    return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${SIGNUP_REPORTS_URL_PART}/${id}/download/`,
      {app: currentWebApp},
      {headers: this.tokenService.baseHeaders, responseType: ResponseContentType.Blob}) // Ensure responseType is 'blob'
      .map(r => r.blob())
      .toPromise(); // Convert the observable to a promise
  }

  public deleteSignupReport(merchantId: number, currentWebApp: string, id) {
    return this.http.delete(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${SIGNUP_REPORTS_URL_PART}/${id}/?app=${currentWebApp}`,
      {headers: this.tokenService.baseHeaders})
      .map(r => null)
      .toPromise();
  }

  async getSignupReport(merchantId: number, currentWebApp: string, id) {
    return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${SIGNUP_REPORTS_URL_PART}/${id}/?app=${currentWebApp}`,
      {headers: this.tokenService.baseHeaders})
      .map(r => r.json() as SignupReport)
      .toPromise();
  }
}
