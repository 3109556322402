<header pb-page-header>
  <div left>Locations</div>
  <div right>
    <div class="buttons">
      <div *ngIf="reference !== undefined">
        <button [routerLink]="['/' + reference]" pb-button type="button" white class="button profile-button">Company
          Profile</button>
      </div>
      <!--<button pb-button class="button" [routerLink]="['/locations','view']"
        [queryParams]="reference !== undefined ? {r:'profile'}: {}">+ Add Location</button>-->
      <button pb-button class="button" (click)="onAddLocation($event)">+ Add Location</button>
    </div>
  </div>
</header>

<table pb-table mdSort (mdSortChange)="sortDataChanged($event)" *ngIf="places.length > 0">
  <thead>
    <tr>
      <th width="70px" md-sort-header="placeIndex">
        <div class="row">
          #
        </div>
      </th>
      <th width="240px" md-sort-header="name">
        <div class="row">
          Name
        </div>
      </th>
      <th width="300px" md-sort-header="address">
        <div class="row">
          Address
        </div>
      </th>
      <th width="200px" md-sort-header="contact">
        <div class="row">
          Main Contact
        </div>
      </th>
      <th md-sort-header="stored">
        <div class="row">
          Location type
        </div>
      </th>
    </tr>
    <tr>
      <th></th>
      <th>
        <pb-search [searchString]="searchString" (searchStringChange)="searchStringChanged($event)"></pb-search>
      </th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let place of pagedItems;">
      <td>{{place.placeIndex}}</td>
      <td>
        <a (click)="editPlaceClick(place)">
          {{place.name}}
        </a>
      </td>
      <td>{{place.formattedAddress}}</td>
      <td>{{place.contactName}}</td>
      <td>
        <div *ngIf="place.isDisplayed">Selling</div>
        <div *ngIf="!place.isDisplayed">Admin/Not-selling</div>
      </td>
    </tr>
  </tbody>
</table>

<div class="error" *ngIf="places.length != 0">
  * Selling locations are listed on Mobile App
</div>

<div class="error" *ngIf="places.length === 0">
  You haven't added any locations yet.
</div>

<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
