<div class="caption">{{data.caption}}</div>

<div class="container">
    <div class="img-container">
        <img id="image" src="{{image}}" alt="Picture">
    </div>
</div>

<div class="buttons-wrapper">
    <button white class="cancel" pb-button md-dialog-close>Cancel</button>
    <button class="apply" pb-button (click)="closeDialog()">Apply</button>
</div>