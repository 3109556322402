import { Subscription } from 'rxjs/Rx';

import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, NgModel, Validator, AbstractControl } from '@angular/forms';
import { ValueAccessorBase } from '../../value-accessor-base';
import {
  Component,
  ElementRef,
  Host,
  Input,
  OnDestroy, OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'pb-age-range',
  templateUrl: './age-range.component.html',
  styleUrls: ['./age-range.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: AgeRangeComponent, multi: true }, {
    provide: NG_VALIDATORS, useExisting: AgeRangeComponent, multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class AgeRangeComponent extends ValueAccessorBase<{ from: number, to: number }> implements OnDestroy, OnInit, Validator {

  private startSubscription: Subscription;

  private endSubscription: Subscription;

  private valueChangedSubscription: Subscription;

  @Input()
  public isActive = true;

  @Input()
  public minInputNumber: number;

  @Input()
  public maxInputNumber: number;

  @ViewChild('start')
  public start: NgModel;

  @ViewChild('end')
  public end: NgModel;

  private readonly validStatus = 'VALID';

  public isValid: boolean;

  public validate(c: AbstractControl) {

    if (!this.isActive) {
      return null;
    }

    return ((this.end && !this.end.valid) || (this.start && !this.start.valid)) ? { required: true } : null;
  }

  constructor(
    @Host() private form: NgForm,
    private elementRef: ElementRef,
  ) {
    super();
    this.innerValue = { from: null, to: null };
  }

  public ngOnInit(): void {
    if (this.start) {
      this.startSubscription = this.start.statusChanges.subscribe(value => {
        this.subscribeOnInactive();
        const valid = this.isValidStatus(value);
        this.isValid = valid;
        if (this.isActive) {
          this.changeControlValidationStatus(valid);
          if (this.end) {
            this.end.control.updateValueAndValidity({ onlySelf: true, emitEvent: false});
          }
        }
      });
    }

    if (this.end) {
      this.endSubscription = this.end.statusChanges.subscribe(value => {
        this.subscribeOnInactive();
        const valid = this.isValidStatus(value);
        this.isValid = valid;
        if (this.isActive) {
          this.changeControlValidationStatus(valid);

          if (this.start) {
            this.start.control.updateValueAndValidity({ onlySelf: true, emitEvent: false});
          }
        }
      });
    }
  }

  private subscribeOnInactive() {
    const control = this.form.controls['ageRange'] as FormControl;
    if (control && !this.valueChangedSubscription) {
      this.valueChangedSubscription = control.valueChanges.subscribe(value => {
        if (value !== 'custom' && this.isValidObject(value)) {
          this.start.control.setErrors(null);
          this.end.control.setErrors(null);
        }
      });
    }
  }

  private isValidObject(instance: any) {
    if (!instance['from'] || !instance['to']) {
      return false;
    }

    if (!Number.isInteger(+instance['from']) || Number.isInteger(+instance['to'])) {
      return false;
    }

    return true;
  }

  private isValidStatus(status: string): boolean {
    return status === this.validStatus;
  }

  private changeControlValidationStatus(valid: boolean) {
    try {
      const control = this.form.controls['ageRange'] as FormControl;

      if (control) {
        const propertyName = 'required';
        const errorObject = {};
        errorObject[propertyName] = true;
        if (!valid && !this.isAlreadyMarked(control.errors)) {
          const mergedObject = this.mergeErrorObject(control.errors, errorObject);
          control.setErrors(mergedObject);
        } else if (valid) {
          control.setErrors(null);
        }
      }
    } catch (e) {

    }
  }

  private isAlreadyMarked(instance: any): boolean {
    if (!instance) {
      return false;
    }

    const key = 'required';
    return instance[key];
  }

  private mergeErrorObject(instance: any, mergingObject: any): any {

    if (!instance) {
      instance = {};
    }

    Object.assign(instance, mergingObject);

    return instance;
  }

  public ngOnDestroy(): void {
    if (this.endSubscription) {
      this.endSubscription.unsubscribe();
    }

    if (this.startSubscription) {
      this.startSubscription.unsubscribe();
    }
  }
}
