<div class="caption">{{data.caption}}</div>
<img-cropper #cropper [image]="outputImage" [settings]="cropperSettings"></img-cropper>
<div class="buttons-wrapper">
    <button white class="cancel" pb-button md-dialog-close>Cancel</button>
    <button *ngIf="(supportFit$ | async) && !isImageFitted" white class="cancel" pb-button (click)="fitImage()">Fit
        Image</button>
    <button *ngIf="(supportFit$ | async) && isImageFitted" white class="cancel" pb-button (click)="revertFit()">Cancel
        Fit</button>
    <!-- <pb-checkbox key="isRectangular" name="isRectangular" label="Rectangular" [value]="isRectangular"
        [checked]="isRectangular" (change)="changeSize($event)" [(ngModel)]="isRectangular"></pb-checkbox> -->
    <button class="apply" pb-button (click)="closeDialog()">Apply</button>
</div>