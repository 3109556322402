<div class="root" *ngIf="activities?.length > 0; else empty">
  <div class="header row">
    <!-- <div class="caption">{{type}}</div> -->
    <!-- <div class="tab no-pointer" [class.active]="type !== 'Messages'" *ngIf="type === 'Messages'; else tabs">Sent ({{activeActivities.length}})</div> -->
    <ng-template #tabs>
      <div class="row">
        <div *ngIf="activeActivities?.length > 0" class="tab" [class.active]="isActiveShow" (click)="showActive()">Active ({{activeActivities.length}})</div>
        <div *ngIf="completedActivities?.length > 0" class="tab" [class.active]="isCompletedShow" (click)="showCompleted()">Completed ({{completedActivities.length}})</div>
        <div *ngIf="notStartedActivities?.length > 0" class="tab" [class.active]="isNotStartedShow" (click)="showNotStarted()">New ({{notStartedActivities.length}})</div>
      </div>
    </ng-template>
  </div>
  <div class="body">
    <div class="item expand"
      *ngFor="let activity of currentActivities; let i = index" (click)="(type === 'Coupons' || type === 'Promotions') ? null: makeActive(i)"
      [ngClass]="{'active': i === activeIndex}">
      <div class="left">
        <div class="left name">{{activity.name}}</div>
        <div class="text" *ngIf="type !== 'Messages'">
          {{ dateRange(activity) }}
          <br>
          ({{(activity.places ? activity.places.length : 0) + ' locations'}})
        </div>
        <div class="text" *ngIf="type === 'Messages'">
          {{activity.startDate | date: 'MMMM d' }}
        </div>
        <div class="text" *ngIf="(type === 'Coupons' || type === 'Promotions') && (!activity.isAbleToEnd || !activity.isAbleToEdit)">
          <button class="button-link" *ngIf="!activity.isAbleToEnd" (click)="openPopup(i)">End Coupon</button>
          <button class="button-link" *ngIf="!activity.isAbleToEdit" (click)="editCoupon(i, type)">Edit</button>
        </div>
      </div>
      <div class="right hidden" *ngIf="type === 'Coupons' || type === 'Promotions'">
        <div class="row">
          <pb-icon name="#iconImpressions" class="icon-small"></pb-icon>{{activity.impressions}}
        </div>
        <div class="row">
          <pb-icon name="#iconGenerations" class="icon-small"></pb-icon>{{activity.likes}}
        </div>
        <div class="row">
          <pb-icon name="#iconRedemptions" class="icon-small"></pb-icon>{{activity.redemptions}}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #empty>
  <div class="root">
    <div class="header row">
      <div class="caption">{{type}}</div>
    </div>
    <div class="body centered">
      <div>
      <pb-icon [name]="'#icon' + type" class="icon"></pb-icon>
      <div class="note">You haven’t added any {{type.toLowerCase()}} yet.</div>
    </div>
  </div>
  </div>
</ng-template>
