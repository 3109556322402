<div class="header">
  <div class="left">
    <div class="item" *ngIf="data">
      <div class="caption">Coupon</div>
      <div class="content"><a [routerLink]="['/view-campaign', data.id]">{{data.name}}</a></div>
    </div>
  </div>
  <div class="right">
    <div class="status" *ngIf="data.isCompleted; else active">Completed</div>
    <ng-template #active>
      <div class="status active">Active</div>
    </ng-template>
    <div class="item">
      <div class="caption">Generations</div>
      <div class="content">{{data.likes}}</div>
    </div>
    <div class="item">
      <div class="caption">Redemptions</div>
      <div class="content">{{data.redemptions}}</div>
    </div>
  </div>
</div>
<div class="body">
  <div class="row">
    <div class="caption big">Locations:</div>
    <div>
      <span *ngFor="let location of data.locations; let i = index">{{location}}<span *ngIf="i + 1 !== data.locations.length">, </span></span>
    </div>
  </div>
  <div class="row">
    <div class="row">
      <div class="caption big">Age Range:</div>
      <div>
        <span *ngFor="let age of data.ageRanges; let i = index">{{age}}<span *ngIf="i + 1 !== data.ageRanges.length">, </span></span>
      </div>
    </div>
    <div class="row">
      <div class="caption big">Select Marketing Distance:</div>
      <div>{{data.radius}}</div>
    </div>
    <div class="row">
      <div class="caption big">Gender:</div>
      <div *ngIf="data.gender === 'Both'; else specificGender">No Preference</div>
      <ng-template #specificGender>
        <div>{{data.gender}} Only</div>
      </ng-template>
    </div>
  </div>
  <div class="row">
    <div class="caption big">Interests:</div>
    <div>
      <span *ngFor="let interest of data.interests; let i = index">{{interest}}<span *ngIf="i + 1 !== data.interests.length">, </span></span>
    </div>
  </div>
</div>
