import { Http } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdInputModule } from '@angular/material';
import { MdAutocompleteModule } from '@angular/material';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { LightboxModule } from 'angular2-lightbox';

// Core
import { AppComponent } from './app.component';
import { AppLayoutComponent } from './app-layout.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MerchantInfoComponent } from './side-bar/merchant-info/merchant-info.component';
import { MenuItemComponent } from './side-bar/menu-item/menu-item.component';
import { BillingInformationPageComponent } from './billing-information-page/billing-information-page.component';

// Modules
import { CoreModule } from './core/core.module';
import { HomePageModule } from './home-page/home-page.module';
import { RegistrationModule } from './registration';
import { LoginPageModule } from './login-page/login-page.module';
import { LoginPageCombinedModule } from './login-page-combined/login-page-combined.module';
import { CompanyProfilePageModule } from './company-profile-page/company-profile-page.module';
import { SharedModule } from './shared/shared.module';
import { NotFoundModule } from './not-found/not-found.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardPageModule } from './dashboard-page/dashboard-page.module';
import { ViewCampaignModule } from './view-campaign/view-campaign.module';
import { ReportDashboardModule } from './report-dashboard/report-dashboard.module';
import { ReportsPageModule } from './reports-page/reports-page.module';
import { InvoicesPageModule } from './invoices-page/invoices-page.module';
import { ViewInvoiceModule } from './view-invoice/view-invoice.module';
import { LocationsModule } from './locations/locations.module';
import { WebAppsModule } from './webapps/webapps.module';
import { ActivitiesPageModule } from './activities/activities-page.module';
import { NewMessageModule } from './new-message/new-message.module';
import { NewAutoMessageModule } from './new-auto-message/new-auto-message.module';
import { ViewPromotionModule } from './view-promotion/view-promotion.module';
import { AuthorizationHttpService } from './core/services/authorization-http.service';
import { RegistrationPayModule } from './registration-pay/registration-pay.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { LoginInformationModule } from './login-information/login-information.module';
import { PricingModule } from './shared/pricing/pricing.module';
import { CampaignModule } from './campaign/campaign.module';
import { CreateCampaignModule } from './campaign/create-campaign/create-campaign.module';
import { PlanSwitcherModule } from './shared/plan-switcher/plan-switcher.module';

// Services
import {
  CategoriesService,
  MerchantService,
  UtilsService,
  PlansService,
  BrandService,
  CustomizationService,
  AuthService,
  WebappService,
  PlaceService,
  TokenStorageService,
  ErrorHandlerService,
  MessagesService,
  DataTransferService,
  ReportsService,
  PaymentService,
  InvoiceService,
  ServerErrorMapper,
  MerchantMapper,
  CategoryMapper,
  StateMapper,
  UserMapper,
  PlaceMapper,
  PaginationService,
  MessageMapper,
  NewMessageMapper,
  NewAutoMessageMapper,
  PromotionMapper,
  CampaignMapper,
  EventMapper,
  CouponMapper,
  ReportMapper,
  FoursquareInfoMapper,
  PlaceSearchMapper,
  CardInfoMapper,
  CustomerInfoMappper,
  InvoiceMapper,
  GooglePlaceProvider, CouponsService, PlansMapper, BrandMapper, CustomizationMapper, WebappMapper,
} from './core';
import { PopupModule } from './popup/popup.module';
import { CampaignDataService } from './campaign/services/campaign-data.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './core/services/error-handler/http-error.interceptor';
import { AutoMessagesPageModule } from './auto-messages/auto-messages.module';
import {RegistrationBrandsModule} from './registration-brands/registration-brands.module';
import {LandingPageBrandsModule} from './landing-page-brands/landing-page-brands.module';
import {LandingPageExploreliModule} from './landing-page-exploreli/landing-page-exploreli.module';
import {EventsModule} from './events/events.module';
import {LandingPageSmokyExplorerModule} from './landing-page-smokyexplorer/landing-page-smokyexplorer.module';
import {
  LandingPageLondonLocalGuideModule
} from './landing-page-london-local-guide/landing-page-london-local-guide.module';
import {LandingPageMinicardsLondonModule} from './landing-page-minicards-london/landing-page-minicards-london.module';
import {LandingPageDiscoverLIModule} from './landing-page-discoverli/landing-page-discoverli.module';
import {WebappSideBarComponent} from "./webapp-side-bar/webapp-side-bar.component";
import {WebAppSideBarMenuItemComponent} from "./webapp-side-bar/menu-item/menu-item.component";
import {WebAppMerchantInfoComponent} from "./webapp-side-bar/merchant-info/merchant-info.component";
import {WebappInfoComponent} from "./side-bar/webapp-info/webapp-info.component";
import {ScanReportsPageModule} from "./scan-reports-page/scan-reports-page.module";
// import { FileDropDirective, FileUploader, FileUploadModule } from 'ng2-file-upload';

/**
 * App root module.
 */
@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    SideBarComponent,
    WebappSideBarComponent,
    MerchantInfoComponent,
    WebappInfoComponent,
    WebAppMerchantInfoComponent,
    MenuItemComponent,
    WebAppSideBarMenuItemComponent,
    BillingInformationPageComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    SharedModule,
    CampaignModule,
    EventsModule,
    MdAutocompleteModule,
    MdInputModule,
    CreateCampaignModule,
    HomePageModule,
    LoginPageModule,
    LoginPageCombinedModule,
    RegistrationBrandsModule,
    LandingPageBrandsModule,
    LandingPageExploreliModule,
    LandingPageDiscoverLIModule,
    LandingPageSmokyExplorerModule,
    LandingPageLondonLocalGuideModule,
    LandingPageMinicardsLondonModule,
    RegistrationModule,
    CompanyProfilePageModule,
    PopupModule,
    NotFoundModule,
    AppRoutingModule,
    DashboardPageModule,
    ViewCampaignModule,
    ReportDashboardModule,
    ScanReportsPageModule,
    ReportsPageModule,
    InvoicesPageModule,
    AutoMessagesPageModule,
    ViewInvoiceModule,
    LocationsModule,
    WebAppsModule,
    NewMessageModule,
    NewAutoMessageModule,
    ActivitiesPageModule,
    LightboxModule,
    ViewPromotionModule,
    RegistrationPayModule,
    ForgotPasswordModule,
    LoginInformationModule,
    PricingModule,
    PlanSwitcherModule,
    FormsModule,
    ReactiveFormsModule,
    // FileUploadModule
  ],
  providers: [
    // Services
    AuthService,
    WebappService,
    TokenStorageService,
    UtilsService,
    PlansService,
    BrandService,
    CustomizationService,
    CampaignDataService,
    CategoriesService,
    MerchantService,
    MessagesService,
    ErrorHandlerService,
    PaginationService,
    CouponsService,
    PlaceService,
    DataTransferService,
    ReportsService,
    PaymentService,
    InvoiceService,
    GooglePlaceProvider,
    PlansMapper,
    WebappMapper,
    BrandMapper,
    CustomizationMapper,
    // Mappers
    ServerErrorMapper,
    MerchantMapper,
    CategoryMapper,
    StateMapper,
    UserMapper,
    MessageMapper,
    NewMessageMapper,
    NewAutoMessageMapper,
    PromotionMapper,
    CampaignMapper,
    EventMapper,
    PlaceMapper,
    LightboxModule,
    CouponMapper,
    ReportMapper,
    FoursquareInfoMapper,
    PlaceSearchMapper,
    CardInfoMapper,
    CustomerInfoMappper,
    InvoiceMapper,
    { provide: Http, useClass : AuthorizationHttpService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
