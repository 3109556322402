import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import * as moment from 'moment';
import { CampaignDto } from '../dtos/campaign.dto';
import { Campaign } from '../models/campaign';


/**
 * @inheritdoc
 */
@Injectable()
export class CampaignMapper implements Mapper<CampaignDto, Campaign> {

    public mapToDto(campaign: Campaign): CampaignDto {
        const dto = <CampaignDto>{};

        dto.name = campaign.name;
        dto.campaign_plan = campaign.campaignPlan;
        dto.start = campaign.startDate;
        dto.end = campaign.endDate;
        dto.merchantId = campaign.merchantId;
        dto.operation_hours = campaign.operationHours;
        dto.target_audience = {
            age_start: campaign.targetAuidience.ageStart,
            age_end: campaign.targetAuidience.ageEnd,
            gender: campaign.targetAuidience.gender,
            interest: campaign.targetAuidience.interest,
            radius: campaign.targetAuidience.radius
        };
        dto.keywords = campaign.keywords;
        dto.places = campaign.places;
        dto.old_price = campaign.oldPrice;
        dto.new_price = campaign.newPrice;

        return dto;
    }

    /**
     * @inheritdoc
     */
    public mapToModel(dto: CampaignDto): Campaign {
        const model = {
            id: dto.id,
            name: dto.name,
            isCompleted: false,
            isActive: false,
            likes: dto.likes,
            clicks: dto.clicks,
            impressions: dto.impressions,
            redemptions: dto.redemptions,
            startDate: null,
            endDate: null,
            merchantId: dto.merchantId,
            operationHours: dto.operation_hours,
            message: dto.message_text,
            keywords: [],
            targetAuidience: {
                ageStart: dto.target_audience != null ? dto.target_audience.age_start : null,
                ageEnd: dto.target_audience != null ? dto.target_audience.age_end : null,
                radius: dto.target_audience != null ? dto.target_audience.radius : null,
                gender: dto.target_audience != null ? dto.target_audience.gender : null,
                interest: dto.target_audience != null ? dto.target_audience.interest : []
            },
            image: dto.image,
            video: dto.video,
            places: dto.places,
            viewUrl: `/view-campaign/${dto.id}`,
            editUrl: `/edit-campaign/${dto.id}`,
            in_store: dto.in_store,
            online: dto.online,
            campaignId: dto.id,
            oldPrice: dto.new_price,
            newPrice: dto.new_price,
            campaignPlan: dto.campaign_plan
        } as Campaign;

        if (dto.start) {
            model.startDate = dto.start; //moment(dto.start).format('MMMM D');
            model.isActive = moment().isAfter(moment(dto.start));
        }
        if (dto.end) {
            model.endDate = dto.end; // moment(dto.end).format('MMMM D');
            model.isCompleted = moment().isAfter(moment(dto.end));
            if (model.isCompleted) {
                model.isActive = false;
            }
        }

        return model;
    }
}
