import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { NewMessageDto } from '../dtos/new-message.dto';
import { NewMessage } from '../../../models/new-message';

/**
 * New message mapper.
 */
@Injectable()
export class NewMessageMapper implements Mapper<NewMessageDto, NewMessage> {
  /**
   * @inheritdoc
   * @param {NewMessageDto} dto NewMessage DTO.
   */
  public mapToModel(dto: NewMessageDto): NewMessage {
    const model = new NewMessage({
      image: dto.image, video: dto.video,
      id: dto.id,
      title: dto.title,
      message: dto.message,
      actionText: dto.action_text,
      actionURL: dto.action_url,
      actionEmail: dto.action_email || '',
      actionPhone: dto.action_phone || '',
      isConcierge: dto.is_concierge,
      targetAudience: {
         ageStart: null,
         ageEnd: null,
         gender: null,
         interests: null,
         radius: null
      },
      created: dto.created,
      audienceCount: dto.audience_count
    });

    if (dto.target_audience) {
      model.targetAudience.ageStart = dto.target_audience.age_start;
      model.targetAudience.ageEnd = dto.target_audience.age_end;
      model.targetAudience.gender = dto.target_audience.gender;
      model.targetAudience.interests = dto.target_audience.interest;
      model.targetAudience.radius = dto.target_audience.radius;
    }

    return model;
  }
}
