import { Subscription } from 'rxjs/Rx';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { PopupService } from '../popup';
import {
  InvoiceService,
  PaymentService,
  MerchantService,
  AuthService,
  Invoice,
  InvoiceItem,
  Merchant,
  CustomerInfo,
  CreditCardInfo,
  Pagination,
  PaginationService
} from '../core';
import { saveFile } from '../../utils/index';
import { Sort } from '@angular/material';

@Component({
  selector: 'pb-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit {

  private userSubscription: Subscription;
  private routeSubscription: Subscription;

  public invoice: Invoice;

  public merchant: Merchant;

  private customerInfo: CustomerInfo;

  public creditCardInfo: CreditCardInfo;

  public past_invoices = [];
  
  private currentSort: Sort;

  public searchString = "";

  public tableData = [];
  public pagination: Pagination = new Pagination();

  public pagedItems = [];
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private invoiceService: InvoiceService,
    private merchantService: MerchantService,
    private paymentService: PaymentService,
    private paginationService: PaginationService,
    private popupService: PopupService
  ) {
  }

  public get invoiceTitle(): string {
    if (!this.invoice) {
      return '';
    }

    return moment(this.invoice.chargeDate, 'YYYY-MM-DD').format('MMMM');
  }

  /**
   * Returns monthly subscription object.
   */
  public get monthlySubscription(): InvoiceItem {
    if (!this.invoice) {
      return null;
    }

    return this.invoice.getInvoiceItem('subscription');
  }

  /**
   * Returns coupons subscription object.
   */
  public get couponsSubscription(): InvoiceItem {
    if (!this.invoice) {
      return null;
    }

    return this.invoice.getInvoiceItem('coupons');
  }

  public get messagesSubscription(): InvoiceItem {
    if (!this.invoice) {
      return null;
    }

    return this.invoice.getInvoiceItem('messages');
  }

  public get promotionSubscription(): InvoiceItem {
    if (!this.invoice) {
      return null;
    }

    return this.invoice.getInvoiceItem('promotions');
  }

  public get getCampaignName(): string {
    if (!this.invoice) {
      return null;
    }

    return this.invoice.campaign.name;
  }

  public get locationFormatted(): string {
    if (!this.merchant) {
      return null;
    }

    return `${this.merchant.address.street}, ${this.merchant.address.city}, ${this.merchant.address.state} ${this.merchant.address.zip}`;
  }

  public async ngOnInit(): Promise<void> {

    // const spinner = this.popupService.spinner();

    this.userSubscription = this.authService.onUserChanged.skipWhile(user => !user)
      .subscribe(async (user): Promise<void> => {
        // spinner.close();

        this.merchant = await this.merchantService.getMerchant(user.merchantId);
        const customerInfos = await this.paymentService.getCustomerInfo(user.merchantId);
        if (customerInfos && customerInfos.length > 0) {
          this.customerInfo = customerInfos.shift();
          this.creditCardInfo = this.customerInfo.creditCards && this.customerInfo.creditCards.length > 0 ? this.customerInfo.creditCards[0]
            : null;
        }

        this.routeSubscription = this.activatedRoute.params.subscribe(async (params): Promise<void> => {
          const id = +params['id'];
          if (id) {
            this.invoice = await this.invoiceService.getInvoice(user.merchantId, id);
            this.past_invoices = await this.invoiceService.getPastInvoices(this.merchant.id, id);
            this.tableData = this.getMappedItems();
            this.setPage(1);
          }

          if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
          }
        });

        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }

      });
      // this.invoices = response;
      // this.tableData = this.getMappedItems();

      // this.setPage(1);
  }

  public async ExportToCsv(): Promise<void> {
    const file = await this.invoiceService.downloadCsv(this.merchant.id, this.invoice.id);
    saveFile(file, 'invoice.csv', 'text/csv');
  }

  public async ExportToPdf(): Promise<void> {
    const file = await this.invoiceService.downloadPdf(this.merchant.id, this.invoice.id);
    saveFile(file, 'invoice.pdf', '*/*');
  }

  /**
   * Shows new list of table items considering table's sorting and search.
   */
  public setPageClicked(page: number): void {
    this.adjustPlacesData(this.currentSort, this.searchString, page);
  }

  /**
   * Invokes data sorting in table considering search string, makes pagination start from 1st page.
   */
  public sortDataChanged(sort: Sort): void {
    this.adjustPlacesData(sort, this.searchString, 1);
  }

  public setPage(page: number) {
    this.pagination = this.paginationService.getPagination(
      this.tableData.length,
      page
    );

    this.pagedItems = this.tableData.slice(
      this.pagination.startIndex,
      this.pagination.endIndex + 1
    );
  }

  /**
   * Combines sorting, filtering and setting pagination methods.
   */
  private adjustPlacesData(
    sort: Sort,
    searchString: string,
    page: number
  ): void {
    this.sortData(sort);
    this.tableData = this.filterItems(this.tableData, searchString);
    this.setPage(page);
  }

  /**
   * Searches for string in table.
   */
  private filterItems(items, searchString: string): any[] {
    this.searchString = searchString;
    return items.filter(
      (i) =>
        i.id.toString().toLowerCase().indexOf(searchString.toLowerCase()) >
          -1 ||
        i.dueDate.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
        searchString === ""
    );
  }

  private sortData(sort: Sort): void {
    this.currentSort = sort;
    const data = this.getMappedItems();
    if (!sort || !sort.active || sort.direction === "") {
      this.tableData = data;
      return;
    }

    this.tableData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "Id":
          return compare(a.invoiceId, b.invoiceId, isAsc);
        case "DueDate":
          return compare(a.deuDate, b.deuDate, isAsc);
        case "CardCharged":
          return compare(a.cardCharged, b.cardCharged, isAsc);
        case "Total":
          return compare(a.sum, b.sum, isAsc);
        case "Status":
          return compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  private getMappedItems(): any[] {
    const invoices = this.past_invoices.map((invoice) => {
      let nextChargeDate = "-";
      if (invoice.chargeDate) {
        nextChargeDate = moment(invoice.chargeDate)
          .add(1, "month")
          .format("MMM DD, YYYY");
        if (moment(invoice.campaign.end).isBefore(nextChargeDate)) {
          nextChargeDate = "-";
        }
      }

      return {
        id: invoice.id,
        // invoiceId: moment(invoice.chargeDate, 'YYYY-MM-DD').format('MMMM'),
        // dueDate: moment(invoice.end).format('MMM DD, YYYY'),
        invoiceId: invoice.campaign.name || "",
        chargeDate: moment(invoice.chargeDate).format("MMM DD, YYYY"),
        nextChargeDate: nextChargeDate,
        cardCharged:
          invoice.transaction && invoice.transaction.creditCard
            ? `****-****-****-${invoice.transaction.creditCard}`
            : "",
        sum: invoice.total ? invoice.total.toString() : "-",
        status: invoice.status ? invoice.status : "",
      };
    });

    return invoices;
  }
}

function compare(a: any, b: any, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}