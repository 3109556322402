import { Observable } from 'rxjs/Rx';
import { CustomerInfoPaged } from './dtos/customer-info.dto';
import { CustomerInfoMappper } from './mappers/customer-info.mapper';
import { CardInfoMapper } from './mappers/card-info.mapper';

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { TokenStorageService } from '../token-storage.service';
import { CardInfo } from '../../models/card-info';
import { CustomerInfo } from '../../models/customer-info';

const MERCHANT_URL_PART = 'merchants';

/**
 * Service for Merchant's promotions.
 */
@Injectable()
export class PaymentService {
    /**
    * .ctor
    */
    constructor(
        private http: Http,
        private tokenService: TokenStorageService,
        private userService: AuthService,
        private cardInfoMapper: CardInfoMapper,
        private customerInfoMapper: CustomerInfoMappper
    ) {

    }

    /**
     * Adding information about payment card.
     * @param merchantId Id of merchant.
     * @param model model which will be mapped to dto.
     */
    public addPayment(merchantId: number, model: CardInfo): Promise<any> {
        const dto = this.cardInfoMapper.mapToDto(model);

        return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/payment/`, dto,
        { headers: this.tokenService.baseHeaders })
        .map(r => r)
        .catch(err => Observable.of(err))
        .toPromise();
    }

    /**
     * Returns customer info.
     * @param merchantId Id of merchant.
     */
    public getCustomerInfo(merchantId: number): Promise<CustomerInfo[]> {
        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/customer/`,
        { headers: this.tokenService.baseHeaders })
        .map(r => r.json() as CustomerInfoPaged)
        .map(r => r.results.map(ci => this.customerInfoMapper.mapToModel(ci)))
        .toPromise();
    }

    public makeTestPayment(merchantId: number): Promise<any> {
        return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/testpayment/`, {}, 
        { headers: this.tokenService.baseHeaders })
        .map(r => r)
        .toPromise();
    }
}
