import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { SignupPageComponent } from './signup-page/signup-page.component';
import { ConfirmPageComponent } from './confirm-page/confirm-page.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [
    SignupPageComponent
  ],
  declarations: [
    SignupPageComponent,
    ConfirmPageComponent,
  ]
})
export class RegistrationModule { }
