import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadedImage } from '../../../core';

/**
 * Drag and drop of files component.
 */
@Component({
  selector: 'pb-drag-and-drop-area',
  templateUrl: './drag-and-drop-area.component.html',
  styleUrls: ['./drag-and-drop-area.component.scss']
})
export class DragAndDropAreaComponent {
  /**
   * Base46 image.
   */
  public base64Image: string;

  /**
   * Title.
   */
  @Input()
  public hasTitle = false;

  /**
   * Displaying loader.
   */
  @Input()
  public displayLoader = false;

  /**
   * Count of images in parent component.
   * Used for restriction.
   */
  @Input()
  public imagesCount = 0;

  /**
   * Count of max images in parent component.
   * Used for restriction.
   */
  @Input()
  public imagesCountRestriction = 0;

  /**
   * Emitter which fired after file entry obtained.
   */
  @Output()
  public fileChanged = new EventEmitter<LoadedImage[]>();

  /**
   * Allow to update a new photo.
   */
  public get isAllowUpload() {
    return this.imagesCount < this.imagesCountRestriction;
  }

  /**
   * Called from pb-browse-file component.
   * @param images Array loaded images.
   */
  public uploadedFile(images: LoadedImage[]) {
    if (this.imagesCount < this.imagesCountRestriction) {
      this.fileChanged.emit(images);
    }
  }
}
