/**
 * Address domain model.
 */
export class Address {
  /**
   * .ctor
   * @param {any} initObject Initial object of predefined field values.
   */
  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }

  public city: string;

  public street: string;

  public state: string;

  public country: string;

  public zip: string;
}
