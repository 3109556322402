import { OnInit, Component, Output, Input, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';
import { CampaignDataService } from '../../campaign/services/campaign-data.service';

/**
 * Page component for Merchant's activities.
 */
@Component({
  selector: "pb-plan-switcher",
  templateUrl: "./plan-switcher.component.html",
  styleUrls: ["./plan-switcher.component.scss"]
})

export class PlanSwitcherComponent implements OnInit {
  public selectedPlan: string = "premium";

  @Input()
  public title = 'Upgrade to premium';

  @Input()
  public premiumFee: number;

  @Output() onPlanUpgrade = new EventEmitter();

  constructor(private router: Router, private dataService: CampaignDataService) { }

  public ngOnInit(): void {}

  public upgradeToPremium(): void {
    // $ev.preventDefault();
    this.onPlanUpgrade.emit();
  }
}
