import { Component, Input } from '@angular/core';

@Component({
  selector: 'pb-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss']
})
export class InfoTooltipComponent {
  @Input()
  public description: string;

  @Input()
  public hint: string;

  @Input()
  public note: string;
}
