
import { ValueAccessorBase } from '../../value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'pb-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: RadioComponent, multi: true }]
})
export class RadioComponent extends ValueAccessorBase<any> {

  constructor(
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
   }

  @Input()
  public name: string;

  @Input()
  public key: string;

  @Input()
  public label: string;

  @Input()
  public isChecked = false;

  @Input()
  public isDisabled = false;

  @HostListener('change', ['$event'])
  private handleClick(ev: UIEvent) {
    this.isChecked = true;
    const nativeElement = <HTMLElement>this.elementRef.nativeElement;
    const element = nativeElement.getElementsByTagName('input').item(0);
    element.setAttribute('checked', 'checked');

    this.changeDispatcher.emit({control: this, key: this.key});
    this.changeDetector.detectChanges();
  }

  public clearState() {
    this.isChecked = false;
    const nativeElement = <HTMLElement>this.elementRef.nativeElement;
    const element = nativeElement.getElementsByTagName('input').item(0);
    element.removeAttribute('checked');
  }
}
