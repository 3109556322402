import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { CategoryDto } from '../dtos/category.dto';
import { Category } from '../../../models/category';

/**
 * Category mapper.
 */
@Injectable()
export class CategoryMapper implements Mapper<CategoryDto, Category> {
  /**
   * @inheritdoc
   * @param {CategoryDto} dto Category DTO.
   */
  public mapToModel(dto: CategoryDto): Category {
    return new Category({
      id: dto.id,
      name: dto.name,
      pluralName: dto.plural_name,
      image: dto.image,
      isFavorite: dto.is_favorite,
      children: dto.children && dto.children.map(v => this.mapToModel(v)),
      parentId: dto.parent_id,
      parent: null
    });
  }
}
