import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MdDialogRef, MD_DIALOG_DATA } from "@angular/material";
import Cropper from "cropperjs";
import { Subject } from "rxjs/Rx";

/**
 * Cropper popup component.
 */
@Component({
  selector: "pb-better-cropper-popup",
  styleUrls: ["./better-cropper-popup.component.scss"],
  templateUrl: "./better-cropper-popup.component.html",
})
export class BetterCropperPopupComponent implements OnInit {
  public image: any;
  public isImageFitted = false;
  public supportFit$: Subject<boolean> = new Subject();
  public cropper: Cropper;

  /**
   * .ctor
   * @param {MdDialogRef<BetterCropperPopupComponent>} dialog This dialog reference.
   * @param {any} data Data params transfered to th dialog.
   */
  constructor(
    private dialog: MdDialogRef<BetterCropperPopupComponent>,
    @Inject(MD_DIALOG_DATA) public data: any
  ) {}

  /**
   * Output image adjusted after cropping.
   */
  public outputImage: any = {};

  private getCroperBoxDimension(w: number, h: number): any {
    let height: number = 0,
      width: number = 0;

    var aspectRatio = w / h;
    if (h > w) {
      height = h;
      width = h / 1.5;
    }
    return { height, width };
  }
  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    var image = <HTMLImageElement>document.querySelector("#image");
    image.src = this.data.image;
    // const { croperHeight, cropperWidth } = this.getCroperBoxDimension(
    //   image.width,
    //   image.height
    // );
    var data = document.querySelector("#data");

    var minCroppedWidth = 1080 / 2;
    var minCroppedHeight = 720 / 2;
    var maxCroppedWidth = 1080 * 4;
    var maxCroppedHeight = 720 * 4;

    var minAspectRatio = 1.5;
    var maxAspectRatio = 1.5;

    const options: any = {
      viewMode: 2,
      aspectRatio: 3 / 2,
      zoomable: false,
      // dragMode: "move",
      data: {
        width: maxCroppedWidth,
        height: maxCroppedHeight,
      },
      ready: function () {
        var cropper = this.cropper;
        var containerData = cropper.getContainerData();
        var cropBoxData = cropper.getCropBoxData();
        var aspectRatio = cropBoxData.width / cropBoxData.height;
        var newCropBoxWidth;

        if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
          newCropBoxWidth =
            cropBoxData.height * ((minAspectRatio + maxAspectRatio) / 2);

          cropper.setCropBoxData({
            left: (containerData.width - newCropBoxWidth) / 2,
            width: newCropBoxWidth,
          });
        }
      },
      crop: function (event) {
        var width = event.detail.width;
        var height = event.detail.height;

        if (
          width < minCroppedWidth ||
          height < minCroppedHeight ||
          width > maxCroppedWidth ||
          height > maxCroppedHeight
        ) {
          this.cropper.setData({
            width: Math.max(minCroppedWidth, Math.min(maxCroppedWidth, width)),
            height: Math.max(
              minCroppedHeight,
              Math.min(maxCroppedHeight, height)
            ),
          });
        }
        // var cropBoxData = this.cropper.getCropBoxData();
        // var aspectRatio = cropBoxData.width / cropBoxData.height;
        // data.textContent = JSON.stringify(
        //   Object.assign(
        //     { aspectRatio: aspectRatio.toFixed(2) },
        //     this.cropper.getData(true)
        //   )
        // );
      },
    };

    this.cropper = new Cropper(image, options);
  }

  /**
   * Handles the dialog closing with passed the output base64 image string.
   */
  public closeDialog() {
    this.dialog.close(this.cropper.getCroppedCanvas().toDataURL());
  }
}
