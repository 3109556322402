<ng-template [ngIf]="pagination.pages && pagination.pages.length > 1">
  <button class="button" [class.disabled]="pagination.currentPage === 1" (click)="setPageClick(pagination.currentPage - 1)">
    Previous
  </button>
  <ul class="list">
    <li class="item" [class.disabled]="pagination.currentPage === 1" (click)="setPageClick(1)">«</li>
    <li class="item" *ngFor="let page of pagination.pages" [class.active]="pagination.currentPage === page" (click)="setPageClick(page)">{{page}}</li>
    <li class="item" [class.disabled]="pagination.currentPage === pagination.totalPages" (click)="setPageClick(pagination.totalPages)">»</li>
  </ul>
  <button class="button" [class.disabled]="pagination.currentPage === pagination.totalPages" (click)="setPageClick(pagination.currentPage + 1)">
    Next
  </button>
</ng-template>
