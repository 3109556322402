import { Merchant, Division } from './merchant';
import {PromoCode} from "./promo-code";

/**
 * User domain model.
 */
export class User {
  /**
   * User identifier.
   */
  public id: number;

  /**
   * First name.
   */
  public firstName: string;

  /**
   * Last name.
   */
  public lastName: string;

  /**
   * User's email aka username.
   */
  public email: string;

  /**
   * Is User a Beta tester.
   */
  public isBetaUser: boolean;

  /**
   * Day of birth.
   */
  public birthday: any;

  /**
   * Gender.
   */
  public gender: string;

  /**
   * Stored user settings.
   */
  public settings: { [key: string]: { [key: string]: boolean } };

  /**
   * Avatar.
   */
  public avatar: any;

  /**
   * Id of merchant assigned to user.
   */
  public merchantId: number | null;

  /**
   * Merchant assignet to a user.
   */
  public merchant?: Merchant;

  /**
   * A list of categories.
   */
  public categories: string[];

  /**
   * Date of registration.
   */
  public dateJoined: Date;

  /**
   * Date of last login.
   */
  public lastLogin: any;

  /**
   * Holding flag.
   */
  public isHolding: boolean;

  /**
   * Divisions of original merchant.
   */
  public divisions: Division[];

  /**
   * Original merchant of user.
   */
  public originalMerchant: Merchant | null;

  /**
   * ID of current active merchant.
   */
  public currentMerchantId?: number | null;
  public currentWebAppId?: string | null;

  /**
   * Signals completion of registration.
   */
  public merchantCreationComplete?: boolean | null;

  public emailConfirmed: boolean;
  public promo_code: PromoCode;
}
