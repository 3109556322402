import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Merchant } from '../../core';
import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { getImageDimensions } from '../../../utils';

/**
 * Merchant info component.
 */
@Component({
  selector: 'pb-merchant-info',
  templateUrl: './merchant-info.component.html',
  styleUrls: ['./merchant-info.component.scss']
})
export class MerchantInfoComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) { }

  public isLogoRect = false;

  @Input()
  public merchant: Merchant;

  @Input()
  public rectLogo: boolean;

  @Input()
  public divisionRectLogos: object;

  @Input()
  public divisions: Merchant[];

  @Input()
  public currentMerchantId: number;

  @Input()
  public isHolding: boolean;

  @Input()
  public showLogo = true;

  @Output()
  public selectedMerchantChange = new EventEmitter<Merchant>();

  @Input()
  public showDivisions = true;

  public async ngOnInit(): Promise<void> {
  }

  public get logoUrl(): string {
    if (!this.merchant.logo) {
      return '';
    }

    return this.merchant.logo;
  }

  public divisionLogoUrl(division: Merchant): string {
    if (!division.logo) {
      return '';
    }

    return division.logo;
    // return this.sanitizer.bypassSecurityTrustStyle(`background-image: url('${division.logo}')`);
  }

  public onSelectMerchant(id: number, isHolding: boolean): void {
    this.selectedMerchantChange.emit(new Merchant({ id, isHolding }));
  }
}
