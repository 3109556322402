/**
 * Step into stepper.
 */
export class Step {
  /**
   * Step name.
   */
  public text: string;
  /**
   * The step number in the stepper.
   */
  public number: number;

  /**
   * Number to display on the stepper.
   */
  public get displayNumber(): number {
    return this.number + 1;
  }

  /**
   * Is the component of this step valid.
   */
  public isValid: boolean;


  /**
   * Callback function is called when switching to this step.
   */
  public callbackWhenGoToStep: Function;
  /**
   * Callback is called when you leave this step.
   */
  public callbackWhenLeavingStep: Function;

  /**
   * Init step.
   */
  constructor(number: number, text = '', callbackWhenLeavingStep?: Function, callbackWhenGoToStep?: Function, isValid = false) {
    this.text = text;
    this.number = number;
    this.isValid = isValid;
    this.callbackWhenGoToStep = callbackWhenGoToStep;
    this.callbackWhenLeavingStep = callbackWhenLeavingStep;
  }
}
