/**
 * View model for Merchant's campaign.
 */
export class Campaign {
  public id: number;
  public name: string;
  public places: string[];
  public isCompleted: boolean;
  public isActive: boolean;
  public impressions: number;
  public likes: number;
  public clicks: number;
  public redemptions: number;
  public startDate: string;
  public endDate: string;
  public merchantId: number;
  public operationHours: any;
  public targetAuidience: {
    ageStart: number;
    ageEnd: number;
    radius: number;
    gender: string;
    /**
     * Array of interest ids
     */
    interest: string[];
  };
  public image: any;

  /**
   * Keywords for merchant's company.
   */
  public keywords: string[];

  /**
   * Url for view coupon.
   */
  public viewUrl: string;

  /**
   * Old price of coupon.
   */
  public oldPrice: string;

  /**
   * New price of coupon.
   */
  public newPrice: string;
  public campaignPlan: number;
  public in_store: string;
  public online: string;
  public video: any;
}
