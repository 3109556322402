/**
 * WebAppPopup domain model.
 */

export class DataPopup {
  id: string;
  name: string;
  logo: string;
  title: string;
  subtitle: string;
  submit_button_text: string;
  ask_phone: boolean;
  phone_placeholder: string;
  ask_zip: boolean;
  zip_placeholder: string;
  ask_email: boolean;
  email_placeholder: string;
  ask_type: boolean;
  user_type_question: string;
  i_agree: boolean;
  i_agree_text: string;
  receive_newsletter: boolean;
  receive_newsletter_text: string;
  is_enabled: boolean;

  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }
}
