import {
    Directive,
    ElementRef,
    HostBinding,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { MdTooltip, Overlay, ScrollDispatcher, Platform, Directionality } from '@angular/material';
import { Subscription } from 'rxjs/Subscription';

const TOOLTIP_TIMEOUT = 2000;

/**
 * Tooltip directive.
 */
@Directive({
  selector: '[pbTooltip][ngModel]'
})
export class TooltipDirective implements OnInit, OnDestroy {
  /**
   * .ctor
   */
  constructor(private ngForm: NgForm,
              private ngModel: NgModel,
              private overlay: Overlay,
              private elementRef: ElementRef,
              private scrollDispatcher: ScrollDispatcher,
              private viewContainerRef: ViewContainerRef,
              private ngZone: NgZone,
              private renderer: Renderer2,
              private platform: Platform,
              private dir: Directionality) {
  }

  /**
   * Bound material tooltip directive to the component.
   */
  @HostBinding('attr.mdTooltip')
  public tooltip: MdTooltip;

  @Input()
  public customElementQuery: string;

  private statusSubscription: Subscription;

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {

    this.initializeTooltip();
    this.statusSubscription = this.ngModel.statusChanges.withLatestFrom(this.ngForm.ngSubmit).subscribe(status => {
      this.showTooltip();
    });
  }

  private initializeTooltip() {
    this.tooltip = new MdTooltip(this.overlay, this.getElementRef(), this.scrollDispatcher,
                    this.viewContainerRef, this.ngZone, this.renderer, this.platform, this.dir);
    this.tooltip.position = 'right';
    this.tooltip.tooltipClass = 'error-tooltip';
  }

  private showTooltip() {
    this.tooltip.disabled = this.ngModel.valid;
    if (this.ngModel.invalid) {
      this.tooltip.message = this.ngModel.errors && this.ngModel.errors[0];
      this.tooltip.show();
      setTimeout(() => this.tooltip.hide(), TOOLTIP_TIMEOUT);
    }
  }

  private getElementRef(): ElementRef {
    if (!this.customElementQuery) {
      return this.elementRef;
    }

    const elementRaw = this.elementRef.nativeElement as HTMLElement;
    const customElement = elementRaw.querySelector(this.customElementQuery);
    if (!customElement) {
      return this.elementRef;
    }

    return new ElementRef(customElement);
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
  }

}
