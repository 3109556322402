import { Directive, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Response } from '@angular/http';
import { Subscription } from 'rxjs/Subscription';

import { ServerErrorMapper } from '../../core';
import { VALIDATION_ERROR } from '../../core';

/**
 * Server validation directive.
 */
@Directive({
  selector: '[pbServerValidation]form'
})
export class ServerValidationDirective implements OnInit, OnDestroy {
  /**
   * .ctor
   * @param ngForm Injected angular form.
   */
  constructor(private ngForm: NgForm, private errorMapper: ServerErrorMapper) { }

  /**
   * Error result emitter passed from page.
   */
  @Input('pbServerValidation')
  public errorEmitter: EventEmitter<Response>;

  private errorEmitterSubscription: Subscription;

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.errorEmitterSubscription = this.errorEmitter.subscribe((errorResponse: Response) => {
      const serverError = this.errorMapper.mapToModel(errorResponse.json());

      if (serverError.code === VALIDATION_ERROR) {
        if (serverError.validationErrors) {
          for (const validationError of serverError.validationErrors) {
            const control = this.ngForm.controls[validationError.field];
            if (control) {
              control.setErrors(validationError.errors);
            }
          }
        }
        if (serverError.detail) {
          this.ngForm.form.setErrors([serverError.detail]);
        }
      }

    });
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.errorEmitterSubscription.unsubscribe();
  }

}
