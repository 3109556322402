import { PagedDto } from '../../pagination/dtos/paged.dto';

export class CustomerInfoPaged extends PagedDto<CustomerInfoDto> {

}

export interface CustomerInfoDto {
    id: number;
    credit_cards: CreditCardInfoDto[];
    created: string;
    modified: string;
    braintree_id: string;
    first_name: string;
    last_name: string;
}

export interface CreditCardInfoDto {
    id: number;
    created: string;
    braintree_id: string;
    last_4: string;
    card_type: string;
    cardholder_name: string;
    default: boolean;
    expiration_date: string;
    billing_address: {
        locality: string;
        street_address: string;
        region: string;
        country_name: string;
        postal_code: string;
    };
    customer: number;
}
