import { SharedModule } from '../shared.module';
import { NgModule } from '@angular/core';
import { PlanSwitcherComponent } from './plan-switcher.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
  ],
  declarations: [
    PlanSwitcherComponent
  ],
  entryComponents: [
  ],
  exports: [
    PlanSwitcherComponent
  ]
})
export class PlanSwitcherModule { }
