import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { CategoriesService} from '../../core';
import { Category } from '../../core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { PopupService } from '../../popup';

/**
 * Target popup component.
 */
@Component({
  selector: 'pb-category-selection-popup',
  templateUrl: './category-selection-popup.component.html',
  styleUrls: ['./category-selection-popup.component.scss']
})
export class CategorySelectionPopupComponent implements OnInit {

  private readonly businessTypeLabels = [
    'Business Category*',
    // 'Category Type',
    // 'Sub-Category Type'
  ];

  private readonly businessTypePlaceholders = [
    'Business Category',
    // 'Category Type',
    // 'Sub-Category Type'
  ];

  constructor(private categoriesService: CategoriesService,
    private mdDialogRef: MdDialogRef<CategorySelectionPopupComponent>,
    @Inject(MD_DIALOG_DATA) public data: any, private popupService: PopupService) {
  }

  /**
   * A list of business types.
   */
  public categories: Category[] = [];

  public controls: FormControl[] = [];

  public categoriesChild: Category[][] = [];

  public selectedCategories: object = {};

  public isBusy = false;

  private flatCategoriesCollection: Category[];

  private treeBuilded = false;

  public async ngOnInit(): Promise<void> {
    this.isBusy = true;
    const spinner = this.popupService.spinner();
    this.categories = await this.categoriesService.getTop();
    this.categoriesChild.push(this.categories);
    const control = new FormControl('', Validators.required);
    this.controls.push(control);
    spinner.close();
    this.isBusy = false;
  }

  public close() {
    this.mdDialogRef.close();
  }

  public addCategory() {
    const lastSelectedCategoryId = this.getLastSelectedCategoryId();
    if (lastSelectedCategoryId) {
      this.mdDialogRef.close(this.selectedCategories);
    } else {
      this.mdDialogRef.close();
    }
  }

  /**
   * Category changed event.
   * @param event Event on category change.
   */
  public categoryChanged(event: { event: any, index: number }) {
    if (event.event) {
      /**
       * Zero based level of categories tree.
       */
      const maxLevel = 0;

      if (!this.treeBuilded) {
        this.flatCategoriesCollection = this.buildTree(this.categories);
      }

      if (this.selectedCategoriesMore(event.index)) {
        this.cleanupCategories(event.index);
      }

      const category = this.flatCategoriesCollection.find(o => o.id === event.event);
      if (category && category.children && category.children.length > 0 && event.index !== maxLevel) {
        this.pushCategories(category);
      }
    }
  }

  /**
   * Pushing categories with controls to form.
   */
  private pushCategories(category: Category): void {
    const control = new FormControl('', Validators.required);
    if (!this.categoriesChild.includes(category.children)) {
      this.categoriesChild.push(category.children);
      this.controls.push(control);
    }
  }

  /**
   * Checking if controls more then selecting category.
   * @param index Index of selected category, zero-based.
   */
  private selectedCategoriesMore(index: number): boolean {
    return this.controls.length > index + 1;
  }

  /**
   * Cleaning controls, categories, and child categories list.
   * @param index Index of selected categories.
   */
  private cleanupCategories(index: number): void {
    const diff = Math.abs(index + 1 - this.categoriesChild.length);
    for (let i = diff; i > 0; i--) {
      this.controls.pop();
      this.categoriesChild.pop();
      this.cleanLastValue();
    }
  }

  /**
   * Cleaning last value from "model" object.
   */
  private cleanLastValue(): void {
    const index = this.getLastIndexInModel();
    delete this.selectedCategories[index];
  }

  /**
   * Returns last index of selected category.
   */
  private getLastIndexInModel(): number {
    let index = 0;
    for (const i in this.selectedCategories) {
      if (+i > index) {
        index = +i;
      }
    }

    return index;
  }

  /**
   * Returns last id of selected category.
   */
  private getLastSelectedCategoryId(): string {
    const index = this.getLastIndexInModel();
    return this.selectedCategories[index];
  }

  /**
   * Returns selected category ids.
   */
  private getSelectedCategoryIds(): string[] {
    const ids = [];
    // tslint:disable-next-line:forin
    for (const index in this.selectedCategories) {
      ids.push(this.selectedCategories[index]);
    }

    return ids;
  }

  /**
   * Building flat tree with parent references.
   */
  private buildTree(categories: Category[]): Category[] {
    const stack = categories.slice();
    const results = [];

    stack.forEach(category => {
      results.push(category);
    });

    while (stack.length > 0) {

      const item = stack.pop();

      if (item.children && item.children.length > 0) {
        item.children.forEach(category => {
          category.parent = item;
          stack.push(category);
          results.push(category);
        });
      }
    }

    this.treeBuilded = true;
    return results;
  }



  /**
   * Returns label by index of category.
   * @param index Index of category level.
   */
  private getBusinessTypeLabel(index: number) {
    if (this.businessTypeLabels[index]) {
      return this.businessTypeLabels[index];
    }

    return this.businessTypeLabels[this.businessTypeLabels.length - 1];
  }

  /**
   * Returns placeholder by index of category.
   * @param index Index of category level.
   */
  private getBusinessTypePlaceholder(index: number) {
    if (this.businessTypePlaceholders[index]) {
      return this.businessTypePlaceholders[index];
    }

    return this.businessTypePlaceholders[this.businessTypePlaceholders.length - 1];
  }

  public isValidSelectedCategories() {
    return Object.keys(this.selectedCategories).length <= 0;
  }
}
