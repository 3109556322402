import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import {checkBadWords, nonCodedPhoneMask, scrollToNotValid} from '../../../utils';
import {BrandService, CategoriesService, Category, CategoryMapper, Merchant, Schedule} from '../../core';
import { PopupService } from '../../popup';
import { CanStepDeactivate } from '../can-step-deactivate';
import { CampaignDataService } from '../services/campaign-data.service';
import { TargetMarketingVm as TargetMarketing } from './target-marketing';
import {MerchantApp} from "../../core/models/merchant-app";

/**
 * The second step of creating the coupon/promotion.
 */
@Component({
  selector: 'pb-target-marketing',
  templateUrl: './target-marketing.component.html',
  styleUrls: ['./target-marketing.component.scss']
})
export class TargetMarketingComponent
  implements OnChanges, OnInit, AfterViewInit, CanStepDeactivate {
  /**
   * Main model for this component.
   */
  @Input()
  public targetMarketing: TargetMarketing;

  @Input()
  public merchantKeywords: string = null;

  @Input()
  public userCountry: string = null;

  @Input()
  public merchant: Merchant;
  /**
   * Keywords.
   */
  public keywords$: BehaviorSubject<string>;

  /**
   * Schedule.
   */
  public schedule$: BehaviorSubject<Schedule>;


  /**
   * If 'true' can assign working hours for every day.
   */
  public isDefineHours = false;

  /**
   * Switch schedule between Basic and Advanced view.
   */
  public isBasicView = true;
  public showCategorySelector = false;

  /**
   * Default hours for the schedule when 'define hours' selected.
   */
  public defaultDefineHours: Schedule;

  public profanityList: string = null;
  public placeCountries = [
    { value: 'US', text: 'United States', checked: true, class: 'black', font: 12, dial_code: '+1' },
    { value: 'CA', text: 'Canada', checked: false, class: 'black', font: 12, dial_code: '+1' },
    { value: 'UK', text: 'United Kingdom', checked: false, class: 'black', font: 12, dial_code: '+44' },
    { value: 'NL', text: 'Netherlands', checked: false, class: 'black', font: 12, dial_code: '+31' },
    { value: 'DE', text: 'Germany', checked: false, class: 'black', font: 12, dial_code: '+49' },
    { value: 'AT', text: 'Austria', checked: false, class: 'black', font: 12, dial_code: '+43' },
  ];

  public callCountry;
  public textCountry;

  /**
   * @inheritdoc
   */
  @ViewChild(NgForm)
  public form: NgForm;
  public categories: { id: string; text: string }[];

  public selectedCategoryIds: string[];
  public brand: MerchantApp = new MerchantApp({id: 'gettinlocal', name: 'GettinLocal', categories: []});

  constructor(
    private dialog: MdDialog,
    private popupService: PopupService,
    private brandService: BrandService,
    private categoriesService: CategoriesService,
    private categoryMapper: CategoryMapper,
    private dataService: CampaignDataService
  ) {
    this.brandService.brand.subscribe((b) => {
      this.brand = b;
    });

    if (this.brand.categories.length > 0) {
      this.showCategorySelector = true;
    }
  }

  public get phoneMask() {
    return nonCodedPhoneMask();
  }

  /**
   *  Set the initial value of input targetMarketing.
   */
  public ngOnInit(): void {
    const schedule = this.targetMarketing.schedule;

    if (this.targetMarketing.keywords.length === 0) {
      this.targetMarketing.keywords = this.merchantKeywords;
    }
    this.keywords$ = new BehaviorSubject<string>(this.targetMarketing.keywords);
    this.schedule$ = new BehaviorSubject<Schedule>(schedule);

    const isDefineSchedule = this.isDefineScheduleView(schedule);

    if (isDefineSchedule) {
      this.switchScheduleView();
    }

    this.dataService.profanity_list.subscribe((list) => {
      this.profanityList = list;
    });

    this.scheduleModeChanged();
    this.callCountry = this.getCountryForCallingCode(this.targetMarketing.contactInformationPhoneCode);
    this.textCountry = this.getCountryForCallingCode(this.targetMarketing.contactInformationTextCode);

    this.categoriesService.getFlatLeveled(null).then(categories => {
      this.handleCategories(categories);
      this.handleBrandCategories();
    });
  }

  /**
   * @inheritdoc
   */
  public ngAfterViewInit(): void {
    // Need trigger a change detection cycle using setTimeout() before can extract a value from a control.
    setTimeout(() => {
      if (this.targetMarketing.errors.length > 0) {
        this.invalidateForm();
      }
    }, 0);
  }

  private invalidateForm(): void {
    const controls = this.form.controls;

    while (this.targetMarketing.errors.length > 0) {
      const { field, errors } = this.targetMarketing.errors.pop();

      controls[field].setErrors(errors);
    }

    this.canStepDeactivate();
  }

  /**
   * Set the States when receive places.
   * @param changes Input changes.
   */
  public ngOnChanges(changes: SimpleChanges): void {

  }

  /**
   * Define Schedule type.
   * @param schedule Campaign schedule.
   */
  private isDefineScheduleView(schedule: Schedule): boolean {
    const isDifferentStartTime = Object.keys(schedule).some(
      k => schedule[k].start !== schedule['monday'].start
    );
    const isDifferentEndTime = Object.keys(schedule).some(
      k => schedule[k].end !== schedule['monday'].end
    );

    return isDifferentStartTime && isDifferentEndTime;
  }

  /**
   * Change the type of schedule.
   */
  public switchScheduleView(): void {
    this.isBasicView = !this.isBasicView;
  }

  /**
   * Add keyword to the Model.
   * @param keywords Keywords.
   */
  public onKeywordsChange(keywords: string[]): void {
    this.targetMarketing.keywords = keywords.join();
    this.form.controls['keywords'].setErrors(null);
  }

  /**
   * Make the Define Hours editable.
   */
  public scheduleModeChanged(): void {
    this.isDefineHours = this.targetMarketing.isDefineHours === 'define';

    if (this.targetMarketing.isDefineHours === 'all') {
      this.targetMarketing.schedule = new Schedule();
    } else {
      this.targetMarketing.schedule = this.defaultDefineHours;
    }
  }

  /**
   * Called when a form is submitting, is now used to highlight invalid fields.
   * @param form Step form.
   */
  public validateForm(form: NgForm): boolean {
    return form.valid;
  }

  /**
   * When a valid form and valid range of the start date of the end campaign dates,
   * Allows you to move to the next step.
   */
  public canStepDeactivate(): boolean {
    this.form.onSubmit(null);

    const errors: any = [];

    const hasTermsAndConditionsTextBadWords = checkBadWords(this.targetMarketing.termsAndConditionsText, this.profanityList);
    if (hasTermsAndConditionsTextBadWords.length > 0) {
      const error = {
        field: 'termsAndConditionsText',
        errors: {
          detail: 'bad words',
          words: hasTermsAndConditionsTextBadWords,
        }
      };
      errors.push(error);
    } else {
      this.targetMarketing.errors = this.targetMarketing.errors.filter(error => error.field !== 'termsAndConditionsText');
    }

    if (this.selectedCategoryIds.length === 0) {
      this.popupService
        .info_sticky('Please select a category')
        .then(() => {
          scrollToNotValid();
        });
      return;
    }

    this.targetMarketing.interest = this.selectedCategoryIds || this.merchant.categoryIds;

    if (errors.length > 0) {
      this.targetMarketing.errors = errors;

      const controls = this.form.controls;

      while (this.targetMarketing.errors.length > 0) {
        const { field, errors } = this.targetMarketing.errors.pop();

        controls[field].setErrors(errors);
      }
    }

    // return this.dateRange.isValid && this.form.valid;
    return this.form.valid;
  }

  /**
   * Update the schedule in the model when the time changes.
   * @param schedule Campaign schedule.
   */
  public changeTime(schedule: Schedule): void {
    if (!this.defaultDefineHours && this.targetMarketing.schedule) {
      this.defaultDefineHours = schedule;
      return;
    }
    this.targetMarketing.schedule = schedule;
  }

  public phoneCodeChanged(value: string): void {
    this.targetMarketing.contactInformationPhoneCode = value;
  }

  public textCodeChanged(value: string): void {
    this.targetMarketing.contactInformationTextCode = value;
  }

  private getCountryForCallingCode(country_calling_code: string): string {
    const country = this.placeCountries.find(c => c.dial_code === country_calling_code);
    if (country) {
      return country.dial_code;
    }
    return '';
  }

  private handleCategories(categories: Category[]): void {
    this.categories = categories.map((category) => ({
      id: category.id,
      text: category.pluralName,
    }));

    /*this.selectedCategoryIds = this.categories
      .filter((category) => this.targetMarketing.interest.includes(category.id))
      .map((category) => category.id);*/
    this.selectedCategoryIds = this.targetMarketing.interest.length > 0 ?
      this.targetMarketing.interest : this.merchant.categoryIds;

    if (this.selectedCategoryIds.length === 0) {
      this.selectedCategoryIds = this.categories
        .filter((category) => this.merchant.categoryIds.includes(category.id))
        .map((category) => category.id);
    }
  }

  private handleBrandCategories() {
    if (this.brand.categories.length > 0) {
      const br_categories = this.brand.categories.map(categoryDto =>
        this.categoryMapper.mapToModel(categoryDto as any)
      );
      this.handleCategories(br_categories);
    }
  }

  public categoryChanged(event: any) {
    const cat = this.categories[event.target.selectedIndex - 1];
    this.selectedCategoryIds = [cat.id];
  }
}
