import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pb-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  constructor() { }

  @Input()
  public name: string;

  public ngOnInit() {
  }

}
