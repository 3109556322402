import { Component, Inject, OnInit } from "@angular/core";
import { MdDialogRef, MD_DIALOG_DATA } from "@angular/material";
import { MerchantService, Subscription } from "../../../core";
import {
  stateAbbreviations,
  canadaStateAbbreviations,
} from "./state-abbreviations";
import { SUBSCRIPTION_STATE } from "../../../core/models/subscription";
/**
 * Target popup component.
 */
@Component({
  selector: "pb-state-selection-popup",
  templateUrl: "./state-selection-popup.component.html",
  styleUrls: ["./state-selection-popup.component.scss"],
})
export class StateSelectionPopupComponent implements OnInit {
  public stateFee: number;
  public planType: string;
  public title: any;

  constructor(
    private merchantService: MerchantService,
    private mdDialogRef: MdDialogRef<StateSelectionPopupComponent>,
    @Inject(MD_DIALOG_DATA) public data: any
  ) {
    this.selectedStates = data.states;
    this.selectedView = data.tab;
    this.stateFee = data.fee;
    this.planType = data.plan_type;
    this.title = data.title;
  }

  public states = Object.keys(stateAbbreviations);
  public canadaStates = Object.keys(canadaStateAbbreviations);

  /**
   * A list of business types.
   */
  public selectedStates: string[] = [];

  public selectedView: string = "map";
  public selectedCountry: string = "us";
  public isBusy = false;

  public async ngOnInit(): Promise<void> {}

  public close() {
    this.mdDialogRef.close();
  }

  public addStates() {
    this.mdDialogRef.close(this.selectedStates);
  }

  public isValidselectedStates() {
    return Object.keys(this.selectedStates).length <= 0;
  }

  public stateSelectionChange($event): void {
    const selected = $event.target.value;
    const checked = $event.target.checked;

    if (checked) {
      if (!this.selectedStates.includes(selected)) {
        this.selectedStates.push(selected);
      }
    } else {
      this.selectedStates.splice(this.selectedStates.indexOf(selected), 1);
    }
  }

  public isSelected($state): boolean {
    return this.selectedStates.includes($state);
  }

  public stateSelected(selected): void {
    if (!this.selectedStates.includes(selected)) {
      this.selectedStates.push(selected);
    } else {
      this.selectedStates.splice(this.selectedStates.indexOf(selected), 1);
    }
  }

  public changeView(tab): void {
    this.selectedView = tab;
  }

  public changeCountry(tab): void {
    this.selectedCountry = tab;
  }

  public getStateName(state): string {
    return stateAbbreviations[state] || canadaStateAbbreviations[state];
  }

  public stateCost(state, p): string {
    if (p === 0 && this.planType === "premium") return "Free";
    else return "$ " + this.stateFee;
  }
}
