import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReportDashboardComponent } from './report-dashboard.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    ReportDashboardComponent
  ],
  exports: [
    ReportDashboardComponent
  ]
})
export class ReportDashboardModule { }
