import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import {CustomizationDto} from '../dtos/customization.dto';
import {Customization} from '../../../models/customization';

/**
 * State mapper.
 */
@Injectable()
export class CustomizationMapper implements Mapper<CustomizationDto, Customization> {
  /**
   * @inheritdoc
   * @param {CustomizationDto} dto Customization DTO.
   */
  public mapToModel(dto: CustomizationDto): Customization {
    return new Customization({
    });
  }
}
