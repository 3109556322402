<form #form="ngForm" name="campaignStyle" [class.form-submitted]="form.submitted">
  <h2 class="section-title">
    <div class="tooltip-title">
      Design your campaign
      <pb-info-tooltip description="" hint="A store front, product or service being sold."></pb-info-tooltip>
    </div>
  </h2>
  <label class="label">
  </label>

  <section class="container">
    <div>
      <span class="limit60" style="color: green;">
        Limit: 60 Characters
      </span>
      <div class="tooltip-title">
        <div class="global-caption">Provide a Headline &nbsp;<div style="font-size: 12px;">(brief description of your
            offer)
          </div>
        </div>
        <div class="global-required">*required</div>
        <pb-info-tooltip
          description="Make an Announcement that your business is now Open, Promote BOGO Tickets or 50% Discounts.  Create a headline that excites consumers to learn more about your campaign."
          hint="">
        </pb-info-tooltip>
      </div>
      <pb-input name="campainMessage" required label="" #campainMessage="ngModel"
        [(ngModel)]="campaignStyle.campaignMessage" maxlength="60" placeholder="Example: 20% Off All Accessories"
        class="image-label" pbTooltip>
        <pb-error-message [errors]="campainMessage.errors"></pb-error-message>
      </pb-input>
    </div>
    <div class="marketing-fact">
      <div class="box">
        <div class="heading">
          Helpful Marketing Fact:
        </div>
        <div class="description">
          Create a campaign description that ignites action. Use a strong call-to-action.
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <div>
      <div class="tooltip-title">
        <div class="global-caption">Tell the customer more about your offer.</div>
        <div class="global-required">*required</div>
        <pb-info-tooltip
          hint="Provide and overview of the campaign. Describe the highlights and the consumer experience. Let consumers learn more  about the campaign">
        </pb-info-tooltip>
      </div>
      <div>
        <pb-textarea name="dealDescription" label="" #dealDescription="ngModel"
          [(ngModel)]="campaignStyle.dealDescription" required
          placeholder="For example: Take advantage our deep discounts only available for a shorttime." note=""
          maxlength="800" class="full-width" rows="8" pbTooltip customElementQuery=".body">
        </pb-textarea>
        <pb-error-message [errors]="dealDescription.errors"></pb-error-message>
      </div>
    </div>
  </section>


  <section class="container">
    <div class="row">
      <div style="margin: 0 auto; margin-top: 0px; margin-top: 30px;">
        <div class="tooltip-title">
          <div class="global-caption">Upload Image or Video</div>
          <div class="global-required">*required</div>
          <pb-info-tooltip
            description="Upload a high quality image or video. 30 Seconds is ideal for consumer engagement.">
          </pb-info-tooltip>
        </div>
        <span *ngIf="mediaType === 'image'" class="note"><b>Image files supported – JPEG, PNG</b></span>
        <span *ngIf="mediaType === 'video'" class="note"><b>Video files supported – MP4/MOV (15-30 seconds preferred /
            60
            seconds
            max)</b></span>
      </div>
      <div class="col">
        <div class="marketing-fact">
          <div class="box">
            <div class="heading">
              Helpful Marketing Fact:
            </div>
            <div class="description">
              Video drives more customer engagement.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="global-subtitle">&nbsp;</div>
    <pb-toggle-selector [options]="mediaTypeSelector" [selected]="mediaType" (onSelect)="changeMediaType($event)">
    </pb-toggle-selector>
    <div class="row">
      <div class="basic-left">
        <div class="coupon-image">
          <br>
          <div *ngIf="mediaType === 'video'" class="browse-custom-image">
            <pb-browse-video-file [required]="!hasVideo()" [buttonWidth]="'240px'" [buttonText]="'Upload Video'"
              name=" video" [(ngModel)]="videoFile" (removed)="removeVideoClicked()" (uploaded)="videoUploaded($event)"
              [notes]="videoUploadInstructions">
            </pb-browse-video-file>
            <br>
            <pb-browse-file *ngIf="hasVideo()" [required]="!hasVideoThumb()" [canRemove]="false" [buttonWidth]="'240px'"
              [buttonText]="'Upload a separate Thumbnail image for this Video (optional)'" name="video_thumb"
              [(ngModel)]="videoThumbImage" (removed)="removeThumbnailClicked()" (uploaded)="videoThumbUploaded($event)"
              [notes]="videoThumbUploadInstructions">
            </pb-browse-file>
          </div>
          <div *ngIf="mediaType === 'image'" class="browse-custom-image">
            <pb-browse-file [required]="!hasCouponImage()" [buttonWidth]="'240px'" [buttonText]="'Upload Picture'"
              name="logo" [(ngModel)]="couponImage" (uploaded)="logoUploaded($event)" (removed)="removeLogoClicked()"
              [notes]="imageUploadInstructions">
            </pb-browse-file>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <div class="tooltip-title">
      <div class="global-caption">Reach as many people as possible.</div>
      <div class="global-required">*required</div>
    </div>
    <div style="font-weight: bold;">Select the distance and locations (markets) you want to reach.</div>
    <br>
    <div class="area">
      <div class="distance">
        <div class="tooltip-title">
          <div class="global-caption">Select marketing distance</div>
          <pb-info-tooltip
            description="Promote to consumers/guests  as close as 300 ft/.09km  up to  200 miles/ 322 km ( guests, stay-cationers, weekend warriors, residents, travelers, millennials).">
          </pb-info-tooltip>
        </div>
        <div class="select-wrapper">
          <select class="select" name="radius" [(ngModel)]="campaignStyle.radius" [compareWith]="compareOptions">
            <option *ngFor="let radius of radiuses" [ngValue]="radius" [innerHTML]="radius.value"></option>
          </select>
        </div>
      </div>
    </div>
    <div class="places-wrapper">
      <div class="places">
        <div class="states">
          <div class="places-header">
            <label class="checkbox-label">
              <input class="checkbox" type="checkbox" name="all-countries" [checked]="isSelectAllCountries"
                     (change)="setSelectAllCountries($event.target.checked)">
              <div class="pseudo-checkbox"></div>
              All
            </label>
          </div>
          <div class="state-container">
            <div class="state" *ngFor="let country of countries; let i = index;">
              <label class="checkbox-label" for="country-{{country.name}}">
                <input class="checkbox" type="checkbox" name="country" id="country-{{country.name}}"
                       (change)="countryChecked($event.target.checked, i)" [checked]="country.value">
                <div class="pseudo-checkbox"></div>
                {{country.name}}
              </label>
            </div>
          </div>
        </div>
        <div class="states">
          <div class="places-header">
            <label class="checkbox-label">
              <input class="checkbox" type="checkbox" name="all-states" [checked]="isSelectAllStates"
                     (change)="setSelectAllStates($event.target.checked)">
              <div class="pseudo-checkbox"></div>
              All
            </label>
          </div>
          <div class="state-container">
            <div class="state" *ngFor="let state of states; let i = index;">
              <label class="checkbox-label" for="state{{state.name}}">
                <input class="checkbox" type="checkbox" name="state" id="state{{state.name}}"
                       (change)="stateChecked($event.target.checked, i)" [checked]="state.value">
                <div class="pseudo-checkbox"></div>
                {{state.name}}
              </label>
            </div>
          </div>
        </div>
        <div class="locations">
          <div class="places-header">
            <label class="checkbox-label">
              <input class="checkbox" type="checkbox" name="all-locations" [checked]="isSelectAllLocations"
                     (change)="setSelectAllLocations($event.target.checked)">
              <div class="pseudo-checkbox"></div>
              All Locations
            </label>
          </div>
          <div class="location-container">
            <div class="location" *ngFor="let location of locations; let i = index;">
              <label class="checkbox-label">
                <input class="checkbox" type="checkbox" name="location" [checked]="location.selected"
                       (change)="locationChecked($event.target.checked, location.id)">
                <div class="pseudo-checkbox"></div>
                {{location.name}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 10px;">
      <button pb-button type="button" class="button"
              style="width: auto;"
              (click)="showEventLocationPopup()">Add New Location
      </button>
    </div>
  </section>

  <section class="container">
    <div class="dateRange">
      <div class="tooltip-title">
        <div class="global-caption">When would you like to run this campaign?</div>
        <div class="global-required">*required</div>
        <pb-info-tooltip description="Choose from 1 day up to 1 year."></pb-info-tooltip>
      </div>
      <div class="global-subtitle">Select the dates you would like this campaign to run.</div>
              <pb-date-range required #dateRange [(ngModel)]="campaignStyle.dateRange" name="dateRange" [endDateRequired]="true"
                [startDateRequired]="true" [noEndDate]="noEndDate" [maxInputDate]="maxDate" [minInputDate]="minDate"
                             (noEndDateChange)="noEndDateEvent($event)" (startDateChange)="startDateChanged($event)"></pb-date-range>
            </div>
          </section>

          <div *ngIf="!isFreePlan()">
            <section class="container">
              <div class="tooltip-title">
                <div class="global-caption">Featured Campaign Placement</div>
                <div class="global-required">*required</div>

                <pb-info-tooltip
                  description="Choose what state/province you want to have Featured/Premium placement of this campaign. Promote to different states/provinces to  consumers to visit your business and/or destination.">
                </pb-info-tooltip>
              </div>
              <ul style="margin-left: -10px;">
                <li>Select the states/provinces where you want this featured campaign displayed</li>
                <li>First state/province is Free</li>
                <li>Add additional States/Provinces</li>
                <li>Displayed On Multiple Featured Carousels</li>
              </ul>
              <!--div style="font-weight: bold;">**Your campaign will be featured in our email marketing blasts.**</div-->
      <div *ngIf="isFreePlan()">
        <pb-plan-switcher [premiumFee]="upgradeFee" (onPlanUpgrade)="upgradeToPremium()"
          title="One state is free with our Featured plan.">
        </pb-plan-switcher>
      </div>
      <div class="premium-states" *ngIf="!isFreePlan()">
        <div *ngIf="campaignStyle.premiumStates.length > 0" class="bold padder">
          Currently active states.
        </div>
        <div class="p-states">
          <div *ngFor="let state of campaignStyle.premiumStates">
            <li class="state-item">{{getStateName(state)}}</li>
          </div>
        </div>
      </div>
      <div class="state-error">{{stateErrorText}}</div>
      <br />
      <div class="row" style="justify-content: center;" *ngIf="canAddStates()">
        Selection Options:
      </div>
      <div class="row add-states" *ngIf="canAddStates()">
        <button pb-button [disabled]="!canAddStates()" class="button" type="button"
          (click)="openStateSelector('map', 'coupon')">
          <pb-icon name="#iconMap" class="icon"></pb-icon>Select from Map
        </button>
        <button pb-button [disabled]="!canAddStates()" class="button" type="button"
          (click)="openStateSelector('list', 'coupon')">
          <pb-icon name="#iconList" class="icon"></pb-icon>Select from List
        </button>
      </div>
    </section>
  </div>

  <section class="container">
    <div class="tooltip-title">
      <div class="global-caption">Does your campaign include a price change or deal?</div>
      <div class="global-optional">(optional)</div>
      <pb-info-tooltip
        description=" Let consumers know they are getting a great opportunity to save at your business. Show the Original Price and Reduced price on the campaign image.">
      </pb-info-tooltip>
    </div>
    <div class="global-subtitle">Let customers know if they are receiving a deal through this campaign.</div>
    <div style="display: flex;">
      <pb-radio label="ON" name="priceOn" key="priceOn" class="radio" [isChecked]="campaignStyle.isShowPrice"
        (change)="onShowPriceChange()"></pb-radio>
      <pb-radio label="OFF" name="priceOff" key="priceOff" class="radio" [isChecked]="!campaignStyle.isShowPrice"
        (change)="onShowPriceChange()"></pb-radio>
    </div>
    <br>
    <div *ngIf="campaignStyle.isShowPrice" class="optional-price">
      <div class="price-change-item">
        <div class="tooltip-title">
          <div class="global-caption">Price - Was</div>
        </div>
        <div class="prepend-label-price">
          <div class="input-header">
            <label>$</label>
          </div>
          <input class="custom-input" [formControl]="oldPrice" type="number" min="0" placeholder="0.00" />
        </div>
      </div>
      <div>
        <div class="tooltip-title">
          <div class="global-caption">Price - Is</div>
        </div>
        <div class="prepend-label-price">
          <div class="input-header">
            <label>$</label>
          </div>
          <input class="custom-input" [formControl]="newPrice" type="number" min="0" placeholder="0.00" />
        </div>
      </div>
    </div>
  </section>
</form>
