export class Invoice {
    public id: number;

    public status: string;

    public start: string;

    public end: string;

    public items: InvoiceItem[];

    public total: number;

    public subtotal: number;

    public discount: number;

    public transaction: {
        status: string,
        rejectReason: string,
        gatewayRejectionReason: string,
        creditCard: string
    };

    public merchant: {
        id: number,
        name: string,
        name_dba: string
    };

    public tax: number;

    public chargeDate: string;
    public campaign: {id: number, name: string, end: string};

    public getInvoiceItem(invoiceItemType: Unit.InvoiceItemType): InvoiceItem | null {
        if (!this.items || this.items.length === 0) {
            return null;
        }

        return this.items.find(i => i.itemType === invoiceItemType);
    }

    public get isValid(): boolean {
      return this.items && this.items.length > 0;
    }
}

export class InvoiceItem {
    public itemType: string;
    public count: number;
    public rate: string;
    public fees: string;

    /**
     * @deprecated
     */
    public getSubtotal(): number {
        if (!+this.rate || !+this.fees) {
            return 0;
        }

        return +this.count * +this.rate;
    }
}

export namespace Unit {
    export type InvoiceItemType = (
        'subscription' | 'coupons' | 'promotions' | 'messages'
    );
}
