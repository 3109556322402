import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { UserDto } from '../dtos/user.dto';

import divisionMapper from '../../merchant/mappers/division.mapper';
import { MerchantDto } from '../../merchant/dtos/merchant.dto';
import { User } from '../../../models/user';
import { Merchant } from '../../../models/merchant';

/**
 * Merchant mapper.
 */
@Injectable()
export class UserMapper implements Mapper<UserDto, User> {
  /**
   * @inheritdoc
   * @param {UserDto} dto User DTO.
   */
  public mapToModel(dto: UserDto): User {
    return {
      promo_code: dto.promo_code,
      id: dto.id,
      firstName: dto.first_name,
      lastName: dto.last_name,
      email: dto.email,
      isBetaUser: dto.is_beta_user,
      birthday: dto.birthday,
      gender: dto.gender,
      settings: dto.settings,
      avatar: dto.avatar,
      merchantId: dto.current_merchant,
      categories: dto.categories,
      emailConfirmed: dto.email_confirmed,
      dateJoined: dto.date_joined,
      lastLogin: dto.last_login,
      isHolding: dto.is_holding,
      divisions: divisionMapper(dto.divisions),
      originalMerchant: this.originalMerchantMapper(dto.merchant),
      currentMerchantId: dto.current_merchant,
      currentWebAppId: dto.current_webapp,
      merchantCreationComplete: dto.merchant ? dto.merchant.creation_completed : null,
    };
  }

  public originalMerchantMapper(merchantDto: MerchantDto): Merchant {
    if (merchantDto == null) {
      return;
    }

    const merchant = new Merchant();

    merchant.id = +merchantDto.id;
    merchant.personalId = merchantDto.merchant_cid;
    merchant.dbaName = merchantDto.merchant_dba_name;
    merchant.name = merchantDto.name;
    merchant.logo = merchantDto.logo;
    merchant.creationCompleted = merchantDto.creation_completed;
    merchant.contact = merchantDto.contact;

    return merchant;
  }
}
