/**
 * Address domain model.
 */
import {Category} from './category';
import {Customization} from './customization';
import {Theme} from './theme';

export class MerchantApp {
  public is_enabled: boolean;
  public id: string;
  public show_pricing: boolean;
  public plan_switchable: boolean;
  public allow_multiple_profiles: boolean;
  public payment_required: boolean;

  public default_plan: number;
  public category: Category;
  public event_category: Category;

  public title: string;
  public meta_description: string;
  public favicon: string;

  public name: string;
  public logo_image: string;
  public brand_login_path: string;

  public theme: Theme;
  public customization: Customization;
  public categories: Category[];

  /**
   * .ctor
   * @param {any} initObject Initial object of predefined field values.
   */
  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }

  public showKeywords(): boolean {
    return this.id === 'gettinlocal';
  }

  public showTitle(): boolean {
    return this.id === 'gettinlocal';
  }

  public showCovidPolicies(): boolean {
    return this.id === 'gettinlocal';
  }

  public isWhiteLabel(): boolean {
    return !(this.id === 'gettinlocal');
  }

  /*public customEvents(): boolean {
    return this.event_category.id;
  }*/
}
