import { CampaignStyleVm } from '../../campaign/campaign-style/campaign-style';
import { TargetMarketingVm } from '../../campaign/target-marketing/target-marketing';
import { RedemptionOptionsVm } from '../../campaign/redemption-options/redemption-options';
import { Plans } from './plans';
import { Subscription } from './subscription';

/**
 * Promotion model.
 */
export class Promotion {
  public campaignPlan: number;
  public subscription: Subscription;

  public promoCode: number;
  /**
   * First step of promotion.
   */
  public campaignStyle: CampaignStyleVm;

  /**
   * Second step of promotion.
   */
  public targetMarketing: TargetMarketingVm;

  /**
   * Third step of coupon.
   */
  public redemptionOptions?: RedemptionOptionsVm;

  /**
   * Init promotion.
   */
  constructor (data: Partial<Promotion>) {
    this.campaignStyle = data.campaignStyle;
    this.targetMarketing = data.targetMarketing;
    this.redemptionOptions = data.redemptionOptions;
  }
}
