import { ServerError } from '../../models/server-error';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import { CampaignDto } from './dtos/campaign.dto';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

import { TokenStorageService } from '../token-storage.service';
import { AuthService } from '../auth/auth.service';
import { TimeInclude } from '../time-inclusion.enum';
import { ServerErrorMapper } from '../error-handler/mappers/server-error.mapper';
import { ServerErrorDto } from '../error-handler/dtos/server-error.dto';
import { CampaignMapper } from './mappers/campaign.mapper';
import { Campaign } from './models/campaign';
import {EventMapper} from "./mappers/event.mapper";



const MERCHANT_URL_PART = 'merchants';
const COUPONS_URL_PART = 'coupons';
const PREMIUM_URL_PART = 'premium';
const EVENTS_URL_PART = 'events';

/**
 * Service for campaigns of Merchant.
 */
@Injectable()
export class CampaignsService {
    /**
    * .ctor
    */
    constructor(
        private http: Http,
        private tokenService: TokenStorageService,
        private userService: AuthService,
        private campaignMapper: CampaignMapper,
        private eventMapper: EventMapper,
        private serverErrorMapper: ServerErrorMapper
    ) {

    }

    /**
     * Returns collection of filtered Merchant's campaigns.
     * @param merchantId Id of merchant, may be null for retrieve using AuthService with request to remote endpoint.
     */
    public getCampaigns(
        startDate: Date,
        endDate: Date,
        merchantId: number,
        locationIds: string[] = null,
        timeInclude: TimeInclude = TimeInclude.None): Promise<Campaign[]> {
        let startDateString = moment(startDate).format('YYYY-MM-DD');
        let startDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD');
        let endDateString = moment(endDate).format('YYYY-MM-DD');

        let queryString = '';
        if (timeInclude === TimeInclude.None) {
            // tslint:disable-next-line:max-line-length
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/?start_lte=${endDateString}&end_gte=${startDateString}&ordering=start`;
        } else if (timeInclude === TimeInclude.End) {
            startDateString = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
            endDateString = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
            startDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
            // tslint:disable-next-line:max-line-length
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/?end_gte=${startDateString}&end_lte=${startDatePlus}&start_lte=${startDateString}`;
        } else if (timeInclude === TimeInclude.Start) {
            // tslint:disable-next-line:max-line-length
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/?start_gte=${startDateString}&start_lte=${startDatePlus}&end_gte=${startDateString}`;
        }

        if (locationIds && locationIds.length > 0) {
            // when we adding in js arrays, here is automatically adding ',' symbol after first and next elements in arrays.
            queryString += '&locations=' + locationIds;
        }

        return this.http.get(queryString, { headers: this.tokenService.baseHeaders })
            .map(r => r.json() as CampaignDto[])
            .map(r => r.map(i => this.campaignMapper.mapToModel(i)))
            .toPromise();
    }

  /**
   * Returns collection of filtered Merchant's campaigns.
   * @param merchantId Id of merchant, may be null for retrieve using AuthService with request to remote endpoint.
   */
  public getEvents(
    startDate: Date,
    endDate: Date,
    merchantId: number,
    locationIds: string[] = null,
    timeInclude: TimeInclude = TimeInclude.None): Promise<Campaign[]> {
    let startDateString = moment(startDate).format('YYYY-MM-DD');
    let startDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD');
    let endDateString = moment(endDate).format('YYYY-MM-DD');

    let queryString = '';
    if (timeInclude === TimeInclude.None) {
      // tslint:disable-next-line:max-line-length
      queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${EVENTS_URL_PART}/?start_lte=${endDateString}&end_gte=${startDateString}&ordering=-start`;
    } else if (timeInclude === TimeInclude.End) {
      startDateString = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
      endDateString = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
      startDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
      // tslint:disable-next-line:max-line-length
      queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${EVENTS_URL_PART}/?end_gte=${startDateString}&end_lte=${startDatePlus}&start_lte=${startDateString}`;
    } else if (timeInclude === TimeInclude.Start) {
      // tslint:disable-next-line:max-line-length
      queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${EVENTS_URL_PART}/?start_gte=${startDateString}&start_lte=${startDatePlus}&end_gte=${startDateString}`;
    }

    if (locationIds && locationIds.length > 0) {
      // when we adding in js arrays, here is automatically adding ',' symbol after first and next elements in arrays.
      queryString += '&locations=' + locationIds;
    }

    return this.http.get(queryString, { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as CampaignDto[])
      .map(r => r.map(i => this.eventMapper.mapToModel(i)))
      .toPromise();
  }

    /**
     * Returns collection of filtered Merchant's campaigns.
     * @param merchantId Id of merchant, may be null for retrieve using AuthService with request to remote endpoint.
     */
    public getPremiumCampaigns(
        startDate: Date,
        endDate: Date,
        merchantId: number,
        locationIds: string[] = null,
        timeInclude: TimeInclude = TimeInclude.None): Promise<Campaign[]> {
        let startDateString = moment(startDate).format('YYYY-MM-DD');
        let startDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD');
        let endDateString = moment(endDate).format('YYYY-MM-DD');

        let queryString = '';
        if (timeInclude === TimeInclude.None) {
            // tslint:disable-next-line:max-line-length
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PREMIUM_URL_PART}/?start_lte=${endDateString}&end_gte=${startDateString}`;
        } else if (timeInclude === TimeInclude.End) {
            startDateString = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
            endDateString = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
            startDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
            // tslint:disable-next-line:max-line-length
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PREMIUM_URL_PART}/?end_gte=${startDateString}&end_lte=${startDatePlus}&start_lte=${startDateString}`;
        } else if (timeInclude === TimeInclude.Start) {
            // tslint:disable-next-line:max-line-length
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PREMIUM_URL_PART}/?start_gte=${startDateString}&start_lte=${startDatePlus}&end_gte=${startDateString}`;
        }

        if (locationIds && locationIds.length > 0) {
            // when we adding in js arrays, here is automatically adding ',' symbol after first and next elements in arrays.
            queryString += '&locations=' + locationIds;
        }

        return this.http.get(queryString, { headers: this.tokenService.baseHeaders })
            .map(r => r.json() as CampaignDto[])
            .map(r => r.map(i => this.campaignMapper.mapToModel(i)))
            .toPromise();
    }

    public addCampaign(campaign: Campaign): Promise<Campaign | ServerError> {
        const dto = this.campaignMapper.mapToDto(campaign);
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${dto.merchantId}/${COUPONS_URL_PART}/`, dto, { headers: this.tokenService.baseHeaders })
            .map(r => {
                const campaignResult = new Campaign();
                Object.assign(campaignResult, r.json());
                return campaignResult;
            })
            .catch(err => {
                const errorDto = err.json() as ServerErrorDto;
                return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
            })
            .toPromise();
    }

    /**
     * Patching company.
     * @param campaign campaign which will be patched.
     */
    public patchCampaign(campaign: Campaign): Promise<Campaign | ServerError> {
        const dto = this.campaignMapper.mapToDto(campaign);

        // tslint:disable-next-line:max-line-length
        return this.http.patch(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${dto.merchantId}/${COUPONS_URL_PART}/${campaign.id}/`, dto, { headers: this.tokenService.baseHeaders })
            .map(r => {
                const campaignResult = new Campaign();
                Object.assign(campaignResult, r.json());
                return campaignResult;
            })
            .catch(error => {
                const errorDto = error.json() as ServerErrorDto;
                return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
            })
            .toPromise();
    }

    /**
     * Uploading logo to server.
     * @param logo Logo for campaign.
     * @param merchantId Id of merchant for campaign.
     * @param campaignId Id of campaign for update logo.
     */
    public updateLogo(logo: Blob, merchantId: number, campaignId: number): Promise<{ logo: string }> {
        const formData = new FormData();
        formData.append('logo', logo);

        return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${campaignId}/upload/`,
            formData, { headers: this.tokenService.baseHeaders })
            .map(r => r.json() as { logo: string })
            .toPromise();
    }

    /**
     * Deleting logo from merchant's coupon.
     * @param merchantId Id of merchant for coupon.
     * @param couponId Id of coupon for merchant.
     */
    public deleteLogo(merchantId: number, couponId: number): Promise<void> {
        return this.http.delete(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${couponId}/clear_logo/`,
            { headers: this.tokenService.baseHeaders })
            .map(r => null)
            .toPromise();
    }

     /**
   * Retrieving merchant's images.
   * @param merchantId Id of merchant.
   */
  public getCouponSubscriptions(merchantId: number, couponId: number): Promise<void> {
    const url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${couponId}/subscriptions/`;

    return this.http.get(url, { headers: this.tokenService.baseHeaders })
      .map(r => r.json())
      .map(r => {
        return r;
      })
      .toPromise();
  }
}
