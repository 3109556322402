import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { LoginPageCombinedComponent } from './login-page-combined.component';
import { RegistrationModule } from '../registration/registration.module';
import { LoginPageModule } from '../login-page/login-page.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    LoginPageModule,
    RegistrationModule
  ],
  declarations: [
    LoginPageCombinedComponent
  ],
  exports: [
    LoginPageCombinedComponent
  ]
})
export class LoginPageCombinedModule { }
