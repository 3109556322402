<aside class="sticky" *ngIf="user$ | async as user">
  <div class="header" [ngClass]="{'bg_white': this.brand.isWhiteLabel()}">
    <img *ngIf="brand.isWhiteLabel()" width="100%" height="100%" src="{{ brand.logo_image }}">
    <img *ngIf="brand.isWhiteLabel()" style="padding-left: 5px; padding-right: 5px; scale: 90%;" width="100%%"
         height="100%"
         src="./assets/images/brands/Powered_by_GL_New.png">
    <div *ngIf="!brand.isWhiteLabel()" style="height: 40px;">
      <pb-icon name="#iconCampaign" class="icon"></pb-icon>
    </div>
  </div>
  <pb-merchant-info *ngIf="user.originalMerchant" [isHolding]="user.isHolding" [rectLogo]="isLogoRect"
                    [divisionRectLogos]="divisionRectLogos" [merchant]="user.originalMerchant"
                    [divisions]="user.divisions"
                    [currentMerchantId]="user.currentMerchantId"
                    (selectedMerchantChange)="onChangeCurrentMerchant($event)"
                    [showDivisions]="brand.allow_multiple_profiles">
  </pb-merchant-info>
  <button *ngIf="user.isHolding && brand.allow_multiple_profiles" class="add-division"
          (click)="addNewBusiness(user.originalMerchant.id)">Add New Account
  </button>

  <div style="background: white; height: 0.1px; width: 100%;"></div>
  <pb-webapp-info *ngIf="currentWebApp"
                  [merchant]="user.originalMerchant"
                  [webapps]="user?.merchant?.webapps"
                  [currentWebAppId]="currentWebApp.id"
                  (selectedWebAppChange)="onChangeCurrentWebApp($event)">
  </pb-webapp-info>
  <div *ngIf="currentWebApp" style="background: white; height: 0.1px; width: 100%;"></div>

  <div class="body" *ngIf="user.merchant as merchant">
    <button class="add-division">App Control Panel
    </button>
    <ul class="menu">
      <div *ngIf="merchant.creationCompleted && currentWebApp">
        <li pb-webapp-sidebar-menu-item caption="Manage Ad/Listing Visibility" [hasMenu]="true"
            *ngIf="currentWebApp.enable_priority_ads">
          <ul class="menu">
            <li pb-webapp-sidebar-menu-item caption="Priority Positioned Ads"
                route="/webapps/{{currentWebApp.id}}/priority-coupons" [className]="'app-link-color'"
                [class.not-finished]="!merchant.creationCompleted"></li>
            <li pb-webapp-sidebar-menu-item caption="Hide or Display Listing"
                route="/webapps/{{currentWebApp.id}}/hide-coupons" [className]="'app-link-color'"
                [class.not-finished]="!merchant.creationCompleted"></li>
          </ul>
        </li>

        <li pb-webapp-sidebar-menu-item caption="Pop-up(s)" [hasMenu]="true"
            *ngIf="currentWebApp.enable_loyalty_popup_edit || currentWebApp.enable_data_popup_edit">
          <ul class="menu" *ngIf="currentWebApp.enable_loyalty_popup_edit">
            <li pb-webapp-sidebar-menu-item caption="Create Pop-up" [className]="'app-link-color'"
                route="/webapps/{{currentWebApp.id}}/loyalty-popups"></li>
          </ul>

          <ul class="menu" *ngIf="currentWebApp.enable_data_popup_edit">
            <li pb-webapp-sidebar-menu-item caption="Request User Data"
                route="/webapps/{{currentWebApp.id}}/data-popups"
                [className]="'app-link-color'"></li>
          </ul>
          <ul class="menu" *ngIf="currentWebApp.enable_loyalty_popup_edit">
            <li pb-webapp-sidebar-menu-item caption="Manage Pop-Up(s)" route="/webapps/{{currentWebApp.id}}/configure"
                [className]="'app-link-color'"></li>
          </ul>
        </li>

        <li pb-menu-item caption="Scan / Download Reports" [hasMenu]="true"
            *ngIf="currentWebApp.enable_scan_reports">
          <ul class="menu" *ngIf="currentWebApp.enable_scan_reports">
            <li pb-menu-item caption="Create Report" route="/reports/{{currentWebApp.id}}/scan-reports/"
                [class.not-finished]="!merchant.creationCompleted">
            </li>
          </ul>
        </li>
      </div>
      <li pb-menu-item caption="Campaign Builder" [hasMenu]="true">
        <ul class="menu">
          <li *ngIf="brand.id === 'gettinlocal';else brand_logo_component" pb-menu-item caption="Create Campaign"
              route="/create_campaign"
              [class.not-finished]="!merchant.creationCompleted"></li>
          <ng-template #brand_logo_component>
            <li pb-menu-item caption="Create Campaign" route="/campaign"
                [class.not-finished]="!merchant.creationCompleted"></li>
          </ng-template>
          <li pb-menu-item caption="Create Event" route="/events"
              [class.not-finished]="!merchant.creationCompleted"></li>
        </ul>
      </li>

      <li pb-menu-item caption="Dashboard" [hasMenu]="true" route="/dashboard">
        <ul class="menu">
          <li pb-menu-item caption="Dashboard" route="/dashboard" [class.not-finished]="!merchant.creationCompleted">
          </li>
        </ul>
      </li>

      <li pb-menu-item caption="Message Center" [hasMenu]="true">
        <ul class="menu">
          <li *ngIf="showAutoMessages === true" pb-menu-item caption="Push Alert - New Followers"
              route="/auto-messages" [class.not-finished]="!merchant.creationCompleted"></li>
          <li pb-menu-item caption="Message to All Followers" route="/new-message"
              [class.not-finished]="!merchant.creationCompleted"></li>
        </ul>
      </li>

      <li pb-menu-item caption="My Account" [hasMenu]="true">
        <ul class="menu">
          <li pb-menu-item caption="Company Profile" route="/profile"></li>
          <li pb-menu-item caption="Locations" route="/locations" [class.not-finished]="!merchant.creationCompleted">
          </li>
        </ul>
      </li>

      <li pb-menu-item caption="Account Administration" [hasMenu]="true">
        <ul class="menu">
          <li pb-menu-item caption="User Login Information" route="/login-information"
              [class.not-finished]="!merchant.creationCompleted"></li>
          <li pb-menu-item caption="Suspend/De-activate" *ngIf="merchant.isActive"
              (click)="changeStatusOfMerchant(false)"></li>
          <li pb-menu-item caption="Reporting" route="/reports" [class.not-finished]="!merchant.creationCompleted"></li>
          <li pb-menu-item caption="Billing Information" route="/billing"
              [class.not-finished]="!merchant.creationCompleted"></li>
          <li pb-menu-item caption="Current Invoice" route="/invoices"
              [class.not-finished]="!merchant.creationCompleted "></li>
        </ul>
      </li>

      <li pb-menu-item caption="Logged in as" [hasMenu]="true">
        <ul class="menu">
          <li pb-menu-item route="/login-information" caption="{{ userFullName }}"></li>
          <li pb-menu-item caption="Log Out" (click)="logOut()"></li>
        </ul>
      </li>
    </ul>
  </div>
</aside>
