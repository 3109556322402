import { Component, Inject, OnInit } from "@angular/core";
import { MdDialogRef, MD_DIALOG_DATA } from "@angular/material";
import { Merchant } from "../../core";
import {CampaignPublishPopupResult} from "./campaign-publish-popup-result.enum";
/**
 * Target popup component.
 */
@Component({
  selector: "pb-event-publish-popup",
  templateUrl: "./campaign-publish-hotel-popup.component.html",
  styleUrls: ["./campaign-publish-hotel-popup.component.scss"],
})
export class CampaignPublishSuccessHotelPopupComponent implements OnInit {
  public title: string;
  public message: string;
  public subtitle: string;
  public merchant: Merchant;
  public incompleteProfileItems: any[] = [];
  public onItemClick: any = null;
  public incompleteItemsTitle: string;

  /**
   * Result from popup.
   */
  public popupResult = CampaignPublishPopupResult;

  constructor(
    private mdDialogRef: MdDialogRef<CampaignPublishSuccessHotelPopupComponent>,
    @Inject(MD_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.message = data.message;
    this.subtitle = data.subtitle;
    this.incompleteProfileItems = data.incompleteList;
    this.incompleteItemsTitle = data.incompleteItemsTitle;
    this.onItemClick = data.onItemClick;
  }

  public incompleItemClicked(item: string) {
    this.mdDialogRef.close();
    this.onItemClick(item);
  }

  public async ngOnInit(): Promise<void> {}

  public close() {
    this.mdDialogRef.close();
  }
}
