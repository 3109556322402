import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ViewInvoiceComponent } from './view-invoice.component';
import { SideComponent } from './side/side.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    ViewInvoiceComponent,
    SideComponent
  ],
  exports: [
    ViewInvoiceComponent
  ]
})
export class ViewInvoiceModule { }
