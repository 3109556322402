import { OnInit, Component, Inject, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorHandlerService, AuthService, ServerError} from '../core';
import { PopupService } from '../popup';

/**
 * Page component for Merchant's activities.
 */
@Component({
  selector: 'pb-login-information',
  templateUrl: './login-information.component.html',
  styleUrls: ['./login-information.component.scss']
})
export class LoginInformationComponent implements OnInit {

  constructor(
    @Inject(forwardRef(() => AuthService))  private authService: AuthService,
    @Inject(forwardRef(() => PopupService)) private popupService: PopupService,
    private router: Router,
    @Inject(forwardRef(() => ErrorHandlerService)) private errorHandlerService: ErrorHandlerService
  ) { }

  public password: string;

  public passwordRepeat: string;

  public newEmail: string;

  public firstName: string;

  public lastName: string;
  
  public ngOnInit(): void {
    this.authService.me()
      .then(result => {
        this.firstName = result.firstName;
        this.lastName = result.lastName;
      });
  }

  private handleServerError(serverError: ServerError, form: NgForm) {
    const map = {
      'new_password1': 'password',
      'new_password2': 'password-repeat',
      'email': 'email',
    };

    this.errorHandlerService.invalidateForm(form, map, serverError);
  }

  public async onUsernameSubmit(form: NgForm): Promise<void> {
    const spinner = this.popupService.spinner();
    const response = await this.authService.changeUserName(this.firstName, this.lastName);
    spinner.close();
    let valid = true;
    if (response instanceof ServerError) {
      this.handleServerError(response, form);
      valid = false;
    }
    if (valid) {
      await this.popupService.info('Your name has been changed successfully.');
    }
  }

  public async onPasswordSubmit(form: NgForm): Promise<void> {
    const spinner = this.popupService.spinner();
    const response = await this.authService.changePassword(this.password, this.passwordRepeat);
    spinner.close();
    let valid = true;
    if (response instanceof ServerError) {
      this.handleServerError(response, form);
      valid = false;
    }
    if (valid) {
      await this.popupService.info('Your password has been changed successfully. Please re-login with your new credentials.');
      await this.authService.logout();
      await this.router.navigateByUrl('/login');
    }
  }

  public async onEmailSubmit(form: NgForm): Promise<void> {
    const spinner = this.popupService.spinner();
    const response = await this.authService.requestChangeEmail(this.newEmail);
    spinner.close();
    let valid = true;
    if (response instanceof ServerError) {
      if (response.validationErrors) {
        this.handleServerError(response, form);
      } else {
        await this.popupService.info(response.detail);
      }
      valid = false;
    }
    if (valid) {
      await this.popupService.info('We have sent a confirmation link to your new email. ' +
        'If you didn\'t find an email, make sure you checked your junk mail/spam folder.');
    }
  }
}
