import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

import { environment } from "../../../../environments/environment";
import {checkImageMimeType} from "../../../../utils/others";

/**
 * Service for work with images.
 */
@Injectable()
export class ImagesService {
  /**
   * @constructor
   */
  public constructor(private http: HttpClient) {}

  /**
   * Add image for campaign.
   * @param logo Campaign logo.
   * @param image Campaign image.
   * @param merchantId Merchant ID.
   * @param campaignId Campaign ID.
   * @param eventType Type of the campaign.
   */
  public uploadCampaignImages(
    logo: Blob,
    image: Blob,
    in_store_image: Blob,
    merchantId: number,
    campaignId: number,
    eventType: string
  ): Observable<void> {
    const formData = new FormData();
    if (logo) {
      const imgExt = checkImageMimeType(image);
      if (imgExt === "unknown") {
        return Observable.throw("Unknown image type");
      }
      formData.append("logo", logo, `logo.${imgExt}`);
    }
    if (image) {
      const imgExt = checkImageMimeType(image);
      if (imgExt === "unknown") {
        return Observable.throw("Unknown image type");
      }
      formData.append("image", image, `image.${imgExt}`);
    }
    if (in_store_image) {
      const imgExt = checkImageMimeType(image);
      if (imgExt === "unknown") {
        return Observable.throw("Unknown image type");
      }
      formData.append("in_store_image", in_store_image, `in_store_image.${imgExt}`);
    }

    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/${eventType}/${campaignId}/upload/`;

    return this.http.post<void>(urlPath, formData);
  }

  /**
   * Add image for barcode/qrcode.
   * @param logo Campaign logo.
   * @param image Campaign image.
   * @param merchantId Merchant ID.
   * @param campaignId Campaign ID.
   * @param eventType Type of the campaign.
   */
  public uploadInStoreImage(
    in_store_image: Blob,
    merchantId: number,
    campaignId: number,
    eventType: string
  ): Observable<void> {
    const formData = new FormData();
    if (in_store_image) {
      const imgExt = checkImageMimeType(in_store_image);
      if (imgExt === "unknown") {
        return Observable.throw("Unknown image type");
      }
      formData.append("in_store_image", in_store_image, `in_store_image.${imgExt}`);
    }

    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/${eventType}/${campaignId}/code_upload/`;

    return this.http.post<void>(urlPath, formData);
  }

  public deleteInStoreImage(
    merchantId: number,
    campaignId: number,
    eventType: string,
    body: object
  ): Observable<any> {
    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/${eventType}/${campaignId}/delete_code/`;
    return this.http.request("delete", urlPath, { body });
  }

  public uploadCampaignImage(
    image: Blob,
    merchantId: number
  ): Observable<HttpEvent<any>> {
    const formData = new FormData();
    if (image) {
      const imgExt = checkImageMimeType(image);
      if (imgExt === "unknown") {
        return Observable.throw("Unknown image type");
      }
      formData.append("image", image, `campaign_image.${imgExt}`);
    }

    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/upload_image/`;
    return this.http.post<HttpEvent<any>>(urlPath, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  public uploadCampaignVideo(
    video: File,
    merchantId: number
  ): Observable<HttpEvent<any>> {
    const formData = new FormData();
    if (video) {
      formData.append("video", video);
    }

    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/upload_video/`;
    return this.http.post<HttpEvent<any>>(urlPath, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  public uploadCampaignVideoThumbnail(
    thumb: Blob,
    merchantId: number,
    videoId: number
  ): Promise<any> {
    const formData = new FormData();
    if (thumb) {
      formData.append("video_thumb", thumb);
    }
    formData.set("id", videoId + "");

    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/video/`;
    return this.http.post<void>(urlPath, formData).toPromise();
  }

  public deleteCampaignImages(
    merchantId: number,
    campaignId: number,
    eventType: string,
    body: object
  ): Observable<any> {
    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/${eventType}/${campaignId}/delete_images/`;
    return this.http.request("delete", urlPath, { body });
  }

  public deleteQRCodeImage(
    merchantId: number,
    campaignId: number,
    eventType: string
  ): Observable<any> {
    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/${eventType}/${campaignId}/delete_images/`;
    const body = {
      code: true,
    };

    return this.http.request("delete", urlPath, { body });
  }
}
