import { SharedModule } from '../shared.module';
import { NgModule } from '@angular/core';
import { PricingComponent } from './pricing.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PricingComponent
  ],
  entryComponents: [
  ],
  exports: [
    PricingComponent
  ]
})
export class PricingModule { }
