import { MerchantsCoupon } from '../services/coupons/models/merchants-coupon';


export class Report {
    public id: number;
    public start: string;

    public end: string;

    public document: string;

    public reportDate: string;

    public name: string;

    public places: string[];

    public data: ReportData[];
}

export class ReportData {

    public merchantMessageCount: number;

    public activePromotionsCount: number;

    public name: string;

    public activeCouponCount: number;

    public year: number;

    public redemptions: number;

    public nameDba: string;

    public coupons: MerchantsCoupon[];
}



export class SignupReport {
  public id: number;
  public status: string;
  public start: string;

  public end: string;

  public document: string;

  public app: string;

  public name: string;
}
