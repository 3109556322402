<pb-breadcrumbs *ngIf="promotion" [items]="['Activities', promotion.name]"></pb-breadcrumbs>

<header pb-page-header>
  <div left>
    <a class="row pointer" *ngIf="promotion" (click)="returnBack($event)">
      <pb-icon name="#iconBackArrow" class="back-arrow"></pb-icon>{{ promotion.name }}
    </a>
  </div>
  <div right>
    <div class="row centered">
      <button class="button-link" *ngIf="isAbleToEnd" (click)="openPopup($event)">End Promotion</button>
      <button class="button-link" *ngIf="isAbleToEdit" (click)="editPromotion($event)">Edit</button>
      <button pb-button class="button" (click)="sendMessage($event)" small>Send a Message</button>
    </div>
  </div>
</header>

<section class="auto-height" pb-section *ngIf="promotion" [caption]="promotion.name">
  <div body>
    <div class="row">
      <div class="left">
        <pb-thumbnail *ngIf="thumbnail" name="Promotion Thumbnail" [showSize]="false" [width]="335" [height]="280" [imageBase64]="thumbnail"
          [isImageUploaded]="true">
        </pb-thumbnail>
        <pb-activity-info *ngIf="thumbnail" [activity]="activity" [company]="company" style="width: 335px;"></pb-activity-info>
        <br *ngIf="thumbnail">
        <div class="global-caption">
          Promotion Image
        </div>
        <div class="image-container" *ngIf="promotion">
          <img [src]="image" alt="" class="image">
        </div>
        <pb-activity-info [activity]="activity" [company]="company"></pb-activity-info>
      </div>
      <div class="right">
        <div class="right">
          <div class="text-block">
            <div class="global-caption">
              Coupon Runs
            </div>
            <div class="text">
              {{ dateRange }}
              <br>
              <br>
              <div *ngFor="let time of operationHours">
                <span class="day-of-week">{{time.day}}</span><span class="time">{{time.time}}</span>
                <br>
              </div>
            </div>
          </div>
          <div class="text-block" *ngIf="keywords && keywords.length > 0">
            <div class="global-caption">
              Keywords (optional)
            </div>
            <div class="text">
              <span *ngFor="let keyword of keywords; let i = index">
                {{keyword}}<span *ngIf="i !== keywords.length - 1">, </span>
              </span>
            </div>
          </div>
          <div class="text-block" *ngIf="locations && locations.length > 0">
            <div class="global-caption">
              Locations Valid ({{locations.length}})
            </div>
            <div class="text">
                <ul class="locations-list">
                    <li *ngFor="let location of locations">
                          {{location.name}} ({{location.address.street}})
                    </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
