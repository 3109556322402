import { CouponMapper } from '../../coupons/mappers/coupon.mapper';
import { Mapper } from '../../abstract/mapper';
import {ReportDto, SignupReportDto} from '../dtos/report.dto';

import { Injectable } from '@angular/core';
import {Report, ReportData, SignupReport} from '../../../models/report';

/**
 * Mapper for Merchant's messages.
 */
@Injectable()
export class ReportMapper implements Mapper<ReportDto, Report> {

    constructor(
        private couponMapper: CouponMapper
    ) { }

    public mapToModel(dto: ReportDto): Report {
        const model: Report = {
            id: dto.id,
            start: dto.start,
            end: dto.end,
            name: dto.name,
            document: dto.document,
            places: dto.places,
            reportDate: dto.report_date,
            data: null
        };

        if (dto.data && dto.data.length > 0) {
            model.data = [];
            dto.data.forEach(data => {

                const modelData = new ReportData();
                if (data.active_coupon_count) {
                    modelData.activeCouponCount = data.active_coupon_count;
                }
                if (data.active_promotions_count) {
                    modelData.activePromotionsCount = data.active_promotions_count;
                }
                if (data.coupons) {
                    modelData.coupons = data.coupons.map(o => {
                        return this.couponMapper.mapToModel(o);
                    });
                }
                if (data.merchant_message_count) {
                    modelData.merchantMessageCount = data.merchant_message_count;
                }
                if (data.name) {
                    modelData.name = data.name;
                }
                if (data.nameDba) {
                    modelData.nameDba = data.nameDba;
                }
                if (data.redemptions) {
                    modelData.redemptions = data.redemptions;
                }
                if (data.year) {
                    modelData.year = data.year;
                }

                model.data.push(modelData);
            });

            return model;
        }
    }

  public mapToSignupReportModel(dto: SignupReportDto) {
    const model: SignupReport = {
      status: dto.status,
      app: dto.app,
      id: dto.id,
      start: dto.start,
      end: dto.end,
      name: dto.name,
      document: dto.document
    };
    return model;
  }
}
