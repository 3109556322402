import {Component, Inject, OnInit} from '@angular/core';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';

import {AuthService, Category, PlaceService} from '../../core';
import {WebappService} from '../../core/services/webapp/webapp.service';


/**
 * Locations page component.
 */
@Component({
  selector: 'pb-coupon-settings-page',
  templateUrl: './coupon-settings-page.component.html',
  styleUrls: ['./coupon-settings-page.component.scss']
})
export class CouponSettingsPageComponent implements OnInit {

  merchantId: number;
  webappId: number;
  couponId: number;
  couponName: string;
  hideOnAll: boolean = false;
  allPrioritized: boolean = false;
  allHidden: boolean = false;
  settings: any[] = [];
  webappFilters: Category[] = [];

  /**
   * .ctor
   * @param DATA
   * @param mdDialogRef
   * @param {AuthService} authService Auth service.
   * @param {PlaceService} placeService Place service.
   * @param webappService
   */
  constructor(
    @Inject(MD_DIALOG_DATA) public DATA: any,
    private mdDialogRef: MdDialogRef<CouponSettingsPageComponent>,
    private authService: AuthService,
    private placeService: PlaceService,
    private webappService: WebappService,
  ) {
    this.webappFilters = DATA.webappFilters;
    this.merchantId = DATA.merchantId;
    this.webappId = DATA.webappId;
    this.couponId = DATA.couponId;
    this.couponName = DATA.couponName;
  }

  /**
   * @inheritdoc
   */
  async ngOnInit(): Promise<void> {
    this.settings = await this.webappService.getCouponSettingsForApp(this.webappId, this.couponId);

    this.hideOnAll = this.settings.some(item => item.hide_all);

    this.settings = this.webappFilters.map(filter => {
      const setting = this.settings.find(s => s.category_id === filter.id);
      return {
        ...filter,
        prioritized: setting ? setting.order > 0 : false,
        order: setting ? setting.order : null,
        hide: setting ? setting.order === 0 : false
      };
    });
  }

  toggleAll() {
    this.allPrioritized = !this.allPrioritized;
    this.settings.forEach(item => item.prioritized = this.allPrioritized);
  }

  toggleAllHidden() {
    this.allHidden = !this.allHidden;
    this.settings.forEach(item => {
      if (this.allHidden) {
        item.order = 0;
      } else if (item.order === 0) {
        item.order = 1; // Set to a default value when unhiding
      }
      item.hide = this.allHidden;
    });
  }

  toggleItem() {
    this.allPrioritized = this.settings.every(item => item.prioritized);
  }

  toggleHideItem(item) {
    if (item.order === 0) {
      item.order = 1; // Set to a default value when unhiding
      item.hide = false;
    } else {
      item.order = 0;
      item.hide = true;
    }
  }

  toggleHideOnAll() {
    this.hideOnAll = !this.hideOnAll;
    if(!this.hideOnAll) {
      this.webappService.hideAllCategoriesForCoupon(this.webappId, this.couponId).then(
        () => {
          this.mdDialogRef.close(this.settings);
        }
      );
    }
  }

  saveChanges() {

    if (this.hideOnAll) {

      this.webappService.hideAllCategoriesForCoupon(this.webappId, this.couponId).then(
        () => {
          console.log('All categories hidden');
          this.mdDialogRef.close(this.settings);
        }
      );
    } else {

      const updatedData = this.settings
        // .filter(item => item.order !== null)
        .map(item => ({
          category_id: item.id,
          order: item.order,
          prioritized: item.prioritized,
          hide: item.hide
        }));
      this.webappService.updateCouponSettingsForApp(this.webappId, this.couponId, updatedData).then(
        () => {
          console.log('Settings updated');
          this.mdDialogRef.close(updatedData);
        }
      );
    }
  }

  close() {
    this.mdDialogRef.close();
  }

}

