export class CustomerInfo {
    public id: number;
    public creditCards: CreditCardInfo[];
    public created: string;
    public modified: string;
    public braintreeId: string;
    public firstName: string;
    public lastName: string;
}

export class CreditCardInfo {
    public id: number;
    public created: string;
    public braintreeId: string;
    public last4: string;
    public cardType: string;
    public cardholderName: string;
    public default: boolean;
    public expirationDate: string;
    public billingAddress: {
        locality: string;
        streetAddress: string;
        region: string;
        countryName: string;
        postalCode: string;
    };
    public customer: number;
}
