import { mapOperationHoursToDays } from '../../utils';
import { Subscription } from 'rxjs/Rx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';

import { floatToRadius, formatDateRangeToHumanReadble } from '../../utils';
import {
  AuthService,
  CampaignsService,
  CategoriesService,
  PlaceService,
  CouponsService,
  Merchant,
  MerchantService,
  MerchantsCoupon
} from '../core';
import { PopupResult, PopupService } from '../popup';

/**
 * Component for view campaign.
 */
@Component({
  selector: 'pb-view-campaign',
  templateUrl: './view-campaign.component.html',
  styleUrls: ['./view-campaign.component.scss']
})
export class ViewCampaignComponent implements OnInit, OnDestroy {

  private routeSubscription: Subscription;
  private userSubscription: Subscription;

  private merchant: Merchant;

  public coupon: MerchantsCoupon;

  constructor(
    private popupService: PopupService,
    private route: ActivatedRoute,
    private campaignService: CampaignsService,
    private couponService: CouponsService,
    private authService: AuthService,
    private merchantsService: MerchantService,
    private categoriesService: CategoriesService,
    private placeService: PlaceService,
    private router: Router) { }

  /**
   * Flag for active/not active button for end coupon.
   */
  public get isAbleToEnd() {

    if (this.isEndedNow) {
      return false;
    }

    if (this.coupon && this.coupon.endDateRaw) {
      return moment(this.coupon.endDateRaw).isAfter(moment());
    }

    if (this.coupon && !this.coupon.endDateRaw) {
      return true;
    }

    return false;
  }

  /**
   * Flag for edit abillity.
   */
  public get isAbleToEdit() {

    if (this.coupon && !this.coupon.endDateRaw) {
      return true;
    }

    if (this.coupon) {
      return moment(this.coupon.endDateRaw).isAfter(moment());
    }

    return false;
  }

  /**
   * After user clicked end and yes on popup flag will be true.
   */
  public isEndedNow = false;

  /**
   * Females flag for icon.
   */
  @Input()
  public females = true;

  /**
   * Males flag for icon.
   */
  @Input()
  public males = true;

  public image = 'assets/images/white-375x375.png';

  public thumbnail: string;

  /**
   * Company info object.
   */
  @Input()
  public company = {
    name: null
  };

  /**
   * Company activity object.
   */
  @Input()
  public activity = {
    name: null,
    radius: null,
    time: null,
    imageSrc: 'assets/images/white-40x40.png'
  };

  /**
   * Keywords for campaign.
   */
  @Input()
  public keywords: string[] = [
  ];

  /**
   * Returns formatted date range.
   */
  public get dateRange() {
    if (!this.coupon) {
      return '';
    }

    let dateRange = moment(this.coupon.startDateRaw).format('MMM DD, YYYY');
    if (this.coupon.endDateRaw) {
      dateRange += ` - ${moment(this.coupon.endDateRaw).format('MMM DD, YYYY')}`;
    }

    return dateRange;
  }


  /**
   * Locations for campaign.
   */
  @Input()
  public locations: object[] = [
  ];


  private dateStart: string;

  private dateEnd: string;

  private operationHours: { day: string, time: string }[] = [];

  public yearsRange = '-';

  public gender = 'M/F';

  public redeemOnlineLimit: string | number;

  public redeemInStoreLimit: string | number;

  /**
   * Interests for campaign.
   */
  @Input()
  public interests: string[] = [];

  public async openPopup(event): Promise<void> {
    event.preventDefault();
    const result = await this.popupService.confirm('Are you sure you want to end this coupon?', 'End Coupon');
    if (result === PopupResult.YES) {
      // do something
      const endDate = moment().add(-1, 'day').format();
      this.couponService.endCoupon(this.merchant.id, this.coupon.id, endDate,
        { in_store: this.coupon.store, online: this.coupon.online });
      this.isEndedNow = true;
      this.coupon.endDateRaw = endDate;
    }
  }

  /**
   * Fired on send message button click.
   * @param event event on click.
   */
  public sendMessage(event) {
    const url = `/new-message/${this.coupon.id}/campaign`;

    this.router.navigateByUrl(url);
  }

  public returnBack(event) {
    this.router.navigateByUrl('/dashboard');
  }

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {

    await Promise.resolve(); // Hack for async/await

    const spinner = this.popupService.spinner();

    this.routeSubscription = this.route.params.subscribe((params) => {
      const id: number = +params['id'];
      this.userSubscription = this.authService.onUserChanged.subscribe(async (user): Promise<void> => {
        if (user != null) {

          const [merchant, coupon, categories, places] = await Promise.all([
            this.merchantsService.getMerchant(user.merchantId),
            this.couponService.getCoupon(user.merchantId, id),
            this.categoriesService.getFlatLeveled(),
            this.placeService.getPlaces(user.merchantId)]);

          this.merchant = merchant;
          this.coupon = coupon;

          this.company.name = this.merchant.dbaName;
          this.activity.name = coupon.name;
          this.activity.radius = floatToRadius(+coupon.targetAuidience.radius);
          this.keywords = coupon.keywords;

          if (this.coupon.image) {
            this.image = coupon.image;
          } else if (this.merchant.logoBackground) {
            this.image = this.merchant.logoBackground;
          }

          if (this.coupon.image) {
            this.thumbnail = this.coupon.image;
          }

          if (this.merchant.logo) {
            this.activity.imageSrc = this.merchant.logo;
          }

          this.locations = this.coupon.places.map(p => {
            const place = places.find(o => o.id === p);
            return place !== null ? {
              name: place.name,
              address: {
                street: place.address
              }
            } : null;
          });

          if (this.coupon.targetAuidience && this.coupon.targetAuidience.interest) {
            this.interests = this.coupon.targetAuidience.interest.map(o => {
              const category = categories.find(c => c.id === o);
              if (!category) {
                return '';
              }

              return category.name;
            });
          }

          if (this.coupon.startDateRaw && this.coupon.endDateRaw) {
            const diff = formatDateRangeToHumanReadble(new Date(Date.now()).toISOString(), this.coupon.endDateRaw);
            if (diff) {
              let postfix = '';
              if (diff.type === 'days') {
                postfix = 'd';
              } else if (diff.type === 'hours') {
                postfix = 'h';
              }

              this.activity.time = `${diff.amount}${postfix}`;
            }
          }

          if (this.coupon.targetAuidience) {
            if (this.coupon.targetAuidience.ageStart) {
              this.yearsRange = `${this.coupon.targetAuidience.ageStart}`;
            }
            if (this.coupon.targetAuidience.ageEnd) {
              this.yearsRange += `-${this.coupon.targetAuidience.ageEnd}`;
            } else {
              this.yearsRange += '+';
            }
            if (this.coupon.targetAuidience.gender) {
              this.gender = this.coupon.targetAuidience.gender;
            }
          }
          if (this.coupon.targetAuidience && this.coupon.targetAuidience.gender) {
            const gender = this.coupon.targetAuidience.gender;
            if (gender === 'M') {
              this.males = true;
              this.females = false;
            } else if (gender === 'F') {
              this.females = true;
              this.males = false;
            }
          }

          if (this.coupon.operationHours) {

            const items: { day: string, start: boolean, time: string }[] = [];

            // tslint:disable-next-line:forin
            for (const prop in this.coupon.operationHours) {
              const convertedOperationHours = mapOperationHoursToDays(prop, this.coupon.operationHours);
              if (convertedOperationHours.time === null) {
                continue;
              }

              items.push(convertedOperationHours);
            }

            items.forEach(item => {
              const secondItem = items.find(p => p.day === item.day && p.start !== item.start);

              const starting = item.start ? item : secondItem;
              const ending = !item.start ? item : secondItem;

              if (starting && ending) {
                const dateTime = {
                  day: moment(item.day, 'ddd').format('ddd'),
                  time: `${moment(starting.time, 'HH:mm:SS').format('hh:mm A')} - ${moment(ending.time, 'HH:mm:SS').format('hh:mm A')}`
                };

                if (!this.operationHours.find(i => i.day === dateTime.day)) {
                  this.operationHours.push(dateTime);
                }
              }
            });
          }

          this.keywords = coupon.keywords;

          this.redeemInStoreLimit =
            coupon.redeemInStoreLimit === 0
              ? 'Unlimited'
              : coupon.redeemInStoreLimit;

          this.redeemOnlineLimit =
            coupon.redeemOnlineLimit === 0
              ? 'Unlimited'
              : coupon.redeemOnlineLimit;

          spinner.close();

          if (this.userSubscription) {
            this.userSubscription.unsubscribe();
          }
        }
      });
    });
  }

  /**
   * Redirect to the edit campaign page.
   */
  public editCoupon(): void {
    this.router.navigate(
      ['edit-campaign', this.coupon.id],
      { queryParams: { type: 'coupon' } }
    );
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}
