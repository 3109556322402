import { TargetAudience } from './target-audience';

/**
 * New Message domain model.
 */
export class NewMessage {
  /**
   * .ctor
   * @param {NewMessage} initObject Initial object.
   */
  constructor(initObject?: NewMessage) {
    Object.assign(this, initObject);
  }

  /**
   * Message id.
   */
  public id: number;

  /**
   * Message title.
   */
  public title = '';

  /**
   * Message text.
   */
  public message = '';

  /**
   * Message title.
   */
  public video: any;
  public image: any;
  /**
   * Action text.
   */
  public actionText = '';

  /**
   * Action URL.
   */
  public actionURL = '';
  public actionPhone = '';
  public actionEmail = '';
  public isConcierge: boolean = false;

  /**
   * @see TargetAudience
   */
  public targetAudience: TargetAudience = new TargetAudience();

  /**
   * Message date of creation.
   */
  public created: string;

  /**
   * Number of people to receive message.
   */
  public audienceCount: number;

  public allInterests?: {id: string, text: string}[];
}
