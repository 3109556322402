import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line
  selector: '[pb-menu-item]',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {

  @Input()
  public caption: string;

  @Input()
  public badge: number;

  @Input()
  public isActive = false;

  @Input()
  public hasMenu = false;

  @Input()
  public route: any;
}
