import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';

import {
  User,
  Merchant,
  MerchantService,
  MerchantsPromotion,
  CategoriesService,
  PromotionsService,
  AuthService,
  PlaceService
} from '../core';

import { mapOperationHoursToDays, floatToRadius, formatDateRangeToHumanReadble } from '../../utils';
import { PopupResult, PopupService } from '../popup';



/**
 * Component for promotion view.
 */
@Component({
  selector: 'pb-view-promotion',
  templateUrl: './view-promotion.component.html',
  styleUrls: ['./view-promotion.component.scss']
})
export class ViewPromotionComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;
  private routSubscription: Subscription;

  private user: User;

  private merchant: Merchant;

  constructor(
    private popupService: PopupService,
    private promotionsService: PromotionsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private categoryService: CategoriesService,
    private placeService: PlaceService,
    private merchantService: MerchantService,
    private router: Router) { }

  /**
   * Flag for active/not active button for end coupon.
   */
  public get isAbleToEnd() {

    if (this.isEndedNow) {
      return false;
    }
    if (this.promotion && this.promotion.endDateRaw) {
      return moment(this.promotion.endDateRaw).isAfter(moment());
    } else if (this.promotion && !this.promotion.endDateRaw) {
      return true;
    }

    return false;
  }

  /**
   * Flag for edit abillity.
   */
  public get isAbleToEdit() {

    if (this.promotion && !this.promotion.endDateRaw) {
      return true;
    }

    if (this.promotion) {
      return moment(this.promotion.endDateRaw).isAfter(moment());
    }

    return false;
  }

  public promotion: MerchantsPromotion;

  /**
   * After user clicked end and yes on popup flag will be true.
   */
  public isEndedNow = false;

  @Input()
  public females = true;

  @Input()
  public males = true;

  public company = {
    name: 'Rolex'
  };

  public activity = {
    name: '10% Select Rolex Watches',
    radius: '10 miles',
    time: null,
    imageSrc: 'assets/images/white-40x40.png'
  };

  @Input()
  public keywords: string[] = [];

  /**
   * Returns formatted date range.
   */
  public get dateRange() {
    if (!this.promotion) {
      return '';
    }

    let dateRange = moment(this.promotion.startDateRaw).format('MMM DD, YYYY');
    if (this.promotion.endDateRaw) {
      dateRange += ` - ${moment(this.promotion.endDateRaw).format('MMM DD, YYYY')}`;
    }

    return dateRange;
  }


  @Input()
  public locations: object[] = [
  ];

  @Input()
  public interests: string[] = [];

  private operationHours: { day: string, time: string }[] = [];

  /**
   * Image for promotion.
   */
  public image = 'assets/images/white-375x375.png';

  /**
   * Thumbnail of promotion.
   */
  public thumbnail: string;


  public sendMessage(event) {
    const url = `/new-message/${this.promotion.id}/promotion`;
    this.router.navigateByUrl(url);
  }

  /**
   * Redirect to the edit campaign page.
   */
  public editPromotion(): void {
    this.router.navigate(
      ['edit-campaign', this.promotion.id],
      { queryParams: { type: 'promotion' } }
    );
  }

  /**
   * Returning back to dashboard.
   * @param event Event on click.
   */
  public async returnBack(event): Promise<void> {
    await this.router.navigateByUrl('/dashboard');
  }

  /**
   * Opening popup.
   * @param event Event on click.
   */
  public async openPopup(event) {
    event.preventDefault();
    const result = await this.popupService.confirm('Are you sure you want to end this promotion?', 'End Promotion');
    if (result === PopupResult.YES) {
      // do something
      const endDate = moment().add(-1, 'day').format();
      await this.promotionsService.endPromotion(this.merchant.id, this.promotion.id, endDate);
      this.isEndedNow = true;
      this.promotion.endDateRaw = endDate;
    }
  }

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {

    await Promise.resolve();
    const spinner = this.popupService.spinner();

    this.userSubscription = this.authService.onUserChanged.skipWhile(r => !r).subscribe(async (user): Promise<void> => {
      this.user = user;

      this.routSubscription = this.route.params.subscribe(async (params): Promise<void> => {
        const id = +params['id'];
        this.promotion = await this.promotionsService.getPromotion(this.user.merchantId, id);
        this.merchant = await this.merchantService.getMerchant(this.user.merchantId);

        // This is not used on view, but may be after redesign we will ned this.
        if (this.promotion.targetAuidience && this.promotion.targetAuidience.interest) {

          const categories = await this.categoryService.getFlatLeveled();

          this.interests = this.promotion.targetAuidience.interest.map(o => {
            const category = categories.find(p => p.id === o);
            return category.name;
          });
        }

        this.company.name = this.merchant.dbaName;
        this.activity.name = this.promotion.name;
        // tslint:disable-next-line:no-magic-numbers
        this.activity.radius = floatToRadius(300);

        if (this.promotion.endDateRaw) {
          const diff = formatDateRangeToHumanReadble(new Date(Date.now()).toISOString(), this.promotion.endDateRaw);
          if (diff) {
            let postfix = '';
            if (diff.type === 'days') {
              postfix = 'd';
            } else if (diff.type === 'hours') {
              postfix = 'h';
            }

            this.activity.time = `${diff.amount}${postfix}`;
          }
        }

        if (this.promotion.image) {
          this.image = <string>this.promotion.image;
        } else if (this.merchant.logoBackground) {
          this.image = this.merchant.logoBackground;
        }

        if (this.promotion.image) {
          this.thumbnail = this.promotion.image;
        }

        if (this.promotion.places) {
          const places = await this.placeService.getPlaces(this.user.merchantId);

          this.locations = this.promotion.places.map(p => {
            const place = places.find(o => o.id === p);

            return {
              name: place.name,
              address: {
                street: place.address
              }
            };
          });
        }

        if (this.promotion.operationHours) {
          const items: { day: string, start: boolean, time: string }[] = [];

          // tslint:disable-next-line:forin
          for (const prop in this.promotion.operationHours) {
            const convertedOperationHours = mapOperationHoursToDays(prop, this.promotion.operationHours);
            if (convertedOperationHours.time === null) {
              continue;
            }

            items.push(convertedOperationHours);
          }

          items.forEach(item => {
            const secondItem = items.find(p => p.day === item.day && p.start !== item.start);

            const starting = item.start ? item : secondItem;
            const ending = !item.start ? item : secondItem;

            if (starting && ending) {

              const dateTime = {
                day: moment(item.day, 'ddd').format('ddd'),
                time: `${moment(starting.time, 'HH:mm:SS').format('hh:mm A')} - ${moment(ending.time, 'HH:mm:SS').format('hh:mm A')}`
              };

              if (!this.operationHours.find(i => i.day === dateTime.day)) {
                this.operationHours.push(dateTime);
              }
            }
          });
        }

        this.activity.name = this.promotion.name;

        if (this.merchant.logo) {
          this.activity.imageSrc = this.merchant.logo;
        }

        if (this.promotion.keywords) {
          this.keywords = this.promotion.keywords;
        }

        spinner.close();

        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }
      });
    });
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    if (this.routSubscription) {
      this.routSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
