import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { InvoicesPageComponent } from './invoices-page.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    InvoicesPageComponent
  ],
  exports: [
    InvoicesPageComponent
  ]
})
export class InvoicesPageModule { }
