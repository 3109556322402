import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'pb-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Input()
  public isSearchClosed = true;

  @Input()
  public searchString: string;

  @Output()
  private searchStringChange = new EventEmitter<string>();

  @ViewChild('input')
  public input: InputComponent;

  public onSearchStringChanged(): void {
    this.searchStringChange.emit(this.searchString);
  }

  public openSearch(): void {
    this.isSearchClosed = false;
  }

  public onFocusOut(): void {
    if (this.searchString.length === 0) {
      this.isSearchClosed = true;
    }
  }
}
