import {Component, OnInit} from '@angular/core';
import {MdDialog, Sort} from '@angular/material';

import {
  AuthService,
  Category,
  DataTransferService,
  MerchantsCoupon,
  Pagination,
  PaginationService,
  PlaceService
} from '../../core';

import {ActivatedRoute, Router} from '@angular/router';
import {PopupService} from '../../popup';
import {WebappService} from '../../core/services/webapp/webapp.service';
import {Observable} from "rxjs/Observable";
import {Subscription} from "rxjs/Subscription";
import {FilterByVisibility} from "../../core/services/filterby.enum";
import {CouponSettingsPageComponent} from "../coupon-settings-page/coupon-settings-page.component";


/**
 * Locations page component.
 */
@Component({
  selector: 'pb-webapp-coupons-page',
  templateUrl: './coupons-page.component.html',
  styleUrls: ['./coupons-page.component.scss']
})
export class WebAppCouponsPageComponent implements OnInit {
  private webappFilters: Category[] = [];

  /**
   * .ctor
   * @param {AuthService} authService Auth service.
   * @param activatedRoute
   * @param {PlaceService} placeService Place service.
   * @param webappService
   * @param dialog
   * @param router
   * @param popupService
   * @param route
   * @param dataTransferService
   * @param {PaginationService} paginationService Pagination service.
   */
  constructor(private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private placeService: PlaceService,
              private webappService: WebappService,
              private dialog: MdDialog,
              private router: Router,
              private popupService: PopupService,
              private route: ActivatedRoute,
              private dataTransferService: DataTransferService,
              private paginationService: PaginationService) {
  }

  /**
   * Current saved sort method.
   */
  private currentSort: Sort;

  /**
   * Search string.
   */
  public searchString = '';

  /**
   * Pagination object from pagination service.
   */
  public pagination: Pagination = new Pagination();

  /**
   * Paged items.
   */
  public pagedItems: MerchantsCoupon[];

  /**
   * Webapps list.
   */
  public coupons: MerchantsCoupon[] = [];
  public total: number = 0;

  /**
   * Current merchant Id.
   */
  private merchantId: number;
  public webAppId: string;
  private routeSub: Subscription;

  public filtering: { id: string; text: string }[] = [
    {id: FilterByVisibility.All.toString(), text: "All"},
    {id: FilterByVisibility.Visible.toString(), text: "Visible"},
    {id: FilterByVisibility.Hidden.toString(), text: "Hidden"}
  ];
  public selectedFiltering: string = FilterByVisibility.All.toString();

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.routeSub = Observable.combineLatest(this.activatedRoute.paramMap, this.authService.onUserChanged).subscribe(async tuple => {
      const [params, user] = tuple;
      if (user) {
        this.merchantId = user.merchantId;

        this.webAppId = params.get('id');
        if (this.webAppId) {
          this.webappFilters = await this.webappService.getWebAppFilters(this.webAppId);
          this.fetchCoupons();
        }
      }
      if (this.routeSub) {
        this.routeSub.unsubscribe();
      }
    });

    const userSubscription = this.authService.onUserChanged.subscribe(async result => {
      const user = result;
      if (user) {
        this.merchantId = user.merchantId;
        if (userSubscription) {
          userSubscription.unsubscribe();
        }
      }
    });
  }

  /**
   * Invokes data sorting in table considering search string, makes pagination start from 1st page.
   */
  public sortDataChanged(sort: Sort): void {
    this.adjustCouponData(sort, this.searchString, 1);
  }

  /**
   * Invokes string search in table considering table's sorting, makes pagination start from 1st page.
   */
  public searchStringChanged(event): void {
    this.searchString = event;
    this.fetchCoupons();
  }

  clearSearch() {
    this.searchString = '';
    console.log('Search string cleared');
    this.searchStringChanged(this.searchString);
  }

  public async filterByChanged(event): Promise<void> {
    this.selectedFiltering = event;
    this.fetchCoupons();
  }

  private fetchCoupons(): void {
    const filter = this.getFilterByVisibility();
    this.webappService.getCoupons(this.webAppId, this.searchString, filter).then(coupons => {
      this.coupons = coupons.results;
      this.total = coupons.count;
      this.adjustCouponData(null, '', 1);
    });
  }

  private getFilterByVisibility(): FilterByVisibility {
    if (this.selectedFiltering === FilterByVisibility.Visible.toString()) {
      return FilterByVisibility.Visible;
    } else if (this.selectedFiltering === FilterByVisibility.Hidden.toString()) {
      return FilterByVisibility.Hidden;
    } else {
      return FilterByVisibility.All;
    }
  }

  /**
   * Shows new list of table items considering table's sorting and search.
   */
  public setPageClicked(page: number): void {
    const filter = this.getFilterByVisibility();
    this.webappService.getCoupons(this.webAppId, this.searchString, filter, page).then(coupons => {
      this.coupons = coupons.results;
      this.total = coupons.count;
      this.adjustCouponData(this.currentSort, this.searchString, page);
    });
  }

  /**
   * Combines sorting, filtering and setting pagination methods.
   */
  private adjustCouponData(sort: Sort, searchString: string, page: number): void {
    this.setPage(page);
  }

  /**
   * Sets page.
   */
  private setPage(page: number): void {
    /**
     * Get pagination object from service.
     */
    this.pagination = this.paginationService.getPagination(this.total, page);

    /**
     * Get current page of items.
     */
    this.pagedItems = this.coupons;
  }

  public editWebAppClick(place: MerchantsCoupon) {
    this.dataTransferService.set({place: place, merchantId: this.merchantId});

    let queryParams = null;
    this.router.navigate(['/webapps', place.id], {queryParams: queryParams});
  }
  openCouponSettings(coupon: MerchantsCoupon): void {
    const dialogRef = this.dialog.open(CouponSettingsPageComponent, {
      width: '900px',
      /**
       * global style for popup to remove paddings
       */
      panelClass: 'campaign-publish-success',
      disableClose: false,
      data: {
        webappFilters: this.webappFilters,
        merchantId: this.merchantId,
        webappId: this.webAppId,
        couponName: coupon.name,
        couponId: coupon.id,
        onSuccess: (id) => {
          // this.reloadPlaces();
        }
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        /*if (result === PopupResult.YES) {
          this.router.navigateByUrl('/create_campaign');
        } else if (result === PopupResult.NO) {
          this.router.navigateByUrl('/dashboard');
        }*/
      })
      .catch((err) => err);
  }
}

