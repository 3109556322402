<div class="root" *ngFor="let image of images">
  <div class="inner">
    <div class="delete" (click)="deleteImage(image.index)">
      <pb-icon name="#iconDelete"></pb-icon>
    </div>
    <img [src]="image.url" [title]="image.name" (click)="openLightBox(image.index)" alt="image">
  </div>
  <div class="name">
    {{image.name}}
  </div>
</div>


