import { Injectable } from '@angular/core';
import { MdDialog, MdDialogRef } from '@angular/material';
import { PopupComponent } from '../components/popup/popup.component';
import { IframePopupComponent } from '../components/iframe-popup/iframe-popup.component';
import { PopupResult } from '../components/popup/popup-result.enum';
import { SpinnerPopupComponent } from '../components/spinner-popup/spinner-popup.component';
import { ProgressSpinnerPopupComponent } from '../components/progress-spinner-popup/progress-spinner-popup.component';

const POPUP_TIMEOUT = 3000;
const POPUP_TIMEOUT_SHORT = 1200;

/**
 * Popup dialog service.
 */
@Injectable()
export class PopupService {
  
  /**
   * .ctor
   * @param {MdDialog} dialog Angular Material dialog service.
   */
  constructor(private dialog: MdDialog) { }

  /**
   * Shows an information popup.
   * @param message Popup message.
   * @param caption Popup caption.
   */
  public info(message: string, caption?: string): Promise<void> {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {
        caption: caption,
        message: message
      },
      width: '280px'
    });
    setTimeout(() => dialogRef.close(), POPUP_TIMEOUT);
    return dialogRef.afterClosed().toPromise();
  }

   /**
   * Shows an information popup.
   * @param message Popup message.
   * @param caption Popup caption.
   */
  public info_sticky(message: string, caption?: string): Promise<void> {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {
        caption: caption,
        message: message
      },
      width: '280px'
    });
    return dialogRef.afterClosed().toPromise();
  }

  public iframe(width: number, height: number, src: string): Promise<void> {
    const dialogRef = this.dialog.open(IframePopupComponent, {
      data: { src: src },
      width: `${width}px`,
      height: `${height}px`
    });

    return dialogRef.afterClosed().toPromise();
  }

  /**
   * Shows an completion popup.
   * @param message Popup message.
   * @param caption Popup caption.
   * @param buttonTitle Accept button title.
   */
  public done(message: string, caption: string, buttonTitle?: string, disableClose: boolean = false): Promise<PopupResult> {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {
        caption: caption,
        message: message,
        buttonTitle: buttonTitle
      },
      width: '417px',
      height: '262px',
      disableClose: disableClose
    });
    return dialogRef.afterClosed().toPromise();
  }

  /**
   * Shows an completion popup.
   * @param message Popup message.
   * @param caption Popup caption.
   * @param buttonTitle Accept button title.
   */
  public success(message: string, caption: string, buttonTitle?: string, disableClose: boolean = false): Promise<PopupResult> {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {
        caption: caption,
        message: message,
        buttonTitle: buttonTitle,
      },
      width: '550px',
      // height: '252px',
      disableClose: disableClose
    });
    return dialogRef.afterClosed().toPromise();
  }

  /**
   * Shows a confirmation popup.
   * @param message Popup message.
   * @param caption Popup caption.
   */
  public confirm(message: string, caption: string): Promise<PopupResult> {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {
        caption: caption,
        message: message,
        confirm: true
      },
      width: '417px',
      height: '197px'
    });
    return dialogRef.afterClosed().toPromise();
  }

  /**
   * Shows popup with spinner for 3 seconds.
   */
  public spinner(): MdDialogRef<SpinnerPopupComponent> {
    return this.dialog.open(SpinnerPopupComponent, {
      disableClose: true
    });
  }

  public progresSpinner(): MdDialogRef<ProgressSpinnerPopupComponent> {
    return this.dialog.open(ProgressSpinnerPopupComponent, {
      disableClose: true
    });
  }
}
