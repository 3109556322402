import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  AuthService, BrandService,
  CardInfo,
  CustomerInfo,
  PaymentService,
  UtilsService,
} from '../../core';
import { Plans } from '../../core/models/plans';
import { PopupService } from '../../popup';
import { CanStepDeactivate } from '../can-step-deactivate';
import { EventDataService } from '../services/event-data.service';
import { EventService } from '../services/event-service/event.service';
import { PromoCode } from '../../core/models/promo-code';
import { EventStyleVm as CampaignStyle } from '../event-style/event-style';
import {MerchantApp} from "../../core/models/merchant-app";
import {PaymentMethodComponent} from "../../campaign/finalize-campaign/payment-method/payment-method.component";

@Component({
  selector: 'pb-finalize-event',
  templateUrl: './finalize-event.component.html',
  styleUrls: ['./finalize-event.component.scss'],
})
export class FinalizeEventComponent implements CanStepDeactivate {
  public campaignPlan: Plans;
  public promoCodeText = '';
  public promoCode: PromoCode;
  public promoCodeValid = false;
  public promoCodeError = '';

  @Output()
  public publishCampaign = new EventEmitter<any>();

  private userSubscription: Subscription;

  private customerInfo: CustomerInfo;

  public editMode = true;
  public paymentRequired = true;
  public bypassCard = false;

  public isValid = () => !this.editMode;

  public merchantId: number;

  public card: any = {
    name: null,
    number: null,
    cvc: null,
    expirationDate: null,
  };

  public address: any = {
    billingAddress: null,
    city: null,
    state: null,
    zip: null,
  };

  public defaultUserCountry = 'United States of America';
  public userCountry: string = this.defaultUserCountry;
  /**
   * Model for this component.
   */
  @Input()
  public campaignStyle: CampaignStyle;

  public states: string[] = [];
  public usStates: string[] = [];
  public canadaStates: string[] = [];
  public premiumStateCount = 0;
  public premiumEmailStateCount = 0;
  public customLinkCount = 0;
  public brand: MerchantApp = new MerchantApp({id: 'gettinlocal', name: 'GettinLocal'});
  public currencySymbol = '$';
  public currencyName = 'USD';

  constructor(
    private cdref: ChangeDetectorRef,
    private authService: AuthService,
    private dataService: EventDataService,
    private popupService: PopupService,
    private couponService: EventService,
    private paymentService: PaymentService,
    private utilsService: UtilsService,
    private brandService: BrandService,
    private router: Router
  ) {
    this.brandService.brand.subscribe((b) => {
      this.brand = b;
    });
  }

  @ViewChild(PaymentMethodComponent)
  public billingInformationForm: PaymentMethodComponent;

  public ngOnInit() {
    this.seedData();

    this.campaignPlan = this.dataService.campaign_plan.getValue();
    this.paymentRequired = this.dataService.payment_required.getValue();
    this.premiumStateCount = this.dataService.premium_state_count.getValue();
    this.premiumEmailStateCount = this.dataService.premium_email_state_count.getValue();
    this.customLinkCount = this.dataService.custom_link_count.getValue();

    if (
      this.premiumEmailStateCount === 0 &&
      this.premiumStateCount === 0 &&
      this.customLinkCount === 0 &&
      this.campaignPlan.name !== 'premium'
    ) {
      this.bypassCard = true;
    }

    if (this.getTotal() <= 0) {
      this.bypassCard = true;
    }

    this.dataService.campaign_plan.subscribe((plan) => {
      this.campaignPlan = plan;
    });
    this.dataService.payment_required.subscribe((reqd) => {
      this.paymentRequired = reqd;
    });

    this.promoCode = this.dataService.promo_code.getValue();

    if (this.promoCode) {
      this.promoCodeText = this.promoCode.code;
      this.promoCodeValid = true;
      if (this.promoCode.discount === 100) {
        this.bypassCard = true;
      }
      this.dataService.promo_code.next(this.promoCode);
    }
  }

  public publish() {
    this.publishCampaign.emit();
  }

  public canStepDeactivate() {
    return false;
  }

  private seedData() {
    this.userSubscription = this.authService.onUserChanged
      .skipWhile((user) => !user)
      .subscribe(
        async (user): Promise<void> => {
          this.merchantId = user.merchantId;
          this.userCountry = user.merchant.address.country || this.defaultUserCountry;

          this.usStates = (await this.utilsService.getUSStates())
        .filter(state => state.code)
        .map(state => state.code);

        this.canadaStates = (await this.utilsService.getCanadaStates())
        .filter(state => state.code)
        .map(state => state.code);

        if (this.userCountry === 'Canada') {
          this.states = this.canadaStates;
        } else {
          this.states = this.usStates;
        }
        // workaround
        switch (this.userCountry) {
          case 'Germany':
            this.currencySymbol = '€';
            break;
          case 'Austria':
            this.currencySymbol = '€';
            break;
          case 'United Kingdom':
            this.currencySymbol = '£';
            break;
          case 'Canada':
            this.currencySymbol = '$';
            break;
          case 'Netherlands':
            this.currencySymbol = '€';
            break;
          default:
            this.currencySymbol = '$';
        }
        switch (this.userCountry) {
          case 'United Kingdom':
            this.currencyName = 'GBP';
            break;
          case 'Canada':
            this.currencyName = 'CAD';
            break;
          case 'Netherlands':
          case 'Germany':
          case 'Austria':
            this.currencyName = 'EUR';
            break;
          default:
            this.currencyName = 'USD';
        }

        this.states.push('Other');

        const customerInfos = await this.paymentService.getCustomerInfo(
          this.merchantId
        );
        if (customerInfos && customerInfos.length > 0) {
          this.customerInfo = customerInfos[0];
          if (
            this.customerInfo &&
            this.customerInfo.creditCards &&
            this.customerInfo.creditCards.length > 0
          ) {
            const creditCard = this.customerInfo.creditCards[0];
            this.card.name = creditCard.cardholderName;

            const regex = /(\d{2})\/(\d{4})/g;
            const matches = regex.exec(creditCard.expirationDate);
            const numberOfMatchesToDate = 3;
            if (
              matches &&
              matches.length > 0 &&
              matches.length === numberOfMatchesToDate
            ) {
              const [, month, year] = matches;
              const numberOfDigitsInYear = 2;
              this.card.expirationDate = `${month}/${year.substr(
                numberOfDigitsInYear,
                numberOfDigitsInYear
              )}`;
            } else {
              this.card.expirationDate = creditCard.expirationDate;
            }
            this.card.number = `****-****-****-${creditCard.last4}`;
            if (creditCard.expirationDate) {
              const date = moment(creditCard.expirationDate);
              this.card.month = date.month().toString();
              this.card.year = date.year().toString();
            }
            if (creditCard.billingAddress) {
              this.address.billingAddress =
                creditCard.billingAddress.streetAddress;
              this.address.city = creditCard.billingAddress.locality;
              this.address.country = creditCard.billingAddress.countryName;

              if (this.address.country === 'Canada') {
                this.states = this.canadaStates;
              } else {
                this.states = this.usStates;
              }

              if (
                !this.states.some(
                  (o) => o === creditCard.billingAddress.region
                )
              ) {
                this.address.customState = creditCard.billingAddress.region;
                this.address.state = 'Other';
              } else {
                this.address.state = creditCard.billingAddress.region;
              }

              this.address.zip = creditCard.billingAddress.postalCode;
            }

            this.changeMode(false);
          }
        }

        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }
      }
    );
  }

  public async removePromoCode(event: any): Promise<void> {
    event.preventDefault();
    this.promoCodeError = '';
    if (this.promoCodeValid) this.dataService.payment_required.next(true);
    this.promoCodeValid = false;
    this.bypassCard = false;
    this.promoCodeText = '';
    this.dataService.promo_code.next(null);
    this.promoCode = null;
  }

  public async applyPromoCode(event: any): Promise<void> {
    event.preventDefault();

    this.promoCodeValid = false;
    this.bypassCard = false;

    if (this.promoCodeText.length === 0) {
      this.promoCodeError = 'Enter a code.';
      return;
    }

    this.promoCodeError = '';
    this.couponService
      .getPromoCode(this.promoCodeText)
      .then((code) => {
        this.popupService.success(code.description, `Congratulations!`, 'OK');
        this.promoCodeValid = true;
        this.promoCode = code;
        if (this.promoCode.discount === 100) this.bypassCard = true;
        this.dataService.promo_code.next(code);
      })
      .catch((err) => {
        this.promoCodeError = 'Invalid code';
      });
  }

  public async handleSubmit(event: any): Promise<void> {
    const model = new CardInfo();
    model.nonce = event.nonce;
    model.cardholderName = event.cardholderName;
    model.billingAddress = {
      streetAddress: event.address.billingAddress,
      locality: event.address.city,
      region: event.address.state,
      country_name: event.address.country
    };

    if (event.address.state === 'Other') {
      model.billingAddress.region = event.address.customState;
    }

    const spinner = this.popupService.spinner();
    let paymentAddHandled = false;

    try {
      const result = await this.paymentService.addPayment(
        this.merchantId,
        model
      );
      if (result && !result.ok) {
        const body = result.json();

        spinner.close();
        this.popupService.info(body.error, 'Error');
      } else {
        this.seedData();
        paymentAddHandled = true;
      }
    } finally {
      spinner.close();
    }

    if (paymentAddHandled) {
      this.popupService.info('Your card information submitted', 'Success');
    }
  }

  public ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  public submitForm(event) {
    this.billingInformationForm.submitForm(event);
  }

  public changeMode(editMode: boolean) {
    this.editMode = editMode;

    if (this.editMode === false) {
      if (!this.customerInfo) {
        this.popupService.info(
          'Credit Card Information required to publish this Campaign',
          'Credit Card Required'
        );
        return (this.editMode = true);
      }
      if (this.customerInfo.creditCards.length === 0) {
        this.popupService.info(
          'Credit Card Information required to publish this Campaign',
          'Credit Card Required'
        );
        return (this.editMode = true);
      }
    }

    if (
      this.customerInfo.creditCards &&
      this.customerInfo.creditCards.length > 0 &&
      !editMode
    ) {
      const creditCard = this.customerInfo.creditCards[0];
      if (this.states.some((s) => s === creditCard.billingAddress.region)) {
        this.address.state = creditCard.billingAddress.region;
        this.address.customState = null;
      } else {
        this.address.state = 'Other';
        this.address.customState = creditCard.billingAddress.region;
      }

      this.address.billingAddress = creditCard.billingAddress.streetAddress;
      this.address.city = creditCard.billingAddress.locality;

      this.card.name = creditCard.cardholderName;
    }
  }

  public get canPublish(): boolean {
    return this.editMode ? this.bypassCard : true;
  }

  public promoCodeChanged(code): void {
    this.promoCodeError = '';
  }

  public getSubscriptionFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.subscription_rate;
    return +fee.toFixed(2);
  }

  public getPremiumStatesFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.premium_state_fee;
    return +fee.toFixed(2);
  }

  public getPremiumStatesTotalFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +(
      this.campaignPlan.premium_state_fee * this.premiumStateCount
    );
    return +fee.toFixed(2);
  }

  public getPremiumEmailStatesFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.premium_email_fee;
    return +fee.toFixed(2);
  }

  public getPremiumEmailStatesTotalFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +(
      this.campaignPlan.premium_email_fee * this.premiumEmailStateCount
    );
    return +fee.toFixed(2);
  }

  public getCustomLinkFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.custom_link_fee;
    return +fee.toFixed(2);
  }

  public getCustomLinkTotalFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +(
      this.campaignPlan.custom_link_fee * this.customLinkCount
    );
    return +fee.toFixed(2);
  }

  public getTax(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }

    const tax_rate: number = +this.campaignPlan.tax_rate;
    const fee: number = this.getSubTotal();

    return +((tax_rate / 100) * fee).toFixed(2);
  }

  public getSubTotal(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    return +(
      this.getSubscriptionFee() +
      this.getPremiumStatesTotalFee() +
      this.getCustomLinkTotalFee() +
      this.getPremiumEmailStatesTotalFee()
    ).toFixed(2);
  }

  public getTotal(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    return +(
      this.getSubTotal() +
      this.getTax() -
      this.getPromotionalSavings()
    ).toFixed(2);
  }

  public getPromotionalSavings(): number {
    if (!this.promoCode) return 0;
    return +(
      +(this.getSubTotal() + this.getTax()) *
      +(this.promoCode.discount / 100)
    ).toFixed(2);
  }

  public isFree(): boolean {
    if (!this.promoCode) {
      return false;
    }

    return this.promoCode.discount === 100;
  }
}
