import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as moment from 'moment';

import { TimePickerVm as TimePicker } from './time-picker';
import { DayVm as Day } from './day';
import { TimeVm } from './time';

const WEEK = {
  mon: 'monday',
  tue: 'tuesday',
  wed: 'wednesday',
  thu: 'thursday',
  fri: 'friday',
  sat: 'saturday',
  sun: 'sunday'
};

const DEFAULT_START_TIME = {
  hour: 8,
  minutes: '00',
  meridiem: 'am'
};

const DEFAULT_END_TIME = {
  hour: 8,
  minutes: '00',
  meridiem: 'pm'
};

const ALL_DAY_START_TIME = {
  hour: 12,
  minutes: '00',
  meridiem: 'am'
};

const ALL_DAY_END_TIME = {
  hour: 11,
  minutes: '59',
  meridiem: 'pm'
};

/**
 * ViewModel for a workday of a week.
 */
export class WorkDayVm {
  /**
   * The start time of work day.
   */
  private startValue = new TimePicker({ ...DEFAULT_START_TIME });

  /**
   * The end time of work day.
   */
  private endValue = new TimePicker({ ...DEFAULT_END_TIME });
  /**
   * @constructor
   * @param day Day name.
   */
  constructor(day: string) {
    this.name = day;
    this.changeTime$ = new BehaviorSubject(this.day);
    this.showTimePickerChange$ = new BehaviorSubject({ day, time: null });
  }

  /**
   * Show time picker change.
   */
  public showTimePickerChange$: BehaviorSubject<{ day: string, time: string }>;

  /**
   * Work Time changing.
   */
  public changeTime$: BehaviorSubject<Day>;

  /**
   * Marker - the day is active.
   */
  public isActive = false;

  /**
   * Name day of a week.
   */
  public name: string;

  /**
   * Start time.
   */
  public set start(val: any) {
    const hour = +moment(val, ['h:mm']).format('h');
    const minutes = moment(val, ['h:mm']).format('mm');
    const meridiem = moment(val, ['h:mm']).format('a');

    this.startValue = new TimePicker({ hour, minutes, meridiem });
    this.changeTime$.next(this.day);
  }

  /**
   * Start time.
   */
  public get start() {
    return this.startValue;
  }

  /**
   * End time.
   */
  public set end(val: any) {
    const hour = +moment(val, ['h:mm']).format('h');
    const minutes = moment(val, ['h:mm']).format('mm');
    const meridiem = moment(val, ['h:mm']).format('a');

    this.endValue = new TimePicker({ hour, minutes, meridiem });
    this.changeTime$.next(this.day);
  }

  /**
   * End time.
   */
  public get end() {
    return this.endValue;
  }

  /**
   * Get day.
   */
  public get day(): Day {
    return {
      name: WEEK[this.name],
      start: this.startValue.time24,
      end: this.endValue.time24,
      isActive: this.isActive
    };
  }

  /**
   * Get start time in 12-hour format.
   */
  public get startTime(): string {
    return this.start.time.time12;
  }

  /**
  * Get end time in 12-hour format.
  */
  public get endTime(): string {
    return this.end.time.time12;
  }

  /**
   * Get hour value of start work time.
   */
  public get startHour(): number {
    return this.start.time.hour;
  }

  /**
   * Get hour value of end work time.
   */
  public get endHour(): number {
    return this.end.time.hour;
  }

  /**
   * Get minutes value of start work time.
   */
  public get startMinutes(): string {
    return this.start.time.minutes;
  }

  /**
   * Get minutes value of end work time.
   */
  public get endMinutes(): string {
    return this.end.time.minutes;
  }

  /**
   * Get meridiem value of start work time.
   */
  public get startMeridiem(): string {
    return this.start.time.meridiem;
  }

  /**
   * Get meridiem value of end work time.
   */
  public get endMeridiem(): string {
    return this.end.time.meridiem;
  }

  /**
   * Active or deactive day.
   * @param value Checkbox value.
   */
  public activateDay(value: boolean): void {
    this.isActive = value;

    this.changeTime$.next(this.day);
    this.showTimePickerChange$.next({ day: null, time: null });
  }

  /**
   * @param value Checkbox value.
   */
  public makeAllDay(value: boolean): void {
    this.startValue = new TimePicker({ ...ALL_DAY_START_TIME });
    this.endValue = new TimePicker({ ...ALL_DAY_END_TIME });
    this.changeTime$.next(this.day);
  }

  /**
   * @param value Checkbox value.
   */
  public isAllDay(): boolean {
    return this.day.start === '00:00' && this.day.end === '23:59';
  }

  /**
   * Show or hide TimePicker popup.
   * @param time 'Start' or 'End' time of workday.
   */
  public toggleSetTime(time: string): void {
    if (time === 'start') {
      this.start.showTimePicker = !this.start.showTimePicker;
      this.showTimePickerChange$.next({ day: this.name, time });
    } else {
      this.end.showTimePicker = !this.end.showTimePicker;
      this.showTimePickerChange$.next({ day: this.name, time });
    }
  }

  /**
   * Change hour.
   * @param direction The direction of changing hour: 'up' or 'down'.
   * @param time 'Start' or 'End' time of workday.
   */
  // tslint:disable:no-magic-numbers
  public changeHour(direction: string, time: string, value?: string): void {
    let hour;

    if (value) {
      hour = +value;
    } else {
      hour = time === 'start'
        ? this.startHour
        : this.endHour;

      hour = direction === 'up'
        ? hour + 1
        : hour - 1;
    }

    if (hour > 12) {
      hour = 1;
    }
    if (hour < 1) {
      hour = 12;
    }

    if (time === 'start') {
      this.start.time.hour = hour;
    } else {
      this.end.time.hour = hour;
    }

    this.changeTime$.next(this.day);
  }

  /**
   * Change minutes.
   * @param direction The direction of changing minutes: 'up' or 'down'.
   * @param time 'Start' or 'End' time of workday.
   */
  public changeMinutes(direction: string, time: string, value?: string): void {
    let minutes;

    if (value) {
      minutes = +value;
    } else {
      minutes = time === 'start'
        ? +this.startMinutes
        : +this.endMinutes;

      minutes = direction === 'up'
        ? minutes + 15
        : minutes - 15;
    }

    // tslint:disable:no-magic-numbers
    if (minutes > 45) {
      minutes = 0;
    }
    if (minutes < 0) {
      minutes = 45;
    }

    if (time === 'start') {
      this.start.time.minutes = minutes < 10
        ? '0' + minutes
        : minutes.toString();
    } else {
      this.end.time.minutes = minutes < 10
        ? '0' + minutes
        : minutes.toString();
    }

    this.changeTime$.next(this.day);
  }
  // tslint:enable:no-magic-numbers

  /**
   * Change meridiem.
   * @param value Meridiem value: 'am' or 'pm'.
   * @param time 'Start' or 'End' time of workday.
   */
  public meridiemChange(value: string, time: string): void {
    if (time === 'start') {
      this.start.time.meridiem = value;
    } else {
      this.end.time.meridiem = value;
    }

    this.changeTime$.next(this.day);
  }
}
