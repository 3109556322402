import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pb-stat-item',
  templateUrl: './stat-item.component.html',
  styleUrls: ['./stat-item.component.scss']
})
export class StatItemComponent implements OnInit {

  constructor() { }

  @Input()
  public name: string;

  @Input()
  public icon: string;

  @Input()
  public cardtype: string;

  @Input()
  public number: number;

  @Input()
  public subtitle: string;

  public ngOnInit() {
  }

}
