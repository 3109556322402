import { Injectable } from '@angular/core';
import { Mapper } from '../../abstract/mapper';
import { Message } from '../../../models/message';
import { MessageDto } from '../dtos/message.dto';


/**
 * Mapper for Merchant's messages.
 */
@Injectable()
export class MessageMapper implements Mapper<MessageDto, Message> {

    /**
     * @inheritdoc
     */
    public mapToModel(dto: MessageDto): Message {
        return {
            id: dto.id,
            logo: dto.logo,
            message: dto.message,
            views: dto.message_view_count,
            clicks: dto.message_click_count,
            message_type: dto.message_type,
            is_enabled: dto.is_enabled,
            name: dto.title,
            endDate : '(comming soon)',
            isSent : false,
            locations : ['(comming soon)'],
            startDate : dto.created,
            viewUrl: `/message-details/${dto.id}`
        };
    }
}
