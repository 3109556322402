import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, TokenStorageService } from '../../core';

@Component({ template: `{{result}}` })
export class ConfirmPageComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService
  ) { }

  public result: string;

  public async ngOnInit() {
    const key = this.route.snapshot.paramMap.get('key') || this.route.snapshot.queryParamMap.get('key');

    if (key) {
      try {
        const result = await this.authService.confirmAccount(key);

        this.refreshToken(result.token);
        this.router.navigate(['/profile']);
      } catch (error) {
        this.result = 'Please sign in at login page.';
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 1000);
      }
    }
  }

  /**
   * Refreshing token in storage.
   * @param token Refreshing token.
   */
  private refreshToken(token: string): void {
    this.tokenStorageService.token = token;
  }
}
