import { NgSelectizeComponent } from 'ng-selectize/ng-selectize.component';
import {
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../../value-accessor-base';
import { Component, Input, ViewEncapsulation } from '@angular/core';

const DEFAULT_DROPDOWN_CONFIG: any = {
  valueField: 'id',
  labelField: 'text',
  searchField: 'text',
  highlight: false,
  create: false,
  persist: true,
  plugins: ['dropdown_direction', 'remove_button'],
  dropdownDirection: 'down',
  loadThrottle: 1000,
};

/**
 * Dropdown component with selectize.js
 */
@Component({
  selector: 'pb-selectize-dropdown',
  templateUrl: './selectize-dropdown.component.html',
  styleUrls: ['./selectize-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectizeDropdownComponent,
      multi: true,
    },
  ],
})
export class SelectizeDropdownComponent extends ValueAccessorBase<string[]>
  implements OnInit, AfterViewInit {
  private inputValueSnapshot: string;

  constructor() {
    super();
  }

  /**
   * Selectize component.
   * Add private field selectize in NgSelectizeComponent.
   */
  @ViewChild('selectize')
  private selectize: { [P in keyof NgSelectizeComponent]: NgSelectizeComponent[P] } & { selectize: NgSelectizeComponent['selectize'] };

  @Input()
  public allowEmptyOption = false;

  private itemsValue;

  /**
   * Array of items for dropdown.
   */
  @Input()
  public items: any[];

  /**
   * Placeholder for dropdown.
   */
  @Input()
  public placeholder: string;

  /**
   * Used for determine multiselection, for multiselect this value must be setted as null.
   */
  @Input()
  public maxItems = 1;

  /**
   * Direction of dropdown, possible values: 'up', 'down', 'auto'.
   */
  @Input()
  public dropdownDirection: string = null;

  /**
   * Flag for arrow display.
   */
  @Input()
  public hasArrow = false;

  @Input()
  public hasAdd = false;

  /**
   * Allow option creation.
   */
  @Input()
  public create = false;

  /**
   * Allow open on input focus.
   */
  @Input()
  public openOnFocus = true;

  /**
   * Persist items after selection.
   */
  @Input()
  public persist = true;

  /**
   * Is control enabled.
   */
  @Input()
  public enabled = true;

  /**
   * Flag for not remove value on blur for input.
   */
  @Input()
  public preserveOnBlur = false;

  /**
   * Handler of query change which rised by control.
   */
  @Output()
  public queryHandler = new EventEmitter<{ query: string; callback: any }>();

  /**
   * clickHandler emit mouse event when you click in selectize except you click remove button.
   */
  @Output()
  public clickHandler = new EventEmitter<MouseEvent>();
  /**
   * Disabling input for control.
   */
  @Input()
  public disableInput = false;

  @Input()
  public disableDropdownOpen = false;

  /**
   * Formatter for input element to display partial data for example.
   */
  @Input()
  public inputDisplayFormatter: (data: any, escape: any) => string = null;

  /**
   * Force displaying results without sorting.
   */
  @Input()
  public forceDisplayResults = false;

  /**
   * If flag is active, dropdown will have behaviour like input without
   * ability to delete items, just edit.
   * @type {boolean}
   */

  @Input()
  public useInputStrategy = false;


  /**
   * Flag for determine focus state of selectize control.
   * @returns {boolean}
   */
  public isFocused(): boolean {
    const selectizeInstance = this.selectize.selectize;
    if (!selectizeInstance) {
      return false;
    }

    return selectizeInstance.isFocused;
  }

  /**
   * Focus handler.
   * Handles focus like usual input and allow user write values instead of
   * deleting first selected item by default.
   */
  private focusHandler: () => void = () => {
    const selectizeInstance = this.selectize.selectize;
    const checkFillInputValueSnapshot =
      !selectizeInstance.inputValueSnapshot && this.getInputDisplayText();
    if (checkFillInputValueSnapshot) {
      selectizeInstance.inputValueSnapshot = this.getInputDisplayText();
    }

    if (!selectizeInstance.inputValueSnapshot) {
      selectizeInstance.inputValueSnapshot = selectizeInstance.$control_input.val();
    }

    setTimeout(() => {
      if (selectizeInstance.inputValueSnapshot) {
        selectizeInstance.setTextboxValue(selectizeInstance.inputValueSnapshot);
        selectizeInstance.$control_input.css('width', '100%');
      }
    }, 1);
  }

  private itemAddHandler: (value, $item) => void = (value, $item) => {
    const selectizeInstance = (<any>this.selectize).selectize;
    if (value !== selectizeInstance.inputValueSnapshot) {
      selectizeInstance.inputValueSnapshot = null;
    }
  }

  /**
   * Blur handler
   * Restoring value on blur for user input.
   */
  private blurHandler: () => void = () => {
    setTimeout(() => {
      const selectizeInstance = this.selectize.selectize;
      if (selectizeInstance.inputValueSnapshot) {
        selectizeInstance.items = [selectizeInstance.inputValueSnapshot];

        selectizeInstance.addOption({
          id: selectizeInstance.inputValueSnapshot,
          text: selectizeInstance.inputValueSnapshot,
        });
        selectizeInstance.setValue(selectizeInstance.inputValueSnapshot, true);
        selectizeInstance.$control_input.css('width', '100%');
        selectizeInstance.refreshItems();
      }
    }, 1);
  }

  public ngOnInit() {
    if (this.useInputStrategy) {
      this.initializeInputStrategy();
    }
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    if (this.disableDropdownOpen) {
      const selectizeInstance = this.selectize.selectize;
      selectizeInstance.on('dropdown_open', $dropdown => $dropdown.hide());
    }
  }

  /**
   * focused on the ng-selectize address input.
   */
  public focus(): void {
    const selectizeInstance = this.selectize.selectize;
    if (selectizeInstance) {
      selectizeInstance.$control_input.focus();
    }
  }

  /**
   * Initializing input strategy, for more information, see useInputStrategy property.
   */
  private initializeInputStrategy() {
    const interval = 100;
    const handler = setInterval(() => {
      if (this.selectize.selectize) {
        const selectizeInstance = (this.selectize as any).selectize;

        selectizeInstance.on('focus', this.focusHandler);
        selectizeInstance.on('blur', this.blurHandler);
        selectizeInstance.on('item_add', this.itemAddHandler);

        clearInterval(handler);
      }
    }, interval);
  }

  /**
   * Return text which displayed in input of dropdown.
   */
  public getInputDisplayText(): string | null {
    if (
      !this.selectize ||
      !this.selectize.selectize ||
      !this.innerValue
    ) {
      return null;
    }
    const elements = this.selectize.selectize.getItem(
      this.innerValue,
    ) as HTMLElement[];
    if (!elements || elements.length === 0) {
      return null;
    }

    const firstElement = elements[0];
    return firstElement.innerText;
  }

  /**
   * Setup default value for dropdown.
   * @param id Id of value.
   * @param value Default value.
   * @param useExistingOptions Using existing options and change display value.
   */
  public setDefaultValue(
    id: string,
    value: string,
    useExistingOptions: boolean = false,
  ) {
    if (this.selectize && this.selectize.selectize) {
      let options = this.selectize.selectize.options;
      let item = { id: id, text: value, $order: 1 };
      let textSnapshot = null;

      if (useExistingOptions) {
        options = this.selectize.selectize.options;
        item = options[id];
        textSnapshot = item.text;
        item.text = value;
      } else {
        options[id] = item;
        this.selectize.selectize.options = options;
      }

      this.clearRenderCache();
      this.selectize.selectize.options = options;
      this.selectize.selectize.setValue(id);

      if (textSnapshot) {
        options[id].text = textSnapshot;
        this.clearRenderCache();
      }
    }
  }

  /**
   * Force to open dropdown.
   */
  public openDropdown() {
    if (this.selectize) {
      this.selectize.selectize.open();
    }
  }

  /**
   * Force to close dropdown.
   */
  public closeDropdown() {
    if (this.selectize) {
      this.selectize.selectize.close();
    }
  }

  public addItem(optionId: string) {
    const selectizeInstance = this.selectize.selectize;
    selectizeInstance.addItem(optionId, true);
    this.value.push(optionId);
  }

  /**
   * Refreshing internal state of selectize.js.
   *
   * P.S. This is the code to force a purge of the node_modules component ng selectize (HARDCORE)
   */
  public refreshState() {

    if (this.selectize) {
      const selectizeInstance = this.selectize.selectize;

      selectizeInstance.clearCache(); // self.renderCache
      selectizeInstance.clearOptions(); // self.options
      selectizeInstance.renderCache['option'] = {};
      selectizeInstance.refreshOptions();
      selectizeInstance.refreshItems();
      selectizeInstance.refreshState();
    }
  }

  /**
   * Emit mouse event в clickHandler except for pressing the delete button.Possible add eventemitter to remove.
   * @param event mouse event which propagation up from ng selective.
   */
  public onClick(event: MouseEvent & { target: HTMLInputElement }): void {
    if (event.target && event.target.className !== 'remove') {
      this.clickHandler.emit(event);
    }
  }
  /**
   * Seeding dropdown config with additional values.
   */
  public get dropdownConfig() {
    DEFAULT_DROPDOWN_CONFIG.maxItems = this.maxItems;
    if (this.dropdownDirection) {
      DEFAULT_DROPDOWN_CONFIG.dropdownDirection = this.dropdownDirection;
    }

    DEFAULT_DROPDOWN_CONFIG.create = this.create;
    DEFAULT_DROPDOWN_CONFIG.openOnFocus = this.openOnFocus;
    DEFAULT_DROPDOWN_CONFIG.persist = this.persist;

    if (this.inputDisplayFormatter) {
      DEFAULT_DROPDOWN_CONFIG.render = {
        item: this.inputDisplayFormatter,
      };
    }

    if (this.allowEmptyOption) {
      DEFAULT_DROPDOWN_CONFIG.allowEmptyOption = true;
    }

    if (this.forceDisplayResults) {
      DEFAULT_DROPDOWN_CONFIG.score = () => {
        return value => {
          return value.$order;
        };
      };
    }
    DEFAULT_DROPDOWN_CONFIG.load = (query, callback) => {
      this.queryHandler.emit({ query, callback });
    };

    if (this.preserveOnBlur) {
      DEFAULT_DROPDOWN_CONFIG.plugins.push('preserve_on_blur');
    }

    if (this.placeholder) {
      DEFAULT_DROPDOWN_CONFIG.placeholder = this.placeholder;
    }

    return DEFAULT_DROPDOWN_CONFIG;
  }

  private clearRenderCache() {
    if (this.selectize && this.selectize.selectize) {
      this.selectize.selectize.renderCache = {};
    }
  }
}
