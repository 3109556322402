<div class="popup-caption">
  New Activity
</div>
<div class="activities">
  <button class="activity" routerLink="/campaign" md-dialog-close>
    <div class="background">
      <pb-icon name="#iconCoupons" class="icon"></pb-icon>
    </div>
    <div class="activity-name">Coupon</div>
    <div class="activity-cost">{{ campaign | currency: 'USD' : true :'2.2-2'}}</div>
  </button>
  <button class="activity" routerLink="/campaign" md-dialog-close>
    <div class="background">
      <pb-icon name="#iconPromotions" class="icon"></pb-icon>
    </div>
    <div class="activity-name">Promotion</div>
    <div class="activity-cost">{{ promotion | currency: 'USD' : true :'2.2-2'}}</div>
  </button>
  <button class="activity" routerLink="/new-message" md-dialog-close>
    <div class="background">
      <pb-icon name="#iconMessages" class="icon"></pb-icon>
    </div>
    <div class="activity-name">Message</div>
    <div class="activity-cost">{{ message | currency: 'USD' : true :'2.2-2'}}</div>
  </button>
</div>
<button class="close-button" md-dialog-close type="button">
  <pb-icon name="#iconClose" class="close-icon"></pb-icon>
</button>
