import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, forwardRef, Input } from '@angular/core';


/**
 * @deprecated not usable
 */
@Directive({
    selector:
    // tslint:disable-next-line:directive-selector
    '[required][ngModel]pb-age-range-obsolete',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => AgeRangeRequiredValidatorDirective),
        multi: true
    }]
})
export class AgeRangeRequiredValidatorDirective implements Validator {
    private _required: boolean;
    private _onChange: () => void;

    @Input()
    get required(): boolean | string { return this._required; }

    @Input('inputModel')
    public model: { from: string, to: string };

    /**
     * Minimum value allowed.
     */
    @Input('minNumber')
    public min: number = null;

    /**
     * Maximum value allowed.
     */
    @Input('maxNumber')
    public max: number = null;


    set required(value: boolean | string) {
        this._required = value != null && value !== false && `${value}` !== 'false';
        if (this._onChange) {
            this._onChange();
        }
    }

    /**
     * @inheritdoc
     */
    public validate(c: AbstractControl): ValidationErrors | null {

        if (this.required) {
            return this.tryValidateMinMax(c);
        }

        return null;
    }

    private tryValidateMinMax(c: AbstractControl): ValidationErrors | null {

        // if (!this.min && !this.max) {
        //     return null;
        // }

        let value = c.value as { from: string, to: string };

        if (<any>value === 'custom' && this.model) {
            value = this.model;
        }

        if (value) {
            const from = +value.from;
            const to = +value.to;

            if (!from && !to) {
                return null;
            }
            if (!from || !to) {
                return { required: true };
            }

            if (this.min) {
                if (from < this.min) {
                    return { required: true };
                }
                if (to < this.min) {
                    return { required: true };
                }
            }

            if (this.max) {
                if (to > this.max) {
                    return { required: true };
                }
                if (from > this.max) {
                    return { required: true };
                }
            }
        }

        return null;
    }
}
