<header pb-page-header>
  <div left>
    <a class="row centered link" [routerLink]="['/locations']"
       [queryParams]="reference !== undefined ? {r:'profile'}: {}">
      <pb-icon name="#iconBackArrow" class="back-arrow"></pb-icon>
      Locations
    </a>
  </div>
  <div right>
    <div class="buttons">
      <div *ngIf="reference !== undefined">
        <button [routerLink]="['/' + reference]" pb-button type="button" white class="button profile-button">Company
          Profile
        </button>
      </div>
      <button *ngIf="place.id" pb-button class="button" small red (click)="showPopup($event)">Delete</button>
      <button pb-button class="button" small
              (click)="form.onSubmit($event)">{{ place && place.id ? 'Update' : 'Add' }}</button>
      <!--<button pb-button class="button" *ngIf="place && place.id" small (click)="saveAndFinish($event)">Save and Finish</button>-->
    </div>
  </div>
</header>

<section pb-section caption="Location" [spinner]="isLoading" class="section-block">
  <div body>
    <form #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted"
          [pbServerValidation]="errorEmitter">
      <div class="address-grid">
        <label class="col-1" for="street">Location Name*</label>
        <div class="col-2">
          <pb-input name="location" [(ngModel)]="place.name" placeholder="Location Name"
                    class="element" required pbTooltip customElementQuery=".body"></pb-input>
          <pb-checkbox key="isDisplayed" name="isDisplayed" label="Is store location" [value]="isDisplayed"
                       [checked]="isDisplayed" (change)="isDisplayed = !isDisplayed"
                       [(ngModel)]="place.isDisplayed"></pb-checkbox>
        </div>
      </div>

      <div body>
        <div class="address-grid">
          <label class="global-caption address-label col-1" for="address">Select the Country for this address</label>
          <div class="col-2" style="margin-top: 10px;">
            <pb-checkbox-group (onSelect)="placeCountrySelected($event)" [disabled]="isEdit()"
                               [options]="placeCountries">
            </pb-checkbox-group>
          </div>
        </div>

        <div class="address-grid">
          <label class="global-caption address-label col-1" for="address">Select Search Method (A or B)</label>
          <ul class="country-list col-2">
            <li>
              <div class="row">
                <label class="global-caption address-label" for="address">A) Business /Organization name (i.e, Pete's Bike
                  Shop, Plainview, NY)</label>
              </div>
              <div class="grid row-2">
                <pb-address-selectize #addressSelectizeComponent required="false"
                                      [searchBusiness]="true"
                                      [helpText]="'Search by Company Name'"
                                      (changeAddressId)="addressChanged($event)"
                                      [countries]="placeCountry"
                                      [currentAddressStreet]="currentAddressBusiness"
                                      class="search-input element input">
                </pb-address-selectize>
              </div>
            </li>
            <li>
              <div class="row">
                <label class="global-caption address-label" for="address">B) Address (i.e, 55 Main Street, Plainview,
                  NY)</label>
              </div>
              <div class="grid row-2">
                <pb-address-selectize class="search-input element input"
                                      [searchBusiness]="false"
                                      #addressSelectizeComponent
                                      [countries]="placeCountry"
                                      [currentAddressStreet]="currentAddressStreet"
                                      (changeAddressId)="addressChanged($event)">
                </pb-address-selectize>
              </div>
            </li>
          </ul>
        </div>

        <div class="row">
          <label class="global-caption address-label" for="address">Address Search Result</label>
        </div>

        <!--<div class="address-grid">
          <label class="col-1" for="street">House/Building #)</label>
          <pb-input name="street" [(ngModel)]="place.street"
                    placeholder="Street" class="element col-2 input" required
                    [readonly]="true"></pb-input>
        </div>-->

        <div class="address-grid">
          <label class="col-1" for="street">Address</label>
          <pb-input name="street_d" value="{{currentAddressStreet}}"
                    placeholder="Street" class="element col-2 input" required
                    [readonly]="true"></pb-input>
          <input name="street" [(ngModel)]="place.street"
                    type="hidden" readonly
                    placeholder="Street" class="element col-2 input"/>
        </div>

        <div class="address-grid">
          <label class="col-1" for="city">City/Town</label>
          <pb-input name="city" placeholder="City" [(ngModel)]="place.city" class="element col-2 input"
                    [readonly]="true" required>
          </pb-input>
        </div>

        <div class="address-grid">
          <label class="col-1" for="state">State/Province</label>
          <pb-input name="state" placeholder="State" [(ngModel)]="place.state"
                    class="element col-2 input" [readonly]="true" required></pb-input>
        </div>

        <div class="address-grid">
          <label class="col-1" for="zip">Zip/Postcode</label>
          <pb-input name="zip" placeholder="ZIP" [(ngModel)]="place.zip" class="element col-2 input"
                    minlength="5"></pb-input>
        </div>

        <div class="address-grid">
          <label class="col-1" for="country">Country</label>
          <pb-input id="country-selection" class="state-dropdown element size-medium col-3"
                    placeholder="Country" [(ngModel)]="place.country" name="Country" required [readonly]="true"></pb-input>
        </div>

        <div class="address-grid">
          <label class="col-1" for="phone">Contact Phone#</label>
          <div style="display: flex; gap: 10px;">
            <!--<div style="width: 15%">
              <pb-select label="Country Code*"
                         (change)="countryCodeChanged($event.target.value)">
                <option [value]="country.dial_code" *ngFor="let country of placeCountries">
                  {{country.text}}</option>
              </pb-select>
            </div>-->
            <div style="width: 15%">

              <pb-input name="country_calling_code"
                        [(ngModel)]="place.country_calling_code"
                        placeholder="Code"
                        [readonly]="true"
                        class="element input input-as-selectize"
                        required minlength="2"></pb-input>
            </div>
            <div style="width: 63%">
              <pb-input name="contact_phone" type="tel" [(ngModel)]="place.contactPhone"
                        placeholder="Phone" class="element input"></pb-input>
            </div>
          </div>
        </div>
        <div class="address-grid">
          <label class="col-1" for="contact">Contact Name</label>
          <pb-input name="contact_name" [(ngModel)]="place.contactName"
                    placeholder="First and Last Name" class="element input size-medium col-2" pbTooltip customElementQuery=".body"></pb-input>
        </div>
        <div class="address-grid">
          <label class="col-1" for="email">Contact Email</label>
          <pb-input name="contact_email" [(ngModel)]="place.contactEmail" placeholder="Email"
                    class="element input size-medium col-2" [pattern]="emailMask" pbTooltip customElementQuery=".body"></pb-input>
        </div>
      </div>
    </form>
  </div>
</section>
