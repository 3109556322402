/**
 * Paged result for merchant's messages.
 */
import { PagedDto } from '../../pagination/dtos/paged.dto';

export class MessagesDto extends PagedDto<MessageDto> {

}


/**
 * Dto for merchant's message
 */
export interface MessageDto {
    id: number;
    title: string;
    message: string;
    message_type: string;
    is_enabled: boolean;
    logo: string;
    created: string;
    message_view_count: number;
    message_click_count: number;
}
