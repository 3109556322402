import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AutoMessagesPageComponent } from './auto-messages.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    AutoMessagesPageComponent
  ],
  exports: [
    AutoMessagesPageComponent
  ]
})
export class AutoMessagesPageModule { }
