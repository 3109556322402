<pb-breadcrumbs *ngIf="company" [items]="['Activities', company.name]"></pb-breadcrumbs>

<header pb-page-header>
  <div left>
    <a class="row pointer" (click)="returnBack($event)">
      <pb-icon name="#iconBackArrow" class="back-arrow"></pb-icon>{{ company.name }}
    </a>
  </div>
  <div right>
    <div class="row centered">
      <button class="button-link" *ngIf="isAbleToEnd" (click)="openPopup($event)">End Coupon</button>
      <button class="button-link" *ngIf="isAbleToEdit" (click)="editCoupon($event)">Edit</button>
      <button pb-button class="button" small (click)="sendMessage($event)">Send a Message</button>
    </div>
  </div>
</header>

<section class="auto-height" pb-section caption="{{ company.name }}">
  <div body>
    <div class="row">
      <div class="left">
        <pb-thumbnail *ngIf="thumbnail" name="Coupon Thumbnail" [showSize]="false" [width]="335" [height]="280"
          [imageBase64]="thumbnail" [isImageUploaded]="true">
        </pb-thumbnail>
        <pb-activity-info *ngIf="thumbnail" [activity]="activity" [company]="company" style="width: 335px;">
        </pb-activity-info>
        <br *ngIf="thumbnail">
        <div class="global-caption">
          Coupon Image
        </div>
        <div class="image-container">
          <img src="{{image}}" alt="" class="image">
        </div>
        <pb-activity-info [activity]="activity" [company]="company"></pb-activity-info>
      </div>
      <div class="right">
        <div class="text-block">
          <div class="global-caption">
            Coupon Runs
          </div>
          <div class="text" *ngIf="coupon">
            {{ dateRange }}
            <br>
            <br>
            <div *ngFor="let time of operationHours">
              <span class="day-of-week">{{time.day}}</span><span class="time">{{time.time}}</span>
              <br>
            </div>
          </div>
          <br>
          <div>
            <strong>Redeem InStore Coupon Limit</strong> - {{redeemInStoreLimit}}
            <br>
            <strong>Redeem Online Coupon Limit</strong> - {{redeemOnlineLimit}}
          </div>
        </div>
        <div class="text-block" *ngIf="keywords && keywords.length > 0">
          <div class="global-caption">
            Keywords (optional)
          </div>
          <div class="text">
            <span *ngFor="let keyword of keywords; let i = index">
              {{keyword}}<span *ngIf="i !== keywords.length - 1">, </span>
            </span>
          </div>
        </div>
        <div class="text-block">
          <div class="global-caption">
            Locations Valid ({{locations.length}})
          </div>
          <div class="text">
            <ul class="locations-list">
              <li *ngFor="let location of locations">
                {{location.name}} ({{location.address.street}})
              </li>
            </ul>
          </div>
        </div>
        <div class="text-block">
          <div class="global-caption">
            Target Recipients
          </div>
          <div class="text">
            <div>• Marketing Distance {{activity.radius}}</div>
            <div *ngIf="interests && interests.length > 0">
              • <span *ngFor="let interest of interests; let i = index">
                {{interest}}<span *ngIf="i !== interests.length - 1">, </span>
              </span>
            </div>
          </div>
        </div>
        <div class="text-block mt-0">
          <pb-info-item caption="yrs old">
            {{yearsRange}}
          </pb-info-item>
          <pb-info-item caption="gender">
            {{gender}}
          </pb-info-item>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="auto-height" pb-section caption="Coupon Details">
  <div body>
    <div class="coupon-details-container" *ngIf="coupon?.online && coupon?.onlineInstructions">
      <div class="coupon-row" *ngIf="coupon?.online">
        <div class="global-caption">Online Coupon</div>
        <div class="text">{{coupon.online}}</div>
      </div>
      <div class="coupon-row" *ngIf="coupon?.onlineInstructions">
        <div class="global-caption">Online Coupon Instructions</div>
        <div class="text">{{coupon.onlineInstructions}}</div>
      </div>
    </div>
    <div class="coupon-details-container" *ngIf="coupon?.isRedeemable && coupon?.inStoreInstructions">
      <div class="coupon-row" *ngIf="coupon?.in_store_type === 'text'">
        <div class="global-caption">Store Coupon</div>
        <div class="text">{{coupon.store}}</div>
      </div>
      <div class="coupon-row" *ngIf="coupon?.in_store_type !== 'text'">
        <div class="global-caption">Store Coupon</div>
        <div class="image-container">
          <img src="{{coupon?.in_store_image}}" alt="" class="image">
        </div>
      </div>
      <div class="coupon-row" *ngIf="coupon?.in_store_type === 'barcode'">
        <div class="global-caption">Store Coupon</div>
        <div class="text">{{coupon.store}}</div>
      </div>
      <div class="coupon-row" *ngIf="coupon?.inStoreInstructions">
        <div class="global-caption">Store Coupon Instructions</div>
        <div class="text">{{coupon.inStoreInstructions}}</div>
      </div>
    </div>
    <div class="coupon-details-container-wide" *ngIf="coupon?.terms && coupon?.rules">
      <div *ngIf="coupon?.terms">
        <div class="global-caption">Terms And Conditions</div>
        <div class="text pre-wrap">{{coupon.terms}}</div>
      </div>
    </div>
    <div class="coupon-details-container-wide" *ngIf="coupon?.terms && coupon?.rules">
      <div *ngIf="coupon?.rules">
        <div class="global-caption">Rules And Restrictions</div>
        <div class="text pre-wrap">{{coupon.rules}}</div>
      </div>
    </div>
  </div>
</section>

<section class="auto-height" pb-section caption="Coupon Stats (Total)">
  <div body>
    <pb-stats [impressions]="coupon?.impressions || 0" [redemptions]="coupon?.redemptions || 0"
      [likes]="coupon?.likes || 0"></pb-stats>
  </div>
</section>