import {Component, Input, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, ValidationErrors, Validator} from '@angular/forms';

import {ValueAccessorBase} from '../../value-accessor-base';
import {deepCopy} from '../../../../utils';

/**
 * Schedle component.
 */
@Component({
  selector: 'pb-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, useExisting: ScheduleComponent, multi: true
    },
    {
      provide: NG_VALIDATORS, useExisting: ScheduleComponent, multi: true
    }]
})
export class ScheduleComponent extends ValueAccessorBase<{ [key: string]: string | boolean }> implements Validator {

  @ViewChildren(NgModel)
  public models: QueryList<NgModel>;

  /**
   * If the component shown as basic view.
   */
  @Input()
  public isBasicView = true;

  @Input()
  public isActive = true;

  @Input()
  public isCoupon = true;

  @Input()
  public canToggleView = true;

  @Input()
  public caption = 'Define time for all days';

  @Input()
  public advancedCaption = 'Define specific days & time';

  /**
   * A list of day views.
   */
  public days = [
    new DayView('mon', 'monday'),
    new DayView('tue', 'tuesday'),
    new DayView('wed', 'wednesday'),
    new DayView('thu', 'thursday'),
    new DayView('fri', 'friday'),
    new DayView('sat', 'saturday'),
    new DayView('sun', 'sunday')
  ];

  /**
   * Basic day view.
   */
  public basicDay = new DayView('', 'monday');

  public validate(c: AbstractControl): ValidationErrors | null {
    if (this.models) {
      let valid = true;
      this.models.filter(model => !!model.value).forEach(model => {
        model.control.updateValueAndValidity();
        if (model.invalid) {
          valid = false;
        }
      });

      if (!valid) {
        return {required: true};
      }
    }

    return null;
  }

  /**
   * .ctor
   */
  constructor() {
    super();
    this.value = {};
  }


  /**
   * Handles view toggling.
   */
  public toggleView(): void {
    this.isBasicView = !this.isBasicView;
    const value = deepCopy(this.value);
    if (this.isBasicView) {
      for (const day of this.days) {
        value[day.field + '_start'] = value['monday_start'];
        value[day.field + '_end'] = value['monday_end'];
      }
    } else {
      for (const day of this.days) {
        value[day.field + '_start'] = undefined;
        value[day.field + '_end'] = undefined;
      }
    }
    this.value = value;
  }

  /**
   * Handles day checkbox switching.
   */
  public toggleDay(isChecked: boolean, day: DayView): void {
    day.isActive = isChecked;
    this.value[day.field + '_active'] = isChecked;
    if (!isChecked) {
      this.value[day.field + '_start'] = undefined;
      this.value[day.field + '_end'] = undefined;
    }
  }

  /**
   * Handle advanced timepicker change.
   */
  public advancedTimepickerChange(event, day: DayView): void {
    if (event.target.value === 'closed') {
      day.isActive = false;
      this.value[day.field + '_active'] = false;
      this.value[day.field + '_start'] = undefined;
      this.value[day.field + '_end'] = undefined;
    }
  }

  /**
   * Handles timepicker changed event.
   */
  public timepickerChange(event, suffix: string) {
    for (const day of this.days) {
      this.value[`${day.field}_${suffix}`] = event.target.value;
    }
  }

  private isValidDateString(input: string) {
    const regex = /[0-9\:^PM]+/g;
    const isValid = regex.test(input);
    if (isValid) {
      this.days.forEach(day => {
        if (this.value[input] !== undefined) {
          day.isActive = true;
        }
      });
    }
    return isValid;
  }
}

class DayView {
  constructor(public name: string,
              public field: string,
              public isActive = false) {
  }
}
