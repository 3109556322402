﻿import { Subscription } from 'rxjs/Rx';
import {Component, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MdDialog } from '@angular/material';

import {
  ErrorHandlerService,
  MerchantsCoupon,
  CouponsService,
  MerchantService,
  MessagesService,
  AuthService,
  DataTransferService,
  ServerError,
  TargetAudience,
  NewMessage,
  CategoriesService,
  UtilsService, LoadedImage,
} from '../core';
import { PopupResult, PopupService } from '../popup';
import {HttpErrorResponse, HttpEventType, HttpResponse} from "@angular/common/http";
import {b64toBlob, emailRegex, nonCodedPhoneMask, websiteUrlRegex} from "../../utils";
import {ImagesService} from "../campaign/services/campaign-service/images.service";
import {BetterCropperPopupComponent} from "../shared";

const VIDEO_TYPE_MP4 = "video/mp4";
const VIDEO_TYPE_QUICKTIME = "video/quicktime";

/**
 * New message component.
 */
@Component({
  selector: 'pb-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss']
})
export class NewMessageComponent implements OnInit, OnDestroy {
  private routePreviousUrl: Subscription;

  /**
   * .ctor
   * @param {CategoriesService} categoriesService Categories service.
   * @param {MdDialog} dialog Material design dialog.
   * @param {PopupService} popupService Popup service.
   * @param {MerchantService} merchantService Merchant service.
   * @param {MessagesService} messagesService Message service.
   * @param {AuthService} authService Auth service.
   * @param {DataTransferService} dataTransferService Data transfer service.
   * @param {Router} router Angular router.
   */
  private readonly defaultImageWidth = 335;
  private readonly defaultImageHeight = 375;
  private readonly customImageWidth = 375;
  private readonly customImageHeight = 375;

  public mediaType: string = "image";
  public mediaTypeSelector = [
    { title: "Image", type: "image", icon: "photo" },
    { title: "Video", type: "video", icon: "video" },
  ];
  public videoFile: File;

  public videoUploadInstructions: string = `
  We recomend using landscape videos that are 1080x720 in resolution.
  `;

  public imageUploadInstructions: string = `
  We recomend using images that are 1080x720 in resolution.
  Recomended aspect ratio is 1.5:1
  `;

  public videoThumbUploadInstructions: string = `
  We recomend using images that are 1080x720 in resolution.
  Recomended aspect ratio is 1.5:1
  `;

  public imageFile = "";
  public videoThumbImage = "";
  public couponVideo: number = undefined;

  public autoCompleteConfig: any = {'class': 'input', 'placeholder': 'Example: Sign up for Hotel Rewards and Loyalty Program', 'sourceField': 'label'};

  constructor(
    private categoriesService: CategoriesService,
    private dialog: MdDialog,
    private popupService: PopupService,
    private merchantService: MerchantService,
    private messagesService: MessagesService,
    private authService: AuthService,
    private dataTransferService: DataTransferService,
    private router: Router,
    private imageService: ImagesService,
    private utilService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private couponsService: CouponsService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.routePreviousUrl = this.router.events
      .filter(e => e instanceof NavigationEnd)
      .subscribe((v: NavigationEnd) => {
        localStorage['previousUrl'] = v.url;
      });
    }

  /**
   * A merchant id.
   */
  private merchantId: number;
  public pushAlertSamples = [];

  /**
   * A message to be sent.
   */
  public message: NewMessage = new NewMessage();

  public coupon: MerchantsCoupon;

  @ViewChild('form')
  public form: NgForm;

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {

    this.utilService
      .getPushAlertSamples()
      .then((list) =>
        list.map((m, idx) => {
          return { id: idx, label: m };
        })
      )
      .then((list) => {
        this.pushAlertSamples = list;
        this.pushAlertSamples.push({id: list.length, label: 'Concierge Contact'})
      })
      .catch((err) => console.error(err));

    this.message.targetAudience = new TargetAudience();
    this.message.audienceCount = 0;
    const userSubscription = this.authService.onUserChanged.subscribe(
      async result => {
        const user = result;
        if (user) {
          this.merchantId = user.merchantId;

          this.getUserCount();

          this.activatedRoute.params.subscribe(
            async (params): Promise<void> => {
              const id: number = params['id'];
              const type: string = params['type'];
              if (id && type) {
                if (type === 'campaign') {
                  this.coupon = await this.couponsService.getCoupon(
                    user.merchantId,
                    id
                  );
                  if (this.coupon.targetAuidience) {
                    this.message.targetAudience.ageStart = this.coupon.targetAuidience.ageStart;
                    this.message.targetAudience.ageEnd = this.coupon.targetAuidience.ageEnd;

                    if (this.coupon.targetAuidience.gender === 'F') {
                      this.message.targetAudience.gender = 'F';
                    }
                    if (this.coupon.targetAuidience.gender === 'M') {
                      this.message.targetAudience.gender = 'M';
                    }
                    if (!this.coupon.targetAuidience.gender) {
                      this.message.targetAudience.gender = 'B';
                    }
                    if (this.coupon.targetAuidience.interest) {
                      this.message.targetAudience.interests = this.coupon.targetAuidience.interest;
                    }
                  }
                }
              }
            }
          );

          if (userSubscription) {
            userSubscription.unsubscribe();
          }
        }
      }
    );

    const categories = await this.categoriesService.getFlatLeveled();

    this.message.allInterests = categories.map(o => {
      return { id: o.id, text: o.pluralName };
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.mediaType = this.getMediaType();
  }

  /**
   * For get the number of mobile customers to be messaged.
   * @param result This is TargetAudience from form target-popap.component.
   */
  public resultTargetAudience(result: TargetAudience): void {
    if (result) {
      this.message.targetAudience = result;
      this.getUserCount();
    }
  }

  /**
   * @inheritdoc
   */
  private async getUserCount(): Promise<void> {
    this.message.audienceCount = await this.merchantService.getTargetAudienceCount(
      this.merchantId,
      this.message.targetAudience
    );
  }

  /**
   * Opens information popup.
   */
  private async openInfoPopup(): Promise<void> {
    const result = await this.popupService.done(
      'Your message has been successfully sent!',
      'Message Sent',
      'View Message'
    );
    this.form.resetForm();
    for (const controlName of Object.keys(this.form.controls)) {
      const control = this.form.controls[controlName];
      control.setValue('');
    }

    if (result === PopupResult.NO) {
      this.router.navigate(['/dashboard']);
    }
    if (result === PopupResult.YES) {
      this.dataTransferService.set({ message: this.message });
      this.router.navigate(['/message-details', this.message.id]);
    }
  }

  /**
   * Handles on submit form event.
   * @param {NgForm} form Angular form.
   */
  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid && this.message.audienceCount > 0) {

      if (this.message.actionURL) {
        if (
          !this.message.actionURL.startsWith("http://") &&
          !this.message.actionURL.startsWith("https://")
        ) {
          this.message.actionURL =
            "http://" + this.message.actionURL;
        }
      }


      if(this.message.actionText.length > 0 && !this.message.isConcierge && this.message.actionURL.length === 0) {
        this.popupService.info_sticky('Please enter a valid URL');
        return;
      }

      if(this.message.actionText.length > 0 && this.message.isConcierge && this.message.actionEmail.length === 0 && this.message.actionPhone.length === 0) {
        this.popupService.info_sticky('Please enter a valid phone number or email');
        return;
      }

      const spinner = this.popupService.progresSpinner();
      const response = await this.messagesService.createMessageWithMedia(
        this.merchantId,
        this.message
      ).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            spinner.componentInstance.updateProgress(percentDone);
          } else if (event instanceof HttpResponse) {
            this.message = <any>event.body;
            this.videoFile = this.message.video;

            this.openInfoPopup();
            // this.removeLogoClicked();
            spinner.close();
          } else if (event instanceof HttpErrorResponse) {
            spinner.close();
          }
        },
        (error) => {
          spinner.close();

          const error_data = error.error;
          if (error_data && error_data.error)
            this.popupService.info_sticky(error_data.error);
          else
            this.popupService.info_sticky(
              "An error happened while sending the message."
            );
        }
      );
      /*if (response instanceof NewMessage) {
        this.message = response;
      } else {
        this.handleServerError(response);
      }*/
    }
  }

  public ngOnDestroy(): void {
    if (this.routePreviousUrl) {
      this.routePreviousUrl.unsubscribe();
    }
  }

  /**
   * Invalidating controls on form.
   * @param serverError Server error returned by remote.
   */
  private handleServerError(serverError: ServerError) {
    const map = {
      title: 'title',
      message: 'message'
    };

    this.errorHandlerService.invalidateForm(this.form, map, serverError);
  }

  setActionText = (text) => this.message.actionText = text;

  private presentLogoCropperPopup(
    w: number,
    h: number,
    image: string | ArrayBuffer,
    caption?: string
  ): Promise<string> {
    const dialogRef = this.dialog.open(BetterCropperPopupComponent, {
      data: {
        image,
        size: this.defaultImageWidth,
        height: this.defaultImageHeight,
        caption,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  public async changeMediaType(event): Promise<void> {
    this.mediaType = event;
  }

  public getMediaType(): string {
    if (this.message.video) {
      if (this.message.video) return "video";
    }
    return "image";
  }

  public hasCouponImage(): boolean {
    if (this.message.image) return true;
    return false;
  }

  public hasVideo(): boolean {
    if (this.message.video) {
      return true;
    }
    return false;
  }

  /*public hasVideoThumb(): boolean {
    if (this.message.video) {
      return this.message.video_thumb !== null;
    }
    return false;
  }*/

  public removeVideoClicked(): void {
    this.videoFile = null;
    this.message.video = null;
  }

  public removeThumbnailClicked(): void {
    this.message.video = null;
  }

  public async videoUploaded(video: File) {
    if (!video) return;

    if (video.type !== VIDEO_TYPE_MP4 && video.type !== VIDEO_TYPE_QUICKTIME) {
      this.popupService.info_sticky("Please upload a MP4/MOV video.");
      return;
    }

    const videoNode = document.querySelector('video')
    const canPlay = videoNode.canPlayType(video.type)
    const fileURL = URL.createObjectURL(video)
    videoNode.src = fileURL
    this.videoFile = video
    this.message.video = video;
  }

  public removeLogoClicked(): void {
    this.message.image = null;
  }

  public async logoUploaded(images: LoadedImage[]) {
    this.removeVideoClicked();

    const fullViewCaption = "Full view image";
    const b64ImageFull = await this.presentLogoCropperPopup(
      this.customImageWidth,
      this.customImageHeight,
      images[0].base64String,
      fullViewCaption
    );

    if (b64ImageFull) {
      this.imageFile = b64ImageFull
      this.message.image = b64toBlob(b64ImageFull);
    }
  }


  onInputChangedEvent(val: string) {
    setTimeout(() => {
      this.message.isConcierge = val === this.pushAlertSamples[this.pushAlertSamples.length - 1].label;
    }, 400);
    // this.message.actionText = val;
  }

  onSelect(item: any) {
    this.message.isConcierge = item.id === this.pushAlertSamples.length - 1;
    this.message.actionText = item.label;
  }

  public get phoneMask() {
    return nonCodedPhoneMask();
  }

  public get websiteUrlMask(): string {
    return websiteUrlRegex();
  }

  public get emailMask() {
    return emailRegex();
  }
}
