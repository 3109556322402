<div *ngIf="isEditable; else static">
  <div body>
    <form id="card-form" #form (ngSubmit)="onSubmit(form, $event)" [class.form-submitted]="isSubmitted" class="form">
      <div class="row">
        <pb-input label="Name on Card*" name="card_name" class="element full" [(ngModel)]="card.name" required
          placeholder="John Doe"></pb-input>
      </div>
      <div class="row card">
        <div class="global-caption element big">Card Number*
          <div name="card_number" class="input"></div>
        </div>
        <div class="global-caption element medium">CVV*
          <div name="cvc" class="input"></div>
        </div>
        <div class="global-caption element wide">Expiry*
          <div name="card_year" class="input"></div>
        </div>
      </div>
      <div class="row">
        <pb-input label="Billing Address*" name="billing_address" class="element full" required
          [(ngModel)]="address.billingAddress" placeholder="1234 Main St."></pb-input>
      </div>
      <div class="row">
        <pb-input label="City*" name="city" class="element wide" [(ngModel)]="address.city" required
          placeholder="New York">
        </pb-input>
        <pb-select label="State*" name="state" class="element states" [hasBigArrow]="true" [(ngModel)]="address.state"
          (change)="stateChanged($event)" required>
          <option [value]="state" *ngFor="let state of states" [selected]="address.state === state">{{state}}</option>
        </pb-select>
        <pb-input *ngIf="showCustomState" name="custom-state" class="element small" [(ngModel)]="address.customState"
          required placeholder="State"></pb-input>
        <div class="global-caption element wide">
          Zip / Postal Code*
          <div name="zip" class="input"></div>
        </div>
        <pb-select label="Country*" name="country" class="element states" [hasBigArrow]="true" [(ngModel)]="address.country" (change)="countryChanged($event)" required>
          <option [value]="country" *ngFor="let country of countries" [selected]="address.country === country">{{country}}</option>
        </pb-select>
      </div>
    </form>
  </div>
</div>

<ng-template #static>
  <div body>
    <div class="row">
      <div class="element">
        <div class="global-caption">Name on Card*</div>
        <div class="text" *ngIf="card.name" id="billing-card-name-text">{{card.name}}</div>
        <div class="text" *ngIf="!card.name">John Doe</div>
      </div>
    </div>
    <div class="row">
      <div class="element">
        <div class="global-caption">Card Number*</div>
        <div class="text" *ngIf="card.number">{{card.number}}</div>
        <div class="text" *ngIf="!card.number">**** **** **** ****</div>
      </div>
      <div class="element small">
        <div class="global-caption">CVC*</div>
        <div class="text" *ngIf="card.number">***</div>
      </div>
      <div class="element medium">
        <div class="global-caption">Exp. Date</div>
        <div class="text" *ngIf="card.number">{{ card.expirationDate }}</div>
      </div>
    </div>
    <div class="row">
      <div class="element">
        <div class="global-caption">Billing Address*</div>
        <div class="text" *ngIf="address.billingAddress" id="billing-address-text">{{address.billingAddress}}</div>
        <div class="text" *ngIf="!address.billingAddress" id="billing-address-text">1234 Main St. 2</div>
      </div>
    </div>
    <div class="row big">
      <div class="element">
        <div class="global-caption">City*</div>
        <div class="text" *ngIf="address.city" id="billing-city-text">{{address.city}}</div>
        <div class="text" *ngIf="!address.city">{{address.city}}</div>
      </div>
      <div class="element small">
        <div class="global-caption">State/Province*</div>
        <div class="text" *ngIf="state">{{ state }}</div>
        <div class="text" *ngIf="!address.state">--</div>
      </div>
      <div class="element big">
        <div class="global-caption">Zip / Postal Code*</div>
        <div class="text" *ngIf="address.zip">{{address.zip}}</div>
        <div class="text" *ngIf="!address.zip">*****</div>
      </div>
    </div>
  </div>
</ng-template>