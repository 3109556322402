<form #form="ngForm" (ngSubmit)="onSubmit(form)" name="keyword-form" class="keyword-form"
	[class.coupon-view]="isCoupon">

	<div class="keywords-container">
		<div class="keywords-container-keyword" *ngFor="let keyword of keywordsListObservable$ | async">
			<p class="keywords-container-keyword-text">{{keyword}}</p>
			<button pb-button white small class="btn keywords-container-keyword-remove" type="button"
				(click)="removeKeyword(keyword)">
				<span class="close"></span>
			</button>
		</div>
	</div>
	<br><br>
	<div class="add-keyword">
		<pb-input class="input add-keyword-input full-width" [(ngModel)]="inputKeyword" name="keyword"
			placeholder="Add a keyword" type="text">
		</pb-input>
		<button pb-button white small class="btn add-keyword-button" (click)="onSubmit(form)" type="button"><span
				class="add"></span></button>
	</div>
</form>

<ng-content></ng-content>