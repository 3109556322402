import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import {
  CropperSettings,
  ImageCropperComponent
} from 'ng2-img-cropper';
import { Subject } from 'rxjs/Rx';

const PREVIEW_WIDTH = 640;
const PREVIEW_HEIGHT = 320;

/**
 * Cropper popup component.
 */
@Component({
  selector: 'pb-cropper-popup',
  styleUrls: ['./cropper-popup.component.scss'],
  templateUrl: './cropper-popup.component.html',
  entryComponents: [ImageCropperComponent]
})
export class CropperPopupComponent implements OnInit {
  private image: any;
  public isImageFitted = false;
  public supportFit$: Subject<boolean> = new Subject();

  /**
   * .ctor
   * @param {MdDialogRef<CropperPopupComponent>} dialog This dialog reference.
   * @param {any} data Data params transfered to th dialog.
   */
  constructor(
    private dialog: MdDialogRef<CropperPopupComponent>,
    @Inject(MD_DIALOG_DATA) public data: any
  ) { }

  /**
   * Third-party cropper settings.
   */
  public cropperSettings = new CropperSettings();

  /**
   * Third-party image cropper component reference from view.
   */
  @ViewChild(ImageCropperComponent)
  public cropper: ImageCropperComponent;

  /**
   * Output image adjusted after cropping.
   */
  public outputImage: any = {};

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.canvasWidth = PREVIEW_WIDTH;
    this.cropperSettings.canvasHeight = PREVIEW_HEIGHT;
    this.cropperSettings.cropperClass = "cropper";
    this.cropperSettings.croppingClass = "cropper2";

    if(this.data.canvasHeight && this.data.canvasWidth) {
      this.cropperSettings.canvasWidth = this.data.canvasWidth;
      this.cropperSettings.canvasHeight = this.data.canvasHeight;
    }
    
    if (this.data.size) {
      this.cropperSettings.width = this.data.size;
      this.cropperSettings.height = this.data.height;
      this.cropperSettings.croppedWidth = this.data.size;
      this.cropperSettings.croppedHeight = this.data.height;
    } else if (this.data.croppedWidth && this.data.croppedHeight) {
      this.cropperSettings.width = this.data.croppedWidth;
      this.cropperSettings.height = this.data.croppedHeight;
      this.cropperSettings.croppedWidth = this.data.croppedWidth;
      this.cropperSettings.croppedHeight = this.data.croppedHeight;
    }

    this.cropperSettings.rounded = this.data.rounded || false;
    this.cropper.settings = this.cropperSettings;

    const image = new Image();
    image.src = this.data.image;
    image.onload = async () => {
      this.image = image;
      this.supportFit$.next(true);
      await this.setImage(image);
    };
  }

  private setImage(image: any): Promise<void> {
    return new Promise<void>(resolve => {
      if (-1 * window.navigator.userAgent.indexOf('Gecko') <= -1) {
        setTimeout(() => {
          this.cropper.setImage(image);
          // tslint:disable-next-line:no-magic-numbers
        }, 500);
      } else {
        this.cropper.setImage(image);
      }

      resolve();
    });
  }

  public async revertFit(): Promise<void> {
    this.cropper.reset();
    await this.setImage(this.image);
    this.isImageFitted = false;
  }

  public async fitImage(): Promise<void> {
    const canvas = document.createElement('canvas');
    const imageSize = this.data.size;
    const aspectRatio = Math.min(
      imageSize / this.image.width,
      this.data.height / this.image.height
    );

    const aspectSize = {
      width: this.image.width * aspectRatio,
      height: this.image.height * aspectRatio
    };

    canvas.width = this.data.size;
    canvas.height = this.data.height;

    const context = canvas.getContext('2d');

    context.drawImage(this.image, 0, 0, this.data.size, this.data.height);

    const base64Image = canvas.toDataURL();
    const image = new Image();
    image.src = base64Image;

    this.cropper.reset();
    await this.setImage(image);
    this.isImageFitted = true;
  }

  /**
   * Handles the dialog closing with passed the output base64 image string.
   */
  public closeDialog() {
    this.dialog.close(this.outputImage.image);
  }
}
