import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  forwardRef
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { switchMap } from 'rxjs/operators';
import { NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../../value-accessor-base';

/**
 * Message component.
 */
@Component({
  selector: 'pb-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => KeywordsComponent), multi: true }]
})
export class KeywordsComponent extends ValueAccessorBase<any> implements OnInit {

  @ViewChild('form')
  public form: NgForm;

  @Output()
  public keywordsChange = new EventEmitter();

  @Input()
  public keywords$: Subject<string>;

  @Input()
  public isCoupon: boolean;

  public keywordsListObservable$ = new Observable<string[]>();

  public keyword$ = new Subject<string>();

  public removeKeyword$ = new Subject<string>();

  public inputKeyword = '';

  public ngOnInit(): void {
    this.keywordsListObservable$ = this.keywords$.pipe(
      switchMap((keywords: string) => {
        let keywordsList = [];
        if (keywords !== '') {
          keywordsList = keywords.split(',')
            .map(val => val.trim());
        }

        const keywordsArray = keywordsList.filter(keyword => keyword.length);
        const add$ = this.createAddKeywordsStream(keywordsArray);
        const rem$ = this.createRemoveKeywordsStream(keywordsArray);

        return add$.merge(rem$).startWith(keywordsArray);
      })
    );
  }

  public createAddKeywordsStream(keywords): Observable<string[]> {
    return this.keyword$.scan((acc, keyword: string) => {
      keyword = keyword.trim();
      if (acc.indexOf(keyword) === -1 && keyword !== '') {
        acc.unshift(keyword);
      }
      this.keywordsChange.emit(acc);
      return acc;
    }, keywords);
  }

  public createRemoveKeywordsStream(keywords): Observable<string[]> {
    return this.removeKeyword$.scan((acc, keyword: string) => {
      keyword = keyword.trim();
      if (acc.indexOf(keyword) !== -1) {
        acc.splice(acc.indexOf(keyword), 1);
      }
      this.keywordsChange.emit(acc);
      return acc;
    }, keywords);
  }

  public onSubmit(form: NgForm): void {
    if (form.valid) {
      const kw = form.controls['keyword'].value.replace(/[\W_]+/g,' ');
      kw.split(' ').forEach(x => this.keyword$.next(x));
      form.controls['keyword'].setValue('');
    }
  }

  public removeKeyword(removeKeyword: string): void {
    this.removeKeyword$.next(removeKeyword);
  }
}
