import {ServerErrorMapper} from '../error-handler/mappers/server-error.mapper';
import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {BehaviorSubject, Observable} from 'rxjs/Rx';

import {environment} from '../../../../environments/environment';
import {TokenStorageService} from '../token-storage.service';
import {MerchantService} from '../merchant/merchant.service';
import {HttpStatusCode} from '../http-status-code.enum';
import {UserMapper} from './mappers/user.mapper';

import {ServerErrorDto} from '../error-handler/dtos/server-error.dto';
import {User} from '../../models/user';
import {Result} from '../../models/result';
import {ServerError} from '../../models/server-error';

const AUTH_URL = 'auth';
const MERCHANTS_URL_PART = 'merchants';
const TOKEN_URL_PART = 'token';

/**
 * Authorization service.
 */
@Injectable()
export class AuthService {
  /**
   * .ctor
   * @param http Http
   */
  constructor(
    private http: Http,
    private userMapper: UserMapper,
    private tokenService: TokenStorageService,
    private merchantService: MerchantService,
    private serverErrorMapper: ServerErrorMapper,
  ) {
  }

  /**
   * The latest redirect URL stored.
   */
  public redirectUrl: string;

  /**
   * Subscription subject which keeps the latest user state and notifies subscribers.
   */
  public onUserChanged = new BehaviorSubject<User>(null);

  /**
   * Return token from local storage.
   */
  public get currentUserToken(): string {
    return localStorage.token;
  }

  /**
   * Registers a new account.
   * @param {string} first_name First Name.
   * @param {string} last_name Last Name.
   * @param {string} email Email.
   * @param {string} password1 Password.
   * @param {string} password2 Confirm password.
   * @param {string} referral Referral Code.
   */
  public registerAccount(
    first_name: string,
    last_name: string,
    email: string,
    password1: string,
    password2: string,
    referral: string = null
  ): Promise<void> {
    const body = {
      first_name: first_name,
      last_name: last_name,
      contact: first_name + ' ' + last_name,
      email: email,
      password1: password1,
      password2: password2,
      referral: referral ? referral : '',
    };
    return this.http
      .post(`${environment.apiEndpoint}/${AUTH_URL}/register/`, body)
      .map(r => (this.tokenService.token = r.json().key))
      .toPromise();
  }

  /**
   * Registers a new account.
   * @param {string} email Email.
   * @param {string} password1 Password.
   * @param {string} password2 Confirm password.
   * @param {string} referral Referral Code.
   */
  public registerBrandAccount(
    email: string,
    password1: string,
    password2: string,
    brandCode: string,
    referral: string = null,
    promoCode: string = null): Promise<void> {
    const body = {
      email: email,
      password1: password1,
      password2: password2,
      referral: referral ? referral : '',
      app: brandCode,
      code: promoCode
    };
    return this.http
      .post(`${environment.apiEndpoint}/${AUTH_URL}/register-brand-account/`, body)
      .map(r => (this.tokenService.token = r.json().key))
      .toPromise();
  }

  /**
   * Confirms registered account.
   * @param {string} key Confirmation key.
   * @returns {boolean} If true - email has successfully confirmed, if false - email was confirmed earlier.
   */
  public confirmAccount(
    key: string,
  ): Promise<{ success: boolean; token: string }> {
    const body = { key: key };
    return this.http
      .post(`${environment.apiEndpoint}/${AUTH_URL}/email-confirm/`, body)
      .map(r => {
        const json = r.json() as { success: boolean; token: string };
        if (r.status === HttpStatusCode.CREATED) {
          json.success = true;
        }
        return json;
      })
      .toPromise();
  }

  /**
   * Logs in a user.
   * @param {string} email Email.
   * @param {string} password Password.
   */
  public login(email: string, password: string): Promise<void> {
    const body = {
      email: email,
      password: password,
    };

    return this.http
      .post(`${environment.apiEndpoint}/${AUTH_URL}/login/`, body)
      .map(r => (this.tokenService.token = r.json().key))
      .toPromise();
  }

  /**
   * Logs in a user.
   * @param {string} email Email.
   * @param {string} password Password.
   */
  public logout(): Promise<Result> {
    return this.http
      .post(`${environment.apiEndpoint}/${AUTH_URL}/logout/`, null, {
        headers: this.tokenService.baseHeaders,
      })
      .map(r => r.json() as Result)
      .do(r => this.tokenService.clearToken())
      .toPromise();
  }

  /**
   * Get currently authorized user info.
   */
  public me(): Promise<User> {
    return this.retrieveCurrentUser()
      .flatMap((user: User) => {
        if (user.currentMerchantId) {
          return Observable.fromPromise(this.merchantService.getMerchant(user.currentMerchantId))
            .map(merchant => {
              user.merchant = merchant;
              return user;
            });
        } else {
          return Observable.of(user);
        }
      })
      .do(user => this.onUserChanged.next(user))
      .toPromise();
  }

  public retrieveCurrentUser(): Observable<User> {
    return this.http
      .get(`${environment.apiEndpoint}/${AUTH_URL}/user/`, {
        headers: this.tokenService.baseHeaders,
      })
      .map(r => r.json())
      .map(r => this.userMapper.mapToModel(r));
  }

  /**
   * Returns payment token
   */
  public getPaymentToken(merchantId: number): Promise<{ clientToken: string }> {
    return this.http
      .get(
        `${
          environment.apiEndpoint
        }/${MERCHANTS_URL_PART}/${merchantId}/${TOKEN_URL_PART}/`,
        {headers: this.tokenService.baseHeaders},
      )
      .map(r => r.json() as any)
      .map(r => {
        return {clientToken: r.client_token};
      })
      .toPromise();
  }

  /**
   * Recovering password for user.
   * @param email Email of user.
   */
  public recoverPassword(email: string): Promise<object | ServerError> {
    return this.http
      .post(`${environment.apiEndpoint}/${AUTH_URL}/password/reset/`, {
        email: email,
      })
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .map(r => {
        if (r instanceof ServerError) {
          return r;
        }

        return r.json();
      })
      .map(r => {
        return r;
      })
      .toPromise();
  }

  /**
   * Send request to change current password
   * @param newPassword
   * @param confirmNewPassword
   * @returns {Promise<void | ServerError>}
   */
  public changePassword(
    newPassword: string,
    confirmNewPassword: string,
  ): Promise<void | ServerError> {
    return this.http
      .post(
        `${environment.apiEndpoint}/${AUTH_URL}/password/change/`,
        {
          new_password1: newPassword,
          new_password2: confirmNewPassword,
        },
        {
          headers: this.tokenService.baseHeaders,
        },
      )
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .map(r => {
        if (r instanceof ServerError) {
          return r;
        }

        return null;
      })
      .toPromise();
  }

  /**
   * Send request to change current username
   * @param firstName
   * @param lastName
   * @returns {Promise<void | ServerError>}
   */
  public changeUserName(
    firstName: string,
    lastName: string,
  ): Promise<void | ServerError> {
    return this.http
      .post(
        `${environment.apiEndpoint}/${AUTH_URL}/username-change/`,
        {
          first_name: firstName,
          last_name: lastName,
        },
        {
          headers: this.tokenService.baseHeaders,
        },
      )
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .map(r => {
        if (r instanceof ServerError) {
          return r;
        }

        return null;
      })
      .toPromise();
  }

  /**
   * Send request to change current email
   * @param newEmail
   * @returns {Promise<void | ServerError>}
   */
  public requestChangeEmail(newEmail: string): Promise<void | ServerError> {
    return this.http
      .post(
        `${environment.apiEndpoint}/${AUTH_URL}/email-change/`,
        {
          email: newEmail,
        },
        {
          headers: this.tokenService.baseHeaders,
        },
      )
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .map(r => {
        if (r instanceof ServerError) {
          return r;
        }

        return null;
      })
      .toPromise();
  }

  public changeCurrentMerchant(id: number): Observable<User> {
    return this.http
      .patch(
        `${environment.apiEndpoint}/${AUTH_URL}/user/`,
        {current_merchant: id},
        {headers: this.tokenService.baseHeaders},
      )
      .map(response => response.json())
      .map(userDto => this.userMapper.mapToModel(userDto))
      .do(user => this.onUserChanged.next(user));
  }

  public changeCurrentWebApp(id: string): Observable<User> {
    return this.http
      .patch(
        `${environment.apiEndpoint}/${AUTH_URL}/user/`,
        {current_webapp: id},
        {headers: this.tokenService.baseHeaders},
      )
      .map(response => response.json())
      .map(userDto => this.userMapper.mapToModel(userDto))
      .do(user => this.onUserChanged.next(user));
  }
}
