import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pb-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  constructor() { }

  @Input()
  public items: string[];

  public ngOnInit() {
  }
}
