import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  Renderer,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';

/**
 * The directive prevents input more than limit symbols. Depends on angular2-text-mask package.
 */
@Directive({
  selector: '[pbLimit]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LimitDirective), multi: true }]
})
export class LimitDirective implements ControlValueAccessor, OnChanges {
  private textMaskInputElement: any;
  private inputElement: HTMLInputElement;

  // stores the last value for comparison
  private lastValue: any;

  /**
   * Limit number.
   */
  @Input('pbLimit')
  public limit: any;

  private onChanged: (value: any) => void;
  private onTouched: () => void;

  /**
   * .ctor
   * @param {Renderer} renderer Angular renderer.
   * @param {ElementRef} element Element reverence.
   */
  constructor(private renderer: Renderer, private element: ElementRef) { }

  /**
   * @inheritdoc
   */
  public ngOnChanges(changes: SimpleChanges) {
    this.setupMask(true);
    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(this.inputElement.value);
    }
  }

  /**
   * @inheritdoc
   */
  public writeValue(value: any) {
    this.setupMask();

    const normalizedValue = value == null ? '' : value;
    this.renderer.setElementProperty(this.inputElement, 'value', normalizedValue);

    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(value);
    }
  }

  /**
   * @inheritdoc
   * @param {(value: T) => void} fn  The function to be called.
   */
  public registerOnChange(fn: (value: any) => void): void {
    this.onChanged = fn;
  }

  /**
   * @inheritdoc
   * @param {() => void} fn The function to be called.
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * On blur listener.
   */
  @HostListener('blur')
  public onBlur() {
    this.onTouched();
  }

  /**
   * On input listiner.
   */
  @HostListener('input', ['$event.target.value'])
  public onInput(value) {
    this.setupMask();

    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(value);

      // get the updated value
      value = this.inputElement.value;

      // check against the last value to prevent firing ngModelChange despite no changes
      if (this.lastValue !== value) {
        this.lastValue = value;
        this.onChanged(value);
      }
    }
  }

  private setupMask(create = false) {
    if (!this.inputElement) {
      if (this.element.nativeElement.tagName === 'INPUT' ||
          this.element.nativeElement.tagName === 'TEXTAREA') {
        this.inputElement = this.element.nativeElement;
      }
    }

    if (this.inputElement && create) {
      this.textMaskInputElement = createTextMaskInputElement({
        inputElement: this.inputElement,
        guide: false,
        mask: this.limit ? Array.apply(null, { length: +this.limit }).map(() => /./) : false
      });
    }

  }
}
