import {Component, OnInit} from '@angular/core';
import {MdDialog, Sort} from '@angular/material';

import {AuthService, DataTransferService, Pagination, PaginationService, PlaceService} from '../../core';

import {ActivatedRoute, Router} from '@angular/router';
import {PopupService} from '../../popup';
import {WebappService} from '../../core/services/webapp/webapp.service';
import {Observable} from "rxjs/Observable";
import {Subscription} from "rxjs/Subscription";
import {DataPopup} from "../../core/models/data-popup";


/**
 * Loyalty Popups page component.
 */
@Component({
  selector: 'pb-data-popups-page',
  templateUrl: './data-popups.component.html',
  styleUrls: ['./data-popups.component.scss']
})
export class DataPopupsComponent implements OnInit {
  /**
   * .ctor
   * @param {AuthService} authService Auth service.
   * @param {PlaceService} placeService Place service.
   * @param webappService
   * @param dialog
   * @param router
   * @param popupService
   * @param route
   * @param dataTransferService
   * @param {PaginationService} paginationService Pagination service.
   */
  constructor(private authService: AuthService,
              private placeService: PlaceService,
              private activatedRoute: ActivatedRoute,
              private webappService: WebappService,
              private dialog: MdDialog,
              private router: Router,
              private popupService: PopupService,
              private route: ActivatedRoute,
              private dataTransferService: DataTransferService,
              private paginationService: PaginationService) {
  }

  public reference: string;

  /**
   * A list of sorted data.
   */
  private sortedData: DataPopup[] = [];

  /**
   * Current saved sort method.
   */
  private currentSort: Sort;

  /**
   * Search string.
   */
  public searchString = '';

  /**
   * Pagination object from pagination service.
   */
  public pagination: Pagination = new Pagination();

  /**
   * Paged items.
   */
  public pagedItems: DataPopup[];

  /**
   * Webapps list.
   */
  public popups: DataPopup[] = [];

  /**
   * Current merchant Id.
   */
  private merchantId: number;
  public webAppId: string;

  private routeSub: Subscription;

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {

    // Combine route parameter map and user change observable
    this.routeSub = Observable.combineLatest([this.activatedRoute.paramMap, this.authService.onUserChanged])
      .subscribe(async ([params, user]) => {
        if (user) {
          this.merchantId = user.merchantId;

          try {
            this.webAppId = this.route.snapshot.params['id'];
            const currentWebApp = user.merchant.webapps.find((webapp) => this.webAppId == webapp.id);
            if (currentWebApp) {
              if (!currentWebApp.enable_data_popup_edit) {
                if (this.routeSub) {
                  this.routeSub.unsubscribe();
                }
                await this.router.navigate(['/dashboard']);

                return;
              }
            }


            this.popups = await this.webappService.getDataPopups(this.webAppId);
          } catch (error) {
            console.error('Error fetching data popups:', error);
            await this.router.navigate(['/dashboard']);
          }

          this.adjustPopupData(null, '', 1);

          if (this.routeSub) {
            this.routeSub.unsubscribe();
          }
        }
      });
    // scroll to top
    window.scrollTo(0, 0);
  }

  /**
   * Invokes data sorting in table considering search string, makes pagination start from 1st page.
   */
  public sortDataChanged(sort: Sort): void {
    this.adjustPopupData(sort, this.searchString, 1);
  }

  /**
   * Shows new list of table items considering table's sorting and search.
   */
  public setPageClicked(page: number): void {
    this.adjustPopupData(this.currentSort, this.searchString, page);
  }

  /**
   * Combines sorting, filtering and setting pagination methods.
   */
  private adjustPopupData(sort: Sort, searchString: string, page: number): void {
    this.sortData(sort);
    this.sortedData = this.filterItems(this.sortedData, searchString);
    this.setPage(page);
  }

  /**
   * Sorts table.
   */
  private sortData(sort: Sort): void {
    this.currentSort = sort;
    const data = this.popups.slice();
    if (!sort || !sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'id':
          return compare(+a.id, +b.id, isAsc);
        case 'name':
          return compare(a.name, b.name, isAsc);
        default:
          return 0;
      }
    });
  }

  /**
   * Searches for string in table.
   */
  private filterItems(items, searchString: string): DataPopup[] {
    this.searchString = searchString;
    return items.filter(i => i.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 || searchString === '');
  }

  /**
   * Sets page.
   */
  private setPage(page: number): void {
    /**
     * Get pagination object from service.
     */
    this.pagination = this.paginationService.getPagination(this.sortedData.length, page);

    /**
     * Get current page of items.
     */
    this.pagedItems = this.sortedData.slice(this.pagination.startIndex, this.pagination.endIndex + 1);
  }

  public editPopupClick(popup: DataPopup) {
    this.dataTransferService.set({popup: popup, webAppId: this.webAppId});

    let queryParams = null;
    if (this.reference) {
      queryParams = {r: this.reference.trim()};
    }

    this.router.navigate([`/webapps/${this.webAppId}/data-popups/`, popup.id], {queryParams: queryParams});
  }

  private async reloadPlaces() {
    this.popups = (await this.webappService.getDataPopups(this.webAppId)).sort((a, b) => a.name.localeCompare(b.name));
    this.adjustPopupData(null, '', 1);
    await this.popupService.info('Places updated.');
  }

  public newPopupClick(): void {
    this.router.navigate([`/webapps/${this.webAppId}/data-popups/`, 'new'], {queryParams: {}});
  }

  // Add this method
  public async deletePopupClick(popup: DataPopup): Promise<void> {
    if (confirm(`Are you sure you want to delete the popup: ${popup.name}?`)) {
      try {
        await this.webappService.deleteDataPopup(this.webAppId, popup.id);
        await this.reloadPopups();
      } catch (error) {
        console.error('Error deleting popup:', error);
        // Optionally show a user-friendly message here
      }
    }
  }

  private async reloadPopups() {
    this.popups = (await this.webappService.getDataPopups(this.webAppId)).sort((a, b) => a.name.localeCompare(b.name));
    this.adjustPopupData(null, '', 1);
    await this.popupService.info('Popups updated.');
  }

}

function compare(a: any, b: any, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
