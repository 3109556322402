import { OnInit, Component, Output, EventEmitter, Input } from "@angular/core";
import { Plans } from "../../../core/models/plans";

/**
 * Page component for Merchant's activities.
 */
@Component({
  selector: "pb-toggle-selector",
  templateUrl: "./toggle-selector.component.html",
  styleUrls: ["./toggle-selector.component.scss"],
})
export class ToggleSelectorComponent implements OnInit {
  public selectedPlan: Plans;

  @Input()
  public options: any;

  @Input()
  public selected: any;

  @Input()
  public disabled: any;

  @Output() onSelect = new EventEmitter();

  public ngOnInit(): void {
    if (!this.selected) this.selected = this.options[0];
  }

  public onChange($ev, $option): void {
    if (this.disabled) return;
    $ev.preventDefault();
    this.selected = $option;

    this.onSelect.emit($option);
  }
}
