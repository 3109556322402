<div class="root" [ngStyle]="{'width': width + 'px'}">
  <div class="header">
    <div class="global-caption">{{name}}</div>
    <div class="size" *ngIf="showSize">{{width}} x {{height}}px</div>
  </div>
  <div class="body" [ngStyle]="{'width': width + 'px', 'height': height + 'px'}">
    <img [src]="imageUrl" alt="image name" class="img" *ngIf="isImageUploaded">
    <pb-browse-file [ngModel]="imageBase64" (ngModelChange)="fileBrowsed($event)" (uploaded)="fileUploaded($event)" [buttonText]="browseButtonText" [buttonWidth]="browseButtonWidth"></pb-browse-file>
  </div>
</div>
