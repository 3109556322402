﻿import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import {
  AuthService, BrandMapper,
  BrandService,
  CategoriesService,
  CouponsService,
  DataTransferService,
  ErrorHandlerService,
  MerchantService,
  MessagesService,
  PlansService
} from '../../core';
import { Plans } from '../../core/models/plans';
import { PopupService } from '../../popup';
import { CampaignDataService } from '../services/campaign-data.service';
import {MerchantApp} from '../../core/models/merchant-app';

/**
 * New message component.
 */
@Component({
  selector: 'pb-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit, OnDestroy {
  private routePreviousUrl: Subscription;

  public brand: MerchantApp = new MerchantApp({
    id: 'gettinlocal',
    name: 'GettinLocal'
  });
  /**
   * .ctor
   * @param {CategoriesService} categoriesService Categories service.
   * @param {MdDialog} dialog Material design dialog.
   * @param {PopupService} popupService Popup service.
   * @param {MerchantService} merchantService Merchant service.
   * @param {MessagesService} messagesService Message service.
   * @param {AuthService} authService Auth service.
   * @param {DataTransferService} dataTransferService Data transfer service.
   * @param {Router} router Angular router.
   */
  constructor(
    private categoriesService: CategoriesService,
    private dialog: MdDialog,
    private popupService: PopupService,
    private merchantService: MerchantService,
    private plansService: PlansService,
    private authService: AuthService,
    private dataTransferService: DataTransferService,
    private router: Router,
    private brandService: BrandService,
    private brandMapper: BrandMapper,
    private activatedRoute: ActivatedRoute,
    private couponsService: CouponsService,
    private errorHandlerService: ErrorHandlerService,
    public dataservice: CampaignDataService
  ) {
    this.routePreviousUrl = this.router.events
      .filter(e => e instanceof NavigationEnd)
      .subscribe((v: NavigationEnd) => {
        localStorage['previousUrl'] = v.url;
      });

    this.brandService.brand.subscribe((b) => {
      this.brand = b;
    });
  }

  private selectedPlan: Plans;
  public availablePlans: Plans[];

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.plansService
      .getCampaignPlans()
      .then(p => {
        this.availablePlans = p;
        this.selectedPlan = this.availablePlans.find(p => p.name === 'premium');

        if (this.brand.isWhiteLabel()) {
          try {
            this.startFreeCampaign();
          } catch (e) {

          }
        }
      })
      .catch(err => console.error(err));

    this.authService.onUserChanged.subscribe(
      async result => {
        const user = result;
        if (user) {
          this.brandService.brand.next(this.brandMapper.mapToModel(user.merchant.app));
        }
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.routePreviousUrl) {
      this.routePreviousUrl.unsubscribe();
    }
  }

  public startCampaign(selectedPlan: Plans): void {
    const premiumPlan = this.availablePlans.find(p => p.name === 'premium');

    this.dataservice.avaiable_plans = this.availablePlans;
    this.dataservice.campaign_plan.next(selectedPlan);
    this.dataservice.premium_state_count.next(0);
    this.dataservice.premium_email_state_count.next(0);
    this.dataservice.payment_required.next(true);
    this.dataservice.custom_link_count.next(0);
    this.dataservice.upgrade_fee.next(premiumPlan.subscription_rate);
    this.dataservice.is_upgradable.next(true);

    this.router.navigate(['/campaign']);
  }

  public startFreeCampaign(): void {
    const freePlan = this.availablePlans.find(p => p.name === 'free');
    const premiumPlan = this.availablePlans.find(p => p.name === 'premium');
    const defaultPlan = this.availablePlans.find(p => p.id === this.brand.default_plan);

    this.dataservice.avaiable_plans = this.availablePlans;
    this.dataservice.campaign_plan.next(defaultPlan || freePlan);
    this.dataservice.premium_state_count.next(0);
    this.dataservice.premium_email_state_count.next(0);
    this.dataservice.payment_required.next(true);
    this.dataservice.custom_link_count.next(0);
    this.dataservice.upgrade_fee.next(premiumPlan.subscription_rate);
    this.dataservice.is_upgradable.next(false);

    this.router.navigate(['/campaign']);
  }

  public planChanged($plan): void {
    this.selectedPlan = $plan;
  }
}
