import { NgModel, RequiredValidator } from '@angular/forms';

import * as moment from 'moment';

import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';
/**
 * Operation hours validation directive.
 */
@Directive({
    selector: '[pbDateRangeValidation]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => DateRangeValidationDirective), multi: true }]
})
export class DateRangeValidationDirective implements Validator {

    @Input()
    public model: NgModel;

    @Input()
    public instance: NgModel;

    public validate(c: AbstractControl): ValidationErrors | null {

        let required = false;

        if (this.instance) {
            const validators = (<any>this.instance)._rawValidators as any[];
            if (validators && validators.length > 0) {
                const requiredValidator = validators.find(validator => validator instanceof RequiredValidator) as RequiredValidator;
                if (requiredValidator && !requiredValidator.required) {
                    return null;
                }
            }
        }

        if (!c.value) {
            return { required: true };
        } else if (c.value && this.model && this.model.value) {

            if (this.model.name === 'end') {
                // this check related to start date.
                required = moment(c.value).isAfter(moment(this.model.value));
            } else if (this.model.name === 'start') {
                // this check related to end date.
                required = moment(c.value).isBefore(moment(this.model.value));
            }

            if (required) {
                return { required: true };
            }
        }

        return null;
    }
}
