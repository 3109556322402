<header pb-page-header>
  <div left>Invoices</div>
  <!-- <div right *ngIf="currentInvoice && currentInvoice.isValid">
    <button pb-button type="button" class="button current-invoice" (click)="navigateToCurrentInvoice()">Last Invoice</button>
  </div> -->
</header>
<div class="container">
  <div class="item" *ngIf="invoicePeriod">
    <div class="item__caption">Billing Period:</div>
    <div class="item__content">{{ invoicePeriod.from }} - {{ invoicePeriod.to }}</div>
  </div>
  <div class="item">
    <div class="item__caption">Total:</div>
    <div class="item__content">{{ total | currency: 'USD' : true :'2.2-2' }}</div>
  </div>
  <!-- <div class="item" *ngIf="invoices">
    <div class="item__caption">Billing Date:</div>
    <div class="item__content">{{ chargeDate }}</div>
  </div> -->
  <!-- <div class="item" *ngIf="merchant">
    <div class="item__caption">Merchant:</div>
    <div class="item__content">{{ merchant.name }}</div>
  </div>
  <div class="item" *ngIf="merchant">
    <div class="item__caption">Address:</div>
    <div class="item__content">{{ address }}</div>
  </div>
  <div class="item" *ngIf="merchant">
    <div class="item__caption">Status:</div>
    <div class="item__content">{{ lastStatus }}</div>
  </div>
  <div class="item" *ngIf="merchant">
    <div class="item__caption">CID#:</div>
    <div class="item__content">{{ merchant.personalId }}</div>
  </div> -->
</div>
<form #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted">
  <div style="display: flex; flex-direction: row; justify-content: flex-end;">
    <div style="align-self: center;">
      Billing Period
    </div>
    <div style="margin-left: 10px;">
      <pb-datepicker required placeholder="Start Date" aria-placeholder="Start Date" pbRequiredDateValidation
        name="start-date" [(ngModel)]="invoiceStartDate" (ngModelChange)="startDateChanged($event)"
        [maxInputDate]="maxDate">
      </pb-datepicker>
    </div>
    <div style="margin-left: 10px;">
      <pb-datepicker required placeholder="End Date" aria-placeholder="End Date" pbRequiredDateValidation
        name="end-date" [(ngModel)]="invoiceEndDate" (ngModelChange)="endDateChanged($event)" [maxInputDate]="maxDate"
        [minInputDate]="invoiceStartDate">
      </pb-datepicker>
    </div>
    <!-- <div style="margin-left: 10px; align-self: flex-end;">
        <button pb-button class="button">Submit
        </button>
      </div> -->
  </div>
</form>
<table pb-table mdSort (mdSortChange)="sortDataChanged($event)" style="table-layout: fixed;">
  <thead>
    <tr>
      <th width="80px">
        Invoice #
      </th>
      <th width="300px" md-sort-header="invoiceName">
        <div class="row">
          Campaign
          <pb-icon name="#iconArrow" class="iconArrow"></pb-icon>
        </div>
      </th>
      <th width="120px" md-sort-header="DueDate">
        <div class="row">
          Bill Date
          <pb-icon name="#iconArrow" class="iconArrow"></pb-icon>
        </div>
      </th>
      <th width="120px" md-sort-header="NextBillDate">
        <div class="row">
          Next Bill Date
          <pb-icon name="#iconArrow" class="iconArrow"></pb-icon>
        </div>
      </th>
      <th width="180px" md-sort-header="CardCharged">
        <div class="row">
          Card Charged
          <pb-icon name="#iconArrow" class="iconArrow"></pb-icon>
        </div>
      </th>
      <th md-sort-header="Total">
        <div class="row">
          Total
          <pb-icon name="#iconArrow" class="iconArrow"></pb-icon>
        </div>
      </th>
      <th md-sort-header="Status">
        <div class="row">
          Status
          <pb-icon name="#iconArrow" class="iconArrow"></pb-icon>
        </div>
      </th>
    </tr>
    <tr>
      <th>
        <pb-search [searchString]="searchString" (searchStringChange)="searchStringChanged($event)"></pb-search>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of pagedItems">
      <td>
        {{item.invoiceId}}
      </td>
      <td>
        <a href="" [routerLink]='["/invoices", item.id]'>{{item.invoiceName}}</a>
      </td>
      <td>{{item.chargeDate}}</td>
      <td>{{item.nextChargeDate}}</td>
      <td>{{item.cardCharged}}</td>
      <td>{{item.sum}}</td>
      <td>{{item.status}}</td>
    </tr>
  </tbody>
</table>
<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>