import { NgModule } from '@angular/core';
import { ActivitiesPopupComponent } from '../shared';
import { SharedModule } from '../shared/shared.module';
import { ActivitiesPageComponent } from './activities-page.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ActivitiesPageComponent,
  ],
  entryComponents: [
    ActivitiesPopupComponent,
  ],
  exports: [
    ActivitiesPageComponent
  ]
})
export class ActivitiesPageModule {
}
