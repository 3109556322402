import { ViewCampaignComponent } from '../view-campaign/view-campaign.component';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewPromotionComponent } from './view-promotion.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [ViewPromotionComponent],
  declarations: [ViewPromotionComponent]
})
export class ViewPromotionModule { }
