import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { DashboardPageComponent } from './dashboard-page.component';
import { AppRoutingModule } from '../app-routing.module';
import { ActivitiesPopupComponent } from '../shared';

@NgModule({
  imports: [
    SharedModule,
    AppRoutingModule
  ],
  declarations: [
    DashboardPageComponent
  ],
  entryComponents: [
    ActivitiesPopupComponent
  ],
  exports: [
    DashboardPageComponent,
  ]
})
export class DashboardPageModule { }
