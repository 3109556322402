/**
 * Location domain model.
 */
export class Location {
  /**
   * Location longitude.
   */
  public longitude: number;

  /**
   * Location latitude.
   */
  public latitude: number;
}
