import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth/auth.service';


/**
 * Authorization guard.
 */
@Injectable()
export class AuthGuard implements CanActivate {
  /**
   * @constructor
   */
  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  /**
   * @inheritdoc
   */
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.currentUserToken) {
      return true;
    }
    this.goToLoginPage(state.url);

    return false;
  }

  private goToLoginPage(redirectUrl: string) {
    const extras: NavigationExtras = {
      queryParams: {
        redirectUrl: redirectUrl,
      },
    };
    this.router.navigate(['/login'], extras);
  }
}
