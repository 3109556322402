<header pb-page-header>
  <div left>Web Applications: {{ webAppId }}</div>
</header>

<ng-container>
  <form (ngSubmit)="onSubmit()">
    <table pb-table>
      <thead>
      <tr>
        <th width="5%">Ad Position</th>
        <th>Change Priority Position</th>
        <th>Search by Business</th>
        <th>Select Headline of Ad</th>
        <th>Filter(s)</th>
        <th>Display Ad in Main Filter</th>
        <th>Display Ad in Business Filter</th>
        <th>Remove Priority Ad</th>
      </tr>
      </thead>
      <tbody *ngIf="couponData.length > 0">
      <tr *ngFor="let row of couponData; let i = index">
        <td>{{ i + 1 }}</td>
        <td>
          <a (click)="moveUp(i)" [hidden]="i === 0">
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
          </a>
          <a (click)="moveDown(i)" [hidden]="i === couponData.length - 1">
            <i class="fa fa-arrow-down" aria-hidden="true"></i>
          </a>
        </td>
        <td>
          <input type="text" [(ngModel)]="row.field1" name="field1-{{i}}" [mdAutocomplete]="auto1"
                 (input)="filterField1Options(row)" class="form-control">
          <md-autocomplete #auto1="mdAutocomplete" [displayWith]="displayMerchantName" class="test-class">
            <md-option *ngFor="let option of row.merchantFilterOptions | async" [value]="option"
                       (onSelectionChange)="onMerchantSelectChanged(row, $event)">
              {{ option.name_dba }}
            </md-option>
          </md-autocomplete>
        </td>
        <td>
          <input type="text" [(ngModel)]="row.field2" name="field2-{{i}}" [mdAutocomplete]="auto2"
                 (input)="filterField2Options(row)" class="form-control">
          <md-autocomplete #auto2="mdAutocomplete" [displayWith]="displayCouponName">
            <md-option *ngFor="let option of row.couponFilterOptions | async" [value]="option"
                       (onSelectionChange)="onCouponSelectChanged(row, $event)">
              {{ option.name }}
            </md-option>
          </md-autocomplete>
        </td>
        <td [innerHTML]="row.field3"></td>
        <td>
          <pb-switch [(checked)]="row.field4" (checkedChange)="onField4OptionChanged(row, $event)"></pb-switch>
        </td>
        <td>
          <pb-switch [(checked)]="row.field5" (checkedChange)="onField5OptionChanged(row, $event)"></pb-switch>
        </td>
        <td>
          <button type="button" style="padding: 0; background: none; border: none;" (click)="removeRow(i)">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="couponData.length === 0">
      <tr>
        <td colspan="8">
          <p style="margin-bottom: 20px">No coupons configured.</p>
        </td>
      </tr>
      </tbody>
    </table>
    <div
      style="height: 40px">
    </div>
    <div style="display: flex; justify-content: flex-end; gap: 10px">
      <button pb-button class="button-white" type="button" (click)="addRow()">Add Row</button>
      <button pb-button class="button" type="submit">Publish</button>
    </div>
  </form>
</ng-container>
