import { PaginationService,  ErrorHandlerService, AuthService, ReportsService, User, Pagination, Report  } from '../core';
import { Subscription } from 'rxjs/Rx';
import { NgForm } from '@angular/forms/src/forms';
import * as moment from 'moment';

import { Component, OnDestroy, OnInit} from '@angular/core';
import { PopupService } from '../popup';


@Component({
  selector: 'pb-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;

  private maxDateField = new Date();

  private user: User;

  constructor(
    private reportsService: ReportsService,
    private authService: AuthService,
    private paginationService: PaginationService,
    private popupService: PopupService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public get maxDate(): Date {
    return this.maxDateField;
  }

  public searchString: string;

  /**
   * Title of report.
   */
  public newReportTitle: string;

  /**
   * Start date for new report.
   */
  public newReportStartDate: Date;

  /**
   * End date for new report.
   */
  public newReportEndDate: Date;

  private tableData = [];

  public pagedItems = [];


  /**
   * Pagination object from pagination service.
   */
  public pagination: Pagination = new Pagination();

  private reports: Report[];

  public async ngOnInit(): Promise<void> {

    this.userSubscription = this.authService.onUserChanged.skipWhile(user => user === null)
    .subscribe(async (user): Promise<void> => {
      this.reports = await this.reportsService.getReports(user.merchantId);
      this.user = user;

      this.tableData = this.reports.map(report => {
        return {
          id: report.id,
          name: report.name,
          start: report.start,
          end: report.end,
          reportDate: report.reportDate
        };
      });

      this.setPageClicked(1);

      if (this.userSubscription) {
        this.userSubscription.unsubscribe();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  public startDateChanged(changes: Date) {
    const startDate = moment(changes);
    if (!this.newReportEndDate) {
      return;
    }
    const endDate = moment(this.newReportEndDate);

    if (endDate.isBefore(startDate)) {
      this.newReportEndDate = startDate.toDate();
    }
  }

  public isValidDate(value: string): boolean {
    if (!value) {
      return false;
    }
    return !moment(value).isAfter(moment());
  }

  /**
   * Triggers on submit form.
   * @param {NgForm} form
   */
  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      const startDate = moment(this.newReportStartDate, 'M/DD/YYYY').format();
      const endDate = moment(this.newReportEndDate, 'M/DD/YYYY').add(1, 'day').add(-1 , 'second').format();

      const spinner = this.popupService.spinner();

      const response = await this.reportsService.addReport(this.user.merchantId, startDate, endDate, this.newReportTitle);

      if (response === null) {
        this.reports = await this.reportsService.getReports(this.user.merchantId);
        this.tableData = this.reports.map(report => {
          return {
            id: report.id,
            name: report.name,
            start: report.start,
            end: report.end,
            reportDate: report.reportDate
          };
        });
        this.setPageClicked(1);
      } else {
         this.errorHandlerService.invalidateForm(form, { 'name': 'report-title' }, response);
      }

      spinner.close();
    }
  }

  public setPageClicked(page: number) {
    this.pagination = this.paginationService.getPagination(this.tableData.length, page);

    this.pagedItems = this.tableData.slice(this.pagination.startIndex, this.pagination.endIndex + 1);
  }

  public searchStringChanged(event) {
    this.tableData = this.filterItems(this.reports, event);
    this.setPageClicked(1);
  }

  /**
   * Searches for string in table.
   */
  private filterItems(items, searchString: string): Report[] {
    this.searchString = searchString;
    return items.filter( i => i.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 || searchString === '');
  }
}
