/**
 * Model for place search.
 */
export class PlaceSearch {
    public provider: string;
    public id: string;

    public address: string;

    public name: string;

    public location: {
        longitude: number;
        latitude: number;
    };

    public addressComponents: AddressComponent[];

    public getAddressComponent(type: AddressComponentType | string): AddressComponent | null {
        if (!this.addressComponents || this.addressComponents.length === 0) {
            return null;
        }

        const item = this.addressComponents.find(ac => ac.types && ac.types.length > 0 && ac.types.includes(type));

        return item ? item : null;
    }

    /**
     * Return string with the address text.
     */
    public getAddressString(): string {
        const address = ['street_number',
        'route',
        'locality',
        'administrative_area_level_1',
        'postal_code',
        'country'];
        let text = '';
        for (const field of address) {
            if (this.getAddressComponent(field) === null) {
                continue;
            }
            if (field === 'country') {
                text += `${this.getAddressComponent(field).shortName} `;
                continue;
            }
            if (this.getAddressComponent(field).longName === null) {
                text += `${this.getAddressComponent(field).shortName} `;
                continue;
            }
            text += `${this.getAddressComponent(field).longName} `;
        }
        return text;
    }



    /**
     * Returns address part of object exclude town.
     */
    public getAddress(): string  {
        const streetNumber = this.getAddressComponent('street_number');
        const route = this.getAddressComponent('route');

        let address = '';
        if (streetNumber) {
            address += streetNumber.longName;
        }
        if (route) {
            if (address.length > 0) {
                address += ' ';
            }
            address += route.longName;
        }

        return address;
    }

    public getCity(): AddressComponent {
        const city = this.getAddressComponent('locality');
        if (city) {
            return city;
        }

        const sublocality = this.getAddressComponent('sublocality');
        if (sublocality) {
            return sublocality;
        }

        const postal_town = this.getAddressComponent('postal_town');
        if (postal_town) {
          return postal_town;
        }

        const administrative_area_level_3 = this.getAddressComponent('administrative_area_level_3');
        if (administrative_area_level_3) {
            return administrative_area_level_3;
        }

        const administrative_area_level_1 = this.getAddressComponent('administrative_area_level_1');
        return administrative_area_level_1;
    }
}

export class AddressComponent {
    public longName: string;
    public shortName: string;
    public types: string[];
}

/**
 * Types for Address components
 * Administrative level 1 it's a state
 * Administrative level 3 it's a city.
 * Locality is city.
 * Route is a street
 * Postal code is postal code.
 * Street number is Street number.
 */
export type AddressComponentType = ('administrative_area_level_1' | 'postal_code' | 'administrative_area_level_3' |
                                    'locality' | 'route' | 'street_number' | 'street_address' | 'country');
