import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {
  AfterViewInit,
  Component,
  forwardRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MdDialogRef, } from '@angular/material/typings';
import {MdDialog} from '@angular/material';

import {Observable, Subject} from 'rxjs/Rx';
import {catchError, filter, map, switchMap, take, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {zip} from 'rxjs/observable/zip';

import {EventStyleComponent} from './event-style/event-style.component';
import {EventStyleVm as EventStyle} from './event-style/event-style';
import {CanStepDeactivate} from './can-step-deactivate';
import {Step} from './step';
import {PreviewCouponVm} from './preview-event/preview-event';

import {
  Address,
  AuthService,
  BrandMapper,
  BrandService,
  CampaignsService,
  EventCoupon,
  Merchant,
  Place,
  PlaceService,
  PlansService,
  Subscription,
  User,
  UtilsService,
  ValidationError
} from '../core';

import {PopupResult, PopupService, SpinnerPopupComponent} from '../popup';
import {EventService} from './services/event-service/event.service';
import {Plans} from '../core/models/plans';
import {EventDataService} from './services/event-data.service';
import {PromoCode} from '../core/models/promo-code';
import {EventPublishSuccessPopupComponent} from './event-publish-popup/event-publish-popup.component';
import {getIncompleteProfileItemList} from '../company-profile-page/profile-utils';
import * as moment from 'moment';
import {MerchantApp} from '../core/models/merchant-app';


enum StepNum {
  One,
  Two,
}

const FIELDS_MAP = {
  name: 'campainMessage',
  campaign_title: 'imageLabel',
  keywords: 'keywords',
  in_store: 'storeCouponCode',
  in_store_instruction: 'storeCouponInstructions',
  online: 'onlineCouponCode',
  online_instruction: 'onlineCouponInstructions',
  deal_description: 'dealDescription',
  contact_email: 'contactInformationEmail',
  rules: 'termsAndConditionsText',
  terms: 'termsAndConditionsText',
};

/**
 * Campaign component.
 * Process of creating the campaign have 4 step.
 * A campaign may be a coupon or a promotion.
 */
@Component({
  selector: 'pb-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy, AfterViewInit {
  public id: number;
  /**
   * Destroy subject.
   */
  private destroy$ = new Subject<void>();

  /**
   * New campaign.
   */
  private newCampaign$ = new Subject<boolean>();

  private merchantKeywords: string;
  /**
   * Update campaign.
   */
  private updateCampaign$ = new Subject<boolean>();

  /**
   * Spinner.
   */
  private spinner: MdDialogRef<SpinnerPopupComponent>;

  /**
   * Current user.
   */
  public user$: Observable<User>;
  public isTester = false;
  public userObj: User = null;

  /**
   * Merchant locations.
   */
  public merchantPlaces$: Observable<Place[]>;

  /**
   * Campaign plan
   */
  public campaignPlan: Plans;
  public promoCode: PromoCode;
  public subscription: Subscription;

  /**
   * The first step in creating a campaign.
   */
  public eventStyle = new EventStyle();


  /**
   * Campaign preview.
   */
  public previewCouponImage$ = new Subject<EventStyle>();

  /**
   * Current component step.
   */
  public currentLeftComponent: CanStepDeactivate;

  /**
   * Array for show steps.
   */
  public steps: Step[];

  /**
   * Choose step.
   */
  public currentStep: Step;

  /**
   * Choose step.
   */
  public previewCouponVm: PreviewCouponVm = new PreviewCouponVm();

  public availablePlans: Plans[];
  public brand: MerchantApp = new MerchantApp({id: 'gettinlocal', name: 'GettinLocal'});

  /**
   * Step components.
   */
  @ViewChildren('leftComponent')
  public leftComponents: QueryList<CanStepDeactivate>;

  /**
   * Component for campaign style component(first step component).
   */
  @ViewChild(forwardRef(() => EventStyleComponent))
  public eventStyleComponent: EventStyleComponent;
  public merchantId: number;
  public userCountry: string;
  public merchant: Merchant;

  public previewPlace = new Address();

  /**
   * @constructor
   */
  constructor(
    private dialog: MdDialog,
    private plansService: PlansService,
    private campaignService: CampaignsService,
    private placeService: PlaceService,
    private authService: AuthService,
    private brandMapper: BrandMapper,
    private brandService: BrandService,
    private dataService: EventDataService,
    private eventService: EventService,
    private popupService: PopupService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.steps = [
      new Step(
        StepNum.One,
        'Design',
        () => this.changeValidationStep(),
        () => {
          this.switchModePreviewCouponToMain();
          window.scrollTo(0, 0);
        }
      ),
      new Step(
        StepNum.Two,
        'Publish',
        () => this.changeValidationStep(),
        () => window.scrollTo(0, 0)
      )
    ];
    this.currentStep = this.steps[StepNum.One];
    this.user$ = this.createUserStream();
    this.user$.subscribe((user) => {
      this.userObj = user;
      if (user.merchant) {
        this.merchant = user.merchant;
        this.merchantKeywords = user.merchant.keywords;
        this.userCountry = user.merchant.address.country;
        this.brandService.brand.next(this.brandMapper.mapToModel(this.merchant.app));
      }


      if (user.promo_code) {
        this.eventService
          .getPromoCode(user.promo_code.code)
          .then((code) => {
            this.dataService.promo_code.next(code);
          })
          .catch((err) => {
          });
      }
    });
    this.merchantPlaces$ = this.ceratePlacesStream();
    this.merchantPlaces$.subscribe(places => {
      this.previewPlace.city = places[0].city;
      this.previewPlace.country = places[0].country;
      this.previewPlace.state = places[0].state;
      this.previewPlace.zip = places[0].zip;
      this.previewPlace.street = places[0].street;
    });

    this.brandService.brand.subscribe((b) => {
      this.brand = b;
      // this.dataService.payment_required.next(this.brand.payment_required);
    });
  }

  /**
   * Change the view mode preview to full(when we come to step third).
   */
  private switchModePreviewCouponToFull() {
    if (this.previewCouponVm.mode === 'main') {
      this.previewCouponVm.mode = 'full';
    }
  }

  private switchModePreviewCouponToMain() {
    if (this.previewCouponVm.mode === 'full') {
      this.previewCouponVm.mode = 'main';
    }
  }

  /**
   * If the Campaign has ID, then forbid change campaign type.
   */
  public get isCampaignHasId(): boolean {
    return this.eventStyle.id != null;
  }

  /**
   * Is the final step.
   */
  public get isLastStep(): boolean {
    return this.currentStep.number === StepNum.Two;
  }

  public get isFirstStep(): boolean {
    return this.currentStep.number === StepNum.One;
  }

  /**
   * Show spinner.
   */
  private startSpinner(): void {
    this.spinner = this.popupService.spinner();
  }

  /**
   * Remove spinner.
   */
  private stopSpinner(): void {
    this.spinner.close();
  }

  /**
   *
   * @param param
   * @param queryParam
   * @param user Current user.
   */
  private getParams(
    param: ParamMap,
    queryParam: ParamMap,
    user: User
  ): { id: number; merchantId: number; type: string } {
    return {
      id: +param.get('id'),
      merchantId: user.merchantId,
      type: queryParam.get('type')
    };
  }

  /**
   * Get campaign by ID.
   * @param id Campaign ID.
   * @param merchantId Merchant ID.
   * @param type Campaign type.
   */
  private getEventById(id: number, merchantId: number, type: string) {
    this.id = id;
    this.merchantId = merchantId;
    return this.eventService.getEventById(id, merchantId);
  }

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    window.scrollTo(0, 0);

    this.availablePlans = this.dataService.avaiable_plans || [];
    await this.plansService
      .getEventPlans()
      .then(plans => {
        this.availablePlans = plans;

        if (!this.dataService.campaign_plan.getValue()) {
          const free_plan = this.availablePlans[0];
          this.initPlan(free_plan);
        }
      })
      .catch(err => console.error(err));

    this.dataService.campaign_plan.subscribe(plan => {
      if (!plan) return;
      this.campaignPlan = plan;
      this.dataService.payment_required.next(true);
    });

    this.dataService.subscription.subscribe(subscription => {
      if (!subscription) return;
      this.subscription = subscription;
    });

    this.dataService.promo_code.subscribe((code) => {
      this.promoCode = code;
    });

    this.utilsService
      .getProfanityList()
      .then((p: any) => {
        if (p) this.dataService.profanity_list.next(p.list || null);
      })
      .catch(err => console.error(err));

    // if (!this.dataService.campaign_plan.getValue()) {
    //   const free_plan = this.availablePlans.find(p => p.name === "free");
    //   this.dataService.campaign_plan.next(free_plan);
    // }

    zip(this.route.paramMap, this.route.queryParamMap, this.user$)
      .pipe(
        take(1),
        map(([params, queryParams, user]) => {
          this.isTester = user ? user.isBetaUser : false;
          this.merchantId = user.merchantId;
          return this.getParams(params, queryParams, user);
        }),
        filter(({id, type}) => !!id && !!type),
        switchMap(({id, merchantId, type}) => {
          return this.getEventById(id, merchantId, type);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((campaign: EventCoupon) => {
        if (moment().isAfter(moment(campaign.eventStyle.dateRange.end))) {
          this.router.navigate(['/dashboard']);
        }

        const plan = this.availablePlans.find(
          p => p.id === campaign.campaignPlan
        );
        this.dataService.campaign_plan.next(plan);
        this.dataService.subscription.next(campaign.subscription);

        const pCode = new PromoCode();
        pCode.id = campaign.promoCode;
        this.dataService.promo_code.next(pCode);

        this.dataService.payment_required.next(false);

        this.eventStyle = campaign.eventStyle;
      });

    this.newCampaign$
      .pipe(
        withLatestFrom(this.user$),
        map(([isCoupon, user]) => ({isCoupon, user})),
        tap(() => this.startSpinner()),
        switchMap(({isCoupon, user}) => this.createCampaign(isCoupon, user)),
        tap(() => this.stopSpinner()),
        catchError((error, source) => this.handleError(error, source)),
        takeUntil(this.destroy$)
      )
      .subscribe((id: number) => this.showRedirectPopup(id));

    this.updateCampaign$
      .pipe(
        withLatestFrom(this.user$),
        map(([isCoupon, user]) => ({isCoupon, user})),
        tap(() => this.startSpinner()),
        switchMap(({isCoupon, user}) => this.updateCampaign(isCoupon, user)),
        tap(() => this.stopSpinner()),
        catchError((error, source) => this.handleError(error, source)),
        takeUntil(this.destroy$)
      )
      .subscribe((id: number) => this.showRedirectPopup(id));
  }

  private handleError(error: HttpErrorResponse, source): Observable<never> {
    this.stopSpinner();

    this.popupService.info(error.error.detail);

    if (error.error.hasOwnProperty('validation_errors')) {
      this.handleValidationErrors(error.error.validation_errors);
    }

    return source;
  }

  private handleValidationErrors(serverErrros: ValidationError[]): void {
    const errors = this.mapErrorFields(serverErrros);

    this.eventStyle.errors = errors.filter(err =>
      this.eventStyle.validationFields.some(field => field === err.field)
    );
    this.eventStyle.errors = errors.filter(err =>
      this.eventStyle.validationFields.some(field => field === err.field)
    );
    this.eventStyle.errors = errors.filter(err =>
      this.eventStyle.validationFields.some(field => field === err.field)
    );

    if (this.eventStyle.errors.length > 0) {
      this.currentStep = this.steps[StepNum.One];
    } else if (this.eventStyle.errors.length > 0) {
      this.currentStep = this.steps[StepNum.One];
    } else if (this.eventStyle.errors.length > 0) {
      this.currentStep = this.steps[StepNum.One];
    }
  }

  // DOTO: Move this to the error service after 1.15 release (look version on package.json).
  private mapErrorFields(serverErrors: ValidationError[]): ValidationError[] {
    const errors = serverErrors.reduce((acc, err) => {
      if (Object.keys(FIELDS_MAP).some(key => key === err.field)) {
        acc.push({
          field: FIELDS_MAP[err.field],
          errors: err.errors
        });
      }

      return acc;
    }, []);

    return errors;
  }

  /**
   * Creates a popup with redirect to.
   * @param id coupon or promotion id where to redirect.
   */
  private showRedirectPopup(id: number): void {

    this.dataService.upgrade_fee.next(0);
    this.dataService.payment_required.next(this.brand.payment_required || true);
    try {
      if (!this.userObj.promo_code) {
        this.dataService.promo_code.next(null);
      }
    } catch (error) {
      console.error("User not found");
    }
    this.router.navigateByUrl('/dashboard');
    // this.showCampaignPublishSuccess();
  }

  private async showCampaignPublishSuccess() {
    const dialogRef = this.dialog.open(EventPublishSuccessPopupComponent, {
      width: '500px',
      /**
       * global style for popup to remove paddings
       */
      panelClass: 'campaign-publish-success',
      disableClose: true,
      data: {
        title: 'Success',
        message: 'Your campaign has been successfully created!',
        subtitle: 'Would you like to start a new Campaign?',
        buttonTitle: 'Start Now',
        secondaryButtonTitle: 'Return To Dashboard',
        incompleteItemsTitle: 'You can update / add information to your company profile at anytime.',
        firstLocation: this.getCoupon().eventStyle.locations[0],
        merchantId: this.merchantId,
        onItemClick: (item: string) => {
          this.router.navigate(['/profile', {nav_to_section: item}]);
        },
        incompleteList: getIncompleteProfileItemList(this.merchant)
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result === PopupResult.YES) {
          this.router.navigateByUrl('/create_campaign');
        } else if (result === PopupResult.NO) {
          this.router.navigateByUrl('/dashboard');
        }
      })
      .catch((err) => err);
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Subscribe to change components when changing the step.
   * And change the current component.
   */
  public ngAfterViewInit(): void {
    this.currentLeftComponent = this.eventStyleComponent;
    this.leftComponents.changes
      .takeUntil(this.destroy$)
      .subscribe(value => (this.currentLeftComponent = value.first));
  }

  public initPlan(plan: Plans): void {
    this.dataService.avaiable_plans = this.availablePlans;
    this.dataService.campaign_plan.next(plan);
    this.dataService.payment_required.next(this.brand.payment_required || true);
  }

  /**
   * Stream of user.
   */
  public createUserStream(): Observable<User> {
    return this.authService.onUserChanged
      .takeUntil(this.destroy$)
      .map(user => user)
      .publishReplay(1)
      .refCount();
  }

  /**
   * Stream of merchant places.
   */
  public ceratePlacesStream(): Observable<Place[]> {
    return this.user$
      .switchMap(user => this.placeService.getPlaces$(user.currentMerchantId))
      .share();
  }

  /**
   * Create coupon or promotion depending on the flag.
   * @param isCoupon Flag of type of campaign.
   * @param user Current user.
   */
  private createCampaign(isCoupon: boolean, user: User): Observable<number> {
    return this.createCoupon(user.merchant);
  }

  /**
   * Create a new coupon.
   * @param merchant Current active merchant.
   */
  private createCoupon(merchant: Merchant): Observable<number> {
    const coupon = this.getCoupon();

    return this.eventService.createCouponWithLogo(coupon, merchant);
  }

  /**
   * Update coupon or promotion depending on the flag.
   * @param isCoupon Flag of type of campaign.
   * @param user Current user.
   * @param Id coupon or promotion after updating.
   */
  private updateCampaign(isCoupon: boolean, user: User): Observable<number> {
    return this.upadateCoupon(user.merchant);
  }

  /**
   * Update coupon.
   * @param merchant Current active merchant.
   */
  private upadateCoupon(merchant: Merchant): Observable<number> {
    const coupon = this.getCoupon();

    return this.eventService.updateCoupon(coupon, merchant);
  }

  /**
   * Return coupon data.
   */
  private getCoupon(): EventCoupon {
    return {
      campaignPlan: this.campaignPlan.id,
      subscription: this.subscription,
      promoCode: this.promoCode ? this.promoCode.id : null,
      eventStyle: this.eventStyle
    };
  }


  /**
   * Change current step and component to the selected.
   */
  public chooseStep(selectedStep: Step): void {
    if (selectedStep.number === this.currentStep.number) {
      return;
    }
    if (this.currentStep.callbackWhenLeavingStep) {
      this.currentStep.callbackWhenLeavingStep();
    }
    if (selectedStep.number < this.currentStep.number) {
      this.currentStep = selectedStep;
      if (this.currentStep.callbackWhenGoToStep) {
        this.currentStep.callbackWhenGoToStep();
      }
      return;
    }
    const prevSelectedStep = this.steps[selectedStep.number - 1];
    if (
      prevSelectedStep.number === this.currentStep.number &&
      this.currentStep.isValid
    ) {
      this.currentStep = selectedStep;
      if (this.currentStep.callbackWhenGoToStep) {
        this.currentStep.callbackWhenGoToStep();
      }
    }
    if (
      prevSelectedStep.number > this.currentStep.number &&
      prevSelectedStep.isValid &&
      this.currentStep.isValid
    ) {
      this.currentStep = selectedStep;
      if (this.currentStep.callbackWhenGoToStep) {
        this.currentStep.callbackWhenGoToStep();
      }
    }
  }

  /**
   * Change current step and component to the next.
   */
  public nextStep(): void {
    if (this.currentStep.callbackWhenLeavingStep) {
      this.currentStep.callbackWhenLeavingStep();
    }
    if (this.currentStep.number < this.steps.length) {
      if (this.currentStep.isValid) {
        this.currentStep = this.steps[this.currentStep.number + 1];
        if (this.currentStep.callbackWhenGoToStep) {
          this.currentStep.callbackWhenGoToStep();
        }
      }
    }
  }

  /**
   * Change current step and component to the back.
   */
  public backStep(): void {
    if (this.currentStep.callbackWhenLeavingStep) {
      this.currentStep.callbackWhenLeavingStep();
    }
    if (this.currentStep.number > 0) {
      this.currentStep = this.steps[this.currentStep.number - 1];
      if (this.currentStep.callbackWhenGoToStep) {
        this.currentStep.callbackWhenGoToStep();
      }
    }
  }

  /**
   * Create or update campaign.
   */
  public publishCampaign(): void {
    if (this.eventStyle.id == null) {
      this.newCampaign$.next(this.eventStyle.feature === 'coupon');
    } else {
      this.updateCampaign$.next(this.eventStyle.feature === 'coupon');
    }
  }

  /**
   * Checks the validity of the current component.
   * And changes the validity of the step.
   */
  public changeValidationStep() {
    if (this.currentLeftComponent.canStepDeactivate()) {
      this.currentStep.isValid = true;
    } else {
      this.currentStep.isValid = false;
    }
  }

  public onMapClick($data): void {
    alert($data);
  }

  public upgradePlan(): void {

  }
}
