
/**
 * Place domain model.
 */
export class PromoCode {
  /**
   * .ctor
   */
  constructor(initObject?: PromoCode) {
    Object.assign(this, initObject);
  }

  public id?: number;
  public code: string;
  public agent: string;
  public description?: string;
  public discount: number;
  public commission: number;
  public is_active: boolean;
}
