import { Component, OnInit, Input } from '@angular/core';
import { Invoice } from '../../core';


@Component({
  selector: 'pb-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss']
})
export class SideComponent implements OnInit {

  constructor() { }

  @Input()
  public invoice: Invoice;

  public get cardCharged(): string {
    if (!this.invoice || !this.invoice.transaction || !this.invoice.transaction.creditCard) {
      return '';
    }

    return `****-****-****-${this.invoice.transaction.creditCard}`;
  }

  public get total(): number {
    if (!this.invoice) {
      return 0;
    }

    return this.invoice.total;
  }


  /**
   * @inheritdoc
   */
  public ngOnInit() {
  }

}
