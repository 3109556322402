/**
 * WebAppPopup domain model.
 */
import {LoyaltyPopup} from "./loyalty-popup";

export class LoyaltyPopupConfig {
  public id: string;
  public name: string;
  public popup: LoyaltyPopup;
  public start: string;
  public end: string;
  public delay: number;
  public repeat: number;
  public is_enabled: boolean;

  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }
}
