import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupService } from './services/popup.service';
import { PopupComponent } from './components/popup/popup.component';
import { SpinnerPopupComponent } from './components/spinner-popup/spinner-popup.component';
import { IframePopupComponent } from './components/iframe-popup/iframe-popup.component';
import { SharedModule } from '../shared/shared.module';
import { ProgressSpinnerPopupComponent } from './components/progress-spinner-popup/progress-spinner-popup.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [PopupService],
  declarations: [
    PopupComponent,
    SpinnerPopupComponent,
    ProgressSpinnerPopupComponent,
    IframePopupComponent
  ],
  entryComponents: [
    PopupComponent,
    SpinnerPopupComponent,
    ProgressSpinnerPopupComponent,
    IframePopupComponent
  ],
  exports: [
    PopupComponent,
    SpinnerPopupComponent,
    ProgressSpinnerPopupComponent,
    IframePopupComponent
  ],
})
export class PopupModule { }
