<div class="popup-caption" *ngIf="caption" [style.margin-top]="(confirm || showButtons) && '15px'">
  {{caption}}
</div>
<div class="popup-text">
  {{message}}
</div>
<div class="centered">
  <button pb-button md-dialog-close class="popup-button" *ngIf="!confirm && showButtons" [md-dialog-close]="popupResult.YES">{{buttonTitle}}</button>
</div>
<div class="popup-link" *ngIf="!confirm && showButtons">
  <!-- <button [md-dialog-close]="popupResult.NO" class="button-link">Back to Activity Dashboard</button> -->
</div>
<div class="row" *ngIf="confirm">
  <button pb-button [md-dialog-close]="popupResult.NO" white small class="popup-button">No</button>
  <button pb-button [md-dialog-close]="popupResult.YES" red small class="popup-button">Yes</button>
</div>
<button class="close-button" [md-dialog-close]="popupResult.OK" *ngIf="showCloseButton">
  <pb-icon name="#iconClose" class="close-icon"></pb-icon>
</button>
