import { AfterContentInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { LoadedImage } from '../../../../core';

/**
 * Directive for drag and drop files.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[drag-n-drop]'
})
export class DragNDropDirective implements AfterContentInit {
  /**
   * Amount of files.
   */
  @Input()
  public filesCount = 0;

  /**
   * Max amount of files.
   */
  @Input()
  public filesCountRestriction = 0;

  /**
   * Format type file regex for validate input files.
   */
  @Input()
  public formatTypeRegex = /^image/gi;

  /**
   * Emitter for file drop(when user unhold mouse button with image).
   */
  @Output()
  public filesChangeEmiter = new EventEmitter<LoadedImage[]>();

  /**
   * If this equals true, we check element and event target for avoid ambigious invocation.
   * This is related to drag n drop element with other drag and drop elements inside of it.
   */
  @Input()
  public useConcreteBlock = false;

  /**
   * Addeing event handlers for child items to handle drop.
   */
  @Input()
  public coverInnerElements = false;

  /**
   * Emitter for dragging over(when user holding image over control).
   */
  @Output()
  public dragoverEmitter = new EventEmitter();

  /**
   * @constructor
   */
  public constructor(private elRef: ElementRef) { }

  /**
   * @inheritdoc
   */
  public ngAfterContentInit(): void {
    this.seedInnerHandlers();
  }

  /**
   * Event which fired when user hold image over component.
   * @param event Event for dragover event type.
   */
  @HostListener('dragover', ['$event'])
  public onDragover(event) {
    event.preventDefault();
    this.dragoverEmitter.emit();
  }


  /**
   * Event which fired when use stop holding image on component and unpress mouse button
   * with dragged image.
   * @param event Event for drop event type.
   */
  @HostListener('drop', ['$event'])
  public async onDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    if (this.useConcreteBlock && !this.isInnerTarget(event.target as HTMLElement)) {
      const element = this.elRef.nativeElement as HTMLElement;
      let target: EventTarget = null;
      if (event.target) {
        target = event.target;
      } else if (event.currentTarget) {
        target = event.currentTarget;
      }

      if (target !== element) {
        return;
      }
    }

    event.stopPropagation();
    event.stopImmediatePropagation();

    if (this.filesCount < this.filesCountRestriction) {
      const files = this.getFiles(event).filter(file => file.type.search(this.formatTypeRegex) !== -1);
      if (files.length === 0) {
        return;
      }

      const loadedImages: LoadedImage[] = [];

      for (const file of files) {
        await this.readFile(file).then((res: LoadedImage) => {
          loadedImages.push(res);
        });
      }

      this.filesChangeEmiter.emit(loadedImages);
    }
  }

  private readFile(file: File): Promise<LoadedImage> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve({ base64String: reader.result, file: file });
      };
    });
  }

  /**
   * Checking if target of dropped element inside of directive.
   * @param target Target of dropped file.
   */
  private isInnerTarget(target: HTMLElement): boolean {
    let parent = target.parentElement;
    if (parent === this.elRef.nativeElement) {
      return true;
    }

    while (parent) {
      parent = parent.parentElement;
      if (parent === this.elRef.nativeElement) {
        return true;
      }
    }

    return false;
  }

  private seedInnerHandlers() {
    if (this.coverInnerElements) {
      const nativeElement = this.elRef.nativeElement as HTMLElement;
      const stack: HTMLElement[] = [];
      stack.push(nativeElement);

      while (stack.length !== 0) {
        const element = stack.pop();
        for (let i = 0; i < element.children.length; i++) {
          stack.push(<HTMLElement>element.children[i]);
        }

        if (element !== nativeElement) {
          element.addEventListener('drop', event => {
            this.onDrop(event);
          });

          element.addEventListener('dragover', event => {
            this.onDragover(event);
          });
        }
      }
    }
  }

  /**
   * Returns file instance with cross browsing respect(IE 11/Edge/Firefox/Chrome).
   * @param evt Drop event.
   */
  private getFiles(evt: any): File[] {
    const items = evt.dataTransfer.items;

    if (items && items.length > 0) {
      const files: File[] = [];
      for (const key of items) {
        const file = key.getAsFile();
        files.push(file);
      }
      return files;
    } else if (evt.dataTransfer.files && evt.dataTransfer.files.length > 0) {
      return evt.dataTransfer.files;
    }

    return null;
  }
}
