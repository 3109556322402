import {HttpErrorResponse, HttpEventType, HttpResponse, } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormControl, NgForm} from '@angular/forms';
import {MdDialog} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';
import {filter, map, tap} from 'rxjs/operators';
import {
  b64toBlob,
  checkBadWords,
  defaultRadiusesInKiloMeter,
  defaultRadiusesInMile, defaultRadiusesInMileForEvents,
  nonCodedPhoneMask,
  padRight,
} from '../../../utils';
import {BrandService, LoadedImage, Place, PlaceService, TokenStorageService,} from '../../core';
import {PopupService} from '../../popup';
import {BetterCropperPopupComponent, DateRangeComponent} from '../../shared';
import {CanStepDeactivate} from '../can-step-deactivate';
import {EventDataService} from '../services/event-data.service';
import {EventImageService} from '../services/event-service/eventimage.service';
import {EventStyleVm as EventStyleVm, Radius} from './event-style';
import {Plans} from '../../core/models/plans';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {EventLocationPopupComponent} from '../event-location-popup/event-location-popup.component';
import {WorkDayVm as WorkDay} from '../../shared/components/work-schedule/models/work-day';
import {Observable} from 'rxjs';
import {MerchantApp} from '../../core/models/merchant-app';

const VIDEO_TYPE_MP4 = 'video/mp4';
const VIDEO_TYPE_QUICKTIME = 'video/quicktime';

@Component({
  selector: 'pb-event-style',
  templateUrl: './event-style.component.html',
  styleUrls: ['./event-style.component.scss'],
})
export class EventStyleComponent
  implements OnChanges, AfterViewInit, CanStepDeactivate {
  // Default image sizes.
  private readonly defaultImageWidth = 335;
  private readonly defaultImageHeight = 280;
  private readonly customImageWidth = 375;
  private readonly customImageHeight = 375;

  public mediaTypeSelector = [
    {title: 'Image', type: 'image', icon: 'photo'},
    {title: 'Video', type: 'video', icon: 'video'},
  ];

  /**
   * Variable for work with browse image and send to cropper.
   */
  public couponImage = '';
  public videoThumbImage = '';
  public couponVideo: number = undefined;
  public profanityList: string = null;

  public places: BehaviorSubject<Place[]> = new BehaviorSubject<Place[]>([]);

  public placeCountries = [
    { value: 'US', text: 'United States', checked: true, class: 'black', font: 12, dial_code: '+1' },
    { value: 'CA', text: 'Canada', checked: false, class: 'black', font: 12, dial_code: '+1' },
    { value: 'UK', text: 'United Kingdom', checked: false, class: 'black', font: 12, dial_code: '+44' },
    { value: 'NL', text: 'Netherlands', checked: false, class: 'black', font: 12, dial_code: '+31' },
    { value: 'DE', text: 'Germany', checked: false, class: 'black', font: 12, dial_code: '+49' },
    { value: 'AT', text: 'Austria', checked: false, class: 'black', font: 12, dial_code: '+43' },
  ];

  public callCountry = '';

  /**
   * Old price from input.
   */
  public oldPrice = new FormControl();

  /**
   * New price from input.
   */
  public newPrice = new FormControl();

  /**
   * Checkbox All for states (federal districts).
   */
  public isSelectAllStates = false;
  public isSelectAllCountries = true;

  /**
   * Checkbox All Locations.
   */
  public isSelectAllLocations = false;

  /**
   * An array of state locations.
   */
  public states: { id: number; name: string; value?: boolean }[] = [];
  public countries: { id: number; name: string; value?: boolean }[] = [];

  /**
   * Array of locations.
   */
  public locations: Place[] = [];
  public radiuses: any;

  public eventTime = new WorkDay('Start');
  public eventSingleDay = false;
  public eventAllDay = false;

  /**
   * If 'true' make Custom Age Range available.
   */
  public isAgeRangeActive = false;

  /**
   * Model for this component.
   */
  @Input()
  public eventStyle: EventStyleVm;

  @Input()
  public merchantId: number;

  @Input()
  public couponId = 0;

  /**
   * All Merchant locations.
   */
  @Input()
  public user;

  @Input()
  public merchantKeywords: string = null;

  @Input()
  public userCountry: string = null;

  /**
   * Campaign form.
   */
  @ViewChild(NgForm)
  public form: NgForm;
  public videoFile: File;

  public campaignDuration = 12;

  @ViewChild('dateRange')
  public dateRange: DateRangeComponent;

  public campaignPlan: Plans;
  public stateErrorText: string;
  public mediaType = 'image';

  public videoUploadInstructions = `
  We recomend using landscape videos that are 1080x720 in resolution.
  `;

  public imageUploadInstructions = `
  We recomend using images that are 1080x720 in resolution.
  Recomended aspect ratio is 1.5:1
  `;

  public videoThumbUploadInstructions = `
  We recomend using images that are 1080x720 in resolution.
  Recomended aspect ratio is 1.5:1
  `;
  public brand: MerchantApp = new MerchantApp({id: 'gettinlocal', name: 'GettinLocal', categories: []});

  /**
   * Keywords.
   */
  public keywords$: BehaviorSubject<string>;

  /**
   * @constructor
   */
  constructor(
    private dialog: MdDialog,
    private tokenService: TokenStorageService,
    private placeService: PlaceService,
    private sanitizer: DomSanitizer,
    private dataService: EventDataService,
    private imageService: EventImageService,
    private brandService: BrandService,
    private popupService: PopupService,
    private cdr: ChangeDetectorRef
  ) {
    this.priceLengthLimitSubscription(this.oldPrice, 'priceLabelWas');
    this.priceLengthLimitSubscription(this.newPrice, 'priceLabelIs');

    this.eventTime.activateDay(true);
    this.eventTime.makeAllDay(false);

    this.places.subscribe(values => {
      this.renderPlaces(values);
    });
    this.placeService.getPlaces(this.merchantId).then(data => {
      this.places.next(data);
    });

    this.brandService.brand.subscribe((b) => {
      this.brand = b;
    });
  }

  public get phoneMask() {
    return nonCodedPhoneMask();
  }

  public async changeMediaType(event): Promise<void> {
    this.mediaType = event;
  }

  public getMediaType(): string {
    if (this.eventStyle.couponVideo) {
      if (this.eventStyle.couponVideo.video) return 'video';
    }
    return 'image';
  }

  public hasCouponImage(): boolean {
    if (this.eventStyle.couponImage) return true;
    return false;
  }

  public hasVideo(): boolean {
    if (this.eventStyle.couponVideo) {
      return this.eventStyle.couponVideo.video !== null;
    }
    return false;
  }

  public hasVideoThumb(): boolean {
    if (this.eventStyle.couponVideo) {
      return this.eventStyle.couponVideo.video_thumb !== null;
    }
    return false;
  }

  public ngOnInit(): void {
    if (this.eventStyle.keywords.length === 0) {
      this.eventStyle.keywords = this.merchantKeywords;
    }

    this.campaignPlan = this.dataService.campaign_plan.getValue();
    this.dataService.campaign_plan.subscribe((plan) => {
      this.campaignPlan = plan;
    });
    this.dataService.profanity_list.subscribe((list) => {
      this.profanityList = list;
    });

    if (this.eventStyle.dateRange.start === null) {
      this.eventStyle.dateRange.start = moment().startOf('day')
        .toDate();
      this.eventStyle.dateRange.end = moment().endOf('day')
        .toDate();

      this.toggleTimeControl();
    }

    this.keywords$ = new BehaviorSubject<string>(this.eventStyle.keywords);

    this.callCountry = this.getCountryForCallingCode(this.eventStyle.contactInformationPhoneCode);

    this.ageRangeChanged();
    this.getRadiuses();
  }

  private toggleEventControls(): void {
    const startDate = moment(this.eventStyle.dateRange.start);
    const endDate = moment(this.eventStyle.dateRange.end);
    if (endDate.diff(startDate, 'days') > 0) {
      this.eventSingleDay = false;
    }

    if (startDate.format('hh:mm:ss a') === '12:00:00 am' &&
      endDate.format('hh:mm:ss a') === '11:59:59 pm'
    ) {
      this.eventAllDay = true;
    } else {
      this.eventAllDay = false;
    }
  }

  private toggleTimeControl(): void {
    this.eventStyle.dateRange.start = moment(
      moment(this.eventStyle.dateRange.start).format('YYYY-MM-DD') + ' ' + this.eventTime.start.time24
    ).toDate();
    this.eventTime.start = this.eventStyle.dateRange.start;

    this.eventStyle.dateRange.end = moment(
      moment(this.eventStyle.dateRange.end).format('YYYY-MM-DD') + ' ' + this.eventTime.end.time24
    ).toDate();
    this.eventTime.end = this.eventStyle.dateRange.end;
  }

  /**
   * @inheritdoc
   */
  public ngOnChanges(changes: SimpleChanges): void {
    this.mediaType = this.getMediaType();

    if (changes.eventStyle && changes.eventStyle.currentValue) {
      this.oldPrice.setValue(+changes.eventStyle.currentValue.priceLabelWas);
      this.newPrice.setValue(+changes.eventStyle.currentValue.priceLabelIs);

      if (this.eventStyle.dateRange.start === null) {
        const startTime = new Date();
        startTime.setHours(9, 0, 0, 0);
        this.eventTime.start = startTime;
      } else {
        this.eventTime.start = this.eventStyle.dateRange.start;
      }

      if (this.eventStyle.dateRange.end === null) {
        const endTime = new Date();
        endTime.setHours(18, 0, 0, 0);
        this.eventTime.end = new Date(endTime);
      } else {
        this.eventTime.end = this.eventStyle.dateRange.end;
      }

      this.toggleEventControls();
      this.callCountry = this.getCountryForCallingCode(this.eventStyle.contactInformationPhoneCode);
      console.error(this.eventStyle.contactInformationPhone)
    }

    if ('places' in changes && changes.places.currentValue) {
      this.renderPlaces(changes.places.currentValue);
    }

    if ('locations' in changes && changes.locations.currentValue) {
      this.renderPlaces(changes.places.currentValue);
    }
  }

  private renderPlaces(places: any): void {
    this.filterMerchantLocations(places);
    this.checkCampaignLocations();
  }

  /**
   * @inheritdoc
   */
  public ngAfterViewInit(): void {
    // Need trigger a change detection cycle using setTimeout() before can extract a value from a control.
    setTimeout(() => {
      if (this.eventStyle.errors.length > 0) {
        this.invalidateForm();
      }
    }, 0);
  }

  private invalidateForm(): void {
    const controls = this.form.controls;

    while (this.eventStyle.errors.length > 0) {
      const {field, errors} = this.eventStyle.errors.pop();

      controls[field].setErrors(errors);
    }

    this.canStepDeactivate();
  }

  /**
   * Limit the price integer part for 4 and fractional part for 2 symbols.
   * @param priceControl Control for necessary price to set value after changes.
   * @param pricePreviewLabel Price label to preview display.
   */
  private priceLengthLimitSubscription(
    priceControl: FormControl,
    pricePreviewLabel: string
  ) {
    priceControl.valueChanges
      .pipe(
        filter((val: number) => !isNaN(val)),
        map((val: number) => {
          const stringify = (+val).toString();
          this.eventStyle[pricePreviewLabel] = stringify;
          return stringify;
        }),
        map((val: string) => this.divideFloatNumber(val)),
        filter(({integerPart, fractionalPart}) =>
          this.checkValidLength(integerPart, fractionalPart)
        ),
        map(({integerPart, fractionalPart}) =>
          this.slicePrice(integerPart, fractionalPart)
        ),
        tap((val) => {
          priceControl.setValue(val);
          this.eventStyle[pricePreviewLabel] = val.toString();
        })
      )
      .subscribe();
  }

  /**
   * Divide float price number for integer and fractional parts.
   * @param value Float number as string to divide by separator.
   */
  private divideFloatNumber(
    value: string
  ): { integerPart: string; fractionalPart: string } {
    const dividedNumber = value.split('.');
    const integerPart = dividedNumber[0];
    const fractionalPart = dividedNumber.length > 1 ? dividedNumber[1] : null;
    return {integerPart: integerPart, fractionalPart: fractionalPart};
  }

  // tslint:disable:no-magic-numbers
  private checkValidLength(integerPart, fractionalPart): boolean {
    return (
      integerPart.length > 4 || (fractionalPart && fractionalPart.length > 2)
    );
  }

  private slicePrice(integerPart, fractionalPart) {
    let resultValue = integerPart.slice(0, 4);
    if (fractionalPart) {
      resultValue += '.' + fractionalPart.slice(0, 2);
    }
    return resultValue;
  }

  // tslint:enable:no-magic-numbers

  /**
   * Update data on logo remove.
   * @param event event fired on remove logo.
   */
  public removeLogoClicked(): void {
    this.eventStyle.couponImage = null;
  }

  public removeVideoClicked(): void {
    this.videoFile = null;
    this.eventStyle.couponVideo = {video: null, id: -1, video_thumb: null};
  }

  public removeThumbnailClicked(): void {
    this.eventStyle.couponVideo.video_thumb = null;
  }

  public async videoUploaded(video: File) {
    if (!video) return;

    if (video.type !== VIDEO_TYPE_MP4 && video.type !== VIDEO_TYPE_QUICKTIME) {
      this.popupService.info_sticky('Please upload a MP4/MOV video.');
      return;
    }

    const spinner = this.popupService.progresSpinner();
    this.imageService.uploadCampaignVideo(video, this.merchantId).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          spinner.componentInstance.updateProgress(percentDone);
        } else if (event instanceof HttpResponse) {
          this.videoFile = video;
          this.eventStyle.couponVideo = <any>event.body;

          this.removeLogoClicked();
          spinner.close();
        } else if (event instanceof HttpErrorResponse) {
          spinner.close();
        }
      },
      (error) => {
        spinner.close();

        const error_data = error.error;
        if (error_data && error_data.error)
          this.popupService.info_sticky(error_data.error);
        else
          this.popupService.info_sticky(
            'An error happened while uploading the video.'
          );
      }
    );
  }

  public getVideoName(): string {
    return this.eventStyle.couponVideo
      ? this.eventStyle.couponVideo.video
      : null;
  }

  public getVideoThumbName(): string {
    return this.eventStyle.couponVideo
      ? this.eventStyle.couponVideo.video_thumb
      : null;
  }

  /**
   * Saves logo uploading result to a variable.
   * @param images Array of uploaded images.
   */
  public async logoUploaded(images: LoadedImage[]) {
    this.removeVideoClicked();

    const fullViewCaption = 'Full view image';
    const b64ImageFull = await this.presentLogoCropperPopup(
      this.customImageWidth,
      this.customImageHeight,
      images[0].base64String,
      fullViewCaption
    );

    if (b64ImageFull) {
      const spinner = this.popupService.progresSpinner();
      this.imageService
        .uploadCampaignImage(b64toBlob(b64ImageFull), this.merchantId)
        .subscribe((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            spinner.componentInstance.updateProgress(percentDone);
          } else if (event instanceof HttpResponse) {
            this.eventStyle.couponImage = <any>event.body;
            this.eventStyle.campainStyleChanged();

            spinner.close();
          } else if (event instanceof HttpErrorResponse) {
            spinner.close();
          }
        });
    }
  }

  public async videoThumbUploaded(images: LoadedImage[]) {
    // this.removeVideoClicked();

    const fullViewCaption = 'Full view image';
    const b64ImageFull = await this.presentLogoCropperPopup(
      this.customImageWidth,
      this.customImageHeight,
      images[0].base64String,
      fullViewCaption
    );

    if (!b64ImageFull) return;

    const spinner = this.popupService.spinner();
    this.imageService
      .uploadCampaignVideoThumbnail(
        b64toBlob(b64ImageFull),
        this.merchantId,
        this.eventStyle.couponVideo.id
      )
      .then((res) => {
        spinner.close();
        this.eventStyle.couponVideo = res;
      })
      .catch((error) => {
        spinner.close();
      });
  }

  private prepearMainImage(b64ImageFull): any {
    const image = new Image();
    image.src = b64ImageFull;

    const canvas = document.createElement('canvas');
    canvas.width = this.defaultImageWidth;
    canvas.height = this.defaultImageHeight;
    canvas
      .getContext('2d')
      .drawImage(image, 0, 0, this.defaultImageWidth, this.defaultImageHeight);

    return canvas.toDataURL('image/png');
  }

  private presentLogoCropperPopup(
    w: number,
    h: number,
    image: string | ArrayBuffer,
    caption?: string
  ): Promise<string> {
    const dialogRef = this.dialog.open(BetterCropperPopupComponent, {
      data: {
        image,
        size: this.defaultImageWidth,
        height: this.defaultImageHeight,
        caption,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  public set campaignMessage(val) {
    this.eventStyle.campaignMessage = val;
  }

  public onShowPriceChange(): void {
    this.eventStyle.isShowPrice = !this.eventStyle.isShowPrice;
  }

  public async prepareImages(src, text, w, h) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = new Image();
    canvas.height = h;
    canvas.width = w;
    img.src = src;

    await this.setImageInCanvas(img, context, w, h);

    context.font = 'bold 30px sans-serif';
    context.fillStyle = '#000';
    context.textAlign = 'center';

    const linePadding = 70;
    const lines = this.fragmentText(context, text, w);

    lines.forEach((line, i) => {
      h += i + linePadding;
      // tslint:disable-next-line:no-magic-numbers
      context.fillText(line, w / 2, h / 2.5);
    });
    return canvas;
  }

  /**
   * Wrap text to the next line if it does not fit in width.
   * @param context Canvas context.
   * @param text Campaign Title.
   * @param width Width of image.
   */
  private fragmentText(
    context: CanvasRenderingContext2D,
    text: string,
    width: number
  ): string[] {
    const lines: string[] = [];

    text.split('\n').forEach((l) => {
      if (context.measureText(l).width < width) {
        lines.push(l);
        return;
      }
      const words = l.split(' ');
      let line = '';

      while (words.length > 0) {
        // Take the first element from the array and removing the last character from it until the condition is met.
        // Characters that have been deleted are placed in the second element of the array.
        while (context.measureText(words[0]).width >= width) {
          const tmp = words[0];
          words[0] = tmp.slice(0, -1);
          if (words.length > 1) {
            words[1] = tmp.slice(-1) + words[1];
          } else {
            words.push(tmp.slice(-1));
          }
        }
        // If the condition is satisfied, remove the first element from the 'words' array.
        // When cycle repeat, the item which was deleted from the 'words' array added to the 'lines' array.
        // The cycle will repeat because at the last step the length of the 'words' array was increased.
        if (context.measureText(line + words[0]).width < width) {
          line += words.shift() + ' ';
        } else {
          lines.push(line);
          line = '';
        }
        if (words.length === 0) {
          lines.push(line);
        }
      }
    });

    return lines;
  }

  public setImageInCanvas(img, context, w, h): Promise<any> {
    return new Promise((resolve) => {
      img.onload = () => {
        const imageSizeWidth = w;
        const imageSizeHeight = h;
        const aspectRatio = {
          w: imageSizeWidth / img.width,
          h: imageSizeHeight / img.height,
        };
        const aspectSize = {
          width: img.width * aspectRatio.w,
          height: img.height * aspectRatio.h,
        };

        let xOffset = Math.min(aspectSize.width, w);
        let yOffset = Math.min(aspectSize.height, h);
        let customXOffset = false;
        let customYOffset = false;
        if (xOffset < img.width) {
          // tslint:disable-next-line:no-magic-numbers
          xOffset = (img.width - xOffset) / 2;
          customXOffset = true;
        }
        if (yOffset < img.height) {
          // tslint:disable-next-line:no-magic-numbers
          yOffset = (img.height - yOffset) / 2;
          customYOffset = true;
        }
        context.drawImage(
          img,
          customXOffset ? xOffset : 0,
          customYOffset ? yOffset : 0,
          aspectSize.width,
          aspectSize.height,
          0,
          0,
          w,
          h
        );
        resolve();
      };
    });
  }

  /**
   * When a valid form,you allow to move to the next step.Starts the highlighting of invalid fields.
   */
  public canStepDeactivate(): boolean {
    this.form.onSubmit(null);

    if (this.form.valid) {
      if (this.locations.filter((l) => l.selected).length === 0) {
        this.popupService.info(
          'Please select at least one Participating Location.'
        );
        return false;
      }
    }

    this.eventStyle.locations = this.locations
      .filter(({selected}) => selected)
      .map(({id}) => id);

    const errors: any = [];

    const hasCampaignMessageBadWords = checkBadWords(
      this.eventStyle.campaignMessage,
      this.profanityList
    );
    if (hasCampaignMessageBadWords.length > 0) {
      const error = {
        field: 'campainMessage',
        errors: {
          detail: 'bad words',
          words: hasCampaignMessageBadWords,
        },
      };
      errors.push(error);
    } else {
      this.eventStyle.errors = this.eventStyle.errors.filter(
        (error) => error.field !== 'campainMessage'
      );
    }

    const hasCampaignDescriptionBadWords = checkBadWords(
      this.eventStyle.dealDescription,
      this.profanityList
    );
    if (hasCampaignDescriptionBadWords.length > 0) {
      const error = {
        field: 'dealDescription',
        errors: {
          detail: 'bad words',
          words: hasCampaignDescriptionBadWords,
        },
      };
      errors.push(error);
    } else {
      this.eventStyle.errors = this.eventStyle.errors.filter(
        (error) => error.field !== 'dealDescription'
      );
    }

    if (errors.length > 0) {
      this.eventStyle.errors = errors;

      const controls = this.form.controls;

      while (this.eventStyle.errors.length > 0) {
        const {field, errors} = this.eventStyle.errors.pop();

        controls[field].setErrors(errors);
      }
    }

    this.eventStyle.categories = [`events`];
    try {
      if (this.brand.event_category.id) {
        this.eventStyle.categories.push(this.brand.event_category.id);
      }
    } catch (e) {
    }

    /* if (
       this.campaignPlan.name === 'premium' &&
       this.eventStyle.premiumStates.length === 0 &&
       !this.couponId
     ) {
       this.stateErrorText = 'Please select at least one state/province.';
       return false;
     }*/

    this.stateErrorText = '';

    return this.isDateValid() && this.form.valid;
  }

  public isDateValid(): boolean {
    if (this.eventTime != null && this.eventTime.start && this.eventTime.end) {
      return !moment(this.eventTime.start).isAfter(moment(this.eventTime.end)) ||
        !moment(this.eventTime.end).isBefore(moment(this.eventTime.start));
    }

    return false;
  }

  public showEventLocationPopup(): void {
    const dialogRef = this.dialog.open(EventLocationPopupComponent, {
      width: '900px',
      /**
       * global style for popup to remove paddings
       */
      panelClass: 'campaign-publish-success',
      disableClose: true,
      data: {
        merchantId: this.merchantId,
        onSuccess: (id) => {
          this.placeService.getPlaces(this.merchantId).then(places => {
            this.places.next(places);
            this.renderPlaces(places);
            setTimeout(() => {
              this.locationChecked(true, id);
            }, 1000);
          }).catch(error => {
            console.error(error);
            this.popupService.info('Error retrieving places');
          });
        }
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        /*if (result === PopupResult.YES) {
          this.router.navigateByUrl('/create_campaign');
        } else if (result === PopupResult.NO) {
          this.router.navigateByUrl('/dashboard');
        }*/
      })
      .catch((err) => err);
  }

  public isFreePlan(): boolean {
    if (!this.campaignPlan) {
      return true;
    }

    if (this.campaignPlan.name === 'free') {
      return true;
    }

    return false;
  }

  public canAddStates(): boolean {
    if (!this.campaignPlan) {
      return false;
    }

    if (this.campaignPlan.name === 'free') {
      return false;
    }

    if (this.couponId) {
      return false;
    }

    return true;
  }

  public canAddEmailStates(): boolean {
    if (!this.campaignPlan) {
      return false;
    }

    if (this.couponId) {
      return false;
    }

    return true;
  }

  public get isAllDay(): boolean {
    return this.eventTime.isAllDay();
  }

  /**
   * Min date for the 'Select Campaign Start & End Dates'
   */
  public get minDate(): Date {
    return new Date(Date.now());
  }

  public get minEndDate(): Date {
    if (this.eventStyle.dateRange.start === null) {
      return new Date(Date.now());
    }

    if (moment(this.eventStyle.dateRange.start).isAfter(moment(this.eventStyle.dateRange.end))) {
      this.eventStyle.dateRange.end = moment(this.eventStyle.dateRange.start).toDate();
    }

    return moment(this.eventStyle.dateRange.start).toDate();
  }

  /**
   * Set the end date to 30 days after whichever start date is selected.
   * @param startDate Start date of campaign.
   */
  public get maxDate(): Date {
    const startDate = this.eventStyle.dateRange.start;
    const endDate = this.eventStyle.dateRange.end;

    if (startDate !== null) {
      const isEndAfterStart = moment(endDate).isAfter(
        moment(startDate).add(this.campaignDuration, 'month')
      );

      if (isEndAfterStart) {
        this.eventStyle.dateRange.end = moment(startDate)
          .add(this.campaignDuration, 'month')
          .toDate();
      }

      return moment(startDate).add(this.campaignDuration, 'month').toDate();
    }

    return null;
  }

  public startDateEmit(startDate: Date): void {
    //
  }

  /**
   * Compare options of Select Marketing Distance.
   * @param a First options to compare.
   * @param b Second options to compare.
   */
  public compareOptions(a: Radius, b: Radius): boolean {
    return a && b ? a.value === b.value : a === b;
  }

  /**
   * Make all States are check or uncheck.
   * @param isChecked Value of the All State checkbox.
   */
  public setSelectAllStates(isChecked: boolean): void {
    this.isSelectAllStates = isChecked;
    this.states.forEach((state) => {
      state.value = isChecked;
    });
    this.uncheckLocation();
    this.filterLocations();
  }

  public setSelectAllCountries(isChecked: boolean): void {
    this.isSelectAllCountries = isChecked;
    this.isSelectAllStates = isChecked;
    this.countries.forEach((country) => {
      country.value = isChecked;
    });
    this.states.forEach((state) => {
      state.value = isChecked;
    });
    this.uncheckLocation();
    this.filterLocations();
  }

  /**
   * Check or uncheck all locations.
   * @param isChecked Value of the All Locations checkbox.
   */
  public setSelectAllLocations(isChecked: boolean): void {
    this.isSelectAllLocations = isChecked;
    this.locations.forEach((location) =>
      this.locationChecked(isChecked, location.id)
    );
  }

  public setFirstLocation(isChecked: boolean): void {
    if (this.locations.length <= 0) return;
    this.locationChecked(isChecked, this.locations[0].id);
  }

  public countryChecked(isChecked: boolean, index: number): void {
    this.countries[index].value = isChecked;
    this.isSelectAllCountries = this.countries.every((country) => country.value);

    this.filterStates();
    this.isSelectAllStates = this.states.every((state) => state.value);

    this.filterLocations();
    this.uncheckLocation();
  }

  /**
   * Show or hide all location with checked State.
   * @param isChecked Value of State checkbox.
   * @param index State index.
   */
  public stateChecked(isChecked: boolean, index: number): void {
    this.states[index].value = isChecked;
    this.isSelectAllStates = this.states.every((state) => state.value);
    this.uncheckLocation();
    this.filterLocations();
  }

  /**
   * Add or delete from the Target model each Location which was checked or unchecked.
   * @param isChecked Value of Location checkbox.
   * @param locationId Location ID.
   */
  public locationChecked(isChecked: boolean, locationId: string): void {
    const index = this.locations.findIndex(({id}) => id === locationId);

    this.locations[index].selected = isChecked;
    this.eventStyle.locations = this.locations
      .filter(({selected}) => selected)
      .map(({id}) => id);
    this.isSelectAllLocations = this.locations.every(
      ({selected}) => selected
    );
  }

  private filterMerchantLocations(places: Place[]): void {
    this.countries = places.reduce((acc, place, index) => {
      if (!place.isDisplayed) {
        return acc;
      }
      if (!acc.some((country) => country.name === place.country)) {
        acc.push({
          id: index,
          name: place.country,
          value: true,
        });
      }

      return acc;
    }, []);

    this.states = places.reduce((acc, place, index) => {
      if (!place.isDisplayed) {
        return acc;
      }

      if (!acc.some((state) => state.name === place.state)) {
        acc.push({
          id: index,
          name: place.state,
          value: true,
        });
      }
      return acc;
    }, []);

    this.locations = places.filter(
      (p) =>
        p.isDisplayed && this.countries.some((s) => s.value && p.country === s.name)
        && this.states.some((s) => s.value && p.state === s.name)
    );
  }

  /**
   * Check selected campaign locations.
   */
  private _checkCampaignLocations(): void {
    // Check place which campaign contains.
    this.places.value.forEach((p) => {
      p.selected = this.eventStyle.locations.some((l) => l === p.id);
    });

    this.countries.forEach((s) => {
      const countryLocations = this.places.value.filter((p) => p.country === s.name);
      s.value = countryLocations.some((l) => l.selected);
    });
    this.isSelectAllCountries = this.countries.every((s) => s.value);

    this.filterStates();

    // Check the states that have selected locations.
    this.states.forEach((s) => {
      const stateLocations = this.places.value.filter((p) => p.state === s.name);
      s.value = stateLocations.some((l) => l.selected);
    });

    // Set all states checkbox is checked if every state is checked.
    this.isSelectAllStates = this.states.every((s) => s.value);

    this.filterLocations();

    // Set all location checkbox is checked if every location is checked.
    this.isSelectAllLocations = this.locations.every(
      ({selected}) => selected
    );
  }

  private checkCampaignLocations() {
    setTimeout(() => {
      if (this.eventStyle.locations.length > 0) {
        this._checkCampaignLocations();
      } else {
        this.isSelectAllStates = true;
        this.setFirstLocation(true);
      }
    }, 1000);
  }

  private filterStates(): void {
    this.states = this.places.value.reduce((acc, place, index) => {
      if (!place.isDisplayed) {
        return acc;
      }

      if (!this.countries.some((country) => country.value && country.name === place.country)) {
        return acc;
      }

      if (!acc.some((state) => state.name === place.state)) {
        acc.push({
          id: index,
          name: place.state,
          value: !acc.some((state) => state.value),
        });
      }

      return acc;
    }, []);
  }

  /**
   * Display only locations which the state (federal district) is selected.
   */
  private filterLocations(): void {
    this.locations = this.places.value.filter(
      (p) =>
        p.isDisplayed && this.states.some((s) => s.value && p.state === s.name)
    );
  }

  /**
   * For each location in which the State is not selected, we make the field "selected" is false.
   */
  private uncheckLocation(): void {
    this.locations.forEach(({id, state}) => {
      if (this.states.some(({name, value}) => name === state && !value)) {
        this.locationChecked(false, id);
      }
    });
  }

  /**
   * Get radiuses for Select  Distance field.
   */
  public getRadiuses(): void {
    this.radiuses = defaultRadiusesInMileForEvents().map((radius) => {
      const halfMile = 0.5;
      const key = radius.miles;

      const feetsPadded = padRight(
        radius.pad,
        `${radius.feets} feet`,
        `&nbsp;`
      );
      const milesPadded = padRight(
        radius.pad,
        `${radius.miles} miles`,
        `&nbsp;`
      );

      const value =
        key < halfMile
          ? `${feetsPadded} / ${radius.kms} km`
          : `${milesPadded} / ${radius.kms} km`;

      return {key, value};
    });
    if (!this.eventStyle.radius.value) {
      this.eventStyle.radius.value = this.radiuses[
      this.radiuses.length - 1
        ].value;
    }
  }

  /**
   * Make the Custom Age Range editable.
   */
  public ageRangeChanged(): void {
    this.isAgeRangeActive = this.eventStyle.ageRange === 'custom';
  }


  /**
   * Add keyword to the Model.
   * @param keywords Keywords.
   */
  public onKeywordsChange(keywords: string[]): void {
    this.eventStyle.keywords = keywords.join();
    this.form.controls['keywords'].setErrors(null);
  }

  public customLinkChanged(event: any): void {
    // this.dataService.custom_link_count.next(1);
  }

  public hasShopOptions(): boolean {
    if (!this.eventStyle.shopOptions) return false;
    if (this.eventStyle.shopOptions[0].url_type !== '') return true;
    return false;
  }

  public clearShopOptioins(): void {
    // this.dataService.custom_link_count.next(0);

    this.eventStyle.shopOptions = [
      {url_type: '', url: '', title: ''},
      {url_type: '', url: '', title: ''},
    ];
  }

  public isShopOptionAvailable(url_type: any): boolean {
    return !!this.eventStyle.shopOptions.find(
      (s) => s.url_type === url_type
    );
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const targetClass = event.target.className;
    if (targetClass && targetClass !== 'time-range-button'
      && targetClass !== 'select ng-untouched ng-pristine ng-valid') {
      this.eventTime.end.showTimePicker = false;
      this.eventTime.start.showTimePicker = false;
      this.toggleTimeControl();
    }
  }

  public setEventAllDay($event: Event) {
    this.eventAllDay = (<HTMLInputElement>$event.target).checked;
    this.eventTime.makeAllDay((<HTMLInputElement>$event.target).checked);

    // this.toggleTimeControl();
  }

  public checkEventTime(): void {
  }

  public startDateChanged($event: Event) {
    if (moment(this.eventStyle.dateRange.start).isAfter(moment(this.eventStyle.dateRange.end))) {
      this.eventStyle.dateRange.end = moment(this.eventStyle.dateRange.start).toDate();
    }
  }

  public countryCodeChanged(value: string): void {
    this.eventStyle.contactInformationPhoneCode = value;
  }

  private getCountryForCallingCode(country_calling_code: string): string {
    const country = this.placeCountries.find(c => c.dial_code === country_calling_code);
    if (country) {
      return country.dial_code;
    }
    return '';
  }
}
