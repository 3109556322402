<div [class.inline-reverse]="inlineReverse" [class.prepend-label]="prependLabel"
     [class.prepend-label-price]="prependLabelPrice">
  <div class="header" [class.inline-note]="inlineNote">
    <label [for]="name" class="label">{{ label }}</label>
    <div class="note">{{ note }}</div>
  </div>
  <div class="body">
    <input
      *ngIf="!inputTrim"
      [(ngModel)]="value"
      class="input"
      [class.pr0]="isTypeNumber"
      [maxlength]="maxlength"
      [textMask]="mask"
      [type]="type"
      [name]="name"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [required]="required"
      [class.transparent]="isTransparent"
      [attr.disabled]="disabled ? 'disabled' : null"
      (focus)="focus()"
      #input
      [attr.max]="max"
      [attr.min]="min"
    />
    <input
      *ngIf="inputTrim"
      [(ngModel)]="value"
      class="input"
      [class.pr0]="isTypeNumber"
      [maxlength]="maxlength"
      inputTrim
      [type]="type"
      [name]="name"
      [required]="required"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [class.transparent]="isTransparent"
      [attr.disabled]="disabled ? 'disabled' : null"
      #input
      (focus)="focus()"
      [attr.max]="max"
      [attr.min]="min"
    />
    <ng-content></ng-content>
  </div>
</div>

