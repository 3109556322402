import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms/src/forms';
import { Sort } from "@angular/material";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Subscription } from "rxjs/Rx";
import { AuthService, Invoice, InvoiceService, Merchant, MerchantService, Pagination, PaginationService } from "../core";
import { PopupService } from "../popup"


@Component({
  selector: "pb-invoices-page",
  templateUrl: "./invoices-page.component.html",
  styleUrls: ["./invoices-page.component.scss"],
})
export class InvoicesPageComponent implements OnInit {
  private userSubscription: Subscription;

  constructor(
    private invoiceService: InvoiceService,
    private authService: AuthService,
    private paginationService: PaginationService,
    private router: Router,
    private popupService: PopupService,
    private merchantService: MerchantService
  ) {}

  public merchant: Merchant;

  private currentSort: Sort;

  public searchString = "";

  public tableData = [];

  public pagedItems = [];

  public invoice = [];

  public invoiceStartDate: Date;
  public invoiceEndDate: Date;

  @ViewChild('form') form;
  
  /**
   * Pagination object from pagination service.
   */
  public pagination: Pagination = new Pagination();

  public currentInvoice: Invoice;
  private maxDateField = new Date();

  public get chargeDate(): string {
    if (this.currentInvoice && this.currentInvoice.chargeDate) {
      return moment(this.currentInvoice.chargeDate, "YYYY-MM-DD").format(
        "MMM DD, YYYY"
      );
    }

    return "-";
  }

  public get maxDate(): Date {
    return this.maxDateField;
  }

  public startDateChanged(changes: Date) {
    const startDate = moment(changes);
    if (!this.invoiceEndDate) {
      return;
    }
    const endDate = moment(this.invoiceEndDate);

    if (endDate.isBefore(startDate)) {
      this.invoiceEndDate = startDate.toDate();
    }

    setTimeout(() => {
      
      this.onSubmit(this.form);
    }, 200);
  }

  public endDateChanged(changes: Date) {
    if (!this.invoiceStartDate) {
      return;
    }

    setTimeout(() => {
      
      this.onSubmit(this.form);
    }, 200);
  }

  /**
   * Triggers on submit form.
   * @param {NgForm} form
   */
  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      const startDate = moment(this.invoiceStartDate, "M/DD/YYYY").format("YYYY-MM-DD");
      const endDate = moment(this.invoiceEndDate, "M/DD/YYYY")
        .add(1, "day")
        .add(-1, "second")
        .format("YYYY-MM-DD");

      const spinner = this.popupService.spinner();
      const response = await this.invoiceService.getInvoices(this.merchant.id, startDate, endDate);
      this.invoices = response;
      this.tableData = this.getMappedItems();

      this.setPage(1);
      spinner.close();
    }
  }

  public get total(): number {
    let sum = 0;

    this.invoices.map((invoice) => {
      sum += +(invoice.total || 0)
    });

    return sum;
  }

  public get lastStatus(): string {
    if (!this.currentInvoice) {
      return "";
    }

    return this.currentInvoice.status;
  }

  public get address(): string {
    if (!this.merchant) {
      return "";
    }

    const address = this.merchant.address;

    return `${address.street}, ${address.city}, ${address.state}`;
  }

  public get invoicePeriod(): { from: string; to: string } {
    const value = { from: "-", to: "-" };

    if (
      this.invoiceStartDate &&
      this.invoiceEndDate
    ) {
      value.from = moment(this.invoiceStartDate).format("MM/DD/YY");
      value.to = moment(this.invoiceEndDate).format("MM/DD/YY");
    } else {
      value.from = moment().startOf('month').format("MM/DD/YY");
      value.to = moment().format("MM/DD/YY");
    }

    return value;
  }

  public invoices: Invoice[] = [];

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.userSubscription = this.authService.onUserChanged
      .skipWhile((user) => !user)
      .subscribe(
        async (user): Promise<void> => {
          const merchantId = user.merchantId;
          const invoices = await this.invoiceService.getCurrentMonth(merchantId);

          this.merchant = await this.merchantService.getMerchant(merchantId);
          this.currentInvoice = await this.invoiceService.getCurrent(
            merchantId
          );
          this.invoices = invoices;
          this.tableData = this.getMappedItems();

          this.setPage(1);

          if (this.userSubscription) {
            this.userSubscription.unsubscribe();
          }
        }
      );
  }

  /**
   * Navigating to current invoice.
   */
  public async navigateToCurrentInvoice(): Promise<void> {
    if (this.currentInvoice) {
      await this.router.navigateByUrl(`/invoices/${this.currentInvoice.id}`);
    }
  }

  /**
   * Invokes string search in table considering table's sorting, makes pagination start from 1st page.
   */
  public searchStringChanged(event): void {
    this.adjustPlacesData(this.currentSort, event, 1);
  }

  /**
   * Shows new list of table items considering table's sorting and search.
   */
  public setPageClicked(page: number): void {
    this.adjustPlacesData(this.currentSort, this.searchString, page);
  }

  /**
   * Invokes data sorting in table considering search string, makes pagination start from 1st page.
   */
  public sortDataChanged(sort: Sort): void {
    this.adjustPlacesData(sort, this.searchString, 1);
  }

  public setPage(page: number) {
    this.pagination = this.paginationService.getPagination(
      this.tableData.length,
      page
    );

    this.pagedItems = this.tableData.slice(
      this.pagination.startIndex,
      this.pagination.endIndex + 1
    );
  }

  /**
   * Combines sorting, filtering and setting pagination methods.
   */
  private adjustPlacesData(
    sort: Sort,
    searchString: string,
    page: number
  ): void {
    this.sortData(sort);
    this.tableData = this.filterItems(this.tableData, searchString);
    this.setPage(page);
  }

  /**
   * Searches for string in table.
   */
  private filterItems(items, searchString: string): any[] {
    this.searchString = searchString;
    return items.filter(
      (i) =>
        i.id.toString().toLowerCase().indexOf(searchString.toLowerCase()) >
          -1 ||
        i.dueDate.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
        searchString === ""
    );
  }

  private sortData(sort: Sort): void {
    this.currentSort = sort;
    const data = this.getMappedItems();
    if (!sort || !sort.active || sort.direction === "") {
      this.tableData = data;
      return;
    }

    this.tableData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "invoiceId":
          return compare(a.invoiceId, b.invoiceId, isAsc);
        case "invoiceName":
            return compare(a.invoiceName, b.invoiceName, isAsc);
        case "DueDate":
          return compare(a.deuDate, b.deuDate, isAsc);
        case "CardCharged":
          return compare(a.cardCharged, b.cardCharged, isAsc);
        case "Total":
          return compare(a.sum, b.sum, isAsc);
        case "Status":
          return compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  private getMappedItems(): any[] {
    const invoices = this.invoices.map((invoice) => {
      let nextChargeDate = "-";
      if (invoice.chargeDate) {
        nextChargeDate = moment(invoice.chargeDate)
          .add(1, "month")
          .format("MMM DD, YYYY");
        if (moment(invoice.campaign.end).isBefore(nextChargeDate)) {
          nextChargeDate = "-";
        }
      }

      return {
        id: invoice.id,
        invoiceId: invoice.id,
        // dueDate: moment(invoice.end).format('MMM DD, YYYY'),
        invoiceName: invoice.campaign.name || "",
        chargeDate: moment(invoice.chargeDate).format("MMM DD, YYYY"),
        nextChargeDate: nextChargeDate,
        cardCharged:
          invoice.transaction && invoice.transaction.creditCard
            ? `****-****-****-${invoice.transaction.creditCard}`
            : "",
        sum: invoice.total ? invoice.total.toString() : "-",
        status: invoice.status ? invoice.status : "",
      };
    });

    return invoices;
  }

  public getInvoiceDateRangeString(): string {
    if(this.invoiceStartDate && this.invoiceEndDate) {
      return (
        'Invoices for ' +
        moment(this.invoiceStartDate).format('YYYY/MM/DD') +
        ' to ' +
        moment(this.invoiceEndDate).format('YYYY/MM/DD')
      )
    }

    return "Invoices from this month";
  }
}

function compare(a: any, b: any, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
