<pb-breadcrumbs [items]="['Activities', 'Create Pop-Up Ad']"></pb-breadcrumbs>

<div class="mt-50"></div>

<div style="display: flex;">
  <div style="width: 80%">
    <form [formGroup]="popupForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col">
          <label for="name">Ad Name</label>
          <input type="text" id="name" formControlName="name" class="form-control">
          <span class="note" style="margin-left: 0px;">Example: Summer Sale</span>
          <div *ngIf="popupForm.get('name').touched && popupForm.get('name').invalid" class="text-danger">
            Name is required
          </div>
        </div>
      </div>

      <!--<div class="row">
        <div class="col">
          <label>Popup Type</label>
          <div>
            <label>
              <input type="radio" formControlName="popupType" value="type1">
              Full Image
            </label>
          </div>
          <div>
            <label>
              <input type="radio" formControlName="popupType" value="type2">
              Logo with Text
            </label>
          </div>
          <div *ngIf="popupForm.get('popupType').touched && popupForm.get('popupType').invalid" class="text-danger">
            Popup Type is required
          </div>
        </div>
      </div>-->

      <div class="row" [ngClass]="popupForm.get('popupType').value === 'type1' ? '' : 'hidden'">
        <div class="col">
          <label for="popupImage">Ad Image</label>
          <div *ngIf="savePopupImage">
            <input type="file" id="popupImage" (change)="onFileChange($event, 'popupImage')" class="form-control">
            <div *ngIf="savePopupImage" class="existing-image">
              <a [href]="savePopupImage" target="_blank">View Current Image</a>
            </div>
          </div>
          <div *ngIf="!savePopupImage">
            <input type="file" id="popupImage" (change)="onFileChange($event, 'popupImage')" class="form-control">
          </div>

          <span class="note" style="margin-left: 0px;"> Example: unitedsummersale.png   png/jpeg  (372 X 544)</span>
        </div>
      </div>
      <div class="row" [ngClass]="popupForm.get('popupType').value === 'type1' ? '' : 'hidden'">
        <div class="col">
          <label for="popupActionUrl">Call To Action</label>
          <input type="url" id="popupActionUrl" formControlName="popupActionUrl" class="form-control">
          <div *ngIf="popupForm.get('popupActionUrl').touched && popupForm.get('popupActionUrl').invalid"
               class="text-danger">
            Popup Action URL is required and must be a valid URL
          </div>
          <span class="note" style="margin-left: 0px;">(call to action URL)      i.e., https://www.ual.com/summersale</span>
        </div>
      </div>

      <div class="row" [ngClass]="popupForm.get('popupType').value === 'type2' ? '' : 'hidden'">
        <div class="col">
          <label for="logo">Logo</label>
          <input type="file" id="logo" (change)="onFileChange($event, 'logo')" class="form-control">
        </div>
      </div>

      <div class="row" [ngClass]="popupForm.get('popupType').value === 'type2' ? '' : 'hidden'">
        <div class="col">
          <label for="backgroundColor">Background Color</label>
          <input type="color" id="backgroundColor" formControlName="backgroundColor" class="form-control">
          <div *ngIf="popupForm.get('backgroundColor').touched && popupForm.get('backgroundColor').invalid"
               class="text-danger">
            Background Color is required
          </div>
        </div>
      </div>

      <div class="row" [ngClass]="popupForm.get('popupType').value === 'type2' ? '' : 'hidden'">
        <div class="col">
          <label for="content">Content</label>
          <textarea id="content" formControlName="content" #contentEditor class="form-control"></textarea>
          <div *ngIf="popupForm.get('content').touched && popupForm.get('content').invalid" class="text-danger">
            Content is required
          </div>
        </div>
      </div>

      <div class="row" [ngClass]="popupForm.get('popupType').value === 'type2' ? '' : 'hidden'">
        <div class="col">
          <h3>Primary Button</h3>
          <label for="primaryButtonText">Text</label>
          <input type="text" id="primaryButtonText" formControlName="primaryButtonText" class="form-control">
          <div *ngIf="popupForm.get('primaryButtonText').touched && popupForm.get('primaryButtonText').invalid"
               class="text-danger">
            Text is required
          </div>
          <label for="primaryButtonUrl">URL</label>
          <input type="url" id="primaryButtonUrl" formControlName="primaryButtonUrl" class="form-control">
          <div *ngIf="popupForm.get('primaryButtonUrl').touched && popupForm.get('primaryButtonUrl').invalid"
               class="text-danger">
            URL is required and must be a valid URL
          </div>

          <div class="row">
            <div class="col">
              <label for="primaryButtonColor">Color</label>
              <input type="color" id="primaryButtonColor" formControlName="primaryButtonColor" class="form-control">
            </div>
            <div class="col">
              <label for="primaryButtonTextColor">Text Color</label>
              <input type="color" id="primaryButtonTextColor" formControlName="primaryButtonTextColor"
                     class="form-control">
            </div>
            <div class="col">
              <label for="primaryButtonBorderColor">Border Color</label>
              <input type="color" id="primaryButtonBorderColor" formControlName="primaryButtonBorderColor"
                     class="form-control">
            </div>
          </div>
        </div>
      </div>
      <div class="row" [ngClass]="popupForm.get('popupType').value === 'type2' ? '' : 'hidden'">
        <div class="col">
          <h3>Secondary Button</h3>
          <label for="secondaryButtonText">Text</label>
          <input type="text" id="secondaryButtonText" formControlName="secondaryButtonText" class="form-control">
          <div *ngIf="popupForm.get('secondaryButtonText').touched && popupForm.get('secondaryButtonText').invalid"
               class="text-danger">
            Text is required
          </div>
          <div class="row">
            <div class="col">
              <label for="secondaryButtonColor">Color</label>
              <input type="color" id="secondaryButtonColor" formControlName="secondaryButtonColor" class="form-control">
            </div>
            <div class="col">
              <label for="secondaryButtonTextColor">Text Color</label>
              <input type="color" id="secondaryButtonTextColor" formControlName="secondaryButtonTextColor"
                     class="form-control">
            </div>
            <div class="col">
              <label for="secondaryButtonBorderColor">Border Color</label>
              <input type="color" id="secondaryButtonBorderColor" formControlName="secondaryButtonBorderColor"
                     class="form-control">
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <div class="section-header">
            <div class="header-text"></div>
            <div right>
              <div class="row">
                <button pb-button class="button" type="button" small white (click)="onCancel($event)">Cancel</button>
                <button pb-button class="button" small type="submit">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="preview">
    <div [innerHTML]="previewContent">
    </div>
  </div>
</div>

<div class="mb-50"></div>
<div style="height: 100px"></div>
