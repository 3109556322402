<form *ngIf="user$ | async as user">
  <!-- <pb-breadcrumbs [items]="['Activities', 'New Campaign']"></pb-breadcrumbs> -->

  <header pb-page-header>
    <div left>{{id? 'Edit Campaign' : 'New Campaign'}}</div>
    <div right>
      <span class="need-assistance">Need help with creating your campaign? Contact us at info@gettinlocal.com</span>
    </div>
  </header>

  <section class="container">
    <div class="steps campaign-steps">
      <pb-step *ngFor="let step of steps" [number]="step.displayNumber" [isActive]="step.number === currentStep.number"
        [isValid]="step.isValid" (click)="chooseStep(step)" [horizontal]="true" [pageNumber]="step.number + 1">
        {{step.text}}</pb-step>
      <div class="separator"></div>
      <button *ngIf="!isFirstStep" pb-button class="button" white autoHeight (click)="backStep()">Back</button>
      <button *ngIf="!isLastStep" pb-button class="button" autoHeight (click)="nextStep()">Next</button>
      <!-- <button *ngIf="isLastStep" pb-button class="button" autoHeight
        (click)="publishCampaign()">Publish Now</button> -->
    </div>
  </section>

  <div class="components-and-preview-container">
    <div class="left-components" [ngSwitch]="currentStep.number">
      <pb-campaign-style #leftComponent [merchantId]="merchantId" [couponId]="id" [campaignStyle]="campaignStyle"
                         *ngSwitchCase="0">
      </pb-campaign-style>
      <pb-redemption-options #leftComponent (upgradePlan)="upgradePlan()" [couponId]="id"
        [redemptionOptions]="redemptionOptions" [isForbiddenToChange]="isCampaignHasId" *ngSwitchCase="1">
      </pb-redemption-options>
      <pb-target-marketing #leftComponent [merchant]="merchant" [targetMarketing]="targetMarketing" [userCountry]="userCountry"
        [merchantKeywords]="merchantKeywords" *ngSwitchCase="2">
      </pb-target-marketing>
      <pb-finalize-campaign #leftComponent [campaignStyle]="campaignStyle" (publishCampaign)="publishCampaign()"
        *ngSwitchCase="3">
      </pb-finalize-campaign>
    </div>
    <pb-preview-coupon *ngIf="user.merchant && !isLastStep" [isEdit]="id" [logo]="user.merchant.logo"
      (upgradePlan)="upgradePlan()" [covidOptions]="user.merchant.covidOptions" [dbaName]="user.merchant.dbaName"
      [Address]="user.merchant.address" [previewCouponVideo]="campaignStyle" [previewCouponImage]="campaignStyle"
      [targetMarketing]="targetMarketing" [redemptionOptions]="redemptionOptions" [previewCoupon]="previewCouponVm"
      [subscription]="subscription" class="preview">
    </pb-preview-coupon>
  </div>

  <section class="container">
    <div class="steps campaign-steps">
      <pb-step *ngFor="let step of steps" [number]="step.displayNumber" [isActive]="step.number === currentStep.number"
        [isValid]="step.isValid" (click)="chooseStep(step)" [horizontal]="true" [pageNumber]="step.number + 1">
        {{step.text}}</pb-step>
      <div class="separator"></div>
      <button *ngIf="!isFirstStep" pb-button class="button" white autoHeight (click)="backStep()">Back</button>
      <button *ngIf="!isLastStep" pb-button class="button" autoHeight (click)="nextStep()">Next</button>
      <!-- <button *ngIf="isLastStep" [disabled]="p" pb-button class="button" autoHeight (click)="publishCampaign()">Publish
        Now</button> -->
    </div>
  </section>
</form>
