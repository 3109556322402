
/**
 * Object which contain rates information.
 */
export class Rates {

    private static dict = [
        { key: 'couponRate', returnFunc: (rates: Rates) => rates.couponRate },
        { key: 'subscriptionRate', returnFunc: (rates: Rates) => rates.subscriptionRate },
        { key: 'promotionRate', returnFunc: (rates: Rates) => rates.promotionRate },
        { key: 'messageRate', returnFunc: (rates: Rates) => rates.messageRate },
    ];

    public couponRate: string;
    public subscriptionRate: string;
    public promotionRate: string;
    public messageRate: string;

    public getRate(rateType: RateType): string {
        let value = null;

        Rates.dict.forEach(kvp => {
            if (kvp.key === rateType) {
                value = kvp.returnFunc(this);
            }
        });

        return value;
    }
}

export type RateType = 'couponRate' | 'subscriptionRate' | 'promotionRate' | 'messageRate';
