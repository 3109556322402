<header pb-page-header>
  <div left>Create Campaign</div>
  <div right>
    <span class="need-assistance">Need help with creating your campaign? Contact us at info@gettinlocal.com</span>
  </div>
</header>
<!--<div class="price-header">-->
<!--  <div class="priceNote">-->
<!--    <h2>Let's get started creating your campaign!</h2>-->
<!--    <p class="time">-->
<!--      <pb-icon name="#iconClock" class="icon"></pb-icon>-->
<!--      Typically takes 5 minutes or less.-->
<!--    </p>-->
<!--    <p>Select the type of campaign you want to create.</p>-->
<!--    <p style="text-decoration: underline; font-weight: 500;">Promo codes are added at checkout.</p>-->
<!--  </div>-->
<!--</div>-->

<div class="price-header">
  Get in front of Known Travelers and drive Direct Bookings
</div>
<img style="width: 100%" src="https://paebac-production.s3.us-east-2.amazonaws.com/portal-public/create-campaign.png">

<div *ngIf='availablePlans' class="plans">
  <pb-pricing (onPlanChanged)="startCampaign($event)" [availablePlans]=availablePlans></pb-pricing>
</div>
