import { Directive, Input, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';


@Directive({
    selector: '[pbTextFormatting]',
    providers: [{provide: NG_VALIDATORS, useExisting: forwardRef(() => TextFormattingValidationDirective), multi: true}]
})
export class TextFormattingValidationDirective implements Validator {

    // tslint:disable-next-line:no-magic-numbers
    private nextLineTextSeparators = [9094 /*u2386*/, 10];

    @Input('line-separators')
    public allowedLineSeparators = 1;

    public validate(c: AbstractControl): ValidationErrors | null {
        if (typeof c.value === 'string') {
            const value: string = c.value;
            let countOfNextLineSeparators = 0;
            for (let i = 0; i < value.length; i++) {
                const charCode = value[i].charCodeAt(0);
                if (this.nextLineTextSeparators.indexOf(charCode) !== -1) {
                    countOfNextLineSeparators += 1;
                }
            }

            if (countOfNextLineSeparators > this.allowedLineSeparators) {
                return { required: true };
            }

            return null;
        }
    }
}
