/**
 * Merchant's image from gallery
 */
export class Image {
    /**
     * Id of merchant's image.
     */
    public id: number;
    /**
     * Url to file.
     */
    public file: string;
}
