/**
 * Used for sorting in dashboard and activites
 * By ending soon and starting soon.
 */
export enum FilterBy {

  /**
   * All sorts(not sorting in our case).
   */
  All = -1,

  /**
   * Filtering only ending soon.
   */
  EndingSoon = 0,

  /**
   * Filtering only starting soon.
   */
  StartingSoon = 1
}


export enum FilterByVisibility {
  All = -1,
  Visible = 0,
  Hidden = 1
}
