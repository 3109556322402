import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {environment} from '../../../../environments/environment';
import {TokenStorageService} from '../token-storage.service';
import {BrandMapper} from './mappers/brand.mapper';
import {MerchantAppDto} from '../merchant/dtos/merchant-app.dto';
import {MerchantApp} from '../../models/merchant-app';
import {BehaviorSubject} from 'rxjs';

const BRAND_URL = 'brands';

/**
 * Core http service provides infrastructural methods.
 */
@Injectable()
export class BrandService {

  public brand: BehaviorSubject<MerchantApp>;

  /**
   * .ctor
   * @param http Http
   */
  constructor(
    private http: Http,
    private brandMapper: BrandMapper,
    private tokenService: TokenStorageService
  ) {
    this.brand = new BehaviorSubject<MerchantApp>(new MerchantApp({
      id: 'gettinlocal',
      name: 'GettinLocal'
    }));
  }

  public IsWhiteLabelMerchant(): boolean {
    return this.brand.getValue().isWhiteLabel();
  }

  public async getBrand(name: string): Promise<MerchantApp> {
    return this.http
      .get(`${environment.apiEndpoint}/${BRAND_URL}/${name}/`, {
        // headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as MerchantAppDto)
      .map(v => this.brandMapper.mapToModel(v))
      /*.map(v => {
        this.brand.next(v);
        return v;
      })*/
      .toPromise();
  }
}
