/**
 * Dto for response with "paged" scheme.
 * Related to Merchant's compaigns.
 */
import { PagedDto } from '../../pagination/dtos/paged.dto';

export class CampaignsDto extends PagedDto<CampaignDto> {

}

/**
 * Dto for Merchant's campaign.
 */
export interface CampaignDto {
    video: number;

    id: number;

    start: string;
    end: string;
    name: string;
    description: string;
    budget: number;
    merchantId: number;
    operation_hours: any;
    target_audience: {
        age_start: number;
        age_end: number;
        radius: number;
        gender: string;
        /**
         * Array of ids for interests.
         */
        interest: string[];
    };

    /**
     * Logo URL.
     */
    image:  any;

    /**
     * Message for merchant's company.
     */
    message_text: string;

    /**
     * Keywords for merchant's company.
     */
    keywords: string[];

    /**
     * Ids of places.
     */
    places: string[];

    /**
     * Impressions by coupon.
     */
    impressions: number;

    /**
     * Generations by coupon.
     */
    likes: number;

    /**
     * Generations by clicks.
     */
    clicks: number;

    /**
     * Redemtions by coupon.
     */
    redemptions: number;

    /**
     * Old price of coupon.
     */
    old_price: string;

    /**
     * New price of coupon.
     */
    new_price: string;

    /**
     * New price of coupon.
     */
    in_store: string;

    /**
     * New price of coupon.
     */
    online: string;

    /**
     * Type of the campaign.
     */
    campaign_plan: number;
    promo_code: number;
}
