import { AddressComponent, PlaceSearch } from '../../../models/place-search';
import { PlaceSearchDto } from '../dtos/place-search.dto';
import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';

/**
 * Place search mapper.
 */
@Injectable()
export class PlaceSearchMapper implements Mapper<PlaceSearchDto, PlaceSearch> {
    public mapToModel(dto: PlaceSearchDto): PlaceSearch {
        const model = new PlaceSearch();
        model.provider = dto.provider;
        model.address = dto.address;
        model.id = dto.id;
        model.location = dto.location;
        model.name = dto.name;

        if (dto.address_components) {
            model.addressComponents = dto.address_components.map(ac => {
                const addressComponent = new AddressComponent();
                addressComponent.longName = ac.long_name;
                addressComponent.shortName = ac.short_name;
                addressComponent.types = ac.types;

                return addressComponent;
            });
        }

        return model;
    }
}
