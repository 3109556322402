
/**
 * Merchant's promotion.
 */
export class MerchantsPromotion {

    /**
     * Id of number.
     */
    public id: number;

    /**
     * Name of promotion
     */
    public name: string;

    /**
     * Start date of promotion.
     */
    public startDate: string;

    /**
     * End date of promotion.
     */
    public endDate: string;

    /**
     * Start date of promotion without mapping modification.
     */
    public startDateRaw: string;

    /**
     * End date of promotion without mapping modification.
     */
    public endDateRaw: string;

    /**
     * Flag for is active.
     */
    public isActive: boolean;

    /**
     * Places for promotion.
     */
    public places: string[];

    /**
     * Completed flag for promotion.
     */
    public isCompleted: boolean;

    /**
     * Id of merchant.
     */
    public merchantId: number;

    /**
     * Operation hours for promotion.
     */
    public operationHours: any;

    /**
     * Target audience information for promotion.
     */
    public targetAuidience: {
        ageStart: number;
        ageEnd: number;
        radius: number;
        gender: string;
        /**
         * Array of interest ids
         */
        interest: string[];
    };

    /**
     * Logo of promotion.
     */
    // public logo: string;

    /**
     * Blob of logo for promotion.
     */
    public logoBlob: Blob;

    /**
     * Url of image for selected category or uploaded image.
     */
    public image: string;

    /**
     * Keywords for merchant's promotion.
     */
    public keywords: string[];

    /**
     * Url for view promo.
     */
    public viewUrl: string;
}
