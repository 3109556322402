import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth/auth.service';


/**
 * Registration workflow guard.
 */
@Injectable()
export class RegisterGuard implements CanActivate {
  /**
   * @constructor
   */
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  /**
   * @inheritdoc
   */
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return Observable.fromPromise(this.authService.me()).map(r => {
      if (!r.merchant.creationCompleted && state.url !== '/profile') {
        this.router.navigate(['/profile']);
        return false;
      }

      return true;
    });
  }
}
