/**
 * Workday View Model.
 */
class Workday {
  constructor() {
    this.start = '00:00';
    this.end = '23:59';
  }
  public start: string;
  public end: string;
}

/**
 * Schedule View Model.
 */
export class Schedule {
  public monday = new Workday();
  public tuesday = new Workday();
  public wednesday = new Workday();
  public thursday = new Workday();
  public friday = new Workday();
  public saturday = new Workday();
  public sunday = new Workday();
}
