import { Component, OnInit, Input } from '@angular/core';

/**
 * Component for activity info block.
 */
@Component({
  selector: 'pb-activity-info',
  templateUrl: './activity-info.component.html',
  styleUrls: ['./activity-info.component.scss']
})
export class ActivityInfoComponent implements OnInit {
  /**
   * Company object.
   */
  @Input()
  public company: any;

  /**
   * Activity object.
   */
  @Input()
  public activity: any;

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
  }

}
