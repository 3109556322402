export function checkImageMimeType(blob: Blob): string {
  const mimeTypesToExtensions = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/jpg': 'jpg',
    // Add more MIME types and their corresponding extensions as needed
  };

  // Get MIME type of the blob
  const mimeType = blob.type;

  // Get the file extension based on the MIME type
  const extension = mimeTypesToExtensions[mimeType];

  return extension || 'unknown'; // Return 'unknown' if MIME type is not in the map
}
