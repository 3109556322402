import { ServerError } from '../../models/server-error';
import { NgForm } from '@angular/forms/src/directives';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlerService {

  constructor() { }

  /**
   * Invalidating form.
   * @param form Form which controls marked with errors.
   * @param map Object with mappers from server field to control name.
   * @param serverError Server error instance.
   */
  public invalidateForm(form: NgForm, map: any, serverError: ServerError): boolean {

    if (!serverError.validationErrors) {
      return false;
    }

    let hasInvalidControl = false;

    serverError.validationErrors.forEach(validationError => {
      const control = form.controls[map[validationError.field]];
      if (!control) {
        return;
      }

      hasInvalidControl = true;
      control.setErrors(validationError.errors);
      control.markAsDirty();
    });

    return hasInvalidControl;
  }

  /**
   * Determine if server error object has validation errors.
   * @param {ServerError} serverError mapped server error object.
   * @returns {boolean} true if not undefined and have items.
   */
  public hasValidationErrors(serverError: ServerError): boolean {
    return serverError.validationErrors && serverError.validationErrors.length > 0;
  }

  public invalidateForms(forms: NgForm[], map: any, serverError: ServerError): { invalid: Boolean, formIndex: number } {
    if (!serverError.validationErrors) {
      return { invalid: true, formIndex: null };
    }

    let hasInvalidControl = false;
    let formIndex = null;

    serverError.validationErrors.forEach(validationError => {
      forms.forEach((form, index) => {
        const control = form.controls[map[validationError.field]];
        if (!control || (hasInvalidControl && formIndex !== index)) {
          return;
        }

        hasInvalidControl = true;
        formIndex = index;
        control.setErrors(validationError.errors);
      });
    });

    return { invalid: hasInvalidControl, formIndex: formIndex };
  }
}
