import { BehaviorSubject } from "rxjs/BehaviorSubject";

import {Schedule, ValidationError} from "../../core";
import { Subject, Subscription } from "rxjs";
import { EventDataService } from "../services/event-data.service";

/**
 * Interface for Radius.
 */
export interface Radius {
  key: number;
  value: string;
}

/**
 * Campaign style model.
 */
export class EventStyleVm {
  public ageRange = "18";
  public customAgeRange: { from: number; to: number } = { from: 13, to: null };
  public gender = "B";
  public radius_in_miles: Radius = { key: 100, value: null };
  public radius_in_kms: Radius = { key: 100, value: "100 KM" };
  public radius = this.radius_in_miles;
  public locations: string[] = [];
  public categories: string[] = [];
  /**
   * Use custom image by default.
   */
  private isCustomImageValue = true;

  /**
   * Main image.
   */
  public couponImage: any;

  public dateRange: { start: Date; end: Date } = { start: null, end: null };

  /**
   * Campaign name.
   */
  private campaignMessageValue = "";

  /**
   * Show or hide price changing.
   */
  private isShowPriceValue = false;

  /**
   * Old price.
   */
  private priceLabelWasValue: string;

  /**
   * New price.
   */
  private priceLabelIsValue: string;

  private oldPriceValue: string;
  private newPriceValue: string;

  /**
   * Text over default image.
   */
  private imageLabelValue = "";

  public premiumStates: string[] = [];
  public premiumEmailStates: string[] = [];
  public subscriptions: Subscription[] = [];

  /**
   * Campaugn ID.
   */
  public id?: number;

  /**
   * Array of component form errors.
   */
  public errors: ValidationError[] = [];

  public dealDescription: string;
  private readonly initialDealDescription = "";
  /**
   * Fields for validation.
   */
  public validationFields = [
    "campainMessage",
    "imageLabel",
    "dealDescription",
    "keywords",
  ];
  public couponVideo: any;

  /**
   * @constructor
   */
  constructor() {
    this.campaignStyleChange.next(this);
    this.dealDescription = this.initialDealDescription;
    this.onlineCouponInstructions = this.initialOnlineCouponInstructions;
    this.storeCouponInstructions = this.initialStoreCouponInstructions;
    // this.contactInformationEmail = this.initialContactInformationEmail;

    // this.dealDescription = this.initialDealDescription;
    this.selectedShopOptions = this.initialselectedShopOptions;

    // this.myOptions = [
    //   { id: 1, name: 'Option 1' },
    //   { id: 2, name: 'Option 2' },
    // ];
  }

  /**
   * Emit this model.
   */
  public campaignStyleChange = new BehaviorSubject<EventStyleVm>(this);

  /**
   * Custom image.
   */
  public set isCustomImage(val) {
    this.isCustomImageValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Custom image.
   */
  public get isCustomImage() {
    return this.isCustomImageValue;
  }

  /**
   * Campaign full image.
   */
  // public set couponImageFull(val) {
  //   this.couponImageFullValue = val;
  //   this.campaignStyleChange.next(this);
  // }

  /**
   * Campaign full image.
   */
  // public get couponImageFull() {
  //   return this.couponImageFullValue;
  // }

  /**
   * Main campaign image.
   */
  // public set couponImageMain(val) {
  //   this.couponImageMainValue = val;
  //   this.campaignStyleChange.next(this);
  // }

  /**
   * Main campaign image.
   */
  // public get couponImageMain() {
  //   return this.couponImageMainValue;
  // }

  public campainStyleChanged() {
    this.campaignStyleChange.next(this);
  }

  /**
   * Default image label.
   */
  public set imageLabel(val) {
    this.imageLabelValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Default image label.
   */
  public get imageLabel() {
    return this.imageLabelValue;
  }

  /**
   * Campaign message.
   */
  public set campaignMessage(val) {
    this.campaignMessageValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Campaign message.
   */
  public get campaignMessage() {
    return this.campaignMessageValue;
  }

  /**
   * Show price.
   */
  public set isShowPrice(val) {
    this.isShowPriceValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Show price.
   */
  public get isShowPrice() {
    return this.isShowPriceValue;
  }

  /**
   * Old price.
   */
  public set priceLabelWas(val) {
    this.priceLabelWasValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Old price.
   */
  public get priceLabelWas() {
    return this.priceLabelWasValue;
  }

  /**
   * New price.
   */
  public set priceLabelIs(val) {
    this.priceLabelIsValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * New price.
   */
  public get priceLabelIs() {
    return this.priceLabelIsValue;
  }
  //
  // public get oldPrice() {
  //   return this.oldPriceValue;
  // }
  // public set oldPrice(val) {
  //   this.oldPriceValue = val;
  //   this.campaignStyleChange.next(this);
  // }



  public keywords = "";
  public isDefineHours = "all";
  public schedule = new Schedule();
  public contactInformationPhone: string = "";
  public contactInformationPhoneCode: string;

  public contactInformationText: string = "";
  public contactInformationEmail: string = "";
  public termsAndConditionsText: string = `• Not valid on prior purchases.
• No cash value.
• Void where prohibited by law.
• Valid at participating locations.
• This offer cannot be combined with other offers / coupons / discounts / promotions.
• Some restrictions may apply (see store for details).`;

  private readonly initialTermsAndConditionsText = `• Not valid on prior purchases.
• No cash value.
• Void where prohibited by law.
• Valid at participating locations.
• This offer cannot be combined with other offers / coupons / discounts / promotions.
• Some restrictions may apply (see store for details).`;

  /**
   * Placeholder input where is enter terms and conditions.
   */
  public termsAndConditionsPlaceholder: string = `• Not valid on prior purchases.
  • No cash value.
  • Void where prohibited by law.
  • Valid at participating locations.
  • This offer cannot be combined with other offers / coupons / discounts / promotions.
  • Some restrictions may apply (see store for details).`;

  /**
   * Reset terms and conditions text to initial value.
   */
  public resetTermsAndConditionsTextToInitial(): void {
    this.termsAndConditionsText = this.initialTermsAndConditionsText;
  }


  public isRedeemable = false;
  /**
   * Campaign feature.
   */
  public feature: "coupon" | "promotion" = "coupon";
  /**
   * Type coupon.
   */
  public couponType: "online" | "in-store" | "both" = "in-store";
  public inStoreCouponType: "text" | "qrcode" | "barcode" | "none" = "none";

  public featureOptions = [
    { value: 1, text: "Let's Do It", checked: true, class: 'green', font: 20 },
    { value: 0, text: "Not Now", checked: false, class: 'black', font: 12 },
  ];
  /**
   * Type limit the number of coupons.
   */
  public storeCouponLimit: "unlimited" | "custom" = "unlimited";
  /**
   * Number of store coupons to use.
   */
  public storeCouponLimitCount = 1;
  /**
   * Code to use store coupon.
   */
  public storeCouponCode: string;
  /**
   * QRCode image.
   */
  public inStoreImage = { base64: null, blob: null, name: null };
  public inStoreImageRemoved = false;
  /**
   * Code to use online coupon.
   */
  public onlineCouponCode: string;
  /**
   * Instructions for in-store coupon.
   */
  public storeCouponInstructions: string;
  private readonly initialStoreCouponInstructions = "Present Code";

  /**
   * Instructions online coupon.
   */
  public onlineCouponInstructions: string;
  private readonly initialOnlineCouponInstructions =
    "Tap Code to Copy & Paste in Shopping Cart Checkout";

  // public optionsModel: number[];
  // public myOptions: IMultiSelectOption[];

  public shopOptionsList = [
    { url_type: "shop_now", title: "Shop Now", pad: 15 },
    { url_type: "order_now", title: "Order Now", pad: 15 },
    { url_type: "reserve_now", title: "Reserve Now", pad: 13 },
    { url_type: "book_now", title: "Book Now", pad: 15 },
    { url_type: "use_in_store", title: "Learn More", pad: 15 },
    { url_type: "get_tickets", title: "Get Tickets", pad: 16 },
  ];

  public enabledShopOptions = {
    shop_now: false,
    order_now: false,
    book_now: false,
    reserve_now: false,
    use_in_store: false,
    get_tickets: false,
  };

  private readonly initialselectedShopOptions = <any>{};
  public selectedShopOptions = {
    shop_now: "",
    order_now: "",
    book_now: "",
    reserve_now: "",
    use_in_store: "",
    get_tickets: "",
  };

  public shopOptions: { url_type: string; url: string; title: string }[] = [
    { url_type: "", title: "", url: "" },
    { url_type: "", title: "", url: "" },
  ];


  /**
   * Reset online and store coupon instructions to initial value.
   */
  public resetCouponInstructions(): void {
    this.onlineCouponInstructions = this.initialOnlineCouponInstructions;
    this.storeCouponInstructions = this.initialStoreCouponInstructions;

    // this.dealDescription = this.initialDealDescription;
  }

  public toggleDropdown(): void {}
}
