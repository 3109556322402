import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Plans } from "../../core/models/plans";
import { PromoCode } from "../../core/models/promo-code";
import { Subscription } from "../../core";

@Injectable()
export class EventDataService {
  avaiable_plans: Plans[];
  campaign_plan: BehaviorSubject<Plans>;
  subscription: BehaviorSubject<Subscription>;
  upgrade_fee: BehaviorSubject<number>;
  premium_state_count: BehaviorSubject<number>;
  premium_email_state_count: BehaviorSubject<number>;
  custom_link_count: BehaviorSubject<number>;
  payment_required: BehaviorSubject<boolean>;
  promo_code: BehaviorSubject<PromoCode>;
  profanity_list: BehaviorSubject<string>;
  is_upgradable: BehaviorSubject<boolean>;
  show_pricing: BehaviorSubject<boolean>;

  constructor() {
    this.campaign_plan = new BehaviorSubject<Plans>(null);
    this.subscription = new BehaviorSubject<Subscription>(null);
    this.promo_code = new BehaviorSubject<PromoCode>(null);
    this.payment_required = new BehaviorSubject<boolean>(false);
    this.upgrade_fee = new BehaviorSubject<number>(0);

    this.premium_state_count = new BehaviorSubject<number>(0);
    this.premium_email_state_count = new BehaviorSubject<number>(0);
    this.custom_link_count = new BehaviorSubject<number>(0);
    this.profanity_list = new BehaviorSubject<string>(null);

    this.is_upgradable = new BehaviorSubject<boolean>(true);
    this.show_pricing = new BehaviorSubject<boolean>(true);
  }
}
