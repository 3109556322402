<header pb-page-header>
  <div left>Web Applications: {{webAppId}}</div>
  <div right>
    <div class="buttons">
      <button pb-button class="button" (click)="newPopupClick()" small type="submit">Add New</button>
    </div>
  </div>
</header>

<table pb-table mdSort (mdSortChange)="sortDataChanged($event)" *ngIf="popups.length > 0">
  <thead>
  <tr>
    <th md-sort-header="name">
      <div class="row">
        Name of Pop-up
      </div>
    </th>
    <th width="10%">
      <div class="row">
        Edit
      </div>
    </th>
    <th width="10%">
      <div class="row">
        Delete
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of pagedItems;">
    <td>
      {{ item.name }}
    </td>
    <td>
      <a (click)="editPopupClick(item)">
        <i class="fa fa-pencil"></i>
      </a>
    </td>
    <td>
      <a (click)="deletePopupClick(item)">
        <i class="fa fa-trash"></i>
      </a>
    </td>
  </tr>
  </tbody>
</table>

<div class="error" *ngIf="popups.length === 0">
  No Popups Found.
</div>

<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
