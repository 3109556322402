<header pb-page-header>
  <div left>Web Applications</div>
  <div right>
    <div class="buttons">
    </div>
  </div>
</header>

<h2 class="section-title">
  <div class="tooltip-title">
    Configure Webapp
  </div>
</h2>

<div class="error" *ngIf="webapps.length != 0">
  * Web Apps assigned to you are displayed here.
</div>

<div class="error" *ngIf="webapps.length === 0">
  You are not assigned to any Web Apps.
</div>

<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
