<header pb-page-header>
  <div left>Web Applications</div>
  <div right>
    <div class="buttons">
    </div>
  </div>
</header>

<div *ngIf="popupSettings; else empty">
  <h2 class="section-title">
    <div class="tooltip-title">
      Configure Pop-up(s)
    </div>
  </h2>

  <section class="container">
    <form [formGroup]="popupForm" (ngSubmit)="onSubmit()">
      <input type="hidden" formControlName="id">

      <div class="row" *ngIf="currentWebApp.enable_data_popup_edit">
        <div class="col-md-12">
          <h3>Data Popups</h3>
          <table pb-table>
            <thead>
            <tr>
              <th width="40%">Popup</th>
              <th width="40%">Show After</th>
              <th width="20%">Enabled</th>
            </tr>
            </thead>
            <tbody>
            <td>
              <select id="data_popup" formControlName="data_popup" class="form-control">
                <option *ngFor="let option of dataPopupOptions" [value]="option.value">{{ option.label }}</option>
              </select>
            </td>
            <td>
              <input id="data_popup_delay" type="number" formControlName="data_popup_delay" class="form-control"
                     style="width: auto;">
            </td>
            <td>
              <input id="show_data_popup" type="checkbox" formControlName="show_data_popup"
                     class="form-check-input">
            </td>
            </tbody>
          </table>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <h3>Save to Home Screen Pop-up</h3>
          <table pb-table>
            <thead>
            <tr>
              <th></th>
              <th width="60%">Display Reminder (In Seconds)</th>
              <th width="20%">Enabled</th>
            </tr>
            </thead>
            <tbody>
            <td>
              <b>Save to Home
                Screen</b>
            </td>
            <td>
              <div>
                <input id="install_popup_delay" type="number" formControlName="install_popup_delay"
                       class="form-control" style="width: auto;">
              </div>
            </td>
            <td>
              <input id="show_install_popup" type="checkbox" formControlName="show_install_popup"
                     class="form-check-input">
            </td>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h3>Pop-Up(s)</h3>
          <table pb-table>
            <thead>
            <tr>
              <th>Pop-up</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th width="10%">Show After (seconds)</th>
              <th width="10%">Repeat</th>
              <th width="5%">Enabled</th>
              <!--              <th>Action</th>-->
            </tr>
            </thead>
            <tbody formArrayName="tabularFields" *ngIf="tabularFields.length > 0">
            <tr *ngFor="let field of tabularFields.controls; let i = index" [formGroupName]="i">
              <td>
                <select id="selectedPopup-{{i}}" formControlName="selectedPopup" class="form-control" readonly>
                  <option *ngFor="let option of tabularOptions" [value]="option.value">{{ option.label }}</option>
                </select>
              </td>
              <td>
                <div *ngIf="!field.get('enabled').value" (click)="handleDisabledClick(i)">
                  <input type="date" class="form-control" readonly style="background: lightgray">
                </div>
                <div *ngIf="field.get('enabled').value">
                  <input id="startTime-{{i}}" type="date" formControlName="startTime" class="form-control"
                         [disabled]="!field.get('enabled').value">
                </div>
              </td>
              <td>
                <div *ngIf="!field.get('enabled').value" (click)="handleDisabledClick(i)">
                  <input type="date" class="form-control" readonly style="background: lightgray">
                </div>
                <div *ngIf="field.get('enabled').value">
                  <input id="endTime-{{i}}" type="date" formControlName="endTime" class="form-control">
                </div>
              </td>
              <td>
                <input id="delay-{{i}}" type="number" formControlName="delay" class="form-control"
                       [disabled]="!field.get('enabled').value">
              </td>
              <td>
                <input id="repeat-{{i}}" type="number" formControlName="repeat" class="form-control"
                       [disabled]="!field.get('enabled').value">
              </td>
              <td>
                <input id="enabled-{{i}}" type="checkbox" formControlName="enabled" class="form-check-input"
                       (change)="toggleRowDisabled(i)">
              </td>
              <!--              <td>-->
              <!--                <button type="button" class="btn btn-danger" (click)="removeTabularField(i)">-->
              <!--                  <li class="fa fa-trash"></li>-->
              <!--                </button>-->
              <!--              </td>-->
            </tr>
            </tbody>
            <tbody *ngIf="tabularFields.length === 0">
            <tr>
              <td colspan="7" class="text-center">No popups configured</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--<div class="row">
        <div class="col-md-6">
          <button pb-button class="button" small (click)="addTabularField()" [disabled]="!canAddTabularField()">Add</button>
        </div>
      </div>-->

      <div class="row mt-10">
        <div class="col">
          <div class="section-header">
            <button pb-button class="button" small white routerLink="/webapp">Cancel</button>
            <button pb-button class="button" small type="submit">Save</button>
          </div>
        </div>
      </div>

    </form>
  </section>
</div>

<ng-template #empty>
  <div class="root">
    <div class="header row"></div>
    <div class="body centered">
      <div>
        Popups are not enabled. Please contact the administrator.
      </div>
    </div>
  </div>
</ng-template>
