import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { FoursquareInfoDto } from '../dtos/foursquare-info.dto';
import { FoursquareInfo } from '../../../models/foursquare-info';

/**
 * Foursquare info mapper.
 * @deprecated
 */
@Injectable()
export class FoursquareInfoMapper implements Mapper<FoursquareInfoDto, FoursquareInfo> {

    public mapToModel(dto: FoursquareInfoDto): FoursquareInfo {
        return {
            address : dto.address,
            categories : dto.categories,
            city : dto.city,
            country : dto.country,
            crossStreet : dto.cross_street,
            id: dto.id,
            location : dto.location,
            name : dto.name,
            state : dto.state,
            zip : dto.zip,
            contact : dto.contact
        } as FoursquareInfo;
    }
}
