import { Injectable } from "@angular/core";

import { Mapper } from "../../abstract/mapper";
import { PlansDto } from "../dtos/plans.dto";
import { Plans } from "../../../models/plans";

/**
 * State mapper.
 */
@Injectable()
export class PlansMapper implements Mapper<PlansDto, Plans> {
  /**
   * @inheritdoc
   * @param {PlansDto} dto Plans DTO.
   */
  public mapToModel(dto: PlansDto): Plans {
    return {
      plan_type: dto.plan_type,
      price_display: dto.price_display,
      id: dto.id,
      name: dto.name,
      title: dto.title,
      description: dto.description,
      subscription_rate: dto.subscription_rate,
      premium_state_fee: dto.premium_state_fee,
      premium_email_fee: dto.premium_email_fee,
      custom_link_fee: dto.custom_link_fee,
      tax_rate: dto.tax_rate
    };
  }
}
