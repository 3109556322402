<form>
  <section>
    <div class="header">
      <p class="header-title">{{title.header}}</p>
      <p class="header-sub-title">{{title.subheader}}</p>
      <p class="header-sub-sub-title">{{title.text}}</p>
    </div>
    <div body>
      <div class="state-selection-box">
        <div class="state-list">
          <div style="display: flex; justify-content: space-evenly;">
            <ul class="button-box">
              <li [ngClass]="{'active': selectedView === 'map'}" class="button-box-item" (click)="changeView('map')">
                <a class="nav-link">Map View</a>
              </li>
              <li [ngClass]="{'active': selectedView === 'list'}" class="button-box-item" (click)="changeView('list')">
                <a class="nav-link">List View</a>
              </li>
            </ul>
            <ul *ngIf="selectedView === 'map'" class="button-box">
              <li [ngClass]="{'active': selectedCountry === 'us'}" class="button-box-item"
                (click)="changeCountry('us')">
                <a class="nav-link">Add US States</a>
              </li>
              <li [ngClass]="{'active': selectedCountry === 'canada'}" class="button-box-item"
                (click)="changeCountry('canada')">
                <a class="nav-link">Add Canadian <br> Provinces</a>
              </li>
            </ul>
          </div>
          <div *ngIf="selectedView === 'map'">
            <us-map *ngIf="selectedCountry === 'us'" [selectedStates]="selectedStates"
              (onMapClick)="stateSelected($event)" fillColor="#000000" fillStateColor="#000000" strokeColor="#8E8E8E">
            </us-map>
            <canada-map *ngIf="selectedCountry === 'canada'" [selectedStates]="selectedStates"
              (onMapClick)="stateSelected($event)" fillColor="#000000" fillStateColor="#000000" strokeColor="#8E8E8E">
            </canada-map>
          </div>


          <div *ngIf="selectedView === 'list'" class="row listview-title">
            Select State / Provinces
          </div>
          <div *ngIf="selectedView === 'list'" class="row">
            <div class="row" style="width: 20px;"></div>
            <div class="row listview-us-title">
              United States
            </div>
            <div class="row" style="width: 20px;"></div>
            <div class="row listview-canada-title">
              Canada
            </div>
          </div>
          <div *ngIf="selectedView === 'list'" class="row">
            <div class="row" style="width: 20px;"></div>
            <div class="row listview-states-us">
              <div class="column">
                <li *ngFor="let state of states | slice:0:10">
                  <pb-checkbox key="{{state}}" name="{{state}}" class="checkbox" label="{{state}}" [value]="state"
                    [checked]="isSelected(state)" (change)="stateSelectionChange($event)">
                  </pb-checkbox>
                </li>
              </div>
              <div class="column">
                <li *ngFor="let state of states | slice:10:20">
                  <pb-checkbox key="{{state}}" name="{{state}}" class="checkbox" label="{{state}}" [value]="state"
                    [checked]="isSelected(state)" (change)="stateSelectionChange($event)">
                  </pb-checkbox>
                </li>
              </div>
              <div class="column">
                <li *ngFor="let state of states | slice:20:30">
                  <pb-checkbox key="{{state}}" name="{{state}}" class="checkbox" label="{{state}}" [value]="state"
                    [checked]="isSelected(state)" (change)="stateSelectionChange($event)">
                  </pb-checkbox>
                </li>
              </div>
              <div class="column">
                <li *ngFor="let state of states | slice:30:40">
                  <pb-checkbox key="{{state}}" name="{{state}}" class="checkbox" label="{{state}}" [value]="state"
                    [checked]="isSelected(state)" (change)="stateSelectionChange($event)">
                  </pb-checkbox>
                </li>
              </div>
              <div class="column">
                <li *ngFor="let state of states | slice:40:50">
                  <pb-checkbox key="{{state}}" name="{{state}}" class="checkbox" label="{{state}}" [value]="state"
                    [checked]="isSelected(state)" (change)="stateSelectionChange($event)">
                  </pb-checkbox>
                </li>
              </div>
              <div class="column">
                <li *ngFor="let state of states | slice:50:60">
                  <pb-checkbox key="{{state}}" name="{{state}}" class="checkbox" label="{{state}}" [value]="state"
                    [checked]="isSelected(state)" (change)="stateSelectionChange($event)">
                  </pb-checkbox>
                </li>
              </div>
            </div>
            <div class="row" style="width: 20px;"></div>
            <div class="row listview-states-canada">
              <div class="column">
                <li *ngFor="let state of canadaStates | slice:0:10">
                  <pb-checkbox key="{{state}}" name="{{state}}" class="checkbox" label="{{state}}" [value]="state"
                    [checked]="isSelected(state)" (change)="stateSelectionChange($event)">
                  </pb-checkbox>
                </li>
              </div>
              <div class="column">
                <li *ngFor="let state of canadaStates | slice:10:20">
                  <pb-checkbox key="{{state}}" name="{{state}}" class="checkbox" label="{{state}}" [value]="state"
                    [checked]="isSelected(state)" (change)="stateSelectionChange($event)">
                  </pb-checkbox>
                </li>
              </div>
            </div>
          </div>
        </div>

        <div class="pricing-list">
          <div class="cost-container">
            <div class="cost-item"><span class="title" style="font-size: 20px;">Selections</span></div>
            <div *ngFor="let state of selectedStates; let i = index" class="cost-item"><a
                class="title">{{getStateName(state)}}</a>
              <span class="right title">{{stateCost(state, i)}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-row">
      <button pb-button white type="button" class="button" (click)="close()">Close</button>
      <button pb-button green type="button" class="button" (click)="addStates()" [disabled]="false">Add Selections</button>
    </div>
  </section>
</form>