import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { ServerErrorDto } from '../dtos/server-error.dto';
import { ServerError } from '../../../models/server-error';
import {HttpErrorResponse} from "@angular/common/http";

/**
 * Server error mapper.
 */
@Injectable()
export class ServerErrorMapper implements Mapper<ServerErrorDto, ServerError> {
  /**
   * @inheritdoc
   * @param {ServerErrorDto} dto Server error DTO.
   */
  public mapToModel(dto: ServerErrorDto): ServerError {
    const model = new ServerError();
    model.code = dto.code;
    model.detail = dto.detail;
    model.validationErrors = dto.validation_errors;
    return model;
  }

  public mapFromHttpClientErrorResponse(dto: HttpErrorResponse): ServerError {
    const model = new ServerError();
    model.code = dto.status.toString();
    model.detail = dto.message;
    model.validationErrors = dto.error.validation_errors;
    return model;
  }
}
