import { OnInit, Component, Output, EventEmitter, Input } from "@angular/core";
import { Plans } from "../../core/models/plans";

/**
 * Page component for Merchant's activities.
 */
@Component({
  selector: "pb-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"]
})
export class PricingComponent implements OnInit {
  public selectedPlan: Plans;

  @Input()
  public availablePlans: any;

  @Output() onPlanChanged = new EventEmitter();

  public ngOnInit(): void {
    this.selectedPlan = this.availablePlans[0];
  }

  public selectPlan($ev, $plan): void {
    $ev.preventDefault();
    this.selectedPlan = $plan;

    this.onPlanChanged.emit($plan);
  }

  public freeRate(): string {
    return this.availablePlans.find(p => p.name === "free").price_display
  }

  public premiumRate(): string {
    return this.availablePlans.find(p => p.name === "premium").subscription_rate
  }

  public freeRatePart(part: number): string {
    const parts: string = this.availablePlans.find(p => p.name === "free").price_display.split('.');
    return parts[part];
  }

  public premiumRatePart(part: number): string {
    const parts: string = this.availablePlans[0].subscription_rate.split('.');
    return parts[part];
  }
}
