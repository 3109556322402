
/**
 * Used for sorting in dashboard and activites
 * By ending soon and starting soon.
 */
export enum SortBy {

    None = 1,
    /**
     * All sorts(not sorting in our case).
     */
    Views = 2,

    /**
     * Filtering only ending soon.
     */
    Likes = 3,

    /**
     * Filtering only starting soon.
     */
    Redeemptions = 4
}
