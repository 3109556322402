<div class="root">
  <div class="cost-container">
    <div class="plan-item"><a class="title">Event Summary</a> <span class="right"></span></div>
    <div class="line"></div>
    <div style="margin-top: 10px;">
    <div class="cost-item"><a class="subscription">Monthly Fee</a> <span class="price bold">{{currency}}{{getSubscriptionFee()}}
      </span>
    </div>
    <div *ngIf="hasPromotion()">
      <div class="cost-item"><a class="subscription">Promotional Savings - {{getPromotionPercentage()}}%</a> <span
        class="price bold">-{{currency}}{{getPromotionalSavings()}}
      </span>
      </div>
      <div class="cost-item" *ngIf="showEnd()"><a class="subscription">
        Ends: {{getEventEndDate()}}</a>
      </div>
    </div>
    <div>
      <div class="cost-item"><a class="subscription">Subtotal</a> <span class="price bold">{{currency}}{{getSubTotalAfterPromotion()}}
        </span>
      </div>
    </div>
    <div class="line"></div>
    <div class="plan-item"><a class="subscription">Taxes</a> <span class="price bold">{{currency}}{{getTax()}}</span></div>
    <div class="line"></div>
    <div class="grey">
      <div class="plan-item"><a class="subscription">You Pay ({{currencyName}})</a> <span class="price bold">{{currency}}{{getTotal()}}</span>
      </div>
      <div class="publish">
        <button [disabled]="disabled" pb-button class="button" type="button" (click)="publish()">
          {{ payButtonText }}</button>
      </div>
    </div>
    <div class="text">Billed monthly on the start date of your event.</div>
  </div>
</div>
