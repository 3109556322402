<div class="item">
  <pb-icon name="#iconImpressions" class="icon gray"></pb-icon>
  <div class="global-caption">
    Impressions
    <div class="number">{{impressions}}</div>
  </div>
</div>
<div class="item">
    <pb-icon name="#iconGenerations" class="icon green"></pb-icon>
    <div class="global-caption">
      Generations
      <div class="number">{{likes}}</div>
    </div>
  </div>
<div class="item">
  <pb-icon name="#iconRedemptions" class="icon orange"></pb-icon>
  <div class="global-caption">
    Redemptions
    <div class="number">{{redemptions}}</div>
  </div>
</div>
<div class="lines">
  <div class="line gray" [style.width]="impressionsPercentage + '%'"></div>
  <div class="line green" [style.width]="likesPercentage + '%'"></div>
  <div class="line orange" [style.width]="redemptionsPercentage +'%'"></div>
</div>
