import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { map } from "rxjs/operators";

import { ServerErrorMapper } from "../error-handler/mappers/server-error.mapper";
import { environment } from "../../../../environments/environment";
import { addHttpProtocolIfMissing } from "../../../../utils";
import { TokenStorageService } from "../token-storage.service";
import { MerchantMapper } from "./mappers/merchant.mapper";
import { TargetAudience } from "../../models/target-audience";
import { ServerErrorDto } from "../error-handler/dtos/server-error.dto";
import { ServerError } from "../../models/server-error";
import { LoadedImage } from "../../models/loaded-images";
import { Merchant } from "../../models/merchant";
import { ImagesDto } from "./dtos/images.dto";
import { Image } from "../../models/image";
import { SubscriptionDto } from "./dtos/subscriptioin-dto";
import { Subscription } from "../../models/subscription";
import {checkImageMimeType} from "../../../../utils/others";

const MERCHANT_URL_PART = "merchants";
const AUDIENCE_URL = "audience";
const NEW_AUDIENCE_URL = "new_audience";
const ADD_DIVISION = "add-division";

/**
 * Merchant service.
 */
@Injectable()
export class MerchantService {
  /**
   * .ctor
   * @param http Http
   */
  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private merchantMapper: MerchantMapper,
    private tokenService: TokenStorageService,
    private serverErrorMapper: ServerErrorMapper
  ) {}

  /**
   * Gets details of the merchant.
   * @param merchantId Merchant identifier.
   */
  public getMerchant(merchantId: number): Promise<Merchant> {
    return this.getMerchant$(merchantId).toPromise();
  }

  public getMerchant$(merchantId: number): Observable<Merchant> {
    return this.http
      .get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json())
      .map(r => this.merchantMapper.mapToModel(r));
  }

  /**
   * Search maerchant by name.
   * @param merchantName Name of merchant.
   */
  public merchantExists$(merchantName: string): Observable<boolean> {
    const url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/?name=${merchantName}`;

    return this.httpClient
      .get(url)
      .pipe(map(({ count }: { count: number }) => count !== 0));
  }

  /**
   * Register a merchant.
   * @param {Merchant} merchant Merchant domain model.
   */
  public registerMerchant(
    merchant: Merchant,
    isDivision: boolean,
    holdingId: number
  ): Promise<{ id: number } | ServerError> {
    const body = {
      name: merchant.name,
      name_dba: merchant.dbaName,
      categories: merchant.categoryIds,
      address_info: {
        street: merchant.address.street,
        city: merchant.address.city,
        state: merchant.address.state,
        country: merchant.address.country,
        zip_code: merchant.address.zip,
      },
      contact: merchant.contact,
      country_calling_code: merchant.country_calling_code,
      contact_title: merchant.contactName,
      email: merchant.email,
      phone: merchant.phone,
      secondary_contact: merchant.secondaryContact,
      secondary_contact_title: merchant.secondaryContactName,
      secondary_email: merchant.secondaryEmail,
      secondary_phone: merchant.secondaryPhone,
      is_holding: merchant.isHolding
    };

    if (isDivision) {
      return this.http
        .post(
          `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${holdingId}/${ADD_DIVISION}/`,
          body,
          { headers: this.tokenService.baseHeaders }
        )
        .map(r => r.json() as { id: number })
        .catch(error => {
          const errorDto = error.json() as ServerErrorDto;
          return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
        })
        .toPromise();
    }

    return this.http
      .post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/`, body, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as { id: number })
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  public createDivision(
    holdingId: number
  ): Observable<{ id: number; email: string }> {
    const url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${holdingId}/add-division/`;

    return this.httpClient
      .post(url, {})
      .pipe(map((division: { id: number; email: string }) => division));
  }

  /**
   * Update merchant's profile.
   * @param {Merchant} merchant Merchant domain model.
   */
  public updateProfile(merchant: Merchant): Promise<Merchant | ServerError> {
    const body = {
      description: merchant.description,
      keywords: merchant.keywords
        .split(",")
        .map(r => r.trim())
        .filter(r => !!r),
      operation_hours: this.merchantMapper.operationHoursMapper(
        merchant.operationHours
      ),
      free_form: merchant.freeForm,
      categories: merchant.categoryIds,
      social_urls: {
        facebook:
          addHttpProtocolIfMissing(merchant.socialUrls["facebook"]) || "",
        twitter: addHttpProtocolIfMissing(merchant.socialUrls["twitter"]) || "",
        instagram:
          addHttpProtocolIfMissing(merchant.socialUrls["instagram"]) || "",
        youtube: addHttpProtocolIfMissing(merchant.socialUrls["youtube"]) || "",
        linkedin:
          addHttpProtocolIfMissing(merchant.socialUrls["linkedin"]) || "",
        snapchat:
          addHttpProtocolIfMissing(merchant.socialUrls["snapchat"]) || "",
        flickr: addHttpProtocolIfMissing(merchant.socialUrls["flickr"]) || "",
        tumblr: addHttpProtocolIfMissing(merchant.socialUrls["tumblr"]) || "",
        meetup: addHttpProtocolIfMissing(merchant.socialUrls["meetup"]) || "",
        evenbright:
          addHttpProtocolIfMissing(merchant.socialUrls["evenbright"]) || "",
        tiktok: addHttpProtocolIfMissing(merchant.socialUrls["tiktok"]) || "",
        pininterest: addHttpProtocolIfMissing(
          merchant.socialUrls["pininterest"] || ""
        )
      },
      address_info: {
        city: merchant.address.city,
        street: merchant.address.street,
        state: merchant.address.state,
        country: merchant.address.country,
        zip_code: merchant.address.zip,
      },
      covid_options: {
        enabled: merchant.covidOptions.enabled,
        type: merchant.covidOptions.type || "U",
        url: merchant.covidOptions.url,
        instructions: merchant.covidOptions.instructions,
        extra_instructions: merchant.covidOptions.extra_instructions
      },
      url: addHttpProtocolIfMissing(merchant.url),
      reviews_url: addHttpProtocolIfMissing(merchant.reviews_url),
      name: merchant.name,
      name_dba: merchant.dbaName,
      contact: merchant.contact,
      contact_title: merchant.contactName,
      email: merchant.email,
      phone: merchant.phone,
      country_calling_code: merchant.country_calling_code,
      secondary_contact: merchant.secondaryContact
        ? merchant.secondaryContact
        : "",
      secondary_contact_title: merchant.secondaryContactName
        ? merchant.secondaryContactName
        : "",
      secondary_email: merchant.secondaryEmail ? merchant.secondaryEmail : "",
      secondary_phone: merchant.secondaryPhone ? merchant.secondaryPhone : ""
    };

    return this.http
      .patch(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchant.id}/`,
        body,
        { headers: this.tokenService.baseHeaders }
      )
      .map(r => r.json())
      .map(r => this.merchantMapper.mapToModel(r))
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  /**
   * Uploads logo images to the server and returns their urls.
   * @param {number} merchantId Merchant identifier.
   * @param {Blob} logo Merchant logo Blob.
   * @param {Blob} logoBackground Merchant background logo Blob.
   * @return {{logo: string, logo_background: string}} Urls object.
   */
  public uploadLogos(
    merchantId: number,
    logo: Blob,
    logoBackground: Blob
  ): Promise<{ logo: string; logo_background: string }> {
    if (!logo && !logoBackground) {
      return Promise.resolve(null);
    }
    const formData = new FormData();
    if (logo) {
      const imgExt = checkImageMimeType(logo);
      if (imgExt === "unknown") {
        return Promise.reject("Unknown image type");
      }
      formData.append("logo", logo, `logo.${imgExt}`);
    }
    if (logoBackground) {
      formData.append("logo_background", logoBackground);
    }

    return this.http
      .post(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/upload/`,
        formData,
        { headers: this.tokenService.baseHeaders }
      )
      .map(r => r.json())
      .toPromise();
  }

  /**
   * Uploading image to Merchant's gallery.
   * @param merchantId Id of merchant.
   * @param file file which being uploaded.
   */
  public uploadImage(merchantId: number, images: LoadedImage[]): Promise<any> {
    const url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/images/`;
    const formData = new FormData();

    for (const image of images) {
      const imgExt = checkImageMimeType(image.file);
      if(imgExt === 'unknown') {
        return Promise.reject('Invalid image type');
      }
      formData.append("files", image.file, image.file.name);
    }

    return this.httpClient.post<any>(url, formData).toPromise();
  }

  /**
     * Deleting logo from merchant's profile.
     * @param merchantId Id of merchant for coupon.
     */
    public deleteLogo(merchantId: number): Promise<void> {
      return this.http.delete(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/clear_logo/`,
          { headers: this.tokenService.baseHeaders })
          .map(r => null)
          .toPromise();
  }

  /**
   * Retrieving merchant's images.
   * @param merchantId Id of merchant.
   */
  public getImages(merchantId: number): Observable<Image[]> {
    const url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/images/`;

    return this.httpClient
      .get<ImagesDto>(url)
      .pipe(map((response: ImagesDto) => response.results));
  }

  /**
   * Deleting image from merchant's gallery.
   * @param imageId Id of image which will be deleted.
   * @param merchantId Id of merchant for gallery where we will delete image.
   */
  public async deleteImage(imageId: number, merchantId: number): Promise<void> {
    await this.http
      .delete(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/images/${imageId}/`,
        { headers: this.tokenService.baseHeaders }
      )
      .toPromise();
  }

  /**
   * Get search by merchant name result.
   * @param {number} merchantId Merchant id.
   * @param {TargetAudience} targetAudience Target audience.
   */
  public async getTargetAudienceCount(
    merchantId: number,
    targetAudience: TargetAudience
  ): Promise<number> {
    const genderBoth = "B";

    const ageStartUrl = targetAudience.ageStart
      ? `?age_start=${targetAudience.ageStart}`
      : "?age_start=1";
    const ageEndUrl = targetAudience.ageEnd
      ? `&age_end=${targetAudience.ageEnd}`
      : "";
    const genderUrl =
      targetAudience.gender && targetAudience.gender !== genderBoth
        ? `&gender=${targetAudience.gender}`
        : "";
    const interestsUrl =
      targetAudience.interests.length > 0
        ? `&interests=${targetAudience.interests.join()}`
        : "";

    // tslint:disable-next-line:max-line-length
    const url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${AUDIENCE_URL}/${ageStartUrl}${ageEndUrl}${genderUrl}${interestsUrl}`;

    return this.http
      .get(url, { headers: this.tokenService.baseHeaders })
      .map(r => r.json())
      .map(r => r.count)
      .toPromise();
  }

  /**
   * Get search by merchant name result.
   * @param {number} merchantId Merchant id.
   * @param {TargetAudience} targetAudience Target audience.
   */
   public async getNewTargetAudienceCount(
    merchantId: number,
    targetAudience: TargetAudience
  ): Promise<number> {
    const genderBoth = "B";

    const ageStartUrl = targetAudience.ageStart
      ? `?age_start=${targetAudience.ageStart}`
      : "?age_start=1";
    const ageEndUrl = targetAudience.ageEnd
      ? `&age_end=${targetAudience.ageEnd}`
      : "";
    const genderUrl =
      targetAudience.gender && targetAudience.gender !== genderBoth
        ? `&gender=${targetAudience.gender}`
        : "";
    const interestsUrl =
      targetAudience.interests.length > 0
        ? `&interests=${targetAudience.interests.join()}`
        : "";

    // tslint:disable-next-line:max-line-length
    const url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${NEW_AUDIENCE_URL}/${ageStartUrl}${ageEndUrl}${genderUrl}${interestsUrl}`;

    return this.http
      .get(url, { headers: this.tokenService.baseHeaders })
      .map(r => r.json())
      .map(r => r.count)
      .toPromise();
  }

  /**
   * Changing state of merchant.
   * @param merchantId Id of merchant.
   * @param active If active is false, we suspend merchant, otherwise activate.
   */
  public changeStateOfMerchant(
    merchantId: number,
    active: boolean
  ): Promise<void> {
    return this.changeMerchantState$(merchantId, active).toPromise();
  }

  public changeMerchantState$(
    merchantId: number,
    isActive: boolean
  ): Observable<void> {
    let url;

    if (isActive) {
      url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/restore/`;
    } else {
      url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/suspend/`;
    }

    return this.http
      .post(url, null, { headers: this.tokenService.baseHeaders })
      .map(r => null);
  }

  /**
   * Retrieving merchant's images.
   * @param merchantId Id of merchant.
   */
  public getSubscriptionsForCoupon(merchantId: number, coupinId: number): Promise<Subscription[]> {
    const url = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/coupon/${coupinId}/subscriptions/`;

    return this.http
      .get(url, { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as Subscription[])
      .toPromise();
  }
}
