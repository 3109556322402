import {Subscription} from './subscription';
import {EventStyleVm} from "../../events/event-style/event-style";
// import {TargetMarketingVm} from "../../events/event-marketing/event-marketing";
// import {RedemptionOptionsVm} from "../../events/event-options/event-options";

/**
 * Coupon model.
 */
export class EventCoupon {

  public promoCode: number;
  public campaignPlan: number;
  public subscription: Subscription;
  /**
   * First step of coupon.
   */
  public eventStyle: EventStyleVm;

  /**
   * Second step of coupon.
   */
  // public targetMarketing: TargetMarketingVm;

  /**
   * Third step of coupon.
   */
  // public redemptionOptions: RedemptionOptionsVm;
}
