import { PopupService } from '../../popup/services/popup.service';

import { Injectable } from '@angular/core';
import { Request, XHRBackend, RequestOptions, Response, Http, RequestOptionsArgs, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ServerErrorMapper } from './error-handler/mappers/server-error.mapper';
import { ServerError } from '../models/server-error';
import { ServerErrorDto } from './error-handler/dtos/server-error.dto';


/**
 * Check if user have control for requested resource.
 */
@Injectable()
export class AuthorizationHttpService extends Http {

  private static excludeUrls = [
    '/login',
    '/explorelongisland',
    '/discoverlongisland',
    '/smokyexplorer',
    '/londonlocalguide',
    '/minicards',
    '/signup',
    '/registration',
    '/email-confirm/',
    '/email-confirm',
    '/forgot-password',
    '/welcome-page',
    '/welcome-page/',
    '/brand/'
  ];

  private static brandLandingPattern = /(\w+)-landing/;

  private static DENIED_FOR_SUSPENDED_CODE = 'denied_for_suspended';

  private static restrictedStatuses = [
    // tslint:disable-next-line:no-magic-numbers
    404,
    // tslint:disable-next-line:no-magic-numbers
    403
  ];

  constructor(backend: XHRBackend, defaultOptions: RequestOptions,
    private serverErrorMapper: ServerErrorMapper,
    private popupService: PopupService) {
    super(backend, defaultOptions);
  }

  /**
   * @inheritdoc
   */
  public request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
    return super.request(url, options).catch((error: Response) => {

      if (!this.isInExcludedUrls(window.location.pathname) && this.isRestrictedStatus(error.status) && !this.isSuspendCode(error)) {
        window.location.href = '/login';
      } else if (this.isSuspendCode(error)) {
        const serverError = this.mapServerError(error);
        this.popupService.info(serverError.detail);
      }

      return Observable.throw(error);
    });
  }

  /**
   * Check if url excluded.
   */
  private isInExcludedUrls(url: string) {
    return AuthorizationHttpService.excludeUrls.includes(url) || url.match(AuthorizationHttpService.brandLandingPattern);
  }

  /**
   * Check if status restricted.
   */
  private isRestrictedStatus(status: number) {
    return AuthorizationHttpService.restrictedStatuses.includes(status);
  }

  private mapServerError(error: Response): ServerError {
    const serverErrorDto = error.json() as ServerErrorDto;
    const serverError = this.serverErrorMapper.mapToModel(serverErrorDto);
    return serverError;
  }

  private isSuspendCode(error: Response): boolean {
    const serverError = this.mapServerError(error);
    if (serverError && serverError.code === AuthorizationHttpService.DENIED_FOR_SUSPENDED_CODE) {
      return true;
    }
  }
}
