import { ValidationErrorDto } from '../services/error-handler/dtos/server-error.dto';

export const VALIDATION_ERROR = 'validation_error';

/**
 * Server error domain model.
 */
export class ServerError {
  /**
   * Error code.
   */
  public code: string;

  /**
   * Error description.
   */
  public detail: string;

  /**
   * A list of validation errors.
   */
  public validationErrors: ValidationError[];
}

/**
 * Validation error domain model.
 */
export class ValidationError implements ValidationErrorDto {
  /**
   * A field name that the error is related to.
   */
  public field: string;

  /**
   * A list of error messages.
   */
  public errors: string[];
}
