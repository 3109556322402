/**
 * Paged promotions dto.
 */
import { PagedDto } from '../../pagination/dtos/paged.dto';

export class PromotionsDto extends PagedDto<PromotionDto> {

}

/**
 * Dto for merchant's promotions.
 */
export class PromotionDto {

    public id: number;

    public name: string;

    public start: string;

    public end: string;

    public places: string[];

    public isActive: boolean;

    public isCompleted: boolean;

    public merchantId: number;

    public operation_hours: any;

    public targetAuidience: {
        age_start: number;
        age_end: number;
        radius: number;
        gender: string;
        /**
         * Array of interest ids
         */
        interest: string[];
    };

    // public logo: string;

    /**
     * Keywords for merchant's promotion.
     */
    public keywords: string[] = [];

    public image: string;
    public video: number;

    public terms: string;

    public campaign_plan: number;
    public premium_states: string[];
    public premium_email_states: string[];
    public deal_description: string;
    public contactInformationPhone: string;
    public contactInformationText: string;
    public contactInformationEmail: string;

    public promo_code: number;
}
