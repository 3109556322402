import { ChangeDetectorRef, Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';


/**
 * Directive to validate minimal and maximal value for numbers.
 */
@Directive({
    selector: '[pbNumberRange]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NumberRangeValidationDirective), multi: true }]
})
export class NumberRangeValidationDirective implements Validator {

    constructor(private cdr: ChangeDetectorRef) {
    }

    /**
     * Maximum value allowed.
     */
    @Input('inputMaxNumber')
    public max: number = null;

    /**
     * Minimum value allowed.
     */
    @Input('inputMinNumber')
    public min: number = null;

    /**
     * @inheritdoc
     */
    public validate(c: AbstractControl): ValidationErrors | null {

        if (!this.min && !this.max) {
            return { required: true };
        }

        if (c.value === null || c.value === undefined) {
            return null;
        }

        const value = +c.value;
        if (Number.isNaN(value) || !this.isValueValid(value)) {
            return { required: true };
        }

        if (this.min && value < this.min) {
            return { required: true };
        }
        if (this.max && value > this.max) {
            return { required: true };
        }

        return null;
    }

    private isValueValid(value: any) {
        return value !== undefined && value != null;
    }
}
