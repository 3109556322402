<form #form="ngForm" (ngSubmit)="validateForm(form)" [class.form-submitted]="form.submitted">
  <h2 class="section-title">
    <div class="tooltip-title">
      Target Options
    </div>
  </h2>
  <section class="container" *ngIf="showCategorySelector">
    <div class="tooltip-title">
      <div class="global-caption">Select category</div>
      <div class="global-required">*required</div>
      <pb-info-tooltip
        description='By selecting your Industry type you will make your business more discoverable when mobile customers are searching for a specific type of business."
                hint="Your business can have multiple categories.'>
      </pb-info-tooltip>
    </div>
    <pb-select required class="cat-select" [placeholder]="'Select a category'"
               (change)="categoryChanged($event)">
      <option [selected]="selectedCategoryIds[0] === category.id" [value]="category.id"
              *ngFor="let category of categories">{{category.text}}</option>
    </pb-select>
  </section>

  <section class="container">
    <div class="tooltip-title">
      <div class="global-caption">Add Searchable Keywords to help make this campaign more discoverable</div>
      <div class="global-required">*required</div>
      <pb-info-tooltip
        description='Words that consumers would use to describe your business : "My City" Attractions, "My City" Tours, Historic  Walking Tour, Bike Rentals, Shopping, Live Entertainment.'>
      </pb-info-tooltip>
    </div>
    <pb-keywords [required]="!targetMarketing.keywords" [isCoupon]="true" #keywords="ngModel" name="keywords" ngModel
      [keywords$]="keywords$" (keywordsChange)="onKeywordsChange($event)">
      <pb-error-message class="keywords-error-message" [errors]="keywords?.errors"></pb-error-message>
    </pb-keywords>
  </section>

  <section class="container">
    <div class="tooltip-title">
      <div class="global-caption">How do you want people to contact you?</div>
      <div class="global-optional">(optional)</div>
      <pb-info-tooltip description="Additional methods for consumers to communicate and contact your business about this campaign (both instructions and header)">
      </pb-info-tooltip>
    </div>
    <div class="global-subtitle">Select how you want people to contact you about this offer. Select all the apply.</div>
    <div>
      <div style="display: flex; gap: 10px;">
        <label style="margin: auto; font-weight: bold;">Phone:</label>
        <div style="width: 25%">
          <pb-select [placeholder]="'Please select'"
                     name="callCountry" (change)="phoneCodeChanged($event.target.value)">
            <option [selected]="callCountry === country.dial_code" [value]="country.dial_code" *ngFor="let country of placeCountries;">
              {{country.text}}
            </option>
          </pb-select>
        </div>
        <div style="width: 15%">
          <pb-input name="country_calling_code_phone"
                    [readonly]="true"
                    [(ngModel)]="targetMarketing.contactInformationPhoneCode"
                    placeholder="Code"
                    class="element size-medium input input-as-selectize"
                    minlength="2"></pb-input>
        </div>
        <div style="width: 37%">
          <pb-input name="contactInformationPhone" #contactInformationPhone="ngModel"
                    placeholder="Enter a Phone" [maxlength]="100"
                    pbTooltip customElementQuery=".body"
                    [(ngModel)]="targetMarketing.contactInformationPhone">
          </pb-input>
          <pb-error-message [errors]="contactInformationPhone.errors"></pb-error-message>
        </div>
      </div>
      <div style="display: flex; gap: 10px;">
        <label style="margin: auto; font-weight: bold;">Text:</label>
        <div style="width: 25%">
          <pb-select [placeholder]="'Please select'" name="textCountry" (change)="textCodeChanged($event.target.value)">
            <option [selected]="textCountry === country.dial_code" [value]="country.dial_code" *ngFor="let country of placeCountries;">
              {{country.text}}
            </option>
          </pb-select>
        </div>
        <div style="width: 15%">
          <pb-input name="country_calling_code_text"
                    [readonly]="true"
                    [(ngModel)]="targetMarketing.contactInformationTextCode"
                    placeholder="Code"
                    class="element size-medium input input-as-selectize"
                    minlength="2"></pb-input>
        </div>
        <div style="width: 37%">
          <pb-input
                    name="contactInformationText" #contactInformationText="ngModel" placeholder="Enter a Phone"
                    [maxlength]="100" [(ngModel)]="targetMarketing.contactInformationText">
          </pb-input>
          <pb-error-message [errors]="contactInformationText.errors"></pb-error-message>
        </div>
      </div>
      <div style="display: flex; gap: 10px;">
        <label style="margin: auto; font-weight: bold;">Email:</label>
        <div style="width: 80%">
          <pb-input name="contactInformationEmail" #contactInformationEmail="ngModel"
            placeholder="Enter an E-mail" [maxlength]="100"
            [(ngModel)]="targetMarketing.contactInformationEmail">
          </pb-input>
          <pb-error-message [errors]="contactInformationEmail.errors"></pb-error-message>
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <div>
      <div class="tooltip-title">
        <div class="global-caption">Edit the terms and conditions of your offer.</div>
        <div class="global-required">*required</div>
        <pb-info-tooltip
          description="You can utilize the default language provided, or edit / add your own Terms & Conditions.">
        </pb-info-tooltip>
        <div class="edit-coupon">
          <a (click)="targetMarketing.resetTermsAndConditionsTextToInitial()" class="reset">Reset to Default</a>
        </div>
      </div>
      <div class="global-subtitle">Edit the provided terms and conditions to your preference.</div>
      <pb-textarea [rows]="'7'" [(ngModel)]="targetMarketing.termsAndConditionsText" label=""
        name="termsAndConditionsText" #termsAndConditionsText="ngModel"
        [placeholder]="targetMarketing.termsAndConditionsPlaceholder">
        <pb-error-message [errors]="termsAndConditionsText.errors"></pb-error-message>
      </pb-textarea>
    </div>
  </section>
</form>
