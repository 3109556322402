<div class="wrapper">
  <div class="divisions">
    <div class="division" *ngFor="let division of webapps" [class.active]="division.id === currentWebAppId"
         (click)="onSelectMerchant(division.id, false)">
      <div class="name-container"style="display: flex">
        <img [src]="division.favicon" class="icon" height="30" width="30">
        <h3 class="name">{{ division.name }}</h3>
      </div>
    </div>
  </div>
</div>
