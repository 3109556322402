/**
 * Object which contain Plans information.
 */

export class Plans {
  public id: number;
  public title: string;
  public description: string;
  public name: string;
  public plan_type: string;
  public price_display: number;
  public subscription_rate: number;
  public premium_state_fee: number;
  public premium_email_fee: number;
  public tax_rate: number;
  public custom_link_fee: any;
}
