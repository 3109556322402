<div style="display: flex;">
  <label class="root" [class.disabled]="isDisabled != null && isDisabled">
    <input type="file" [accept]="inputAcceptType" #input multiple class="input" (click)="clearValue(input)"
      (change)="handleUpload($event.target.files)" [attr.disabled]="isDisabled ? isDisabled : null">
    <div class="text" *ngIf="isLink" [class.disabled]="isDisabled != null && isDisabled">
      {{ buttonText }}
    </div>
    <button class="button" *ngIf="!isLink" [class.disabled]="isDisabled != null && isDisabled"
      [style.width]="buttonWidth">
      {{ buttonText }}
    </button>
  </label>
  <button *ngIf="canRemove" class="remove-button" (click)="handleRemove($event)">
    <pb-icon name="#iconDelete" class="icon"></pb-icon>
  </button>
  <div *ngIf="notes" class="notes">
    {{notes}}
  </div>
</div>