<div class="caption">Payment Summary</div>
<div class="subcaption">Month to Date Charges:</div>
<div class="text big" *ngIf="invoice">{{ invoice.total | currency: 'USD' : true :'1.2-2' }}</div>
<div class="row">
  <div>
    <div class="subcaption">Billing Period</div>
    <div class="text" *ngIf="invoice">{{ invoice.start | date: 'MM/dd/yy' }} - {{ invoice.end | date: 'MM/dd/yy' }}</div>
  </div>
  <div *ngIf="invoice">
    <div class="subcaption">Billing Date:</div>
    <div class="text">{{ invoice.chargeDate | date }}</div>
  </div>
  <div *ngIf="invoice">
    <div class="subcaption">Card Charged:</div>
    <div class="text">{{ cardCharged }}</div>
  </div>
</div>
<div class="inner" *ngIf="invoice">
  <div class="row">
    <div>Total</div>
    <div>{{ invoice.subtotal | currency: 'USD' : true :'1.2-2' }}</div>
  </div>
  <div class="row">
    <div>Discount</div>
    <div>{{ invoice.discount | currency: 'USD' : true :'1.2-2' }}</div>
  </div>
  <div class="row">
    <div>Tax</div>
    <div>{{ invoice.tax | currency: 'USD' : true :'1.2-2' }}</div>
  </div>
  <div class="row">
    <div><strong>Final Total (tax included)</strong></div>
    <div><strong>{{ total | currency: 'USD' : true :'1.2-2' }}</strong></div>
  </div>
</div>
<div class="note">Your credit card will automatically be charged the total amount above on the date indicated.</div>
