import { ValidationError, Schedule, Subscription } from "../../core";


/**
 * The Component View Model.
 */
export class TargetMarketingVm {
  public keywords = "";
  public isDefineHours = "all";
  public schedule = new Schedule();
  public contactInformationPhone: string = "";
  public contactInformationPhoneCode: string = "";
  public contactInformationText: string = "";
  public contactInformationTextCode: string = "";
  public contactInformationEmail: string = "";
  public termsAndConditionsText: string = `• Not valid on prior purchases.
• No cash value.
• Void where prohibited by law.
• Valid at participating locations.
• This offer cannot be combined with other offers / coupons / discounts / promotions.
• Some restrictions may apply (see store for details).`;

private readonly initialTermsAndConditionsText = `• Not valid on prior purchases.
• No cash value.
• Void where prohibited by law.
• Valid at participating locations.
• This offer cannot be combined with other offers / coupons / discounts / promotions.
• Some restrictions may apply (see store for details).`;

  /**
   * Placeholder input where is enter terms and conditions.
   */
  public termsAndConditionsPlaceholder: string = `• Not valid on prior purchases.
  • No cash value.
  • Void where prohibited by law.
  • Valid at participating locations.
  • This offer cannot be combined with other offers / coupons / discounts / promotions.
  • Some restrictions may apply (see store for details).`;
  /**
   * Array of component form errors.
   */
  public errors: ValidationError[] = [];

  public interest: string[] = [];

  /**
   * Fields for validation.
   */
  public validationFields = ["keywords", "contactInformationEmail", "termsAndConditionsText"];


  /**
   * Reset terms and conditions text to initial value.
   */
  public resetTermsAndConditionsTextToInitial(): void {
    this.termsAndConditionsText = this.initialTermsAndConditionsText;
  }
}
