/**
 * Http status codes.
 */
export enum HttpStatusCode {
  NONE = 0,
  CREATED = 201,
  NO_CONTENT = 204,

  BAD_REQUEST = 400,

  UNAUTHORIZED = 401,
  INTERNAL_ERROR = 500
}
