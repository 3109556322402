<header pb-page-header>
  <div left>Automated Messages</div>
  <div right>
  </div>
</header>

<div class="mt-30"></div>

<div right class="buttons-container">
  <div class="buttons">
    <button pb-button class="button" (click)='newAutoMessage()' small>{{ 'Add Automated Push Alert' }}</button>
  </div>
</div>
 
<table pb-table style="table-layout: fixed;">
  <thead>
    <tr>
      <th width="300px">
        Title
      </th>
      <th width="300px">
        Message
      </th>
      <th width="200px">
        Time
      </th>
      <th></th>
    </tr>
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of messages">
      <td>
        <a href="" [routerLink]='["/auto-messages", item.id]'>{{item.title}}</a>
      </td>
      <td>
        {{item.message}}
      </td>
      <td>{{getTimerName(item.auto_time_delta)}}</td>
      <td><a href="" [routerLink]='["/auto-messages", item.id]'>Edit</a></td>
    </tr>
  </tbody>
</table>