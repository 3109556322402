<header pb-page-header>
  <div left>Reports
  </div>
  <div right *ngIf="false">
    <button pb-button small class="button" disabled>+(coming soon)
      <!-- Export All -->
    </button>
  </div>
</header>

<div>
  <form class="new-report-form" #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted">
    <label>
      Report Title
    </label>
    <pb-input name="report-title" placeholder="Report Title" class="item"
    required [(ngModel)]="newReportTitle" pbTooltip customElementQuery=".body"></pb-input>
    <label>
    Start Date
    </label>
    <pb-datepicker aria-placeholder="Start Date" pbRequiredDateValidation name="start-date" [(ngModel)]="newReportStartDate" (ngModelChange)="startDateChanged($event)" [maxInputDate]="maxDate">
    </pb-datepicker>
    <label>
      End Date
    </label>
    <pb-datepicker aria-placeholder="End Date" pbRequiredDateValidation name="end-date" [(ngModel)]="newReportEndDate" [maxInputDate]="maxDate" [minInputDate]="newReportStartDate">
    </pb-datepicker>
    <button pb-button small class="button" >New Report
    </button>
  </form>
</div>
<table pb-table class="reports-table">
  <thead>
    <tr>
      <th width="24px"></th>
      <th width="280px">
        <div class="row">
          Report Title
          <pb-icon name="#iconArrow" class="iconArrow"></pb-icon>
        </div>
      </th>
      <th>
        Date Generated
      </th>
      <th></th>
    </tr>
    <tr>
      <th></th>
      <th>
        <pb-search [searchString]="searchString" (searchStringChange)="searchStringChanged($event)"></pb-search>
      </th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of pagedItems;">
      <td>
        <!--<pb-checkbox [name]="item.id" [key]="item.id" mr="0"></pb-checkbox>-->
      </td>
      <td>
        <a [routerLink]="['/report-dashboard/', item.id]">{{ item.name }}</a>
      </td>
      <td>{{ item.reportDate | date: 'MM/dd/yyyy' }}</td>
      <td></td>
    </tr>
  </tbody>
</table>

<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
