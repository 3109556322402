import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../../value-accessor-base';

/**
 * Select component.
 */
@Component({
  selector: 'pb-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: SelectComponent, multi: true }]
})
export class SelectComponent extends ValueAccessorBase<string> {

  constructor() {
    super();
  }

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public hasBigArrow = false;

  @Input()
  public icon: string;

  @Input()
  public disabled = false;

  @Input()
  public defaultValue: string;

  @Input()
  public removeArrow = false;

}
