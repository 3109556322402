
import { Mapper } from '../../abstract/mapper';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PromotionDto } from '../dtos/promotion.dto';
import { MerchantsPromotion } from '../models/merchantsPromotion';

/**
 * Mapper for Merchant's promotions.
 */
@Injectable()
export class PromotionMapper implements Mapper<PromotionDto, MerchantsPromotion> {

    /**
     * @inheritdoc
     */
    public mapToModel(dto: PromotionDto): MerchantsPromotion {

        const model = {
            id: dto.id,
            name: dto.name,
            startDate : dto.start,
            endDate : dto.end,
            isActive : true,
            merchantId: dto.merchantId,
            keywords: dto.keywords,
            operationHours: dto.operation_hours,
            // logo: dto.logo,
            places: dto.places,
            viewUrl: `/view-promotion/${dto.id}`,
            editUrl: `/edit-campaign/${dto.id}`,
            logoBlob: null,
            targetAuidience: {
                ageStart: null,
                ageEnd: null,
                gender: null,
                interest: null,
                radius: null
            },
            isCompleted: false,
            startDateRaw: dto.start,
            endDateRaw: dto.end,
            image: dto.image
        } as MerchantsPromotion;

        if (dto.targetAuidience) {
            model.targetAuidience.ageStart = dto.targetAuidience.age_start;
            model.targetAuidience.ageEnd = dto.targetAuidience.age_end;
            model.targetAuidience.gender = dto.targetAuidience.gender;
            model.targetAuidience.interest = dto.targetAuidience.interest;
            model.targetAuidience.radius = dto.targetAuidience.radius;
        }

        if (dto.start) {
            model.startDate = moment(dto.start).format('MMMM D');
            model.isActive = moment().isAfter(moment(dto.start));
        }
        if (dto.end) {
            model.endDate = moment(dto.end).format('MMMM D');
            model.isCompleted = moment().isAfter(moment(dto.end));
            if (model.isCompleted) {
                model.isActive = false;
            }
        }

        model.startDateRaw = dto.start;
        model.endDateRaw = dto.end;

        return model;
    }

    /**
     * Creating promotion object from dto.
     * @param promotion Promotion object.
     */
    public mapToDto(promotion: MerchantsPromotion): PromotionDto {

        const dto = {
            id: promotion.id,
            name: promotion.name,
            start : promotion.startDate,
            end : promotion.endDate,
            isActive : true,
            merchantId: promotion.merchantId,
            keywords: promotion.keywords,
            operation_hours: promotion.operationHours,
            // logo: promotion.logo,
            places: promotion.places,
            isCompleted: false,
            targetAuidience: {
                age_start: null,
                age_end: null,
                gender: null,
                interest: null,
                radius: null
            }
        } as PromotionDto;

        if (promotion.targetAuidience) {
            dto.targetAuidience.age_start = promotion.targetAuidience.ageStart;
            dto.targetAuidience.age_end = promotion.targetAuidience.ageEnd;
            dto.targetAuidience.gender = promotion.targetAuidience.gender;
            dto.targetAuidience.interest = promotion.targetAuidience.interest;
            dto.targetAuidience.radius = promotion.targetAuidience.radius;
        }

        return dto;
    }
}
