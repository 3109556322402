import {AuthService, ErrorHandlerService, Pagination, PaginationService, Report, ReportsService, User} from '../core';
import {Subscription} from 'rxjs/Rx';
import {NgForm} from '@angular/forms/src/forms';
import * as moment from 'moment';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopupResult, PopupService} from '../popup';
import {Observable} from "rxjs/Observable";
import {ActivatedRoute} from "@angular/router";
import {SignupReport} from "../core/models/report";


@Component({
  selector: 'pb-scan-reports-page',
  templateUrl: './scan-reports-page.component.html',
  styleUrls: ['./scan-reports-page.component.scss']
})
export class ScanReportsPageComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;

  private maxDateField = new Date();

  private user: User;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportsService: ReportsService,
    private authService: AuthService,
    private paginationService: PaginationService,
    private popupService: PopupService,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  public get maxDate(): Date {
    return this.maxDateField;
  }

  public searchString: string;

  /**
   * Title of report.
   */
  public newReportTitle: string;

  /**
   * Start date for new report.
   */
  public newReportStartDate: Date;

  /**
   * End date for new report.
   */
  public newReportEndDate: Date;

  private tableData = [];

  public pagedItems = [];


  /**
   * Pagination object from pagination service.
   */
  public pagination: Pagination = new Pagination();

  private reports: SignupReport[];
  private routeSub: Subscription;
  public currentWebApp: string = null;
  public interval: any;

  public async ngOnInit(): Promise<void> {

    this.routeSub = Observable.combineLatest(this.activatedRoute.paramMap, this.authService.onUserChanged).subscribe(async tuple => {
      const [params, user] = tuple;
      this.user = user;
      this.currentWebApp = params.get('id');

      await this.reloadReports();
      this.setPageClicked(1);

      if (this.routeSub) {
        this.routeSub.unsubscribe();
      }
    });

    this.interval = setInterval(() => {
      this.refreshStatus();
    }, 10000);

    window.scrollTo(0, 0);
  }

  public async refreshStatus() {
    try {
      this.pagedItems.map(async (item) => {
        if (item.status === 'pending') {
          const updatedReport = await this.reportsService.getSignupReport(this.user.merchantId, this.currentWebApp, item.id);
          if (updatedReport) {
            item.status = updatedReport.status;
          }
        }
      });
    } catch (e) {

    }
  }

  public async reloadReports() {
    try {
      this.reports = await this.reportsService.getSignupReports(this.user.merchantId, this.currentWebApp);
    } catch (error) {
      console.log(error);
    }

    this.tableData = this.reports.map(report => {
      return {
        id: report.id,
        name: report.name,
        start: report.start,
        end: report.end,
        status: report.status
      };
    });
  }

  public ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public startDateChanged(changes: Date) {
    const startDate = moment(changes);
    if (!this.newReportEndDate) {
      return;
    }
    const endDate = moment(this.newReportEndDate);

    if (endDate.isBefore(startDate)) {
      this.newReportEndDate = startDate.toDate();
    }
  }

  public isValidDate(value: string): boolean {
    if (!value) {
      return false;
    }
    return !moment(value).isAfter(moment());
  }

  /**
   * Triggers on submit form.
   * @param {NgForm} form
   */
  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      const startDate = moment(this.newReportStartDate, 'M/DD/YYYY').format();
      const endDate = moment(this.newReportEndDate, 'M/DD/YYYY').add(1, 'day').add(-1, 'second').format();

      const spinner = this.popupService.spinner();

      const response = await this.reportsService.addSignupReport(this.user.merchantId,
        this.currentWebApp, startDate, endDate, this.newReportTitle);

      if (response === null) {
        this.reports = await this.reportsService.getSignupReports(this.user.merchantId, this.currentWebApp);
        this.tableData = this.reports.map(report => {
          return {
            id: report.id,
            name: report.name,
            start: report.start,
            end: report.end,
            status: report.status
          };
        });
        this.setPageClicked(1);
      } else {
        this.errorHandlerService.invalidateForm(form, {'name': 'report-title'}, response);
      }

      spinner.close();
    }
  }

  public setPageClicked(page: number) {
    this.pagination = this.paginationService.getPagination(this.tableData.length, page);

    this.pagedItems = this.tableData.slice(this.pagination.startIndex, this.pagination.endIndex + 1);
  }

  public searchStringChanged(event) {
    this.tableData = this.filterItems(this.reports, event);
    this.setPageClicked(1);
  }

  /**
   * Searches for string in table.
   */
  private filterItems(items, searchString: string): Report[] {
    this.searchString = searchString;
    return items.filter(i => i.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 || searchString === '');
  }

  public downloadReport($event: MouseEvent, item: SignupReport) {
    this.reportsService.downloadSignupReport(this.user.merchantId, this.currentWebApp, item.id)
      .then((blob: Blob) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = `Scan Report - ${this.currentWebApp} - ${moment(item.start, 'YYYY-MM-DD').format('MM-DD-YYYY')} - ${moment(item.end, 'YYYY-MM-DD').format('MM-DD-YYYY')}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Revoke the URL after the download
      })
      .catch(error => {
        alert('Error while downloading report');
        console.error(error);
      });
  }

  public async deleteReport($event: MouseEvent, item: any) {
    // show confirmation
    const result = await this.popupService.confirm('Are you sure to delete report ?', 'Report Deleting');

    if (result === PopupResult.OK || result === PopupResult.YES) {
      const spinner = this.popupService.spinner();
      this.reportsService.deleteSignupReport(this.user.merchantId, this.currentWebApp, item.id)
        .then(() => {
          return this.reportsService.getSignupReports(this.user.merchantId, this.currentWebApp);
        })
        .then((reports) => {
          this.reports = reports;
          this.tableData = this.reports.map(report => {
            return {
              id: report.id,
              name: report.name,
              start: report.start,
              end: report.end,
              status: report.status
            };
          });
          this.setPageClicked(1);
          spinner.close();
        })
        .catch(error => {
          alert('Error while deleting report');
          console.error(error);
          spinner.close();
        });
    }
  }
}
