<form #form="ngForm" name="eventStyle" [class.form-submitted]="form.submitted">
  <h2 class="section-title">
    <div class="tooltip-title">
      Create Event Listing
    </div>
  </h2>
  <label class="label">
  </label>

  <section class="container">
    <div>
      <span class="limit60" style="color: green;">
        Limit: 60 Characters
      </span>
      <div class="tooltip-title">
        <div class="global-caption">Add Event Title &nbsp;<div style="font-size: 12px;">(i.e., Restaurant Week)
        </div>
        </div>
        <div class="global-required">*required</div>
      </div>
      <pb-input name="campainMessage" required label="" #campainMessage="ngModel"
                [(ngModel)]="eventStyle.campaignMessage" maxlength="60"
                placeholder="Example: Restaurant Week"
                class="image-label" pbTooltip>
        <pb-error-message [errors]="campainMessage.errors"></pb-error-message>
      </pb-input>
    </div>
  </section>

  <section class="container">
    <div>
      <div class="tooltip-title">
        <div class="global-caption">Add a Description about the event.</div>
        <div class="global-required">*required</div>
      </div>
      <div>
        <pb-textarea name="dealDescription" label="" #dealDescription="ngModel"
                     [(ngModel)]="eventStyle.dealDescription" required
                     placeholder="For example: Join us for a local celebration of our culinary scene."
                     note=""
                     maxlength="800" class="full-width" rows="8" pbTooltip customElementQuery=".body">
        </pb-textarea>
        <pb-error-message [errors]="dealDescription.errors"></pb-error-message>
      </div>
    </div>
  </section>

  <section class="container">
    <div class="tooltip-title">
      <div class="global-caption">Add Keywords for Event&nbsp;<div style="font-size: 12px;">
        (i.e., Restaurant Week, Food and Wine, Dining)
      </div>
      </div>
      <div class="global-required">*required</div>
    </div>
    <pb-keywords [required]="!eventStyle.keywords" [isCoupon]="true" #keywords="ngModel" name="keywords" ngModel
                 [keywords$]="keywords$" (keywordsChange)="onKeywordsChange($event)">
      <pb-error-message class="keywords-error-message" [errors]="keywords?.errors"></pb-error-message>
    </pb-keywords>
  </section>

  <section class="container">
    <div class="row">
      <div>
        <div class="tooltip-title">
          <div class="global-caption">Add Photo or Video</div>
          <div class="global-required">*required</div>
        </div>
        <span *ngIf="mediaType === 'image'" class="note"><b>Image files supported – JPEG, PNG</b></span>
        <span *ngIf="mediaType === 'video'" class="note"><b>Video files supported – MP4/MOV (15-30 seconds preferred /
            60
            seconds
            max)</b></span>
      </div>
    </div>

    <div class="global-subtitle">&nbsp;</div>
    <pb-toggle-selector [options]="mediaTypeSelector" [selected]="mediaType" (onSelect)="changeMediaType($event)">
    </pb-toggle-selector>
    <div class="row">
      <div class="basic-left">
        <div class="coupon-image">
          <br>
          <div *ngIf="mediaType === 'video'" class="browse-custom-image">
            <pb-browse-video-file [required]="!hasVideo()" [buttonWidth]="'240px'" [buttonText]="'Upload Video'"
                                  name=" video" [(ngModel)]="videoFile" (removed)="removeVideoClicked()"
                                  (uploaded)="videoUploaded($event)"
                                  [notes]="videoUploadInstructions">
            </pb-browse-video-file>
            <br>
            <pb-browse-file *ngIf="hasVideo()" [required]="!hasVideoThumb()" [canRemove]="false" [buttonWidth]="'240px'"
                            [buttonText]="'Upload a separate Thumbnail image for this Video (optional)'"
                            name="video_thumb"
                            [(ngModel)]="videoThumbImage" (removed)="removeThumbnailClicked()"
                            (uploaded)="videoThumbUploaded($event)"
                            [notes]="videoThumbUploadInstructions">
            </pb-browse-file>
          </div>
          <div *ngIf="mediaType === 'image'" class="browse-custom-image">
            <pb-browse-file [required]="!hasCouponImage()" [buttonWidth]="'240px'" [buttonText]="'Upload Picture'"
                            name="logo" [(ngModel)]="couponImage" (uploaded)="logoUploaded($event)"
                            (removed)="removeLogoClicked()"
                            [notes]="imageUploadInstructions">
            </pb-browse-file>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <div style="display: flex; justify-content: space-evenly">
      <div>
        <div class="tooltip-title">
          <div class="global-caption">Add Address of Event</div>
          <div class="global-required">*required</div>
        </div>
        <div style="font-weight: bold;">Select the locations (markets) you want to reach.</div>
      </div>
      <div class="marketing-fact">
        <div class="box">
          <div class="heading">
            Helpful Marketing Fact:
          </div>
          <div class="description">
            Add New Location if your event is being held at a different address then listed.
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="area" style="display: flex; flex-direction: column;">
      <div class="distance">
        <div class="tooltip-title">
          <div class="global-caption">Select marketing distance</div>
          <pb-info-tooltip
            description="Promote to consumers/guests  as close as 300 ft/.09km  up to  200 miles/ 322 km ( guests, stay-cationers, weekend warriors, residents, travelers, millennials).">
          </pb-info-tooltip>
        </div>
        <div class="select-wrapper">
          <select class="select" name="radius" [(ngModel)]="eventStyle.radius" [compareWith]="compareOptions">
            <option *ngFor="let radius of radiuses" [ngValue]="radius" [innerHTML]="radius.value"></option>
          </select>
        </div>
      </div>
      <div class="places-wrapper">
        <div class="places">
          <div class="states">
            <div class="places-header">
              <label class="checkbox-label">
                <input class="checkbox" type="checkbox" name="all-countries" [checked]="isSelectAllCountries"
                       (change)="setSelectAllCountries($event.target.checked)">
                <div class="pseudo-checkbox"></div>
                All
              </label>
            </div>
            <div class="state-container">
              <div class="state" *ngFor="let country of countries; let i = index;">
                <label class="checkbox-label" for="country-{{country.name}}">
                  <input class="checkbox" type="checkbox" name="country" id="country-{{country.name}}"
                         (change)="countryChecked($event.target.checked, i)" [checked]="country.value">
                  <div class="pseudo-checkbox"></div>
                  {{country.name}}
                </label>
              </div>
            </div>
          </div>
          <div class="states">
            <div class="places-header">
              <label class="checkbox-label">
                <input class="checkbox" type="checkbox" name="all-states" [checked]="isSelectAllStates"
                       (change)="setSelectAllStates($event.target.checked)">
                <div class="pseudo-checkbox"></div>
                All
              </label>
            </div>
            <div class="state-container">
              <div class="state" *ngFor="let state of states; let i = index;">
                <label class="checkbox-label" for="state{{state.name}}">
                  <input class="checkbox" type="checkbox" name="state" id="state{{state.name}}"
                         (change)="stateChecked($event.target.checked, i)" [checked]="state.value">
                  <div class="pseudo-checkbox"></div>
                  {{state.name}}
                </label>
              </div>
            </div>
          </div>
          <div class="locations">
            <div class="places-header">
              <label class="checkbox-label">
                <input class="checkbox" type="checkbox" name="all-locations" [checked]="isSelectAllLocations"
                       (change)="setSelectAllLocations($event.target.checked)">
                <div class="pseudo-checkbox"></div>
                All Locations
              </label>
            </div>
            <div class="location-container">
              <div class="location" *ngFor="let location of locations; let i = index;">
                <label class="checkbox-label">
                  <input class="checkbox" type="checkbox" name="location" [checked]="location.selected"
                         (change)="locationChecked($event.target.checked, location.id)">
                  <div class="pseudo-checkbox"></div>
                  {{location.name}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 10px;">
      <button pb-button type="button" class="button"
              style="width: auto;"
              (click)="showEventLocationPopup()">Add New Location
      </button>
    </div>
  </section>

  <section class="container">
    <div class="tooltip-title">
      <div class="global-caption">Contact Phone Number</div>
      <div class="global-optional">(optional)</div>
    </div>
    <div style="display: flex; gap: 10px;">
      <div style="width: 25%">
        <pb-select name="call_country" [(ngModel)]="callCountry"
                   (change)="countryCodeChanged($event.target.value)">
          <option [value]="country.dial_code" *ngFor="let country of placeCountries;">{{country.text}}
          </option>
        </pb-select>
      </div>
      <div style="width: 15%">
        <pb-input name="country_calling_code"
                  [readonly]="true"
                  [(ngModel)]="eventStyle.contactInformationPhoneCode"
                  placeholder="Code"
                  class="element size-medium input input-as-selectize"
                  minlength="2"></pb-input>
      </div>
      <div style="width: 47%">
        <pb-input name="contactInformationPhone" #contactInformationPhone="ngModel"
                  placeholder="Enter a Phone"
                  [maxlength]="100"
                  pbTooltip customElementQuery=".body"
                  [(ngModel)]="eventStyle.contactInformationPhone">
        </pb-input>
        <pb-error-message [errors]="contactInformationPhone.errors"></pb-error-message>
      </div>
    </div>
  </section>

  <section class="container">
    <div class="dateRange">
      <div class="tooltip-title">
        <div class="global-caption">Event Dates & Times</div>
        <div class="global-required">*required</div>
      </div>
      <div class="global-subtitle">Select the dates you would like this event to run.</div>

      <!--<div class="publish-date-container">

        <pb-checkbox [label]="'Single Day Event'"
                     [checked]="eventSingleDay"
                     (change)="eventSingleDay = $event.target.checked"></pb-checkbox>
        <pb-checkbox [name]="eventTime.name" [key]="eventTime.name" [label]="'All Day Event'"
                     [checked]="eventAllDay"
                     [isDisabled]="!eventTime.isActive"
                     (change)="setEventAllDay($event);"></pb-checkbox>
      </div>-->
      <div class="row publish-daterange-container">
        <div style="flex: 5%;">
          <span>From</span>
        </div>
        <div style="flex: 20%;">
          <pb-datepicker class="datepicker"
                         [(ngModel)]="eventStyle.dateRange.start"
                         [maxInputDate]="maxDate"
                         [minInputDate]="minDate"
                         (ngModelChange)="startDateChanged($event)"
                         [required]="true"
                         name="start"
                         placeholder="Start Date"
                         pbDateRangeValidation>
          </pb-datepicker>
        </div>
        <div style="flex: 15%;">
          <span>Start Time</span>
        </div>
        <div style="flex: 20%;">
          <div class="start-time">
            <button class="time-range-button" type="button" (click)="eventTime.toggleSetTime('start')">
              <svg class="icon">
                <use inlineHref="#iconClock"></use>
              </svg>
              {{eventTime.startTime}}
            </button>
            <div *ngIf="eventTime.start.showTimePicker" class="time-range-control">
              <input class="time" type="number" [value]="eventTime.startHour"
                     (change)="eventTime.changeHour(null, 'start', $event.target.value)"/>
              <button type="button" (click)="eventTime.changeHour('up', 'start')">&#9650;</button>
              <button type="button" (click)="eventTime.changeHour('down', 'start')">&#9660;</button>
              <span>:</span>
              <input class="time" type="number" [value]="eventTime.startMinutes"
                     (change)="eventTime.changeMinutes(null, 'start', $event.target.value)"/>
              <button type="button" (click)="eventTime.changeMinutes('up', 'start')">&#9650;</button>
              <button type="button" (click)="eventTime.changeMinutes('down', 'start')">&#9660;</button>

              <div class="select-wrapper">
                <select class="select" name="startMeridiem" [ngModel]="eventTime.startMeridiem"
                        (ngModelChange)="eventTime.meridiemChange($event, 'start')">
                  <option value="am" default>AM</option>
                  <option value="pm">PM</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row publish-daterange-container">
        <div style="flex: 5%;">
          <span>End</span>
        </div>
        <div style="flex: 20%;">
          <pb-datepicker class="datepicker"
                         [(ngModel)]="eventStyle.dateRange.end"
                         [maxInputDate]="maxDate"
                         [minInputDate]="minEndDate"
                         [required]="true"
                         name="end"
                         placeholder="End Date"
                         pbDateRangeValidation>
          </pb-datepicker>
        </div>
        <div style="flex: 15%;">
          <span>End Time</span>
        </div>
        <div style="flex: 20%;">
          <div class="end-time">
            <button class="time-range-button" type="button" (click)="eventTime.toggleSetTime('end')">
              <svg class="icon">
                <use inlineHref="#iconClock"></use>
              </svg>
              {{eventTime.endTime}}
            </button>
            <div *ngIf="eventTime.end.showTimePicker" class="time-range-control">
              <input class="time" type="number" [value]="eventTime.endHour"
                     (change)="eventTime.changeHour(null, 'end', $event.target.value)"/>
              <button type="button" (click)="eventTime.changeHour('up', 'end')">&#9650;</button>
              <button type="button" (click)="eventTime.changeHour('down', 'end')">&#9660;</button>
              <span>:</span>
              <input class="time" type="number" [value]="eventTime.endMinutes"
                     (change)="eventTime.changeMinutes(null, 'end', $event.target.value)"/>
              <button type="button" (click)="eventTime.changeMinutes('up', 'end')">&#9650;</button>
              <button type="button" (click)="eventTime.changeMinutes('down', 'end')">&#9660;</button>

              <div class="select-wrapper">
                <select class="select" name="endMeridiem" [ngModel]="eventTime.endMeridiem"
                        (ngModelChange)="eventTime.meridiemChange($event, 'end');checkEventTime()">
                  <option value="am" default>AM</option>
                  <option value="pm">PM</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <div>
      <div class="tooltip-title">
        <div class="global-caption">Add a custom link to your eCommerce site</div>
        <div class="global-optional">(optional)</div>
      </div>
      <div class="global-subtitle">Create a custom call-to-action for your site.</div>
      <div class="form-group">
        <div style="display: flex;">
          <select [(ngModel)]="eventStyle.shopOptions[0].url_type"
                  (change)="customLinkChanged($event)" name="shopoptionurl1" class="cat-select">
            <option value="" selected disabled>Select an option</option>
            <option [disabled]="isShopOptionAvailable(shopOption.url_type)" [value]="shopOption.url_type"
                    *ngFor="let shopOption of eventStyle.shopOptionsList">
              {{shopOption.title}}</option>
          </select>
          <pb-input name="shopoption1" style="width: 100%;" placeholder="Enter a URL" [maxlength]="1024"
                    [readonly]="!hasShopOptions()" [(ngModel)]="eventStyle.shopOptions[0].url">
          </pb-input>
          <button *ngIf="hasShopOptions()" (click)="clearShopOptioins()" class="shop-option-clear">Remove</button>
        </div>
        <!-- <div style="display: flex;">
          <select [(ngModel)]="redemptionOptions.shopOptions[1].url_type" name="shopoptionurl2" class="cat-select">
            <option value="" selected disabled>Select an option</option>
            <option [disabled]="isShopOptionAvailable(shopOption.url_type)" [value]="shopOption.url_type"
              *ngFor="let shopOption of redemptionOptions.shopOptionsList">
              {{shopOption.title}}</option>
          </select>
          <pb-input name="shopoption2" style="width: 100%;" placeholder="Enter a URL" [maxlength]="100"
            [(ngModel)]="redemptionOptions.shopOptions[1].url">
          </pb-input>
        </div> -->
      </div>
    </div>
  </section>
</form>
