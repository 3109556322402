<header pb-page-header>
  <div left>
    <a class="row" [routerLink]="['/invoices']">
      <pb-icon name="#iconBackArrow" class="back-arrow"></pb-icon> {{ invoiceTitle }}
    </a>
  </div>
  <div right>
    <div class="buttons">
      <button pb-button small class="button" (click)="ExportToCsv()">
        Export CSV
      </button>
      <button pb-button small class="button" (click)="ExportToPdf()">
        Export PDF
      </button>
    </div>
  </div>
</header>

<div class="row">
  <div class="body">
    <div class="info row">
      <div class="item" *ngIf="merchant">
        <div class="info__caption">Merchant</div>
        <div class="info__text">{{ invoice ? (invoice.merchant.name || invoice.merchant.name_dba) : "" }}</div>
      </div>
      <div class="item">
        <div class="info__caption">Account Holder</div>
        <div class="info__text" *ngIf="creditCardInfo">{{ creditCardInfo.cardholderName }}</div>
      </div>
      <div class="item">
        <div class="info__caption">CID</div>
        <div class="info__text" *ngIf="merchant">{{ merchant.personalId }}</div>
      </div>
      <div class="item" *ngIf="invoice">
        <div class="info__caption">Invoice Status</div>
        <div class="info__text">{{invoice.status}}</div>
      </div>
    </div>
    <div class="info row">
      <div class="item">
        <div class="info__caption">Legal Address</div>
        <div class="info__text" *ngIf="merchant">{{ locationFormatted }}</div>
      </div>
    </div>
    <br>
    <div class="info row">
      <div class="item">
        <div class="info__caption">Campaign</div>
        <div class="info__text" *ngIf="merchant">{{ getCampaignName }}</div>
      </div>
    </div>
    <table pb-table *ngIf="monthlySubscription && couponsSubscription">
      <caption>Details</caption>
      <thead>
        <tr>
          <th>Type</th>
          <th>Qty</th>
          <th>Cost</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="monthlySubscription && monthlySubscription.count > 0">
          <td>Plan Fee</td>
          <td>{{ monthlySubscription.count }}</td>
          <td>{{ monthlySubscription.rate | currency: 'USD' : true :'2.2-2' }}</td>
          <td>{{ monthlySubscription.getSubtotal() | currency: 'USD' : true :'2.2-2' }}</td>
        </tr>
        <tr *ngIf="couponsSubscription && couponsSubscription.count > 0">
          <td>States</td>
          <td>{{ couponsSubscription.count }}</td>
          <td>{{ couponsSubscription.rate | currency: 'USD' : true :'2.2-2' }}</td>
          <td>{{ couponsSubscription.getSubtotal() | currency: 'USD' : true :'2.2-2' }}</td>
        </tr>
        <tr *ngIf="promotionSubscription && promotionSubscription.count > 0">
          <td>Emails</td>
          <td *ngIf="promotionSubscription">{{ promotionSubscription.count }}</td>
          <td *ngIf="promotionSubscription">{{ promotionSubscription.rate | currency: 'USD' : true :'2.2-2' }}</td>
          <td *ngIf="promotionSubscription">{{ promotionSubscription.getSubtotal() | currency: 'USD' : true :'2.2-2' }}
          </td>
        </tr>
        <tr *ngIf="messagesSubscription && messagesSubscription.count > 0">
          <td>Links</td>
          <td *ngIf="messagesSubscription">{{ messagesSubscription.count }}</td>
          <td *ngIf="messagesSubscription">{{ messagesSubscription.rate | currency: 'USD' : true :'2.2-2' }}</td>
          <td *ngIf="messagesSubscription">{{ messagesSubscription.getSubtotal() | currency: 'USD' : true :'2.2-2' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <pb-side [invoice]="invoice"></pb-side>
  <br><br>
  <div class="caption">All Invoices for Campaign {{getCampaignName}}</div>
  <table pb-table style="margin-top: 0px;">
    <thead>
      <tr>
        <th width="400px">
          <div class="row">
            Invoice #
          </div>
        </th>
        <th width="180px">
          <div class="row">
            Bill Date
          </div>
        </th>
        <th width="180px">
          <div class="row">
            Card Charged
          </div>
        </th>
        <th>
          <div class="row">
            Total
          </div>
        </th>
        <th>
          <div class="row">
            Status
          </div>
        </th>
      </tr>
      <tr>
        <th>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of pagedItems">
        <td>
          <a href="" [routerLink]='["/invoices", item.id]'>{{item.invoiceId}}</a>
        </td>
        <td>{{item.chargeDate}}</td>
        <td>{{item.cardCharged}}</td>
        <td>{{item.sum}}</td>
        <td>{{item.status}}</td>
      </tr>
    </tbody>
  </table>
  <pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
</div>