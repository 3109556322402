import { Subscription } from "../../../models/public_api";

/**
 * Dto for merchant's coupon.
 */
export class CouponDto {
  /**
   * Id of coupon.
   * Need to migrate to coupon infrastructure.
   */
  public id: number;

  /**
   * Id of campaign.
   */
  public campaign: number;

  /**
   * Name of coupon.
   */
  public name: string;

  /**
   * Online code.
   */
  public online: string;

  /**
   * Online coupon instruction.
   */
  public online_instruction: string;

  /**
   * In store code.
   */
  public in_store: string;
  public in_store_image: string;
  public in_store_type: string;

  /**
   * In store instruction.
   */
  public in_store_instruction: string;

  /**
   * Rules for coupon.
   */
  public rules: string;

  /**
   * Terms for coupon.
   */
  public terms: string;

  public start: string;

  public end: string;

  public description: string;

  public budget: number;

  /**
   * Id of merchant.
   */
  public merchantId: number;

  /**
   * Operation hours object.
   */
  public operation_hours: any;

  public target_audience: {
    age_start: number;
    age_end: number;
    radius: number;
    gender: string;
    /**
     * Array of ids for interests.
     */
    interest: string[];
  };

  public places: string[];

  /**
   * Image of coupon.
   */
  public image: string;
  public video: any;

  /**
   * Logo of coupon.
   */
  // public logo: string;

  /**
   * Keywords for coupon.
   */
  public keywords: string[];

  /**
   * Flag for expiration of coupon.
   */
  public is_expired = false;
  public is_redeemable = false;
  /**
   * Text of message for coupon.
   */
  public message_text: string;

  /**
   * Impressions by coupon.
   */
  public impressions: number;

  /**
   * Generations by coupon.
   */
  public likes: number;
  public clicks: number;
  /**
   * Redemtions by coupon.
   */
  public redemptions: number;

  public in_store_redeem_limit: number;

  public in_store_redeem_left: number;

  public online_redeem_limit: number;

  public online_redeem_left: number;

  /**
   * Old price of coupon.
   */
  public old_price: string;

  /**
   * New price of coupon.
   */
  public new_price: string;

  public campaign_plan: number;
  public premium_states: string[];
  public premium_email_states: string[];
  public subscriptions: Subscription[];
  // public shop_options: {}[];

  public promo_code: number;
  public hidden: boolean;
  public featured: boolean;

  public provider: string;
}
