import { Injectable } from '@angular/core';

/**
 * Data transfer service. This service transits arbitrary data between application routes.
 */
@Injectable()
export class DataTransferService {

  private dataInner: any = {};

  /**
   * Data setter.
   * @param data Data of any type to be transited.
   */
  public set(data: any): void {
    this.dataInner = data;
  }

  /**
   * Data getter. To retrieve data from service.
   */
  public get data(): any {
    return this.dataInner;
  }

  /**
   * Clears data from service. This can be used combined with ngOnDestroy.
   */
  public clearData(): any {
    return this.dataInner = {};
  }

}
