import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { environment } from "../../../../environments/environment";
import { Plans } from "../../models/plans";
import { TokenStorageService } from "../token-storage.service";
import { PlansDto } from "./dtos/plans.dto";
import { PlansMapper } from "./mappers/plans.mapper";

const UTILS_URL = "plans";

/**
 * Core http service provides infrastructural methods.
 */
@Injectable()
export class PlansService {
  /**
   * .ctor
   * @param http Http
   */
  constructor(
    private http: Http,
    private plansMapper: PlansMapper,
    private tokenService: TokenStorageService
  ) {}

  /**
   * Returns rates object for merchant.
   */
  public async getPlans(): Promise<Plans[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${UTILS_URL}/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as PlansDto[])
      .map(r => r.map(v => this.plansMapper.mapToModel(v)))
      .toPromise();
  }

  public async getCampaignPlans(): Promise<Plans[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${UTILS_URL}/?type=C`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as PlansDto[])
      .map(r => r.map(v => this.plansMapper.mapToModel(v)))
      .toPromise();
  }

  public async getEventPlans(): Promise<Plans[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${UTILS_URL}/?type=E`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as PlansDto[])
      .map(r => r.map(v => this.plansMapper.mapToModel(v)))
      .toPromise();
  }
}
