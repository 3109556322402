
import { Observable } from 'rxjs/Rx';

import {Component, EventEmitter, Input} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { VALIDATION_ERROR, AuthService, HttpStatusCode } from '../core';
import {PopupService} from "../popup";

const DELAY_BEFORE_SETTING_FIELDS_IN_INVALID = 1000;
/**
 * Login page component.
 */
@Component({
  selector: 'pb-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  /**
   * .ctor
   * @param {Router} router Router.
   * @param {AuthService} authService Authorization service.
   */
  constructor(private router: Router, private popupService: PopupService, private authService: AuthService) {
  }

  @Input()
  public brandName: string = 'GettinLocal';

  /**
   * Email input.
   */
  public email: string;

  /**
   * Password input.
   */
  public password: string;

  /**
   * If a verification message must be shown.
   */
  public showMessage = false;

  /**
   * Error emitted passed to pbServerValidation directive.
   */
  public errorEmitter = new EventEmitter<any>();

  private createErrorObject(errors: { field: string, errors: string }[]) {
    const validationErrors = [];
    errors.forEach(error => {
      validationErrors.push({ field: error.field, errors: [error.errors] });
    });

    return validationErrors;
  }

  /**
   * Setup form as invalid.
   */
  private setFieldsIsNotValid(form: NgForm, emailErrors: any = null, passwordErrors: any = null) {
    setTimeout(() => {
      const email = form.controls['email'];
      email.setValue(this.email);
      email.markAsDirty();
      if (emailErrors) {
        email.setErrors([emailErrors]);
      }

      const password = form.controls['password'];
      password.setValue(this.password);
      password.markAsDirty();
      if (passwordErrors) {
        password.setErrors([passwordErrors]);
      }

    }, DELAY_BEFORE_SETTING_FIELDS_IN_INVALID);
  }

  /**
   * Seeding error object with body which seeded with errors field.
   */
  private createLoginFormError(body: any, error: any, emailMessage: string, passwordMessage: string) {

    body.validation_errors = this.createErrorObject(
      [
        { field: 'email', errors: emailMessage },
        { field: 'password', errors: body.detail }
      ]
    );
    if (!error.code) {
      error.code = VALIDATION_ERROR;
      error.detail = VALIDATION_ERROR;
    }
    error._body = JSON.stringify(body);
  }

  /**
   * On submit event handler.
   * @param {NgForm} form Angular form.
   */
  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      try {
        await this.authService.login(this.email, this.password);
        const sub = this.authService.retrieveCurrentUser()
          .catch(error => {
            const json = error.json();
            this.setFieldsIsNotValid(form, json.detail, json.detail);
            return Observable.of(null);
          })
          .subscribe(user => {
            if (sub) {
              sub.unsubscribe();
            }
            if (user) {
              if (user.emailConfirmed)
                this.router.navigate([this.authService.redirectUrl || '/dashboard']);
              else
                this.popupService.info_sticky('Error: This email address provided must be confirmed by clicking the verify link. Please check your email.')
            }
          });
      } catch (error) {
        const body = error.json();
        // Manual validation.
        if (error.status && error.status === HttpStatusCode.BAD_REQUEST) {
          this.createLoginFormError(body, error, body.detail, body.detail);
          this.setFieldsIsNotValid(form);
        }

        this.errorEmitter.emit(error);
      }
    }
  }
}
