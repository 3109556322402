import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../../value-accessor-base';
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MdDialog } from '@angular/material';
import { b64toBlob } from '../../../../utils';
import { CropperPopupComponent } from '../cropper-popup/cropper-popup.component';


/**
 * Component for image thumbnail.
 */
@Component({
  selector: 'pb-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ThumbnailComponent, multi: true }]
})
export class ThumbnailComponent extends ValueAccessorBase<{ base64: string, blob: Blob }> {

  constructor(
    private dialog: MdDialog,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  /**
   * Thumbnail title.
   */
  @Input()
  public name = 'Thumbnail';

  @Input()
  public browseButtonText = 'Browse';

  @Input()
  public browseButtonWidth = '128px';

  /**
   * Width of thumbnail.
   */
  @Input()
  public width = 335;

  /**
   * Height of thumbnail.
   */
  @Input()
  public height = 335;


  @Input()
  public showSize = true;

  /**
   *  Things related to upload.
   */

  /**
   * Flag for show and hide browse component and show uploaded image.
   */
  @Input()
  public isImageUploaded = false;

  private temporaryBase64: string;

  /**
   * Data url.
   */
  @Input()
  public imageBase64: string;

  /**
   * Blob of uploaded image.
   */
  public imageBlob: Blob;

  /**
   * Fired after image uploaded to cropper.
   */
  @Output()
  public imageUploadedToCropper: EventEmitter<File> = new EventEmitter();

  /**
   * Handling upload to thumbnail with cropping.
   * @param result File uploaded to thumbnail.
   */
  public async fileUploaded(result: File): Promise<void> {
    this.isImageUploaded = false;
    const b64Image = await this.presentCropper();
    if (b64Image) {
      this.imageUploadedToCropper.emit(result);
      this.imageBase64 = b64Image;
      this.imageBlob = b64toBlob(b64Image);
      this.onChange({ base64: this.imageBase64, blob: this.imageBlob });
      this.isImageUploaded = true;
    }
  }

  /**
   * Browsed file assignemtn.
   * @param event Event fired on file browsing.
   */
  public async fileBrowsed(event) {
    this.temporaryBase64 = event;
  }

  /**
   * Url of image.
   */
  private get imageUrl(): SafeValue {

    if (this.imageBase64) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.imageBase64);
    }

    this.isImageUploaded = false;

    return null;
  }

  /**
   * Presenting cropper.
   */
  private presentCropper(): Promise<any> {
    const dialogRef = this.dialog.open(CropperPopupComponent, {
      data: {
        image: this.temporaryBase64,
        size: this.width,
        supportFit: true,
        height: this.height
      }
    });
    return dialogRef.afterClosed().toPromise();
  }
}
