﻿import {Observable, Subscription} from "rxjs/Rx";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { FormControl, NgForm } from "@angular/forms";
import { MdDialog } from "@angular/material";

import {
  ErrorHandlerService,
  MerchantsCoupon,
  CouponsService,
  MerchantService,
  MessagesService,
  AuthService,
  DataTransferService,
  ServerError,
  TargetAudience,
  CategoriesService,
  UtilsService, LoadedImage, ServerErrorDto, ServerErrorMapper,
} from "../core";
import { PopupResult, PopupService } from "../popup";
import { NewAutoMessage } from "../core/models/new-auto-message";
import {b64toBlob, emailRegex, nonCodedPhoneMask, websiteUrlRegex} from "../../utils";
import {BetterCropperPopupComponent} from "../shared";
import {HttpErrorResponse, HttpEventType, HttpResponse} from "@angular/common/http";

const VIDEO_TYPE_MP4 = "video/mp4";
const VIDEO_TYPE_QUICKTIME = "video/quicktime";
/**
 * New message component.
 */
@Component({
  selector: "pb-new-auto-message",
  templateUrl: "./new-auto-message.component.html",
  styleUrls: ["./new-auto-message.component.scss"],
})
export class NewAutoMessageComponent implements OnInit, OnDestroy {
  private routePreviousUrl: Subscription;

  /**
   * .ctor
   * @param {CategoriesService} categoriesService Categories service.
   * @param {MdDialog} dialog Material design dialog.
   * @param {PopupService} popupService Popup service.
   * @param {MerchantService} merchantService Merchant service.
   * @param {MessagesService} messagesService Message service.
   * @param {AuthService} authService Auth service.
   * @param {DataTransferService} dataTransferService Data transfer service.
   * @param {Router} router Angular router.
   */
  private readonly defaultImageWidth = 335;
  private readonly defaultImageHeight = 375;
  private readonly customImageWidth = 375;
  private readonly customImageHeight = 375;

  public mediaType: string = "image";
  public mediaTypeSelector = [
    { title: "Image", type: "image", icon: "photo" },
    { title: "Video", type: "video", icon: "video" },
  ];
  public videoFile: File;

  public videoUploadInstructions: string = `
  We recomend using landscape videos that are 1080x720 in resolution.
  `;

  public imageUploadInstructions: string = `
  We recomend using images that are 1080x720 in resolution.
  Recomended aspect ratio is 1.5:1
  `;

  public videoThumbUploadInstructions: string = `
  We recomend using images that are 1080x720 in resolution.
  Recomended aspect ratio is 1.5:1
  `;

  public imageFile = "";
  public videoThumbImage = "";


  public autoCompleteConfig: any = {'class': 'input', 'placeholder': 'Example: Sign up for Hotel Rewards and Loyalty Program', 'sourceField': 'label'};


  constructor(
    private categoriesService: CategoriesService,
    private dialog: MdDialog,
    private popupService: PopupService,
    private utilService: UtilsService,
    private merchantService: MerchantService,
    private messagesService: MessagesService,
    private authService: AuthService,
    private dataTransferService: DataTransferService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private couponsService: CouponsService,
    private errorHandlerService: ErrorHandlerService,
    private serverErrorMapper: ServerErrorMapper
  ) {
    this.routePreviousUrl = this.router.events
      .filter((e) => e instanceof NavigationEnd)
      .subscribe((v: NavigationEnd) => {
        localStorage["previousUrl"] = v.url;
      });
  }

  public action_url = new FormControl();
  public pushAlertSamples = [];
  /**
   * A merchant id.
   */
  private merchantId: number;

  public send_timers = [
    { value: 5, key: "5 Minutes" },
    { value: 15, key: "15 Minutes" },
    { value: 30, key: "30 Minutes" },
    { value: 60, key: "1 Hour" },
    { value: 120, key: "2 Hour" },
    { value: 480, key: "8 Hours" },
    { value: 1440, key: "24 Hours" },
    { value: 2880, key: "48 Hours" },
    { value: 4320, key: "72 Hours" },
  ];

  /**
   * A message to be sent.
   */
  public message: NewAutoMessage = new NewAutoMessage();
  public second_message: NewAutoMessage = new NewAutoMessage();

  public coupon: MerchantsCoupon;

  @ViewChild("form")
  public form: NgForm;

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.utilService
      .getPushAlertSamples()
      .then((list) =>
        list.map((m, idx) => {
          return { id: idx, label: m };
        })
      )
      .then((list) => {
        this.pushAlertSamples = list;
        this.pushAlertSamples.push({id: list.length, label: 'Concierge Contact'})
      })
      .catch((err) => console.error(err));

    this.message.audienceCount = 0;
    const userSubscription = this.authService.onUserChanged.subscribe(
      async (result) => {
        const user = result;
        if (user) {
          this.merchantId = user.merchantId;

          if(!user.merchant.isAutoHeartingEnabled()) {
            this.router.navigateByUrl("/dashboard");
          }

          this.activatedRoute.params.subscribe(
            async (params): Promise<void> => {
              const messageId: number = params["id"];

              if (messageId) {
                const spinner = this.popupService.spinner();
                /* Load auto message */
                const response = await this.messagesService.getAutoMessage(
                  this.merchantId,
                  messageId
                );
                spinner.close();
                if (response instanceof NewAutoMessage) {
                  this.message = response;
                  if(this.message.video) {
                    this.mediaType = "video";
                    this.videoFile = this.message.video;
                    setTimeout(() => {
                      const videoNode = document.querySelector('video')
                      videoNode.src = this.message.video;
                    }, 1000);
                  }
                  this.imageFile = this.message.image;
                } else {
                  this.handleServerError(response);
                }
              } else {
                // this.message.auto_time_delta = 30;
              }
            }
          );

          if (userSubscription) {
            userSubscription.unsubscribe();
          }
        }
      }
    );

    const categories = await this.categoriesService.getFlatLeveled();

    this.message.allInterests = categories.map((o) => {
      return { id: o.id, text: o.pluralName };
    });
  }

  /**
   * Opens information popup.
   */
  private async openInfoPopup(): Promise<void> {
    await this.popupService.info(
      "Loyalty Push Alert successfully saved!",
      "Alert Saved"
    );
  }

  /**
   * Handles on submit form event.
   * @param {NgForm} form Angular form.
   */
  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      if (this.message.actionURL) {
        if (
          !this.message.actionURL.startsWith("http://") &&
          !this.message.actionURL.startsWith("https://")
        ) {
          this.message.actionURL = "https://" + this.message.actionURL;
        }
      }

      if(this.message.actionText.length > 0 && !this.message.isConcierge && this.message.actionURL.length === 0) {
        this.popupService.info_sticky('Please enter a valid URL');
        return;
      }

      if(this.message.actionText.length > 0 && this.message.isConcierge && this.message.actionEmail.length === 0 && this.message.actionPhone.length === 0) {
        this.popupService.info_sticky('Please enter a valid phone number or email');
        return;
      }

      const spinner = this.popupService.progresSpinner();

      let request;
      if (this.message.id) {
        request = this.messagesService.updateAutoMessageWithMedia(
          this.merchantId,
          this.message
        )
      } else {
        request = this.messagesService.createAutoMessageWithMedia(
          this.merchantId,
          this.message
        )
      }
      request.subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round((100 * event.loaded) / event.total);
              spinner.componentInstance.updateProgress(percentDone);
            } else if (event instanceof HttpResponse) {
              this.message = <any>event.body;
              this.videoFile = this.message.video;
              this.imageFile = this.message.image;
              spinner.close();
              // this.openInfoPopup();
              this.router.navigateByUrl("/auto-messages");
              // this.removeLogoClicked();
            } else if (event instanceof HttpErrorResponse) {
              spinner.close();
            }
          },
          (error) => {
            spinner.close();

            const error_data = error.error;
            if (error_data && error_data.error)
              this.popupService.info_sticky(error_data.error);
            else {

              this.handleHttpErrorResponse(error);
            }
          }
        );
    }
  }

  public ngOnDestroy(): void {
    if (this.routePreviousUrl) {
      this.routePreviousUrl.unsubscribe();
    }
  }

  /**
   * Invalidating controls on form.
   * @param serverError Server error returned by remote.
   */
  private handleHttpErrorResponse(httpErrorResponse: HttpErrorResponse) {
    console.warn(httpErrorResponse.error)

    const serverError = this.serverErrorMapper.mapFromHttpClientErrorResponse(httpErrorResponse);

    if (!httpErrorResponse.error) {
      return false;
    }

    let errors = "";

    serverError.validationErrors.forEach((validationError) => {
      errors += validationError.errors[0] + "\n";
    });

    if (errors) this.popupService.info_sticky(errors);
    else this.popupService.info_sticky(
      "An error happened while sending the message."
    );

    const map = {
      action_url: "acton_url"
    };

    this.errorHandlerService.invalidateForm(this.form, map, serverError);
  }

  /**
   * Invalidating controls on form.
   * @param serverError Server error returned by remote.
   */
  private handleServerError(serverError: ServerError) {
    if (!serverError.validationErrors) {
      return false;
    }

    let errors = "";

    serverError.validationErrors.forEach((validationError) => {
      errors += validationError.errors[0] + "\n";
    });

    if (errors) this.popupService.info_sticky(errors);
    // this.errorHandlerService.invalidateForm(this.form, map, serverError);
  }

  stopAlert = async function (messageId: number) {
    const spinner = this.popupService.spinner();

    if (messageId) {
      const response = await this.messagesService.stopAutoMessage(
        this.merchantId,
        messageId
      );
      if (response instanceof NewAutoMessage) {
        this.router.navigate(["/auto-messages"]);
      } else {
        this.handleServerError(response);
      }
    }
    spinner.close();
  };

  setActionText = (text) => this.message.actionText = text;

  public stateChanged(event: any) {}

  private presentLogoCropperPopup(
    w: number,
    h: number,
    image: string | ArrayBuffer,
    caption?: string
  ): Promise<string> {
    const dialogRef = this.dialog.open(BetterCropperPopupComponent, {
      data: {
        image,
        size: this.defaultImageWidth,
        height: this.defaultImageHeight,
        caption,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  public async changeMediaType(event): Promise<void> {
    this.mediaType = event;

    if(event === 'video' && this.message.video) {
      if(typeof this.message.video === "string") {
        setTimeout(() => {
          const videoNode = document.querySelector('video')
          videoNode.src = this.message.video;
        }, 1000);
      }
    }
  }

  public getMediaType(): string {
    if (this.message.video) {
      if (this.message.video) return "video";
    }
    return "image";
  }

  public hasCouponImage(): boolean {
    if (this.message.image) return true;
    return false;
  }

  public hasVideo(): boolean {
    if (this.message.video) {
      return true;
    }
    return false;
  }

  /*public hasVideoThumb(): boolean {
    if (this.message.video) {
      return this.message.video_thumb !== null;
    }
    return false;
  }*/

  public removeVideoClicked(): void {
    this.videoFile = null;
    this.message.video = null;
  }

  public removeThumbnailClicked(): void {
    this.message.video = null;
  }

  public async videoUploaded(video: File) {
    if (!video) return;

    if (video.type !== VIDEO_TYPE_MP4 && video.type !== VIDEO_TYPE_QUICKTIME) {
      this.popupService.info_sticky("Please upload a MP4/MOV video.");
      return;
    }

    const videoNode = document.querySelector('video')
    const canPlay = videoNode.canPlayType(video.type)
    const fileURL = URL.createObjectURL(video)
    videoNode.src = fileURL
    this.videoFile = video

    this.message.video = video;
  }

  public removeLogoClicked(): void {
    this.message.image = null;
  }

  public async logoUploaded(images: LoadedImage[]) {
    this.removeVideoClicked();

    const fullViewCaption = "Full view image";
    const b64ImageFull = await this.presentLogoCropperPopup(
      this.customImageWidth,
      this.customImageHeight,
      images[0].base64String,
      fullViewCaption
    );

    if (b64ImageFull) {
      this.imageFile = b64ImageFull
      this.message.image = b64toBlob(b64ImageFull);
    }
  }

  onInputChangedEvent(val: string) {
    setTimeout(() => {
      this.message.isConcierge = val === this.pushAlertSamples[this.pushAlertSamples.length - 1].label;
    }, 400);
    // this.message.actionText = val;
  }

  onSelect(item: any) {
    this.message.isConcierge = item.id === this.pushAlertSamples.length - 1;
    this.message.actionText = item.label;
  }

  public get phoneMask() {
    return nonCodedPhoneMask();
  }

  public get websiteUrlMask(): string {
    return websiteUrlRegex();
  }

  public get emailMask() {
    return emailRegex();
  }
}
