import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pb-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss']
})
export class InfoItemComponent implements OnInit {
  @Input()
  public caption: string;

  public ngOnInit() {
  }

}
