<div class="l_wrapper">
  <div class="l_title">
    <h3>Login to your existing {{brandName}} Business Account</h3>
  </div>
  <pb-message caption="Account Verified!" class="l_message" text="Your account is ready to go. Welcome to GettinLocal!"
    *ngIf="showMessage"></pb-message>
  <div class="l_content">
    <form #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted"
      [pbServerValidation]="errorEmitter">
      <br>
      <br>
      <br>
      <pb-input name="email" #emailRef="ngModel" [(ngModel)]="email" placeholder="Email*"  email required pbTooltip
        customElementQuery=".body">
      </pb-input>
      <pb-input name="password" #passwordRef="ngModel" type="password" [(ngModel)]="password" placeholder="Password*"
        required pbTooltip customElementQuery=".body">
      </pb-input>
      <br>
      <div style="margin: 21px 0 11px 0; text-align: center;">
        Can't access your account? <a [routerLink]="['/forgot-password']" class="forgot-password-link">Password reminder</a>
      </div>
      <br>
      <button pb-button type="submit" class="login-button">
        <span class="btn-text">Login</span>
      </button>
    </form>
  </div>
</div>
