<div class="logo">
  <img src="{{activity.imageSrc ? activity.imageSrc : 'assets/images/white-40x40.png'}}" width="40" height="40" [alt]="company.name">
</div>
<div class="body">
  <div class="top">
    {{activity.name}}
  </div>
  <div class="bottom">
    <div class="left" *ngIf="activity.radius">
      {{company.name}}, {{activity.radius}} <br/>
    </div>
    <div class="left" *ngIf="!activity.radius">
      {{company.name}}
    </div>
    <div class="right" *ngIf="activity.time">
      <pb-icon name="#iconClock" class="icon"></pb-icon>
      {{activity.time}}
    </div>
  </div>
</div>

