import { CouponDto } from '../../coupons/dtos/coupon.dto';
import { PagedDto } from '../../pagination/dtos/paged.dto';

/**
 * Paged dto for reports.
 */
export class ReportsDto extends PagedDto<ReportDto> {

}

/**
 * Dto for report.
 */
export class ReportDto {
    public id: number;
    public start: string;

    public end: string;

    public document: string;

    public report_date: string;

    public name: string;

    public places: string[];

    public data: ReportDataDto[];
}

/**
 * Dto for data of report.
 */
export class ReportDataDto {
    public merchant_message_count: number;

    public active_promotions_count: number;

    public name: string;

    public active_coupon_count: number;

    public year: number;

    public redemptions: number;

    public nameDba: string;

    public coupons: CouponDto[];
}


export class SignupReportsDto extends PagedDto<SignupReportDto> {

}

/**
 * Dto for report.
 */
export class SignupReportDto {
  public id: number;
  public start: string;

  public end: string;

  public document: string;

  public app: string;

  public name: string;
  public status: string;
}
