import { MessagesDto } from "./dtos/message.dto";

import { Observable } from "rxjs/Rx";
import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import * as moment from "moment";

import { environment } from "../../../../environments/environment";
import { TokenStorageService } from "../token-storage.service";
import { AuthService } from "../auth/auth.service";
import { MessageMapper } from "./mappers/message.mapper";
import { NewMessageMapper } from "./mappers/new-message.mapper";
import { NewAutoMessageMapper } from "./mappers/new-auto-message.mapper";
import { ErrorHandlerService } from "../error-handler/error-handler.service";
import { ServerErrorMapper } from "../error-handler/mappers/server-error.mapper";
import { TimeInclude } from "../time-inclusion.enum";
import { Message } from "../../models/message";
import { NewMessage } from "../../models/new-message";
import { NewMessageDto } from "./dtos/new-message.dto";
import { ServerError } from "../../models/server-error";
import { ServerErrorDto } from "../error-handler/dtos/server-error.dto";
import { NewAutoMessage } from "../../models/new-auto-message";
import { NewAutoMessageDto } from "./dtos/new-auto-message.dto";
import {HttpClient, HttpEvent} from "@angular/common/http";
import {checkImageMimeType} from "../../../../utils/others";

const MERCHANT_URL_PART = "merchants";
const MESSAGES_URL_PART = "messages";

/**
 * Service for merchant's messages.
 */
@Injectable()
export class MessagesService {
  /**
   * .ctor
   */
  constructor(
    private http: Http,
    private http_client: HttpClient,
    private tokenService: TokenStorageService,
    private userService: AuthService,
    private messageMapper: MessageMapper,
    private newMessageMapper: NewMessageMapper,
    private newAutoMessageMapper: NewAutoMessageMapper,
    private errorHandlerService: ErrorHandlerService,
    private serverErrorMapper: ServerErrorMapper
  ) {}

  /**
   * Returns collection of Message
   * @see Message
   * @param merchantId Id of merchant or null if we haven't id
   */
  public async getMessages(
    merchantId: number,
    startDate: Date = null,
    endDate: Date = null,
    locationIds: string[] = null,
    timeInclude: TimeInclude = TimeInclude.None
  ): Promise<Message[]> {
    let startDateString = null;
    let endDateString = null;
    let endDatePlus = null;

    if (startDate !== null && endDate !== null) {
      startDateString = moment(startDate).format("YYYY-MM-DD");
      endDateString = moment(endDate).format("YYYY-MM-DD");
      endDatePlus = moment(endDate).add(1, "day").format("YYYY-MM-DD");
    }

    let queryString = "";
    if (
      timeInclude === TimeInclude.None &&
      startDate !== null &&
      endDate !== null
    ) {
      queryString =
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${MESSAGES_URL_PART}` +
        `/?ordering=type,-date`; //start_lte=${endDatePlus}&start_gte=${startDateString}`;
    } /* else if (timeInclude === TimeInclude.End) {
      startDateString = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
      endDateString = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
      endDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
      queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${MESSAGES_URL_PART}` +
        `/?end_gte=${startDateString}&end_lte=${startDatePlus}&start_lte=${startDateString}`;
    } else if (timeInclude === TimeInclude.Start) {
      queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${MESSAGES_URL_PART}` +
        `/?start_gte=${startDateString}&start_lte=${startDatePlus}&end_gte=${startDateString}`;
    } */ else {
      queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${MESSAGES_URL_PART}/`;
    }

    if (locationIds && locationIds.length > 0) {
      let querySymbol = "?";

      if (startDate !== null && endDate !== null) {
        querySymbol = "&";
      }

      queryString += `${querySymbol}location=` + locationIds;
    }

    return await this.http
      .get(queryString, { headers: this.tokenService.baseHeaders })
      .map((r) => r.json() as MessagesDto)
      .map((r) => r.results.map((m) => this.messageMapper.mapToModel(m)))
      .toPromise();
  }

  /**
   * Returns collection of Message
   * @param {number} merchantId Merchant id.
   * @param {number} messageId Message id.
   */
  public getMessageInfo(
    merchantId: number,
    messageId: number
  ): Promise<NewMessage> {
    return this.http
      .get(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${MESSAGES_URL_PART}/${messageId}/`,
        { headers: this.tokenService.baseHeaders }
      )
      .map((r) => r.json() as NewMessageDto)
      .map((r) => this.newMessageMapper.mapToModel(r))
      .toPromise();
  }

  public createMessageWithMedia(
    merchantId: number,
    newMessage: NewMessage
  ): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('title', newMessage.title);
    formData.append('message', newMessage.message);
    formData.append('audience_count', newMessage.audienceCount.toString());
    formData.append('action_text', newMessage.actionText);
    formData.append('action_url', newMessage.actionURL);
    formData.append('action_phone', newMessage.actionPhone);
    formData.append('action_email', newMessage.actionEmail);
    formData.append('is_enabled', true+"");
    formData.append('is_concierge', (newMessage.actionText === "Concierge Contact") + "");
    if (newMessage.video) {
      formData.append("video", newMessage.video);
    }
    if (newMessage.image) {
      const imgExt = checkImageMimeType(newMessage.image);
      if (imgExt === "unknown") {
        return Observable.throw("Unknown image type");
      }
      formData.append("image", newMessage.image, `campaign_image.${imgExt}`);
    }

    const urlPath = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/messages/`;
    return this.http_client.post<HttpEvent<any>>(urlPath, formData, {
      reportProgress: true,
      observe: "events",
      headers: this.tokenService.baseHttpClientHeaders
    });
  }

  /**
   * Create new message.
   * @param {number} merchantId Merchant id.
   * @param {NewMessage} newMessage New message domain model.
   */
  public createMessage(
    merchantId: number,
    newMessage: NewMessage
  ): Promise<NewMessage | ServerError> {
    const body = {
      title: newMessage.title,
      message: newMessage.message,
      audience_count: newMessage.audienceCount,
      action_text: newMessage.actionText,
      action_url: newMessage.actionURL,
      action_phone: newMessage.actionPhone,
      action_email: newMessage.actionEmail,
      is_concierge: (newMessage.actionText === "Concierge Contact") + "",
    };

    return this.http
      .post(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/messages/`,
        body,
        { headers: this.tokenService.baseHeaders }
      )
      .map((r) => r.json() as NewMessageDto)
      .map((r) => this.newMessageMapper.mapToModel(r))
      .catch((error) => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  /**
   * Create auto message.
   * @param {number} merchantId Merchant id.
   * @param {NewMessage} newMessage New message domain model.
   */
  public getAutoMessage(
    merchantId: number,
    messageId: number
  ): Promise<NewAutoMessage | ServerError> {
    return this.http
      .get(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/auto-messages/${messageId}/`,
        { headers: this.tokenService.baseHeaders }
      )
      .map((r) => r.json() as NewAutoMessageDto)
      .map((r) => this.newAutoMessageMapper.mapToModel(r))
      .catch((error) => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  /**
   * Create auto message.
   * @param {number} merchantId Merchant id.
   * @param {NewMessage} newMessage New message domain model.
   */
  public getAutoMessages(
    merchantId: number
  ): Promise<NewAutoMessage[] | ServerError> {

    return this.http
      .get(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/auto-messages/`,
        { headers: this.tokenService.baseHeaders }
      )
      .map((r) => r.json())
      .map((r) => r.results.map((m) => this.newAutoMessageMapper.mapToModel(m)))
      .catch((error) => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  public createAutoMessageWithMedia(
    merchantId: number,
    newMessage: NewAutoMessage
  ): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('title', newMessage.title);
    formData.append('message_type', "A");
    formData.append('message', newMessage.message);
    formData.append('audience_count', newMessage.audienceCount.toString());
    formData.append('action_text', newMessage.actionText);
    formData.append('action_url', newMessage.actionURL);
    formData.append('auto_time_delta', newMessage.auto_time_delta.toString());
    formData.append('is_enabled', true+"");
    formData.append('action_phone', newMessage.actionPhone);
    formData.append('action_email', newMessage.actionEmail);
    formData.append('is_concierge', (newMessage.actionText === "Concierge Contact") + "");
    if (newMessage.video && typeof newMessage.video !== "string") {
      formData.append("video", newMessage.video);
    }
    if (newMessage.image && typeof newMessage.image !== "string") {
      const imgExt = checkImageMimeType(newMessage.image);
      if (imgExt === "unknown") {
        return Observable.throw("Unknown image type");
      }
      formData.append("image", newMessage.image, `campaign_image.${imgExt}`);
    }

    const urlPath = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/auto-messages/`;
    return this.http_client.post<HttpEvent<any>>(urlPath, formData, {
      reportProgress: true,
      observe: "events",
      headers: this.tokenService.baseHttpClientHeaders
    });
  }

  /**
   * Create auto message.
   * @param {number} merchantId Merchant id.
   * @param {NewMessage} newMessage New message domain model.
   */
  public createAutoMessage(
    merchantId: number,
    newMessage: NewAutoMessage
  ): Promise<NewAutoMessage | ServerError> {
    const body = {
      title: newMessage.title,
      message_type: "A",
      message: newMessage.message,
      audience_count: newMessage.audienceCount,
      action_text: newMessage.actionText,
      action_url: newMessage.actionURL,
      action_phone: newMessage.actionPhone,
      action_email: newMessage.actionEmail,
      is_concierge: (newMessage.actionText === "Concierge Contact") + "",
      auto_time_delta: newMessage.auto_time_delta,
    };

    return this.http
      .post(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/auto-messages/`,
        body,
        { headers: this.tokenService.baseHeaders }
      )
      .map((r) => r.json() as NewAutoMessageDto)
      .map((r) => this.newAutoMessageMapper.mapToModel(r))
      .catch((error) => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  /**
   * Create auto message.
   * @param {number} merchantId Merchant id.
   * @param {NewMessage} newMessage New message domain model.
   */
  public updateAutoMessage(
    merchantId: number,
    newMessage: NewAutoMessage
  ): Promise<NewAutoMessage | ServerError> {
    const body = {
      id: newMessage.id,
      title: newMessage.title,
      message: newMessage.message,
      audience_count: newMessage.audienceCount,
      action_text: newMessage.actionText,
      action_url: newMessage.actionURL,
      action_phone: newMessage.actionPhone,
      action_email: newMessage.actionEmail,
      is_concierge: (newMessage.actionText === "Concierge Contact") + "",
      auto_time_delta: newMessage.auto_time_delta,
    };

    return this.http
      .put(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/auto-messages/${newMessage.id}/`,
        body,
        { headers: this.tokenService.baseHeaders }
      )
      .map((r) => r.json() as NewAutoMessageDto)
      .map((r) => this.newAutoMessageMapper.mapToModel(r))
      .catch((error) => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  public updateAutoMessageWithMedia(
    merchantId: number,
    newMessage: NewAutoMessage
  ): Observable<HttpEvent<any>> {

    const formData = new FormData();
    formData.append('title', newMessage.title);
    formData.append('message', newMessage.message);
    formData.append('audience_count', newMessage.audienceCount.toString());
    formData.append('action_text', newMessage.actionText);
    formData.append('action_url', newMessage.actionURL);
    formData.append('auto_time_delta', newMessage.auto_time_delta.toString());
    formData.append('is_enabled', true+"");
    formData.append('action_phone', newMessage.actionPhone);
    formData.append('action_email', newMessage.actionEmail);
    formData.append('is_concierge', (newMessage.actionText === "Concierge Contact") + "");
    if (newMessage.video && typeof newMessage.video !== "string") {
      formData.append("video", newMessage.video);
    }
    if (newMessage.image && typeof newMessage.image !== "string") {
      const imgExt = checkImageMimeType(newMessage.image);
      if (imgExt === "unknown") {
        return Observable.throw("Unknown image type");
      }
      formData.append("image", newMessage.image, `campaign_image.${imgExt}`);
    }

    const urlPath = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/auto-messages/${newMessage.id}/`;
    return this.http_client.patch<HttpEvent<any>>(urlPath, formData, {
      reportProgress: true,
      observe: "events",
      headers: this.tokenService.baseHttpClientHeaders
    });
  }

  /**
   * Create auto message.
   * @param {number} merchantId Merchant id.
   * @param {NewMessage} newMessage New message domain model.
   */
  public stopAutoMessage(
    merchantId: number,
    messageId: number
  ): Promise<NewAutoMessage | ServerError> {
    const body = {};

    return (
      this.http
        .post(
          `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/auto-messages/${messageId}/disable/`,
          body, { headers: this.tokenService.baseHeaders }
        )
        .map((r) => r.json() as NewAutoMessageDto)
        .map((r) => this.newAutoMessageMapper.mapToModel(r))
        .catch((error) => {
          const errorDto = error.json() as ServerErrorDto;
          return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
        })
        .toPromise()
    );
  }
}
