import * as moment from 'moment';

import {CouponDto} from './dto/coupon-dto';
import {OperationHoursDto} from './dto/operation-hours-dto';


import {defaultRadiusesInMile, defaultRadiusesInKiloMeter, padRight} from '../../../../utils/index';
import {Coupon, Schedule, Promotion, Subscription, EventCoupon} from '../../../core';
import {PromoCode} from '../../../core/models/promo-code';
import {TargetMarketingVm} from '../../event-marketing/event-marketing';
import {RedemptionOptionsVm} from '../../event-options/event-options';
import {EventStyleVm} from '../../event-style/event-style';

const FOOT_PER_MILE = 5280;
const FOOT_PER_KM = 3280;

/**
 * It is class contain methods for mapping campaign models.
 */
export class CampaignMapper {
  /**
   * Convert the model schedule to the DTO operation hours.
   * @param schedule Schedule from target-marketing model.
   */
  private operationHoursMapper(schedule: Schedule): OperationHoursDto {
    const operationHours: OperationHoursDto = {
      monday_start: null,
      monday_end: null,
      tuesday_start: null,
      tuesday_end: null,
      wednesday_start: null,
      wednesday_end: null,
      thursday_start: null,
      thursday_end: null,
      friday_start: null,
      friday_end: null,
      saturday_start: null,
      saturday_end: null,
      sunday_start: null,
      sunday_end: null,
    };

    for (const day in schedule) {
      if (schedule.hasOwnProperty(day)) {
        const startTime = `${day}_start`;
        const endTime = `${day}_end`;

        operationHours[startTime] = schedule[day].start;
        operationHours[endTime] = schedule[day].end;
      }
    }

    return operationHours;
  }

  /**
   * Convert the coupon model to the DTO.
   * @param coupon Coupon model.
   * @param merchant Merchant model.
   */
  public convertCouponToDto(coupon: EventCoupon, merchant): CouponDto {
    const {promoCode, campaignPlan, subscription, eventStyle} = coupon;

    const gender = eventStyle.gender !== 'B' ? eventStyle.gender : null;
    const keywords = eventStyle.keywords.split(',');
    // const radius = Math.floor(eventStyle.radius.key * (merchant.address.country === 'Canada' ? FOOT_PER_KM : FOOT_PER_MILE));
    const radius = Math.floor(eventStyle.radius.key * FOOT_PER_MILE);
    const inStoreInstruction = eventStyle.storeCouponInstructions;
    const contactPhone = eventStyle.contactInformationPhone;
    const contactText = eventStyle.contactInformationText;
    const contactEmail = eventStyle.contactInformationEmail;
    const isRedeemable = eventStyle.isRedeemable;
    const onlineInstruction = eventStyle.onlineCouponInstructions;
    const terms = eventStyle.termsAndConditionsText;
    const redemptionLimit = eventStyle.storeCouponLimit === 'custom'
      ? +eventStyle.storeCouponLimitCount
      : 0;
    const operationHours = this.operationHoursMapper(eventStyle.schedule);
    const age = eventStyle.ageRange === 'custom'
      ? {
        start: eventStyle.customAgeRange.from,
        end: eventStyle.customAgeRange.to
      } : {
        start: +eventStyle.ageRange,
        end: null
      };

    const shopOptionsList = eventStyle.shopOptions;
    const shopOptions: { url_type: string, url: string, title: string }[] = [];
    shopOptionsList.map(opt => {
      let link: string = opt.url;
      if (!link) return;
      link = (link.indexOf('://') === -1) ? 'https://' + link : link;
      const title = eventStyle.shopOptionsList.find(s => s.url_type === opt.url_type);
      if (!title) return;
      shopOptions.push(Object.assign(opt, {url: link, title: title.title}));
    });

    let premium_email_states = <any[]>eventStyle.premiumEmailStates.map(s => {
      return {'state': s};
    });
    if (premium_email_states.length === 0) {
      premium_email_states = <any[]>eventStyle.premiumEmailStates.map(s => {
        return {'state': s};
      });
    }

    const campaignDto: CouponDto = {
      id: null,
      name: eventStyle.campaignMessage,
      promo_code: promoCode,
      campaign_plan: campaignPlan,
      subscription: [subscription],
      campaign_title: eventStyle.imageLabel,
      in_store: eventStyle.storeCouponCode,
      in_store_instruction: inStoreInstruction,
      online: eventStyle.onlineCouponCode,
      online_instruction: onlineInstruction,
      contact_phone: contactPhone,
      country_calling_code_phone: eventStyle.contactInformationPhoneCode,
      contact_text: contactText,
      contact_email: contactEmail,
      deal_description: eventStyle.dealDescription,
      rules: terms,
      terms: terms,
      places: eventStyle.locations,
      likes: 0,
      cilcks: 0,
      impressions: 0,
      redemptions: 0,
      budget: 0,
      start: eventStyle.dateRange.start,
      end: eventStyle.dateRange.end,
      merchantId: merchant.id,
      keywords: keywords,
      operation_hours: operationHours,
      target_audience: {
        age_start: age.start,
        age_end: age.end,
        radius: radius,
        gender: gender,
        interest: eventStyle.categories
      },
      image: eventStyle.couponImage ? eventStyle.couponImage : {image: null, id: -1, logo: null},
      video: eventStyle.couponVideo ? eventStyle.couponVideo : {video: null, id: -1, video_thumb: null},
      is_redeemable: isRedeemable,
      is_expired: false,
      in_store_redeem_limit: redemptionLimit,
      in_store_redeem_left: 0,
      online_redeem_limit: 0,
      online_redeem_left: 0,
      old_price: eventStyle.priceLabelWas,
      new_price: eventStyle.priceLabelIs,
      shop_options: shopOptions,
      in_store_image: '',
      in_store_type: eventStyle.inStoreCouponType,
      premium_states: <any[]>eventStyle.premiumStates.map(s => {
        return {'state': s};
      }),
      premium_email_states: premium_email_states,
    };

    return campaignDto;
  }


  /**
   * Convert DTO to the model.
   * @param dto Coupon DTO
   */
  public convertCouponDtoToModel(dto: CouponDto): EventCoupon {
    const coupon = {
      promoCode: this.getPromoCode(dto),
      campaignPlan: this.getCampaignPlan(dto),
      subscription: this.getSubscription(dto),
      eventStyle: this.getEventStyle(dto),
    } as EventCoupon;

    return coupon;
  }

  /**
   * Convert DTO to the Campaign style model.
   * @param dto Coupon DTO.
   */
  private getCampaignPlan(dto: CouponDto): number {
    return dto.campaign_plan;
  }

  private getSubscription(dto: CouponDto): Subscription {
    return dto.subscription[0];
  }

  private getPromoCode(dto: CouponDto): number {
    return dto.promo_code;
  }

  /**
   * Convert DTO to the Campaign style model.
   * @param dto Coupon DTO.
   */
  private getEventStyle(dto: CouponDto): EventStyleVm {
    const model = new EventStyleVm();
    model.id = dto.id;
    model.campaignMessage = dto.name;
    model.imageLabel = dto.campaign_title;
    model.isCustomImage = dto.campaign_title.length === 0;
    model.couponImage = dto.image ? dto.image : {image: null, id: -1, logo: null};

    if (dto.old_price != null) {
      model.isShowPrice = true;
      model.priceLabelWas = dto.old_price;
      model.priceLabelIs = dto.new_price;
    }

    if (dto.target_audience.radius !== null) {
      const dtoRadiusInFeet = +dto.target_audience.radius;
      const {radius, radiusValue} = this.getRadius(dtoRadiusInFeet);

      model.customAgeRange.from = dto.target_audience.age_start;
      model.customAgeRange.to = dto.target_audience.age_end;
      model.ageRange = dto.target_audience.age_end ? 'custom' : `${dto.target_audience.age_start}`;
      model.gender = dto.target_audience.gender || 'B'; // If gender is 'null' it's mean 'Both'
      model.radius = {
        key: radius.miles,
        value: radiusValue,
      };
    }

    model.dateRange = {
      start: moment(dto.start).toDate(),
      end: moment(dto.end).toDate()
    };

    model.couponVideo = dto.video ? dto.video : {video: null, id: -1, video_thumb: null};
    model.dealDescription = dto.deal_description;
    dto.premium_states.map(s => model.premiumStates.push(s['state']));

    model.locations = dto.places;


    model.keywords = dto.keywords.join();

    model.isDefineHours = this.getScheduleType(dto.operation_hours);

    model.schedule = {
      monday: {
        start: dto.operation_hours.monday_start,
        end: dto.operation_hours.monday_end
      },
      tuesday: {
        start: dto.operation_hours.tuesday_start,
        end: dto.operation_hours.tuesday_end
      },
      wednesday: {
        start: dto.operation_hours.wednesday_start,
        end: dto.operation_hours.wednesday_end
      },
      thursday: {
        start: dto.operation_hours.thursday_start,
        end: dto.operation_hours.thursday_end
      },
      friday: {
        start: dto.operation_hours.friday_start,
        end: dto.operation_hours.friday_end
      },
      saturday: {
        start: dto.operation_hours.saturday_start,
        end: dto.operation_hours.saturday_end
      },
      sunday: {
        start: dto.operation_hours.sunday_start,
        end: dto.operation_hours.sunday_end
      },
    };
    model.contactInformationPhone = dto.contact_phone;
    model.contactInformationPhoneCode = dto.country_calling_code_phone;
    model.contactInformationText = dto.contact_text;
    model.contactInformationEmail = dto.contact_email;
    model.termsAndConditionsText = dto.terms;


    const couponLimit = dto.in_store_redeem_limit === 0
      ? 'unlimited'
      : 'custom';

    model.feature = 'coupon';
    model.isRedeemable = dto.is_redeemable;
    model.storeCouponCode = dto.in_store;
    model.onlineCouponCode = dto.online;
    model.storeCouponLimit = couponLimit;
    model.storeCouponLimitCount = dto.in_store_redeem_limit;
    model.storeCouponInstructions = dto.in_store_instruction;
    model.onlineCouponInstructions = dto.online_instruction;
    model.inStoreCouponType = <'text' | 'qrcode' | 'barcode'>dto.in_store_type;

    model.inStoreImage = {
      base64: dto.in_store_image,
      blob: null,
      name: 'In Store Code'
    };

    model.shopOptions = dto.shop_options;
    while (model.shopOptions.length < 2) {
      model.shopOptions.push({url_type: '', url: '', title: ''});
    }
    // dto.shop_options.map(opt => {
    //   model.enabledShopOptions[opt.url_type] = true;
    //   model.selectedShopOptions[opt.url_type] = opt.url;
    // });

    dto.premium_email_states.map(s => model.premiumEmailStates.push(s['state']));


    return model;
  }

  private getRadius(dtoRadiusInFeet) {
    const limitValueForOutputInFeet = 800;

    const radius_in_miles = defaultRadiusesInMile().find(defaultRadius => defaultRadius.feets === dtoRadiusInFeet);
    /*if(!radius_in_miles) {
      const radius_in_km = defaultRadiusesInKiloMeter().find(defaultRadius => defaultRadius.feets === dtoRadiusInFeet);
      const radiusValue = dtoRadiusInFeet > limitValueForOutputInFeet
        ? `${radius_in_km.miles} KM`
        : `${radius_in_km.feets} feet`;
      return { radius: radius_in_km, radiusValue: radiusValue };
    }*/

    const feetsPadded = padRight(radius_in_miles.pad, `${radius_in_miles.feets} feet`, `&nbsp;`);
    const milesPadded = padRight(radius_in_miles.pad, `${radius_in_miles.miles} miles`, `&nbsp;`);

    const radiusValue = dtoRadiusInFeet > limitValueForOutputInFeet
      ? `${milesPadded} / ${radius_in_miles.kms} km`
      : `${feetsPadded} / ${radius_in_miles.kms} km`;
    return {radius: radius_in_miles, radiusValue: radiusValue};
  }

  private getScheduleType(operationHours: OperationHoursDto): string {
    const isDefaultStartTime = Object.keys(operationHours)
      .filter(k => k.search(/_start/i) !== -1)
      .every(k => operationHours[k] === '00:00:00');

    const isDefaultEndTime = Object.keys(operationHours)
      .filter(k => k.search(/_end/i) !== -1)
      .every(k => operationHours[k] === '23:59:00');

    if (isDefaultStartTime && isDefaultEndTime) {
      return 'all';
    }

    return 'define';
  }
}
