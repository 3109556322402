<div class="plan-box">
  <div class="plan">
    <div class="plan-title blue">
      <span>
        {{availablePlans[1].title}} - ${{ freeRate() }}
      </span>
<!--      <span style='color:red; text-decoration:line-through;'>-->
<!--        <span style="color: white;">-->
<!--          ${{ freeRate() }}-->
<!--        </span>-->
<!--      </span>-->
      <span class="text-white">Per Ad /<br> Per Month</span>
    </div>
    <div class="shop-button">
      <button (click)="selectPlan($event, availablePlans[1])" class="select-button">
        <!--        <pb-icon name="#iconCorrectBlack" class="icon"></pb-icon> -->
        Start Now
      </button>
    </div>
    <ul class="plan-features">
      <!--      <div class="plan-cost">-->
      <!--        <span class="plan-price">Free*</span>-->
      <!--        <span style='color:red; text-decoration:line-through;'>-->
      <!--          <span class="plan-price">-->
      <!--            $ {{freeRatePart(0)}}.-->
      <!--            <span style="font-size: 10px; color: black; text-decoration: none !important;">{{freeRatePart(1)}}</span>-->
      <!--          </span>-->
      <!--        </span>-->
      <!--      </div>-->
      <!--      <div class="text">per ad / per month</div>-->
    </ul>
    <div class="plan_details">
      <ul>
        <li>Geo-Targeted Campaigns</li>
        <li>Drive direct bookings and retain 100% of your Revenues</li>
        <li>E-commerce link to drive customers to your booking page</li>
        <li>Customize your message and promote your brand</li>
        <li>Displaying on "All" category</li>
        <li>Include a video or image</li>
        <li>Create a special offer or announcement</li>
        <li>Keyword Search</li>
        <li>Send Push Alerts Messages to followers</li>
        <li>Choose your marketing distance / geofence</li>
        <li>Add a retain Barcode / QR code</li>
      </ul>
    </div>
<!--    <div class="plan-footer">-->
<!--      *During the Covid 19 pandemic GettinLocal is offering Free local campaigns.-->
<!--    </div>-->
  </div>

  <div class="spacer"></div>

  <div class="plan">
    <div class="plan-title green">
      <span>
      {{availablePlans[0].title}} - ${{ premiumRate() }}
      </span>
      <span class="text-white">Per Ad /<br> Per Month</span>
    </div>
    <div class="shop-button">
      <button (click)="selectPlan($event, availablePlans[0])" class="select-button">
        <!--        <pb-icon name="#iconCorrectBlack" class="icon"></pb-icon>-->
        Start Now
      </button>
      <!--      <div style="font-size: 12px; font-weight: bold; color: green; margin-top: 5px;">Recommended Package</div>-->
    </div>
    <ul class="plan-features">
      <!--      <div class="plan-cost">-->
      <!--        <span class="plan-price">-->
      <!--          $ {{premiumRatePart(0)}}.-->
      <!--          <span style="font-size: 10px;">{{ premiumRatePart(1)}}</span>-->
      <!--        </span>-->
      <!--      </div>-->
      <!--      <div class="text">per ad / per month</div>-->
    </ul>
    <div class="plan_details">
      <ul>
        <li class="b">Geo-Targeted Campaigns</li>
        <li class="b">Drive direct bookings and retain 100% of your Revenues</li>
        <li class="b">Featured multiple times in the "Top Picks For You" carousels</li>
        <li class="b">Displaying on "All" category with a banner "Local Choice"</li>
        <li class="b">Multiple customer discovery points and more impressions</li>
        <li class="b">Broader geographical reach (State/Province)</li>
        <li>Create direct bookings and retail 100% of your Revenues</li>
        <li>E-commerce link to drive customers to your booking page</li>
        <li>Customize your message and promote your brand</li>
        <li>Displaying on "All" category</li>
        <li>Include a video or image</li>
        <li>Create a special offer or announcement</li>
        <li>Keyword Search</li>
        <li>Send Push Alerts Messages to followers</li>
        <li>Choose your marketing distance / geofence</li>
        <li>Add a retain Barcode / QR code</li>
      </ul>
    </div>
  </div>
</div>
<!--<div class="plan-box">-->
<!--  <div class="example">-->
<!--    <button (click)="selectPlan($event, availablePlans[1])" class="select-button">-->
<!--      <pb-icon name="#iconCorrectBlack" class="icon"></pb-icon> Select-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="spacer"></div>-->
<!--  <div class="example">-->
<!--    <button (click)="selectPlan($event, availablePlans[0])" class="select-button ">-->
<!--      <pb-icon name="#iconCorrectBlack" class="icon"></pb-icon> Select-->
<!--    </button>-->
<!--    <div style="font-size: 12px; font-weight: bold; color: green; margin-top: 5px;">Recommended Package</div>-->
<!--  </div>-->
<!--</div>-->
<!-- <div class="plan-box">
  <div class="example">
    <a href="#" target="_blank">See Example</a>
  </div>
  <div class="spacer"></div>
  <div class="example">
    <a href="#" target="_blank">See Example</a>
  </div>
</div> -->
