import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ServerError, AuthService, ErrorHandlerService } from '../core';
import { PopupService } from '../popup';

/**
 * Component for password reset.
 */
@Component({
  selector: 'pb-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private popupService: PopupService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public email: string;

  public errorEmitter = new EventEmitter<any>();

  public ngOnInit() {
  }

  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      const spinner = this.popupService.spinner();
      const response = await this.authService.recoverPassword(this.email);
      let valid = true;
      let type_of_recovery = 'reset';

      if (response instanceof ServerError) {
        this.handleServerError(response, form);
        valid = false;
      } else {
        type_of_recovery = response['type_of_recovery'];
      }
      spinner.close();

      if (valid) {
        if (type_of_recovery === 'confirmation') {
          await this.popupService.info('We have resend the activation email. ' +
            'If you didn\'t find an email, make sure you checked your junk mail/spam folder.');
        } else {
          await this.popupService.info('We created a new password for your account and sent it to email. ' +
            'If you didn\'t find an email, make sure you checked your junk mail/spam folder.');
        }
      }
    }
  }

  private handleServerError(serverError: ServerError, form: NgForm) {
    const map = {
      'email': 'email'
    };

    this.errorHandlerService.invalidateForm(form, map, serverError);
  }

}
