import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CanadaMapService } from "./canada-map.service";

@Component({
  selector: "canada-map",
  templateUrl: "./canada-map.component.html",
  styleUrls: ["./canada-map.component.css"]
})
export class CanadaMapComponent implements OnInit {
  coordinates: object;
  @Input() selectedStates: string[] = [];
  @Input() fillColor: string = "#FFFFFF";
  @Input() fillStateColor: string = "#FF9900";
  @Input() strokeColor: string = "#000000";
  @Output("onMapClick") click = new EventEmitter();

  constructor(private canadaMapService: CanadaMapService) {}

  ngOnInit() {
    this.canadaMapService
      .getCanadaMapCoordinates()
      .then(data => (this.coordinates = data));
  }

  public onCanadaMapClick(state) {
    this.click.emit(state);
  }

  public getFillStateColor($state): string {
    return this.selectedStates.includes($state) ? "#0d77f1" : "#FFFFFF";
  }

  public getTextColor($state): string {
    return this.selectedStates.includes($state) ? "#FFFFFF" : "#000000";
  }
}
