
import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdDialogRef } from '@angular/material';

@Component({
  selector: 'pb-streetnumber-popup',
  templateUrl: './streetnumber-popup.component.html',
  styleUrls: ['./streetnumber-popup.component.scss']
})
export class StreetNumberPopupComponent {

  @Input()
  public streetNumber: string;

  constructor(private dialog: MdDialogRef<StreetNumberPopupComponent>) { }

  public get numberMask(): string {
    return `^\\d+$`;
  }

  public onSubmit(form: NgForm): void {
    if (form.valid) {
      this.dialog.close(this.streetNumber);
    }
  }

  public closeDialog(event): void {
    this.dialog.close();
  }
}
