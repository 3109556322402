import { CampaignStyleVm } from '../../campaign/campaign-style/campaign-style';
import { TargetMarketingVm } from '../../campaign/target-marketing/target-marketing';
import { RedemptionOptionsVm } from '../../campaign/redemption-options/redemption-options';
import { Plans } from './plans';
import { Subscription } from './subscription';

/**
 * Coupon model.
 */
export class Coupon {

  public promoCode: number;
  public campaignPlan: number;
  public subscription: Subscription;
  /**
   * First step of coupon.
   */
  public campaignStyle: CampaignStyleVm;

  /**
   * Second step of coupon.
   */
  public targetMarketing: TargetMarketingVm;

  /**
   * Third step of coupon.
   */
  public redemptionOptions: RedemptionOptionsVm;
}
