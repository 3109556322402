import { Invoice, InvoiceItem } from '../../../models/invoice';
import { InvoiceDto } from '../dtos/invoice.dto';
import { Mapper } from '../../abstract/mapper';
import { Injectable } from '@angular/core';


/**
 * Invoice mapper.
 */
@Injectable()
export class InvoiceMapper implements Mapper<InvoiceDto, Invoice> {

    /**
     * @inheritdoc
     */
    public mapToModel(dto: InvoiceDto): Invoice {
        const model = new Invoice();
        model.chargeDate = dto.charge_date,
            model.discount = dto.discount;
        model.end = dto.end;
        model.id = dto.id;
        model.start = dto.start;
        model.status = dto.status;
        model.tax = dto.tax;
        model.total = dto.total;
        model.subtotal = dto.subtotal;
        model.campaign = dto.campaign;

        if (dto.merchant) {
            model.merchant = {
                id: dto.merchant.id,
                name: dto.merchant.name,
                name_dba: dto.merchant.name_dba
            };
        }

        if (dto.items && dto.items.length > 0) {
            model.items = dto.items.map(v => {
                const invoiceItem = new InvoiceItem();
                invoiceItem.itemType = v.type;
                invoiceItem.count = v.count;
                invoiceItem.rate = v.rate;
                invoiceItem.fees = v.fees;

                return invoiceItem;
            });
        }

        if (dto.transaction) {
            model.transaction = {
                status: dto.transaction.status,
                rejectReason: dto.transaction.reject_reason,
                gatewayRejectionReason: dto.transaction.gateway_rejection_reason,
                creditCard: dto.transaction.credit_card
            };
        }

        return model;
    }
}
