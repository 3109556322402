<h2 class="section-title">
  <div class="tooltip-title">
    Payment
  </div>
</h2>
<section class="container">
  <div body>
    <div class="row">
      <div *ngIf="paymentRequired; else normal_publish" class="left-right">
        <div class="finalize-left">
          <div class="promo_code_container" *ngIf="!isFree()">
            <div class="text" style="font-size: 16px;">Enter Promo Code Here</div>
            <div class="promo_code">
              <form (ngSubmit)="applyPromoCode($event)">
                <input name="promo_code" [readonly]="promoCodeValid" placeholder="Enter code" maxlength="100"
                       [(ngModel)]="promoCodeText" (ngModelChange)="promoCodeChanged($event)" />
              </form>
              <div style="color: red; margin-top: 10px;" *ngIf="promoCodeError">{{promoCodeError}}</div>
              <a href="#" *ngIf="!promoCodeValid" class="apply-button" (click)="applyPromoCode($event)">Apply Code</a>
              <a href="#" *ngIf="promoCodeValid" class="remove-button" (click)="removePromoCode($event)">Remove Code</a>
            </div>
          </div>
          <pb-cost-breakdown [campaignPlan]="campaignPlan" [promoCode]="promoCode" [customLinkCount]="customLinkCount"
            [premiumEmailStateCount]="premiumEmailStateCount" [premiumStateCount]="premiumStateCount" [brand]="brand"
            [disabled]="!canPublish" [endDate]="campaignStyle.dateRange.end" (publishCampaign)="publish()"
            [currency]="currencySymbol" [currencyName]="currencyName">
          </pb-cost-breakdown>
        </div>
        <div *ngIf="!bypassCard" class="payment-right">
          <pb-payment-method [isEditable]="editMode" (submitEmitter)="handleSubmit($event)" [card]="card"
            [address]="address" [states]="states" [usStates]="usStates" [canadaStates]="canadaStates"
            [country]="userCountry" [merchantId]="merchantId"></pb-payment-method>
          <br><br>
          <div class="buttons">
            <button pb-button white class="edit-button" (click)="changeMode(false)" *ngIf="editMode">Cancel</button>
            <button pb-button orange class="edit-button" (click)="changeMode(true)" *ngIf="!editMode">Edit</button>
            <button pb-button class="edit-button" (click)="submitForm($event)" *ngIf="editMode">Save</button>
          </div>¸
        </div>
      </div>
      <ng-template #normal_publish>
        <div class="finalize">
          <h3 class="subtitle">Final Confirmation</h3>
          <p class="text">Click Publish Campaign Now to go live with your campaign.</p>
          <button pb-button class="button" type="button" (click)="publish()">Publish
            Campaign Now</button>
        </div>
      </ng-template>
    </div>
  </div>
</section>
