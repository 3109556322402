import { Component, OnInit, OnDestroy } from '@angular/core';
import { MdDialog } from '@angular/material';
import { Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs/Rx';
import { Subscription } from 'rxjs/Subscription';
import {
  PlaceService,
  MessagesService,
  PromotionsService,
  AuthService,
  CampaignsService,
  Campaign,
  MerchantsPromotion,
  CouponsService,
  TimeInclude,
  User,
  Message,
} from '../core';
import { ActivitiesPopupComponent } from '../shared';
import { PopupService } from '../popup';
import { FilterBy } from '../core/services/filterby.enum';

const LOCATION_CHANGE_DELAY = 1000;
/**
 * Page component for Merchant's activities.
 */
@Component({
  selector: 'pb-activities-page',
  templateUrl: './activities-page.component.html',
  styleUrls: ['./activities-page.component.scss'],
})
export class ActivitiesPageComponent implements OnInit, OnDestroy {

  private user: User;

  private locationChangedDelay: Subscription;

  /**
   * Subscription for user's change.
   */
  private userSubscription: Subscription;

  /**
   * Count of reedemed coupons for Merchant.
   */
  private reedemedCouponsCount = '0';

  /**
   * Merchant's compaings.
   */
  public campaigns: Campaign[] = [];

  /**
   * Merchant's messages.
   */
  public messages: Message[] = [];

  /**
   * Merchant's promotions.
   */
  public promotions: MerchantsPromotion[] = [];

  /**
   * Locations for filter.
   */
  public locations: { id: string, text: string }[];

  /**
   * Selected locations for filtering.
   */
  public selectedLocations: string[];

  /**
   * Sortings for
   */
  public sortings: { id: string, text: string }[] = [
    { id: FilterBy.All.toString(), text: 'All' },
    { id: FilterBy.EndingSoon.toString(), text: 'Ending Soon' },
    { id: FilterBy.StartingSoon.toString(), text: 'Starting Soon' },
  ];

  /**
   * Selected sorting.
   */
  public selectedSorting: string;

  private routePreviousUrl: Subscription;

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.routePreviousUrl) {
      this.routePreviousUrl.unsubscribe();
    }
  }

  /**
   * .ctor
   */
  constructor(
    private messagesService: MessagesService,
    private promotionsService: PromotionsService,
    private campaignsService: CampaignsService,
    private authService: AuthService,
    private couponsService: CouponsService,
    private locationsService: PlaceService,
    private popupService: PopupService,
    private router: Router,
    private dialog: MdDialog,
  ) {
    this.routePreviousUrl = this.router.events.filter(e => e instanceof NavigationEnd)
      .subscribe((v: NavigationEnd) => {
        localStorage['previousUrl'] = v.url;
      });
  }

  /**
   * Count of active campaigns.
   */
  public get activeCampaignsCount(): number {
    return this.campaigns ? this.campaigns.filter(c => c.isActive).length : 0;
  }

  /**
   * Current date.
   * @example April 2017
   */
  public get currentDate(): string {
    return moment().format('MMMM YYYY');
  }

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {

    this.userSubscription = await this.authService.onUserChanged.subscribe(async user => {

      if (user) {

        this.user = user;

        await this.seedData();

        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }
      }
    });

  }

  private async seedData(seedFilters: boolean = true): Promise<void> {
    const spinner = this.popupService.spinner();

    await Promise.resolve();

    let startDate = new Date(moment().local().startOf('month').format());
    let endDate = new Date(moment().local().add(1, 'month').startOf('month').format());
    let includeTimeType = TimeInclude.None;

    if (this.selectedSorting === FilterBy.EndingSoon.toString()) {
      includeTimeType = TimeInclude.End;
    } else if (this.selectedSorting === FilterBy.StartingSoon.toString()) {
      includeTimeType = TimeInclude.Start;
    }

    if (includeTimeType !== TimeInclude.None) {
      startDate = new Date(moment().local().format()); // Will be modified inside of service.
      endDate = new Date(moment().local().format()); // Will be modified inside of service.
    }

    const [messages, promotions, campaigns, count, places] = await Promise.all([
      this.messagesService.getMessages(this.user.merchantId, new Date(moment().local().startOf('month').format()),
        new Date(moment().local().startOf('month').add(1, 'month').startOf('month').format()), this.selectedLocations),
      this.promotionsService.getPromotions(this.user.merchantId, startDate, endDate, this.selectedLocations, includeTimeType),
      this.campaignsService.getCampaigns(startDate, endDate, this.user.merchantId, this.selectedLocations, includeTimeType),
      this.couponsService.getRedeemed(startDate, endDate, this.user.merchantId, includeTimeType),
      this.locationsService.getPlaces(this.user.merchantId),
    ]);

    this.messages = [];
    this.promotions = [];
    this.campaigns = [];

    this.messages = messages;

    this.promotions = promotions;

    this.campaigns = campaigns;

    this.reedemedCouponsCount = count.toString();

    this.locations = places.map(o => {
      return { id: o.id, text: o.name && o.name.length > 0 ? o.name : o.address };
    });

    spinner.close();
  }

  public async locationChanged(event): Promise<void> {
    if (this.locationChangedDelay) {
      this.locationChangedDelay.unsubscribe();
    }

    this.locationChangedDelay = Observable.of(JSON.stringify(this.selectedLocations))
      .delay(LOCATION_CHANGE_DELAY)
      .subscribe(async (v): Promise<void> => {
        if (!this.compareLocations(v)) {
          await this.seedData();
        }
      });
  }

  private compareLocations(previuous: string): boolean {
    return JSON.stringify(this.selectedLocations) === previuous;
  }

  public async sortByChanged(event): Promise<void> {
    await this.seedData();
  }

  /**
   * Opening popup.
   * @param event Event on popup opening
   */
  public openPopup(event): void {
    event.preventDefault();
    this.presentDialog();
  }

  /**
   * Presenting modal window.
   */
  private presentDialog(): Promise<void> {
    const dialogRef = this.dialog.open(ActivitiesPopupComponent, {
      width: '550px',
    });
    return dialogRef.afterClosed().toPromise();
  }
}
