import {Component, OnInit, Renderer2} from '@angular/core';
import {MdDialog} from '@angular/material';
import {AuthService, PlaceService, WebApp} from '../../core';
import {ActivatedRoute, Router} from '@angular/router';
import {PopupService} from '../../popup';
import {WebappService} from '../../core/services/webapp/webapp.service';
import {WebAppPopup} from "../../core/models/webapp-popips";
import {Observable} from "rxjs/Observable";
import {Subscription} from "rxjs/Subscription";
import {LoyaltyPopupConfig} from "../../core/models/loyalty-popup-config";
import * as moment from "moment";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";

/**
 * Locations page component.
 */
@Component({
  selector: 'pb-webapp-popup-settings-page',
  templateUrl: './popups-settings-page.component.html',
  styleUrls: ['./popups-settings-page.component.scss']
})
export class PopupsSettingsPageComponent implements OnInit {
  popupForm: FormGroup;
  dataPopupOptions: any[] = [];
  tabularOptions: any[] = []; // Add options for the tabular select boxes
  dataPopups: any[] = [];

  /**
   * .ctor
   * @param renderer
   * @param {AuthService} authService Auth service.
   * @param activatedRoute
   * @param {PlaceService} placeService Place service.
   * @param webappService
   * @param dialog
   * @param router
   * @param popupService
   * @param fb
   * @param route
   */
  constructor(private renderer: Renderer2,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private placeService: PlaceService,
              private webappService: WebappService,
              private dialog: MdDialog,
              private router: Router,
              private popupService: PopupService,
              private fb: FormBuilder,
              private route: ActivatedRoute) {

    this.popupForm = this.fb.group({
      id: [null],
      data_popup: [''],
      data_popup_delay: [0, [Validators.required, Validators.min(0)]],
      show_data_popup: [false],
      show_install_popup: [false],
      install_popup_delay: [{value: 0, disabled: true}, [Validators.required, Validators.min(0)]],
      tabularFields: this.fb.array([])
    });
  }

  private routeSub: Subscription;

  /**
   * Paged items.
   */
  public pagedItems: WebAppPopup;

  /**
   * Webapps list.
   */
  public popupSettings: WebAppPopup;

  public loyaltyPopupsConfig: LoyaltyPopupConfig[];

  /**
   * Current merchant Id.
   */
  private merchantId: number;
  private webAppId: string;
  public currentWebApp: WebApp = null;
  public canControlWebApps: boolean = false;

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.routeSub = Observable.combineLatest([this.activatedRoute.paramMap, this.authService.onUserChanged])
      .subscribe(async ([params, user]) => {
        if (user) {
          this.merchantId = user.merchantId;

          this.canControlWebApps = user.merchant.webapps.length > 0;
          if (this.canControlWebApps) {
            this.currentWebApp = user.merchant.webapps.find(w => w.id === user.currentWebAppId) || user.merchant.webapps[0];
          } else {
            this.currentWebApp = null;
          }

          await this.loadPopupSettings();
          if (this.popupSettings) {
            await this.loadDataPopups();
            await this.loadLoyaltyPopups();
            await this.loadLoyaltyPopupSettings();
          }

          this.onChanges();

          if (this.routeSub) {
            this.routeSub.unsubscribe();
          }

        }
      });

    // scroll to top
    window.scrollTo(0, 0);
  }

  private async loadPopupSettings(): Promise<void> {
    try {
      this.webAppId = this.route.snapshot.params['id'];
      this.popupSettings = await this.webappService.getPopupSettings(this.webAppId);
      this.popupForm.patchValue(
        Object.assign({}, this.popupSettings, {
          show_data_popup: this.popupSettings.data_popup
        })
      );
    } catch (error) {
      console.error('Error loading popup settings:', error);
    }
  }

  loadDataPopups() {
    this.webappService.getDataPopups(this.webAppId).then(data => {
      this.dataPopupOptions = data.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    });
  }

  loadLoyaltyPopupSettings() {
    this.webappService.getLoyaltyPopupSettings(this.webAppId, this.popupSettings.id).then(data => {
      this.loyaltyPopupsConfig = data;
      this.addInitialTabularFields(); // Add an initial row with loaded options
    });
  }

  loadLoyaltyPopups() {
    this.webappService.getLoyaltyPopups(this.webAppId).then(data => {
      this.tabularOptions = data.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    });
  }

  onChanges(): void {
    this.popupForm.get('show_install_popup').valueChanges
      .subscribe(showInstallPopup => {
        const installPopupDelayControl = this.popupForm.get('install_popup_delay');
        if (showInstallPopup >= 0) {
          installPopupDelayControl.enable();
        } else {
          installPopupDelayControl.disable();
        }
      });

    this.popupForm.get('show_data_popup').valueChanges
      .subscribe(showDataPopup => {
        const dataPopupDelayControl = this.popupForm.get('data_popup_delay');
        if (showDataPopup) {
          dataPopupDelayControl.enable();
        } else {
          dataPopupDelayControl.disable();
        }

        const dataPopupSelect = this.popupForm.get('data_popup');
        if (showDataPopup) {
          dataPopupSelect.setValidators(Validators.required);
          dataPopupDelayControl.enable();
        } else {
          dataPopupSelect.clearValidators();
          dataPopupDelayControl.disable();
        }
      });
  }

  get tabularFields() {
    return this.popupForm.get('tabularFields') as FormArray;
  }

  addInitialTabularFields() {
    const existingPopups = this.loyaltyPopupsConfig.map(config => config.popup.id);

    this.loyaltyPopupsConfig.forEach(data => {
      this.addTabularField({
        selectedPopup: data.popup.id,
        startTime: data.start,
        endTime: data.end,
        delay: data.delay,
        repeat: data.repeat,
        enabled: data.is_enabled
      });
    });

    this.tabularOptions.forEach(option => {
      if (!existingPopups.includes(option.value)) {
        this.addTabularField({
          selectedPopup: option.value,
          startTime: '',
          endTime: '',
          delay: 0,
          repeat: 0,
          enabled: false
        });
      }
    });
  }

  addTabularField(data?: any) {
    const tabularField = this.fb.group({
      selectedPopup: [data ? data.selectedPopup : '', Validators.required],
      startTime: [data ? data.startTime : '', Validators.required],
      endTime: [data ? data.endTime : '', Validators.required],
      delay: [data ? data.delay : 0, [Validators.required, Validators.min(0)]],
      repeat: [data ? data.repeat : 0, [Validators.required, Validators.min(0)]],
      enabled: [data ? data.enabled : false]
    });

    this.tabularFields.push(tabularField);
    this.toggleRowDisabled(this.tabularFields.length - 1);
  }

  removeTabularField(index: number) {
    this.tabularFields.removeAt(index);
  }

  canAddTabularField(): boolean {
    const currentOptions = this.tabularFields.controls.map(control => control.get('selectedPopup').value);
    return this.tabularOptions.some(option => !currentOptions.includes(option.value));
  }

  onSubmit() {
    if (this.popupForm.valid) {
      this.onSave();
    } else {
      this.popupService.info('Please fill in all required fields');
    }
  }

  public async onSave(): Promise<void> {
    try {
      await this.webappService.savePopupSettings(this.popupSettings.id, this.webAppId, this.popupForm.value);
      await this.webappService.saveLoyaltyPopupSettings(this.webAppId, this.popupSettings.id, this.tabularFields.value);
      this.popupService.info('Settings updated.');
    } catch (e) {
      this.popupService.info('Error saving settings');
    }
  }

  public getDuration(start, end): string {
    return (
      (start ? moment(start).format("MMM D, YYYY") : "-") +
      " - \n" +
      (end ? moment(end).format("MMM D, YYYY") : "-")
    );
  }

  toggleRowDisabled(index: number) {
    const field = this.tabularFields.at(index);
    const controls = ['startTime', 'endTime', 'delay', 'repeat'];
    controls.forEach(control => {
      if (field.get('enabled').value) {
        field.get(control).enable();
      } else {
        field.get(control).disable();
      }
    });
  }

  handleDisabledClick(i: number) {
    // Enable the row
    this.tabularFields.at(i).get('enabled').setValue(true);
    // refresh the row
    this.toggleRowDisabled(i);
  }
}

function compare(a: any, b: any, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
