import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line
  selector: '[pb-section]',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent {
  @Input()
  public caption: string;

  /**
   * Show/hide material design spinner.
   */
  @Input()
  public spinner = false;
}
