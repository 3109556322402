import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { referalMask } from '../../../utils/index';
import {
  ErrorHandlerService,
  ServerErrorMapper,
  AuthService,
} from '../../core';

/**
 * Sign up page component.
 */
@Component({
  selector: 'pb-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss'],
})
export class SignupPageComponent {
  /**
   * .ctor
   * @param {Router} router Router.
   * @param {AuthService} authService Authorization service.
   */
  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private serverErrorMapper: ServerErrorMapper,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['ref']) {
        this.referral = params['ref'];
      }
    });
  }

  public first_name: string;
  public last_name: string;

  public email: string;

  public password1: string;

  public password2: string;

  public showMessage = false;

  public referral: string = null;

  public errorEmitter = new EventEmitter<any>();

  public get referalMaskPattern(): any {
    return referalMask;
  }

  private makepass(length: number): string {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      const passwd = this.makepass(16);
      try {
        await this.authService.registerAccount(
          this.first_name,
          this.last_name,
          this.email,
          passwd,
          passwd,
          this.referral
        );
        this.showMessage = true;
        // tslint:disable-next-line:forin
        for (const controlName in form.controls) {
          const control = form.controls[controlName];
          control.setValue('');
        }

        form.resetForm();
      } catch (error) {
        const body = JSON.parse(error._body);
        const serverError = this.serverErrorMapper.mapToModel(body);
        if (this.errorHandlerService.hasValidationErrors(serverError)) {
          const validationFieldsMap = {
            first_name: 'first_name',
            last_name: 'last_name',
            email: 'email',
            password1: 'password1',
            password2: 'password2',
          };

          this.errorHandlerService.invalidateForm(
            form,
            validationFieldsMap,
            serverError,
          );
        } else {
          const emailControl = form.controls['email'];
          emailControl.setErrors([serverError.detail]);
        }
      }
    }
  }
}
