import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { PlaceDto } from '../dtos/place.dto';
import { Place } from '../../../models/place';

/**
 * Place mapper.
 */
@Injectable()
export class PlaceMapper implements Mapper<PlaceDto, Place> {
  /**
   * @inheritdoc
   * @param {PlaceDto} dto Place DTO.
   */
  public mapToModel(dto: PlaceDto): Place {
    return new Place({
      country_calling_code: dto.country_calling_code,
      id: dto.id,
      forsquareId: dto.id,
      name: dto.name,
      address: dto.address,
      formattedAddress: dto.formatted_address,
      crossStreet: dto.cross_street,
      country: dto.country,
      state: dto.state,
      city: dto.city,
      zip: dto.zip,
      contactPhone: dto.contact,
      contactName: dto.contact_name,
      contactEmail: dto.contact_email,
      location: dto.location,
      categoryIds: dto.categories,
      provider: null,
      streetNumber: dto.street_number,
      street: dto.street,
      created: dto.created,
      placeIndex: dto.place_index_formatted,
      isDisplayed: dto.is_displayed,
      selected: null
    });
  }
}
