import { NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs/Rx";
import { SortBy, PaginationService, Pagination, MerchantService, TargetAudience, ServerError } from "../core";
import { Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { Subscription } from "rxjs/Subscription";
import { MdDialog, MdSort } from "@angular/material";
import { PopupResult, PopupService } from "../popup";

import {
  MessagesService,
  PromotionsService,
  AuthService,
  CampaignsService,
  Campaign,
  MerchantsPromotion,
  CouponsService,
  TimeInclude,
  PlaceService,
  InvoiceService
} from "../core";
import { User, Message } from "../core";
import { ActivitiesPopupComponent } from "../shared";
import { FilterBy } from "../core/services/filterby.enum";
import { NewAutoMessage } from "../core/models/new-auto-message";
import {EventService} from "../events/services/event-service/event.service";

const LOCATION_CHANGE_DELAY = 1000;
/**
 * Component for dashboard page.
 */
@Component({
  selector: "pb-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"]
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  private user: User;

  private locationChangedDelay: Subscription;

  /**
   * Subscription for user's change.
   */
  private userSubscription: Subscription;

  private routePreviousUrl: Subscription;

  /**
   * Count of reedemed coupons for Merchant.
   */
  public viewCount = 0;
  public reedemedCouponsCount = 0;
  public likeCount = 0;
  public clickCount = 0;

  public shouldShowBookmarkLink: boolean;
  /**
   * Balance for merchant.
   */
  public monthBalance: number;
  public monthCharges: number;

  public editMessageClicked: boolean = false;
  /**
   * Due for merchant.
   */
  public paymentDue: Date;
  public lastPayDate: string;

  public isAutoHeartDashboard: boolean = false;
  /**
   * Merchant's compaings.
   */
  public campaigns: Campaign[] = [];
  public premium_campaigns: Campaign[] = [];
  public events: Campaign[] = [];

  /**
   * Merchant's messages.
   */
  public messages: Message[] = [];
  public auto_messages: NewAutoMessage[] | ServerError = [];

  /**
   * Merchant's promotions.
   */
  public promotions: MerchantsPromotion[] = [];

  /**
   * Locations for filter.
   */
  public locations: { id: string; text: string }[];

  /**
   * Selected locations for filtering.
   */
  public selectedLocations: string[];

  private currentSort: number = SortBy.None;
  public searchString = "";
  public tableData = [];
  public eventsTableData = [];

  public pagedCampaignItems = [];
  public pagination: Pagination = new Pagination();
  public pagedMessageItems = [];
  public messagePagination: Pagination = new Pagination();

  public invoiceStartDate;
  public invoiceEndDate;
  /**
   * Sortings for
   */
  public filtering: { id: string; text: string }[] = [
    { id: FilterBy.All.toString(), text: "All" },
    { id: FilterBy.EndingSoon.toString(), text: "Ending Soon" },
    { id: FilterBy.StartingSoon.toString(), text: "Starting Soon" }
  ];

  /**
   * Sortings for
   */
  public sortings: { id: string; text: string }[] = [
    { id: SortBy.Views.toString(), text: "Views" },
    { id: SortBy.Likes.toString(), text: "Bookmarks" },
    { id: SortBy.Redeemptions.toString(), text: "Redemption" }
  ];

  /**
   * Selected sorting field which contains id.
   */
  public selectedFiltering: string;
  public selectedSorting: number = SortBy.None;
  public audiences: number;
  public new_audiences: number;
  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.routePreviousUrl) {
      this.routePreviousUrl.unsubscribe();
    }
  }

  /**
   * .ctor
   */
  constructor(
    private messagesService: MessagesService,
    private promotionsService: PromotionsService,
    private campaignsService: CampaignsService,
    private eventService: EventService,
    private authService: AuthService,
    private couponsService: CouponsService,
    private dialog: MdDialog,
    private popupService: PopupService,
    private locationsService: PlaceService,
    private invoiceService: InvoiceService,
    private paginationService: PaginationService,
    private merchantService: MerchantService,
    private couponService: CouponsService,
    private router: Router
  ) {
    this.routePreviousUrl = this.router.events
      .filter(e => e instanceof NavigationEnd)
      .subscribe((v: NavigationEnd) => {
        localStorage["previousUrl"] = v.url;
      });
  }

  public async openEndCouponPopup(id): Promise<void> {
    event.preventDefault();

    const selectedCampaign = this.campaigns.find(c => c.id === id);
    if (!selectedCampaign) {
      return;
    }
    const campaignId = selectedCampaign.id;
    const online = selectedCampaign.online;
    const in_store = selectedCampaign.in_store;

    const result = await this.popupService.confirm(
      "Are you sure you want to end this coupon?",
      "End Coupon"
    );
    if (result === PopupResult.YES) {
      const spinner = this.popupService.spinner();
      const endDate = moment()
        .add(-1, "day")
        .format();
      await this.couponService.endCoupon(
        this.user.merchant.id,
        campaignId,
        endDate,
        { in_store: in_store, online: online }
      );

      spinner.close();

      this.reloadEvents();
    }
  }

  public async openEndEventPopup(id): Promise<void> {
    event.preventDefault();

    const selectedCampaign = this.events.find(c => c.id === id);
    if (!selectedCampaign) {
      return;
    }
    const campaignId = selectedCampaign.id;
    const online = selectedCampaign.online;
    const in_store = selectedCampaign.in_store;

    const result = await this.popupService.confirm(
      "Are you sure you want to end this event?",
      "End Event"
    );
    if (result === PopupResult.YES) {
      const spinner = this.popupService.spinner();
      const endDate = moment()
        .add(-1, "day")
        .format();
      await this.couponService.endEvent(
        this.user.merchant.id,
        campaignId,
        endDate,
        { in_store: in_store, online: online }
      );

      spinner.close();

      this.reloadEvents();
    }
  }

  /**
   * Opening popup.
   * @param event Event on popup opening
   */
  public openPopup(event): void {
    event.preventDefault();
    this.presentDialog();
  }

  /**
   * Count merchant email.
   */
  public get userEmail(): string {
    return this.user ? this.user.email : "";
  }

  /**
   * Count merchant email.
   */
  public get userName(): string {
    return this.user ? this.user.firstName : "";
  }

  public get userMerchantId(): number {
    return this.user ? this.user.merchant.id : -1;
  }

  public isBetaUser(): boolean {
    return this.user ? this.user.isBetaUser : false
  }

  /**
   * Count of clicks.
   */
  public get getClickCount(): number {
    let count = 0;

    if (this.campaigns) {
      this.campaigns.map(c => count += c.clicks);
    }
    if (this.events) {
      this.events.map(c => count += c.clicks);
    }

    return count;
  }

  /**
   * Count of likes.
   */
  public get getLikeCount(): number {
    if(!this.campaigns)
      return 0;

    let count = 0;
    this.campaigns.map(c => count+= c.likes);

    return count;
  }

  /**
   * Count of audiences.
   */
  public get getFollowersCount(): number {
    if (!this.audiences) {
      return 0;
    }

      return this.audiences;
  }

  /**
   * Count of audiences.
   */
   public get getNewFollowersCount(): number {
    if (!this.new_audiences) {
      return 0;
    }

      return this.new_audiences;
  }

  /**
   * Count of audiences.
   */
  public get getAudienceCount(): string {
    return "";
    // if(!this.audiences)
    //   return "Unique followers: " + 0;

    //   return "Unique followers: " + this.audiences;
  }

  /**
   * Count of redemptions.
   */
  public get getReedemedCouponsCount(): number {
    let count = 0;

    if (this.campaigns) {
      this.campaigns.map(c => count += c.redemptions);
    }

    if (this.events) {
      this.events.map(c => count += c.redemptions);
    }

    return count;
  }

   /**
   * Count of active campaigns.
   */
    public get openMessagesCount(): number {
      if (!this.campaigns && !this.events) {
        return 0;
      }

      let count = 0;
      this.messages.map(c => count += c.views);
      return count;
    }

    /**
   * Count of active campaigns.
   */
     public get messageClickCount(): number {
      let count = 0;

      if (this.campaigns) {
        this.messages.map(c => count += c.clicks);
      }

      if (this.events) {
        this.messages.map(c => count += c.clicks);
      }

      return count;
    }


  public get allCampaignsCount(): number {
    let count = 0;

    if (this.campaigns) {
      const allCampaigns = this.campaigns.filter(c => c.isActive === true);
      count += allCampaigns.length;
    }


    if (this.events) {
      const allEvents = this.events.filter(c => c.isActive === true);
      count += allEvents.length;
    }

    return count;
  }

   /**
   * Count of active campaigns.
   */
   public get allCampaignsViewCount(): number {
     let count = 0;

     if (this.campaigns) {
       const allCampaigns = this.campaigns.filter(c => c.isActive === true);
       allCampaigns.map(c => count += c.impressions);
     }

     if (this.events) {
       const allEvents = this.events.filter(c => c.isActive === true);
       allEvents.map(c => count += c.impressions);
     }

     return count;
   }

   /**
   * Count of active campaigns.
   */
  public get activeCampaignsViewCount(): number {
     let count = 0;

     if (this.campaigns) {
       const localCampaigns = this.campaigns.filter(c => c.campaignPlan === 1);
       localCampaigns.map(c => count += c.impressions);
     }

    return count;
  }

  /**
   * Count of premium campaigns.
   */
  public get premiumCampaignsCount(): number {
    if (!this.campaigns)
      return 0;

    const localCampaigns = this.campaigns.filter(c => c.campaignPlan === 2);
    let count = 0;
    localCampaigns.map(c => count+= c.impressions);

    return count;
  }

  /**
   * Presenting modal window.
   */
  private presentDialog(): Promise<void> {
    const dialogRef = this.dialog.open(ActivitiesPopupComponent, {
      width: "550px"
    });
    return dialogRef.afterClosed().toPromise();
  }

  /**
   * Current date.
   */
  public get currentDate(): Date {
    return new Date();
  }

  /**
   * Current date.
   */
  public async bookmarkPage(e): Promise<void> {
    if (e) e.preventDefault();
    const message =
      "Press " +
      (navigator.userAgent.toLowerCase().indexOf("mac") != -1
        ? "Command/Cmd"
        : "CTRL") +
      " + D to bookmark this page.";
    const result = await this.popupService.info(message, "Bookmark This Page"); // do something
    localStorage.setItem("should_show_bookmark_link", "1");
    this.shouldShowBookmarkLink = false;
  }

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    // this.bookmarkPage(null);

    document.getElementById('content').style.background = '#f5f5f5';
    document.getElementById('page-header').style.background = '#f5f5f5';

    this.shouldShowBookmarkLink = !(
      localStorage.getItem("should_show_bookmark_link") === "1"
    );

    this.userSubscription = await this.authService.onUserChanged.subscribe(
      async user => {
        if (user) {
          this.user = user;
          this.isAutoHeartDashboard = user.merchant.isAutoHeartingDashboard();

          await this.seedData();

          if (this.userSubscription) {
            this.userSubscription.unsubscribe();
          }
        }
      }
    );
  }

  public async reloadCampaigns(): Promise<void> {
    const spinner = this.popupService.spinner();

    await Promise.resolve(); // Hack for async/await to avoid exception.

    let startDate = new Date(
      moment()
        .local()
        .startOf("month")
        .format()
    );
    let endDate = new Date(
      moment()
        .local()
        .add(1, "month")
        .startOf("month")
        .format()
    );
    let includeTimeType = TimeInclude.None;

    if (this.selectedFiltering === FilterBy.EndingSoon.toString()) {
      includeTimeType = TimeInclude.End;
    } else if (this.selectedFiltering === FilterBy.StartingSoon.toString()) {
      includeTimeType = TimeInclude.Start;
    }

    if (includeTimeType !== TimeInclude.None) {
      startDate = new Date(
        moment()
          .local()
          .format()
      ); // Will be modified inside of service.
      endDate = new Date(
        moment()
          .local()
          .format()
      ); // Will be modified inside of service.
    }

    const campaigns = await this.campaignsService.getCampaigns(
      startDate,
      new Date(
        moment()
        .local()
        .add(3, 'month')
        .format()
      ),
      this.user.merchantId,
      this.selectedLocations,
      includeTimeType
    );
    this.campaigns = campaigns;

    this.tableData = this.getMappedItems();
    this.setCampaignPage(1);

    spinner.close();
  }

  public async reloadEvents(): Promise<void> {
    const spinner = this.popupService.spinner();

    await Promise.resolve(); // Hack for async/await to avoid exception.

    let startDate = new Date(
      moment()
        .local()
        .startOf("month")
        .format()
    );
    let endDate = new Date(
      moment()
        .local()
        .add(1, "month")
        .startOf("month")
        .format()
    );
    let includeTimeType = TimeInclude.None;

    if (this.selectedFiltering === FilterBy.EndingSoon.toString()) {
      includeTimeType = TimeInclude.End;
    } else if (this.selectedFiltering === FilterBy.StartingSoon.toString()) {
      includeTimeType = TimeInclude.Start;
    }

    if (includeTimeType !== TimeInclude.None) {
      startDate = new Date(
        moment()
          .local()
          .format()
      ); // Will be modified inside of service.
      endDate = new Date(
        moment()
          .local()
          .format()
      ); // Will be modified inside of service.
    }

    const events = await this.campaignsService.getEvents(
      startDate,
      new Date(
        moment()
          .local()
          .add(3, 'month')
          .format()
      ),
      this.user.merchantId,
      this.selectedLocations,
      includeTimeType
    );
    this.events = events;
    this.eventsTableData = this.getMappedEventsItems();

    spinner.close();
  }

  /**
   * Seeding data for component.
   */
  private async seedData(): Promise<void> {
    const spinner = this.popupService.spinner();

    await Promise.resolve(); // Hack for async/await to avoid exception.

    // let startDate = new Date(
    //   moment()
    //     .local()
    //     .startOf("month")
    //     .format()
    // );
    let endDate = new Date(
      // moment()
      //   .local()
      //   .add(1, "month")
      //   .startOf("month")
      //   .format()
    );

    let  startDate = new Date(moment(endDate).subtract(1, 'M').format());
    let includeTimeType = TimeInclude.None;

    if (this.selectedFiltering === FilterBy.EndingSoon.toString()) {
      includeTimeType = TimeInclude.End;
    } else if (this.selectedFiltering === FilterBy.StartingSoon.toString()) {
      includeTimeType = TimeInclude.Start;
    }

    this.sortData(this.selectedSorting);

    if (includeTimeType !== TimeInclude.None) {
      startDate = new Date(
        moment()
          .local()
          .format()
      ); // Will be modified inside of service.
      endDate = new Date(
        moment()
          .local()
          .format()
      ); // Will be modified inside of service.
    }

    this.invoiceStartDate = moment(startDate).format("YYYY-MM-DD");
    this.invoiceEndDate = moment(endDate).add(1, 'day').format("YYYY-MM-DD");

    const [
      messages,
      auto_messages,
      promotions,
      campaigns,
      events,
      premium_campaigns,
      count,
      places,
      invoices,
      audiences,
      new_audiences
    ] = await Promise.all([
      this.messagesService.getMessages(
        this.user.merchantId,
        startDate,
        endDate,
        this.selectedLocations
      ),
      this.messagesService.getAutoMessages(
        this.user.merchantId
      ),
      this.promotionsService.getPromotions(
        this.user.merchantId,
        startDate,
        endDate,
        this.selectedLocations,
        includeTimeType
      ),
      this.campaignsService.getCampaigns(
        startDate,
        new Date(
          moment()
          .local()
          .add(3, 'month')
          .format()
        ),
        this.user.merchantId,
        this.selectedLocations,
        includeTimeType
      ),
      this.campaignsService.getEvents(
        startDate,
        new Date(
          moment()
            .local()
            .add(3, 'month')
            .format()
        ),
        this.user.merchantId,
        this.selectedLocations,
        includeTimeType
      ),
      this.campaignsService.getPremiumCampaigns(
        startDate,
        endDate,
        this.user.merchantId,
        this.selectedLocations,
        includeTimeType
      ),
      this.couponsService.getRedeemed(
        startDate,
        endDate,
        this.user.merchantId,
        includeTimeType
      ),

      this.locationsService.getPlaces(this.user.merchantId),
      this.invoiceService.getInvoices(this.user.merchantId, this.invoiceStartDate, this.invoiceEndDate),
      this.merchantService.getTargetAudienceCount(
        this.user.merchantId,
        new TargetAudience()
      ),
      this.merchantService.getNewTargetAudienceCount(
        this.user.merchantId,
        new TargetAudience()
      )
      // this.invoiceService.getCurrentMonth(this.user.merchantId)
    ]);

    this.messages = [];
    this.promotions = [];
    this.campaigns = [];
    this.events = [];

    this.messages = messages;

    this.auto_messages = auto_messages;

    this.promotions = promotions;

    this.campaigns = campaigns;
    this.events = events;

    this.audiences = audiences;
    this.new_audiences = new_audiences;

    this.premium_campaigns = premium_campaigns;

    if (count) {
      const viewc = count.find(c => c.action === "I");
      this.viewCount = viewc ? viewc.total : 0;

      const redeemc = count.find(c => c.action === "R");
      this.reedemedCouponsCount = redeemc ? redeemc.total : 0;

      const clickc = count.find(c => c.action === "S");
      this.clickCount = clickc ? clickc.total : 0;

      const likec = count.find(c => c.action === "L");
      this.likeCount = likec ? likec.total : 0;
    }

    this.paymentDue = this.user.merchant.paymentDue;

    this.eventsTableData = this.getMappedEventsItems();

    this.tableData = this.getMappedItems();

    this.setCampaignPage(1);
    this.setMessagePage(1);

    this.monthCharges = 0;
    invoices.map(iv => {
      this.monthCharges += iv.total !== null ? +iv.total : 0.0;
      this.lastPayDate = iv.chargeDate;
    });

    this.locations = places.map(o => {
      return {
        id: o.id,
        text: o.name && o.name.length > 0 ? o.name : o.address
      };
    });

    this.sortData(this.currentSort);

    spinner.close();
  }

  /**
   * Reseeding data after location change.
   * @param event Event on location ddl changed.
   */
  public async locationChanged(event): Promise<void> {
    if (this.locationChangedDelay) {
      this.locationChangedDelay.unsubscribe();
    }

    this.locationChangedDelay = Observable.of(
      JSON.stringify(this.selectedLocations)
    )
      .delay(LOCATION_CHANGE_DELAY)
      .subscribe(
        async (v): Promise<void> => {
          if (!this.compareLocations(v)) {
            await this.seedData();
          }
        }
      );
  }

  private compareLocations(previuous: string): boolean {
    return JSON.stringify(this.selectedLocations) === previuous;
  }

  public searchStringChanged(event): void {
    this.adjustPlacesData(this.currentSort, event, 1);
  }

  /**
   * Reseeding data by changed sort by.
   * @param event Event which fired after sort by ddl changed.
   */
  public async filterByChanged(event): Promise<void> {
    await this.seedData();
  }

  public async sortByChanged(sort: number): Promise<void> {
    this.selectedSorting = sort;
    this.adjustPlacesData(sort, this.searchString, 1);
  }

  /**
   * Shows new list of table items considering table's sorting and search.
   */
  public setCampaignPageClicked(page: number): void {
    this.adjustPlacesData(this.currentSort, this.searchString, page);
  }

  public setMessagePageClicked(page: number): void {
    this.setMessagePage(page);
  }

  public setCampaignPage(page: number) {
    this.pagination = this.paginationService.getPagination(
      this.tableData.length,
      page,
    );

    this.pagedCampaignItems = this.tableData.slice(
      this.pagination.startIndex,
      this.pagination.endIndex + 1
    );
  }

  public setMessagePage(page: number) {
    this.messagePagination = this.paginationService.getPagination(
      this.messages.length,
      page,
    );

    this.pagedMessageItems = this.messages.slice(
      this.messagePagination.startIndex,
      this.messagePagination.endIndex + 1
    );
  }

  /**
   * Combines sorting, filtering and setting pagination methods.
   */
  private adjustPlacesData(
    sort: number,
    searchString: string,
    page: number
  ): void {
    this.sortData(sort);
    this.tableData = this.filterItems(this.getMappedItems(), searchString);
    this.setCampaignPage(page);
  }

  /**
   * Searches for string in table.
   */
  private filterItems(items, searchString: string): any[] {
    this.searchString = searchString;
    return items.filter(
      i =>
        i.name
          .toString()
          .toLowerCase()
          .indexOf(searchString.toLowerCase()) > -1 || searchString === ""
    );
  }

  private sortData(sort: number): void {
    this.currentSort = sort;
    const data = this.getMappedItems();

    this.tableData = data.sort((a, b) => {
      const isAsc = false;
      switch (+sort) {
        case SortBy.None:
          return compare(
            moment(a.startDate).unix(),
            moment(b.startDate).unix(),
            isAsc
          );
        case SortBy.Views:
          return compare(a.impressions, b.impressions, isAsc);
        case SortBy.Likes:
          return compare(a.likes, b.likes, isAsc);
        case SortBy.Redeemptions:
          return compare(a.redemptions, b.redemptions, isAsc);
        default:
          console.error("default");

          return 0;
      }
    });
  }

  private getMappedItems(): any[] {
    const campaigns = this.campaigns.map(campaign => {
      return {
        id: campaign.id,
        name: campaign.name,
        places: campaign.places,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        logo: campaign.image ? campaign.image.logo : "",
        video: campaign.video ? campaign.video.video_thumb : "",
        ended: campaign.isCompleted,
        impressions: campaign.impressions,
        clicks: campaign.clicks,
        likes: campaign.likes,
        in_store: campaign.in_store,
        online: campaign.online,
        featured: campaign.campaignPlan == 2,
        redemptions: campaign.redemptions
      };
    });

    return campaigns;
  }

  private getMappedEventsItems(): any[] {
    const campaigns = this.events.map(campaign => {
      return {
        id: campaign.id,
        name: campaign.name,
        places: campaign.places,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        logo: campaign.image ? campaign.image.logo : "",
        video: campaign.video ? campaign.video.video_thumb : "",
        ended: campaign.isCompleted,
        impressions: campaign.impressions,
        clicks: campaign.clicks,
        likes: campaign.likes,
        in_store: campaign.in_store,
        online: campaign.online,
        featured: campaign.campaignPlan == 2,
        redemptions: campaign.redemptions
      };
    });

    return campaigns;
  }

  /**
   * getMessageStatus
   */
  public getMessageStatus(active): string {
    if (!active) return "Stopped";
    return "Active";
  }

  /**
   * getStatus
   */
  public getStatus(active): string {
    if (!active) return "Ended";
    return "Active";
  }

  public getDuration(start, end): string {
    return (
      moment(start).format("MMM D, YYYY") +
      " - \n" +
      moment(end).format("MMM D, YYYY")
    );
  }

  public getStartDate(start): string {
    return (
      moment(start).format("MMMM D, YYYY")
    );
  }

  public getEndDate(end): string {
    return (
      moment(end).format("MMMM D, YYYY")
    );
  }

  public editCoupon(index, type): void {
    this.router.navigate(["edit-campaign", index], {
      queryParams: { type: "coupon" }
    });
  }

  public editEvent(index, type): void {
    this.router.navigate(["edit-event", index], {
      queryParams: { type: "event" }
    });
  }

  public isNoCampaigns(): boolean {
    return this.campaigns.length === 0;
  }

  public isNoMessages(): boolean {
    return this.messages.length === 0 && (this.auto_messages as NewAutoMessage[]).length === 0;
  }

  public editAutoMessage(index): void {
    this.editMessageClicked = true;
    this.router.navigate(["auto-messages", index]);
  }

   /**
   * Make activity item active.
   */
  public makeActive(viewUrl: string): void {
    if (viewUrl && !this.editMessageClicked) {
      this.router.navigateByUrl(viewUrl);
    }
  }
}

function compare(a: any, b: any, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
