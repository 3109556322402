import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line
  selector: '[pb-section-required]',
  templateUrl: './section-required.component.html',
  styleUrls: ['./section-required.component.scss']
})
export class SectionRequiredComponent {
  @Input()
  public caption: string;

  /**
   * Show/hide material design spinner.
   */
  @Input()
  public spinner = false;
}
