<div class="text-container">
  <header class="header">
    <p class="header-line" *ngIf="showTitle">GETTINLOCAL</p>
    <p class="header-line">TERMS OF USE</p>
  </header>
  <p class="text">
    PaeBac, LLC (d/b/a GettinLocal) and its affiliates (collectively,
    &ldquo;
    <span class="underline">GettinLocal</span>,&rdquo; &ldquo;
    <span class="underline">we</span>,&rdquo; &ldquo;
    <span class="underline">us</span>,&rdquo; or &ldquo;
    <span class="underline">our</span>&rdquo;) own or license all right, title, and interest in and to the website
    located at
    <a href="http://www.ClearDoc.com/">www.GettinLocal.com</a>
    (the &ldquo;
    <span class="underline">Website</span>&rdquo;) and any related software, service and/or mobile application available
    thereon
    (the &ldquo;
    <span class="underline">App</span>,&rdquo; and collectively with the Website, the &ldquo;
    <span class="underline">Services</span>&rdquo;). We have established the following terms and conditions (the &ldquo;
    <span class="underline">Agreement</span>&rdquo;) with which you must comply when you access or use the Services. All
    references
    to &ldquo;
    <span class="underline">you</span>&rdquo; or &ldquo;
    <span class="underline">your</span>&rdquo; refer to you and the entity on behalf of which you use the Services.
  </p>
  <p class="text">
    BY ACCESSING THE SERVICE OR SUBMITTING CONTENT (AS DEFINED BELOW),
    YOU AGREE TO THE TERMS AND CONDITIONS CONTAINED
    IN THIS AGREEMENT. IF YOU DO NOT ACCEPT THESE TERMS AND CONDITIONS, DO NOT USE OR ACCESS, OR SUBMIT
    CONTENT
    TO, THE SERVICES.
  </p>
  <p class="text">
    From time to time, GettinLocal may without advance notice: (a)
    supplement or make changes to this Agreement
    and other rules or access and use procedures, documentation, security procedures and standards for
    equipment
    related to the Services, or (b) modify or withdraw any Service. All changes to the Agreement will be
    posted
    on this page and are effective as of the date they are posted. Accordingly, you should periodically
    visit
    this page to review the terms of this Agreement. Your use of the Services after a modification to this
    Agreement
    constitutes your acceptance of the modification.
  </p>
  <p class="text">
    You should also review our Privacy Policy
    <a href="http://www.cleardoc.com/privacy">http://www.GettinLocal.com/privacy</a>, the terms of which
    are incorporated herein by reference and made
    a part of this Agreement.
  </p>
  <ol class="list">
    <li class="list-item">
      <header class="list-item-header">OWNERSHIP</header>
      <div class="list-item-content">
        <p class="text">GettinLocal and its licensors and suppliers own all information,
          text, reports, analyses, data, graphics, images,
          sound recordings, audio and visual clips, User Content (as defined below) photographs, programs,
          applications,
          software, and other content (
          <u>except</u> User Content as defined below) contained in the Services, as well as the collection,
          design, production,
          selection and arrangement thereof (collectively, the &ldquo;
          <u>GettinLocal</u>
          <u> Material</u>&rdquo;). We may incorporate third party software (including open source software) as
          part of
          the Services, and all such third party software is subject to additional terms (if any) provided by the
          third
          party licensor. The names, marks, and logos appearing in the Services (collectively, the &ldquo;
          <u>Trademarks</u>&rdquo;) are owned by or licensed to GettinLocal and are used with permission of the
          owner.
          The GettinLocal Materials are protected by trademark, copyright, and other intellectual property laws.
          GettinLocal
          requires you to respect the intellectual property rights that we have in the GettinLocal Material and
          in
          the Trademarks. </p>
        <p class="text">
          You acknowledge GettinLocal&rsquo;s proprietary rights in the
          Service and associated documentation and shall
          protect the proprietary nature thereof. If you suggest any new features, functionality or performance
          for
          the Service that GettinLocal subsequently incorporates into the Service (or any other software or
          service),
          you hereby acknowledges that (i) GettinLocal shall own, and has all rights to use, such suggestions and
          the
          Service (or any other service) incorporating such new features, functionality, or performance shall be
          the
          sole and exclusive property of GettinLocal and (ii) all such suggestions shall be free from any
          confidentiality
          restrictions that might otherwise be imposed upon GettinLocal.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">LICENSE</header>
      <div class="list-item-content">
        <p class="text">
          GettinLocal hereby grants you a limited, non-exclusive, revocable,
          non-sublicensable, non-transferable license
          to view and use the Services in accordance with the terms of this Agreement. If you are a merchant
          subscriber
          to our fee-based services provided by GettinLocal via the Services, then this license is contingent
          upon
          your payment of any applicable subscription fees and your compliance with any other terms and
          conditions
          applicable to you as a subscriber. You agree to retain all proprietary notices on any copy you make of
          the
          GettinLocal Material. This license does not give you any ownership or intellectual property interest in
          any
          GettinLocal Material or the Trademarks. Other than as required to facilitate your permissible use, you
          may
          not reproduce, perform, publicly display, embed, create derivative works of, republish, upload, post,
          retransmit
          or redistribute in any way whatsoever any GettinLocal Material. All rights not expressly granted to you
          under
          this Agreement are reserved by GettinLocal.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">USER IDS.</header>
      <div class="list-item-content">
        <p class="text">
          In order to create an account to use the Services, you may be
          required to create a unique identification name
          and password for access to and use of the Service (&ldquo;
          <u>User ID</u>&rdquo;). You shall be solely responsible for ensuring the security and confidentiality
          of your
          User ID. You acknowledge that you will be fully responsible for all liabilities incurred through use of
          your
          User ID and that any transactions under your User ID will be deemed to have been performed by you. Use
          of
          any User ID other than as provided in this Agreement shall be considered a breach of this Agreement by
          you.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">PERSONAL INFORMATION</header>
      <div class="list-item-content">
        <p class="text">
          You may be required to submit information that is personal or
          which identifies you such as your name, physical
          address, e-mail address, telephone numbers and other personal information (&ldquo;
          <u>Personal Information
          </u>&rdquo;) to use the Services. Your Personal Information will be handled in accordance with our
          Privacy
          Policy
          <a href="http://www.cleardoc.com/privacy">http://www.GettinLocal.com/privacy</a>.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">USER CONTENT</header>
      <div class="list-item-content">
        <p class="text">
          You may submit photographs, images, videos, information, comments,
          suggestions, graphics, drawings, designs,
          or other similar materials, including, without limitation, merchants who advertise through the Service
          (collectively,
          &ldquo;
          <u>User Content</u>&rdquo;) to GettinLocal via the Services. By uploading or submitting any User
          Content to
          GettinLocal, you represent and warrant that you are the original author, owner, or licensee of such
          User
          Content and all rights thereto, and that you have the right to upload or submit the User Content. When
          you
          submit User Content to GettinLocal, you grant GettinLocal a non-exclusive, worldwide, perpetual,
          irrevocable,
          sublicensable, royalty-free, freely transferable right and license to use, copy, modify, create
          derivative
          works of, delete, transmit, publish, display, perform, translate, sell, and distribute the User
          Content,
          and to incorporate such User Content into any form or medium now known or hereinafter invented, without
          compensation
          to you. No User Content will be subject to any obligation of confidence on the part of GettinLocal.
        </p>
        <p class="text">
          You agree that disputes arising from an alleged violation of
          intellectual property rights may result in GettinLocal
          suffering irreparable harm and that, in the event of such a dispute, GettinLocal may obtain a
          restraining
          order, preliminary injunctive relief, an injunction, specific performance or other equitable relief
          and/or
          legal remedies.
        </p>
        <p class="text highlight-color">
          ALL USER CONTENT IS PROVIDED &ldquo;AS-IS&rdquo; AND
          &ldquo;AS AVAILABLE,&rdquo; WITHOUT WARRANTIES
          OF ANY KIND.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">SITE IS A VENUE</header>
      <div class="list-item-content">
        <p class="text">
          The Service is a communications platform for enabling the
          connection between individuals who are either seeking
          to offer merchant services to users local to the merchant. The Service is merely a venue through which
          GettinLocal&rsquo;s
          users contract between themselves and, as such, GettinLocal does not have
          <strong>any</strong> control over the quality, timing or legality of services actually requested or delivered by
          its merchant
          users. Merchants and their customers agree to make their own decisions and assessments about whether to
          participate
          in any merchant offering. In no way is GettinLocal involved in transactions for services to be rendered
          by
          any merchant and its customers and GettinLocal does not independently verify any of the offerings made
          by
          a merchant user.
        </p>

        <p class="text">
          Merchants and their customers must deal directly with each other
          to resolve any disputes between them. GettinLocal
          will not be held responsible and expressly disclaims any liability whatsoever for any claims and/or
          controversies
          that may arise for any disputes between merchants and their customers.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">CHILDREN&rsquo;S PRIVACY</header>
      <div class="list-item-content">
        <p class="text">
          The Services are not designed for or targeted to children under
          the age of 13, and GettinLocal will never knowingly
          solicit or collect the Personal Information of a child under the age of 13. If we discover that
          Personal
          Information of a child under the age of 13 has been submitted without verifiable parental consent, we
          will
          immediately delete such Personal Information.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">
        DIGITAL MILLENNIUM COPYRIGHT ACT (&ldquo;<span class="underline">DMCA</span>&rdquo;) NOTICE
      </header>
      <div class="list-item-content">
        <p class="text">
          GettinLocal is under no obligation to, and does not, scan third
          party content used in connection with the Services
          for the inclusion of illegal or impermissible content. However, GettinLocal respects the copyright
          interests
          of others and, as a policy, does not knowingly permit content that infringes another party&rsquo;s
          copyright.
          It is GettinLocal&rsquo;s intention to fully comply with the DMCA, including the notice and &ldquo;take
          down&rdquo;
          provisions and to benefit from the safe harbors immunizing it from liability to the fullest extent
          provided
          by law.
        </p>
        <p class="text">
          If you believe any content on the Services infringe a copyright,
          you should provide GettinLocal with written
          notice that at a minimum contains:
        </p>
        <p class="text">
          &bull; A physical or electronic signature of a person authorized to act
          on behalf of the owner of an exclusive right
          that is allegedly infringed;
        </p>
        <p class="text">
          &bull; Identification of the copyrighted work claimed to have been
          infringed, or, if multiple copyrighted works at
          a single online site are covered by a single notification, a representative list of such works at that
          site;
        </p>
        <p class="text">
          &bull; Identification of the material that is claimed to be infringing or
          to be the subject of infringing activity
          and that is to be removed or access to which is to be disabled, and information reasonably sufficient
          to
          permit GettinLocal to locate the material;
        </p>
        <p class="text">
          &bull; Information reasonably sufficient to permit GettinLocal to contact
          the complaining party, such as an address,
          telephone number, and, if available, an electronic mail address at which the complaining party may be
          contacted;
        </p>
        <p class="text">
          &bull; A statement that the complaining party has a good faith belief
          that use of the material in the manner complained
          of is not authorized by the copyright owner, its agent, or the law; and
        </p>
        <p class="text">
          &bull; A statement that the information in the notification is accurate,
          and under penalty of perjury, that the complaining
          party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
        </p>
        <p class="text"> All DMCA notices should be sent to GettinLocal&rsquo;s designated
          agent as follows:
        </p>
        <br>
        <p class="text">Gettinlocal</p>
        <p class="text">Compliance Dept.</p>
        <p class="text">1400 Old Country Rd.</p>
        <p class="text">C-108</p>
        <p class="text">Westbury, NY 11590</p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">PROHIBITED ACTS</header>
      <div class="list-item-content">
        <p class="text">
          The following is a non-exhaustive list of activities that are
          prohibited in connection with the Services:
        </p>
        <ul>
          <li class="text">
            criminal or tortious activity, committing fraud or conspiracy,
            sending sexually-explicit images or obscene
            materials, gambling, harassment, threatening, stalking, spamming, sending of viruses or other
            harmful
            files, intellectual property infringement or trade secret theft, or sending hateful, insulting or
            discriminatory
            materials, messages or content;
          </li>
          <li class="text">
            using any information contained in the Services for purposes
            of constructing a competing service or otherwise
            diminishing the value of the Service;
          </li>
          <li class="text">
            web scraping, framing or utilizing framing techniques to
            enclose, or deep link to, any Trademarks, GettinLocal
            Material or other proprietary information of GettinLocal or its licensors and suppliers;
          </li>
          <li class="text">
            any automated use of the Services or User Content, such as
            using scripts to add subscribers or send comments
            or messages;
          </li>
          <li class="text">
            interfering with, disrupting, or creating an undue burden on
            the Services;
          </li>
          <li class="text">
            removing copyright, trademark or other proprietary rights
            notices contained in or on the Services;
          </li>
          <li class="text">
            using robots, spiders, or other automated devices to retrieve
            or index any portion of the Services;
          </li>
          <li class="text">
            attempting to impersonate another person or entity;
          </li>
          <li class="text">
            using any information obtained from the Services to harass,
            abuse, discriminate against or harm another
            person; or
          </li>
          <li class="text">
            using the Services in a manner inconsistent with applicable
            laws and regulations.
          </li>
        </ul>
        <p class="text">
          GettinLocal reserves the right to investigate and take appropriate
          legal action against anyone who, in GettinLocal&rsquo;s
          sole discretion, violates this section, including without limitation, reporting such violations to law
          enforcement
          authorities.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">TERMINATION</header>
      <div class="list-item-content">
        <p class="text">
          GettinLocal may, in its sole discretion, terminate this Agreement
          by discontinuing operation of the Services.
          GettinLocal reserves the right to terminate your account and prevent you from accessing the Services at
          any
          time and for any reason, including, but not limited to, your violation of this Agreement or the law.
          The
          violation of any of the terms and conditions set forth in this Agreement may result in the immediate
          termination
          of your right and license to use the GettinLocal Material and obligates you to immediately destroy any
          copies
          of the GettinLocal Material in your possession.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">DISCLAIMER OF WARRANTIES</header>
      <div class="list-item-content">
        <p class="text">
          EXCEPT AS OTHERWISE SET FORTH HEREIN, THE SERVICES AND THE
          GETTINLOCAL MATERIAL ARE PROVIDED ON AN &ldquo;AS
          IS,&rdquo; &ldquo;AS AVAILABLE&rdquo; BASIS WITHOUT ANY WARRANTIES OF ANY KIND.
          <span class="highlight-color underline">
            GETTINLOCAL DISCLAIMS ALL OTHER WARRANTIES, EXPRESS AND
            IMPLIED, WITH RESPECT TO THE SERVICES, GETTINLOCAL
            MATERIAL AND USER CONTENT, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            NON-INFRINGEMENT,
            TITLE, QUALITY OF INFORMATION, AND FITNESS FOR A PARTICULAR PURPOSE.
          </span>
          IN THE EVENT OF ANY PROBLEM WITH THE SERVICES, YOU AGREE THAT YOUR SOLE REMEDY IS TO CEASE USING
          THE SERVICES.
        </p>
        <p class="text">
          GettinLocal Material and User Content may contain inaccurate or
          out-of-date information or typographical or
          spelling errors. GettinLocal does not make any representations or warranties regarding: (i) the truth,
          accuracy,
          completeness, timeliness, legality, or reliability of any GettinLocal Material and User Content; or
          (ii)
          any results to be obtained from using the Services or relying on the GettinLocal Material and/or the
          User
          Content.
        </p>
        <p class="text">
          GettinLocal does not warrant that: (i) the quality of any
          information, products, services, or other material
          that you obtain from the Services will meet your expectations; (ii) the Services will operate
          uninterrupted
          and error-free; (iii) the Services will be free of viruses, worms or other harmful material; or (iv)
          the
          Services will operate in perpetuity.
        </p>
        <p class="text">
          GettinLocal shall not be in default or otherwise liable for any
          delay in or failure of its performance under
          this Agreement where such delay or failure arises by reason of any Act of God, or any government or any
          governmental
          body, war, insurrection, acts of terrorism, the elements, strikes or labor disputes, or other similar
          or
          dissimilar causes beyond GettinLocal&rsquo;s control. User acknowledges that the performance of certain
          of
          GettinLocal&rsquo;s obligations may require the cooperation of third parties designated by User and
          outside
          the control of GettinLocal In the event such third parties fail to cooperate with GettinLocal in a
          manner
          that reasonably permits GettinLocal to perform its obligations, such failures shall be consider as
          causes
          beyond the control of the GettinLocal for the purposes of this Section and shall not be the basis for a
          determination
          that GettinLocal is in breach of any of its obligations under this Agreement or is otherwise liable.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">LIMITATION OF LIABILITY</header>
      <div class="list-item-content">
        <p class="text">
          YOU USE THE SERVICES AND THE GETTINLOCAL MATERIAL IS AT YOUR OWN
          RISK. TO THE EXTENT PERMITTED BY APPLICABLE
          LAW, GETTINLOCAL SHALL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR
          <span class="underline highlight-color">
            LIABLE TO YOU OR ANY OTHER THIRD PARTY FOR ANY DIRECT,
                  INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL,
                  SPECIAL, OR CONSEQUENTIAL DAMAGES (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                  OTHERWISE)
                  ARISING OUT OF THE SERVICES, GETTINLOCAL MATERIAL AND USER CONTENT, EVEN IF THEY HAVE BEEN
                  ADVISED
                  OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. IN ANY EVENT, THE MAXIMUM LIABILITY OF ANY OF
                  THE
                  FOREGOING PARTIES FOR ALL CLAIMS (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                  OTHERWISE)
                  OF EVERY KIND ARISING OUT OF THE SERVICES, GETTINLOCAL MATERIAL AND USER CONTENT WILL IN NO
                  EVENT
                  EXCEED THE AMOUNTS PAID BY YOU FOR THE SERVICES IN THE THREE (3) MONTHS PRIOR TO A CLAIM
                  ARISING
                  HEREUNDER OR, IF YOU ARE NOT A PAID USER OF THE SERVICES, THEN ONE HUNDRED DOLLARS ($100).
          </span>
        </p>

        <p class="text">
          GETTINLOCAL ASSUMES NO RESPONSIBILITY OR LIABILITY FOR ANY LOST
          DATA, ERROR, OMISSION, INTERRUPTION, DELETION,
          DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR DESTRUCTION OR
          UNAUTHORIZED
          ACCESS TO, OR ALTERATION OF, ANY USER COMMUNICATION. GETTINLOCAL IS NOT RESPONSIBLE OR LIABLE FOR ANY
          PROBLEMS
          OR TECHNICAL MALFUNCTION OF ANY TELEPHONE NETWORK OR LINES, COMPUTER ONLINE SYSTEMS, SERVERS OR
          PROVIDERS,
          COMPUTER EQUIPMENT, MOBILE DEVICES, SOFTWARE, OR FAILURE OF EMAIL DUE TO TECHNICAL PROBLEMS OR TRAFFIC
          CONGESTION
          ON THE INTERNET, INCLUDING INJURY OR DAMAGE TO ANY COMPUTER OR MOBILE DEVICE RELATED TO OR RESULTING
          FROM
          USE OF THE SERVICES.
        </p>
        <p class="text">
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          LIABILITY FOR DAMAGES.&nbsp; ACCORDINGLY, THE
          ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">COMPLIANCE WITH LAWS</header>
      <div class="list-item-content">
        <p class="text highlight-color">
          You agree to comply with all applicable local, state, national and international
          laws, rules and regulations,
          including without limitation, laws governing online conduct and the unlawful export of software or
          technical
          data to restricted individuals or countries.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">ADDITIONAL TERMS</header>
      <div class="list-item-content">
        <p class="text">Occasionally, GettinLocal may notify you of additional terms and
          conditions that you are required to accept
          in order to receive certain services offered on or through the Services (the &ldquo;
          <u>Additional Terms</u>&rdquo;), which will be posted in specific areas and require reading and
          acceptance.
          The Additional Terms are incorporated herein by reference and made a part of this Agreement.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">INDEMNIFICATION</header>
      <div class="list-item-content">
        <p class="text">
          You agree to defend, indemnify, and hold harmless GettinLocal and
          its parent, subsidiaries, affiliates, officers,
          directors, shareholders, employees, contractors, agents, and Independent Contractors from and against
          any
          action, claim, demand, damages, costs or expenses (including attorneys&rsquo; fees and court costs)
          arising
          out of: (i) your use of the Services or the GettinLocal Material; (ii) any conduct by you that violates
          the
          terms of this Agreement; or (iii) your infringement of the intellectual property rights of third
          parties.
        </p>
      </div>
    </li>
    <li class="list-item">
      <header class="list-item-header">GENERAL PROVISIONS</header>
      <div class="list-item-content">


        <p class="text">
          his Agreement is governed by the laws of State of New York
          without regard to its conflict of laws principles.
          You agree to submit to the jurisdiction of the courts in New York, U.S.A., with respect to any dispute,
          disagreement,
          or cause of action related to the Services.
        </p>
        <p class="text highlight-color">GettinLocal&rsquo;s failure to enforce any provision hereof shall not constitute
          or be construed as a waiver
          of such provision or of the right to enforce it or any other provision at a later time.
        </p>
        <p class="text">
          GettinLocal may, at any time, in its sole discretion, and without
              notice to you, assign some or all of its
              rights and obligations under this Agreement.
             <span class="highlight-color"> You may not assign your rights or delegate your duties under this Agreement
                without the prior written consent
                of GettinLocal.
             </span>
        </p>
        <p class="text highlight-color">If any provision of this Agreement is held invalid, illegal or unenforceable for
                any reason, such invalidity,
                illegality or unenforceability shall be deleted and shall not affect any other provision of this
                Agreement.
        </p>
        <p class="text">
          Except as expressly provided elsewhere on the Services, this
              Agreement constitutes the entire agreement between
              you and GettinLocal with respect to your use of the Services.
        </p>
        <p class="text" >Last updated: 11/22/18</p>
      </div>
    </li>
  </ol>

</div>
<pb-checkbox class="tos" name="tos" id="tos-checkbox" label="I agree to the" [ngModel]="isAgree"
             (ngModelChange)="agreed($event)">
  <a class="link" href="javascript:void(0);" (click)="openTermsOfUsePopup()">Terms & Conditions</a>
</pb-checkbox>
