import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line
  selector: '[pb-page-header]',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input()
  public isBig = false;

  public ngOnInit() {
  }
}
