<header pb-page-header>
  <div left>Web Applications: {{ webAppId }}</div>
  <div right>
    <div class="buttons">
    </div>
  </div>
</header>

<div class="row mt-10">
  <div class="col-6">
    <label for="webappFilter">Filter</label>
    <select id="webappFilter" (change)="filterChanged($event)" class="form-control">
      <option *ngFor="let option of appFilters" [value]="option.id">{{ option.name }}</option>
    </select>
  </div>
</div>

<ng-container *ngIf="coupons.length > 0; else empty">
  <table pb-table mdSort>
    <thead>
    <tr>
      <th width="5%">Order</th>
      <th>Name</th>
      <th width="10%">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let coupon of coupons; let i = index"
        draggable="true"
        (dragstart)="dragStart($event, i)"
        (dragover)="allowDrop($event)"
        (drop)="drop($event, i)"
        (dragenter)="dragEnter($event, i)"
        (dragleave)="dragLeave($event)">
      <td>
        <a (click)="moveUp(i)" [hidden]="i === 0">
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
        </a>
        <a (click)="moveDown(i)" [hidden]="i === coupons.length - 1">
          <i class="fa fa-arrow-down" aria-hidden="true"></i>
        </a>
      </td>
      <td>{{ coupon.coupon_name }}</td>
      <td>
        <a (click)="toggleFeatured(coupon)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #empty>
  <div style="width: 100%; height: 100px; display: flex; justify-content: center; align-items: center;">
    No coupons found. Select a different filter or add a coupons from the &nbsp;<a
    [routerLink]="['/webapps', webAppId, 'listings']">Listings</a>&nbsp; page.
  </div>
</ng-template>
