import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';

import { CampaignMapper } from './campain.mapper';
import { ImagesService } from './images.service';

import { PromotionDto } from './dto/promotion-dto';

import { environment } from '../../../../environments/environment';
import { Promotion, Merchant } from '../../../core';

/**
 * Commpon part for the promotion URL.
 */
const PATH = 'promotions';

/**
 * Promotion service.
 */
@Injectable()
export class PromotionService {
  /**
   * Campaigm mapper;
   */
  private mapper = new CampaignMapper();

  /**
   * @constructor
   */
  public constructor(
    private http: HttpClient,
    private imagesService: ImagesService,
  ) {
  }

  /**
   * Create a promotion then add promotions images.
   * @param promotion Promotion data.
   * @param merchant Merchant data.
   * @return returns ID promotion after success.
   */
  public createPromotionWithLogo(promotion: Promotion, merchant: Merchant): Observable<number> {
    return this.createPromotion(promotion, merchant).pipe(
      map(({ id }) => ({ campaignId: id, merchantId: merchant.id })),
      switchMap(({ campaignId, merchantId }) => {
        return this.uploadImages(promotion, merchantId, campaignId).switchMap(() => Observable.of(campaignId));
      }),
    );
  }

  /**
   * Create new promotion.
   * @param promotion Promotion data.
   * @param merchant Merchant data.
   */
  public createPromotion(promotion: Promotion, merchant: Merchant): Observable<PromotionDto> {
    const urlPath = `${environment.apiEndpoint}/merchants/${merchant.id}/${PATH}/`;
    const data = this.mapper.convertPromotionToDto(promotion, merchant);

    return this.http.post<PromotionDto>(urlPath, data);
  }

  /**
   * Upload the promotion images.
   * @param promotion Promotion data.
   * @param merchantId Merchant ID.
   * @param promotionId Coupon ID.
   */
  private uploadImages(promotion: Promotion, merchantId: number, promotionId: number): Observable<void> {
    return this.imagesService.uploadCampaignImages(
      null, // promotion.campaignStyle.couponImageMain.blob,
      null, // promotion.campaignStyle.couponImageFull.blob,
      promotion.redemptionOptions.inStoreImage.base64,
      merchantId,
      promotionId,
      PATH,
    );
  }

  /**
   * Get coupon data by ID and convert to the coupon model.
   * @param id Coupon ID.
   * @param merchantId Merchant ID.
   */
  public getPromotionById(id: number, merchantId: number): Observable<Promotion> {
    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/${PATH}/${id}/`;

    return this.http.get<PromotionDto>(urlPath)
      .pipe(
        map((response: PromotionDto) => this.mapper.convertPromotionDtoToModel(response)),
      );
  }

  /**
   * Update promotion info.
   * @param promotion Coupon data.
   * @param merchant Merchant data.
   * @return ID promotion after success.
   */
  public updatePromotion(promotion: Promotion, merchant: Merchant): Observable<number> {
    const { campaignStyle: { id } } = promotion;
    const urlPath = `${environment.apiEndpoint}/merchants/${merchant.id}/${PATH}/${id}/`;
    const data = this.mapper.convertPromotionToDto(promotion, merchant);

    // If the Main Image Blob not null then image was updating.
    // Need to delete old images from DB and upload the new.
    // if (couponImageMain.blob !== null) {
    //   const body = {
    //     logo: true,
    //     image: true,
    //   };
    //   return this.imagesService.deleteCampaignImages(merchant.id, id, PATH, body)
    //     .pipe(
    //       switchMap(() => this.http.patch<any>(urlPath, data)),
    //       switchMap(() => this.updatePromotionWithImages(urlPath, data, promotion, merchant.id)),
    //       switchMap(() => Observable.of(id)),
    //     )
    //     ;
    // }

    return this.http.patch<void>(urlPath, data).switchMap(() => Observable.of(id));
  }

  /**
   * Update promotion data and update images.
   * @param urlPath URL.
   * @param data Promotion DTO.
   * @param promotion Promotion Model.
   * @param merchantId Merchant ID.
   */
  private updatePromotionWithImages(urlPath, data, promotion, merchantId): Observable<any> {
    return this.http.patch<any>(urlPath, data)
      .pipe(
        switchMap(({ id }) => this.uploadImages(promotion, merchantId, id)),
      );
  }
}
