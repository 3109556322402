<pb-breadcrumbs [items]="['Activities', 'New Data Request']"></pb-breadcrumbs>

<div class="mt-50"></div>

<div style="display: flex;">
  <div style="width: 80%">
    <form [formGroup]="popupForm" (ngSubmit)="onSubmit($event)">

      <div class="row mt-10">
        <div class="col">
          <label for="name">Name</label>
          <input type="text" id="name" formControlName="name" class="form-control"
                 [ngClass]="{'is-invalid': popupForm.get('name').invalid && popupForm.get('name').touched}">
          <span class="note" style="margin-left: 0px;">Example: Omni Popup</span>
          <div *ngIf="popupForm.get('name').invalid && popupForm.get('name').touched" class="invalid-feedback">
            <div *ngIf="popupForm.get('name').errors.required">Name is required.</div>
            <div *ngIf="popupForm.get('name').errors.minlength">Name must be at least 3 characters long.</div>
          </div>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <label for="logo">Logo</label>
          <input type="file" id="logo" (change)="onFileChange($event, 'logo')" class="form-control"
                 [ngClass]="{'is-invalid': popupForm.get('logo').invalid && popupForm.get('logo').touched}">
          <div *ngIf="popupForm.get('logo').invalid && popupForm.get('logo').touched" class="invalid-feedback">
            <div *ngIf="popupForm.get('logo').errors.required">Logo is required.</div>
          </div>
          <img *ngIf="logoPreview" [src]="logoPreview" alt="Logo Preview" class="img-thumbnail mt-2"
               style="max-height: 200px;">
        </div>
      </div>

      <div class="row">
        <div class="col" style="display: flex; justify-content: center;">
          <p>-or-</p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="title">Title</label>
          <textarea id="title" formControlName="title" class="form-control" (change)="onTitleChange($event)"></textarea>
          <div *ngIf="popupForm.get('title').invalid && popupForm.get('title').touched" class="invalid-feedback">
            <div *ngIf="popupForm.get('title').errors.required">Title is required.</div>
          </div>
        </div>
      </div>

      <div *ngIf="popupForm.errors?.atLeastOneRequired && popupForm.touched" class="invalid-feedback">
        Either Logo or Title is required.
      </div>

      <div class="row mt-10">
        <div class="col">
          <label for="subtitle">Subtitle</label>
          <textarea id="subtitle" formControlName="subtitle" class="form-control"></textarea>
          <div *ngIf="popupForm.get('subtitle').invalid && popupForm.get('subtitle').touched" class="invalid-feedback">
            <div *ngIf="popupForm.get('subtitle').errors.required">Subtitle is required.</div>
          </div>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <label for="submit_button_text">Submit Button Text</label>
          <input type="text" id="submit_button_text" formControlName="submit_button_text" class="form-control"
                 [ngClass]="{'is-invalid': popupForm.get('submit_button_text').invalid && popupForm.get('submit_button_text').touched}">
          <div *ngIf="popupForm.get('submit_button_text').invalid && popupForm.get('submit_button_text').touched"
               class="invalid-feedback">
            <div *ngIf="popupForm.get('submit_button_text').errors.required">Submit Button Text is required.</div>
          </div>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <input type="checkbox" id="ask_phone" formControlName="ask_phone">
          <label for="ask_phone">Ask for Phone</label>
          <div *ngIf="popupForm.get('ask_phone').value">
            <input type="text" id="phone_placeholder" formControlName="phone_placeholder" class="form-control">
          </div>
          <span class="note" style="margin-left: 0px;">Phone placeholder</span>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <input type="checkbox" id="ask_zip" formControlName="ask_zip">
          <label for="ask_zip">Ask for ZIP Code</label>
          <div *ngIf="popupForm.get('ask_zip').value">
            <input type="text" id="zip_placeholder" formControlName="zip_placeholder" class="form-control">
          </div>
          <span class="note" style="margin-left: 0px;">Zip placeholder</span>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <input type="checkbox" id="ask_email" formControlName="ask_email">
          <label for="ask_email">Ask for Email</label>
          <div *ngIf="popupForm.get('ask_email').value">
            <input type="text" id="email_placeholder" formControlName="email_placeholder" class="form-control">
          </div>
          <span class="note" style="margin-left: 0px;">Email placeholder</span>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <input type="checkbox" id="ask_type" formControlName="ask_type">
          <label for="ask_type">Ask for User Type</label>
          <div *ngIf="popupForm.get('ask_type').value">
            <input type="text" id="user_type_question" formControlName="user_type_question" class="form-control">
          </div>
          <span class="note" style="margin-left: 0px;">User type question</span>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <input type="checkbox" id="i_agree" formControlName="i_agree">
          <label for="i_agree">I Agree</label>
          <span class="note" style="margin-left: 0px;">Description</span>
          <textarea id="i_agree_text" formControlName="i_agree_text" class="form-control"
                    [disabled]="!popupForm.get('i_agree').value"></textarea>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <input type="checkbox" id="receive_newsletter" formControlName="receive_newsletter">
          <label for="receive_newsletter">Receive Newsletter</label>
          <span class="note" style="margin-left: 0px;">Description</span>
          <textarea id="receive_newsletter_text" formControlName="receive_newsletter_text" class="form-control"
                    [disabled]="!popupForm.get('receive_newsletter').value"></textarea>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <input type="checkbox" id="is_enabled" formControlName="is_enabled">
          <label for="is_enabled">Is Enabled</label>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col">
          <div class="section-header">
            <div class="header-text"></div>
            <div right>
              <div class="row">
                <button pb-button class="button" small white routerLink="/webapp">Cancel</button>
                <button pb-button class="button" small type="button" (click)="onSubmit($event)">Preview</button>
                <button pb-button class="button" small type="submit">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
  <div class="preview">
    <div [innerHTML]="previewContent">
    </div>
  </div>
</div>

<div class="mb-50"></div>
<div style="height: 100px"></div>
