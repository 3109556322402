<form *ngIf="user$ | async as user">
  <!-- <pb-breadcrumbs [items]="['Activities', 'New Campaign']"></pb-breadcrumbs> -->

  <header pb-page-header>
    <div left>{{id? 'Edit Event' : 'New Event'}}</div>
    <div right>
      <span class="need-assistance">Need help with creating your campaign? Contact us at info@gettinlocal.com</span>
    </div>
  </header>

  <section class="container">
    <div class="steps campaign-steps">
      <pb-step *ngFor="let step of steps" [number]="step.displayNumber" [isActive]="step.number === currentStep.number"
        [isValid]="step.isValid" [last]="2" (click)="chooseStep(step)" [horizontal]="true" [pageNumber]="step.number + 1">
        {{step.text}}</pb-step>
      <div class="separator"></div>
      <button *ngIf="!isFirstStep" pb-button class="button" white autoHeight (click)="backStep()">Back</button>
      <button *ngIf="!isLastStep" pb-button class="button" autoHeight (click)="nextStep()">Next</button>
      <!-- <button *ngIf="isLastStep" pb-button class="button" autoHeight
        (click)="publishCampaign()">Publish Now</button> -->
    </div>
  </section>

  <div class="components-and-preview-container">
    <div class="left-components" [ngSwitch]="currentStep.number">
      <pb-event-style #leftComponent [merchantId]="merchantId" [couponId]="id"
                      [eventStyle]="eventStyle"
                      [merchantKeywords]="merchantKeywords"
                      [userCountry]="userCountry"
                      *ngSwitchCase="0">
      </pb-event-style>
      <pb-finalize-event #leftComponent [campaignStyle]="eventStyle" (publishCampaign)="publishCampaign()"
        *ngSwitchCase="1">
      </pb-finalize-event>
    </div>
    <pb-preview-event *ngIf="user.merchant && !isLastStep"
                      [isEdit]="id"
                      [logo]="user.merchant.logo"
                      (upgradePlan)="upgradePlan()"
                      [covidOptions]="user.merchant.covidOptions"
                      [dbaName]="user.merchant.dbaName"
                      [Address]="previewPlace ? previewPlace : user.merchant.address"
                      [previewCouponVideo]="eventStyle"
                      [previewCouponImage]="eventStyle"
                      [eventStyle]="eventStyle"
                      [previewCoupon]="previewCouponVm"
                      [subscription]="subscription" class="preview">
    </pb-preview-event>
  </div>

  <section class="container">
    <div class="steps campaign-steps">
      <pb-step *ngFor="let step of steps" [number]="step.displayNumber" [isActive]="step.number === currentStep.number"
        [isValid]="step.isValid" [last]="2" (click)="chooseStep(step)" [horizontal]="true" [pageNumber]="step.number + 1">
        {{step.text}}</pb-step>
      <div class="separator"></div>
      <button *ngIf="!isFirstStep" pb-button class="button" white autoHeight (click)="backStep()">Back</button>
      <button *ngIf="!isLastStep" pb-button class="button" autoHeight (click)="nextStep()">Next</button>
      <!-- <button *ngIf="isLastStep" [disabled]="p" pb-button class="button" autoHeight (click)="publishCampaign()">Publish
        Now</button> -->
    </div>
  </section>
</form>
