<form #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted">
  <header pb-page-header>
    <div left *ngIf="!brand">Account</div>
    <div left *ngIf="brand">Company Profile</div>
    <div right>
      <div class="buttons">
        <button (click)="cancelClicked($event)" pb-button type="button" white class="button"
                style="width: 100px;">Cancel
        </button>
        <button pb-button type="submit" class="button" [disabled]="disableSaveButton">Save</button>
      </div>
    </div>
  </header>

  <div class="welcome" *ngIf="showTitle()">
    Welcome to {{ brand.name }}.<br>
    Let’s get started building your mobile profile.
  </div>

  <section pb-section-required caption="Company Information">
    <div body>
      <div class="container">
        <div class="left">
          <div class="row">
            <div class="tooltip-title">
              <div class="global-caption">Business or Organization Name</div>
              <pb-info-tooltip
                description="Your Mobile App / DBA is the name that will be displayed to advertise your company on {{ brand.name }}."
                hint="As an example the signage on your storefront may read: Calda Pizza. This is how customers know your business.">
              </pb-info-tooltip>
            </div>
            <pb-input required [maxlength]="50" name="dbaNameOfBusiness" label="" #merchantDbaName="ngModel"
                      [(ngModel)]="merchant.dbaName" placeholder="ABC Business" class="full-width" pbTooltip
                      customElementQuery=".body">
              <pb-error-message [errors]="merchantDbaName.errors"></pb-error-message>
            </pb-input>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section pb-section caption="Upload Logo (Optional)" class="logoSection">
    <div body>
      <div class="container">
        <div class="left" style="width: 300px;">
          <div class="tooltip-title">
            <div class="global-caption">Upload Logo</div>
            <pb-info-tooltip
              description="Your company Logo will be displayed when your customers favorite your business or are searching keywords associated with your business.">
            </pb-info-tooltip>
          </div>
          <span class="note" style="margin-left: 0px;">Image files supported – JPEG, PNG</span>
          <br><br>
          <div class="flex-radio">
            <div class="tooltip-title-logo">
              <label class="label">
                <input type="radio" name="logo_type" required (change)="handleLogoTypeChange($event)"
                       [checked]="(isLogoRect === false)" value="square">
                Square
              </label>
            </div>
            <div class="tooltip-title-logo">
              <label class="label">
                <input type="radio" name="logo_type" (change)="handleLogoTypeChange($event)"
                       [checked]="(isLogoRect === true)" value="rectangle">
                Rectangle
              </label>
            </div>
          </div>
          <br>

          <br>
          <div>
            <pb-browse-file name="logo" #logo="ngModel" class="logo-browse-link" [(ngModel)]="merchant.logo"
                            (uploaded)="logoUploaded($event)" [isLink]="true"></pb-browse-file>
          </div>
          <br>
          <div [ngClass]="(isLogoRect!==true)?'logo':'logo rect'" style.background-image="url({{merchant.logo}})"
               [class.logo-required]="!logo.valid || null" drag-n-drop (filesChangeEmiter)="imageDragged($event)"
               [coverInnerElements]="true" [useConcreteBlock]="true" [filesCountRestriction]="1" [filesCount]="0">
          </div>
          <br>
          <button type="button" *ngIf="merchant.logo && merchant.logo !== 'removed'" class="link"
                  style="background-color: transparent; border: none;" isLink="true"
                  (click)="removeLogoClicked()" [class.disabled]="isDisabled != null && isDisabled"
                  [style.width]="buttonWidth">
            Remove Logo
          </button>
        </div>
        <div class="right">
          <div style="margin-top: 100px; font-size: 12px; color: grey;">
            <ul *ngIf="isLogoRect">
              <li>Recommended size is 400 x 800 pixels, or higher for best results.</li>
              <li>The Aspect Ratio will be 2:1 (width twice as long as height).</li>
              <li>Lower resolution logos will be accepted.</li>
              <li>However, with lower resolutions you may observe some blurring when previewing the image in the
                merchant
                portal. This does not affect the display of the logo on the mobile app.
              </li>
            </ul>
            <ul *ngIf="!isLogoRect">
              <li>Recommended size of 500 x 500 pixels, or higher for best results.</li>
              <li>Lower resolution logos will be accepted.</li>
              <li>However, with lower resolutions you may observe some blurring when previewing the image in the
                merchant portal. This does not affect the display of the logo on the mobile app.
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="image__header image__header-background">
              <div class="tooltip-title">
                <div class="image__logo-caption global-caption">* Upload Your Background Image</div>
                <pb-info-tooltip
                  description="This is an image of your; store front, interior, or products sold. That will be displayed on your mobile app profile.">
                </pb-info-tooltip>
              </div>
              <div class="image__right">
                <div class="size">375 x 375 pixels</div>
                <pb-browse-file name="bgLogo" #bgLogo="ngModel" [ngModel]="merchant.logoBackground"
                  (ngModelChange)="bgLogoImage = $event" (uploaded)="bgLogoUploaded($event)" [isLink]="true" required>
                </pb-browse-file>
              </div>
            </div>
            <div class="image__body" [class.logo-required]="!bgLogo.valid || null"
              style.background-image="url({{merchant.logoBackground}})" drag-n-drop
              (filesChangeEmiter)="bgImageDragged($event)" [filesCountRestriction]="1" [filesCount]="0"
              [useConcreteBlock]="true">
            </div> -->
      </div>
    </div>
  </section>

  <section pb-section-required caption="Select Business Type">
    <div body>
      <div class="container">
        <div class="left">
          <div class="row">
            <div class="tooltip-title">
              <div class="global-caption">Select Business Type</div>
              <pb-info-tooltip
                description="By selecting your Industry type you will make your business more discoverable when mobile customers are searching for a specific type of business."
                hint="Your business can have multiple categories."></pb-info-tooltip>
            </div>
          </div>
          <div class="row categories-row">
            <pb-select required class="cat-select" [placeholder]="'Select a category'"
                       (change)="categoryChanged($event)">
              <option [selected]="selectedCategoryIds[0] === category.id" [value]="category.id"
                      *ngFor="let category of categories">{{category.text}}</option>
            </pb-select>
            <!-- <pb-selectize-dropdown #categoriesDropdown class="sort categoryDropdown" name="category"
              [(ngModel)]="selectedCategoryIds" [items]="categories" placeholder="Choose category" [openOnFocus]="true"
              [maxItems]="null" required dropdownDirection="up" [hasAdd]="false" [disableInput]="true"
              (clickHandler)="categorySelectionHandler()" [disableDropdownOpen]="true">
              {{categoriesDropdown.name}}
            </pb-selectize-dropdown> -->
          </div>
          <div class="row" *ngIf="showKeywords()">
            <div class="tooltip-title">
              <div class="row global-caption">Searchable Keywords</div>
              <pb-info-tooltip
                description="Mobile customers can discover your business when searching key words that are relavent to your business. Choose words that consumers would use to describe your business, or products & services."
                hint="Add key words such as your business name (e.g. Calda Pizza),  pizza, calzone, lunch, specialty slice, salad, pasta, student special, etc.">
              </pb-info-tooltip>
            </div>
            <pb-keywords #keywords="ngModel" name="keywords" ngModel [keywords$]="keywordsOfMerchats$"
                         (keywordsChange)="onKeywordsChange($event)">
              <pb-error-message [errors]="keywords?.errors"></pb-error-message>
            </pb-keywords>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section pb-section-required caption="Add Business or Organization Address">
    <div tooltip>
      <pb-info-tooltip
        description="This is the Physical Address and Contact Phone Number that will be displayed on Your Mobile App."
        hint="You Can Add Additional Locations Once You Save This Profile."></pb-info-tooltip>
    </div>
    <div header>
      Add additional addresses by clicking the "locations" button on the side tool bar after you have saved this page.
    </div>

    <div body>
      <div class="address-grid">
        <label class="global-caption address-label col-1" for="address">Select the Country for this address</label>
        <div class="col-2" style="margin-left: 237px; margin-top: -10px;">
          New Countries Coming Soon!
        </div>
        <div class="col-2" style="margin-top: 10px; display: flex; gap: 100px;">
          <pb-checkbox-group (onSelect)="merchantCountrySelected($event)" [disabled]="isEdit()"
                             [options]="merchantCountries">
          </pb-checkbox-group>
          <pb-checkbox-group [disabled]="true" [options]="merchantCountriesFuture1">
          </pb-checkbox-group>
          <pb-checkbox-group [disabled]="true" [options]="merchantCountriesFuture2">
          </pb-checkbox-group>
        </div>
      </div>

      <div class="address-grid">
        <label class="global-caption address-label col-1" for="address">Select Search Method<br/> (A or B)</label>
        <ul class="country-list col-2">
          <li>
            <div class="row">
              <label class="global-caption address-label" for="address">A) Business /Organization name (i.e, Pete's Bike
                Shop, Plainview, NY)</label>
            </div>
            <div class="">
              <pb-address-selectize #addressSelectizeComponent required="false"
                                    [searchBusiness]="true"
                                    [helpText]="'Search by Company Name'"
                                    (changeAddressId)="handleAddressInputChange($event)"
                                    [countries]="merchantCountry"
                                    [currentAddressStreet]="currentAddressBusiness"
                                    class="search-input element input">
              </pb-address-selectize>
            </div>
          </li>
          <li>
            <div class="row">
              <label class="global-caption address-label" for="address">B) Address (i.e, 55 Main Street, Plainview,
                NY)</label>
            </div>
            <div class="rw">
              <pb-address-selectize class="search-input element input"
                                    [searchBusiness]="false"
                                    #addressSelectizeComponent
                                    [countries]="merchantCountry"
                                    [currentAddressStreet]="currentAddressStreet"
                                    (changeAddressId)="handleAddressInputChange($event)">
              </pb-address-selectize>
            </div>
          </li>
        </ul>
      </div>

      <div class="row">
        <label class="global-caption address-label" for="address">Address Search Result</label>
      </div>

      <div class="address-grid">
        <label class="col-1" for="street">Address</label>
        <pb-input name="street" [(ngModel)]="merchant.address.street"
                  placeholder="Street" class="element col-2 input" required
                  [readonly]="true"></pb-input>
      </div>

      <div class="address-grid">
        <label class="col-1" for="city">City/Town</label>
        <pb-input name="city" [class.input-as-selectize]="!merchant.address.street" [(ngModel)]="merchant.address.city"
                  placeholder="City" class="element col-2 input" required
                  [readonly]="true"></pb-input>
      </div>

      <div class="address-grid">
        <label class="col-1" for="state">State/Province</label>
        <pb-input name="state" placeholder="State" [(ngModel)]="merchant.address.state"
                  [readonly]="true"
                  class="element col-2 input" required></pb-input>
      </div>

      <div class="address-grid">
        <label class="col-1" for="zip">Zip/Postcode</label>
        <pb-input name="zip" [(ngModel)]="merchant.address.zip" placeholder="ZIP"
                  class="element size-medium col-2 input input-as-selectize" minlength="4"></pb-input>
      </div>

      <div class="address-grid">
        <label class="col-1" for="country">Country</label>
        <pb-input id="country-selection" class="state-dropdown element size-medium col-3"
                  [readonly]="true"
                  placeholder="Country" [(ngModel)]="merchant.address.country" name="Country" required></pb-input>
      </div>

      <div class="address-grid">
        <label class="col-1" for="phone">Enter Phone#</label>
        <div style="display: flex; gap: 10px;">
          <!--<div style="width: 15%">
            <pb-select label="Country Code*">
              <option [value]="country.dial_code" *ngFor="let country of countryList"
                      [innerHTML]="country.flag"></option>
            </pb-select>
          </div>-->
          <div style="width: 15%">

            <pb-input name="country_calling_code"
                      label="Code*"
                      [(ngModel)]="merchant.country_calling_code"
                      placeholder="Code"
                      [readonly]="true"
                      class="element size-medium col-2 input input-as-selectize"
                      required minlength="2"></pb-input>
          </div>
          <div style="width: 63%">
            <pb-input name="phone" type="tel" label="Phone*" [(ngModel)]="merchant.phone" placeholder="Phone"
                      class="element" required
                      pbTooltip
                      customElementQuery=".body"></pb-input>
          </div>
        </div>
      </div>
      <div *ngIf="merchant.creationCompleted;else disabled_location_button" class="row">
        <a class="loc-link" [routerLink]="['/locations']" [queryParams]="{r:'profile'}">Additional Locations</a>
      </div>
      <ng-template #disabled_location_button>
        <div class="row">
          <a class="disabled-button">Additional Locations</a>
        </div>
      </ng-template>
    </div>
  </section>

  <section pb-section caption="About Us (Optional)" class="aboutSection">
    <div body>
      <div class="container">
        <div class="left">
          <div class="row">
            <div class="title-wrapper">
              <div class="tooltip-title">
                <div class="global-caption">About Us</div>
                <pb-info-tooltip
                  description="Here is where Current and Future Customers can learn about your business.Make sure to Include your business history, what services or products  you offer. Talk about why customers should visit your business."
                  hint="Your can type or cut & paste the about us section from your website."></pb-info-tooltip>
              </div>
              <span class="input-note">(Lim 800 characters)</span>
            </div>
            <pb-textarea name="aboutUs" label="" #merchantDescription="ngModel" [(ngModel)]="merchant.description"
                         placeholder="" note="" maxlength="800" class="full-width" rows="13" pbTooltip
                         customElementQuery=".body">
              <pb-error-message [errors]="merchantDescription.errors"></pb-error-message>
            </pb-textarea>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section pb-section caption="Hours of Operation (Optional)" class="hoursSection">
    <div tooltip>
      <pb-info-tooltip description="Select the hours of operation for your business."
                       hint="For e-commerce businesses, please input the hours a customer service representative is available.">
      </pb-info-tooltip>
    </div>
    <div body class="schedule">
      <pb-work-schedule *ngIf="schedule$ | async" [schedule$]="schedule$" (changeTime)="changeTime($event)"
                        [isBasicView]="false"></pb-work-schedule>
    </div>
  </section>

  <section pb-section caption="Photo Gallery (Optional)" class="photosSection">
    <div body drag-n-drop (filesChangeEmiter)="addImages($event)" [filesCount]="galleryImagesCount"
         [filesCountRestriction]="50">
      <div class="row">
        <div class="tooltip-title">
          <div class="global-caption">Upload Photos</div>
          <pb-info-tooltip
            description="Add images of your business, products, services, menus, customer interaction etc."
            hint="Upload photos of customers enjoying your place of business.These are the same images you would post on social media.">
          </pb-info-tooltip>
          <span class="note">(50 photos Max)</span>
        </div>
      </div>
      <span class="note" style="margin-left: 0px;">Image files supported – JPEG, PNG</span>
      <pb-drag-and-drop-area [displayLoader]="isImageLoading" [hasTitle]="true" (fileChanged)="addImages($event)"
                             [imagesCount]="galleryImagesCount" [imagesCountRestriction]="50"></pb-drag-and-drop-area>
      <div class="error">{{ imagesErrorText }}</div>
      <div class="row">
        <pb-image-preview #galleryPreview (deleteImageEmitter)="deleteImage($event)"></pb-image-preview>
      </div>
    </div>
  </section>

  <!-- <section pb-section caption="Additional Information">
    <div tooltip>
      <pb-info-tooltip
        description="Here is an additional section where you can add more information about your business products and services.">
      </pb-info-tooltip>
    </div>
    <div body>
      <div class="row">
        <pb-textarea name="freeForm" label="Free form (optional) - additional description of your products, or services"
          #merchantFreeForm="ngModel" [(ngModel)]="merchant.freeForm"
          placeholder="Here is an additional section where you can add more information about your business products and services."
          note="(Lim 300 characters)" maxlength="300" class="full-width" pbTooltip customElementQuery=".body">
          <pb-error-message [errors]="merchantFreeForm.errors"></pb-error-message>
        </pb-textarea>
      </div>
    </div>
  </section> -->

  <section pb-section caption="Website (Optional)" class="webSiteSection">
    <div body>
      <div class="tooltip-title">
        <div class="global-caption">Website URL</div>
        <br>
        <pb-info-tooltip description="Example: www.Caldapizza.com" note="(Note - The www is already pre-filled)">
        </pb-info-tooltip>
      </div>
      <div class="websiteFields">
        <pb-input label="Enter your website url:" placeholder="https://example.com" name="site"
                  [(ngModel)]="merchantURL" class="input site-input" [pattern]="websiteUrlMask" pbTooltip
                  customElementQuery=".body"></pb-input>
      </div>
    </div>
  </section>

  <section *ngIf="brand.showCovidPolicies()" pb-section caption="COVID-19 Safety & Cleaning Information"
           class="covidSection">
    <div tooltip>
      <pb-info-tooltip description="COVID-19 Safety & Cleaning Information"
                       hint="Add a link to your COVID-19 Safety Details or create a custom list"></pb-info-tooltip>
    </div>
    <div body>
      <div class="col">
        <input type="checkbox" name="covid-option" (change)="toggleCovidOption()"
               [checked]="merchant.covidOptions.enabled" class="global-caption">
        <b>Add a link to your COVID-19 Safety Details or create a custom list</b>
      </div>
      <br><br>
      <div class="global-caption">Select One</div>
      <br>
      <div class="col" style="max-width: 645px;">
        <input type="radio" name="covid-option1" key="U" value="U" [disabled]="!merchant.covidOptions.enabled"
               (change)="covidOptionChange('U')" [checked]="merchant.covidOptions.type === 'U'" class="radio">
        Add a Link to your COVID-19 Information on your website.
        <pb-input label="Cut and Paste URL:" name="covid_options_url" required placeholder="https://"
                  [disabled]="!merchant.covidOptions.enabled || merchant.covidOptions.type !== 'U'"
                  [(ngModel)]="merchant.covidOptions.url" class="input-social" [pattern]="websiteUrlMask" pbTooltip
                  [prependLabel]="true" customElementQuery=".body" #covidOptionsUrl="ngModel">
        </pb-input>
        <div *ngIf="covidOptionsUrl.invalid && (covidOptionsUrl.dirty || covidOptionsUrl.touched)" class="error">
          <div *ngIf="covidOptionsUrl.errors.required">
            This field is required.
          </div>
        </div>
      </div>
      <br>
      <div class="col" style="max-width: 645px;">
        <input type="radio" [disabled]="!merchant.covidOptions.enabled" (change)="covidOptionChange('I')"
               name="covid-option2" key="I" value="I" [checked]="merchant.covidOptions.type === 'I'" class="radio">
        Create a list of your COVID-19 Safety and Cleaning details.
        <br><br>
        <button (click)="editCovidInstructions($event)" pb-button type="button" class="button"
                [disabled]="!merchant.covidOptions.enabled || merchant.covidOptions.type !== 'I'"
                style="width: 200px; height: 40px; margin-left: 0px;">Add / Edit Information
        </button>
        <br><br>
        <b>COVID-19 Safety And Cleaning Informations:</b>
        <ul style="list-style-type: decimal; padding-left: 15px;" [innerHtml]="textCovidInstructions">
        </ul>
      </div>
    </div>
  </section>

  <section pb-section caption="Add a Link to Google Reviews (Optional)" class="reviewLinksSection">
    <div tooltip>
      <pb-info-tooltip description="Consumers can visit your reviews page from the app."
                       hint="Google your company page and find the link to the reviews and Cut and Paste the Link."></pb-info-tooltip>
    </div>
    <div body>
      <div class="row">
        <p>Simply copy & paste the full URL for the reviews section from the desired website.
          <br><br>
          (example: Google Reviews, your company website, Yelp etc.)
        </p>
      </div>
      <div class="row">
        <div class="reviewlink-container">
          <pb-input label="Enter Review URL:" name="reviews_url" placeholder="https://"
                    [(ngModel)]="reviewsURL" class="input-social" [pattern]="websiteUrlMask" pbTooltip
                    customElementQuery=".body"></pb-input>
          <button pb-button class="review-button" type="button" white (click)="openInstruction()">Show Me
            How?
          </button>
        </div>
      </div>
    </div>
  </section>

  <section pb-section caption="Social Media (Optional)" class="socialSection">
    <div tooltip>
      <pb-info-tooltip
        description="Consumers can visit your social media pages from {{ brand.name }} }} to learn more from your customers. Make sure to include all Social Media Links."
        hint="Google the Social Media Sites and Cut and Paste those Links."></pb-info-tooltip>
    </div>
    <div body>
      <div class="row">
        <p>(Cut &amp; Paste the Full Social Media Link from your browser)
          <br/>example - https://www.facebook.com/yourbusiness</p>
      </div>
      <div class="row">
        <div class="social-container">
          <img src="/assets/images/ic_facebook.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="facebook"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.facebook" class="input-social"
                    [pattern]="facebookUrlMask" pbTooltip customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_instagram.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="instagram"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.instagram" class="input-social"
                    [pattern]="instagramUrlMask" pbTooltip customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_twitter.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="twitter"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.twitter" class="input-social" pbTooltip
                    [pattern]="twitterUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_youtube.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="youtube"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.youtube" class="input-social" pbTooltip
                    [pattern]="youtubeUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_linkedin.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="linkedin"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.linkedin" class="input-social" pbTooltip
                    [pattern]="linkedinUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_tiktok.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="tiktok"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.tiktok" class="input-social" pbTooltip
                    [pattern]="tiktokUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_pinterest.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="pininterest"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.pininterest" class="input-social" pbTooltip
                    [pattern]="pininterestUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_snapchat.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="snapchat"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.snapchat" class="input-social" pbTooltip
                    [pattern]="snapchatUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_flickr.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="flickr"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.flickr" class="input-social" pbTooltip
                    [pattern]="flickrUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_tumblr.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="tumblr"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.tumblr" class="input-social" pbTooltip
                    [pattern]="tumblrUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_meetup.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="meetup"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.meetup" class="input-social" pbTooltip
                    [pattern]="meetupUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
        <div class="social-container">
          <img src="/assets/images/ic_evenbright.png" class="social-icon" alt="standard image"/>
          <pb-input placeholder="Cut & Paste" name="evenbright"
                    [(ngModel)]="merchant.socialUrls && merchant.socialUrls.evenbright" class="input-social" pbTooltip
                    [pattern]="evenbrightUrlMask" customElementQuery=".body" inputTrim="true"></pb-input>
        </div>
      </div>
    </div>
  </section>


  <section pb-section-required caption="Set password"
           *ngIf="!merchant.creationCompleted && merchant.isHolding">
    <div body>
      <div class="row">
        <pb-input type="password" name="password" #passwordRef="ngModel" [(ngModel)]="password" label="New Password"
                  placeholder="Enter new password" class="element" required pbTooltip
                  customElementQuery=".body"></pb-input>
      </div>
      <div class="row">
        <pb-input type="password" name="password-repeat" [equal]="passwordRef.value" [(ngModel)]="passwordRepeat"
                  label="New Password Repeat" placeholder="Repeat new password" class="element" required pbTooltip
                  customElementQuery=".body"></pb-input>
      </div>
    </div>
  </section>

  <section pb-section-required caption="Terms Of Use"
           *ngIf="!merchant.creationCompleted && merchant.isHolding">
    <div body>
      <pb-terms-of-use [brandName]="brand.name" (isAgreeChange)="onAgreeChange($event)"></pb-terms-of-use>
    </div>
  </section>

  <footer>
    <button pb-button type="submit" class="button" [disabled]="disableSaveButton">Save</button>
  </footer>
</form>
