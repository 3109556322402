import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuard, RegisterGuard, PaymentGuard, HoldingCompanyGuard } from './guards/public_api';
import { DataTransferService } from './services/data-transfer/data-transfer.service';
import { ImagesService } from '../campaign/services/campaign-service/images.service';
import { TokenInterceptor } from './services/token.interceptor';
import { PromotionsService } from './services/promotions/promotions.service';
import { CouponsService } from './services/coupons/coupons.service';
import { CampaignsService } from './services/campaigns/campaigns.service';

/**
 * Core module.
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    AuthGuard,
    RegisterGuard,
    PromotionsService,
    CouponsService,
    CampaignsService,
    PaymentGuard,
    HoldingCompanyGuard,
    DataTransferService,
    ImagesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ]
})
export class CoreModule { }
