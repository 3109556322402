import { Http } from '@angular/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { StateMapper } from './mappers/state.mapper';
import { StateDto } from './dtos/state.dto';
import { TokenStorageService } from '../token-storage.service';
import { State } from '../../models/state';
import { Rates } from '../../models/rates';
import { RatesDto } from './dtos/rates.dto';


const UTILS_URL = 'utils';

/**
 * Core http service provides infrastructural methods.
 */
@Injectable()
export class UtilsService {
  /**
   * .ctor
   * @param http Http
   */
  constructor(private http: Http,
              private stateMapper: StateMapper,
              private tokenService: TokenStorageService) { }

  /**
   * Gets a list of US states.
   */
  public async getStates(): Promise<State[]> {
    return this.http.get(`${environment.apiEndpoint}/${UTILS_URL}/states/`)
      .map(r => r.json() as StateDto[])
      .map(r => r.map(v => this.stateMapper.mapToModel(v)))
      .toPromise();
  }

   /**
   * Gets a list of US states.
   */
  public async getUSStates(): Promise<State[]> {
    return this.http.get(`${environment.apiEndpoint}/${UTILS_URL}/us-states/`)
      .map(r => r.json() as StateDto[])
      .map(r => r.map(v => this.stateMapper.mapToModel(v)))
      .toPromise();
  }

   /**
   * Gets a list of US states.
   */
  public async getCanadaStates(): Promise<State[]> {
    return this.http.get(`${environment.apiEndpoint}/${UTILS_URL}/canada-states/`)
      .map(r => r.json() as StateDto[])
      .map(r => r.map(v => this.stateMapper.mapToModel(v)))
      .toPromise();
  }

  /**
   * Returns rates object for merchant.
   */
  public getRates(): Promise<Rates> {
    return this.http.get(`${environment.apiEndpoint}/${UTILS_URL}/rates/`)
    .map(r => r.json() as RatesDto)
    .map(r => {
      const rates = new Rates();
      rates.couponRate = r.COUPON_RATE;
      rates.messageRate = r.MESSAGE_RATE;
      rates.promotionRate = r.PROMOTION_RATE;
      rates.subscriptionRate = r.SUBSCRIPTION_RATE;
      return rates;
    })
    .toPromise();
  }

  /**
   * Gets a profanity list.
   */
  public async getProfanityList(): Promise<State[]> {
    return this.http.get(`${environment.apiEndpoint}/${UTILS_URL}/profanity-list/`)
      .map(r => r.json())
      .toPromise();
  }

  /**
   * Gets a push alert samples.
   */
  public async getPushAlertSamples(): Promise<State[]> {
    return this.http.get(`${environment.apiEndpoint}/${UTILS_URL}/push-alert-samples/`)
      .map(r => r.json())
      .toPromise();
  }
}
