<pb-breadcrumbs *ngIf="report" [items]="['Reports', report.reportDate | date: 'MMMM dd']"></pb-breadcrumbs>

<header pb-page-header>
  <div left>
    <a class="row" [routerLink]="['/reports']">
      <pb-icon name="#iconBackArrow" class="back-arrow"></pb-icon>Reports
    </a>
  </div>
  <div right>
    <button pb-button small white class="button" (click)="deleteReport($event)">Delete</button>
    <button pb-button small class="button" (click)="exportToPdf($event)">Export to PDF</button>
  </div>
</header>

<div class="row row-margins">
  <div class="date" *ngIf="report">{{ report.start | date: 'MM/dd/yyyy' }} - {{ report.end | date: 'MM/dd/yyyy' }}</div>
  <div class="info-container">
    <div class="info">
      <div class="info__text">Legal Name of Business</div>
      <div class="info__data" *ngIf="merchant">{{ merchant.name }}</div>
    </div>
    <div class="info">
      <div class="info__text">Client ID</div>
      <div class="info__data" *ngIf="merchant">{{ merchant.personalId }}</div>
    </div>
  </div>
</div>

<div class="row" *ngIf="report">
  <pb-stat-item name="# of Active Coupons" [number]="activeCouponCount"></pb-stat-item>
  <pb-stat-item name="# of Promotions" [number]="activePromotionCount"></pb-stat-item>
  <pb-stat-item name="# of Messages" [number]="messagesCount"></pb-stat-item>
  <pb-stat-item name="# of Redemptions" [number]="redemptions"></pb-stat-item>
</div>

<pb-activity-stat *ngFor="let coupon of campaigns" [data]="coupon"></pb-activity-stat>
