import { Component, Inject } from '@angular/core';
import { MD_DIALOG_DATA } from '@angular/material';
import { PopupResult } from './popup-result.enum';


/**
 * Popup component.
 */
@Component({
  selector: 'pb-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  /**
   * .ctor
   * @param data Passed to the popup data.
   */
  constructor(@Inject(MD_DIALOG_DATA) public data: any) {
    this.caption = data.caption;
    this.message = data.message;
    this.confirm = data.confirm;
    this.buttonTitle = data.buttonTitle;
    this.showCloseButton = data.showCloseButton;
  }

  /**
   * Result from popup.
   */
  public popupResult = PopupResult;

  /**
   * Popup caption.
   */
  public caption: string;

  /**
   * Popup message text.
   */
  public message: string;

  /**
   * If the popup has confirmation buttons.
   */
  public confirm = false;

  /**
   * Information button text.
   */
  public buttonTitle: string;

  /**
   * If the popup has close button.
   */
  public showCloseButton = false;

  /**
   * If the information buttons are visible.
   */
  public get showButtons() {
    return !!this.buttonTitle;
  }

}
