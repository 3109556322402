import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { map, switchMap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Coupon, Merchant, TokenStorageService } from "../../../core";
import { PromoCode } from "../../../core/models/promo-code";
import { CampaignMapper } from "./campain.mapper";
import { CouponDto } from "./dto/coupon-dto";
import { PromoCodeDto } from "./dto/promo-code.dto";
import { ImagesService } from "./images.service";
import { PromoCodeMapper } from "./promo-code.mapper";



/**
 * Common part for the coupons URL.
 */
const PATH = "coupons";
const PREMIUM_PATH = "premium";
/**
 * Coupon service.
 */
@Injectable()
export class CouponService {
  /**
   * Campaign mapper.
   */
  private mapper = new CampaignMapper();
  private promocodeMapper = new PromoCodeMapper();

  /**
   * @constructor
   */
  public constructor(
    private http: HttpClient,
    private httpclient: Http,
    private tokenService: TokenStorageService,
    private imagesService: ImagesService
  ) {}

  /**
   * Create a coupon then add coupons images.
   * @param coupon Coupon data.
   * @param merchant Merchant data.
   * @return returns ID coupon after success
   */
  public createCouponWithLogo(
    coupon: Coupon,
    merchant: Merchant
  ): Observable<number> {
    return this.createCoupon(coupon, merchant).pipe(
      map(({ id }) => ({ couponId: id, merchantId: merchant.id })),
      switchMap(({ couponId, merchantId }) => {
        const {
          redemptionOptions: { inStoreImage },
        } = coupon;
        if (inStoreImage.blob !== null) {
          return this.uploadInstoreImage(
            coupon,
            merchantId,
            couponId
          ).switchMap(() => Observable.of(couponId));
        } else {
          return Observable.of(couponId);
        }
      })
    );
  }

  /**
   * Create new coupon.
   * @param coupon Coupon data.
   * @param merchant Merchant model.
   */
  public createCoupon(
    coupon: Coupon,
    merchant: Merchant
  ): Observable<CouponDto> {
    const urlPath = `${environment.apiEndpoint}/merchants/${merchant.id}/${PATH}/`;
    const data = this.mapper.convertCouponToDto(coupon, merchant);

    return this.http.post<CouponDto>(urlPath, data);
  }

  /**
   * Upload the coupon images.
   * @param coupon Coupon data.
   * @param merchantId Merchant ID.
   * @param couponId Coupon ID.
   */
  private uploadInstoreImage(
    coupon: Coupon,
    merchantId: number,
    couponId: number
  ): Observable<void> {
    return this.imagesService.uploadInStoreImage(
      coupon.redemptionOptions.inStoreImage.blob,
      merchantId,
      couponId,
      PATH
    );
  }

  /**
   * Upload the coupon images.
   * @param coupon Coupon data.
   * @param merchantId Merchant ID.
   * @param couponId Coupon ID.
   */
  private uploadImages(
    coupon: Coupon,
    merchantId: number,
    couponId: number
  ): Observable<void> {
    return this.imagesService.uploadCampaignImages(
      null, //coupon.campaignStyle.couponImageMain.blob,
      null, //coupon.campaignStyle.couponImageFull.blob,
      coupon.redemptionOptions.inStoreImage.blob,
      merchantId,
      couponId,
      PATH
    );
  }

  /**
   * Get coupon data by ID and convert to the coupon model.
   * @param id Coupon ID.
   * @param merchantId Merchant ID.
   */
  public getCouponById(id: number, merchantId: number): Observable<Coupon> {
    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/${PATH}/${id}/`;

    return this.http
      .get<CouponDto>(urlPath)
      .pipe(
        map((response: CouponDto) =>
          this.mapper.convertCouponDtoToModel(response)
        )
      );
  }

  /**
   * Get coupon data by ID and convert to the coupon model.
   * @param id Coupon ID.
   * @param merchantId Merchant ID.
   */
  public getPremiumCouponById(
    id: number,
    merchantId: number
  ): Observable<Coupon> {
    const urlPath = `${environment.apiEndpoint}/merchants/${merchantId}/${PREMIUM_PATH}/${id}/`;

    return this.http
      .get<CouponDto>(urlPath)
      .pipe(
        map((response: CouponDto) =>
          this.mapper.convertCouponDtoToModel(response)
        )
      );
  }

  /**
   * Update coupon info.
   * @param coupon Coupon data.
   * @param merchant Merchant data.
   * @return returns ID coupon after success
   */
  public updateCoupon(coupon: Coupon, merchant: Merchant): Observable<number> {
    const {
      campaignStyle: { id },
    } = coupon;
    const {
      redemptionOptions: { inStoreImage, inStoreImageRemoved },
    } = coupon;
    const urlPath = `${environment.apiEndpoint}/merchants/${merchant.id}/${PATH}/${id}/`;
    const data = this.mapper.convertCouponToDto(coupon, merchant);

    if (inStoreImage.blob !== null) {
      return this.updateCouponWithInStoreImage(
        urlPath,
        data,
        coupon,
        merchant.id
      );
    }

    if (inStoreImageRemoved === true) {
      const body = {
        in_store_image: true,
      };

      return this.imagesService
        .deleteCampaignImages(merchant.id, id, PATH, body)
        .pipe(
          switchMap(() => this.http.patch<any>(urlPath, data)),
          switchMap(() => Observable.of(id))
        );
    }

    return this.http
      .patch<void>(urlPath, data)
      .switchMap(() => Observable.of(id));
  }

  /**
   * Update coupon data and update images.
   * @param urlPath URL.
   * @param data Coupon DTO.
   * @param coupon Coupon Model.
   * @param merchantId Merchant ID.
   */
  private updateCouponWithImages(
    urlPath,
    data,
    coupon,
    merchantId
  ): Observable<any> {
    return this.http
      .patch<any>(urlPath, data)
      .pipe(switchMap(({ id }) => this.uploadImages(coupon, merchantId, id)));
  }

  /**
   * Update coupon data and update images.
   * @param urlPath URL.
   * @param data Coupon DTO.
   * @param coupon Coupon Model.
   * @param merchantId Merchant ID.
   */
  private updateCouponWithInStoreImage(
    urlPath,
    data,
    coupon,
    merchantId
  ): Observable<any> {
    return this.http
      .patch<any>(urlPath, data)
      .pipe(
        switchMap(({ id }) => this.uploadInstoreImage(coupon, merchantId, id))
      );
  }

  /**
   * Get coupon data by ID and convert to the coupon model.
   * @param id Coupon ID.
   * @param merchantId Merchant ID.
   */
  public getPromoCode(code: string): Promise<PromoCode> {
    return this.httpclient
      .get(`${environment.apiEndpoint}/promocodes/${code}/`, {
        headers: this.tokenService.baseHeaders,
      })
      .map((r) => r.json() as PromoCodeDto)
      .map((r) => this.promocodeMapper.mapToModel(r))
      .toPromise();
  }
}
