import { DivisionDto } from '../dtos/division.dto';
import { Division } from '../../../models/merchant';


export default function (divisionsDto: DivisionDto[]): Division[] {
  const divisions: Division[] = [];

  if (divisionsDto === null) {
    return divisions;
  }

  divisionsDto.forEach(divisionDto => {
    const division = new Division();
    division.id = +divisionDto.id;
    division.personalId = divisionDto.merchant_cid;
    division.dbaName = divisionDto.merchant_dba_name;
    division.name = divisionDto.name;
    division.logo = divisionDto.logo;
    divisions.push(division);
  });

  return divisions;
}
