<div class="container-login">
  <div class="icon-container">
    <pb-icon name="#iconColoredCampaign" class="icon"></pb-icon>
  </div>
  <div class="wrap-login">
    <div class="login-form">
      <pb-signup-page></pb-signup-page>
    </div>
    <div class="login-form">
      <pb-login-page></pb-login-page>
    </div>
  </div>
</div>