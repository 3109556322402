import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CanadaMapService } from './canada-map.service';
import { CanadaMapComponent } from './canada-map.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CanadaMapComponent],
  providers: [CanadaMapService],
  exports: [CanadaMapComponent]
})
export class CanadaMapModule { }
