import { Component, Input } from '@angular/core';

@Component({
  selector: 'pb-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {

  @Input()
  public errors: { [key: string]: string | string[] };

  /**
   * Get string with an errors words.
   */
  public getErrorWords(words) {
    return words.join(', ');
  }
}
