<!--<div class="link-promo-container" *ngIf="isFreePlan()">-->
<!--  <img src="/assets/images/link_promo.png" class="link-promo-image" />-->
<!--</div>-->

<!--<div class="link_promo_text" *ngIf="isFreePlan()">-->
<!--  <div class="heading">-->
<!--    Add a link or redeem code or both for only $ {{ campaignPlan.custom_link_fee }}-->
<!--  </div>-->
<!--  <div class="description">-->
<!--    Pay no commissions. Keep 100% of the revenue 90% of customers are willing to check out at this point-->
<!--  </div>-->
<!--</div>-->
<!--<section class="container" *ngIf="isFreePlan()">-->
<!--  <div style="display: flex; justify-content: center;">-->
<!--    <pb-checkbox-group (onSelect)="featureOptionsSelected($event)" [disabled]="isEdit()"-->
<!--      [options]="redemptionOptions.featureOptions">-->
<!--    </pb-checkbox-group>-->
<!--  </div>-->
<!--</section>-->

<form #form="ngForm" name="form" (ngSubmit)="validateForm()" [class.form-submitted]="form.submitted">
  <h2 class="section-title">
    <div class="tooltip-title">
      Added Features
    </div>
  </h2>

  <section class="container" *ngIf="showFearures()">
    <div>
      <div class="tooltip-title">
        <div class="global-caption">Add a custom link to your eCommerce site</div>
        <div class="global-optional">(optional)</div>
        <pb-info-tooltip description='E-commerce Link/ Reservation System: Enter the URL (webpage) where you want the customer to checkout Retail.'>
        </pb-info-tooltip>
      </div>
      <div class="global-subtitle">Create a custom call-to-action for your site.</div>
      <div class="form-group">
        <div style="display: flex;">
          <select [(ngModel)]="redemptionOptions.shopOptions[0].url_type" [disabled]="!canEditCustomLink()"
            (change)="customLinkChanged($event)" name="shopoptionurl1" class="cat-select">
            <option value="" selected disabled>Select an option</option>
            <option [disabled]="isShopOptionAvailable(shopOption.url_type)" [value]="shopOption.url_type"
              *ngFor="let shopOption of redemptionOptions.shopOptionsList">
              {{shopOption.title}}</option>
          </select>
          <pb-input name="shopoption1" style="width: 100%;" placeholder="Enter a URL" [maxlength]="1024"
            [readonly]="!hasShopOptions() || !canEditCustomLink()" [(ngModel)]="redemptionOptions.shopOptions[0].url">
          </pb-input>
          <button *ngIf="hasShopOptions()" (click)="clearShopOptioins()" class="shop-option-clear">Remove</button>
        </div>
        <!-- <div style="display: flex;">
          <select [(ngModel)]="redemptionOptions.shopOptions[1].url_type" name="shopoptionurl2" class="cat-select">
            <option value="" selected disabled>Select an option</option>
            <option [disabled]="isShopOptionAvailable(shopOption.url_type)" [value]="shopOption.url_type"
              *ngFor="let shopOption of redemptionOptions.shopOptionsList">
              {{shopOption.title}}</option>
          </select>
          <pb-input name="shopoption2" style="width: 100%;" placeholder="Enter a URL" [maxlength]="100"
            [(ngModel)]="redemptionOptions.shopOptions[1].url">
          </pb-input>
        </div> -->
      </div>
    </div>
  </section>

  <section class="container" *ngIf="showFearures()">
    <div *ngIf="redemptionOptions.couponType === 'both' || redemptionOptions.couponType === 'in-store'">
      <div class="tooltip-title">
        <div class="global-caption">Add a retail redemption code.</div>
        <div class="global-required">*required</div>
        <pb-info-tooltip description="Upload a QR code, Bar code, Alpha/Numeric code that can be tracked in your Point of Sale.">
        </pb-info-tooltip>
      </div>
      <div class="global-subtitle">Select how you want people to redeem your offer.</div>
      <br>
      <pb-toggle-selector [options]="couponTypeSelector" [selected]="redemptionOptions.inStoreCouponType"
        [disabled]="!canEditCustomLink()" (onSelect)="couponTypeSelect($event)"></pb-toggle-selector>
      <br><br>
      <div *ngIf="redemptionOptions.inStoreCouponType === 'text'">
        <span class="limit60">
          Limit: 20 Characters
        </span>
        <div class="tooltip-title">
          <div class="global-caption">In-Store Coupon Code</div>
          <div class="global-required">*required</div>
          <pb-info-tooltip
            description="Enter a code (alpha/numeric) that can be accepted with your Point of Sale environment.">
          </pb-info-tooltip>
        </div>
        <br>
        <pb-input name="storeCouponCode" placeholder="ex. 10OFF" [maxlength]="20" #storeCouponCode="ngModel"
          [inlineNote]="true" [(ngModel)]="redemptionOptions.storeCouponCode" [disabled]="!isRedeemptionEnabled">
          <pb-error-message [errors]="storeCouponCode.errors"></pb-error-message>
        </pb-input>
      </div>
      <div
        *ngIf="redemptionOptions.inStoreCouponType === 'qrcode' || redemptionOptions.inStoreCouponType === 'barcode'">
        <br>
        <div style="display: flex; flex-direction: column;">
          <pb-browse-file [required]="!hasCouponImage()" [canRemove]="redemptionOptions.inStoreImage.base64"
            [buttonWidth]="'240px'" [buttonText]="'Upload a Code'" name="redemptionOptions.inStoreImage"
            #inStoreCoupon="ngModel" [(ngModel)]="redemptionOptions.inStoreImage" (removed)="removeCodeClicked()"
            (uploaded)="codeUploaded($event)">
          </pb-browse-file>
          <br>
          <div [ngClass]="(redemptionOptions.inStoreCouponType === 'qrcode')?'qr-code':'bar-code'"
            [ngStyle]="{'background-image': 'url(' + (redemptionOptions.inStoreImage.base64 ?redemptionOptions.inStoreImage.base64  : '') + ')'}"
            [class.logo-required]="!inStoreCoupon.valid || null" drag-n-drop (filesChangeEmiter)="imageDragged($event)"
            [coverInnerElements]="true" [useConcreteBlock]="true" [filesCountRestriction]="1" [filesCount]="0">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="redemptionOptions.inStoreCouponType !== 'none'" class="container flex">
    <div class>
      <div class="tooltip-title">
        <div class="global-caption">How many receptions are allowed?
        </div>
        <div class="global-required">*required</div>
        <pb-info-tooltip description="Select the number of times a coupon can be redeemed by an individual customer."
          hint="A high value coupon can be limited to only 1 use, or you can make your coupon available for unlimited use.">
        </pb-info-tooltip>
      </div>
      <div class="global-subtitle">This is for in-store purchase only.</div>
      <br>
      <div *ngIf="redemptionOptions.couponType === 'both' || redemptionOptions.couponType === 'in-store'" pbRadioGroup
        [(ngModel)]="redemptionOptions.storeCouponLimit" name="couponLimit">
        <div class="flex">
          <pb-toggle-selector [options]="couponLimitSelector" [selected]="redemptionOptions.storeCouponLimit"
            (onSelect)="couponRedeemLimitSelect($event)"></pb-toggle-selector>
          <pb-input *ngIf="redemptionOptions.storeCouponLimit === 'custom'"
            (onFocus)="redemptionOptions.storeCouponLimit = 'custom'" type="number" label=""
            name="in-store-custom-limit-redeem" placeholder="" note="per customer" [inlineReverse]="true"
            [required]="redemptionOptions.storeCouponLimit === 'custom'"
            [(ngModel)]="redemptionOptions.storeCouponLimitCount" [min]=1
            [disabled]="redemptionOptions.storeCouponLimit === 'unlimited'" [max]=100>
          </pb-input>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="container">
    <div>
      <div class="tooltip-title">
        <div class="global-caption">Edit Instructions*</div>
        <pb-info-tooltip
          description="You can utilize the default language provided, or edit / add your own Instructions.">
        </pb-info-tooltip>
        <div class="edit-coupon">
          <span>Lim: 100 Char</span>
          <a (click)="redemptionOptions.resetCouponInstructions()" class="reset">Reset to Default</a>
        </div>
      </div>
      <br>
      <div *ngIf="redemptionOptions.couponType === 'both' || redemptionOptions.couponType === 'in-store'">
        <pb-input label="In Store:" name="storeCouponInstructions" #storeCouponInstructions="ngModel" placeholder=""
          [maxlength]="100" [prependLabel]="true" pbTooltip customElementQuery=".body"
          [(ngModel)]="redemptionOptions.storeCouponInstructions">
        </pb-input>
        <pb-error-message [errors]="storeCouponInstructions.errors"></pb-error-message>
      </div>
      <div *ngIf="redemptionOptions.couponType === 'both' || redemptionOptions.couponType === 'online'">
        <pb-input label="Online:" name="onlineCouponInstructions" #onlineCouponInstructions="ngModel"
          placeholder="Tap Code to Copy & Paste in Shopping Cart Checkout" [prependLabel]="true" [maxlength]="100"
          [(ngModel)]="redemptionOptions.onlineCouponInstructions">
        </pb-input>
        <pb-error-message [errors]="onlineCouponInstructions.errors"></pb-error-message>
      </div>
    </div>
  </section> -->

  <!--div *ngIf="isFreePlan()">
    <section class="container bordered">
      <div class="section-header">
        Email Blasts
      </div>
      <div class="content">
      <div class="tooltip-title">
        <div class="global-caption">Add eMail Marketing?</div>
        <div class="global-optional">(optional)</div>
        <pb-info-tooltip
          description="You can include this campaign in weekly eMail blasts for a low cost of $39.99 per state / province, per month. ">
        </pb-info-tooltip>
      </div>
      <div class="global-subtitle">Select the States and/or Provinces where this campaign should be included in the
        eMail blasts.</div>
      <div class="premium-states">
        <<div class="bold padder">
          Currently active states / provinces.
        </div>>
        <div class="p-states">
          <div *ngFor="let state of redemptionOptions.premiumEmailStates">
            <li class="state-item">{{getStateName(state)}}</li>
          </div>
        </div>
      </div>
      <br />
      <div class="row" style="justify-content: center;" *ngIf="canAddEmailStates()">
        Selection Options:
      </div>
      <br />
      <div class="row add-states" *ngIf="canAddEmailStates()">
        <button pb-button [disabled]="!canAddEmailStates()" class="button" type="button"
          (click)="openStateSelector('map', 'email')">
          <pb-icon name="#iconMap" class="icon"></pb-icon>Select from map
        </button>
        <button pb-button [disabled]="!canAddEmailStates()" class="button" type="button"
          (click)="openStateSelector('list', 'email')">
          <pb-icon name="#iconList" class="icon"></pb-icon>Select from list
        </button>
      </div>
      </div>
    </section>
  </div-->

</form>
