/**
 * Merchant's message view model.
 */
export class Message {
  public id: number;
  public name: string;
  public message: string;
  public is_enabled: boolean;
  public message_type: string;
  public logo: string;

  public clicks: number;
  public views: number;

  /** draft fields, which is not discussed */
  public startDate: string;
  public endDate: string;
  public locations: string[];
  public isSent: false;

  /**
   * Url for view coupon.
   */
  public viewUrl: string;
}
