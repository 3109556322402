import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MdDialogRef} from '@angular/material';
import {Observable, Subject} from 'rxjs/Rx';
import {finalize, map, switchMap, takeUntil, tap} from 'rxjs/operators';

import {
  AuthService,
  BrandMapper,
  BrandService,
  Division,
  Invoice,
  InvoiceService,
  Merchant,
  MerchantService,
  PlaceService,
  User,
  WebApp
} from '../core';
import {PopupResult, PopupService, SpinnerPopupComponent} from '../popup';
import {getImageDimensions} from '../../utils';
import {MerchantApp} from "../core/models/merchant-app";

/**
 * Side bar component.
 */
@Component({
  selector: 'pb-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy {
  /**
   * Destroy subject.
   */
  private destroy$ = new Subject();
  /**
   * Spinner.
   */
  private spinner: MdDialogRef<SpinnerPopupComponent>;

  public isLogoRect = false;
  public divisionRectLogos = {};
  /**
   * User.
   */
  public user$: Observable<User>;
  public userFullName: string;
  public userEmail: string;
  public showAutoMessages: boolean = false;

  public canControlWebApps: boolean = false;
  public currentWebApp: WebApp = null;

  public loginUrl: string = '/login';

  /**
   * Invoice.
   */
  public currentInvoice$: Observable<Invoice>;

  /**
   * Disabled Activities.
   */
  public isDisabledActivities$: Observable<boolean>;
  public brand: MerchantApp = new MerchantApp({
    id: 'gettinlocal',
    name: 'GettinLocal',
    logo_image: '',
    allow_multiple_profiles: false
  });

  /**
   * @constructor
   */
  constructor(
    private authService: AuthService,
    private router: Router,
    private brandMapper: BrandMapper,
    private placeService: PlaceService,
    private merchantService: MerchantService,
    private popupService: PopupService,
    private brandService: BrandService,
    private invoiceService: InvoiceService,
  ) {
    this.brandService.brand.subscribe((b) => {
      this.brand = b;
    });
  }

  public logOut(): void {
    this.popupService
      .confirm('Are you sure you want to Logout? ', 'Logout')
      .then(result => {
          if (result === PopupResult.YES) {
            this.authService.logout();
            this.router.navigate([this.brand.brand_login_path]);
          }
        },
      );
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.user$ = this.authService.onUserChanged.pipe(
      takeUntil(this.destroy$),
      map((user: User) => {
        if (user.merchant) {
          this.brandService.brand.next(this.brandMapper.mapToModel(user.merchant.app));
          this.showAutoMessages = user.merchant.isAutoHeartingEnabled();

          this.canControlWebApps = user.merchant.webapps.length > 0;
          if (this.canControlWebApps) {
            this.currentWebApp = user.merchant.webapps.find(w => w.id === user.currentWebAppId) || user.merchant.webapps[0];
          } else {
            this.currentWebApp = null;
          }
        } else {
          this.canControlWebApps = false;
          this.currentWebApp = null;
        }

        this.userEmail = user ? user.email : '';
        this.userFullName = user && user.firstName ? user.firstName + (user.lastName ? ' ' + user.lastName : '') : this.userEmail;
        if (user) {
          getImageDimensions(user.originalMerchant.logo).then(res => {
            this.isLogoRect = res.w !== res.h;
          }).catch(error => console.error(error));
        }
        user.divisions = user.divisions.sort(Division.idComparer);
        user.divisions.forEach(d => {
          getImageDimensions(d.logo).then(res => {
            this.divisionRectLogos[d.logo] = res.w !== res.h;
          }).catch(error => console.error(error));
        });
        return user;
      }),
    );
    this.isDisabledActivities$ = this.user$
      .switchMap(user =>
        this.placeService
          .getPlaces$(user.currentMerchantId)
          .map(
            places => places.length === 0 || !user.merchant.creationCompleted,
          ),
      )
      .share();

    this.currentInvoice$ = this.user$.switchMap(user =>
      this.invoiceService.getCurrent$(user.currentMerchantId),
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Count merchant email.
   */
  public get userName(): string {
    return this.userEmail;
  }

  public async addNewBusiness(merchantId: number): Promise<void> {
    const message = 'Are you sure you want to Add a New Business?';
    const caption = 'Add New Business';
    const result = await this.popupService.confirm(message, caption);

    if (result === PopupResult.YES) {
      this.merchantService.createDivision(merchantId).pipe(
        tap(() => this.startSpinner()),
        switchMap(({id}) => this.authService.changeCurrentMerchant(id)),
        tap(() => this.router.navigate(['/profile'])),
        tap(() => window.location.reload()),
        finalize(() => this.stopSpinner())
      ).subscribe();
    }
  }

  /**
   * Changing state of merchant.
   */
  public async changeStatusOfMerchant(isActive: boolean): Promise<void> {
    let message = '';

    if (isActive) {
      message = 'Are you sure to restore your account?';
    } else if (!isActive) {
      message = 'Are you sure to suspend your account?';
    }

    const result = await this.popupService.confirm(
      message,
      'Changing state of account',
    );

    if (result === PopupResult.YES) {
      const spinner = this.popupService.spinner();

      this.user$
        .switchMap(user =>
          this.merchantService.changeMerchantState$(
            user.currentMerchantId,
            isActive,
          ),
        )
        .takeUntil(this.destroy$)
        .subscribe(() => {
          spinner.close();
        });
    }
  }

  public onChangeCurrentMerchant(merchant: Merchant): void {
    const spinner = this.popupService.spinner();
    this.authService
      .changeCurrentMerchant(merchant.id)
      .takeUntil(this.destroy$)
      .subscribe(() => {
        spinner.close();
        if (merchant.isHolding) {
          this.router.navigate(['/profile']);
          if (this.router.url === '/profile') {
            window.location.reload();
          }
        } else if (this.router.url !== '/dashboard') {
          this.router.navigate(['/dashboard']);
        } else {
          window.location.reload();
        }
      });
  }

  public onChangeCurrentWebApp(webapp: WebApp): void {
    this.currentWebApp = webapp;
    const spinner = this.popupService.spinner();
    this.authService
      .changeCurrentWebApp(webapp.id)
      .takeUntil(this.destroy$)
      .subscribe(() => {
        spinner.close();
        if (this.router.url !== '/dashboard') {
          this.router.navigate(['/dashboard']);
        } else {
          window.location.reload();
        }
      });
  }

  /**
   * Show spinner.
   */
  private startSpinner(): void {
    this.spinner = this.popupService.spinner();
  }

  /**
   * Remove spinner.
   */
  private stopSpinner(): void {
    this.spinner.close();
  }
}
