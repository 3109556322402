import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { environment } from '../../../../environments/environment';
import { CategoryMapper } from './mappers/category.mapper';
import { CategoryDto } from './dtos/category.dto';
import { Category } from '../../models/category';
import { Observable } from 'rxjs/';

const CATEGORIES_URL = 'categories';
const BRANDS_URL = 'brands';

/**
 * Categories http service provides methods to work with categories.
 */
@Injectable()
export class CategoriesService {
  /**
   * .ctor
   * @param http Http
   * @param categoryMapper category mapper
   */
  constructor(private http: Http, private categoryMapper: CategoryMapper) {}

  /**
   * Gets a list of categories in flat mode.
   */
  public getAllFlat(): Promise<Category[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${CATEGORIES_URL}/`)
      .map(r => r.json() as CategoryDto[])
      .map(r => r.map(v => this.categoryMapper.mapToModel(v)))
      .toPromise();
  }

  /**
   * Gets a list of categories in tree mode.
   */
  public getAllTree(): Promise<Category[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${CATEGORIES_URL}/tree/`)
      .map(r => r.json() as CategoryDto[])
      .map(r => r.map(v => this.categoryMapper.mapToModel(v)))
      .toPromise();
  }

    /**
   * Gets a list of categories in tree mode.
   */
  public getTop(): Promise<Category[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${CATEGORIES_URL}/top/`)
      .map(r => r.json() as CategoryDto[])
      .map(r => r.map(v => this.categoryMapper.mapToModel(v)))
      .toPromise();
  }

  /**
   * Returns categories within specified level.
   * @param maxLevel Maximum level of tree.
   */
  public getFlatLeveled(maxLevel: number = null): Promise<Category[]> {
    return this.getFlatLeveled$(maxLevel).toPromise();
  }

  public getFlatLeveled$(maxLevel: number): Observable<Category[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${CATEGORIES_URL}/top/`)
      .map(response => response.json() as CategoryDto[])
      .map(categoriesDto =>
        categoriesDto.map(categoryDto =>
          this.categoryMapper.mapToModel(categoryDto)
        )
      )
      .map(categories => {
        /*
        const stack: any[] = [];
        const items: Category[] = [];

        stack.push(...categories);
        items.push(...categories);

        while (stack.length !== 0) {
          const item = stack.pop();
          if (!item.parent) {
            item.level = 1;
          } else {
            item.level = item.parent.level + 1;
          }

          if (maxLevel && item.level >= maxLevel) {
            continue;
          }

          for (const children of item.children) {
            children.parent = item;
            stack.push(children);
            items.push(children);
          }
        }*/

        /* FIXME: Exclude Featured category */
        return categories.filter(c => c.id !== '4d4b7105d754a06380d81259');
      });
  }

  public getMerchantAppCategories(brand_id: string): Promise<Category[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${BRANDS_URL}/${brand_id}/categories`)
      .map(response => response.json() as CategoryDto[])
      .map(categoriesDto =>
        categoriesDto.map(categoryDto =>
          this.categoryMapper.mapToModel(categoryDto)
        )
      )
      .map(categories => categories)
      .toPromise();
  }
}
