import {Injectable} from '@angular/core';

import {Mapper} from '../../abstract/mapper';
import {WebappDto} from '../dtos/webapp.dto';
import {WebApp} from '../../../models/web-app';
import {WebAppPopup} from "../../../models/webapp-popips";
import {WebappPopupDto} from "../dtos/popups.dto";
import {LoyaltyPopupsDto} from "../dtos/loyalty-popups.dto";
import {LoyaltyPopup} from "../../../models/loyalty-popup";
import {LoyaltyPopupConfig} from "../../../models/loyalty-popup-config";
import {LoyaltyPopupsConfigDto} from "../dtos/loyalty-popups-config.dto";
import {DataPopupsDto} from "../dtos/data-popups.dto";
import {DataPopup} from "../../../models/data-popup";
import {Validators} from "@angular/forms";

/**
 * State mapper.
 */
@Injectable()
export class WebappMapper implements Mapper<WebappDto, WebApp> {
  /**
   * @inheritdoc
   * @param {MerchantAppDto} dto MerchantApp DTO.
   */
  public mapToModel(dto: WebappDto): WebApp {
    return new WebApp({
      id: dto.id,
      name: dto.name
    });
  }

  public mapToPopupsModel(dto: WebappPopupDto): WebAppPopup {
    return new WebAppPopup({
      id: dto.id,
      data_popup: dto.data_popup,
      data_popup_delay: dto.data_popup_delay,
      show_install_popup: dto.show_install_popup,
      install_popup_delay: dto.install_popup_delay,
      sponsors: dto.sponsors.map(s => this.mapToLoyaltyPopupsModel(s))
    });
  }

  public mapToLoyaltyPopupsModel(dto: LoyaltyPopupsDto): LoyaltyPopup {
    return new LoyaltyPopup({
      id: dto.id,
      name: dto.name,
      content: dto.content,
      popupType: dto.popup_type,
      popupImage: dto.popup_image,
      popup_action_url: dto.popup_action_url,
      logo_image: dto.logo_image,
      primaryButtonText: dto.primary_button_text,
      primaryButtonUrl: dto.primary_button_url,
      secondaryButtonText: dto.secondary_button_text,
      primaryButtonColor: dto.primary_button_color,
      primaryButtonBorderColor: dto.primary_button_border_color,
      primaryButtonTextColor: dto.primary_button_text_color,
      secondaryButtonColor: dto.secondary_button_color,
      secondaryButtonBorderColor: dto.secondary_button_border_color,
      secondaryButtonTextColor: dto.secondary_button_text_color,
      backgroundColor: dto.background_color,
      popupActionUrl: dto.popup_action_url
    });
  }

  public mapToDataPopupsModel(dto: DataPopupsDto): DataPopup {
    return new DataPopup({
      id: dto.id,
      name: dto.name,
      logo: dto.logo,
      title: dto.title,
      subtitle: dto.subtitle,
      submit_button_text: dto.submit_button_text,
      ask_phone: dto.ask_phone,
      phone_placeholder: dto.phone_placeholder,
      ask_zip: dto.ask_zip,
      zip_placeholder: dto.zip_placeholder,
      ask_email: dto.ask_email,
      email_placeholder: dto.email_placeholder,
      ask_type: dto.ask_type,
      user_type_question: dto.user_type_question,
      i_agree: dto.i_agree,
      i_agree_text: dto.i_agree_text,
      receive_newsletter: dto.receive_newsletter,
      receive_newsletter_text: dto.receive_newsletter_text,
      is_enabled: dto.is_enabled
    });
  }

  public mapModelToLoyaltyPopupsDto(model: LoyaltyPopup): LoyaltyPopupsDto {
    return {
      id: model.id,
      name: model.name,
      content: model.content,
      popup_image: model.popupImage,
      popup_type: model.popupType,
      logo_image: model.logo_image,
      primary_button_text: model.primaryButtonText,
      primary_button_url: model.primaryButtonUrl,
      secondary_button_text: model.secondaryButtonText,
      primary_button_color: model.primaryButtonColor,
      primary_button_border_color: model.primaryButtonBorderColor,
      primary_button_text_color: model.primaryButtonTextColor,
      secondary_button_color: model.secondaryButtonColor,
      secondary_button_border_color: model.secondaryButtonBorderColor,
      secondary_button_text_color: model.secondaryButtonTextColor,
      background_color: model.backgroundColor,
      popup_action_url: model.popupActionUrl
    };
  }

  public mapModelToDataPopupsDto(model: DataPopup): DataPopupsDto {
    return {
      id: model.id,
      name: model.name,
      logo: model.logo,
      title: model.title,
      subtitle: model.subtitle,
      submit_button_text: model.submit_button_text,
      ask_phone: model.ask_phone,
      phone_placeholder: model.phone_placeholder,
      ask_zip: model.ask_zip,
      zip_placeholder: model.zip_placeholder,
      ask_email: model.ask_email,
      email_placeholder: model.email_placeholder,
      ask_type: model.ask_type,
      user_type_question: model.user_type_question,
      i_agree: model.i_agree,
      i_agree_text: model.i_agree_text,
      receive_newsletter: model.receive_newsletter,
      receive_newsletter_text: model.receive_newsletter_text,
      is_enabled: model.is_enabled
    };
  }

  public mapToLoyaltyPopupsConfigModel(dto: LoyaltyPopupsConfigDto): LoyaltyPopupConfig {
    return new LoyaltyPopupConfig({
      id: dto.id,
      name: dto.name,
      start: dto.start,
      end: dto.end,
      delay: dto.delay,
      repeat: dto.repeat,
      is_enabled: dto.is_enabled,
      popup: this.mapToLoyaltyPopupsModel(dto.popup)
    });
  }
}
