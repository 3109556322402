
import { Component, Inject, Input, OnInit, forwardRef } from '@angular/core';
import { UtilsService } from '../../../core';

@Component({
  selector: 'pb-activities-popup',
  templateUrl: './activities-popup.component.html',
  styleUrls: ['./activities-popup.component.scss']
})
export class ActivitiesPopupComponent implements OnInit {

  constructor(
     @Inject(forwardRef(() => UtilsService)) private utilsService: UtilsService
  ) {
  }

  @Input()
  public campaign = 0;

  @Input()
  public promotion = 0;

  @Input()
  public message = 0;

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    const rates = await this.utilsService.getRates();
    if (rates) {
      this.campaign = +rates.getRate('couponRate');
      this.promotion = +rates.getRate('promotionRate');
      this.message = +rates.getRate('promotionRate');
    }
  }
}
