import { Injectable } from '@angular/core';
import { MerchantsCoupon } from '../models/merchants-coupon';
import { CouponDto } from '../dtos/coupon.dto';
import { Mapper } from '../../abstract/mapper';
import * as moment from 'moment';
import { Campaign } from '../../campaigns/models/campaign';

/**
 * Mapper for dto.
 */
@Injectable()
export class CouponMapper implements Mapper<CouponDto, MerchantsCoupon> {
  public mapToModel(dto: CouponDto): MerchantsCoupon {
    const model = {
      id: dto.id,
      campaignId: dto.campaign,
      campaignPlan: dto.campaign_plan,
      name: dto.name,
      locations: dto.places,
      likes: dto.likes,
      impressions: dto.impressions,
      redemptions: dto.redemptions,
      startDate: dto.end,
      endDate: dto.start,
      merchantId: dto.merchantId,
      operationHours: dto.operation_hours,
      message: dto.message_text,
      keywords: dto.keywords,
      targetAuidience: {
        ageStart:
          dto.target_audience != null ? dto.target_audience.age_start : null,
        ageEnd:
          dto.target_audience != null ? dto.target_audience.age_end : null,
        radius: dto.target_audience != null ? dto.target_audience.radius : null,
        gender: dto.target_audience != null ? dto.target_audience.gender : null,
        interest:
          dto.target_audience != null ? dto.target_audience.interest : [],
      },
      // logo: dto.logo,
      in_store_image: dto.in_store_image,
      in_store_type: dto.in_store_type,
      online: dto.online,
      onlineInstructions: dto.online_instruction,
      store: dto.in_store,
      inStoreInstructions: dto.in_store_instruction,
      rules: dto.rules,
      terms: dto.terms,
      places: dto.places,
      description: dto.description,
      isCompleted: false,
      isRedeemable: dto.is_redeemable,
      isActive: false,
      image: dto.image,
      video: dto.video,
      startDateRaw: dto.start,
      endDateRaw: dto.end,
      viewUrl: `/view-promotion/${dto.id}`,
      provider: dto.provider,
      isExpired: false,
      redeemInStoreLimit: dto.in_store_redeem_limit,
      redeemInStoreLeft: dto.in_store_redeem_left,
      redeemOnlineLimit: dto.online_redeem_limit,
      redeemOnlineLeft: dto.online_redeem_left,
      oldPrice: dto.old_price,
      newPrice: dto.new_price,
      hidden: dto.hidden,
      featured: dto.featured,
    } as MerchantsCoupon;

    if (dto.start) {
      model.startDate = moment(dto.start).format('MMMM D');
      model.isActive = moment().isAfter(moment(dto.start));
    }
    if (dto.end) {
      model.endDate = moment(dto.end).format('MMMM D');
      model.isCompleted = !moment().isBefore(moment(dto.end));
    }

    return model;
  }

  /**
   * Mapping model to dto.
   * @param model model which will be mapped to dto.
   */
  public mapToDto(model: MerchantsCoupon): CouponDto {
    const dto = {
      name: model.name,
      campaign: model.campaignId,
      in_store: model.store,
      in_store_instruction: model.inStoreInstructions,
      online: model.online,
      online_instruction: model.onlineInstructions,
      rules: model.rules,
      terms: model.terms,
      places: model.places,
      likes: 0,
      impressions: 0,
      redemptions: 0,
      start: model.startDate,
      end: model.endDate,
      merchantId: model.merchantId,
      description: model.description,
      operation_hours: model.operationHours,
      budget: 0,
      message_text: model.message,
      keywords: model.keywords,
      target_audience: {
        age_start:
          model.targetAuidience != null ? model.targetAuidience.ageStart : null,
        age_end:
          model.targetAuidience != null ? model.targetAuidience.ageEnd : null,
        radius:
          model.targetAuidience != null ? model.targetAuidience.radius : null,
        gender:
          model.targetAuidience != null ? model.targetAuidience.gender : null,
        interest:
          model.targetAuidience != null ? model.targetAuidience.interest : [],
      },
      // logo: model.logo,
      in_store_image: model.in_store_image,
      image: model.image,
      video: model.video,
      id: model.id,
      is_expired: model.isExpired,
      in_store_redeem_limit: model.redeemInStoreLimit,
      in_store_redeem_left: model.redeemInStoreLeft,
      online_redeem_limit: model.redeemOnlineLimit,
      online_redeem_left: model.redeemOnlineLeft,
      old_price: model.oldPrice,
      new_price: model.newPrice,
      campaign_plan: model.campaignPlan
    } as CouponDto;

    return dto;
  }

  /**
   *
   * @param campaign
   */
  public mergeFromCampaign(campaign: Campaign, existingCoupon: MerchantsCoupon): MerchantsCoupon {
    Object.assign(existingCoupon, campaign);
    Object.assign(existingCoupon.targetAuidience, campaign.targetAuidience);
    return existingCoupon;
  }
}
