import { TabComponent } from "./tab.component";
import {
  Component,
  Input,
  EventEmitter,
  ContentChildren,
  Output
} from "@angular/core";

@Component({
  selector: "pb-tabset",
  templateUrl: "./tabset.component.html",
  styleUrls: ["./tab.component.scss"]
})
export class TabsetComponent {
  @Input() vertical;
  @Output() onSelect = new EventEmitter();
  @ContentChildren(TabComponent) tabs;

  ngAfterContentInit() {
    const tabs = this.tabs.toArray();
    const actives = this.tabs.filter(t => {
      return t.active;
    });

    if (actives.length > 1) {
      console.error(`Multiple active tabs set 'active'`);
    } else if (!actives.length && tabs.length) {
      tabs[0].active = true;
    }
  }

  tabClicked(tab) {
    const tabs = this.tabs.toArray();

    tabs.forEach(tab => (tab.active = false));
    tab.active = true;

    this.onSelect.emit(tab);
  }
}
