<header pb-page-header>
  <div left>
    Billing Information
  </div>
  <div right>
    <div class="buttons">
      <button pb-button white class="button" (click)="changeMode(false)" *ngIf="editMode">Cancel</button>
      <button pb-button orange class="button" (click)="changeMode(true)" *ngIf="!editMode">Edit</button>
      <button pb-button class="button" (click)="submitForm($event)" *ngIf="editMode">Save</button>
    </div>
  </div>
</header>
<pb-billing-information [isEditable]="editMode" (submitEmitter)="handleSubmit($event)" [card]="card" [address]="address"
  [states]="states" [usStates]="usStates" [canadaStates]="canadaStates" [country]="userCountry" [merchantId]="merchantId"></pb-billing-information>
<!-- <button pb-button class="button" (click)="testPayment($event)">Test</button> -->
<div class="note">
  Your credit card will be charged automatically after each campaign that is published.<br>
  Campaigns that run for multiple months will be billed on a recurring basis, each month until the duration of the
  Campaign is completed.
</div>