
import { Observable } from 'rxjs/Rx';

import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { VALIDATION_ERROR, AuthService, HttpStatusCode } from '../core';

/**
 * Login page component.
 */
@Component({
  selector: 'pb-login-page-combined',
  templateUrl: './login-page-combined.component.html',
  styleUrls: ['./login-page-combined.component.scss']
})
export class LoginPageCombinedComponent {
  /**
   * .ctor
   * @param {Router} router Router.
   * @param {AuthService} authService Authorization service.
   */
  constructor(private router: Router, private authService: AuthService) { }


}
