<form #streetNumberForm="ngForm" (ngSubmit)="onSubmit(streetNumberForm)" [class.form-submitted]="streetNumberForm.submitted" class="streetNumberForm">
  <div body>
    <div class="row">
      The selected address does not have a street number. Do you want to add street number manually?
    </div>
    <div class="row">
      <pb-input name="streetNumber" #passwordRef="ngModel" type="text" [(ngModel)]="streetNumber" placeholder="Street Number"
                required pbTooltip customElementQuery=".body" [pattern]="numberMask"></pb-input>
    </div>
    <div class="row" style="display: flex; justify-content: space-evenly">
      <button pb-button type="submit" style="margin-right: 5px;">
        <span class="btn-text">Add Street number</span>
      </button>
      <button pb-button type="button" white (click)="closeDialog($event)">
        <span class="btn-text">No Street number</span>
      </button>
    </div>
  </div>
</form>
