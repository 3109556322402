<header pb-page-header>
  <div left>Web Applications: {{ webAppId }}</div>
  <div right>
    <div class="buttons">
    </div>
  </div>
</header>

<div class="row" style="display: flex; justify-content: space-between; margin-top: 10px;">
  <div>
    <input class="form-control" type="text" [(ngModel)]="searchString" (ngModelChange)="searchStringChanged($event)"
           placeholder="Search listings..."/>
    <button *ngIf="searchString" class="clear-button" (click)="clearSearch()">×</button>
  </div>
  <div class="right">
    <div style="display: flex;">
      <span class="sort-text">Filter by:</span>
      <select class="custom-select" name="sortby" [(ngModel)]="selectedFiltering"
              (ngModelChange)="filterByChanged($event)" required>
        <option value="" disabled selected>Choose filtering</option>
        <option *ngFor="let item of filtering" [value]="item.id">{{ item.text }}</option>
      </select>
    </div>
  </div>
</div>

<table pb-table mdSort (mdSortChange)="sortDataChanged($event)" *ngIf="coupons.length > 0">
  <thead>
  <tr>
    <th md-sort-header="name">
      <div class="row">
        Name
      </div>
    </th>
    <th width="10%">
      <div class="row">
        Settings
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let coupon of pagedItems;"
      [ngClass]="{ 'featured': coupon.featured, 'not-featured': !coupon.featured, 'not-shown': coupon.hidden }">
    <td>
      <a href="{{coupon.viewUrl}}" target="_blank">{{ coupon.name }}</a>
    </td>
    <td>
      <a (click)="openCouponSettings(coupon)">
        <i class="fa fa-gear" style="color: black;"></i>
      </a>
    </td>
  </tr>
  </tbody>
</table>

<div class="error" *ngIf="coupons.length === 0">
  No Coupons Found
</div>

<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
