import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs/Rx";
import { AuthService, MessagesService, ServerError } from "../core";
import { NewAutoMessage } from "../core/models/new-auto-message";
import { PopupService } from "../popup";

@Component({
  selector: "pb-auto-messages",
  templateUrl: "./auto-messages.component.html",
  styleUrls: ["./auto-messages.component.scss"],
})
export class AutoMessagesPageComponent implements OnInit {
  private userSubscription: Subscription;

  public send_timers = [
    { value: 0, key: "" },
    { value: 5, key: "5 Minutes" },
    { value: 15, key: "15 Minutes" },
    { value: 30, key: "30 Minutes" },
    { value: 60, key: "1 Hour" },
    { value: 120, key: "2 Hour" },
    { value: 480, key: "8 Hours" },
    { value: 1440, key: "24 Hours" },
    { value: 2880, key: "48 Hours" },
    { value: 4320, key: "72 Hours" },
  ];

  constructor(
    private messagesService: MessagesService,
    private authService: AuthService,
    private popupService: PopupService,
    private router: Router
  ) {}

  public messages: NewAutoMessage[] = [];

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.userSubscription = this.authService.onUserChanged
      .skipWhile((user) => !user)
      .subscribe(
        async (user): Promise<void> => {
          if(!user.merchant.isAutoHeartingEnabled()) {
            this.router.navigateByUrl("/dashboard");
          }

          const merchantId = user.merchantId;

          const spinner = this.popupService.spinner();
          const response = await this.messagesService.getAutoMessages(
            merchantId
          );
          spinner.close();

          if (response instanceof ServerError) {
            alert("Error!");
          } else {
            this.messages = response as NewAutoMessage[];
            if (this.messages.length === 0) {
              document.location.href = '/new-auto-message';
              // this.router.navigateByUrl("/new-auto-message");
            }
          }
          if (this.userSubscription) {
            this.userSubscription.unsubscribe();
          }
        }
      );
  }

  getTimerName = function (timer_value) {
    return this.send_timers.find((t) => t.value === timer_value).key;
  };

  newAutoMessage = function () {
    this.router.navigateByUrl("/new-auto-message");
  };
}
