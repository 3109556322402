<div class="plan-switcher">
  <div class="plan-switcher-header">
    <p class="plan-title">
      {{title}}
    </p>
    <p class="plan-details">
      <pb-icon name="#iconInfo" class="icon"></pb-icon><a href="#">Details</a>
    </p>
  </div>
  <div class="plan-switcher-body">
    <p class="plan-title">
      <pb-icon name="#iconAdd" class="icon"></pb-icon><a (click)="upgradeToPremium()">Upgrade to Premium</a>
    </p>
    <p class="plan-pricing">
      ${{premiumFee}} (Billed monthly)
    </p>
  </div>
</div>