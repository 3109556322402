<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 800" height="540">>
    <rect [attr.fill]="getFillStateColor('PE')" [attr.stroke]="strokeColor" width="30" x="960" y="560" height="22"
        id="MA_1" (click)="onCanadaMapClick('PE')" />
    <rect [attr.fill]="getFillStateColor('NS')" [attr.stroke]="strokeColor" x="960" y="590" width="30" height="22"
        id="RI_1" (click)="onCanadaMapClick('NS')" />

    <g transform="rotate(-10 0 0)">
        <path *ngFor="let coordinate of coordinates;let i = index" [attr.id]="coordinate.id" [attr.d]="coordinate.d"
            [attr.fill]="getFillStateColor(coordinate.id)" [attr.stroke]="strokeColor"
            (click)="onCanadaMapClick(coordinate.id)">
        </path>
    </g>
    <text style="cursor: pointer;" x="205" y="530" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('BC')"
        (click)="onCanadaMapClick('BC')">
        <tspan dy="6.5">BC</tspan>
    </text>
    <text style="cursor: pointer;" x="305" y="530" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('AB')"
        (click)="onCanadaMapClick('AB')">
        <tspan dy="6.5">AB</tspan>
    </text>
    <text style="cursor: pointer;" x="395" y="580" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('SK')"
        (click)="onCanadaMapClick('SK')">
        <tspan dy="6.5">SK</tspan>
    </text>
    <text style="cursor: pointer;" x="485" y="500" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('MB')"
        (click)="onCanadaMapClick('MB')">
        <tspan dy="6.5">MB</tspan>
    </text>
    <text style="cursor: pointer;" x="595" y="570" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('ON')"
        (click)="onCanadaMapClick('ON')">
        <tspan dy="6.5">ON</tspan>
    </text>
    <text style="cursor: pointer;" x="760" y="510" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('QC')"
        (click)="onCanadaMapClick('QC')">
        <tspan dy="6.5">QC</tspan>
    </text>
    <text style="cursor: pointer;" x="130" y="360" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('YT')"
        (click)="onCanadaMapClick('YT')">
        <tspan dy="6.5">YT</tspan>
    </text>
    <text style="cursor: pointer;" x="280" y="380" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('NT')"
        (click)="onCanadaMapClick('NT')">
        <tspan dy="6.5">NT</tspan>
    </text>
    <text style="cursor: pointer;" x="460" y="360" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('NU')"
        (click)="onCanadaMapClick('NU')">
        <tspan dy="6.5">NU</tspan>
    </text>
    <text style="cursor: pointer;" x="855" y="470" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('NL')"
        (click)="onCanadaMapClick('NL')">
        <tspan dy="6.5">NL</tspan>
    </text>
    <text style="cursor: pointer;" x="865" y="610" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('NB')"
        (click)="onCanadaMapClick('NB')">
        <tspan dy="6.5">NB</tspan>
    </text>
    <text style="cursor: pointer;" x="975" y="600" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('NS')"
        (click)="onCanadaMapClick('NS')">
        <tspan dy="6.5">NS</tspan>
    </text>
    <text style="cursor: pointer;" x="975" y="570" text-anchor="middle" stroke="none" [attr.fill]="getTextColor('PE')"
        (click)="onCanadaMapClick('PE')">
        <tspan dy="6.5">PE</tspan>
    </text>
</svg>