import {Pipe, PipeTransform} from '@angular/core';

export const TEXTS = {
  sidebar: {
    add_business_button_text: 'Add a new Business'
  },
  profile: {

  }
};

export function getFlagEmoji(countryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

