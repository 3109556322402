<div class="header">
  <div class="caption-group">
    <h2 class="caption">
      {{caption}}
    </h2>
    <div class="tooltip">
      <ng-content select="[tooltip]"></ng-content>
    </div>
  </div>
  <div class="right">
    <ng-content select="[header]"></ng-content>
  </div>
</div>
<div class="body">
  <div class="centered" *ngIf="spinner">
    <md-spinner></md-spinner>
  </div>
  <ng-content select="[body]" *ngIf="!spinner"></ng-content>
</div>
