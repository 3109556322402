/**
 * WebAppPopup domain model.
 */

export class LoyaltyPopup {
  id: string;
  name: string;
  popupType: string;
  popup_action_url: string;
  logo_image: string;
  primaryButtonText: string;
  primaryButtonTextColor: string;
  secondaryButtonText: string;
  primaryButtonColor: string;
  primaryButtonBorderColor: string;
  secondaryButtonColor: string;
  secondaryButtonTextColor: string;
  secondaryButtonBorderColor: string;
  backgroundColor: string;
  content: string;
  primaryButtonUrl: string;
  popupActionUrl: string;
  popupImage: string;

  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }
}
