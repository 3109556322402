import { InvoiceDto, InvoicesDto } from './dtos/invoice.dto';

import { Observable } from 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';

import { environment } from '../../../../environments/environment';
import { TokenStorageService } from '../token-storage.service';

import { Invoice } from '../../models/invoice';
import { InvoiceMapper } from './mappers/invoice.mapper';

const MERCHANT_URL_PART = 'merchants';
const INVOICES_URL_PART = 'invoices';

/**
 * Merchant service.
 */
@Injectable()
export class InvoiceService {
    /**
     * .ctor
     * @param http Http
     */
    constructor(private http: Http,
        private invoiceMapper: InvoiceMapper,
        private tokenService: TokenStorageService) { }

    /**
     * Returns array of invoices for current merchant.
     * @param merchantId Id of merchant.
     */
    public getInvoices(merchantId: number, starDate: string, endDate: string): Promise<Invoice[]> {
        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${INVOICES_URL_PART}/`,
        { headers: this.tokenService.baseHeaders, search: {start: starDate, end: endDate}  })
        .map(r => r.json() as InvoicesDto)
        .map(pagedResponse => pagedResponse.results.map(r => this.invoiceMapper.mapToModel(r)))
        .toPromise();
    }

      /**
     * Returns array of invoices for current merchant.
     * @param merchantId Id of merchant.
     */
    public getPastInvoices(merchantId: number, invoiceId: number): Promise<Invoice[]> {
        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${INVOICES_URL_PART}/${invoiceId}/past`,
        { headers: this.tokenService.baseHeaders })
        .map(r => r.json() as InvoicesDto)
        .map(pagedResponse => pagedResponse.results.map(r => this.invoiceMapper.mapToModel(r)))
        .toPromise();
    }

    /**
     * Returns invoice object.
     * @param merchantId Id of merchant.
     * @param invoiceId Id of invoice.
     */
    public getInvoice(merchantId: number, invoiceId: number): Promise<Invoice> {
        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${INVOICES_URL_PART}/${invoiceId}/`,
        { headers: this.tokenService.baseHeaders })
        .map(r => r.json() as InvoiceDto)
        .map(r => this.invoiceMapper.mapToModel(r))
        .toPromise();
    }

    /**
     * Returns current invoice at current date.
     * @param merchantId Id of merchant.
     */
    public getCurrentMonth(merchantId: number): Promise<Invoice[]> {
        return this.getCurrentMonth$(merchantId).toPromise();
    }

    public getCurrentMonth$(merchantId: number): Observable<Invoice[]> {
        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${INVOICES_URL_PART}/month/`,
            { headers: this.tokenService.baseHeaders }
        )
        .map(r => r.json() as InvoiceDto[])
        .map((res: InvoiceDto[]) => res.map(r => this.invoiceMapper.mapToModel(r)))
    }

    public getCurrent(merchantId: number): Promise<Invoice> {
        return this.getCurrent$(merchantId).toPromise();
    }

    public getCurrent$(merchantId: number): Observable<Invoice> {
        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${INVOICES_URL_PART}/current/`,
            { headers: this.tokenService.baseHeaders }
        )
        .map(r => r.json() as InvoiceDto)
        .map(r => this.invoiceMapper.mapToModel(r));
    }


    public downloadCsv(merchantId: number, invoiceId: number): Promise<ArrayBuffer> {
        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${INVOICES_URL_PART}/${invoiceId}/csv/`,
        { headers: this.tokenService.baseHeaders })
        .map(r => r.arrayBuffer())
        .toPromise();
    }

    public downloadPdf(merchantId: number, invoiceId: number): Promise<Blob> {
        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${INVOICES_URL_PART}/${invoiceId}/pdf/`,
        { headers: this.tokenService.baseHeaders, responseType: ResponseContentType.Blob })
        .map(r => {
            return new Blob([r.blob()], {
                type: 'application/pdf'
            });
        })
        .toPromise();
    }
}
