import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError } from 'rxjs/operators/catchError';

/**
 * Token interceptor.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public constructor() { }

  /**
   * @inheritDoc
   *
   * @param request @inheritDoc
   * @param next @inheritDoc
   */
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const new_request = request.clone({
      headers: request.headers.set('Authorization', `token ${token}`)
    });
    return next.handle(new_request).pipe(catchError(error => new ErrorObservable(error)));
  }
}
