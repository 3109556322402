import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import * as moment from 'moment';

import { environment } from '../../../../environments/environment';
import { TokenStorageService } from '../token-storage.service';
import { PlaceDto } from './dtos/place.dto';
import { PlaceMapper } from './mappers/place.mapper';
import { Place } from '../../models/place';
import { AuthService } from '../auth/auth.service';
import { ProviderArrayMap, ProviderMap } from './public_api';
import { PlaceSearchMapper } from './mappers/place-search.mapper';
import { PlaceSearch } from '../../models/place-search';
import { FoursquareInfo } from '../../models/foursquare-info';
import { FoursquareInfoDto } from './dtos/foursquare-info.dto';
import { FoursquareInfoMapper } from './mappers/foursquare-info.mapper';
import { ServerErrorMapper } from '../error-handler/mappers/server-error.mapper';
import { State } from '../../models/state';

const MERCHANT_URL = 'merchants';
const PLACES_URL = 'places';

/**
 * Place service.
 */
@Injectable()
export class PlaceService {
  /**
   * .ctor
   * @param http Http
   */
  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private tokenService: TokenStorageService,
    private placeMapper: PlaceMapper,
    private userService: AuthService,
    private foursquareMapper: FoursquareInfoMapper,
    private placeSearchMapper: PlaceSearchMapper,
    private serverErrorMapper: ServerErrorMapper
  ) { }

  /**
   * Gets a place information from foursquare.com by it's Foursquare Id.
   * @param {string} forsquareId Foursquare place identifier.
   */
  public async getPlaceInfoFromFoursquare(forsquareId: string): Promise<Place> {
    return this.http.get(`${environment.apiEndpoint}/${PLACES_URL}/${forsquareId}`, { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as PlaceDto)
      .map(r => this.placeMapper.mapToModel(r))
      .toPromise();
  }

  public sortingFunc(a: Place, b: Place): number {

    if (a.created && !b.created) {
      return 1;
    }

    if (b.created && !a.created) {
      return -1;
    }

    const aDate = moment(a.created);
    const bDate = moment(b.created);

    if (aDate.isBefore(bDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }

    return 0;
  }

  /**
   * Gets a list of places related to merchant.
   * @param {number} merchantId Merchant identifier.
   */
  public async getPlaces(merchantID: number = null): Promise<Place[]> {
    if (merchantID === null) {
      const user = await this.userService.me();
      merchantID = user.merchantId;
    }

    return this.getPlaces$(merchantID).toPromise();
  }

  public getPlaces$(merchantId: number): Observable<Place[]> {
    return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL}/${merchantId}/places/`, { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as PlaceDto[])
      .map(r => r.map(v => this.placeMapper.mapToModel(v)));
  }

  /**
   * Gets a list of places related to merchant.
   * @param {number} merchantId Merchant identifier.
   * @param {string} forsquareId Foursquare place identifier.
   */
  public async getPlaceInfo(merchantId: number, forsquareId: string): Promise<Place> {
    return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL}/${merchantId}/places/${forsquareId}/`,
      { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as PlaceDto)
      .map(r => this.placeMapper.mapToModel(r))
      .toPromise();
  }

  /**
   * Searching addresses.
   * @param provider One of providers, google or foursquare.
   * @param query Query text.
   * @param near Search near of.
   * @deprecated Use google place provider.
   */
  public async searchPlacesInfo<P extends keyof ProviderArrayMap>(provider: P, query: string, near: string = 'US'):
    Promise<ProviderArrayMap[P]> {
    return this.http.get(`${environment.apiEndpoint}/${PLACES_URL}/search/?provider=${provider}&query=${query}&near=${near}`,
      { headers: this.tokenService.baseHeaders })
      .map(r => {
        return r.json() as any;
      })
      .map(r => {
        if (!r) {
          return null;
        }
        if (provider === 'google') {
          return r;
        } else if (provider === 'foursquare') {
          return r.map(item => this.foursquareMapper.mapToModel(<any>item));
        }
      })
      .toPromise();
  }

  /**
   * Retrieve information about place search result.
   * @param provider Provider for search.
   * @param id Id of place search result.
   * @deprecated Use google place provider.
   */
  public async getSearchPlaceInfo<P extends keyof ProviderMap>(provider: P, id: string): Promise<ProviderMap[P]> {
    return this.http.get(`${environment.apiEndpoint}/${PLACES_URL}/${id}/?provider=${provider}`,
      { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as any)
      .map(r => {
        if (provider === 'google') {
          return this.placeSearchMapper.mapToModel(r);
        } else if (provider === 'foursquare') {
          return this.foursquareMapper.mapToModel(r);
        }
      })
      .toPromise();
  }

  /**
   * Retrieving info about foursquare place by id.
   * @param foursquareId Id of foursquare.
   * @deprecated Use other api. Since 09/26/2017.
   */
  public async getFoursquareInfo(foursquareId: string): Promise<FoursquareInfo | null> {
    return this.http.get(`${environment.apiEndpoint}/${PLACES_URL}/${foursquareId}`, { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as FoursquareInfoDto)
      .map(r => r ? this.foursquareMapper.mapToModel(r) : null)
      .toPromise();
  }

  /**
   * Searching of places on Foursquare.
   * @param query Query string to search on foursquare.
   * @param near Additional parameter for search 'near'
   * @deprecated Use other api. Since 09/26/2017.
   */
  public async getFoursquarePlaceInfo(query: string, near: string = null): Promise<FoursquareInfo[]> {

    let queryString = `query=${query}`;
    if (near) {
      queryString += `&near=${near}`;
    }

    return this.http.get(`${environment.apiEndpoint}/${PLACES_URL}/search?${queryString}`, { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as FoursquareInfoDto[])
      .map(r => r.map(item => this.foursquareMapper.mapToModel(item)))
      .toPromise();
  }

  /**
   * Searching in Google for addresses.
   * @param query Query string for search address.
   * @deprecated Use other api. Since 09/26/2017.
   */
  public async getGooglePlaces(query: string): Promise<{ id: string, description: string }[]> {
    return this.http.get(`${environment.apiEndpoint}/${PLACES_URL}/address/?query=${query}`,
      { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as { id: string, description: string }[])
      .toPromise();
  }

  /**
   * Register a new place for merchant.
   * @param merchantId Merchant identifier.
   * @param placeId Place identifier.
   * @param place Place domain model.
   */
  public addPlace(merchantId: number, placeId: string, place: Place): Promise<Place> {
    const url = `${environment.apiEndpoint}/${MERCHANT_URL}/${merchantId}/${PLACES_URL}/`;
    const body = {
      place_id: placeId,
      contact_name: place.contactName,
      contact_email: place.contactEmail,
      address: place.address,
      contact: place.contactPhone,
      country_calling_code: place.country_calling_code,
      provider: place.provider,
      name: place.name,
      city: place.city,
      state: place.state,
      zip: place.zip,
      country: place.country,
      street: place.street,
      street_number: place.streetNumber,
      is_displayed: place.isDisplayed,
    };

    return this.httpClient.post<PlaceDto>(url, body)
      .pipe(
        map((dto: PlaceDto) => this.placeMapper.mapToModel(dto)),
      ).toPromise();
  }

  /**
   * Update place.
   * @param merchantId Merchant identifier.
   * @param place Place domain model.
   */
  public async updatePlace(merchantId: number, place: Place): Promise<Place> {
    const url = `${environment.apiEndpoint}/${MERCHANT_URL}/${merchantId}/${PLACES_URL}/${place.id}/`;
    const body = {
      name: place.name,
      address: place.address,
      contact: place.contactPhone,
      contact_name: place.contactName,
      contact_email: place.contactEmail,
      city: place.city,
      country_calling_code: place.country_calling_code,
      state: place.state,
      zip: place.zip,
      country: place.country,
      street: place.street,
      street_number: place.streetNumber,
      location: place.location,
      is_displayed: place.isDisplayed,
    };

    return this.httpClient.patch<PlaceDto>(url, body)
      .pipe(
        map((dto: PlaceDto) => this.placeMapper.mapToModel(dto)),
      ).toPromise();
  }

  /**
   * Deleting place from merchant.
   * @param {number} merchantId Merchant identifier.
   * @param {string} forsquareId Foursquare place identifier.
   */
  public async deletePlace(merchantId: number, forsquareId: string): Promise<void> {
    this.http.delete(`${environment.apiEndpoint}/${MERCHANT_URL}/${merchantId}/${PLACES_URL}/${forsquareId}/`,
      { headers: this.tokenService.baseHeaders })
      .toPromise();
  }

  public updatePlaceObject(placeSearch: PlaceSearch, place: Place, states: State[]) {
    place.address = placeSearch.address;
    place.location = placeSearch.location;

    const streetNumber = placeSearch.getAddressComponent('street_number');
    const street = placeSearch.getAddressComponent('route');
    const city = placeSearch.getCity();
    const state = placeSearch.getAddressComponent('administrative_area_level_1');
    const state2 = placeSearch.getAddressComponent('administrative_area_level_2');
    const zip = placeSearch.getAddressComponent('postal_code');
    const country = placeSearch.getAddressComponent('country');

    if (city) {
      place.city = city.longName;
    } else {
      place.city = '';
    }
    place.state = '';
    if (state) {
      place.state = state.shortName;
      /*if (country.longName === 'United States' || country.longName === 'Canada') {
        if (states.some(s => s.code === state.shortName)) {
          place.state = state.shortName;
        }
      } else if (state2) {
        place.state = state2.shortName;
      }*/
    } else if (state2) {
      place.state = state2.shortName;
    }

    if (zip) {
      place.zip = zip.longName;
    } else {
      place.zip = '';
    }
    if (!place.name || place.name.length === 0) {
      place.name = placeSearch.name;
    }

    if (street) {
      place.street = street.longName;
    }

    if (streetNumber) {
      place.streetNumber = streetNumber.longName;
    }

    if (country) {
      place.country = country.longName;
    } else {
      place.country = 'USA';
    }
  }
}
