import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { MdDialog } from "@angular/material";
import { b64toBlob, scrollToNotValid, checkBadWords } from "../../../utils";
import { LoadedImage } from "../../core";
import { CropperPopupComponent } from "../../shared";
import { CanStepDeactivate } from "../can-step-deactivate";
import { RedemptionOptionsVm as RedemptionOptions } from "./redemption-options";
import { Plans } from "../../core/models/plans";
import { CampaignDataService } from "../services/campaign-data.service";
import { StateSelectionPopupComponent } from "../target-marketing/state-selection/state-selection-popup.component";
import { stateAbbreviations } from "../target-marketing/state-selection/state-abbreviations";

/**
 * The third step of creating the coupon/promotion.
 */
@Component({
  selector: "pb-redemption-options",
  templateUrl: "./redemption-options.component.html",
  styleUrls: ["./redemption-options.component.scss"],
})
export class RedemptionOptionsComponent
  implements AfterViewInit, CanStepDeactivate {
  private readonly defaultImageWidth = 300;
  private readonly defaultImageHeight = 300;
  private readonly inStoreImageWidth = 300;
  private readonly inStoreImageHeight = 300;
  private readonly barCodeImageWidth = 300;
  private readonly barCodeImageHeight = 300;

  public isRedeemptionEnabled = true;

  public couponTypeSelector = [
    { title: "Alphanumeric Code", type: "text", icon: "alpha" },
    { title: "QR Code", type: "qrcode", icon: "qrcode" },
    { title: "Bar Code", type: "barcode", icon: "barcode" },
    { title: "No Code", type: "none", icon: "forbidden" },
  ];
  public profanityList: string = null;

  public couponLimitSelector = [
    { title: "No Limit", type: "unlimited", icon: "forbidden" },
    { title: "Limit", type: "custom", icon: "user" },
  ];
  /**
   * @inheritdoc
   */
  public planType: string;
  /**
   * Campaign form.
   */
  @ViewChild(NgForm)
  public form: NgForm;

  @ViewChild("shopOptions") shopOptionsEle;

  /**
   * Model for this component.
   */
  /**
   * Current redemption options.
   */
  @Input()
  public redemptionOptions: RedemptionOptions;

  /**
   * If 'true' then disallow change the Campaign Feature Type.
   */
  @Input()
  public isForbiddenToChange: boolean;

  public upgradeFee: number;
  public campaignPlan: Plans;
  public hasCustomLinks: boolean = false;

  @Output() upgradePlan = new EventEmitter();

  @Input()
  public couponId: number = 0;

  private inStoreCoupon: Blob;
  /**
   * Base64 inStoreCoupon image adjusted after cropping.
   */
  public inStoreImage: string | ArrayBuffer;

  /**
   * @constructor
   */
  constructor(
    private dataService: CampaignDataService,
    private dialog: MdDialog
  ) {}

  public ngOnInit(): void {
    this.upgradeFee = this.dataService.upgrade_fee.getValue();
    this.campaignPlan = this.dataService.campaign_plan.getValue();

    this.dataService.upgrade_fee.subscribe((fee) => {
      this.upgradeFee = fee;
    });
    this.dataService.campaign_plan.subscribe((plan) => {
      this.campaignPlan = plan;
    });
    this.dataService.profanity_list.subscribe((list) => {
      this.profanityList = list;
    });
    this.dataService.subscription.subscribe((subscription) => {
      if(!subscription) return;
      this.hasCustomLinks = subscription.link_count > 0;
      this.redemptionOptions.featureOptions[this.hasCustomLinks ? 0 : 1].checked = true;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
  }
  /**
   * @inheritdoc
   */
  public ngAfterViewInit(): void {
    // Need trigger a change detection cycle using setTimeout() before can extract a value from a control.
    setTimeout(() => {
      if (this.redemptionOptions.errors.length > 0) {
        this.invalidateForm();
      }
    }, 0);

    this.setupLink();
  }

  private invalidateForm(): void {
    const controls = this.form.controls;

    while (this.redemptionOptions.errors.length > 0) {
      const { field, errors } = this.redemptionOptions.errors.pop();
      controls[field].setErrors(errors);
    }

    this.canStepDeactivate();
  }

  /*
  @HostListener('document:click', ['$event'])
  clickout(event) {
    let targetClass = event.target.className;
    if (targetClass === 'container' || targetClass === 'content')
      this.closeShopOptions();
    console.debug(event.target.className);
  }
  */
  /**
   * Validating form.
   */
  public validateForm(): void {
    if (
      !this.redemptionOptions.storeCouponCode &&
      this.redemptionOptions.inStoreCouponType === "text"
    ) {
      try {
        this.form.controls["storeCouponCode"].setErrors({ incorrect: true });
      } catch (error) {}
    } else {
      try {
        this.form.controls["storeCouponCode"].setErrors(null);
      } catch (error) {}
    }

    if (
      (this.redemptionOptions.inStoreCouponType === "qrcode" ||
        this.redemptionOptions.inStoreCouponType === "barcode") &&
      this.redemptionOptions.inStoreImage.base64 === null
    ) {
      try {
        this.form.controls["redemptionOptions.inStoreImage"].setErrors({ incorrect: true });
      } catch (error) {}
    } else {
      try {
        this.form.controls["redemptionOptions.inStoreImage"].setErrors(null);
      } catch (error) {}
    }

    if (!this.form.valid) {
      scrollToNotValid();
    }
  }

  /**
   * Returns the current value validation of the campaign form of this component.Used in stepper.
   */
  public canStepDeactivate(): boolean {
    this.form.onSubmit(null);

    let errors: any = [];

    const hasStoreCouponCodeTextBadWords = checkBadWords(this.redemptionOptions.storeCouponCode, this.profanityList)
    if(hasStoreCouponCodeTextBadWords.length > 0) {
      const error = {
        field: "storeCouponCode",
        errors: {
          detail: "bad words",
          words: hasStoreCouponCodeTextBadWords,
        }
      }
      errors.push(error);
    } else {
      this.redemptionOptions.errors = this.redemptionOptions.errors.filter(error => error.field !== 'storeCouponCode')
    }

    if(errors.length > 0) {
      this.redemptionOptions.errors = errors;

      const controls = this.form.controls;

      while (this.redemptionOptions.errors.length > 0) {
        const { field, errors } = this.redemptionOptions.errors.pop();

        controls[field].setErrors(errors);
      }
    }

    return this.form.valid;
  }

  public log(val: any) {
    console.log("LOG", val);
  }

  public isFreePlan(): boolean {
    if (!this.campaignPlan) {
      return true;
    }

    if (this.campaignPlan.name === "free") {
      return true;
    }

    return false;
  }

  public upgradeToPremium(): void {
    this.upgradePlan.emit();
  }

  public canAddStates(): boolean {
    if (!this.campaignPlan) {
      return false;
    }

    if (this.campaignPlan.name === "free") {
      return false;
    }

    if (this.couponId) {
      return false;
    }

    return true;
  }

  public canChangeShopOptions(): boolean {
    if (this.campaignPlan.name === "premium") {
      return true;
    }

    if (!this.hasCustomLinks && this.couponId) {
      return false;
    }

    return true;
  }

  public canEditCustomLink(): boolean {
    if (this.campaignPlan.name === "premium") {
      return true;
    }

    const lets_do_it = this.redemptionOptions.featureOptions[0].checked;
    if (lets_do_it) {
      return true;
    }

    return false;
  }

  public showFearures(): boolean {
    // if (this.campaignPlan.name === "premium") {
    //   return true;
    // }

    // const not_now = this.redemptionOptions.featureOptions[1].checked;
    // if (not_now) {
    //   return false;
    // }

    return true;
  }

  public isEdit(): boolean {
    return this.couponId > 0;
  }

  public canAddEmailStates(): boolean {
    if (!this.campaignPlan) {
      return false;
    }

    if (this.couponId) {
      return false;
    }

    return true;
  }

  public async openStateSelector(tab, type): Promise<void> {
    switch (type) {
      case "email":
        const selectedEmailStates: string[] = await this.openStateSelectorWithStates(
          tab,
          this.redemptionOptions.premiumEmailStates,
          this.campaignPlan.premium_email_fee,
          this.campaignPlan.name
        );
        if (selectedEmailStates !== undefined) {
          this.redemptionOptions.premiumEmailStates = selectedEmailStates;
          this.dataService.premium_email_state_count.next(
            selectedEmailStates.length
          );

          let numEmailStates = this.redemptionOptions.premiumEmailStates.length;
          if (this.campaignPlan.name === "premium") numEmailStates -= 1;

          this.dataService.premium_email_state_count.next(numEmailStates);
        } else {
          this.dataService.premium_email_state_count.next(0);
        }
        break;
      default:
        break;
    }
  }

  public customLinkChanged(event: any): void {
    // this.dataService.custom_link_count.next(1);
  }

  public shopOptionChanged(i: number, event: any) {
    this.redemptionOptions.shopOptions[i].url_type = event.target.value;
  }

  public isShopOptionSelected(i: number, url_type: any): boolean {
    return !this.redemptionOptions.shopOptions[i].url_type === url_type;
  }

  public isShopOptionAvailable(url_type: any): boolean {
    return !!this.redemptionOptions.shopOptions.find(
      (s) => s.url_type === url_type
    );
  }

  public async openStateSelectorWithStates(
    tab,
    states,
    fee,
    plan_type
  ): Promise<string[]> {
    const dialogRef = this.dialog.open(StateSelectionPopupComponent, {
      width: "1000px",
      /**
       * global style for popup to remove paddings
       */
      panelClass: "target-dialog",
      data: {
        states: states,
        tab: tab,
        fee: fee,
        plan_type: plan_type,
        title: {
          header: "Add this campaign to eMail Marketing Blast",
          subheader:
            "Select the states / provinces where you want this campaign to be featured.",
          text: "",
        },
      },
    });
    const selectedStates: string[] = await dialogRef.afterClosed().toPromise();
    return selectedStates;
  }

  public getStateName(state): string {
    return stateAbbreviations[state];
  }
  /**
   * Validating form.
   */
  public doClick(n) {
    // let e: Element = document.createEvent("MouseEvents");
    // e.initEvent("mousedown", true, false);
    // n.dispatchEvent(e, true);
    // e = document.createEvent("MouseEvents");
    // e.initEvent("mouseup", true, false);
    // n.dispatchEvent(e, true);
  }

  public toggleRedeemable(event) {
    this.redemptionOptions.feature = "coupon";
    if (!event.target.checked) {
      if (!this.redemptionOptions.storeCouponCode)
        this.redemptionOptions.storeCouponCode = "GETTINLOCAL";

      this.redemptionOptions.feature = "coupon";
    } else {
      if (this.redemptionOptions.storeCouponCode === "GETTINLOCAL") {
        this.redemptionOptions.storeCouponCode = "";
      }
    }
    this.redemptionOptions.isRedeemable = event.target.checked;
  }

  public updateShopOptions(event) {
    event.preventDefault();
    if (event.target.checked) {
      let enabled_count = 0;
      Object.keys(this.redemptionOptions.enabledShopOptions).map((k) => {
        if (this.redemptionOptions.enabledShopOptions[k] === true) {
          enabled_count++;
        }
      });
      if (enabled_count >= 2) {
        alert("Only 2 options are allowed at a time.");
        event.target.checked = false;
        this.redemptionOptions.enabledShopOptions[event.target.value] = false;
        return;
      }
      this.redemptionOptions.enabledShopOptions[event.target.value] = true;
    } else {
      this.redemptionOptions.enabledShopOptions[event.target.value] = false;
    }
  }

  public async couponRedeemLimitSelect(event): Promise<void> {
    this.redemptionOptions.storeCouponLimit = event;
  }

  public async couponTypeSelect(event): Promise<void> {
    this.redemptionOptions.inStoreCouponType = event;
    if (event === "none") {
      if (!this.redemptionOptions.storeCouponCode)
        this.redemptionOptions.storeCouponCode = "GETTINLOCAL";

      this.redemptionOptions.feature = "coupon";
    } else {
      if (this.redemptionOptions.storeCouponCode === "GETTINLOCAL") {
        this.redemptionOptions.storeCouponCode = "";
      }
    }
    this.redemptionOptions.isRedeemable = event !== "none";

    this.redemptionOptions.inStoreImage = {
      base64: null,
      blob: null,
      name: null,
    };
    this.redemptionOptions.inStoreImageRemoved = true;
  }

  public async couponTypeChange(event): Promise<void> {
    if (event.target.value === "none") {
      if (!this.redemptionOptions.storeCouponCode)
        this.redemptionOptions.storeCouponCode = "GETTINLOCAL";

      this.redemptionOptions.feature = "coupon";
    } else {
      if (this.redemptionOptions.storeCouponCode === "GETTINLOCAL") {
        this.redemptionOptions.storeCouponCode = "";
      }
    }
    this.redemptionOptions.isRedeemable = event.target.value !== "none";

    this.redemptionOptions.inStoreImage = {
      base64: null,
      blob: null,
      name: null,
    };
    this.redemptionOptions.inStoreImageRemoved = true;
  }

  public async removeCodeClicked(): Promise<void> {
    this.redemptionOptions.inStoreImage = {
      base64: null,
      blob: null,
      name: null,
    };
    this.redemptionOptions.inStoreImageRemoved = true;
    this.redemptionOptions.storeCouponCode = "";
  }

  /**
   * Saves logo uploading result to a variable.
   * @param images Array of uploaded images.
   */
  public async codeUploaded(images: LoadedImage[]): Promise<void> {
    const file = images[0].file;
    const fullViewCaption = "Full view image";
    const b64ImageFull = await this.presentCodeCropperPopup(
      this.redemptionOptions.inStoreCouponType === "qrcode"
        ? this.inStoreImageWidth
        : this.barCodeImageWidth,
      this.redemptionOptions.inStoreCouponType === "qrcode"
        ? this.inStoreImageHeight
        : this.barCodeImageHeight,
      images[0].base64String,
      fullViewCaption
    );
    const b64ImageMain = this.prepearMainImage(b64ImageFull);
    if (b64ImageMain) {
      this.redemptionOptions.inStoreImage = {
        base64: b64ImageMain,
        blob: b64toBlob(b64ImageMain),
        name: file.name,
      };
    }
    this.redemptionOptions.inStoreImageRemoved = false;
    this.redemptionOptions.storeCouponCode = "GETTINLOCAL";
    // this.form.controls['storeCouponCode'].setErrors(null);
  }

  private prepearMainImage(b64ImageFull): any {
    const image = new Image();
    image.src = b64ImageFull;

    const canvas = document.createElement("canvas");
    canvas.width =
      this.redemptionOptions.inStoreCouponType === "qrcode"
        ? this.inStoreImageWidth
        : this.barCodeImageWidth;
    canvas.height =
      this.redemptionOptions.inStoreCouponType === "qrcode"
        ? this.inStoreImageHeight
        : this.barCodeImageHeight;
    canvas
      .getContext("2d")
      .drawImage(
        image,
        0,
        0,
        this.redemptionOptions.inStoreCouponType === "qrcode"
          ? this.inStoreImageWidth
          : this.barCodeImageWidth,
        this.redemptionOptions.inStoreCouponType === "qrcode"
          ? this.inStoreImageHeight
          : this.barCodeImageHeight
      );

    return canvas.toDataURL("image/png");
  }

  private presentCodeCropperPopup(
    w: number,
    h: number,
    image: string | ArrayBuffer,
    caption?: string
  ): Promise<string> {
    const dialogRef = this.dialog.open(CropperPopupComponent, {
      data: {
        image,
        size: w,
        supportFit: true,
        keepAspect: false,
        height: h,
        caption,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  public clearShopOptioins(): void {
    // this.dataService.custom_link_count.next(0);

    this.redemptionOptions.shopOptions = [
      { url_type: "", url: "", title: "" },
      { url_type: "", url: "", title: "" },
    ];
  }

  public hasShopOptions(): boolean {
    if (!this.redemptionOptions.shopOptions) return false;
    if (this.redemptionOptions.shopOptions[0].url_type !== "") return true;
    return false;
  }

  public hasCouponImage(): boolean {
    if (
      this.redemptionOptions.inStoreCouponType === "qrcode" ||
      this.redemptionOptions.inStoreCouponType === "barcode"
    ) {
      if (this.redemptionOptions.inStoreImage) {
        return false;
      }
    }

    return false;
  }

  private setupLink(): void {
    this.dataService.custom_link_count.next(1);
  }

  public featureOptionsSelected(event): void {
    const checked = event.checked;
    const val = event.val;

    for (var i = 0; i < this.redemptionOptions.featureOptions.length; i++) {
      this.redemptionOptions.featureOptions[i].checked = false;
    }

    if (checked) {
      var foundIndex = this.redemptionOptions.featureOptions.findIndex(
        (x) => x.value == val
      );
      setTimeout(() => {
        this.redemptionOptions.featureOptions[foundIndex].checked = true;
      }, 200);

      if(foundIndex === 0) {
        this.dataService.custom_link_count.next(1);
      } else {
        this.redemptionOptions.shopOptions[0].url = '';
        this.redemptionOptions.inStoreCouponType = 'none';
        this.redemptionOptions.storeCouponLimit = 'unlimited';
        this.dataService.custom_link_count.next(0);
      }
    }
  }
}
