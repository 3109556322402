import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Custom pagination component.
 */
@Component({
  selector: 'pb-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {

  /**
   * Pagination object from pagination service.
   */
  @Input()
  public pagination: any = {};

  /**
   * Sets page.
   */
  @Output()
  private setPage = new EventEmitter<number>();

  public setPageClick(page: number): void {
    this.setPage.emit(page);
  }

}
