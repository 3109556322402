import {
  Component,
  Input,
  ViewChild,
  OnInit,
  ElementRef,
  AfterViewInit, Output, EventEmitter,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ValueAccessorBase } from '../../value-accessor-base';

/**
 * Custom input component.
 */
@Component({
  selector: 'pb-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: InputComponent, multi: true },
  ],
})
export class InputComponent extends ValueAccessorBase<any>
  implements OnInit, AfterViewInit {
  /**
   * The mask object for angular2-text-mask.
   */
  @Input()
  public mask = {
    mask: false,
  };

  @Input()
  public maxlength: string;

  @Input()
  public type = 'text';

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public max?: number;

  @Input()
  public min?: number;

  // TODO remove
  @Input()
  public value;

  @Input()
  public placeholder = '';

  @Input()
  public note = '';

  @Input()
  public disabled? = null;

  @ViewChild('input')
  public input: ElementRef;

  @Input()
  public setFocus = false;

  @Input()
  public readonly = false;

  @Input()
  public required = false;

  public isTypeNumber: boolean;

  @Input()
  public isTransparent = false;

  @Input()
  public inlineReverse = false;

  @Input()
  public prependLabel = false;

  @Input()
  public prependLabelPrice = false;

  @Input()
  public inlineNote = false;

  @Input()
  public inputTrim = false;

  @Input()
  public maxLength?: number;

  @Input()
  public showCurrency = false;
  @Output()
  public onFocus: EventEmitter<boolean> = new EventEmitter();

  public ngOnInit(): void {
    this.isTypeNumber = this.type === 'number';
  }

  public ngAfterViewInit() {
    if (this.setFocus) {
      this.input.nativeElement.focus();
    }
  }

  public focus() {
    this.onFocus.emit(true);
  }
}
