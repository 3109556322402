import { Component, OnInit, Inject } from '@angular/core';
import { MD_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'pb-iframe-popup',
  templateUrl: './iframe-popup.component.html',
  styleUrls: ['./iframe-popup.component.scss']
})
export class IframePopupComponent implements OnInit {

  /**
   * .ctor
   * @param data Passed to the popup data.
   */
  constructor(@Inject(MD_DIALOG_DATA) public data: any) {
    this.src = data.src;
  }

  public src: string;

  public ngOnInit() {
  }

}
