import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { NewAutoMessageComponent } from './new-auto-message.component';
import { TargetPopupComponent } from './target-popup/target-popup.component';
import { DetailsComponent } from './details/details.component';
import { AppRoutingModule } from '../app-routing.module';
import { AutocompleteModule } from '../shared/components/autocomplete/autocomplete.module';

/**
 * New message module.
 */
@NgModule({
  imports: [
    SharedModule,
    AppRoutingModule,
    AutocompleteModule
  ],
  declarations: [
    NewAutoMessageComponent,
    TargetPopupComponent,
    DetailsComponent,
  ],
  entryComponents: [
    TargetPopupComponent,
  ],
  exports: [
    NewAutoMessageComponent,
    DetailsComponent,
  ],
})
export class NewAutoMessageModule {
}
