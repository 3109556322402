import { Component, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../../value-accessor-base';

/**
 * Checkbox component.
 */
@Component({
  selector: 'pb-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CheckboxComponent, multi: true }],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent extends ValueAccessorBase<any> {

  constructor() {
    super();
  }

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public checked = false;

  @Input()
  public key: string;

  @Input()
  public value: boolean;

  @Input()
  public mr: string;

  @Input()
  public isValid = true;

  @Input()
  public isDisabled = false;

  @HostListener('change')
  public handleChange() {
    this.onChange(this.checked);
    this.changeDispatcher.emit({ control: this, value: this.checked });
  }

  @HostListener('checked')
  public handleChecked() {
    this.onChange(this.checked);
    this.changeDispatcher.emit({ control: this, value: this.checked });
  }
}
