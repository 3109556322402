import {Component, OnInit} from '@angular/core';
import {MdDialog} from '@angular/material';

import {AuthService, PlaceService} from '../../core';

import {ActivatedRoute, Router} from '@angular/router';
import {PopupService} from '../../popup';
import {WebappService} from '../../core/services/webapp/webapp.service';
import {Observable} from "rxjs/Observable";
import {Subscription} from "rxjs/Subscription";
import {WebAppFeaturedCoupon} from "../../core/services/webapp/models/webapp-featured-coupon";
import {MerchantDto} from "../../core/services/merchant/dtos/merchant.dto";
import {WebAppMerchant} from "../../core/models/webapp-merchant";
import {WebappMerchantCoupon} from "../../core/models/webapp-merchant-coupon";
import {CouponOverrideSettings} from "../../core/models/coupon-override-settings";

/**
 * Locations page component.
 */
@Component({
  selector: 'pb-webapp-coupons-page',
  templateUrl: './priority-coupons-page.component.html',
  styleUrls: ['./priority-coupons-page.component.scss']
})
export class WebAppPriorityCouponsPageComponent implements OnInit {

  /**
   * .ctor
   * @param {AuthService} authService Auth service.
   * @param activatedRoute
   * @param {PlaceService} placeService Place service.
   * @param webappService
   * @param dialog
   * @param router
   * @param dragulaService
   */
  constructor(private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private placeService: PlaceService,
              private webappService: WebappService,
              private dialog: MdDialog,
              private router: Router,
              private popupService: PopupService) {
  }

  /**
   * Webapps list.
   */
  public coupons: WebAppFeaturedCoupon[] = [];
  public total: number = 0;

  /**
   * Current merchant Id.
   */
  private merchantId: number;
  public webAppId: string;
  private routeSub: Subscription;

  field1Options: any[] = [];
  field2Options: any[] = [];

  couponData: {
    couponFilterOptions: Observable<any[]>;
    field1: WebAppMerchant;
    merchantFilterOptions: Observable<any[]>;
    field3: string;
    field2: WebappMerchantCoupon;
    field5: boolean;
    field4: boolean
  }[] = [];

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.initializationData();

    this.routeSub = Observable.combineLatest(this.activatedRoute.paramMap, this.authService.onUserChanged).subscribe(async tuple => {
      const [params, user] = tuple;
      if (user) {
        this.merchantId = user.merchantId;
        this.webAppId = params.get('id');

        try {
          const currentWebApp = user.merchant.webapps.find((webapp) => this.webAppId == webapp.id);
          if (currentWebApp) {
            if (!currentWebApp.enable_priority_ads) {
              if (this.routeSub) {
                this.routeSub.unsubscribe();
              }
              await this.router.navigate(['/dashboard']);

              return;
            }
          }
        } catch (error) {

        }

        if (this.webAppId) {
          this.webappService.getFeaturedCoupons(this.webAppId).then((data: CouponOverrideSettings[]) => {
            this.couponData = data.map(coupon => {
              return {
                field1: coupon.merchant,
                merchantFilterOptions: Observable.of(this.field1Options),
                field2: coupon.coupon,
                couponFilterOptions: Observable.of(this.field2Options),
                field3: this.getCategoryNameList(coupon.coupon.categories),
                field4: coupon.show_in_main,
                field5: coupon.show_in_business
              };
            });
          });
        }
      }
      if (this.routeSub) {
        this.routeSub.unsubscribe();
      }
    });

    const userSubscription = this.authService.onUserChanged.subscribe(async result => {
      const user = result;
      if (user) {
        this.merchantId = user.merchantId;
        if (userSubscription) {
          userSubscription.unsubscribe();
        }
      }
    });

    // scroll to top
    window.scrollTo(0, 0);
  }

  initializationData() {
    this.couponData = [
      this.createEmptyRow()
    ];
  }

  createEmptyRow() {
    return {
      field1: {} as WebAppMerchant,
      merchantFilterOptions: Observable.of(this.field1Options),
      field2: {} as WebappMerchantCoupon,
      couponFilterOptions: Observable.of(this.field2Options),
      field3: '',
      field4: false,
      field5: false
    };
  }

  filterField1Options(row) {
    const filterValue = row.field1.toLowerCase();
    this.webappService.getMerchants(this.webAppId, filterValue, 0)
      .then((data: WebAppMerchant[]) => {
        console.log('Field1 Filtered Options', data);
        row.merchantFilterOptions = Observable.of(data);
      });
  }

  onMerchantSelectChanged(row, event: any) {
    if (event.isUserInput) {
      const merchantId = event.source.value.id;
      this.webappService.getMerchantCoupons(this.webAppId, merchantId, "")
        .then((coupons: WebappMerchantCoupon[]) => {
          row.couponFilterOptions = Observable.of(coupons);
          if (coupons.length > 0) {
            row.field2 = coupons[0];
            row.field3 = this.getCategoryNameList(coupons[0].categories);
          } else {
            this.popupService.info("No coupons found for this merchant");
          }
        });
    }
  }

  filterField2Options(row) {
    const filterValue = row.field2.toLowerCase();
    this.webappService.getMerchantCoupons(this.webAppId, row.field1.id, filterValue)
      .then((data: WebappMerchantCoupon[]) => {
        row.couponFilterOptions = Observable.of(data);
      });
  }

  onCouponSelectChanged(row, event: any) {
    if (event.isUserInput) {
      const categories = event.source.value.categories;
      row.field3 = categories.map(c => c.name).join(', ');
    }
  }

  onField4OptionChanged(row: any, $event: boolean) {
    row.field4 = $event;
  }

  onField5OptionChanged(row: any, $event: boolean) {
    row.field5 = $event;
  }

  displayMerchantName(merchant: MerchantDto): string {
    return merchant && merchant.name_dba ? merchant.name_dba : '';
  }

  displayCouponName(coupon: WebappMerchantCoupon): string {
    return coupon && coupon.name ? coupon.name : '';
  }

  addRow() {
    this.couponData.push(this.createEmptyRow());
  }

  removeRow(index: number) {
    this.couponData.splice(index, 1);
  }

  onSubmit() {
    const error = this.couponData.some(row => !row.field1.id || !row.field2.id);
    if (error) {
      this.popupService.info("Please fill all fields");
      return;
    }
    this.webappService.updateFeaturedCouponsOrder(this.webAppId, this.couponData).then((res) => {
      this.popupService.info("Updated");
    });
  }

  moveUp(index: number) {
    if (index > 0) {
      [this.couponData[index], this.couponData[index - 1]] = [this.couponData[index - 1], this.couponData[index]];
    }
  }

  moveDown(index: number) {
    if (index < this.couponData.length - 1) {
      [this.couponData[index], this.couponData[index + 1]] = [this.couponData[index + 1], this.couponData[index]];
    }
  }

  getCategoryNameList(categories: any[]): string {
    return `<ul class="cat-list">` + categories.map(c => `<li>${c.name}</li>`).join('') + `</ul>`;
  }
}

