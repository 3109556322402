import {Component} from '@angular/core';

import {AuthService} from './core';
import {ActivatedRoute} from "@angular/router";

/**
 * App main layout which contains side menu.
 */
@Component({
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent {

  public isWebAppPage: boolean = false;
  public webAppId: string = null;

  /**
   * .ctor
   * @param {AuthService} authService Authorization service.
   */
  constructor(authService: AuthService,
              private route: ActivatedRoute,
  ) {
    this.route.url.subscribe(r => {
      this.isWebAppPage = r.some(x => x.path === 'webapps') && r.length > 1;
      this.webAppId = r.length > 1 ? r[1].path : null;
    });
    const userSubscription = authService.onUserChanged.subscribe(r => {
      this.disabled = !r || !r.merchant || !r.merchant.creationCompleted;
      if (userSubscription) {
        userSubscription.unsubscribe();
      }
    });
  }

  /**
   * If the side bar is disabled.
   */
  public disabled = false;
}
