<header pb-page-header [isBig]="true">
  <div left>
      <div class="icon-block">
          <pb-icon name="#iconColoredCampaign" class="icon"></pb-icon>
      </div>
  </div>
</header>
<div class="container" *ngIf="rates">
  <div class="left">
    <div class="caption">Pricing Plans</div>
    <div class="info-item">
      <div class="info-container">
        <div class="info-icon">MP</div>
        <div class="info-main">
          <div class="info-caption">Monthly Mobile Presence Hosting</div>
          Monthly Plan ({{ rates.getRate('subscriptionRate') | currency: 'USD' : true :'2.2-2' }}/month)
        </div>
      </div>
      <ul class="info-ul">
        <li class="info-li">• Access to GettinLocal’s exclusive Merchant Portal</li>
        <li class="info-li">• Create targeted coupons, messages, and promotions</li>
        <li class="info-li">• See stats related to your marketing efforts</li>
        <li class="info-li">• Put your account on hold, anytime.</li>
      </ul>
    </div>
    <div class="info-item">
      <div class="info-container">
        <div class="info-icon">
          <pb-icon name="#iconSingleCampaign" class="icon"></pb-icon>
        </div>
        <div class="info-main">
          <div class="info-caption">Coupon Publishing</div>
          {{ rates.getRate('couponRate') | currency: 'USD' : true :'2.2-2' }} per published coupon
        </div>
      </div>
      <ul class="info-ul">
        <li class="info-li">• Create coupons to tracks your marketing efforts</li>
        <li class="info-li">• Each coupon is a flat fee of  {{ rates.getRate('couponRate') | currency: 'USD' : true :'2.2-2' }}</li>
        <li class="info-li">• You determine where and how long to target your coupon!</li>
      </ul>
      <span>
        Note - Published Coupons will be included in the monthly bill based on the Publish date.
        The total of all monthly expenses incurred will be charged to the Credit/Debit card that
        is on file in association with your account profile.
      </span>
    </div>
  </div>
  <div class="right">
    <pb-billing-information [isEditable]="true" (submitEmitter)="handleSubmit($event)" [card]="card" [address]="address" [states]="states"></pb-billing-information>
    <div class="buttons">
      <button pb-button white class="button" (click)="logout()">Logout</button>
      <button pb-button white class="button" (click)="refreshPage()">Cancel</button>
      <button pb-button class="button" (click)="submitForm($event)">Submit</button>
    </div>
  </div>
</div>

