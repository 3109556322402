import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pb-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  @Input()
  public impressions: number;

  @Input()
  public redemptions: number;

  @Input()
  public likes: number;

  public totalPercentage = 100;

  public ngOnInit() {
  }

  public get impressionsPercentage() {
    return (this.impressions / ((this.impressions + this.redemptions + this.likes) / this.totalPercentage));
  }

  public get redemptionsPercentage() {
    return (this.redemptions / ((this.impressions + this.redemptions + this.likes) / this.totalPercentage));
  }

  public get likesPercentage() {
    return (this.likes / ((this.impressions + this.redemptions + this.likes) / this.totalPercentage));
  }
}
