import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MdButtonToggleModule, MdIconModule } from "@angular/material";

import { PlanSwitcherModule } from "../shared/plan-switcher/plan-switcher.module";
import { PricingModule } from "../shared/pricing/pricing.module";
import { SharedModule } from "../shared/shared.module";
import { EventStyleComponent } from "./event-style/event-style.component";
import { EventsComponent } from "./events.component";
import { EventCostBreakdownComponent } from "./finalize-event/event-cost-breakdown/event-cost-breakdown.component";
import { FinalizeEventComponent } from "./finalize-event/finalize-event.component";
import { PreviewEventComponent } from "./preview-event/preview-event.component";
import { EventOptionsComponent } from "./event-options/event-options.component";
import { EventDataService } from "./services/event-data.service";
import { EventService } from "./services/event-service/event.service";
import { EventMarketingComponent } from "./event-marketing/event-marketing.component";
import { EventPublishSuccessPopupComponent } from "./event-publish-popup/event-publish-popup.component";
import {CampaignModule} from "../campaign/campaign.module";
import {EventImageService} from "./services/event-service/eventimage.service";
import {EventLocationPopupComponent} from "./event-location-popup/event-location-popup.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    PricingModule,
    PlanSwitcherModule,
    MdButtonToggleModule,
    MdIconModule,
    CampaignModule,
  ],
  declarations: [
    EventsComponent,
    EventStyleComponent,
    EventMarketingComponent,
    EventOptionsComponent,
    EventCostBreakdownComponent,
    FinalizeEventComponent,
    PreviewEventComponent,
    EventPublishSuccessPopupComponent,
    EventLocationPopupComponent
  ],
  entryComponents: [
    EventPublishSuccessPopupComponent,
    EventLocationPopupComponent
  ],
  providers: [EventService, EventImageService, EventDataService],
  exports: [EventsComponent]
})
export class EventsModule {}
