import { NgModule } from '@angular/core';
import {Route, RouterModule, Routes, UrlSegment, UrlSegmentGroup} from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegistrationPayComponent } from './registration-pay/registration-pay.component';
import { ViewPromotionComponent } from './view-promotion/view-promotion.component';
import { ActivitiesPageComponent } from './activities/activities-page.component';
import { AppLayoutComponent } from './app-layout.component';

import { SignupPageComponent, ConfirmPageComponent } from './registration';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginPageCombinedComponent } from './login-page-combined/login-page-combined.component';
import { CompanyProfilePageComponent } from './company-profile-page/company-profile-page.component';
import { BillingInformationPageComponent } from './billing-information-page/billing-information-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { ViewCampaignComponent } from './view-campaign/view-campaign.component';
import { ReportDashboardComponent } from './report-dashboard/report-dashboard.component';
import { ReportsPageComponent } from './reports-page/reports-page.component';
import { InvoicesPageComponent } from './invoices-page/invoices-page.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { LocationsPageComponent } from './locations/locations-page/locations-page.component';
import { ViewLocationComponent } from './locations/view-location/view-location.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard, RegisterGuard, PaymentGuard} from './core';
import { NewMessageComponent } from './new-message/new-message.component';
import { NewAutoMessageComponent } from './new-auto-message/new-auto-message.component';
import { DetailsComponent } from './new-message/details/details.component';
import { LoginInformationComponent } from './login-information/login-information.component';
import { CampaignComponent } from './campaign/campaign.component';
import { EventsComponent } from './events/events.component';
import { CreateCampaignComponent } from './campaign/create-campaign/create-campaign.component';
import { AutoMessagesPageComponent } from './auto-messages/auto-messages.component';
import {LandingPageBrandsComponent} from './landing-page-brands/landing-page-brands.component';
import {LandingPageExploreliComponent} from './landing-page-exploreli/landing-page-exploreli.component';
import {LandingPageSmokyExplorerComponent} from './landing-page-smokyexplorer/landing-page-smokyexplorer.component';
import {
  LandingPageLondonLocalGuideComponent
} from './landing-page-london-local-guide/landing-page-london-local-guide.component';
import {
  LandingPageMinicardsLondonComponent
} from "./landing-page-minicards-london/landing-page-minicards-london.component";
import {LandingPageDiscoverLIComponent} from "./landing-page-discoverli/landing-page-discoverli.component";
import {WebAppsPageComponent} from "./webapps/webapps-page/webapps-page.component";
import {WebappViewPageComponent} from "./webapps/webapp-view-page/webapp-view-page.component";
import {WebAppCouponsPageComponent} from "./webapps/coupons-page/coupons-page.component";
import {
  WebAppPriorityCouponsPageComponent
} from "./webapps/priority-coupons-page/priority-coupons-page.component";
import {PopupsSettingsPageComponent} from "./webapps/popups-settings-page/popups-settings-page.component";
import {LoyaltyPopupsComponent} from "./webapps/loyalty-popups-page/loyalty-popups.component";
import {AddLoyaltyPopupComponent} from "./webapps/add-loyalty-popup/add-loyalty-popup.component";
import {AddDataPopupPageComponent} from "./webapps/add-data-popup-page/add-data-popup-page.component";
import {DataPopupsComponent} from "./webapps/data-popups-page/data-popups.component";
import {WebAppHideCouponsPageComponent} from "./webapps/hide-coupons-page/hide-coupons-page.component";
import {WebAppFeaturedCouponsPageComponent} from "./webapps/featured-coupons-page/featured-coupons-page.component";
import {ScanReportsPageComponent} from "./scan-reports-page/scan-reports-page.component";


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'home',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'signup',
    redirectTo: '/login',
  },
  { path: 'email-confirm/:key', component: ConfirmPageComponent },
  { path: 'email-confirm', component: ConfirmPageComponent },
  {
    path: 'registration-pay',
    component: RegistrationPayComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginPageCombinedComponent,
  },
  {
    path: 'smokyexplorer',
    component: LandingPageSmokyExplorerComponent,
  },
  {
    path: 'londonlocalguide',
    component: LandingPageLondonLocalGuideComponent,
  },
  {
    path: 'explorelongisland',
    component: LandingPageExploreliComponent,
  },
  {
    path: 'minicards',
    component: LandingPageMinicardsLondonComponent,
  },
  {
    path: 'discoverlongisland',
    component: LandingPageDiscoverLIComponent,
  },
  /*{
    matcher: (url: UrlSegment[]) => {
      return url.length === 1 && url[0].path.endsWith('-landing') ? ({consumed: url}) : null;
    },
    component: LandingPageBrandsComponent,
  },*/
  {
    path: 'login-information',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: LoginInformationComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'profile',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: CompanyProfilePageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'dashboard',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'billing',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: BillingInformationPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'campaign',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: CampaignComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'events',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: EventsComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'create_campaign',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: CreateCampaignComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'edit-campaign',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id',
        component: CampaignComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'edit-event',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id',
        component: EventsComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'view-campaign',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id',
        component: ViewCampaignComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'view-promotion',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id',
        component: ViewPromotionComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'reports',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: ReportsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'reports/:id/scan-reports',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: ScanReportsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'report-dashboard',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id',
        component: ReportDashboardComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'invoices',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: InvoicesPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'invoices',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id',
        component: ViewInvoiceComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: WebAppsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: WebappViewPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/listings',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: WebAppCouponsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/featured',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: WebAppFeaturedCouponsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/priority-coupons',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: WebAppPriorityCouponsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/hide-coupons',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: WebAppHideCouponsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/configure',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: PopupsSettingsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/loyalty-popups',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: LoyaltyPopupsComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/loyalty-popups/add',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: AddLoyaltyPopupComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/loyalty-popups/:popupId',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: AddLoyaltyPopupComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/data-popups',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: DataPopupsComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/data-popups/add',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: AddDataPopupPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'webapps/:id/data-popups/:popupId',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: AddDataPopupPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'locations',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: LocationsPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'locations/view',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: ViewLocationComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'locations/:forsquareID',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: ViewLocationComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'activities',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: ActivitiesPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'new-message',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id/:type',
        component: NewMessageComponent
      },
      {
        path: '',
        component: NewMessageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'auto-messages',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id',
        component: NewAutoMessageComponent
      },
      {
        path: '',
        component: AutoMessagesPageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'new-auto-message',
    component: AppLayoutComponent,
    children: [
      {
        path: ':id/:type',
        component: NewAutoMessageComponent
      },
      {
        path: '',
        component: NewAutoMessageComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'message-details/:id',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: DetailsComponent
      }
    ],
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [AuthGuard, RegisterGuard, PaymentGuard]
  }
];

/**
 * App root routing module.
 */
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
