import { OnInit, Component, Output, EventEmitter, Input } from "@angular/core";
import { P } from "@angular/core/src/render3";
import { Plans } from "../../../core/models/plans";

/**
 * Page component for Merchant's activities.
 */
@Component({
  selector: "pb-checkbox-group",
  templateUrl: "./checkbox-group.component.html",
  styleUrls: ["./checkbox-group.component.scss"],
})
export class CheckboxGroupComponent implements OnInit {
  public selectedPlan: Plans;

  @Input()
  public options: any;

  @Input()
  public selected: any;

  @Input()
  public disabled: boolean = false;

  @Output() onSelect = new EventEmitter();

  public ngOnInit(): void {
    if (!this.selected) this.selected = this.options[0];
  }

  public onChange($ev): void {
    if (this.disabled) return;
    const checked = $ev.target.checked;
    const val = $ev.target.value;
    $ev.preventDefault();
        
    if (val) {
      this.onSelect.emit({
        val: val,
        checked: checked
      });
    }
  }
}
