import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { LoginInformationComponent } from './login-information.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    LoginInformationComponent
  ],
  entryComponents: [
  ],
  exports: [
    LoginInformationComponent
  ]
})
export class LoginInformationModule { }
