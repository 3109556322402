import { BehaviorSubject } from "rxjs/BehaviorSubject";

import { ValidationError } from "../../core";
import { Subject, Subscription } from "rxjs";
import { CampaignDataService } from "../services/campaign-data.service";

/**
 * Interface for Radius.
 */
export interface Radius {
  key: number;
  value: string;
}

/**
 * Campaign style model.
 */
export class CampaignStyleVm {
  public ageRange = "18";
  public customAgeRange: { from: number; to: number } = { from: 13, to: null };
  public gender = "B";
  public radius_in_miles: Radius = { key: 200, value: null };
  public radius_in_kms: Radius = { key: 200, value: "200 KM" };
  public radius = this.radius_in_miles;
  public locations: string[] = [];
  /**
   * Use custom image by default.
   */
  private isCustomImageValue = true;

  /**
   * Main image.
   */
  public couponImage: any;

  public dateRange: { start: Date; end: Date } = { start: null, end: null };

  /**
   * Campaign name.
   */
  private campaignMessageValue = "";

  /**
   * Show or hide price changing.
   */
  private isShowPriceValue = false;

  /**
   * Old price.
   */
  private priceLabelWasValue: string;

  /**
   * New price.
   */
  private priceLabelIsValue: string;

  private oldPriceValue: string;
  private newPriceValue: string;

  /**
   * Text over default image.
   */
  private imageLabelValue = "";

  public premiumStates: string[] = [];
  public premiumEmailStates: string[] = [];
  public subscriptions: Subscription[] = [];

  /**
   * Campaugn ID.
   */
  public id?: number;

  /**
   * Array of component form errors.
   */
  public errors: ValidationError[] = [];

  public dealDescription: string;
  private readonly initialDealDescription = "";
  /**
   * Fields for validation.
   */
  public validationFields = ["campainMessage", "imageLabel", "dealDescription"];
  public couponVideo: any;

  /**
   * @constructor
   */
  constructor() {
    this.campaignStyleChange.next(this);
    this.dealDescription = this.initialDealDescription;
  }

  /**
   * Emit this model.
   */
  public campaignStyleChange = new BehaviorSubject<CampaignStyleVm>(this);

  /**
   * Custom image.
   */
  public set isCustomImage(val) {
    this.isCustomImageValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Custom image.
   */
  public get isCustomImage() {
    return this.isCustomImageValue;
  }

  /**
   * Campaign full image.
   */
  // public set couponImageFull(val) {
  //   this.couponImageFullValue = val;
  //   this.campaignStyleChange.next(this);
  // }

  /**
   * Campaign full image.
   */
  // public get couponImageFull() {
  //   return this.couponImageFullValue;
  // }

  /**
   * Main campaign image.
   */
  // public set couponImageMain(val) {
  //   this.couponImageMainValue = val;
  //   this.campaignStyleChange.next(this);
  // }

  /**
   * Main campaign image.
   */
  // public get couponImageMain() {
  //   return this.couponImageMainValue;
  // }

  public campainStyleChanged() {
    this.campaignStyleChange.next(this);
  }

  /**
   * Default image label.
   */
  public set imageLabel(val) {
    this.imageLabelValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Default image label.
   */
  public get imageLabel() {
    return this.imageLabelValue;
  }

  /**
   * Campaign message.
   */
  public set campaignMessage(val) {
    this.campaignMessageValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Campaign message.
   */
  public get campaignMessage() {
    return this.campaignMessageValue;
  }

  /**
   * Show price.
   */
  public set isShowPrice(val) {
    this.isShowPriceValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Show price.
   */
  public get isShowPrice() {
    return this.isShowPriceValue;
  }

  /**
   * Old price.
   */
  public set priceLabelWas(val) {
    this.priceLabelWasValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * Old price.
   */
  public get priceLabelWas() {
    return this.priceLabelWasValue;
  }

  /**
   * New price.
   */
  public set priceLabelIs(val) {
    this.priceLabelIsValue = val;
    this.campaignStyleChange.next(this);
  }

  /**
   * New price.
   */
  public get priceLabelIs() {
    return this.priceLabelIsValue;
  }
  //
  // public get oldPrice() {
  //   return this.oldPriceValue;
  // }
  // public set oldPrice(val) {
  //   this.oldPriceValue = val;
  //   this.campaignStyleChange.next(this);
  // }
}
