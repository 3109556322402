import { Component, OnInit } from '@angular/core';
import { MdDialog, Sort } from '@angular/material';
import {
  AuthService,
  Category,
  DataTransferService, Location,
  MerchantsCoupon,
  Pagination,
  PaginationService,
  PlaceService
} from '../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService } from '../../popup';
import { WebappService } from '../../core/services/webapp/webapp.service';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { FilterByVisibility } from '../../core/services/filterby.enum';
import { CouponSettingsPageComponent } from '../coupon-settings-page/coupon-settings-page.component';
import {WebAppFeaturedCoupon} from "../../core/services/webapp/models/webapp-featured-coupon";

@Component({
  selector: 'pb-webapp-hide-coupons-page',
  templateUrl: './hide-coupons-page.component.html',
  styleUrls: ['./hide-coupons-page.component.scss']
})
export class WebAppHideCouponsPageComponent implements OnInit {
  private webappFilters: Category[] = [];
  private currentSort: Sort;
  public searchString = '';
  public pagination: Pagination = new Pagination();
  public pagedItems: MerchantsCoupon[];
  public coupons: MerchantsCoupon[] = [];
  public default_location: Location = new Location();
  public total: number = 0;
  private merchantId: number;
  public webAppId: string;
  private routeSub: Subscription;
  public filtering: { id: string; text: string }[] = [
    { id: FilterByVisibility.All.toString(), text: "All" },
    { id: FilterByVisibility.Visible.toString(), text: "Visible" },
    { id: FilterByVisibility.Hidden.toString(), text: "Hidden" }
  ];
  public selectedFiltering: string = FilterByVisibility.All.toString();

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private placeService: PlaceService,
    private webappService: WebappService,
    private dialog: MdDialog,
    private router: Router,
    private popupService: PopupService,
    private route: ActivatedRoute,
    private dataTransferService: DataTransferService,
    private paginationService: PaginationService
  ) {
    this.default_location.latitude = 40.7128;
    this.default_location.longitude = -74.0060;
  }

  public async ngOnInit(): Promise<void> {
    this.routeSub = Observable.combineLatest(this.activatedRoute.paramMap, this.authService.onUserChanged)
      .subscribe(async tuple => {
        const [params, user] = tuple;
        if (user) {
          this.merchantId = user.merchantId;
          this.webAppId = params.get('id');

          try {
            const currentWebApp = user.merchant.webapps.find((webapp) => this.webAppId == webapp.id);
            if (currentWebApp) {
              if (currentWebApp.default_location) {
                this.default_location = currentWebApp.default_location;
              }
              if (!currentWebApp.enable_priority_ads) {
                if (this.routeSub) {
                  this.routeSub.unsubscribe();
                }
                await this.router.navigate(['/dashboard']);

                return;
              }
            }
          } catch (error) {

          }

          if (this.webAppId) {
            this.webappFilters = await this.webappService.getWebAppFilters(this.webAppId);
            this.fetchCoupons();
          }
        }
        if (this.routeSub) {
          this.routeSub.unsubscribe();
        }
      });

    const userSubscription = this.authService.onUserChanged.subscribe(async result => {
      const user = result;
      if (user) {
        this.merchantId = user.merchantId;
        if (userSubscription) {
          userSubscription.unsubscribe();
        }
      }
    });

    // scroll to top
    window.scrollTo(0, 0);
  }

  public sortDataChanged(sort: Sort): void {
    this.adjustCouponData(sort, this.searchString, 1);
  }

  public searchStringChanged(event): void {
    this.searchString = event;
    this.fetchCoupons();
  }

  public clearSearch(): void {
    this.searchString = '';
    this.searchStringChanged(this.searchString);
  }

  public async filterByChanged(event): Promise<void> {
    this.selectedFiltering = event;
    this.fetchCoupons();
  }

  private fetchCoupons(): void {
    const filter = this.getFilterByVisibility();
    this.webappService.getCoupons(this.webAppId, this.searchString, filter).then(coupons => {
      this.coupons = coupons.results;
      this.total = coupons.count;
      this.adjustCouponData(null, '', 1);
    });
  }

  private getFilterByVisibility(): FilterByVisibility {
    if (this.selectedFiltering === FilterByVisibility.Visible.toString()) {
      return FilterByVisibility.Visible;
    } else if (this.selectedFiltering === FilterByVisibility.Hidden.toString()) {
      return FilterByVisibility.Hidden;
    } else {
      return FilterByVisibility.All;
    }
  }

  public setPageClicked(page: number): void {
    const filter = this.getFilterByVisibility();
    this.webappService.getCoupons(this.webAppId, this.searchString, filter, page).then(coupons => {
      this.coupons = coupons.results;
      this.total = coupons.count;
      this.adjustCouponData(this.currentSort, this.searchString, page);
    });
  }

  private adjustCouponData(sort: Sort, searchString: string, page: number): void {
    this.setPage(page);
  }

  private setPage(page: number): void {
    this.pagination = this.paginationService.getPagination(this.total, page, 100);
    this.pagedItems = this.coupons;
  }

  public openCouponSettings(coupon: MerchantsCoupon): void {
    const dialogRef = this.dialog.open(CouponSettingsPageComponent, {
      width: '900px',
      panelClass: 'campaign-publish-success',
      disableClose: false,
      data: {
        webappFilters: this.webappFilters,
        merchantId: this.merchantId,
        webappId: this.webAppId,
        couponName: coupon.name,
        couponId: coupon.id,
        onSuccess: (id) => {
          // this.reloadPlaces();
        }
      },
    });
    dialogRef.afterClosed().toPromise().then(result => {
      // Handle dialog close result if needed
    }).catch(err => err);
  }

  public toggleCoupon(coupon: MerchantsCoupon, $event: boolean) {
    this.webappService.showHideCoupon(coupon, this.webAppId, $event).then(() => {
      this.popupService.info('Listing updated.');

      if (this.selectedFiltering === FilterByVisibility.Hidden.toString()) {
        this.coupons = this.coupons.filter(c => c.id !== coupon.id);
        this.total = this.total - 1;
      }
    });
  }

  getCouponProvider(provider: string) {
    if (provider === "opentable") {
      return "OpenTable";
    } else if (provider === "viator") {
      return "Viator";
    } else if (provider === "tripster") {
      return "Tripster";
    } else if(provider == 'groupon') {
        return 'Groupon';
    } else if (provider === "tiquets") {
      return "Tiquets";
    } else if (provider === "simpleview") {
      return "Simple View";
    } else if (provider === "exploreli") {
      return "Explore LI";
    } else if (provider === "londonlocalguide") {
      return "London Local Guide";
    } else if (provider === "minicards") {
        return "Minicards";
    } else if (provider === "smokyexplorer") {
        return "Smoky Explorer";
    } else if (provider === "discoverlongisland") {
        return "Discover Long Island";
    }
    return "GettinLocal";
  }
}
