import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MdButtonToggleModule, MdIconModule } from "@angular/material";
// import { AngularMultiSelectModule } from 'angular4-multiselect-dropdown/angular4-multiselect-dropdown';
// import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { PlanSwitcherModule } from "../shared/plan-switcher/plan-switcher.module";
import { PricingModule } from "../shared/pricing/pricing.module";
import { SharedModule } from "../shared/shared.module";
import { CampaignStyleComponent } from "./campaign-style/campaign-style.component";
import { CampaignComponent } from "./campaign.component";
import { CostBreakdownComponent } from "./finalize-campaign/cost-breakdown/cost-breakdown.component";
import { FinalizeCampaignComponent } from "./finalize-campaign/finalize-campaign.component";
import { PaymentMethodComponent } from "./finalize-campaign/payment-method/payment-method.component";
import { PreviewCouponComponent } from "./preview-coupon/preview-coupon.component";
import { RedemptionOptionsComponent } from "./redemption-options/redemption-options.component";
import { CampaignDataService } from "./services/campaign-data.service";
import { CouponService } from "./services/campaign-service/coupon.service";
import { PromotionService } from "./services/campaign-service/promotion.service";
import { StateSelectionPopupComponent } from "./target-marketing/state-selection/state-selection-popup.component";
import { UsMapModule } from "./target-marketing/state-selection/us-map/us-map.module";
import { TargetMarketingComponent } from "./target-marketing/target-marketing.component";
import { CanadaMapModule } from "./target-marketing/state-selection/canada-map/canada-map.module";
import { CampaignPublishSuccessPopupComponent } from "./campaign-publish-popup/campaign-publish-popup.component";
import { CampaignPublishSuccessHotelPopupComponent } from "app/campaign/campaign-publish-hotel-popup/campaign-publish-hotel-popup.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    // AngularMultiSelectModule,
    // MultiselectDropdownModule,
    PricingModule,
    PlanSwitcherModule,
    UsMapModule,
    CanadaMapModule,
    MdButtonToggleModule,
    MdIconModule,
  ],
  declarations: [
    CampaignComponent,
    CampaignStyleComponent,
    TargetMarketingComponent,
    RedemptionOptionsComponent,
    CostBreakdownComponent,
    FinalizeCampaignComponent,
    PreviewCouponComponent,
    PaymentMethodComponent,
    StateSelectionPopupComponent,
    CampaignPublishSuccessPopupComponent,
    CampaignPublishSuccessHotelPopupComponent
  ],
  entryComponents: [
    StateSelectionPopupComponent,
    CampaignPublishSuccessPopupComponent,
    CampaignPublishSuccessHotelPopupComponent
  ],
  providers: [PromotionService, CouponService, CampaignDataService],
  exports: [CampaignComponent, PaymentMethodComponent]
})
export class CampaignModule {}
