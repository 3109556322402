<div class="global-caption">{{caption}}</div>

<div class="container" *ngIf="isCouponView; else oldView">
  <div class="date">
    <span>Start</span>
    <pb-datepicker class="datepicker" [instance]="instanceStart" #instanceStart="ngModel" [maxInputDate]="maxInputDate"
      [minInputDate]="minInputDate" [required]="startDateRequired" name="start" placeholder="Start Date" [(ngModel)]="value.start"
      #start="ngModel" pbDateRangeValidation [model]="end" (ngModelChange)="startDateEmit($event)"></pb-datepicker>
  </div>
  <div class="date">
    <div class="row">
      <div *ngIf="!noEndDate" style="display: flex; align-items: center;">
      <span style="padding-right: 15px;">End</span>
      <pb-datepicker class="datepicker" [instance]="instanceEnd" #instanceEnd="ngModel" [maxInputDate]="maxInputDate"
        [minInputDate]="minInputDate" [required]="endDateRequired" name="end" placeholder="12 MONTHS" [(ngModel)]="value.end"
        #end="ngModel" pbDateRangeValidation [model]="start"></pb-datepicker>
      </div>
      <div style="padding-top: 15px;">
      <pb-checkbox key="noEndDate"
                   name="noEndDate"
                   label="No End Date"
                   [value]="noEndDate"
                   [checked]="noEndDate"
                   (change)="noEndDateEmit($event)"></pb-checkbox>
      </div>
    </div>
  </div>
</div>

<ng-template #oldView>
  <div class="container" *ngIf="is">
    <pb-datepicker class="datepicker" [instance]="instanceStart" #instanceStart="ngModel" [maxInputDate]="maxInputDate"
      [minInputDate]="minInputDate" [required]="startDateRequired" name="start" placeholder="Start Date" [(ngModel)]="value.start"
      #start="ngModel" pbDateRangeValidation [model]="end"></pb-datepicker>
    <span class="to">to</span>
    <pb-datepicker class="datepicker" [instance]="instanceEnd" #instanceEnd="ngModel" [maxInputDate]="maxInputDate"
      [minInputDate]="minInputDate" [required]="endDateRequired" name="end" placeholder="12 MONTHS" [(ngModel)]="value.end"
      #end="ngModel" pbDateRangeValidation [model]="start"></pb-datepicker>
  </div>
</ng-template>
