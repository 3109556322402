import {Subscription} from 'rxjs/Rx';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MdDialog} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {
  AuthService,
  CategoriesService,
  CouponsService,
  DataTransferService,
  MerchantsCoupon,
  MerchantService,
  MessagesService,
  NewMessage,
  ServerError,
  UtilsService,
  WebappService,
} from '../../core';
import {PopupService} from '../../popup';
import {ImagesService} from "../../campaign/services/campaign-service/images.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {takeUntil} from "rxjs/operators";
import {combineLatest} from "rxjs/observable/combineLatest";
import {Subject} from "rxjs";

declare var tinymce: any;

/**
 * New message component.
 */
@Component({
  selector: 'pb-add-loyalty-popup',
  templateUrl: './add-loyalty-popup.component.html',
  styleUrls: ['./add-loyalty-popup.component.scss']
})
export class AddLoyaltyPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  private routePreviousUrl: Subscription;
  public popupForm: FormGroup;
  logoFile: File | null = null;
  popupImage: File | null = null;
  savePopupImage: String = '';
  private destroy$ = new Subject<void>();

  @ViewChild('contentEditor') contentEditor: ElementRef;

  constructor(
    private fb: FormBuilder,
    private categoriesService: CategoriesService,
    private webappService: WebappService,
    private dialog: MdDialog,
    private popupService: PopupService,
    private merchantService: MerchantService,
    private messagesService: MessagesService,
    private authService: AuthService,
    private dataTransferService: DataTransferService,
    private router: Router,
    private route: ActivatedRoute,
    private imageService: ImagesService,
    private utilService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private couponsService: CouponsService,
    private sanitizer: DomSanitizer,
  ) {
    this.routePreviousUrl = this.router.events
      .filter(e => e instanceof NavigationEnd)
      .subscribe((v: NavigationEnd) => {
        localStorage['previousUrl'] = v.url;
      });
  }

  /**
   * A merchant id.
   */
  private merchantId: number;

  /**
   * A message to be sent.
   */
  public message: NewMessage = new NewMessage();

  public coupon: MerchantsCoupon;

  private webAppId: string;
  public previewContent: SafeHtml;

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.popupForm = this.fb.group({
      name: ['', Validators.required],
      popupType: ['type1', Validators.required],
      backgroundColor: ['#ffffff', Validators.required],
      content: ['', Validators.required],
      primaryButtonText: ['', Validators.required],
      primaryButtonUrl: ['', [Validators.required, Validators.pattern('https?://.+')]],
      primaryButtonColor: ['#000000', Validators.required],
      primaryButtonTextColor: ['#ffffff', Validators.required],
      primaryButtonBorderColor: ['#000000', Validators.required],
      secondaryButtonText: ['', Validators.required],
      secondaryButtonColor: ['#000000', Validators.required],
      secondaryButtonTextColor: ['#ffffff', Validators.required],
      secondaryButtonBorderColor: ['#000000', Validators.required],
      popupActionUrl: ['', [Validators.required, Validators.pattern('https?://.+')]], // New field for Popup Action URL
    });

    combineLatest([
      this.activatedRoute.paramMap,
      this.authService.onUserChanged
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(async ([params, user]) => {
      if (user) {
        this.merchantId = user.merchantId;
        const spinner = this.popupService.spinner();

        try {
          this.webAppId = this.activatedRoute.snapshot.params['id'];
          const popupId = this.activatedRoute.snapshot.params['popupId'];

          if (popupId !== 'new') {
            let data = await this.webappService.getLoyaltyPopup(this.webAppId, popupId);
            const t_previewContent = await this.webappService.getPopupPreviewHTML(this.webAppId, popupId);
            this.previewContent = this.sanitizer.bypassSecurityTrustHtml(t_previewContent);

            this.fixHexColor();
            this.savePopupImage = data.popupImage;
            this.popupForm.patchValue(data);

            if (tinymce.activeEditor) {
              tinymce.activeEditor.setContent(data.content);
            }
          } else {
            const t_previewContent = await this.webappService.getPopupDefaultPreviewHTML(this.webAppId);
            this.previewContent = this.sanitizer.bypassSecurityTrustHtml(t_previewContent);

            this.popupForm.controls['popupType'].setValue('type1');
            this.toggleFields('type1');
            // this.previewContent = this.sanitizer.bypassSecurityTrustHtml('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"' +
            //   ' integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
          }

          spinner.close();
        } catch (e) {
          console.error('Error occurred:', e);
          spinner.close();
        }
      }
    });
  }

  private initializeTinyMCE(): void {
    setTimeout(() => {
      try {
        tinymce.init({
          height: 480,
          promotion: false,
          target: this.contentEditor.nativeElement,
          plugins: 'link paste table',
          toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link',
          setup: function (editor) {
            editor.on('change', function () {
              editor.save();
              this.popupForm.patchValue({
                [editor.id]: editor.getContent()
              });
            }.bind(this));
          }.bind(this)
        });
      } catch (e) {
        console.error('Error initializing TinyMCE:', e);
      }
    }, 0);
  }

  public ngAfterViewInit(): void {
    this.initializeTinyMCE();

    this.popupForm.get('popupType').valueChanges.subscribe(value => {
      this.toggleFields(value);
    });
  }

  public toggleFields(value: string) {
    const fullImageFields = ['popupActionUrl'];
    const logoWithTextFields = [
      'backgroundColor', 'content', 'primaryButtonText', 'primaryButtonUrl',
      'primaryButtonColor', 'primaryButtonTextColor', 'primaryButtonBorderColor',
      'secondaryButtonText', 'secondaryButtonColor', 'secondaryButtonTextColor',
      'secondaryButtonBorderColor'
    ];

    if (value === 'type1') {
      this.popupForm.get("popupActionUrl").setValidators([Validators.required, Validators.pattern('https?://.+')]);
      logoWithTextFields.forEach(field => this.popupForm.get(field).clearValidators());
    } else if (value === 'type2') {
      fullImageFields.forEach(field => this.popupForm.get(field).clearValidators());
      logoWithTextFields.forEach(field => this.popupForm.get(field).setValidators([Validators.required]));
    }

    fullImageFields.concat(logoWithTextFields).forEach(field => this.popupForm.get(field).updateValueAndValidity());
  }

  private fixHexColor(): void {
    const colorFields = [
      'primaryButtonColor',
      'primaryButtonTextColor',
      'primaryButtonBorderColor',
      'secondaryButtonColor',
      'secondaryButtonTextColor',
      'secondaryButtonBorderColor',
      'backgroundColor'
    ];

    colorFields.forEach(field => {
      this.popupForm.get(field).valueChanges.subscribe(value => {
        const sanitizedValue = this.unSanitizeColor(value);
        if (value !== sanitizedValue) {
          this.popupForm.get(field).setValue(sanitizedValue, {emitEvent: false});
        }
      });
    });
  }

  /**
   * Handles on submit form event.
   */
  onSubmit() {

    if (this.popupForm.valid) {
      this.popupForm.value.backgroundColor = this.sanitizeColor(this.popupForm.value.backgroundColor);
      this.popupForm.value.primaryButtonColor = this.sanitizeColor(this.popupForm.value.primaryButtonColor);
      this.popupForm.value.primaryButtonTextColor = this.sanitizeColor(this.popupForm.value.primaryButtonTextColor);
      this.popupForm.value.primaryButtonBorderColor = this.sanitizeColor(this.popupForm.value.primaryButtonBorderColor);
      this.popupForm.value.secondaryButtonColor = this.sanitizeColor(this.popupForm.value.secondaryButtonColor);
      this.popupForm.value.secondaryButtonTextColor = this.sanitizeColor(this.popupForm.value.secondaryButtonTextColor);
      this.popupForm.value.secondaryButtonBorderColor = this.sanitizeColor(this.popupForm.value.secondaryButtonBorderColor);

      const spinner = this.popupService.spinner();
      this.webappService.updateLoyaltyPopup(this.webAppId, this.route.snapshot.params['popupId'], this.popupForm.value, this.logoFile, this.popupImage)
        .then((res: any) => {
          const newId = res.id;
          spinner.close();
          this.popupService.info('Loyalty popup updated.');
          this.webappService.getPopupPreviewHTML(this.webAppId, newId).then((t_previewContent) => {
            this.previewContent = this.sanitizer.bypassSecurityTrustHtml(t_previewContent);
          });
          setTimeout(() => {
            this.router.navigate([`/webapps/${this.webAppId}/configure/`]);
          });
        })
        .catch((error: ServerError) => {
          spinner.close();
        });
    } else {
      console.info(this.popupForm)
      this.popupService.info('Please fill in all required fields.')
    }
  }

  public ngOnDestroy(): void {
    tinymce.remove();

    if (this.routePreviousUrl) {
      this.routePreviousUrl.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onFileChange(event: any, field: string) {
    if (event.target.files.length > 0) {
      if (field === 'logo') {
        this.logoFile = event.target.files[0];
      } else if (field === 'popupImage') {
        this.popupImage = event.target.files[0];

        // Find element with id="previewImage"
        const previewImage = document.getElementById('previewImage');
        console.log(previewImage);
        if (previewImage) {
          previewImage.setAttribute('src', URL.createObjectURL(this.popupImage));
        }
      }
    }
  }

  // Sanitize the color value to remove transparency
  public unSanitizeColor(color: string): string {
    // If color includes transparency part, strip it off
    if (color.length === 9 && color.startsWith('#')) {
      return color.slice(0, 7);
    }
    return color;
  }

  public sanitizeColor(color: string): string {
    // Add FF to the beginning if missing
    if (color.length === 7 && color.startsWith('#')) {
      color = `#${color.slice(1)}FF`;
    }
    return color;
  }

  onCancel($event: MouseEvent) {

    $event.preventDefault();
    this.router.navigate([`/webapps/${this.webAppId}/loyalty-popups/`]);
  }
}
