<div class="root">
  <div class="cost-container">
    <div class="plan-item"><a class="title">Campaign Summary</a> <span class="right"></span></div>
    <div class="line"></div>
    <!--div class="plan-item"><a class="subscription">Campaign Type Selected</a> <span
        class="right title">{{getPlanName()}}</span>
    </div-->
    <br />
    <div class="cost-item"><a class="subscription">Monthly Fee</a> <span class="price bold">{{currency}}{{getSubscriptionFee()}}
      </span>
    </div>
    <div *ngIf="getPlanName() === 'Featured'">
      <div class="cost-item" *ngIf="premiumStateCount > 0"><a class="subscription">Featured Placement</a>
      </div>
      <div class="cost-item" *ngIf="premiumStateCount > 0"><a class="subscription">
          &nbsp;&nbsp;> Additional States - {{premiumStateCount}} @
          {{currency}}{{getPremiumStatesFee()}} ea.</a>
        <span class="price bold">{{currency}}{{getPremiumStatesTotalFee()}}</span>
      </div>
    </div>
    <div *ngIf="getPlanName() === 'Local'">
      <div class="cost-item" *ngIf="premiumEmailStateCount > 0"><a class="subscription">Additional Fees</a>
      </div>
      <div class="cost-item" *ngIf="premiumEmailStateCount > 0"><a class="subscription">
          &nbsp;&nbsp;> Additional States - {{premiumEmailStateCount}} @
        {{currency}}{{getPremiumEmailStatesFee()}} ea.</a>
        <span class="price bold">{{currency}}{{getPremiumEmailStatesTotalFee()}}</span>
      </div>
<!--      <div class="cost-item" *ngIf="customLinkCount > 0"><a class="subscription">Added Features Fees</a>-->
<!--      </div>-->
<!--      <div class="cost-item" *ngIf="customLinkCount > 0"><a class="subscription">-->
<!--          &nbsp;&nbsp;> Custom Links - -->
<!--          ${{getCustomLinkFee()}} </a>-->
<!--        <span class="price bold">${{getCustomLinkTotalFee()}}</span>-->
<!--      </div>-->
    </div>
    <div *ngIf="hasPromotion()">
      <div class="cost-item"><a class="subscription">Promotional Savings - {{getPromotionPercentage()}}%</a> <span
        class="price bold">-{{currency}}{{getPromotionalSavings()}}
        </span>
      </div>
      <div class="cost-item" *ngIf="showEnd()"><a class="subscription">
        Ends: {{getCampaignEndDate()}}</a>
      </div>
    </div>
    <div>
      <div class="cost-item"><a class="subscription">Subtotal</a> <span class="price bold">{{currency}}{{getSubTotalAfterPromotion()}}
        </span>
      </div>
    </div>
    <div class="line"></div>
    <div class="plan-item"><a class="subscription">Taxes</a> <span class="price bold">{{currency}}{{getTax()}}</span></div>
    <div class="line"></div>
    <div class="grey">
      <div class="plan-item"><a class="subscription">You Pay ({{currencyName}})</a> <span class="price bold">{{currency}}{{getTotal()}}</span>
      </div>
      <div class="publish">
        <button [disabled]="disabled" pb-button class="button" type="button" (click)="publish()">
          {{ payButtonText }}</button>
      </div>
    </div>
    <div class="text">Billed monthly on the start date of your campaign.</div>
  </div>
</div>
