import { Subscription } from 'rxjs/Subscription';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, ObservableInput } from 'rxjs/Observable';


import { MerchantService } from '../services/merchant/merchant.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthService } from '../services/auth/auth.service';
import { PaymentService } from '../services/payment/payment.service';
import { User } from '../models/user';
import { Merchant } from '../models/merchant';


/**
 * Check user payment status.
 */
@Injectable()
export class PaymentGuard implements CanActivate {

    private subscription: Subscription;

    /**
     * .ctor
     * @param router Angular router.
     * @param authService Authorization service.
     * @param tokenService Token storage service.
     */
    constructor(private router: Router,
        private authService: AuthService,
        private tokenService: TokenStorageService,
        private merchantService: MerchantService,
        private paymentService: PaymentService) { }

    /**
     * @inheritdoc
     */
    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        this.subscription = this.authService.onUserChanged
            .skipWhile(user => !user)
            // tslint:disable-next-line:no-magic-numbers
            .timeout(10000)
            .catch((e, caugh): ObservableInput<User> => {
                this.unsubscribeCheck();
                return null;
            })
            .subscribe(async (user): Promise<void> => {
                const merchant = await this.merchantService.getMerchant(user.merchantId);
                const customerInfos = await this.paymentService.getCustomerInfo(merchant.id);
                if (this.isRequireBillingInfo(merchant)) {
                    await this.redirectToPaymentPage();
                }
                this.unsubscribeCheck();
            });

        return true;
    }

    /**
     * Checking if billing required.
     * @param merchant Current merchant.
     */
    private isRequireBillingInfo(merchant: Merchant): boolean {
        return merchant.forcePayment;
    }

    /**
     * Redirecting to payment page.
     */
    private async redirectToPaymentPage(): Promise<void> {
        this.router.navigate(['/registration-pay']);
    }

    /**
     * Unsubscribe on events.
     */
    private unsubscribeCheck() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
