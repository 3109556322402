import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pb-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements OnInit {
  constructor() {
  }

  @Input()
  public last: number = 4;

  @Input()
  public number: number;

  @Input()
  public isActive: boolean;

  @Input()
  public horizontal: boolean;

  @Input()
  public pageNumber: number;

  /**
   * Put a mark in the step.(The component of this step is valid).
   */
  @Input()
  public isValid: boolean;

  public ngOnInit() {
  }

}
