import {AfterViewChecked, ChangeDetectorRef, Component, Input} from '@angular/core';

@Component({
  selector: 'pb-simple-tooltip',
  templateUrl: './simple-tooltip.component.html',
  styleUrls: ['./simple-tooltip.component.scss']
})
export class SimpleTooltipComponent implements AfterViewChecked {
  @Input()
  public description: string;

  @Input()
  public hint: string;

  @Input()
  public note: string;

  constructor(private changeDetector : ChangeDetectorRef  ) {}

  public ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
}
