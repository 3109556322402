import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationPayComponent } from './registration-pay.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [RegistrationPayComponent]
})
export class RegistrationPayModule { }
