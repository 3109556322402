import {Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MdDialog, MdDialogRef } from '@angular/material';
import { Observable, Subject, BehaviorSubject } from 'rxjs/Rx';
import { map, switchMap, tap, finalize } from 'rxjs/operators';

import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { CategorySelectionPopupComponent } from './category-selection/category-selection-popup.component';
import { CovidOptionsSelectionPopupComponent } from './covid-options-picker/covid-options-picker.component';

import {
  User,
  ServerError,
  Merchant,
  Place,
  State,
  PlaceSearch,
  Schedule,
  Category,
  Image,
  ErrorHandlerService, PlansService, BrandService, BrandMapper,
  CustomizationService, Address, CategoryMapper
} from '../core';
import {
  CategoriesService,
  MerchantService,
  AuthService,
  UtilsService,
  GooglePlaceProvider,
  PlaceService,
  LoadedImage,
} from '../core';
import {
  CropperPopupComponent,
  SelectizeDropdownComponent,
  AddressSelectizeComponent,
} from '../shared';

import {
  httpUrlRegex,
  twitterUrlRegex,
  facebookUrlRegex,
  instagramUrlRegex,
  youtubeUrlRegex,
  linkedinUrlRegex,
  tiktokUrlRegex,
  pininterestUrlRegex,
  snapchatUrlRegex,
  flickrUrlRegex,
  tumblrUrlRegex,
  meetupUrlRegex,
  evenbrightUrlRegex,
  websiteUrlRegex,
  nonCodedPhoneMask,
  scrollToNotValid,
  scrollToBottom,
  httpsOrHttpsUriRegex,
  b64toBlob,
  getImageDimensions,
  scrollIntoField,
} from '../../utils';
import { zip } from 'rxjs/observable/zip';
import { of } from 'rxjs/observable/of';
import { PopupResult, PopupService, SpinnerPopupComponent } from '../popup';
import { CampaignPublishSuccessPopupComponent } from '../campaign/campaign-publish-popup/campaign-publish-popup.component';
import { getIncompleteProfileItemList } from './profile-utils';
import {CampaignPublishSuccessHotelPopupComponent} from '../campaign/campaign-publish-hotel-popup/campaign-publish-hotel-popup.component';
import {CampaignPublishPopupResult} from '../campaign/campaign-publish-hotel-popup/campaign-publish-popup-result.enum';
import {CampaignDataService} from '../campaign/services/campaign-data.service';
import {Plans} from '../core/models/plans';
import {MerchantApp} from '../core/models/merchant-app';
import {getFlagEmoji} from '../../utils/texts';
import { COUNTRY_LIST } from '../../utils/countries';
import event = google.maps.event;
import {CategoryDto} from "../core/services/categories/dtos/category.dto";
import {checkImageMimeType} from "../../utils/others";

const NUMBER_OF_OPERATION_HOURS_FIELDS = 7;

/**
 * Merchant's company profile page component.
 */
@Component({
  selector: 'pb-company-profile-page',
  templateUrl: './company-profile-page.component.html',
  styleUrls: ['./company-profile-page.component.scss'],
})
export class CompanyProfilePageComponent implements OnInit, OnDestroy, AfterViewChecked {
  /**
   * Destroy subject.
   */
  private destroy$ = new Subject<void>();

  /**
   * Spinner.
   */
  private spinner: MdDialogRef<SpinnerPopupComponent>;

  /**
   * Selected address ID.
   */
  private placeId: string;

  /**
   * The place from Locations list which set in profile.
   */
  private merchantLocation: Place;

  public merchantCountries = [
    { value: 'US', text: 'United States', checked: true, class: 'black', font: 12, dial_code: '+1' },
    { value: 'CA', text: 'Canada', checked: false, class: 'black', font: 12, dial_code: '+1' },
    { value: 'UK', text: 'United Kingdom', checked: false, class: 'black', font: 12, dial_code: '+44' },
    { value: 'NL', text: 'Netherlands', checked: false, class: 'black', font: 12, dial_code: '+31' },
    { value: 'DE', text: 'Germany', checked: false, class: 'black', font: 12, dial_code: '+49' },
    { value: 'AT', text: 'Austria', checked: false, class: 'black', font: 12, dial_code: '+43' },
  ];
  public merchantCountry = ['US'];

  public merchantCountriesFuture1 = [
    { value: 'IR', text: 'Ireland', checked: false, class: 'grey', font: 12, dial_code: '+1' },
    { value: 'FR', text: 'France', checked: false, class: 'grey', font: 12, dial_code: '+1' },
    { value: 'BG', text: 'Belgium', checked: false, class: 'grey', font: 12, dial_code: '+44' },
    { value: 'DM', text: 'Denmark', checked: false, class: 'grey', font: 12, dial_code: '+1' },
  ];
  public merchantCountriesFuture2 = [
    { value: 'SW', text: 'Spain', checked: false, class: 'grey', font: 12, dial_code: '+1' },
    { value: 'SP', text: 'Spain', checked: false, class: 'grey', font: 12, dial_code: '+44' },
    { value: 'IT', text: 'Italy', checked: false, class: 'grey', font: 12, dial_code: '+31' },
    { value: 'BH', text: 'Bahamas', checked: false, class: 'grey', font: 12, dial_code: '+31' }
  ];

  /**
   * All merchant locations.
   */
  private allMerchantLocations: Place[] = [];

  /**
   * Current user.
   */
  public user: User;

  /**
   * Current merchant.
   */
  public merchant: Merchant = new Merchant();

  /**
   * Merchant keywords.
   */
  public keywordsOfMerchats$ = new Subject<string>();

  /**
   * Schedule.
   */
  public schedule$: BehaviorSubject<Schedule>;

  /**
   * Merchant website url.
   */
  public merchantURL: string;

  /**
   * Merchant reviews url.
   */
  public reviewsURL: string;

  /**
   * Merchant website url.
   */
  public isLogoRect = false;

  /**
   * User agree with Terms of Use.
   */
  public isAgree = false;

  public password: string = null;
  public passwordRepeat: string = null;
  public imagesErrorText: string;
  public textCovidInstructions = '';

  public currentAddressStreet: string;
  public currentAddressBusiness: string;
  public brandImage: string = null;

  /**
   * Component form.
   */
  @ViewChild(NgForm)
  public form: NgForm;

  /**
   * Dropdown component of address.
   */
  @ViewChild('addressSelectizeComponent')
  public addressSelectizeComponent: AddressSelectizeComponent;

  /**
   * Photo Gallery preview.
   */
  @ViewChild('galleryPreview')
  public galleryPreview: ImagePreviewComponent;

  private logo: Blob;
  /**
   * Base64 logo image adjusted after cropping.
   */
  public logoImage: string | ArrayBuffer;

  public get galleryImagesCount(): number {
    if (this.galleryPreview) {
      return this.galleryPreview.images.length;
    }

    return 0;
  }

  public get httpsOrHttpMask(): string {
    return httpsOrHttpsUriRegex();
  }

  public get urlMask(): string {
    return httpUrlRegex();
  }

  public get twitterUrlMask(): string {
    return twitterUrlRegex();
  }

  public get facebookUrlMask(): string {
    return facebookUrlRegex();
  }

  public get instagramUrlMask(): string {
    return instagramUrlRegex();
  }

  public get youtubeUrlMask(): string {
    return youtubeUrlRegex();
  }

  public get linkedinUrlMask(): string {
    return linkedinUrlRegex();
  }

  public get tiktokUrlMask(): string {
    return tiktokUrlRegex();
  }

  public get pininterestUrlMask(): string {
    return pininterestUrlRegex();
  }

  public get snapchatUrlMask(): string {
    return snapchatUrlRegex();
  }

  public get flickrUrlMask(): string {
    return flickrUrlRegex();
  }

  public get tumblrUrlMask(): string {
    return tumblrUrlRegex();
  }

  public get meetupUrlMask(): string {
    return meetupUrlRegex();
  }

  public get evenbrightUrlMask(): string {
    return evenbrightUrlRegex();
  }

  public get websiteUrlMask(): string {
    return websiteUrlRegex();
  }
  public get phoneMask() {
    return nonCodedPhoneMask();
  }

  private bgLogo: Blob;

  /**
   * Base64 background logo image adjusted after cropping.
   */
  public bgLogoImage: string | ArrayBuffer;

  /**
   * A list of categories.
   */
  public categories: { id: string; text: string }[];

  public selectedCategoryIds: string[];

  @ViewChild('categoriesDropdown')
  public categoriesDropdown: SelectizeDropdownComponent;
  /**
   * A list of states.
   */
  public states: State[] = [];

  public get statesFormatted() {
    return this.states.map((v) => {
      return { id: v.code, text: v.code };
    });
  }

  /**
   * Flag for determine activity of account.
   */
  public isActive = true;

  public availablePlans: Plans[];

  /**
   * Flag for image load indication.
   */
  public isImageLoading = false;

  public saveAndFinishInProcess = false;

  public places: { id: string; text: string }[] = [];
  public place: Place = new Place();


  public brand: MerchantApp = new MerchantApp({
    id: 'gettinlocal',
    name: 'GettinLocal'
  });

  public zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public usZipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public canadaZipMask = null; // [/[A-Z]/, /\d/, /[A-Z]/, /\s/, /\d/, /[A-Z]/, /\d/];
  public countryList = COUNTRY_LIST;
  /**
   * Disable the Save button.
   */
  public get disableSaveButton() {
    const { isHolding, creationCompleted } = this.merchant;

    return isHolding && !creationCompleted && !this.isAgree;
  }

  /**
   * Showing tooltip.
   */
  public get showTooltip() {
    return !this.merchant.isHolding && !this.merchant.creationCompleted;
  }

  /**
   * @constructor
   */
  constructor(
    private cdref: ChangeDetectorRef,
    private dialog: MdDialog,
    private authService: AuthService,
    private popupService: PopupService,
    private categoriesService: CategoriesService,
    private merchantService: MerchantService,
    private dataService: CampaignDataService,
    private plansService: PlansService,
    private brandService: BrandService,
    private brandMapper: BrandMapper,
    private categoryMapper: CategoryMapper,
    private customizationService: CustomizationService,
    private router: Router,
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private utilsService: UtilsService,
    private googlePlaceProvider: GooglePlaceProvider,
    private placeService: PlaceService
  ) {
    this.brandService.brand.subscribe((b) => {
      this.brand = b;
      this.customizationService.setCustomizations(b.customization);
    });
  }


  public ngAfterViewChecked() {
    this.cdref.detectChanges();
  }
  /**
   * Set merchant operating hours when schedule change.
   * @param schedule Schedule of merchnt work.
   */
  public changeTime(schedule: Schedule) {
    this.merchant.operationHours = schedule;
  }

  public categoryChanged(event: any) {
    // if (this.selectedCategoryIds.length > 0) {
    //   this.selectedCategoryIds.map((oc) => {
    //     const tmp = this.categories.find((c) => c.id === oc);
    //     console.error(tmp);
    //     if (tmp) {
    //       const mer_cats = this.merchant.keywords.split(",");
    //       const index = mer_cats.indexOf(tmp.text);
    //       console.error(mer_cats);
    //       if (index > 0) {
    //         this.merchant.keywords = mer_cats
    //           .splice(index, 1)
    //           .join(",");
    //       }
    //     }
    //   });
    // }

    const cat = this.categories[event.target.selectedIndex - 1];
    this.selectedCategoryIds = [cat.id];
    // if (!this.merchant.keywords.includes(cat.text)) {
    //   this.merchant.keywords += `,${cat.text}`;
    // }

    // this.keywordsOfMerchats$.next(this.merchant.keywords);
    // this.selectedCategoryIds = Object.assign([], this.selectedCategoryIds);
  }

  public async categorySelectionHandler(): Promise<void> {
    const dialogRef = this.dialog.open(CategorySelectionPopupComponent, {
      width: '600px',
      /**
       * global style for popup to remove paddings
       */
      panelClass: 'target-dialog',
      data: this.selectedCategoryIds,
    });
    const categoryId: string[] = await dialogRef.afterClosed().toPromise();
    if (categoryId) {
      //
      const categorys = [];
      for (const id in categoryId) {
        if (categoryId[id]) {
          categorys[0] = this.categories.find((o) => o.id === categoryId[id]);
        }
      }
      const lastCategory = Object.keys(categoryId).slice(-1);
      this.categoriesDropdown.addItem(categoryId[lastCategory[0]]);
      categorys.forEach((categoryItem: { id: string; text: string }) => {
        if (!this.merchant.keywords.includes(categoryItem.text)) {
          this.merchant.keywords += `, ${categoryItem.text} `;
        }
      });
      this.keywordsOfMerchats$.next(this.merchant.keywords);
      this.selectedCategoryIds = Object.assign([], this.selectedCategoryIds);
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.startSpinner();

    this.plansService
      .getCampaignPlans()
      .then(p => {
        this.availablePlans = p;
      })
      .catch(err => console.error(err));

    zip(
      this.authService.onUserChanged,
      this.categoriesService.getFlatLeveled$(null),
      this.utilsService.getStates()
    )
      .pipe(
        map(([user, categories, states]: [User, Category[], State[]]) => ({
          user,
          categories,
          states,
        })),
        tap(({ user, categories, states }) =>
          this.handleProfileData(user, categories, states)
        ),
        switchMap(() => this.placeService.getPlaces(this.merchant.id)),
        tap((places: Place[]) => (this.allMerchantLocations = places)),
        tap(() => (this.merchantLocation = this.getMerchantLocation())),
        switchMap(() => this.handleMerchantImages()),
        tap((images: Image[]) => this.addImagesToPreview(images)),
        finalize(() => {
          if (
            !this.user.merchantCreationComplete &&
            this.user.merchant.logo !== null
          ) {
            this.popupService
              .done(
                'To finish Claiming This Business, please update our Password and ' +
                  ' update the Terms Of Use located at the bottom of this page.',
                'Action Required',
                'Update',
              )
              .then(() => {
                scrollToBottom();
              });
          }
          this.stopSpinner();
          this.goToSection();
        })
      )
      .subscribe();
  }

  public goToSection(): void {
    const nav_to_section = this.route.snapshot.paramMap.get('nav_to_section');
    if (nav_to_section) {
      setTimeout(() => {
        scrollIntoField(nav_to_section);
      }, 1000);
    }
  }

  private handleProfileData(
    user: User,
    categories: Category[],
    states: State[]
  ): void {
    this.handleMerchant(user);
    this.handleCategories(categories);
    this.states = states;
    this.handleBrandCategories();
  }

  private handleMerchant(user: User): void {
    this.user = user;
    this.merchant = user.merchant;
    this.keywordsOfMerchats$.next(this.merchant.keywords);
    this.schedule$ = new BehaviorSubject<Schedule>(
      this.merchant.operationHours
    );

    this.merchantCountries = this.merchantCountries.map((c: any) => {
      c.checked = c.text === user.merchant.address.country;
      if (c.checked) {
        this.merchantCountry = [c.value];
      }
      return c;
    });

    getImageDimensions(this.merchant.logo)
      .then((res) => {
        this.isLogoRect = res.w !== res.h;
      })
      .catch((error) => console.error(error));

    if (this.merchant.url) {
      this.merchantURL = this.merchant.url;
      // const urlData = new URL(this.merchant.url);
      // this.merchantURL = urlData.hostname + urlData.pathname;
      /*if (this.merchantURL.startsWith('www')) {
        this.merchantURL = this.merchantURL.replace('www.', '');
      }*/
    }

    if (this.merchant.reviews_url) {
      this.reviewsURL = this.merchant.reviews_url;
    }

    if (!this.merchant.covidOptions.instructions) {
      this.merchant.covidOptions.instructions = [
        'Elevated Sanitation + Heightened Frequency of Hand Washing & Glove Changes For Every Order',
        'Required Face Masks For Team Members & Vendors + Guests as required by state or local rules',
        'Hand Sanitization Stations',
        'Contact-Free & Cashless Payment + Cashier Shield as required by state or local rules',
        'Enforced Social Distancing ',
        'Enhanced Food Safety Protocols',
        'Daily Team Member Wellness Checks + Temperature Checks where required by state or local rules',
      ];
    }

    if (this.merchant.covidOptions.instructions) {
      this.textCovidInstructions = this.merchant.covidOptions.instructions
        .map((v, k) => '<li>' + v + '</li>')
        .join('');
    }

    if (this.merchant.covidOptions.extra_instructions) {
      this.textCovidInstructions +=
        '<br>' + this.merchant.covidOptions.extra_instructions;
    }

    this.merchant.email = this.merchant.email || this.user.email;

    this.isActive = this.merchant.isActive;

    this.brandService.brand.next(this.brandMapper.mapToModel(this.merchant.app));
  }

  private handleCategories(categories: Category[]): void {
    this.categories = categories.map((category) => ({
      id: category.id,
      text: category.pluralName,
    }));

    this.selectedCategoryIds = this.categories
      .filter((category) => this.merchant.categoryIds.includes(category.id))
      .map((category) => category.id);
  }

  private getMerchantLocation(): Place {
    const { city, state, zip, country } = this.merchant.address;

    if (country === 'Canada') {
      this.zipMask = this.canadaZipMask;
    }

    return this.allMerchantLocations.find(
      (place) =>
        place.city === city && place.state === state && place.zip === zip
    );
  }

  private handleMerchantImages(): Observable<Image[] | null> {
    if (this.galleryPreview && this.galleryPreview.images.length === 0) {
      return this.merchantService.getImages(this.merchant.id);
    }
    return of(null);
  }

  private addImagesToPreview(images: Image[]): void {
    if (images) {
      images.forEach((image) =>
        this.galleryPreview.addImageAsUrl(image.file, image.id)
      );
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onKeywordsChange(keywordsArray: string[]): void {
    this.merchant.keywords = keywordsArray.join();
    this.form.controls['keywords'].setErrors(null);
  }

  /**
   * Check user consent states with the Terms of Use.
   * @param value User consent states with the Terms of Use.
   */
  public onAgreeChange(value: boolean): void {
    this.isAgree = value;
  }

  public async saveAndFinish(event) {
    this.saveAndFinishInProcess = true;
    this.form.onSubmit(event);
  }

  /**
   * Saves background logo uploading result to a variable.
   * @param images Array of uploaded images.
   */
  public async bgLogoUploaded(images: LoadedImage[]): Promise<void> {
    const image = images[0].base64String;
    const dimen = await getImageDimensions(image);

    const b64Image = await this.presentLogoCropperPopup(image, dimen);
    if (b64Image) {
      this.merchant.logoBackground = b64Image;
      this.bgLogo = b64toBlob(b64Image);
    }
  }

  /**
   * Saves logo uploading result to a variable.
   * @param images Array of uploaded images.
   */
  public async logoUploaded(images: LoadedImage[]): Promise<void> {
    const image = images[0].base64String;
    const dimen = await getImageDimensions(image);

    const b64Image = await this.presentLogoCropperPopup(image, dimen);
    if (b64Image) {
      this.merchant.logo = b64Image;
      this.logo = b64toBlob(b64Image);
      const dimen = await getImageDimensions(b64Image);
      this.isLogoRect = dimen.w !== dimen.h;
    }
  }

  /**
   * Check that the "Hours of Operations" schedule is empty.
   */
  public isScheduleEmpty(): boolean {
    const { operationHours: schedule } = this.merchant;

    if (Object.keys(schedule).length === 0) {
      return true;
    }

    const emptyKeys = [];

    for (const key in schedule) {
      if (schedule.hasOwnProperty(key)) {
        if (
          schedule[key].start === undefined &&
          schedule[key].end === undefined
        ) {
          emptyKeys.push(key);
        }
      }
    }

    // If all fields in "Hours of Operations" is empty then return true.
    return emptyKeys.length === NUMBER_OF_OPERATION_HOURS_FIELDS;
  }

  public async onPasswordSubmit(form: NgForm): Promise<boolean> {
    if (form.valid) {
      const spinner = this.popupService.spinner();
      const response = await this.authService.changePassword(
        this.password,
        this.passwordRepeat
      );
      spinner.close();
      let valid = true;
      if (response instanceof ServerError) {
        this.handleServerError(response);
        valid = false;
      }
      return valid;
    }
  }

  /**
   * Handles on submit form event.
   * @param {NgForm} form Angular form.
   */
  public async onSubmit(form: NgForm): Promise<void> {
    let valid = true;
    if (this.password && this.passwordRepeat) {
      valid = await this.onPasswordSubmit(form);
    }

    if (form.valid && valid) {
      if (!this.merchant.address.street) {
        this.popupService
          .info_sticky('Street address is required.')
          .then(() => {
            scrollToNotValid();
          });
        return;
      }

      if (this.selectedCategoryIds.length === 0) {
        this.popupService
          .info_sticky('Please select a Business Type ')
          .then(() => {
            scrollToNotValid();
          });
        return;
      }

      const spinner = this.popupService.spinner();

      this.merchant.categoryIds = this.selectedCategoryIds;

      if (this.merchantURL) {
        this.merchantURL = this.merchantURL.toLowerCase();
      }
      this.merchant.url = this.merchantURL;

      this.merchant.reviews_url = this.reviewsURL;
      if (this.reviewsURL) {
        this.reviewsURL = this.reviewsURL.toLowerCase();
        this.merchant.reviews_url = this.reviewsURL;
      }

      if (this.merchant.covidOptions.url) {
        if (
          !this.merchant.covidOptions.url.startsWith('http://') &&
          !this.merchant.covidOptions.url.startsWith('https://')
        ) {
          this.merchant.covidOptions.url =
            'http://' + this.merchant.covidOptions.url;
        }
      }

      const response = await this.merchantService.updateProfile(this.merchant);
      if (response instanceof Merchant) {
        const place = {
          ...this.merchantLocation,
          contactPhone: this.merchant.phone,
          country_calling_code: this.merchant.country_calling_code,
          isDisplayed: true,
          provider: 'google',
          contactName: this.merchant.contact,
          contactEmail: this.merchant.email,
        };
        if (!this.merchant.creationCompleted) {
          if (this.placeId) {
            await this.placeService.addPlace(
              this.merchant.id,
              this.placeId,
              place
            );
          }
        }

        this.user.merchant = response;
        this.user.merchant.covidOptions = this.merchant.covidOptions;

        if (this.merchant.logo === 'removed') {
          await this.merchantService.deleteLogo(this.merchant.id);
        } else {
          await this.merchantService.uploadLogos(
            this.merchant.id,
            this.logo,
            this.bgLogo
          );
        }
        this.authService.onUserChanged.next(this.user);

        spinner.close();

        if (this.merchant.app.id !== 'gettinlocal') {
          this.startFreeCampaign();
        } else {
          this.showCampaignPublishSuccess();
        }
      } else if (response instanceof ServerError) {
        this.handleServerError(response);
        spinner.close();
      }
    } else {
      this.popupService
        .info_sticky(
          'Please complete all required fields or resolve all highlighted errors',
          'Your profile cannot be saved.'
        )
        .then(() => scrollToNotValid());
    }

    this.saveAndFinishInProcess = false;
  }

  public startFreeCampaign(): void {
    const freePlan = this.availablePlans.find(p => p.name === 'free');
    const premiumPlan = this.availablePlans.find(p => p.name === 'premium');
    const defaultPlan = this.availablePlans.find(p => p.id === this.brand.default_plan);

    this.dataService.avaiable_plans = this.availablePlans;
    this.dataService.campaign_plan.next(defaultPlan || freePlan);
    this.dataService.premium_state_count.next(0);
    this.dataService.premium_email_state_count.next(0);
    this.dataService.payment_required.next(true);
    this.dataService.custom_link_count.next(0);
    this.dataService.upgrade_fee.next(premiumPlan.subscription_rate);
    this.dataService.is_upgradable.next(false);

    this.router.navigate(['/campaign']);
  }

  private async showCampaignPublishSuccessForHotels(merchant) {
    const dialogRef = this.dialog.open(CampaignPublishSuccessHotelPopupComponent, {
      width: '750px',
      /**
       * global style for popup to remove paddings
       */
      panelClass: 'campaign-publish-success',
      disableClose: true,
      data: {
        title: 'Success',
        message: 'Your Profile has been saved!',
        incompleteItemsTitle: 'You can update / add information to your company profile at anytime.',
        onItemClick: (item: string) => {
          scrollIntoField(item);
        },
        incompleteList: getIncompleteProfileItemList(merchant)
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result === CampaignPublishPopupResult.FIRST) {
          this.router.navigateByUrl('/locations/view');
        }
        else if (result === CampaignPublishPopupResult.SECOND) {
          this.router.navigateByUrl('/create_campaign');
        }
        else if (result === CampaignPublishPopupResult.THIRD) {
          this.router.navigateByUrl('/new-message');
        } else if (result === CampaignPublishPopupResult.NO) {
          this.router.navigateByUrl('/dashboard');
        }
      })
      .catch((err) => err);
  }

  private async showCampaignPublishSuccess() {
    const merchant_obj = Object.assign(this.user.merchant, { logo: this.logo });
    const is_hotel = merchant_obj.isAutoHeartingEnabled();

    if (is_hotel) {
      return this.showCampaignPublishSuccessForHotels(merchant_obj);
    }

    const primaryActionText = 'Let\'s Do It';
    const subtitle = 'Would you like to start a new Campaign?';
    const primaryActionUrl = '/create_campaign';
    const secondaryActionText = 'Not Now';
    const dialogRef = this.dialog.open(CampaignPublishSuccessPopupComponent, {
      width: '500px',
      /**
       * global style for popup to remove paddings
       */
      panelClass: 'campaign-publish-success',
      disableClose: true,
      data: {
        title: 'Success',
        message: 'Your Profile has been saved!',
        subtitle: subtitle,
        buttonTitle: primaryActionText,
        secondaryButtonTitle: secondaryActionText,
        incompleteItemsTitle: 'You can update / add information to your company profile at anytime.',
        onItemClick: (item: string) => {
          scrollIntoField(item);
        },
        incompleteList: getIncompleteProfileItemList(merchant_obj)
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result === PopupResult.YES) {
          this.router.navigateByUrl(primaryActionUrl);
        } else if (result === PopupResult.NO) {
          this.router.navigateByUrl('/dashboard');
        }
      })
      .catch((err) => err);
  }

  public imageDragged(images: LoadedImage[]): void {
    this.logoImage = images[0].base64String;
    this.logoUploaded(images);
  }

  public bgImageDragged(images: LoadedImage[]): void {
    this.bgLogoImage = images[0].base64String;
    this.bgLogoUploaded(images);
  }

  /**
   * Handling server error by invalidating form.
   * @param serverError Server error object returned from remote.
   */
  private handleServerError(serverError: ServerError) {
    const map = {
      name_dba: 'dbaNameOfBusiness',
      description: 'aboutUs',
      keywords: 'keywords',
      files: 'merchantImages',
      free_form: 'freeForm',
      new_password2: 'password-repeat',
      new_password1: 'password',
    };
    this.errorHandlerService.invalidateForm(this.form, map, serverError);
  }

  private presentBgLogoCropperPopup(): Promise<any> {
    const dialogRef = this.dialog.open(CropperPopupComponent, {
      data: {
        image: this.bgLogoImage,
        size: 375,
        supportFit: true,
        keepAspect: false,
        height: 375,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  private presentLogoCropperPopup(
    image: string | ArrayBuffer,
    dimen: any
  ): Promise<any> {
    const canvasHeight = dimen.h > 320 ? 320 : dimen.h;
    const canvasWidth = dimen.w > 640 ? 640 : dimen.w;

    const dialogRef = this.dialog.open(CropperPopupComponent, {
      data: {
        image,
        size: this.isLogoRect ? 640 : 320,
        supportFit: true,
        keepAspect: false,
        height: 320,
        canvasHeight: canvasHeight,
        canvasWidth: canvasWidth,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  public async removeLogoClicked(): Promise<void> {
    // XXX: workaround for UI to Update
    this.merchant.logo = 'removed';
    this.logo = null;
  }

  public async handleLogoTypeChange(evt): Promise<void> {
    const target = evt.target;
    if (target.checked) {
      this.isLogoRect = target.value === 'rectangle';
    }
  }

  public async cancelClicked(event): Promise<void> {
    window.location.reload();
  }

  /**
   * Upload images and show it in the gallery.
   * @param images Loaded images.
   */
  public async addImages(images: LoadedImage[]): Promise<void> {
    this.isImageLoading = true;
    this.imagesErrorText = '';
    try {
      const uploadedImages = await this.merchantService.uploadImage(
        this.merchant.id,
        images
      );

      this.isImageLoading = false;

      for (const image of uploadedImages) {
        this.galleryPreview.addImageAsUrl(image.file, image.id);
      }
    } catch (error) {
      this.isImageLoading = false;
      this.imagesErrorText = 'Unsupported image type.';
    }
  }

  public async deleteImage(event: number): Promise<void> {
    this.imagesErrorText = '';
    await this.merchantService.deleteImage(event, this.merchant.id);
  }

  public covidOptionChange(type): void {
    this.merchant.covidOptions.type = type;
  }

  public toggleCovidOption(): void {
    this.merchant.covidOptions.enabled = !this.merchant.covidOptions.enabled;
  }

  public editCovidInstructions(event): void {
    event.preventDefault();
    const dialogRef = this.dialog.open(CovidOptionsSelectionPopupComponent, {
      width: '920px',
      panelClass: 'target-dialog',
      data: {
        options: Object.assign([], this.merchant.covidOptions.instructions),
        extra: this.merchant.covidOptions.extra_instructions,
      },
    });

    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.merchant.covidOptions.instructions = data.options;
          if (data.options) {
            this.textCovidInstructions = data.options
              .map((v, k) => '<li>' + v + '</li>')
              .join('');
          }
          this.merchant.covidOptions.extra_instructions = data.extra;
          if (data.extra) {
            this.textCovidInstructions += '<br>' + data.extra;
          }
        }
      });
  }

  /**
   * Changing state of merchant.
   */
  public async changeStatusOfMerchant(isActive: boolean): Promise<void> {
    let message = '';
    if (isActive) {
      message = 'Are you sure to restore your account?';
    } else if (!isActive) {
      message = 'Are you sure to suspend your account?';
    }

    const result = await this.popupService.confirm(
      message,
      'Changing state of account'
    );
    if (result === PopupResult.YES) {
      const spinner = this.popupService.spinner();
      await this.merchantService.changeStateOfMerchant(
        this.merchant.id,
        isActive
      );
      this.isActive = isActive;
      spinner.close();
    }
  }

  /**
   * Handling address input change.
   * @param event Event on input change.
   */
  public async handleAddressInputChange(id: string): Promise<void> {
    if (id) {
      this.placeId = id;
      const placeSearch = await this.googlePlaceProvider.getPlaceDetails(id);
      if (!placeSearch) {
        return;
      }

      placeSearch.id = id;
      const address = placeSearch.getAddressComponent('street_number');
      const street = placeSearch.getAddressComponent('route') || {
        longName: placeSearch.name
      };
      let text = '';
      if (address) {
        text += address.longName;
      }
      if (street) {
        if (text.length > 0) {
          text += ' ';
        }

        text += street.longName;
      }

      if (text.trim().length > 0) {
        this.merchant.address.street = text;
      }
      this.mapPlaceInfo(placeSearch);
    }
  }
  /**
   * Mapping of place search item.
   * @param placeSearch Object which will be mapped.
   */
  public mapPlaceInfo(placeSearch: PlaceSearch): void {
    const zip = placeSearch.getAddressComponent('postal_code');
    const city = placeSearch.getCity();
    const state = placeSearch.getAddressComponent(
      'administrative_area_level_1'
    );
    const state2 = placeSearch.getAddressComponent('administrative_area_level_2');
    const country = placeSearch.getAddressComponent('country');

    if (zip) {
      this.merchant.address.zip = zip.longName;
    } else {
      this.merchant.address.zip = '';
    }
    if (city) {
      this.merchant.address.city = city.longName;
    } else {
      this.merchant.address.city = '';
    }

    if (country) {
      this.merchant.address.country = country.longName;
      this.zipMask =
        country.longName === 'Canada' ? this.canadaZipMask : this.usZipMask;
    } else {
      this.merchant.address.country = 'USA';
      this.zipMask = this.usZipMask;
    }


    if (state) {
      this.merchant.address.state = state.shortName;
      /*if (country.longName === 'United States' || country.longName === 'Canada') {
        if (this.states.some(s => s.code === state.shortName)) {
          this.merchant.address.state = state.shortName;
        }
      } else if (state2) {
        this.merchant.address.state = state2.shortName;
      }*/
    } else if (state2) {
      this.merchant.address.state = state2.shortName;
    }

    if (!this.merchantLocation) {
      this.merchantLocation = new Place();
    }

    this.placeService.updatePlaceObject(
      placeSearch,
      this.merchantLocation,
      this.states
    );
  }

  /**
   * Show spinner.
   */
  private startSpinner(): void {
    this.spinner = this.popupService.spinner();
  }

  /**
   * Remove spinner.
   */
  private stopSpinner(): void {
    this.spinner.close();
  }

  public openInstruction(): void {
    window.open('/assets/downloads/google-reviews.pdf', '_blank');
  }

  public showTitle(): boolean {
    if (!this.brand) {
      return true;
    }
    return !this.merchant.creationCompleted && this.brand.showTitle();
  }

  public showKeywords(): boolean {
    if (!this.brand) {
      return true;
    }
    return this.brand.showKeywords();
  }

  public merchantCountrySelected(event): void  {

    this.merchantCountry = [event.val];
    this.merchantCountries = this.merchantCountries.map((c: any) => {
      c.checked = c.value === event.val;
      return c;
    });

    this.merchant.address = new Address();
    this.currentAddressBusiness = '';
    this.currentAddressStreet = '';

    const selected = this.merchantCountries.find(c => c.value === event.val);
    if (selected) {
      this.merchant.country_calling_code = selected.dial_code;
    }
  }

  public isEdit(): boolean {
    return false;
  }

  public getFlag(iso: any): string {
    return getFlagEmoji(iso);
  }

  private handleBrandCategories() {
    if (this.brand.categories.length > 0 && this.merchant.id != null) {
      this.brand.categories = this.brand.categories.map(categoryDto =>
        this.categoryMapper.mapToModel(categoryDto as any)
      );
      this.handleCategories(this.brand.categories);
    }
  }
}
