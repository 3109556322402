import { CreditCardInfo, CustomerInfo } from '../../../models/customer-info';
import { CustomerInfoDto } from '../dtos/customer-info.dto';
import { Injectable } from '@angular/core';
import { Mapper } from '../../abstract/mapper';


/**
 * Mapper for Card info.
 */
@Injectable()
export class CustomerInfoMappper implements Mapper<CustomerInfoDto, CustomerInfo> {


    /**
     * @inheritdoc
     */
    public mapToModel(dto: CustomerInfoDto): CustomerInfo {
        const model = {
            id: dto.id,
            braintreeId: dto.braintree_id,
            created: dto.created,
            firstName: dto.first_name,
            lastName: dto.last_name,
            modified: dto.modified
        } as CustomerInfo;

        model.creditCards = dto.credit_cards.map(creditCard => {
            const creditCardInfo = new CreditCardInfo();
            creditCardInfo.id = creditCard.id;
            creditCardInfo.braintreeId = creditCard.braintree_id;
            creditCardInfo.cardholderName = creditCard.cardholder_name;
            creditCardInfo.cardType = creditCard.card_type;
            creditCardInfo.created = creditCard.created;
            creditCardInfo.customer = creditCard.customer;
            creditCardInfo.default = creditCard.default;
            creditCardInfo.expirationDate = creditCard.expiration_date;
            creditCardInfo.last4 = creditCard.last_4;
            creditCardInfo.expirationDate = creditCard.expiration_date;

            if (creditCard.billing_address) {
                creditCardInfo.billingAddress = {
                    countryName: creditCard.billing_address.country_name,
                    locality: creditCard.billing_address.locality,
                    postalCode: creditCard.billing_address.postal_code,
                    region: creditCard.billing_address.region,
                    streetAddress: creditCard.billing_address.street_address
                };
            }

            return creditCardInfo;
        });

        return model;
    }
}
