import { ServerErrorMapper } from '../error-handler/mappers/server-error.mapper';
import { CouponDto } from './dtos/coupon.dto';
import { CouponMapper } from './mappers/coupon.mapper';
import { MerchantsCoupon } from './models/merchants-coupon';
import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ServerError } from '../../models/server-error';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

import { Observable } from 'rxjs/Rx';
import { ServerErrorDto } from '../error-handler/dtos/server-error.dto';
import { TokenStorageService } from '../token-storage.service';
import { AuthService } from '../auth/auth.service';
import { TimeInclude } from '../time-inclusion.enum';

const MERCHANT_URL_PART = 'merchants';
const COUPONS_URL_PART = 'coupons';
const EVENTS_URL_PART = 'events';
const STATS_URL_PART = 'stats';

/**
 * Service for Merchant's coupons.
 */
@Injectable()
export class CouponsService {
  /**
   * .ctor
   */
  constructor(
    private http: Http,
    private tokenService: TokenStorageService,
    private userService: AuthService,
    private couponMapper: CouponMapper,
    private serverErrorMapper: ServerErrorMapper,
  ) {}

  /**
   * Returns collection of filtered Merchant's coupons.
   * @param merchantId Id of merchant, may be null for retrieve using AuthService with request to remote endpoint.
   */
  public getCoupons(
    startDate: Date,
    endDate: Date,
    merchantId: number,
    locationIds: string[] = null,
    includeTime: boolean = false,
    timeInclude: TimeInclude = TimeInclude.None,
  ): Promise<MerchantsCoupon[]> {
    let startDateString = moment(startDate).format('YYYY-MM-DD');
    let endDateString = moment(endDate).format('YYYY-MM-DD');

    if (includeTime) {
      if (timeInclude === TimeInclude.Start) {
        startDateString = moment(startDate).format('YYYY-MM-DD hh:mm:ss');
      } else if (timeInclude === TimeInclude.End) {
        endDateString = moment(endDate).format('YYYY-MM-DD hh:mm:ss');
      }
    }

    // tslint:disable-next-line:max-line-length
    let queryUrl = `${
      environment.apiEndpoint
    }/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/?start_lte=${endDateString}&end_gte=${startDateString}`;
    if (locationIds && locationIds.length > 0) {
      // when we adding in js arrays, here is automatically adding ',' symbol after first and next elements in arrays.
      queryUrl += '&locations=' + locationIds;
    }

    return this.http
      .get(queryUrl, { headers: this.tokenService.baseHeaders })
      .map(r => r.json() as CouponDto[])
      .map(r => r.map(i => this.couponMapper.mapToModel(i)))
      .toPromise();
  }

  /**
   * Returns reedemed coupons count.
   * @param startDate Start date to filter.
   * @param endDate End date to filter.
   * @param merchantId Id of merchant or null.
   */
  public getRedeemed(
    startDate: Date,
    endDate: Date,
    merchantId: number,
    timeInclude: TimeInclude = TimeInclude.None,
  ): Promise<any> {
    const startDateString = moment(startDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
    let startDatePlus = moment(startDate)
      .add(1, 'day')
      .format('YYYY-MM-DD HH:mm:ss');
    const endDateString = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

    let queryString = '';
    if (timeInclude === TimeInclude.None) {
      queryString = `${
        environment.apiEndpoint
      }/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${STATS_URL_PART}/?start_lte=${endDateString}&end_gte=${startDateString}`;
    } else if (timeInclude === TimeInclude.End) {
      startDatePlus = moment(startDate)
        .add(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss');
      queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${STATS_URL_PART}` +
        `/?end_gte=${startDateString}&end_lte=${startDatePlus}&start_lte=${startDateString}`;
    } else if (timeInclude === TimeInclude.Start) {
      queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${STATS_URL_PART}` +
        `/?start_gte=${startDateString}&start_lte=${startDatePlus}&end_gte=${startDateString}`;
    }

    return this.http
      .get(queryString, { headers: this.tokenService.baseHeaders })
      .map((v): number => v.json())
      .toPromise();
  }

  /**
   * Patching coupon.
   * @param coupon Coupon which will be patched.
   * @param merchantId Id of merchant which belongs to coupon.
   */
  public patchCoupon(
    coupon: MerchantsCoupon,
    merchantId: number,
  ): Promise<MerchantsCoupon | ServerError> {
    const dto = this.couponMapper.mapToDto(coupon);

    return this.http
      .patch(
        `${
          environment.apiEndpoint
        }/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${dto.id}/`,
        dto,
        { headers: this.tokenService.baseHeaders },
      )
      .map(v => v.json() as CouponDto)
      .map(couponDto => this.couponMapper.mapToModel(couponDto))
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  public postCoupon(
    coupon: MerchantsCoupon,
    merchantId: number,
  ): Promise<MerchantsCoupon | ServerError> {
    const dto = this.couponMapper.mapToDto(coupon);
    // tslint:disable-next-line:max-line-length
    return this.http
      .post(
        `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${
          dto.merchantId
        }/${COUPONS_URL_PART}/`,
        dto,
        { headers: this.tokenService.baseHeaders },
      )
      .map(v => v.json() as CouponDto)
      .map(couponDto => this.couponMapper.mapToModel(couponDto))
      .catch(error => {
        const errorDto = error.json() as ServerErrorDto;
        return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
      })
      .toPromise();
  }

  /**
   * Uploading logo to server.
   * @param logo Logo for campaign.
   * @param merchantId Id of merchant for campaign.
   * @param couponId Id of campaign for update logo.
   */
  public updateImages(
    logo: Blob,
    image: Blob,
    merchantId: number,
    couponId: number,
  ): Promise<{ logo: string }> {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo);
    }
    if (image) {
      formData.append('image', image);
    }

    return this.http
      .post(
        `${
          environment.apiEndpoint
        }/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${couponId}/upload/`,
        formData,
        { headers: this.tokenService.baseHeaders },
      )
      .map(r => r.json() as { logo: string })
      .toPromise();
  }

  /**
   * Changing image for coupon to category's image.
   * @param merchantId Id of merchant for coupon.
   * @param couponId Id of coupon to update.
   * @param categoryId Id of category for use his image.
   */
  public useCategoryImage(
    merchantId: number,
    couponId: number,
    categoryId: string,
  ): Promise<void> {
    return this.http
      .post(
        `${
          environment.apiEndpoint
        }/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${couponId}/use_category/`,
        { category_id: categoryId },
        { headers: this.tokenService.baseHeaders },
      )
      .map(r => null)
      .toPromise();
  }

  /**
   * Getting coupon object from api.
   * @param merchantId Id of merchant.
   * @param couponId Id of coupon to get.
   */
  public getCoupon(merchantId: number, couponId: number): Promise<MerchantsCoupon> {
    return this.http
      .get(
        `${
          environment.apiEndpoint
        }/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${couponId}/`,
        { headers: this.tokenService.baseHeaders },
      )
      .map(r => r.json() as CouponDto)
      .map(r => this.couponMapper.mapToModel(r))
      .toPromise();
  }

  /**
   * Ending coupon by sending patch with back time.
   * @param merchantId Id of merchant.
   * @param couponId Id of coupon.
   * @param date date for end of coupon.
   */
  public endCoupon(
    merchantId: number,
    couponId: number,
    date: string,
    coupons: { in_store: string; online: string },
  ) {
    const object = {
      end: date,
    };

    Object.assign(object, coupons);

    return this.http
      .post(
        `${
          environment.apiEndpoint
        }/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${couponId}/end/`,
        object,
        { headers: this.tokenService.baseHeaders },
      )
      .map(v => null)
      .toPromise();
  }

  /**
   * Ending coupon by sending patch with back time.
   * @param merchantId Id of merchant.
   * @param couponId Id of coupon.
   * @param date date for end of coupon.
   */
  public endEvent(
    merchantId: number,
    couponId: number,
    date: string,
    coupons: { in_store: string; online: string },
  ) {
    const object = {
      end: date,
    };

    Object.assign(object, coupons);

    return this.http
      .post(
        `${
          environment.apiEndpoint
        }/${MERCHANT_URL_PART}/${merchantId}/${EVENTS_URL_PART}/${couponId}/end/`,
        object,
        { headers: this.tokenService.baseHeaders },
      )
      .map(v => null)
      .toPromise();
  }

  /**
   * Deleting image for coupons.
   * @param merchantId Id of merchant.
   * @param couponId Id of coupon.
   * @param logo Flag for delete logo.
   * @param image Flag for delete image.
   */
  public deleteImages(
    merchantId: number,
    couponId: number,
    logo: boolean,
    image: boolean,
  ): Promise<void> {
    return this.http
      .delete(
        `${
          environment.apiEndpoint
        }/${MERCHANT_URL_PART}/${merchantId}/${COUPONS_URL_PART}/${couponId}/delete_images/`,
        {
          headers: this.tokenService.baseHeaders,
          body: {
            logo: logo,
            image: image,
          },
        },
      )
      .map(v => null)
      .toPromise();
  }
}
