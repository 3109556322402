import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../../value-accessor-base';
import { LoadedImage } from '../../../core';

/**
 * Custom file load component with the file input.
 */
@Component({
  selector: 'pb-browse-video-file',
  templateUrl: './browse-video-file.component.html',
  styleUrls: ['./browse-video-file.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: BrowseVideoFileComponent, multi: true }],
})
export class BrowseVideoFileComponent extends ValueAccessorBase<string> {
  /**
   * Mark button to disable.
   */
  @Input()
  public isDisabled?: boolean;
  /**
   * Appearance as a link or button.
   */
  @Input()
  public isLink = false;

  /**
   * Text link or button.
   */
  @Input()
  public buttonText = 'Browse';

  /**
   * Width of the button works if isLink = false.
   */
  @Input()
  public buttonWidth = '128px';
  /**
   * Accept type in input with type equal file.
   */
  @Input()
  public inputAcceptType = 'video/mp4,video/quicktime';
  /**
   * Regex for files that are emits in uploader.
   */
  @Input()
  public formatTypeRegex = /^video/gi;

  @Output()
  public removed = new EventEmitter<File>();

  @Input()
  public notes: string = '';

  @Input()
  public canRemove = false;
  /**
   * Emit files uploaded by the user.
   */
  @Output()
  public uploaded = new EventEmitter<File>();

  /**
   * Clear the value in input so that even when the same image is selected, onchange is called.
   * @input Input from which onchange is called.
   */
  public clearValue(input: HTMLInputElement): void {
    input.value = '';
  }

  public async handleRemove($event): Promise<void> {
    return this.removed.emit();
  }

  /**
   * When you change the input file, it reads the files and uploaded emits it.
   * @param files files uploaded by the user.
   */
  public async handleUpload(files: FileList): Promise<void> {
    const acceptsFiles = Array.from(files).filter(file => file.type.search(this.formatTypeRegex) !== -1);
    if (acceptsFiles.length === 0) {
      return;
    }

    this.uploaded.emit(acceptsFiles[0]);
  }
}
