<pb-breadcrumbs [items]="['Automated Message ', 'Push Alert']"></pb-breadcrumbs>

<div class="mt-50"></div>

<form #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted">
  <div class="section-header">
    <div class="header">
      <div class="header-text">Push Alert - New Followers</div>
    </div>
    <div right>
      <div class="row">
        <button *ngIf="message.id" (click)="stopAlert(message.id)" pb-button class="button" small type="button">
          {{ message.is_enabled ? 'Stop Message' : 'Start Message' }}
        </button>
        <button pb-button class="button" small type="submit">Save</button>
      </div>
    </div>
  </div>

  <div class="row mt-20">
    <span class="noteb">Guests that install the GettinLocal app while on your hotel property will receive a
      custom Automated Push Alert. The alert will be delivered based on the time-frame that you select (ranging from 5
      Minutes to 72 Hours) from when the guest installed the app.</span>
  </div>

  <div class="row mt-10">
    <div class="col">
      <pb-input [maxlength]="100" label="Subject*" name="title" required [(ngModel)]="message.title" pbTooltip customElementQuery=".body">
      </pb-input>
      <span class="note" style="margin-left: 0px;">Example: Welcome to “Hotel Name”</span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <pb-textarea label="Message*" name="message" big required [(ngModel)]="message.message" class="textarea" pbTooltip
        customElementQuery=".body"></pb-textarea>
      <span class="note" style="margin-left: 0px;">Example: Thank you for visiting our “Hotel Name”, get 25% OFF at our
        Spa or
        Visit our Restaurant with the GettinLocal App.</span>
    </div>
  </div>

  <div class="row">
    <div style="margin-top: 0px; margin-top: 30px;">
      <div class="tooltip-title">
        <div class="global-caption">Upload Image or Video</div>
      </div>
      <span *ngIf="mediaType === 'image'" class="note"><b>Image files supported – JPEG, PNG</b></span>
      <span *ngIf="mediaType === 'video'" class="note"><b>Video files supported – MP4/MOV (15-30 seconds preferred /
            60
            seconds
            max)</b></span>
    </div>
  </div>

  <div class="global-subtitle">&nbsp;</div>
  <pb-toggle-selector [options]="mediaTypeSelector" [selected]="mediaType" (onSelect)="changeMediaType($event)">
  </pb-toggle-selector>
  <div class="row">
    <div class="basic-left">
      <div class="coupon-image">
        <br>
        <div *ngIf="mediaType === 'video'" class="browse-custom-image">
          <pb-browse-video-file [buttonWidth]="'240px'" [buttonText]="'Upload Video'"
                                name="video" [(ngModel)]="videoFile" (removed)="removeVideoClicked()"
                                (uploaded)="videoUploaded($event)"
                                [notes]="videoUploadInstructions">
          </pb-browse-video-file>
          <br/>
          <video [ngClass]="(!videoFile)?'hidden':'video-full'" style="width: 300px" controls></video>
        </div>
        <div *ngIf="mediaType === 'image'" class="browse-custom-image">
          <pb-browse-file [buttonWidth]="'240px'" [buttonText]="'Upload Image'"
                          name="logo" [(ngModel)]="imageFile" (uploaded)="logoUploaded($event)"
                          (removed)="removeLogoClicked()"
                          [notes]="imageUploadInstructions">
          </pb-browse-file>
          <div [ngClass]="(!imageFile)?'hidden':''" style="margin-top: 10px; width:100%;">
            <div class="image-logo-full" style.background-image="url({{imageFile}})">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-10">
    <div class="col-50">
      <div>
        <div class="header">
          <label
            style="padding-left: 1px; font-size: 14px; line-height: 19px; font-weight: bold; color: #231f20; white-space: nowrap;"
            for="acton_url">Call To Action</label>
          <div class="note"></div>
        </div>
        <div class="body">
          <input autocomplete [defaultValue]="message.actionText" [(ngModel)]="message.actionText" name="action_text"
            placeholder="Example: Sign up for Hotel Rewards and Loyalty Program" [config]="autoCompleteConfig"
            [items]="pushAlertSamples" (inputChangedEvent)="onInputChangedEvent($event)" (selectEvent)="onSelect($event)" />
        </div>
      </div>
    
      <span class="note" style="margin-left: 0px;">Enter Your Call to Action or choose from the list</span>
    </div>
    
    <div class="col-50">
      <pb-input *ngIf="!message.isConcierge" label="Call To Action - URL" style="width: 48%;" name="acton_url" [(ngModel)]="message.actionURL"
      [pattern]="websiteUrlMask" pbTooltip customElementQuery=".body">
      </pb-input>
      <span *ngIf="!message.isConcierge" class="note" style="margin-left: 0px;">Example: https://gettinlocal.com/sign-up-example</span>
      <div class="row">
        <pb-input *ngIf="message.isConcierge" style="width: 48%;" name="acton_phone" type="tel" label="Phone" [(ngModel)]="message.actionPhone" placeholder="Phone"
          class="element" [mask]="{mask: phoneMask, guide: true, placeholderChar: '\u2000'}"
          customElementQuery=".body"></pb-input>
        
        <pb-input *ngIf="message.isConcierge" name="acton_email" [(ngModel)]="message.actionEmail" style="width: 48%;" label="Email" placeholder="Email"
          class="element" [pattern]="emailMask" pbTooltip customElementQuery=".body"></pb-input>
        <span *ngIf="message.isConcierge" class="note" style="margin-left: 0px;">Enter Phone or Email or both</span>
      </div>
    </div>
  </div>

  <div class="row mt-10">
    <div class="col-50">
      <pb-select label="Automatic Send Timer*:" placeholder="Please select" name="state" required class="element states"
        [hasBigArrow]="true" [(ngModel)]="message.auto_time_delta" (change)="stateChanged($event)" required>
        <option [value]="timer.value" *ngFor="let timer of send_timers"
          [selected]="timer.key === message.auto_time_delta">
          {{timer.key}}</option>
      </pb-select>
      <span class="note" style="margin-left: 0px;">Choose a delivery time parameter : 5 Minutes up to 72 Hours</span>
    </div>
  </div>

  <br>
  <div class="section-header">
    <div class="header">
      <div class="header-text"></div>
    </div>
    <div right>
      <div class="row">
        <button *ngIf="message.id" (click)="stopAlert(message.id)" pb-button class="button" small type="button">
          {{ message.is_enabled ? 'Stop Message' : 'Start Message' }}
        </button>
        <button pb-button class="button" small type="submit">Save</button>
      </div>
    </div>
  </div>
</form>

<div class="mb-50"></div>
<div style="height: 100px"></div>