import { ValidationError, Subscription } from "../../core";
// import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

/**
 * Redemption options domain model.
 */
export class RedemptionOptionsVm {
  public isRedeemable = false;
  /**
   * Campaign feature.
   */
  public feature: "coupon" | "promotion" = "coupon";
  /**
   * Type coupon.
   */
  public couponType: "online" | "in-store" | "both" = "in-store";
  public inStoreCouponType: "text" | "qrcode" | "barcode" | "none" = "none";

  public featureOptions = [
    { value: 1, text: "Let's Do It", checked: true, class: 'green', font: 20 },
    { value: 0, text: "Not Now", checked: false, class: 'black', font: 12 },
  ];
  /**
   * Type limit the number of coupons.
   */
  public storeCouponLimit: "unlimited" | "custom" = "unlimited";
  /**
   * Number of store coupons to use.
   */
  public storeCouponLimitCount = 1;
  /**
   * Code to use store coupon.
   */
  public storeCouponCode: string;
  /**
   * QRCode image.
   */
  public inStoreImage = { base64: null, blob: null, name: null };
  public inStoreImageRemoved = false;
  /**
   * Code to use online coupon.
   */
  public onlineCouponCode: string;
  /**
   * Instructions for in-store coupon.
   */
  public storeCouponInstructions: string;
  private readonly initialStoreCouponInstructions = "Present Code";
  public premiumEmailStates: string[] = [];

  // public dealDescription: string;
  private readonly initialDealDescription = "";
  /**
   * Instructions online coupon.
   */
  public onlineCouponInstructions: string;
  private readonly initialOnlineCouponInstructions =
    "Tap Code to Copy & Paste in Shopping Cart Checkout";
  /**
   * The text of terms of the coupon or promotion.
   */
  public termsAndConditionsText: string;

  // public optionsModel: number[];
  // public myOptions: IMultiSelectOption[];

  public shopOptionsList = [
    { url_type: "shop_now", title: "Shop Now", pad: 15 },
    { url_type: "order_now", title: "Order Now", pad: 15 },
    { url_type: "reserve_now", title: "Reserve Now", pad: 13 },
    { url_type: "book_now", title: "Book Now", pad: 15 },
    { url_type: "use_in_store", title: "Learn More", pad: 15 },
    { url_type: "get_tickets", title: "Get Tickets", pad: 16 },
  ];

  public enabledShopOptions = {
    shop_now: false,
    order_now: false,
    book_now: false,
    reserve_now: false,
    use_in_store: false,
    get_tickets: false,
  };

  private readonly initialselectedShopOptions = <any>{};
  public selectedShopOptions = {
    shop_now: "",
    order_now: "",
    book_now: "",
    reserve_now: "",
    use_in_store: "",
    get_tickets: "",
  };

  public shopOptions: { url_type: string; url: string; title: string }[] = [
    { url_type: "", title: "", url: "" },
    { url_type: "", title: "", url: "" },
  ];

  /**
   * Array of component form errors.
   */
  public errors: ValidationError[] = [];

  /**
   * Fields for validation.
   */
  public validationFields = [
    "storeCouponCode",
    "storeCouponInstructions",
    "contactInformationPhone",
    "contactInformationText",
    "contactInformationEmail",
    "shopOptionUrls",
    "selectedShopOptions",
    "dealDescription",
    "onlineCouponCode",
    "onlineCouponInstructions",
    "termsAndConditionsText",
  ];

  /**
   * Init some fields campaign feature to their initial values.
   */
  constructor() {
    this.onlineCouponInstructions = this.initialOnlineCouponInstructions;
    this.storeCouponInstructions = this.initialStoreCouponInstructions;
    // this.contactInformationEmail = this.initialContactInformationEmail;

    // this.dealDescription = this.initialDealDescription;
    this.selectedShopOptions = this.initialselectedShopOptions;

    // this.myOptions = [
    //   { id: 1, name: 'Option 1' },
    //   { id: 2, name: 'Option 2' },
    // ];
  }

  /**
   * Reset online and store coupon instructions to initial value.
   */
  public resetCouponInstructions(): void {
    this.onlineCouponInstructions = this.initialOnlineCouponInstructions;
    this.storeCouponInstructions = this.initialStoreCouponInstructions;

    // this.dealDescription = this.initialDealDescription;
  }

  public toggleDropdown(): void {}
}
