import { Injectable } from "@angular/core";
import { PromoCode } from "../../../core/models/promo-code";
import { PromoCodeDto } from "./dto/promo-code.dto";
import { Mapper } from "../../../core/services/abstract/mapper";

/**
 * Place mapper.
 */
@Injectable()
export class PromoCodeMapper {
  /**
   * @inheritdoc
   * @param {PlaceDto} dto Place DTO.
   */
  public mapToModel(dto: PromoCodeDto): PromoCode {
    return new PromoCode({
      id: dto.id,
      code: dto.code,
      agent: dto.agent,
      description: dto.description,
      is_active: dto.is_active,
      discount: dto.discount,
      commission: dto.commission,
    });
  }
}
