<div [ngClass]="classList">
    <input
      type="text"
      placeholder="{{ placeholder }}"
      (blur)="showAutoComplete = false"
      (focus)="showAutoComplete = true"
      [value]="value"
      (keyup)="enterText($event)"
    />
    <ul *ngIf="showAutoComplete && candidates && candidates.length > 0">
      <li
        *ngFor="let candidate of candidates; let idx = index"
        [ngClass]="{ active: idx === selectedIndex }"
        (keyup)="onKeyUpEvent($event, idx)"
        (mouseover)="selectedIndex = idx"
        (mousedown)="onSelect(idx)"
      >
        {{ candidatesLabels[idx] }}
      </li>
    </ul>
  </div>