import {Component, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../../value-accessor-base';

/**
 * Custom textarea component.
 */
@Component({
  selector: 'pb-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TextareaComponent, multi: true }]
})
export class TextareaComponent extends ValueAccessorBase<string> implements OnInit {

  @Input()
  public maxlength: string;

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public rows = '3';

  @Input()
  public placeholder = '';

  @Input()
  public isBig = false;

  @Input()
  public note = '';

  @Input()
  public disabled = false;

  @Input()
  public readonly = false;
  
  @Input()
  public defaultText: string;

  public enableDefaultText() {
    this.value = this.defaultText;
  }

  public ngOnInit() {
    if (this.defaultText) {
      this.value = this.defaultText;
    }
  }

}
