<header pb-page-header>
  <div left>
    Login information
  </div>
</header>
<form #passwordForm="ngForm" (ngSubmit)="onPasswordSubmit(passwordForm)" [class.form-submitted]="passwordForm.submitted">
  <section pb-section caption="Edit password">
    <div body>
      <div class="row">
        <pb-input type="password" name="password" #passwordRef="ngModel" [(ngModel)]="password" label="New Password" placeholder="Enter new password" class="element"
                  required pbTooltip customElementQuery=".body"></pb-input>
      </div>
      <div class="row">
        <pb-input type="password" name="password-repeat" [equal]="passwordRef.value" [(ngModel)]="passwordRepeat" label="New Password Repeat" placeholder="Repeat new password" class="element"
                  required pbTooltip customElementQuery=".body"></pb-input>
      </div>
      <div class="row buttons">
        <button pb-button type="submit" class="button save-button">Save</button>
      </div>
    </div>
  </section>
</form>

<form #usernameForm="ngForm" (ngSubmit)="onUsernameSubmit(emailForm)" [class.form-submitted]="usernameForm.submitted">
  <section pb-section caption="Edit User Name - First / Last">
    <div body>
      <div class="row">
        <pb-input type="text" name="first-name" [(ngModel)]="firstName" label="First Name" placeholder="Enter First Name" class="element"
                  required pbTooltip customElementQuery=".body"></pb-input>
      </div>
      <div class="row">
        <pb-input type="text" name="last-name" [(ngModel)]="lastName" label="Last Name" placeholder="Enter Last Name" class="element"
                  required pbTooltip customElementQuery=".body"></pb-input>
      </div>
      <div class="row buttons">
        <button pb-button type="submit" class="button save-button">Save</button>
      </div>
    </div>
  </section>
</form>


<form #emailForm="ngForm" (ngSubmit)="onEmailSubmit(emailForm)" [class.form-submitted]="emailForm.submitted">
  <section pb-section caption="Edit email">
    <div body>
      <div class="row">
        <pb-input type="email" name="email" [(ngModel)]="newEmail" label="New Email" placeholder="Enter new email" class="element"
                  required pbTooltip customElementQuery=".body"></pb-input>
      </div>
      <div class="row buttons">
        <button pb-button type="submit" class="button save-button">Send email change link</button>
      </div>
    </div>
  </section>
</form>
