/**
 * Address domain model.
 */
import {Category} from './category';
import {Customization} from './customization';
import {Theme} from './theme';
import {Location} from "./location";

export class WebApp {
  public id: string;
  public name: string;
  public enable_data_popup_edit: boolean;
  public enable_loyalty_popup_edit: boolean;
  public enable_scan_reports: boolean;
  public default_location: Location;
  public enable_priority_ads: boolean;
  public favicon: string;

  /**
   * .ctor
   * @param {any} initObject Initial object of predefined field values.
   */
  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }
}
