import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared';
import { RegistrationBrandsComponent } from './registration-brands.component';
import {LoginPageModule} from '../login-page/login-page.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    LoginPageModule
  ],
  declarations: [
    RegistrationBrandsComponent
  ],
  exports: [
    RegistrationBrandsComponent
  ]
})
export class RegistrationBrandsModule { }
