import { CropperPopupComponent } from '../shared';
import { NgModule } from '@angular/core';

import { CompanyProfilePageComponent } from './company-profile-page.component';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { AppRoutingModule } from '../app-routing.module';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { OperationHoursValidatorDirective } from './operation-hours-validator.directive';
import { CategorySelectionPopupComponent } from './category-selection/category-selection-popup.component';
import { CovidOptionsSelectionPopupComponent, KeysPipe } from './covid-options-picker/covid-options-picker.component';

@NgModule({
  imports: [
    SharedModule,
    AppRoutingModule
  ],
  declarations: [
    KeysPipe,
    CompanyProfilePageComponent,
    ColorPickerComponent,
    ImagePreviewComponent,
    OperationHoursValidatorDirective,
    CategorySelectionPopupComponent,
    CovidOptionsSelectionPopupComponent
  ],
  entryComponents: [
    CropperPopupComponent,
    CategorySelectionPopupComponent,
    CovidOptionsSelectionPopupComponent
  ],
  exports: [
    CompanyProfilePageComponent
  ]
})
export class CompanyProfilePageModule { }
