
/**
 * Used for service to determine
 * for which part of sort include time.
 */
export enum TimeInclude {
    None = 0,
    Start = 1,
    End = 2
}
