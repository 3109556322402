import * as moment from 'moment';
import { PROFANITY_LIST } from './constants';
import { ValidationError } from '../app/core';

/**
 * Create a copy of the source object in memory.
 * @param object Source object.
 */
export function deepCopy(object: any): any {
  return JSON.parse(JSON.stringify(object));
}

export function defaultRadiusesInMile(): { feets: number, miles: number, kms: number, pad: number }[] {
  return [
    { feets: 300.00, miles: 0.057, kms: 0.09, pad: 9 },
    { feets: 800.00, miles: 0.1516, kms: 0.24, pad: 9 },
    { feets: 2640.00, miles: 0.5, kms: 0.8, pad: 9 },
    { feets: 5280.00, miles: 1, kms: 1.6, pad: 11 },
    { feets: 15840.00, miles: 3, kms: 4.8, pad: 10 },
    { feets: 26400.00, miles: 5, kms: 8.0, pad: 10 },
    { feets: 52800.00, miles: 10, kms: 16, pad: 9 },
    { feets: 79200.00, miles: 15, kms: 24, pad: 9 },
    { feets: 105600.00, miles: 20, kms: 32.1, pad: 9 },
    { feets: 132000.00, miles: 25, kms: 40.2, pad: 9 },
    { feets: 264000.00, miles: 50, kms: 80.5, pad: 9 },
    { feets: 528000.00, miles: 100, kms: 160.1, pad: 9 },
    { feets: 792000.00, miles: 150, kms: 241.4, pad: 9 },
    { feets: 1056000.00, miles: 200, kms: 321.8, pad: 9 },
  ];
}

export function defaultRadiusesInMileForEvents(): { feets: number, miles: number, kms: number, pad: number }[] {
  return [
    { feets: 300.00, miles: 0.057, kms: 0.09, pad: 9 },
    { feets: 800.00, miles: 0.1516, kms: 0.24, pad: 9 },
    { feets: 2640.00, miles: 0.5, kms: 0.8, pad: 9 },
    { feets: 5280.00, miles: 1, kms: 1.6, pad: 11 },
    { feets: 15840.00, miles: 3, kms: 4.8, pad: 10 },
    { feets: 26400.00, miles: 5, kms: 8.0, pad: 10 },
    { feets: 52800.00, miles: 10, kms: 16, pad: 9 },
    { feets: 79200.00, miles: 15, kms: 24, pad: 9 },
    { feets: 105600.00, miles: 20, kms: 32.1, pad: 9 },
    { feets: 132000.00, miles: 25, kms: 40.2, pad: 9 },
    { feets: 264000.00, miles: 50, kms: 80.5, pad: 9 },
    { feets: 528000.00, miles: 100, kms: 160.1, pad: 9 },
  ];
}

/* variable name is still called 'miles' */
export function defaultRadiusesInKiloMeter(): { feets: number, miles: number }[] {
  return [
    { feets: 300.00, miles: 0.057 },
    { feets: 800.00, miles: 0.1516 },
    { feets: 1640.00, miles: 0.5 },
    { feets: 3280.00, miles: 1 },
    { feets: 9840.00, miles: 3 },
    { feets: 16400.00, miles: 5 },
    { feets: 32800.00, miles: 10 },
    { feets: 49200.00, miles: 15 },
    { feets: 65600.00, miles: 20 },
    { feets: 82000.00, miles: 25 },
    { feets: 164000.00, miles: 50 },
    { feets: 328084.00, miles: 100 },
    { feets: 492126.00, miles: 150 },
    { feets: 656168.00, miles: 200 },
  ];
}

/**
 * Function which return regex without support of country code.
 */
export function nonCodedPhoneMask(): (string | RegExp)[] {
  return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
}

export const NON_CODED_PHONE_PATTERN = /\(\d{3}\)\ \d{3}\-\d{4}/;

/**
 * Regex for http(s) startin uri's.
 */
export function httpsOrHttpsUriRegex(): string {
  return `^http(s)?(www\.)?\:\/\/.+`;
}

export function httpUrlRegex(): string {
  // tslint:disable-next-line:max-line-length
  return `^(https?|ftp):\\/\\/(((([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:)*@)?(((\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5]))|((([a-z]|\\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\\.?)(:\\d*)?)(\\/((([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)+(\\/(([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)*)*)?)?(\\?((([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)|[\uE000-\uF8FF]|\\/|\\?)*)?(\\#((([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)|\\/|\\?)*)?$`;
}

export function twitterUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)twitter.com\/[^\\s]+`;
}

export function facebookUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)facebook.com\/[^\\s]+`;
}

export function instagramUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)instagram.com\/[^\\s]+`;
}

export function linkedinUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)linkedin.com\/[^\\s]+`;
}

export function tiktokUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)tiktok.com\/[^\\s]+`;
}

export function pininterestUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)pinterest.com\/[^\\s]+`;
}

export function snapchatUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)snapchat.com\/[^\\s]+`;
}

export function flickrUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)flickr.com\/[^\\s]+`;
}

export function tumblrUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)tumblr.com\/[^\\s]+`;
}

export function meetupUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)meetup.com\/[^\\s]+`;
}

export function evenbrightUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)evenbright.com\/[^\\s]+`;
}

export function youtubeUrlRegex(): string {
  return `^(https?:\/\/)?((w{3}\.)?)youtube.com\/[^\\s]+`;
}

export function websiteUrlRegex(): string {
  // return `^[A-z0-9\-]*[\\.]+[\\S]+`;
  return `^(https?:\/\/)?((w{3}\.)?)[A-z0-9\-]*[\\.]+[\\S]+`;
}

export function emailRegex(): string {
  // tslint:disable-next-line:max-line-length
  return `^[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$`;
}

export function codedPhoneMask(): (string | RegExp)[] {
  return ['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
}

export function referalMask(rawInput: string) {
  const mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  return mask;
}

/**
 * Dynamic phone mask creation.
 * @param rawInput Input of field.
 */
export function phoneMaskDynamic(rawInput: string): (string | RegExp)[] {
  const phoneMask = ['+', /\d/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  const phoneMask2 = ['+', /\d/, /\d/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  const phoneMask3 = ['+', /\d/, /\d/, /\d/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  const oneCodeRegex = /\+\d\(/g;
  const oneCodeFullRegex = /\+\d{1}\(\d{3}\)\d{3}\-\d{4}/g;
  const twoCodeRegex = /\+\d{2}\(/g;
  const twoCodeFullRegex = /\+\d{2}\(\d{3}\)\d{3}\-\d{4}/g;
  const threeCodeRegex = /\+\d{3}\(/g;
  const threeCodeFullRegex = /\+\d{3}\(\d{3}\)\d{3}\-\d{4}/g;

  const dictionary = [{
    key: oneCodeRegex,
    value: phoneMask
  },
  {
    key: oneCodeFullRegex,
    value: phoneMask
  },
  {
    key: twoCodeRegex,
    value: phoneMask2
  },
  {
    key: twoCodeFullRegex,
    value: phoneMask2
  },
  {
    key: threeCodeRegex,
    value: phoneMask3
  },
  {
    key: threeCodeFullRegex,
    value: phoneMask3
  }];

  // tslint:disable-next-line:no-magic-numbers
  let regex = null;
  dictionary.forEach(kvp => {
    if (kvp.key.test(rawInput)) {
      regex = kvp.value;
    }
  });


  return regex ? regex : phoneMask3;
}

/**
 * Generates guid.
 */
export function generateGuid(): string {
  function s4() {
    // tslint:disable-next-line:no-magic-numbers
    return Math.floor((1 + Math.random()) * 0x10000)
      // tslint:disable-next-line:no-magic-numbers
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

/**
 * Returns human readable amount of miles converted from feet.
 * @param float amount of feets which will be converted to miles text representation.
 */
export function floatToRadius(float: number): string {
  const feetPerMile = 5280;
  // tslint:disable-next-line:no-magic-numbers
  if (float >= feetPerMile / 2) {
    const miles = float / feetPerMile;
    if (miles <= 1) {
      return `${miles.toFixed(miles === 1 ? 0 : 1)} Mile`;
    } else {
      return `${miles.toFixed(0)} Miles`;
    }
  } else if (float > 0) {
    return `${float.toFixed(0)} Feet`;
  } else {
    return '-';
  }
}

export function normalizeGender(genderSymbol: string): string {
  switch (genderSymbol) {
    case 'M':
    case 'm':
      return 'Male';

    case 'F':
    case 'f':
      return 'Female';

    default:
      return 'Both';
  }
}

export function formatDateRangeToHumanReadble(start: string, end: string): { amount: number, type: DateTimeRangeDiffType } | null {
  const from = moment(start);
  const to = moment(end);

  if (to.isBefore(from)) {
    return null;
  }

  let diff = to.diff(from, 'days');
  let type: DateTimeRangeDiffType = null;
  if (diff > 0) {
    type = 'days';
  } else {
    diff = to.diff(from, 'hours');
    type = 'hours';
  }

  return { amount: diff, type: type };
}

export type DateTimeRangeDiffType = ('days' | 'hours');

/**
 * Returns human readable amount of miles converted from feet.
 * @param float number presentation of miles as string.
 */
export function stringToRadius(floatAsString: string): string {
  switch (floatAsString) {
    case '300.00':
      return '300f';
    case '800.00':
      return '800f';
    case '2640.00':
      return '0.5m';
    case '5280.00':
      return '1m';
    default:
      return '-';
  }
}


export function scrollIntoField(field) {
  const element = document.createElement("input");
  if (!element.scrollIntoView && !(<any>element).scrollIntoViewIfNeeded) {
    return;
  }

  const elementItems = document.getElementsByClassName(field);
  if(elementItems && elementItems.length > 0) {
    const elementItem = elementItems[0];

    if ((<any>elementItem).disabled === true) {
      return;
    }

    elementItem.classList.add("flash");
    setTimeout(() => {
      elementItem.classList.remove("flash");
    }, 3000);

    if ((<any>elementItem).scrollIntoViewIfNeeded) {
      (<any>elementItem).scrollIntoViewIfNeeded(true);
    } else {
      elementItem.scrollIntoView({ behavior: "smooth" });
    }
  }
}

/**
 * Scrolling to bottom of the page
 */
export function scrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight - 1000);
}

/**
 * Scrolling to elements with 'ng-invalid' class.
 */
export function scrollToNotValid() {
  const element = document.createElement("input");
  if (!element.scrollIntoView && !(<any>element).scrollIntoViewIfNeeded) {
    return;
  }

  const elements = document.getElementsByClassName("ng-invalid");
  for (let i = 0; i < elements.length; i++) {
    const elementItem = elements.item(i);


    if (elementItem.tagName === "FORM") {
      continue;
    }

    if((<any>elementItem).disabled === true) {
      continue;
    }

    if (
      elementItem.tagName === "SELECT" ||
      ((elementItem.tagName === "INPUT" ||
        elementItem.tagName === "PB-INPUT"))
    ) {
      if ((<any>elementItem).scrollIntoViewIfNeeded) {
        (<any>elementItem).scrollIntoViewIfNeeded(true);
      } else {
        elementItem.scrollIntoView({ behavior: "smooth" });
      }
      break;
    }
  }
}

export function saveFile(buffer: ArrayBuffer | Blob, fileName: string, mimeType: string) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    if (buffer instanceof ArrayBuffer) {
      const blob = new Blob([buffer], {
        type: mimeType
      });
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      window.navigator.msSaveOrOpenBlob(buffer, fileName);
    }
  } else {
    const download = (filename, url) => {
      const pom = document.createElement('a');
      pom.setAttribute('href', url);
      pom.setAttribute('download', filename);
      if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
      } else {
        pom.click();
      }
    };

    if (buffer instanceof ArrayBuffer) {
      const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
      const url = `data:${mimeType};base64, ${base64String}`;
      download(fileName, url);
    } else if (buffer instanceof Blob) {
      const url = URL.createObjectURL(buffer);
      download(fileName && fileName.length > 0 ? fileName : 'invoice.pdf', url);
    }
  }
}

/**
 * Mapping property of operation hours object.
 * @param propertyName name of property for operation_hours object.
 */
export function mapOperationHoursToDays(propertyName: string, operationHours: any): { day: string, start: boolean, time: string } {
  switch (propertyName) {
    case 'friday_end':
      return { day: 'friday', start: false, time: operationHours['friday_end'] };
    case 'friday_start':
      return { day: 'friday', start: true, time: operationHours['friday_start'] };
    case 'monday_end':
      return { day: 'monday', start: false, time: operationHours['monday_end'] };
    case 'monday_start':
      return { day: 'monday', start: true, time: operationHours['monday_start'] };
    case 'saturday_end':
      return { day: 'saturday', start: false, time: operationHours['saturday_end'] };
    case 'saturday_start':
      return { day: 'saturday', start: true, time: operationHours['saturday_start'] };
    case 'sunday_end':
      return { day: 'sunday', start: false, time: operationHours['sunday_end'] };
    case 'sunday_start':
      return { day: 'sunday', start: true, time: operationHours['sunday_start'] };
    case 'thursday_end':
      return { day: 'thursday', start: false, time: operationHours['thursday_end'] };
    case 'thursday_start':
      return { day: 'thursday', start: true, time: operationHours['thursday_start'] };
    case 'tuesday_end':
      return { day: 'tuesday', start: false, time: operationHours['tuesday_end'] };
    case 'tuesday_start':
      return { day: 'tuesday', start: true, time: operationHours['tuesday_start'] };
    case 'wednesday_end':
      return { day: 'wednesday', start: false, time: operationHours['wednesday_end'] };
    case 'wednesday_start':
      return { day: 'wednesday', start: true, time: operationHours['wednesday_start'] };
  }
}

/**
 * Returns false if operation hours haven't all properties.
 * @param instance Instance which will be checked.
 */
export function isOperationHoursFull(instance: any, validationFunc: (propertyValue, propertyName) => boolean = null) {
  const map = [
    'friday_end',
    'friday_start',
    'monday_end',
    'monday_start',
    'saturday_end',
    'saturday_start',
    'sunday_end',
    'sunday_start',
    'thursday_end',
    'thursday_start',
    'tuesday_end',
    'tuesday_start',
    'wednesday_end',
    'wednesday_start'
  ];

  let full = true;
  const propertyNames = Object.getOwnPropertyNames(instance);
  map.forEach(propertyName => {

    if (validationFunc && propertyNames.includes(propertyName)) {
      if (!validationFunc(instance[propertyName], propertyName)) {
        full = false;
      }
    }

    if (!propertyNames.includes(propertyName)) {
      full = false;
    }
  });

  return full;
}

export function cleanOperationHours(instance: any, validationFunc: (propertyName, propertyValue) => boolean): void {
  const map = [
    'friday_end',
    'friday_start',
    'monday_end',
    'monday_start',
    'saturday_end',
    'saturday_start',
    'sunday_end',
    'sunday_start',
    'thursday_end',
    'thursday_start',
    'tuesday_end',
    'tuesday_start',
    'wednesday_end',
    'wednesday_start'
  ];

  const propertyNames = Object.getOwnPropertyNames(instance);
  map.forEach(propertyName => {

    if (validationFunc && propertyNames.includes(propertyName)) {
      if (!validationFunc(instance[propertyName], propertyName)) {
        instance[propertyName] = null;
      }
    }
  });
}

export function retrieveFileNameFromUrl(url: string): string | null {
  const regex = /[A-z0-9]+\.(png|jpg)/gi;

  const results = regex.exec(url);

  if (results.length === 0) {
    return null;
  }

  return results[0];
}

export function getImageDimensions(image: any): Promise<any> {
  return new Promise<any>(resolve => {
    var i = new Image()
    i.onload = function () {
      resolve({ w: i.width, h: i.height })
    };
    i.src = image;
  });
}

export function getImageDimensionsFromBase64Data(image: any): Promise<any> {
  return new Promise<any>(resolve => {
    var i = new Image()
    i.onload = function () {
      resolve({ w: i.width, h: i.height })
    };
    i.src = image;
  });
}

/**
 * Convert a base64 string to a Blob object.
 * @param b64Data Base64 string.
 * @param contentType Content type.
 * @param sliceSize Slice size.
 */
export function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  contentType = b64Data.match(/^data:([^;]+);base64,/)[1];
  const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

/**
 * Convert a base64 string to a Blob object.
 * @param b64Data Base64 string.
 * @param contentType Content type.
 * @param sliceSize Slice size.
 */
export function b64toArray(b64Data: string, contentType = '', sliceSize = 512): Uint8Array {
  const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const arrayLenght = byteArrays.length * sliceSize;
  const uint8Array = new Uint8Array(arrayLenght);

  let offset = 0;
  byteArrays.forEach((chunk) => {
    chunk.forEach((byte) => {
      uint8Array[offset] = byte;
      offset += 1;
    });
  });

  return uint8Array;
}

export function isPngOrJpeg(buffer: Uint8Array) {
  const fileType = determineFileType(buffer);
  return fileType === 'jpeg' || fileType === 'png';
}

export function determineMimeType(buffer: Uint8Array): string | null {
  const fileType = determineFileType(buffer);

  if (fileType === 'png') {
    return 'image/png';
  } else if (fileType === 'jpeg') {
    return 'image/jpeg';
  }

  return null;
}

export function determineFileType(buffer: Uint8Array): FileType {
  // tslint:disable-next-line:no-magic-numbers
  const pngHeader = [137, 80, 78, 71, 13, 10, 26, 10];
  // tslint:disable-next-line:no-magic-numbers
  const jpegHeader = [255, 216];

  let isPng = true;
  pngHeader.forEach((byte, index) => {
    if (buffer[index] !== byte) {
      isPng = false;
    }
  });

  let isJpg = true;
  jpegHeader.forEach((byte, index) => {
    if (buffer[index] !== byte) {
      isJpg = false;
    }
  });

  if (isPng) {
    return 'png';
  } else if (isJpg) {
    return 'jpeg';
  }

  return 'unknown';
}

export type FileType = 'jpeg' | 'png' | 'unknown';

/**
 * Stripping exif for image file.
 * @param {Uint8Array} array
 * @returns {Blob}
 */
export function stripExif(array: Uint8Array): Blob {
  const dataView = new DataView(array.buffer);
  let offset = 0;
  let recess = 0;
  const chunks = [];
  let i = 0;

  if (isPngOrJpeg(array)) {
    // tslint:disable-next-line:no-magic-numbers
    offset += 2;
    let app1Header = dataView.getUint16(offset);
    // tslint:disable-next-line:no-magic-numbers
    offset += 2;

    while (offset < dataView.byteLength) {
      // tslint:disable-next-line:no-magic-numbers
      if (app1Header === 0xffe1) {
        // tslint:disable-next-line:no-magic-numbers
        chunks[i] = {recess: recess, offset: offset - 2};
        recess = offset + dataView.getUint16(offset);
        i++;
        // tslint:disable-next-line:no-magic-numbers
      } else if (app1Header === 0xffda) {
        break;
        // tslint:disable-next-line:no-magic-numbers
      } else if (app1Header === 0xffed) {
        break;
      }
      offset += dataView.getUint16(offset);
      app1Header = dataView.getUint16(offset);
      // tslint:disable-next-line:no-magic-numbers
      offset += 2;
    }

    if (chunks.length > 0) {
      const newChunks = [];
      chunks.forEach(v => {
        newChunks.push(dataView.buffer.slice(v.recess, v.offset));
      }, this);
      newChunks.push(dataView.buffer.slice(recess));
      return new Blob(newChunks, { type: determineMimeType(array)});
    } else {
      return new Blob([array], { type: determineMimeType(array)});
    }
  } else {
    return new Blob([array], { type: determineMimeType(array)});
  }
}

/**
 * Set all null properties to undefined.
 * @param obj Object.
 */
export function undefineNullProperties(obj) {
  if (obj) {
    for (const prop in obj) {
      if (obj[prop] === null) {
        obj[prop] = undefined;
      }
    }
  }
  return obj;
}

/**
 * Set all undefined properties to null.
 * @param obj Object.
 */
export function nullifyUndefinedProperties(obj) {
  if (obj) {
    for (const prop in obj) {
      if (obj[prop] === undefined) {
        obj[prop] = null;
      }
    }
  }
  return obj;
}

/** @hidden */
export function isTrueProperty(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();
    return (val === 'true' || val === 'on' || val === '');
  }
  return !!val;
}

/** @hidden */
export function isCheckedProperty(a: any, b: any): boolean {
  if (a === undefined || a === null || a === '') {
    return (b === undefined || b === null || b === '');

  } else if (a === true || a === 'true') {
    return (b === true || b === 'true');

  } else if (a === false || a === 'false') {
    return (b === false || b === 'false');

  } else if (a === 0 || a === '0') {
    return (b === 0 || b === '0');
  }

  // not using strict comparison on purpose
  return (a == b); // tslint:disable-line
}

/**
 * Converting radius to different presentation using input.
 * @param radius Radius representation as string or number.
 * @param convertTo Converting to type.
 */
export function convertRadius(radius: string | number, convertTo: RadiusPresentationType): string {
  if (typeof radius === 'string') {
    const numbersRaw = /\d+/.exec(radius);
    if (numbersRaw.length === 0) {
      throw Error('Cannot find numbers in input string');
    }

    const radiusPresentationType = determineTypeOfRadius(radius);
    const match = numbersRaw[0];

    if (radiusPresentationType === 'miles' && convertTo === 'miles') {
      return `${match}mi`;
    } else if (radiusPresentationType === 'feet' && convertTo === 'miles') {
      return floatToRadius(Number(match));
    } else if (radiusPresentationType === 'feet' && convertTo === 'feet') {
      return `${match}f`;
    }

    throw new Error('Cannot determine type of radius.');
  } else if (typeof radius === 'number') {
    return floatToRadius(Number(radius));
  }
}

function determineTypeOfRadius(radius: string): RadiusPresentationType {
  const milesRegex = /mi/;
  const fleetRegex = /f/;

  if (milesRegex.test(radius)) {
    return 'miles';
  } else if (fleetRegex.test(radius)) {
    return 'feet';
  }

  return 'unknown';
}

/**
 * Type of presentation of radiuses
 */
export type RadiusPresentationType = 'unknown' | 'miles' | 'feet';

export function addHttpProtocolIfMissing(url: string): string {
  if (url && !url.startsWith('http')) {
    return `http://${url}`;
  }
  return url;
}

export function padRight(width: number, str: string, padding: string) {
  const pad_len = width - str.length;
  for(var i = 0; i < pad_len; i++) {
    str += padding;
  }
  return str;
}

export function checkBadWords(content: string, profanity_list: string): string[] {
  let bad_words: string[] = [];

  const profanityList = (profanity_list || PROFANITY_LIST).split(',');

  if (!content) return bad_words;

  const words = content.split(' ');
  words.map(word => {
    word = word.replace( /[\r\n]+/gm, '' );
    word = word.replace( '•', '' );
    if(word.length === 0) return;
    word = word.toLowerCase();
    if(profanityList.includes(word)) {
      bad_words.push(word);
    }
  });

  return bad_words;
}
