<form #form="ngForm" [class.form-submitted]="form.submitted">
  <section pb-section caption="Target Audience">
    <div header>
      <div class="row nowrap">
      </div>
    </div>
    <div body>
      <div class="row">
        <div class="target-left">
          <!-- <div class="global-caption">
            Age Range (optional)
          </div> -->
          <!-- <div class="row item min-height" pbRadioGroup [(ngModel)]="ageStart" name="ageRange" required (ngModelChange)="ageRangeChanged()">
            <pb-radio label="18 years and older" name="age" [key]="18" [isChecked]="ageStart === 18" class="radio"></pb-radio>
            <pb-radio label="21 years and older" name="age" [key]="21" [isChecked]="ageStart === 21" class="radio"></pb-radio>
            <pb-radio label="Custom Age Range" name="age" [key]="-1" [isChecked]="ageStart === -1" class="radio">
              <pb-age-range [isActive]="isAgeRangeActive" [required]="isAgeRangeActive" [(ngModel)]="ageRange" name="ageRange"
                (ngModelChange)="ageRangeChanged()" [minInputNumber]="13" [maxInputNumber]="100"></pb-age-range>
            </pb-radio>
          </div> -->
          <div class="global-caption">
            Select Target Interests (Optional)
          </div>
          <div class="row item">
            <pb-selectize-dropdown #categoriesDropdown class="sort categoryDropdown" name="interests" [(ngModel)]="targetAudience.interests"
              [items]="allInterests" placeholder="Choose interests" [openOnFocus]="true" [maxItems]="null"
              dropdownDirection="up" [hasAdd]="true" [disableInput]="true" (clickHandler)="categorySelectionHandler()"
              [disableDropdownOpen]="true">
            </pb-selectize-dropdown>
          </div>
        </div>
        <div class="target-right">
          <div class="global-caption">
            Gender (optional)
          </div>
          <div class="row item min-height" pbRadioGroup [(ngModel)]="targetAudience.gender" name="gender">
            <pb-radio label="Both" name="gender" key="B" value="both" [isChecked]="targetAudience.gender === 'B'" class="radio"></pb-radio>
            <pb-radio label="Male" name="gender" key="M" value="male" [isChecked]="targetAudience.gender === 'M'" class="radio"></pb-radio>
            <pb-radio label="Female" name="gender" key="F" value="female" [isChecked]="targetAudience.gender === 'F'"
              class="radio"></pb-radio>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
