<div class="col" style="height: 100%; display: flex;">
    <div class="card engagement-card {{cardtype}} text-center">
        <div class="circle">
            <i class="fas {{icon}}"></i>
        </div><!-- .icon-circle -->
        <p class="counter">{{number}}</p>

        <p class="title">{{name}}</p>
        <p class="description">{{subtitle}}</p>
    </div><!-- .card -->
</div>
