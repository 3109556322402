<header pb-page-header>
  <div left>Web Applications: {{ webAppId }}</div>
  <div right>
    <div class="buttons">
      <!-- Add buttons here if needed -->
    </div>
  </div>
</header>

<div class="row" style="display: flex; justify-content: space-between; margin-top: 10px;">
  <div style="width: 400px;">
    <input class="form-control" type="text" [(ngModel)]="searchString"
           (ngModelChange)="searchStringChanged($event)" placeholder="Search by Listing Headline..."/>
    <button *ngIf="searchString" class="clear-button" (click)="clearSearch()">×</button>
  </div>
  <div class="right">
    <div style="display: flex;">
      <span class="sort-text">Filter by:</span>
      <select class="custom-select" name="sortby" [(ngModel)]="selectedFiltering"
              (ngModelChange)="filterByChanged($event)" required>
        <option value="" disabled selected>Choose filtering</option>
        <option *ngFor="let item of filtering" [value]="item.id">{{ item.text }}</option>
      </select>
    </div>
  </div>
</div>

<table pb-table mdSort (mdSortChange)="sortDataChanged($event)" *ngIf="coupons.length > 0">
  <thead>
  <tr>
    <th header="name">
      <div class="row">
        Listing Heading
      </div>
    </th>
    <th>
      <div class="row">
        Provider
      </div>
    </th>
    <th width="10%">
      <div class="row">
        Hide Listing
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let coupon of pagedItems;">
    <td>
      <a href="{{coupon.viewUrl}}?lat={{default_location.latitude}}&lng={{default_location.longitude}}"
         target="_blank">{{ coupon.name }}</a>
    </td>
    <td>
      {{ getCouponProvider(coupon.provider) }}
    </td>
    <td>
      <pb-switch [(checked)]="coupon.hidden" (checkedChange)="toggleCoupon(coupon, $event)"></pb-switch>
    </td>
  </tr>
  </tbody>
</table>

<div class="error" *ngIf="coupons.length === 0">
  No Listings Found
</div>

<pb-paginator [pagination]="pagination" (setPage)="setPageClicked($event)"></pb-paginator>
