<div class="root"
     [class.active]="isActive"
     [class.valid]="isValid && !isActive"
     [class.horizontal]="horizontal"
     [class.first]="pageNumber===1"
     [class.last]="pageNumber===last">
  <div class="number">
    <span>{{number}}</span>
  </div>
  <div class="container">
    <div class="caption">Step {{number}}</div>
    <div class="text">
      <ng-content></ng-content>
    </div>
  </div>
</div>
