import { Component } from "@angular/core";

@Component({
  selector: "pb-progress-spinner-popup",
  templateUrl: "./progress-spinner-popup.component.html",
  styleUrls: ['./progress-spinner-popup.component.scss']
})
export class ProgressSpinnerPopupComponent {
  public progress: number = 0;
  public mode: string = "indeterminate";

  updateProgress = (p: number) => (this.progress = p);
}
