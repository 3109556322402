import {
  Component,
  Input,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectizeDropdownComponent, AgeRangeComponent } from '../../shared';
import { MdDialog } from '@angular/material';
import { CategorySelectionPopupComponent } from './../../company-profile-page/category-selection/category-selection-popup.component';
import { Subject } from 'rxjs/';
import { NewMessage, TargetAudience, CategoriesService } from '../../core';
import { PopupService } from '../../popup';

const AGE_OF_MAJORITY = 18;
/**
 * Target popup component.
 */
@Component({
  selector: 'pb-target-popup',
  templateUrl: './target-popup.component.html',
  styleUrls: ['./target-popup.component.scss']
})
export class TargetPopupComponent implements OnInit, OnDestroy {
  /**
   * .ctor
   * @param {CategoriesService} categoriesService Categories service.
   * @param data Passed to the popup data.
   */
  constructor(
    private categoriesService: CategoriesService,
    private dialog: MdDialog,
    private popopService: PopupService
  ) {}

  private destroy$ = new Subject();

  @Input()
  public newMessage: NewMessage;

  @ViewChild('form')
  public form: NgForm;

  @ViewChild(AgeRangeComponent)
  public ageRangeComponent: AgeRangeComponent;

  /**
   * If is ageRange is active.
   */
  @Input()
  public isAgeRangeActive = false;

  /**
   * All main categories from backend.
   */
  public allInterests: { id: string; text: string }[] = [];

  /**
   * Age range.
   */
  public ageRange: { from: number; to: number } = { from: 18, to: null };

  /**
   * To filter target audience.
   */
  public targetAudience: TargetAudience = new TargetAudience();

  /**
   * Start age.
   */
  public ageStart: number;
  @Output()
  public resultTargetAudience: EventEmitter<
    TargetAudience
  > = new EventEmitter();

  @ViewChild('categoriesDropdown')
  public categoriesDropdown: SelectizeDropdownComponent;

  public async categorySelectionHandler(): Promise<void> {
    const dialogRef = this.dialog.open(CategorySelectionPopupComponent, {
      width: '600px',
      /**
       * global style for popup to remove paddings
       */
      panelClass: 'target-dialog',
      data: this.targetAudience.interests
    });
    const categoryId: {
      [idCategory: number]: string;
    } = await dialogRef.afterClosed().toPromise();
    if (categoryId) {
      // get index last kategory and add element last category
      const indexLastCategory = Object.keys(categoryId).length - 1;
      this.categoriesDropdown.addItem(categoryId[indexLastCategory]);
      this.targetAudience.interests = Object.assign(
        [],
        this.targetAudience.interests
      );
    }
  }

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.targetAudience = this.newMessage.targetAudience;
    this.allInterests = this.newMessage.allInterests;

    this.ageStart = this.targetAudience.ageStart || AGE_OF_MAJORITY;
    if (+this.targetAudience.ageEnd) {
      this.ageStart = -1;
      this.isAgeRangeActive = true;
      this.ageRange.from = +this.targetAudience.ageStart
        ? this.targetAudience.ageStart
        : null;
      this.ageRange.to = +this.targetAudience.ageEnd
        ? this.targetAudience.ageEnd
        : null;
    } else {
      // tslint:disable-next-line:no-magic-numbers
      this.ageRange.from = 0;
      this.ageRange.to = +this.targetAudience.ageEnd
        ? this.targetAudience.ageEnd
        : null;
    }

    if (!this.allInterests) {
      const spinner = this.popopService.spinner();

      this.allInterests = (await this.categoriesService.getFlatLeveled()).map(
        o => {
          return { id: o.id, text: o.pluralName };
        }
      );

      spinner.close();
    }

    this.form.control.valueChanges
      .takeUntil(this.destroy$)
      .subscribe(
        (val: {
          ageRange: number;
          gender: 'B' | 'M' | 'F';
          interests: string[];
        }) => {
          const targetAudience = {} as TargetAudience;
          targetAudience.gender = val.gender;
          targetAudience.interests = val.interests;

          if (typeof val.ageRange === 'number' && val.ageRange !== -1) {
            targetAudience.ageStart = val.ageRange;
          } else {
            targetAudience.ageEnd = this.ageRange.to;
            targetAudience.ageStart = this.ageRange.from;
          }
          return this.resultTargetAudience.emit(targetAudience);
        }
      );
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * If age range is active we receive start and end ages from ageRange.
   */
  public ageRangeChanged(): void {
    this.isAgeRangeActive = this.ageStart === -1;
    if (this.isAgeRangeActive) {
      this.targetAudience.ageStart = +this.ageRange.from;
      if (this.ageRange.to) {
        this.targetAudience.ageEnd = +this.ageRange.to;
      }
      // this.ageRangeControl.setErrors({ 'required': true });
    } else {
      this.targetAudience.ageStart = +this.ageStart;
      this.targetAudience.ageEnd = null;
    }
  }
}
