import { TimeVm as Time } from './time';

/**
 * View Module for Time Picker.
 */
export class TimePickerVm {
  /**
   * @constructor
   * @param hour Hour value.
   * @param minutes Minutes value.
   * @param meridiem Meridiem valu.
   */
  constructor({ hour, minutes, meridiem }) {
    this.time = new Time(hour, minutes, meridiem);
  }

  /**
   * Flag for displaying the Time Picker popup.
   */
  public showTimePicker = false;

  /**
   * Time.
   */
  public time: Time;

  /**
   * Get time in 24-hour format.
   */
  public get time24(): string {
    return this.time.time24;
  }
}
