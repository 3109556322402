import { Router } from '@angular/router';
import { Component, Input, Output, OnChanges, EventEmitter, ElementRef, HostListener } from '@angular/core';
import * as moment from 'moment';
import { PopupResult, PopupService } from '../../../popup';
import {
  CouponsService,
} from '../../../core';

/**
 * Component for merchant's activites.
 */
@Component({
  selector: 'pb-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})

export class ActivityComponent implements OnChanges {

  /**
   * Ctor.
   * @param {ElementRef} elementRef Element ref.
   */
  constructor(elementRef: ElementRef,
    private popupService: PopupService,
    private couponService: CouponsService,

  private router: Router) {
    this.elementRef = elementRef;
  }

  /**
   * Element ref.
   */
  public elementRef;

  /**
   * Activities.
   */
  @Input()
  public activities: object[];

  /**
   * Active activities.
   */
  private activeActivities: object[] = [];

  /**
   * Completed activities.
   */
  private completedActivities: object[] = [];

  /**
   * Current activities.
   */
  private currentActivities: object[];

  /**
   * Activities which is not started.
   */
  private notStartedActivities: object[] = [];

  /**
   * Is active flag for activity.
   */
  private isActiveShow = true;

  private isCompletedShow = false;

  private isNotStartedShow = false;

  @Input()
  public type: string; // Campaigns || Promotions || Messages

  @Input()
  public merchantId: number; //Merchant ID for ending campaign

  @Output()
  public reloadCampaigns: EventEmitter<any> = new EventEmitter();

  /**
   * Notice for empty block.
   */
  @Input()
  public emptyNotice = 'You haven\'t added any items';

  /**
   * Active activity item index.
   */
  public activeIndex: number = null;

  public dateRange(activity: any): string {
    let date = '';
    if (activity.startDate) {
      date += activity.startDate;
    }
    if (activity.endDate) {
      date += ` - ${activity.endDate}`;
    }

    return date;
  }
  /**
   * Checks if click was outside or inside activity item.
   */
  @HostListener('document:click', ['$event'])
  public handleClick(event) {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.activeIndex = null;
    }
  }

  public isAbleToEnd(activity) {

    if (activity.isEndedNow) {
      return false;
    }

    // if (activity.endDateRaw) {
    //   return moment(activity.endDateRaw).isAfter(moment());
    // }

    // if (!activity.endDateRaw) {
    //   return true;
    // }

    return activity.isCompleted;;
  }

  /**
   * Flag for edit abillity.
   */
  public isAbleToEdit(activity) {

    // if (activity && !activity.endDateRaw) {
    //   return true;
    // }

    // if (activity) {
    //   return moment(activity.endDateRaw).isAfter(moment());
    // }

    return activity.isCompleted;
  }

  /**
   * Redirect to the edit campaign page.
   */
  public editCoupon(index, type): void {
    this.activeIndex = index;
    const activity = this.currentActivities[index];
    const campaignId = (<any>activity).campaignId;

    this.router.navigate(
      ['edit-campaign', campaignId],
      { queryParams: { type: 'coupon' } }
    );
  }

  public async openPopup(index): Promise<void> {
    event.preventDefault();
    this.activeIndex = index;
    const activity = this.currentActivities[index];
    const campaignId = (<any>activity).campaignId;
    const online = (<any>activity).online;
    const in_store = (<any>activity).in_store;

    const result = await this.popupService.confirm('Are you sure you want to end this coupon?', 'End Coupon');
    if (result === PopupResult.YES) {
      const spinner = this.popupService.spinner();
      // do something
      const endDate = moment().add(-1, 'day').format();
      await this.couponService.endCoupon(this.merchantId, campaignId, endDate,
        { in_store: in_store, online: online });
      (<any>activity).isEndedNow = true;
      (<any>activity).endDateRaw = endDate;

      this.reloadCampaigns.emit();
      
      spinner.close();
    }

  }

  /**
   * Filtering activities on component.
   * @param activities activites to filter.
   * @param type type of activity.
   */
  public filterActivities(activities, type): void {
    if (activities) {
      if (type === 'Messages') {
        this.activeActivities = this.activities;
      } else {

        this.activeActivities = [];
        this.completedActivities = [];
        this.notStartedActivities = [];

        activities.map(item => {

          if(type === 'Coupons' || type === 'Promotions') {
            item.isAbleToEdit = this.isAbleToEdit(item);
            item.isAbleToEnd = this.isAbleToEnd(item);
          }

          item.isActive ? this.activeActivities.push(item) : item.isCompleted ? this.completedActivities.push(item) :
          this.notStartedActivities.push(item);
        });

        if (!this.activeActivities || this.activeActivities.length === 0) {
          if (this.completedActivities && this.completedActivities.length > 0) {
            this.showCompleted();
          } else if (this.notStartedActivities && this.notStartedActivities.length > 0) {
            this.showNotStarted();
          }
        } else {
          this.showActive();
        }
      }
    }
  }

  /**
   * Triggering component to show active activities.
   */
  public  showActive(): void {
    this.isActiveShow = true;
    this.isCompletedShow = false;
    this.isNotStartedShow = false;
    this.currentActivities = this.activeActivities;
  }

  /**
   * Triggering component to show completed activities.
   */
  public showCompleted(): void {
    this.isActiveShow = false;
    this.isCompletedShow = true;
    this.isNotStartedShow = false;
    this.currentActivities = this.completedActivities;
  }

  public showNotStarted(): void {
    this.isActiveShow = false;
    this.isCompletedShow = false;
    this.isNotStartedShow = true;
    this.currentActivities = this.notStartedActivities;
  }

  /**
   * @inheritdoc
   */
  public ngOnChanges() {
    this.seedActivities();
  }

  private seedActivities() {
    this.filterActivities(this.activities, this.type);
    this.currentActivities = this.isActiveShow ? this.activeActivities : this.isCompletedShow ?
                             this.completedActivities : this.notStartedActivities;
  }

  /**
   * Make activity item active.
   */
  public makeActive(index: number): void {
    this.activeIndex = index;
    const activity = this.currentActivities[index];
    if ((<any>activity).viewUrl) {
      const url = (<any>activity).viewUrl;
      this.router.navigateByUrl(url);
    }
  }
}
