<div class="wrapper">
  <div class="holding" (click)="onSelectMerchant(merchant.id, true)">
    <div *ngIf="logoUrl && showLogo" [ngClass]="rectLogo !== true ? 'logo-container' : 'logo-container rect'">
      <pb-icon *ngIf="!merchant" name="#iconUser" class="logo"></pb-icon>
      <img *ngIf="logoUrl" class="logo" [src]="logoUrl">
    </div>
    <div class="name-container" [class.active]="merchant.id === currentMerchantId">
      <h3 *ngIf="isHolding" class="name">{{merchant.dbaName}}</h3>
      <div class="id">CID# {{merchant.personalId}}</div>
    </div>
  </div>
  <div class="divisions" *ngIf="showDivisions">
    <div class="division" *ngFor="let division of divisions" [class.active]="division.id === currentMerchantId" (click)="onSelectMerchant(division.id, false)">
      <div [ngClass]="divisionRectLogos[division.logo] !== true ? 'logo-container' : 'logo-container rect'" *ngIf="division.logo && showLogo">
        <img class="logo" [src]="divisionLogoUrl(division)">
      </div>
      <div class="name-container">
        <h3 class="name">{{division.dbaName}}</h3>
        <div class="id">CID# {{division.personalId}}</div>
      </div>
    </div>
  </div>
</div>
