import { PopupResult } from '../popup';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService, MerchantService, MerchantsCoupon, PlaceService, CategoriesService, ReportsService } from '../core';
import { Category, Place, Merchant, Report } from '../core';
import { floatToRadius } from '../../utils';
import { PopupService } from '../popup';
import { normalizeGender } from '../../utils';



@Component({
  selector: 'pb-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss']
})
export class ReportDashboardComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;

  private activatedRouteSubscription: Subscription;

  public merchant: Merchant;

  constructor(
    private authService: AuthService,
    private merchantService: MerchantService,
    private activatedRoute: ActivatedRoute,
    private reportsService: ReportsService,
    private popupService: PopupService,
    private placeService: PlaceService,
    private categoriesService: CategoriesService,
    private router: Router
  ) { }

  public get currentDate() {
    return new Date();
  }



  public report: Report;

  public activeCouponCount = 0;

  public activePromotionCount = 0;

  public redemptions = 0;

  public messagesCount = 0;

  public campaigns: object[] = [];

  /**
   * Exporting to pdf.
   * @param event Event on click.
   */
  public exportToPdf(event) {
    if (this.report.document) {
      window.open(this.report.document, '_blank');
    }
  }

  /**
   * Deleting report and redirecting to list of reports.
   */
  public async deleteReport(event): Promise<void> {

    const result = await this.popupService.confirm('Are you sure to delete report ?', 'Report Deleting');

    if (result === PopupResult.OK || result === PopupResult.YES) {
      const spinner = this.popupService.spinner();

      await this.reportsService.deleteReport(this.merchant.id, this.report.id);

      spinner.close();

      this.router.navigateByUrl('/reports');
    }
  }

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {

    const spinner = this.popupService.spinner();

    let places: Place[] = [];
    let categories: Category[] = [];

    this.userSubscription = this.authService.onUserChanged.skipWhile(u => u === null)
      .subscribe(async (user): Promise<void> => {
        this.merchant = await this.merchantService.getMerchant(user.merchantId);
        this.activatedRouteSubscription = this.activatedRoute.params.subscribe(async (params): Promise<void> => {
          const reportId = params['id'];
          this.report = await this.reportsService.getReport(this.merchant.id, reportId);

          if (this.report && this.report.data && this.report.data.length > 0) {
            const coupons: MerchantsCoupon[] = [];

            this.report.data.forEach(data => {

              if (data.activeCouponCount) {
                this.activeCouponCount += +data.activeCouponCount;
              }
              if (data.activePromotionsCount) {
                this.activePromotionCount += +data.activePromotionsCount;
              }
              if (data.redemptions) {
                this.redemptions += +data.redemptions;
              }
              if (data.merchantMessageCount) {
                this.messagesCount += +data.merchantMessageCount;
              }

              data.coupons.forEach(coupon => {
                coupons.push(coupon);
              });
            });

            if (coupons.length && categories.length === 0) {
              categories = await this.categoriesService.getFlatLeveled();
            }


            if (coupons.length && places.length === 0) {
              places = await this.placeService.getPlaces(this.merchant.id);
            }
            this.campaigns = await Promise.all(coupons.map(async (coupon): Promise<object> => {
              const object = {
                  id: coupon.id,
                  name: coupon.name,
                  startDate: coupon.startDate,
                  endDate: coupon.endDate,
                  locations: [],
                  isActive: coupon.isActive,
                  isCompleted: coupon.isCompleted,
                  impressions: coupon.impressions,
                  likes: coupon.likes,
                  redemptions: coupon.redemptions,
                  gender: null,
                  radius: null,
                  interests: [],
                  ageRanges: []
                };

              if (coupon.targetAuidience) {
                object.gender = normalizeGender(coupon.targetAuidience.gender);
                object.radius = floatToRadius(+coupon.targetAuidience.radius);
                if (coupon.targetAuidience.ageStart) {
                  object.ageRanges = [`${coupon.targetAuidience.ageStart}`];
                  if (coupon.targetAuidience.ageEnd) {
                    object.ageRanges[0] = object.ageRanges[0] += ` - ${coupon.targetAuidience.ageEnd}`;
                  }
                }
              }

              if (coupon.places.length > 0) {
                object.locations = coupon.places.map(place => {
                  return <string>place.name;
                });
              }

              categories.forEach(item => {
                if (coupon.targetAuidience.interest.includes(item.id)) {
                  if (item.name) {
                    object.interests.push(item.name);
                  } else if (item.pluralName) {
                    object.interests.push(item.pluralName);
                  }
                }
              });

              return object;
            }));
          }

          spinner.close();

          // Hack for subscription, because awaiter still call this on Delete report.
          // May be it's related to user change ?
          if (this.activatedRouteSubscription) {
            this.activatedRouteSubscription.unsubscribe();
          }
          if (this.userSubscription) {
            this.userSubscription.unsubscribe();
          }
        });
      });
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    if (this.activatedRouteSubscription) {
      this.activatedRouteSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}
