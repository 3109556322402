import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {environment} from '../../../../environments/environment';
import {TokenStorageService} from '../token-storage.service';
import {BehaviorSubject} from 'rxjs';
import {Customization} from '../../models/customization';
import {CustomizationMapper} from './mappers/customization.mapper';
import {CustomizationDto} from './dtos/customization.dto';


/**
 * Core http service provides infrastructural methods.
 */
@Injectable()
export class CustomizationService {

  public options: BehaviorSubject<Customization>;

  /**
   * .ctor
   * @param http Http
   */
  constructor(
    private http: Http,
    private customizationMapper: CustomizationMapper,
    private tokenService: TokenStorageService
  ) {
    this.options = new BehaviorSubject<Customization>(new Customization({}));
  }

  public get showTitle(): boolean {
    return true;
  }

  public setCustomizations(customization: CustomizationDto): void {
    this.options.next(this.customizationMapper.mapToModel(customization));
  }
}
