import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import {MerchantAppDto} from '../../merchant/dtos/merchant-app.dto';
import {MerchantApp} from '../../../models/merchant-app';

/**
 * State mapper.
 */
@Injectable()
export class BrandMapper implements Mapper<MerchantAppDto, MerchantApp> {
  /**
   * @inheritdoc
   * @param {MerchantAppDto} dto MerchantApp DTO.
   */
  public mapToModel(dto: MerchantAppDto): MerchantApp {
    return new MerchantApp({
      favicon: dto.favicon,
      meta_description: dto.meta_description,
      title: dto.title,
      payment_required: dto.payment_required,
      allow_multiple_profiles: dto.allow_multiple_profiles,
      category: dto.category,
      event_category: dto.event_category,
      default_plan: dto.default_plan,
      categories: dto.categories,
      plan_switchable: dto.plan_switchable,
      show_pricing: dto.show_pricing,
      id: dto.id,
      name: dto.name,
      logo_image: dto.logo_image,
      brand_login_path: dto.brand_login_path,
      is_enabled: dto.is_enabled,
      theme: dto.theme,
      customization: dto.customization,
    });
  }
}
