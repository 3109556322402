import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, SimpleChange } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { GooglePlaceProvider, PredictionResult } from '../../../core';
import { PlaceSearch } from '../../../core';
import { SelectizeDropdownComponent } from '../selectize-dropdown/selectize-dropdown.component';


/**
 * The wrapper on address selectize dropdown.
 */
@Component({
  selector: 'pb-address-selectize',
  templateUrl: './address-selectize.component.html',
  styleUrls: ['./address-selectize.component.scss'],
})
export class AddressSelectizeComponent implements OnInit, OnDestroy {

  /**
   * Destroy subject.
   */
  private destroy$ = new Subject<void>();

  @ViewChild('addressDropdown')
  public addressDropdown: SelectizeDropdownComponent;

  /**
   * Items for the drop down in address dropdown selectize.
   */
  public places: PredictionResult[] = [];

  /**
   * Current address entered in address selectize dropdown.
   */
  public selectedAddressName = '';
  private addressQueryChange$: Subject<string> = new Subject<string>();

  /**
   * When you change the place emits a value.
   */
  @Output()
  public changePlaceSearch: EventEmitter<PlaceSearch> = new EventEmitter<PlaceSearch>();
  /**
   * When you change the place emits a plase address street.
   */
  @Output()
  public changeAddressId: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Default address street.
   */
  @Input()
  public currentAddressStreet: string;

  @Input()
  public searchBusiness: boolean = false;

  @Input()
  public helpText: string;

  @Input()
  public countries: string[];

  @Input()
  public placeholder: string = 'Search for Address';


  @Input()
  public required: boolean = true;

  /**
   * Hidden or shown addressDropdown.NgIf has problems hiding the addressDropdown.
   */
  public displayAddressDropdown: 'block' | 'none' = 'none';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private googlePlaceProvider: GooglePlaceProvider
  ) { }

  /**
   * Init selectize.
   */
  public ngOnInit(): void {
    this.subscribeToAddressQueryChanges();
    this.helpText = this.helpText || 'This address will appear on your merchant page';
  }

  public ngOnChanges(changes: SimpleChange): void {
    this.helpText = this.helpText || 'This address will appear on your merchant page';
  }

  /**
   * Subscribe to address query changes and load places in address dropdown.
   */
  public subscribeToAddressQueryChanges() {
    const timeDelayAfterStopTyping = 300;
    this.addressQueryChange$.pipe(
      debounceTime(timeDelayAfterStopTyping),
      distinctUntilChanged(),
      switchMap((query: string) => this.searchGooglePlace(query)),
      catchError((error, caught) => caught),
      tap(() => this.addressDropdown.refreshState()),
      takeUntil(this.destroy$),
      map((queryResults: PredictionResult[]) => queryResults.map(({ id, text }) => ({ id, text }))),
    ).subscribe(places => this.openAddressDropdown(places));
  }

  /**
   * Loads google places  or if the query is empty closes the dropdown.
   * @param query Request for finding places.
   */
  public searchGooglePlace(query: string) {
    this.places = [];
    if (query && query.length > 0) {
      return this.googlePlaceProvider.search(query, this.countries,
        this.searchBusiness === true ? ['establishment'] : ['geocode']);
    } else {
      this.addressDropdown.closeDropdown();
      this.addressDropdown.refreshState();
    }
  }

  /**
   * Loads of places in dropdown and opens it.
   * @param places that should be displayed in the dropdown.
   */
  public openAddressDropdown(places: PredictionResult[]): void {
    if (places && places.length > 0) {
      this.places = places;
      this.changeDetectorRef.detectChanges();
      this.addressDropdown.openDropdown();
    } else {
      this.places = [];
      this.addressDropdown.closeDropdown();
    }
  }

  /**
   * When the address input focus change focus to on the address dropDown input and shows it.
   */
  public focus(): void {
    this.displayAddressDropdown = 'block';
    this.addressDropdown.refreshState();
    this.changeDetectorRef.detectChanges();
    this.addressDropdown.focus();
  }

  /**
   * When address dropdown selecting and losing focus hides addressDropdown.
   */
  public hideAddress(): void {
    this.displayAddressDropdown = 'none';
  }

  /**
   * Handling address input change.
   * @param value Value on input change.
   */
  public handleAddressInputChange(value: string): void {
    if (value) {
      this.changeAddressId.emit(value);
    }
  }

  /**
   * Searching locations by query.
   * @param event Event of input.
   */
  public handleAddressQuery(event: {
    query: string,
    callback: any,
  }): void {
    this.addressQueryChange$.next(event.query);
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
