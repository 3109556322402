/// <reference types="@types/googlemaps" />
import { BehaviorSubject } from 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { PredictionResult } from './google/prediction.result';
import { PlaceSearch, AddressComponent } from '../../../models/place-search';



@Injectable()
export class GooglePlaceProvider {

    constructor() {
        this.subject = new BehaviorSubject<PredictionResult[]>(null);
    }

    public subject: BehaviorSubject<PredictionResult[]>;

    /**
     * Search using google api.
     * @param prediction Query prediction for search.
     * @param countries Query prediction for countries.
     * @param types Query prediction for countries.
     */
    public search(prediction: string, countries= ['US', 'CA'], types: string[] = []): Promise<PredictionResult[]> {
        return new Promise<PredictionResult[]>((resolve, reject) => {
            const service = new google.maps.places.AutocompleteService();

            service.getPlacePredictions({
                input: prediction,
                types: types,
                componentRestrictions: {
                    country: countries
                }
            }, (results, status) => {
                if (!results) {
                    reject(`Results is null, status is ${status}`);
                    return;
                }

                const places = results.map(result => {
                    const item = new PredictionResult();
                    item.text = result.description;
                    item.id = result.place_id;
                    return item;
                });

                resolve(places);
            });
        });
    }

    public getPlaceDetails(id: string): Promise<PlaceSearch> {
        return new Promise<PlaceSearch>((resolve, reject) => {
            const service = new google.maps.places.PlacesService(document.createElement('div'));
            try {
              const details = service.getDetails({
                placeId: id
              }, (result, status) => {
                if (result) {
                  resolve(this.seedDetailsResult(result));
                } else {
                  resolve(null);
                }
              });
            } catch (e) {
              resolve(null);
            }
        });
    }

    private seedDetailsResult(result: google.maps.places.PlaceResult): PlaceSearch {
        const placeSearch = new PlaceSearch();
        placeSearch.address = result.formatted_address;
        placeSearch.id = result.id;
        placeSearch.location = {
            latitude: result.geometry.location.lat(),
            longitude: result.geometry.location.lng()
        };
        placeSearch.name = result.name;
        placeSearch.provider = 'google';
        placeSearch.addressComponents = result.address_components.map(ac => {
            const addressComponent = new AddressComponent();
            addressComponent.longName = ac.long_name;
            addressComponent.shortName = ac.short_name;
            addressComponent.types = ac.types;

            return addressComponent;
        });

        return placeSearch;
    }
}
