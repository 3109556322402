<div class="preview">
    <div class="sticky">
        <div *ngIf="canShowPricing()">
            <div class="plan-info">
                <p class="plan-title">
                    Pricing - {{isFreePlan() ? 'Local Campaign' : 'Featured Campaign'}}
                </p>
            </div>
            <div class="plan-info">
                <p class="plan-pricing">
                    ${{getPlanCost()}} monthly*
                </p>
                <p class="plan-extra">
                </p>
            </div>
        </div>
        <!-- </h2> -->
        <div class="buttons">
            <button pb-button class="button switch-type-image" [class.active]="previewCoupon.mode === 'main'" small
                type="button" (click)="onTypeImageChange('main')">
                Preview
            </button>
            <button pb-button class="button switch-type-image" [class.active]="previewCoupon.mode  === 'full'" small
                type="button" (click)="onTypeImageChange('full')">
                Details
            </button>
        </div>

        <div class="row">
            <div class="marvel-device s5">
                <div class="top-bar"></div>
                <!-- <div class="sleep"></div> -->
                <!-- <div class="volume"></div> -->
                <!-- <div class="camera"></div> -->
                <!-- <div class="sensor"></div> -->
                <div class="speaker"></div>
                <div class="screen" style="overflow-x: hidden;">
                    <div *ngIf="previewCoupon.mode === 'main'" class="coupon-preview-main">
                        <div style="display: flex; flex-direction:row; padding: 5px;">
                            <div [ngClass]="(isLogoRect!==true)?'image-logo-full':'image-logo-full rect'"
                                style.background-image="url({{logo}})">
                            </div>
                            <p class="merchant-name-text">{{dbaName}}</p>
                        </div>
                        <img class="image-coupon" [src]='campaignMainImage'>
                        <div class="coupon-price-container" *ngIf="(price$ | async) as price">
                            <div class="coupon-price" *ngIf="price.show">
                                <p class="price-count-was">${{ price.was }}</p>
                                <p class="price-count-is"><strong>${{ price.is }}</strong></p>
                            </div>
                        </div>
                        <div class="campaign-message-container">
                            <p class="campaign-message-text">
                                {{ previewCouponImage?.campaignMessage }}
                            </p>
                            <label class="name-of-coupon">{{Address.city}}, {{ Address.state }} - x.x mi / km</label>
                            <label class="expires-days">
                                <pb-icon *ngIf="covidOptions.enabled" name="#iconCovid" class="icon"></pb-icon>
                                <pb-icon name="#iconLikeFilled" class="icon"></pb-icon>
                                <pb-icon name="#iconBookmarkFilled" class="icon"></pb-icon>
                            </label>
                        </div>
                    </div>
                    <div *ngIf="previewCoupon.mode === 'full'" class="coupon-preview-full">
                        <img class="image-coupon-full" [src]='campaignMainImage'>

                        <div class="info-row">
                            <div class="campaign-message-container">
                                <p class="campaign-message-text">
                                    {{ previewCouponImage?.campaignMessage }}
                                </p>
                                <!-- <label class="x-x-mi">x.x mi</label> -->
                                <label class="name-of-coupon">{{ dbaName }}</label>
                                <label class="expires-days">{{Address.city}}, {{ Address.state }}</label>
                            </div>

                            <div class="valid-select-location">
                                <label class="valid-select-location-text">
                                    Valid at select locations
                                    <img src="/assets/images/ic_info.svg" alt="info">
                                </label>
                                <div class="coupon-redeemed" *ngIf="isStoreCouponLimitCountDisplay">
                                    <p class="coupon-redeemed-text">Redeemed:</p>
                                    <label class="coupon-redeemed-count"><strong>0</strong> of
                                        <strong>{{redemptionOptions?.storeCouponLimitCount}}</strong></label>
                                </div>
                            </div>

                            <div *ngIf="redemptionOptions.isRedeemable">
                                <button pb-button class="button blue redeem-now-button" small>Redeem Now <i
                                        class="arrow right"></i></button>
                            </div>
                            <!-- <div *ngIf="covidOptions.enabled">
                                <button pb-button class="button green redeem-now-button" small>Covid Cleaning - Learn
                                    More
                                    <i class="arrow right"></i></button>
                            </div> -->
                            <div *ngFor="let option of redemptionOptions.shopOptionsList">
                                <div *ngIf="isShopNowEnabled(option.url_type)">
                                    <button pb-button class="button dark-blue redeem-now-button" small>{{option.title}}
                                        <i class="arrow right"></i></button>
                                </div>
                            </div>
                            <div class="contact-button-group">
                                <div class="contact-button-container">
                                    <div *ngIf="targetMarketing.contactInformationPhone" class="contact-button">Call
                                    </div>
                                </div>
                                <div class="contact-button-container">
                                    <div *ngIf="targetMarketing.contactInformationText" class="contact-button">Text
                                    </div>
                                </div>
                                <div class="contact-button-container">
                                    <div *ngIf="targetMarketing.contactInformationEmail" class="contact-button">Email
                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                            <div *ngIf="previewCouponImage.dealDescription">
                                <div class="section-heading">Deal Description</div>
                                <div class="section-content">
                                    {{previewCouponImage.dealDescription}}
                                </div>
                            </div>
                            <div class="divider"></div>
                            <div class="campaign-message-container-full">
                                <div [ngClass]="(isLogoRect!==true)?'image-logo-full2':'image-logo-full2 rect'"
                                    style.background-image="url({{logo}})"></div>
                                <div class="message-info">
                                    <div class="text-and-name">
                                        <p class="campaign-message-text-full">
                                            {{ dbaName }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="home"></div>
                <div class="bottom-bar"></div>
            </div>
        </div>
        <div *ngIf="isFreePlan() && !isEdit && isUpgradable()" class="upgrade-box">
            <div class="up-text">Upgrade this Campaign to Featured?</div>
            <button (click)="upgradeToPremium()" class="up-button">Upgrade Now</button>
            <div class="plan-compare">
                <ul>
                    <li *ngFor="let item of planComparison; let index = index">{{item.text}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
