import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { DataTransferService, MessagesService, AuthService, CategoriesService, NewMessage } from '../../core';

/**
 * Message details component.
 */
@Component({
  selector: 'pb-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {

  private static defaultUrl = '/new-message';
  private static activitiesUrl = '/activities';

  private static dashboardUrl = '/dashboard';

  /**
   * .ctor
   * @param {ActivatedRoute} activatedRoute Activated angular route.
   * @param {DataTransferService} dataTransferService Data transfer service.
   * @param {MessagesService} messagesService Messages service.
   * @param {AuthService} authService Authentication service.
   * @param {CategoriesService} categoriesService Categories service.
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private dataTransferService: DataTransferService,
    private messagesService: MessagesService,
    private authService: AuthService,
    private categoriesService: CategoriesService,
    private router: Router,
  ) {
    const key = 'previousUrl';
    this.previousUrl = localStorage[key] ? localStorage[key] : '/new-message';
  }

  /**
   * Previous url.
   */
  public previousUrl: string;


  public get firstBreadcrumb(): string {
    if (this.previousUrl === DetailsComponent.dashboardUrl) {
      return 'Dashboard';
    }
    if (this.previousUrl === DetailsComponent.activitiesUrl) {
      return 'Activities';
    }

    return 'New Message';
  }

  /**
   * Route subscriber.
   */
  private routeSub: Subscription;

  /**
   * A merchant id.
   */
  private merchantId: number;

  /**
   * A message id.
   */
  private messageId: number;

  /**
   * A message.
   */
  public message: NewMessage = new NewMessage();

  /**
   * To trigger spinner if data is loading.
   */
  public isLoading = true;

  /**
   * All main categories from backend.
   */
  public allInterests: { id: string, text: string }[] = [];

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.routeSub = Observable.combineLatest(
      this.activatedRoute.paramMap,
      this.authService.onUserChanged,
      // tslint:disable-next-line:no-magic-numbers
      this.categoriesService.getFlatLeveled(),
    ).subscribe(async triple => {
      const [params, user, interests] = triple;
      if (user) {
        this.merchantId = user.merchantId;
        this.messageId = +params.get('id');
        this.message = await this.messagesService.getMessageInfo(this.merchantId, this.messageId);

        if(this.message.video) {
          setTimeout(() => {
            const videoNode = document.querySelector('video')
            videoNode.src = this.message.video;
          }, 1000);
        }

        /*if (this.message.targetAudience.interests) {
          this.allInterests = interests
            .map(o => {
              return { id: o.id, text: o.pluralName };
            });


          const interestNames = this.message.targetAudience.interests.map(id => {
            const category = this.allInterests.find(mainCategory => mainCategory.id === id);
            if (!category) {
              return '';
            }
            return category.text;
          });

          if (!interestNames.every(o => o === '')) {
            this.message.targetAudience.interests = interestNames;
          } else {
            this.message.targetAudience.interests = [];
          }
        }*/
        this.isLoading = false;

        if (this.routeSub) {
          this.routeSub.unsubscribe();
        }
      }
    });
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy() {
    this.dataTransferService.clearData();
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
