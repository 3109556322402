import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ScanReportsPageComponent } from './scan-reports-page.component';
import { RequiredDateValidationDirective } from './directives/required-date.directive';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
  ],
  declarations: [
    ScanReportsPageComponent,
    RequiredDateValidationDirective,
  ],
  exports: [
    ScanReportsPageComponent,
  ],
})
export class ScanReportsPageModule {
}
