<pb-breadcrumbs [items]="[firstBreadcrumb, 'Message Details']"></pb-breadcrumbs>

<header pb-page-header>
  <div left>
    <a class="row centered link" [routerLink]="[previousUrl]">
      <pb-icon name="#iconBackArrow" class="back-arrow"></pb-icon>{{firstBreadcrumb}}
    </a>
  </div>
</header>

<section pb-section caption="Message Details" [spinner]="isLoading">
  <div body>
    <div class="row">
      <div class="left">
        <div class="global-caption">Sent</div>
        <div class="text">{{message.created | date:'M/d/y hh:mm a'}}</div>
        <div class="global-caption">Title</div>
        <div class="text">
          {{message.title}}
        </div>
        <div class="global-caption">Message</div>
        <div class="text">
          {{message.message}}
        </div>
        <div *ngIf="!message.isConcierge && message.actionURL" class="global-caption">Action URL</div>
        <div *ngIf="!message.isConcierge && message.actionURL" class="text">
          {{message.actionText}} - {{message.actionURL}}
        </div>
        <div *ngIf="message.isConcierge" class="global-caption">Concierge Contact</div>
        <div *ngIf="message.isConcierge && message.actionPhone" class="text">
          {{message.actionPhone}}
        </div>
        <div *ngIf="message.isConcierge && message.actionEmail" class="text">
          {{message.actionEmail}}
        </div>
      </div>
      <!-- <div class="right">
        <div class="global-caption">Target Recipients ({{message.audienceCount}} Total)</div>
        <div class="text" *ngIf="!message.targetAudience.interests">• All Interests</div>
        <div *ngIf="message.targetAudience.interests && message.targetAudience.interests.length > 0">
          <div class="text" *ngFor="let interest of message.targetAudience.interests">
          <div *ngIf="interest && interest.length > 0">
            • {{interest}}
          </div>
          </div>
        </div>
        <pb-info-item caption="yrs old" *ngIf="message.targetAudience && message.targetAudience.ageStart && message.targetAudience.ageEnd">
          {{message.targetAudience.ageStart}}
          <ng-template [ngIf]="message.targetAudience.ageEnd">
            - {{message.targetAudience.ageEnd}}
          </ng-template>
          <ng-template [ngIf]="!message.targetAudience.ageEnd">
            +
          </ng-template>
        </pb-info-item>
        <pb-info-item caption="males" *ngIf="message.targetAudience && (message.targetAudience.gender === 'M' || message.targetAudience.gender === 'B')">
          <pb-icon name="#iconMale" class="male"></pb-icon>
        </pb-info-item>
        <pb-info-item caption="females" *ngIf="message.targetAudience &&  (message.targetAudience.gender === 'F' || message.targetAudience.gender === 'B')">
          <pb-icon name="#iconFemale" class="female"></pb-icon>
        </pb-info-item>
      </div> -->
      <div class="left" *ngIf="message.video || message.image">
        <br />
        <br />
        <div class="coupon-image">
          <div class="global-caption">Media</div>
          <video [ngClass]="(!message.video)?'hidden':'video-full'" style="width: 300px" controls></video>
          <div [ngClass]="(!message.image)?'hidden':''" style="margin-top: 10px; width:100%;">
            <div class="image-logo-full" style.background-image="url({{message.image}})"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
