import { Component, Input } from '@angular/core';

/**
 * Message component.
 */
@Component({
  selector: 'pb-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  /**
   * Message caption.
   */
  @Input()
  public caption: string;

  /**
   * Message text.
   */
  @Input()
  public text: string;
}
