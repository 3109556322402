import { EventEmitter, HostListener, OnInit, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

/**
 * Base NgModel value accessor.
 */
export class ValueAccessorBase<T> implements ControlValueAccessor, OnInit {
  protected innerValue: T;

  private disabledListeners: EventListeners[] = [];
  private emptyValuePropagation = true;
  private allListenersDisabled = false;

  private onChanged: (value: T) => void;
  private onTouched: () => void;

  @Input()
  public turnOffListeners = false;

  @Input()
  public disableEmptyValuePropagation = false;

  /**
   * Change dispatcher.
   */
  public changeDispatcher: EventEmitter<T> = new EventEmitter();

  /**
   * Gets an input value
   */
  public get value(): T {
    return this.innerValue;
  }

  /**
   * Sets an input value.
   */
  public set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  /**
   * @inheritdoc
   */
  protected onChange(value: T) {
    if (this.onChanged) {
      this.onChanged(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  /**
   * @inheritdoc
   * @param value A new value.
   */
  public writeValue(value: T): void {
    if (this.innerValue !== value && value !== null) {
      this.innerValue = value;
    }
  }

  /**
   * @inheritdoc
   * @param {(value: T) => void} fn  The function to be called.
   */
  public registerOnChange(fn: (value: T) => void): void {
    this.onChanged = fn;
  }

  /**
   * @inheritdoc
   * @param {() => void} fn The function to be called.
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Handling event on change.
   */
  @HostListener('change')
  protected handleChange() {
    if (this.isRestrictedEvent('change')) {
      return;
    }

    if (this.isValueValid()) {
      this.onChange(this.innerValue);
      this.changeDispatcher.emit(this.innerValue);
    }
  }

  /**
   * Handling event on input.
   */
  @HostListener('input')
  protected handleInput() {
    if (this.isRestrictedEvent('input')) {
      return;
    }

    if (this.isValueValid()) {
      this.onChange(this.innerValue);
      this.changeDispatcher.emit(this.innerValue);
    }
  }

  /**
   * Handling event on checked.
   */
  @HostListener('checked')
  protected handleChecked() {
    if (this.isRestrictedEvent('checked')) {
      return;
    }

    if (this.isValueValid()) {
      this.onChange(this.innerValue);
      this.changeDispatcher.emit(this.innerValue);
    }
  }

  /**
   * Checking if value valid.
   */
  private isValueValid(): boolean {
    if (this.emptyValuePropagation && !this.innerValue) {
      return true;
    } else if (!this.emptyValuePropagation && !this.innerValue) {
      return false;
    }

    return true;
  }

  /**
   * Setting empty value propagation strategy.
   * @param allow Flag for allow empty values (undefined/null) to propagate.
   */
  protected setEmptyValuePropagation(allow: boolean) {
    this.emptyValuePropagation = allow;
  }

  /**
   * Disabling event listener.
   * @param eventListener Type of event listener.
   */
  protected disableListener(eventListener: EventListeners) {
    if (!this.disabledListeners.includes(eventListener)) {
      this.disabledListeners.push(eventListener);
    }
  }

  /**
   * Checking of disabled event listeners.
   * @param eventListeners Type of event listeners.
   */
  private isRestrictedEvent(eventListeners: EventListeners) {
    if (this.allListenersDisabled) {
      return true;
    }

    return this.disabledListeners.includes(eventListeners);
  }

  /**
   * Enabling listener if it was in disabled.
   * @param eventListener Type of host listener.
   */
  protected enableListener(eventListener: EventListeners) {
    if (!this.disabledListeners.includes(eventListener)) {
      return;
    }

    const cloned = [];

    this.disabledListeners.forEach(item => {
      if (item === eventListener) {
        return;
      }

      cloned.push(item);
    });

    this.disabledListeners = cloned;
  }

  /**
   * Changing strategy of change listening.
   * @param disable Flag for disable propagation from host listeners.
   */
  protected changeListenersState(disable: boolean) {
    this.allListenersDisabled = disable;
  }

  public ngOnInit(): void {
    this.changeListenersState(this.turnOffListeners);
    this.setEmptyValuePropagation(this.disableEmptyValuePropagation);
  }
}

/**
 * Types of events of host listener.
 */
export type EventListeners = ('change' | 'input' | 'checked');
