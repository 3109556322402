import { Injectable } from '@angular/core';

import { MerchantDto } from '../dtos/merchant.dto';
import divisionMapper from './division.mapper';
import { Merchant, Address, Schedule, CovidOptions } from '../../../models/public_api';
import { Mapper } from '../../abstract/mapper';

import { OperationHoursDto } from '../../../../campaign/services/campaign-service/dto/operation-hours-dto';

/**
 * Merchant mapper.
 */
@Injectable()
export class MerchantMapper implements Mapper<MerchantDto, Merchant> {
  /**
   * @inheritdoc
   * @param {MerchantDto} dto Merchant DTO.
   */
  public mapToModel(dto: MerchantDto): Merchant {
    const merchant = new Merchant({
      id: dto.id,
      name: dto.name,
      dbaName: dto.name_dba,
      description: dto.description,
      categoryIds: dto.categories,
      address: new Address({
        city: dto.address_info.city,
        street: dto.address_info.street,
        state: dto.address_info.state,
        country: dto.address_info.country,
        zip: dto.address_info.zip_code,
      }),
      covidOptions: new CovidOptions({
        enabled: dto.covid_options ? dto.covid_options.enabled : false,
        type: dto.covid_options ? dto.covid_options.type : "U",
        url: dto.covid_options ? dto.covid_options.url : "",
        instructions: dto.covid_options ? dto.covid_options.instructions : "",
        extra_instructions: dto.covid_options ? dto.covid_options.extra_instructions : ""
      }),
      contact: dto.contact,
      contactName: dto.contact_title,
      email: dto.email,
      phone: dto.phone,
      country_calling_code: dto.country_calling_code,
      socialUrls: dto.social_urls,
      images: dto.images,
      operationHours: {
        monday: {
          start: dto.operation_hours ?
            dto.operation_hours.monday_start : null,
          end: dto.operation_hours ?
            dto.operation_hours.monday_end : null,
        },
        tuesday: {
          start: dto.operation_hours ?
            dto.operation_hours.tuesday_start : null,
          end: dto.operation_hours ?
            dto.operation_hours.tuesday_end : null,
        },
        wednesday: {
          start: dto.operation_hours ?
            dto.operation_hours.wednesday_start : null,
          end: dto.operation_hours ?
            dto.operation_hours.wednesday_end : null,
        },
        thursday: {
          start: dto.operation_hours ?
            dto.operation_hours.thursday_start : null,
          end: dto.operation_hours ?
            dto.operation_hours.thursday_end : null,
        },
        friday: {
          start: dto.operation_hours ?
            dto.operation_hours.friday_start : null,
          end: dto.operation_hours ?
            dto.operation_hours.friday_end : null,
        },
        saturday: {
          start: dto.operation_hours ?
            dto.operation_hours.saturday_start : null,
          end: dto.operation_hours ?
            dto.operation_hours.saturday_end : null,
        },
        sunday: {
          start: dto.operation_hours ?
            dto.operation_hours.sunday_start : null,
          end: dto.operation_hours ?
            dto.operation_hours.sunday_end : null,
        },
      },
      url: dto.url,
      reviews_url: dto.reviews_url,
      logo: dto.logo,
      logoBackground: dto.logo_background,
      keywords: dto.keywords.join(', '),
      freeForm: dto.free_form,
      creationCompleted: dto.creation_completed,
      paymentDue: dto.payment_due,
      invoiceAmount: dto.invoice_amount,
      forcePayment: dto.force_payment,
      personalId: dto.personal_id,
      referral: dto.referral,
      secondaryContact: dto.secondary_contact,
      secondaryContactName: dto.secondary_contact_title,
      secondaryEmail: dto.secondary_email,
      secondaryPhone: dto.secondary_phone,
      isActive: dto.is_active,
      isAutoHeartEnabled: dto.auto_heart_enabled,
      isNonAutoHeartDashboard: dto.non_auto_heart_dashboard,
      isHolding: dto.is_holding,
      parentMerchant: dto.is_division_of,
      divisions: divisionMapper(dto.divisions),
      parentName: dto.is_division_of_name,
      app: dto.app,
      webapps: dto.webapps
    });
    return merchant;
  }

  private cutHours(operationHours: object) {
    if (operationHours === null || Object.keys(operationHours).length === 0) {
      return operationHours;
    }

    for (const prop in operationHours) {
      if (operationHours.hasOwnProperty(prop)) {
        if (typeof operationHours[prop] === 'string') {
          // Cut the string from the end '00:00:00' -> '00:00'
          operationHours[prop] = operationHours[prop].match(/\d\d:\d\d/)[0];
        }
      }
    }

    return operationHours;
  }

  /**
   * Convert the model schedule to the DTO operation hours.
   * @param schedule Schedule from target-marketing model.
   */
  public operationHoursMapper(schedule: Schedule): OperationHoursDto {
    const operationHours: OperationHoursDto = {
      monday_start: null,
      monday_end: null,
      tuesday_start: null,
      tuesday_end: null,
      wednesday_start: null,
      wednesday_end: null,
      thursday_start: null,
      thursday_end: null,
      friday_start: null,
      friday_end: null,
      saturday_start: null,
      saturday_end: null,
      sunday_start: null,
      sunday_end: null,
    };

    for (const day in schedule) {
      if (schedule.hasOwnProperty(day)) {
        const startTime = `${day}_start`;
        const endTime = `${day}_end`;
        if (schedule[day].start && schedule[day].end) {
          operationHours[startTime] = schedule[day].start;
          operationHours[endTime] = schedule[day].end;
        }
      }
    }

    return operationHours;
  }
}
