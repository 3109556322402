import { Observable } from 'rxjs/Rx';
import { ServerErrorDto, ServerErrorMapper } from '../error-handler/public_api';


import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { TokenStorageService } from '../token-storage.service';
import { AuthService } from '../auth/auth.service';
import { PromotionMapper } from './mappers/promotion.mapper';
import { TimeInclude } from '../time-inclusion.enum';
import { MerchantsPromotion } from './models/merchantsPromotion';
import { PromotionDto, PromotionsDto } from './dtos/promotion.dto';
import { ServerError } from '../../models/server-error';




const MERCHANT_URL_PART = 'merchants';
const PROMOTIONS_URL_PART = 'promotions';

/**
 * Service for Merchant's promotions.
 */
@Injectable()
export class PromotionsService {

    /**
    * .ctor
    */
    constructor(
        private http: Http,
        private tokenService: TokenStorageService,
        private userService: AuthService,
        private promotionMapper: PromotionMapper,
        private serverErrorMapper: ServerErrorMapper) {
    }

    /**
     * Returns promotions which belongs to merchant.
     * @param merchantId Id of merchant
     */
    public async getPromotions(
        merchantId: number,
        startDate: Date,
        endDate: Date,
        locationIds: string[] = null,
        timeInclude: TimeInclude = TimeInclude.None): Promise<MerchantsPromotion[]> {

        let startDateString = moment(startDate).format('YYYY-MM-DD');
        let startDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD');
        let endDateString = moment(endDate).format('YYYY-MM-DD');

        let queryString = '';
        if (timeInclude === TimeInclude.None) {
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}` +
              `/?start_lte=${endDateString}&end_gte=${startDateString}`;
        } else if (timeInclude === TimeInclude.End) {
            startDateString = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
            endDateString = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
            startDatePlus = moment(startDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}` +
              `/?end_gte=${startDateString}&end_lte=${startDatePlus}&start_lte=${startDateString}`;
        } else if (timeInclude === TimeInclude.Start) {
            queryString = `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}` +
              `/?start_gte=${startDateString}&start_lte=${startDatePlus}&end_gte=${startDateString}`;
        }

        if (locationIds && locationIds.length > 0) {
            queryString += '&locations=' + locationIds;
        }

        return await this.http.get(
            queryString,
            { headers: this.tokenService.baseHeaders })
            .map(r => r.json() as PromotionsDto)
            .map(items => items.results.map(i => this.promotionMapper.mapToModel(i)))
            .toPromise();
    }

    /**
     * Retrieving from remote promotion.
     * @param merchantId Id of merchant.
     * @param promotionId Id of promotion.
     */
    public getPromotion(merchantId: number, promotionId: number) {

        return this.http.get(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}/${promotionId}/`,
            { headers: this.tokenService.baseHeaders })
            .map(r => r.json() as PromotionDto)
            .map(r => this.promotionMapper.mapToModel(r))
            .toPromise();
    }

    /**
     * Adding promotion to remote endpoint.
     * @param promotion Promotion which will be added.
     */
    public addPromotion(promotion: MerchantsPromotion): Promise<MerchantsPromotion | ServerError> {
        const dto = this.promotionMapper.mapToDto(promotion);

        return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${dto.merchantId}/${PROMOTIONS_URL_PART}/`,
            dto, { headers: this.tokenService.baseHeaders })
            .map(r => {
                const response = r.json() as PromotionDto;
                const model = new MerchantsPromotion();
                Object.assign(model, response);
                return model;
            })
            .catch(error => {
                const errorDto = error.json() as ServerErrorDto;
                return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
            })
            .toPromise();
    }

    /**
     * Patching promotion.
     * @param promotion Promotion which will be patched.
     */
    public patchPromotion(promotion: MerchantsPromotion): Promise<MerchantsPromotion | ServerError> {
        const dto = this.promotionMapper.mapToDto(promotion);

        return this.http.patch(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${dto.merchantId}/${PROMOTIONS_URL_PART}/${promotion.id}/`,
            dto, { headers: this.tokenService.baseHeaders })
            .map(r => {
                const response = r.json() as PromotionDto;
                const model = new MerchantsPromotion();
                Object.assign(model, response);
                return model;
            })
            .catch(error => {
                const errorDto = error.json() as ServerErrorDto;
                return Observable.of(this.serverErrorMapper.mapToModel(errorDto));
            })
            .toPromise();
    }

    /**
     * Uploading logo to server.
     * @param logo Logo for promotion.
     * @param merchantId Id of merchant for promotion.
     * @param promotionId Id of promotion for update logo.
     */
    public updateLogo(logo: Blob, merchantId: number, promotionId: number, image: Blob = null): Promise<{ logo: string }> {
        const formData = new FormData();
        if (logo) {
            formData.append('logo', logo);
        }
        if (image !== null) {
            formData.append('image', image);
        }

        return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}/${promotionId}/upload/`,
            formData, { headers: this.tokenService.baseHeaders })
            .map(r => r.json() as { logo: string })
            .toPromise();
    }

    /**
     * Deleting logo from merchant's promotion.
     * @param merchantId Id of merchant for promotion.
     * @param promotion Id of promotion for merchant.
     */
    public deleteLogo(merchantId: number, promotion: number): Promise<void> {
        // tslint:disable-next-line:max-line-length
        return this.http.delete(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}/${promotion}/clear_logo/`,
            { headers: this.tokenService.baseHeaders })
            .map(r => null)
            .toPromise();
    }

    /**
     * Changing image for promotion to category's image.
     * @param merchantId Id of merchant for promotion.
     * @param promotionId Id of promotion to update.
     * @param categoryId Id of category for use his image.
     */
    public useCategoryImage(merchantId: number, promotionId: number, categoryId: string): Promise<void> {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}/${promotionId}/use_category/`,
            { 'category_id': categoryId }, { headers: this.tokenService.baseHeaders })
            .map(r => null)
            .toPromise();
    }

    /**
     * Ending promoution by sending patch with back time.
     * @param merchantId Id of merchant.
     * @param promotionId Id of promoution.
     * @param date date for end of promoution.
     */
    public endPromotion(merchantId: number, promotionId: number, date: string) {
        return this.http.patch(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}/${promotionId}/`,
            {
                end: date
            },
            { headers: this.tokenService.baseHeaders })
            .map((v) => null)
            .toPromise();
    }

    /**
     * Ending promoution by sending patch with back time.
     * @param merchantId Id of merchant.
     * @param promotionId Id of promoution.
     * @param date date for end of promoution.
     */
    public clearImage(merchantId: number, promotionId: number) {
        return this.http.patch(`${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}/${promotionId}/`,
            {
                image: null
            },
            { headers: this.tokenService.baseHeaders })
            .map((v) => null)
            .toPromise();
    }

    /**
     * Deleting image for promotion.
     * @param merchantId Id of merchant.
     * @param promotionId Id of promotion.
     * @param logo Flag for delete logo.
     * @param image Flag for delete image.
     */
    public deleteImages(merchantId: number, promotionId: number, logo: boolean, image: boolean): Promise<void> {

        return this.http.delete(
            `${environment.apiEndpoint}/${MERCHANT_URL_PART}/${merchantId}/${PROMOTIONS_URL_PART}/${promotionId}/delete_images/`,
            {
                headers: this.tokenService.baseHeaders, body: {
                    logo: logo,
                    image: image
                }
            })
            .map((v) => null)
            .toPromise();
    }
}
