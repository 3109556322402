
import * as moment from 'moment';

import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';

/**
 * Validation directive for validate date on null/undefined and date in future.
 */
@Directive({
    selector: '[pbRequiredDateValidation]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => RequiredDateValidationDirective), multi: true }]
})
export class RequiredDateValidationDirective implements Validator {

    public validate(c: AbstractControl): ValidationErrors | null {
        if (!c.value) {
            return { required: true };
        }

        return moment().isBefore(c.value) ? { required: true } : null;
    }
}
