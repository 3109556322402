import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import {LandingPageDiscoverLIComponent} from './landing-page-discoverli.component';
import { RegistrationBrandsModule } from '../registration-brands/registration-brands.module';
import { LoginPageModule } from '../login-page/login-page.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    LoginPageModule,
    RegistrationBrandsModule
  ],
  declarations: [
    LandingPageDiscoverLIComponent
  ],
  exports: [
    LandingPageDiscoverLIComponent
  ]
})
export class LandingPageDiscoverLIModule { }
