import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { merge } from 'rxjs/observable/merge';
import { takeUntil, filter, map, take } from 'rxjs/operators';

import { WorkDayVm as WorkDay, WorkDayVm } from './models/work-day';
import { DayVm as Day } from './models/day';

import { Schedule } from '../../../core';

const WEEK_ARR = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

/**
/**
 * Work Schedule.
 */
@Component({
  selector: 'pb-work-schedule',
  templateUrl: './work-schedule.component.html',
  styleUrls: ['./work-schedule.component.scss']
})
export class WorkScheduleComponent implements OnInit, OnDestroy {
  /**
   * Destroy subject.
   */
  private destroy$ = new Subject();

  /**
   * Campaign schedule.
   */
  @Input()
  public schedule$: BehaviorSubject<any>;

  /**
   * Need for set class 'active'.
   */
  @Input()
  public isActive = true;

  /**
   * Type of schedule view.
   */
  @Input()
  public isBasicView = true;

  /**
   * Emit shedule.
   */
  @Output()
  public changeTime = new EventEmitter();

  /**
   * Workday for a basic view of schedule.
   */
  public basicSchedule = new WorkDay('mon');

  /**
   * Workday for a advanced view of schedule.
   */
  public advancedSchedule = [
    new WorkDay('mon'),
    new WorkDay('tue'),
    new WorkDay('wed'),
    new WorkDay('thu'),
    new WorkDay('fri'),
    new WorkDay('sat'),
    new WorkDay('sun')
  ];

  /**
   * @inheritdoc
   * Create two subscriptions but emit only one
   * depending on the type of schedule: basic or advanced.
   */
  public ngOnInit(): void {
    this.schedule$
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        map((schedule: Schedule) => this.defineScheduleTime(schedule)),
      )
      .subscribe();

    this.basicSchedule.changeTime$
      .pipe(
        filter(() => this.isBasicView),
        takeUntil(this.destroy$)
      )
      .subscribe((day: Day) => this.createWeekSchedule(day));

    combineLatest(...this.advancedSchedule.map(d => d.changeTime$))
      .pipe(
        filter(() => !this.isBasicView),
        takeUntil(this.destroy$)
      )
      .subscribe((days: Day[]) => this.createWeekScheduleByDay(days));

    this.basicSchedule.showTimePickerChange$
      .pipe(
        filter(() => this.isBasicView),
        takeUntil(this.destroy$)
      )
      .subscribe(({ time }) => this.handleTimePickerBasic(time));

    merge(...this.advancedSchedule.map(d => d.showTimePickerChange$))
      .pipe(
        filter(() => !this.isBasicView),
        takeUntil(this.destroy$)
      )
      .subscribe(({ day, time }) => this.handleTimePickerAdvanced(day, time));
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Define schedule type and set a time.
   * @param schedule Schedule from target marketing model.
   */
  private defineScheduleTime(schedule: Schedule): void {
    // return 'true' if some value is 'null' or 'undefined'
    const isDifferentTime = Object.keys(schedule).some(k => schedule[k].start !== null);

    if (isDifferentTime) {
      Object.keys(schedule).forEach((key, i) => {
        const isNoTime = schedule[key].start != null && schedule[key].end != null;
        if (isNoTime) {
          this.advancedSchedule[i].isActive = true;
          this.advancedSchedule[i].start = schedule[key].start;
          this.advancedSchedule[i].end = schedule[key].end;
        }
      });
    } else {
      this.basicSchedule.start = schedule.monday.start;
      this.basicSchedule.end = schedule.monday.end;
    }
  }

  /**
   * Create a work schedule for simple schedule and emit this to parent.
   * @param workday Workday.
   */
  private createWeekSchedule(workday: Day): void {
    const schedule = WEEK_ARR.reduce((week, day) => {
      week[day] = {
        start: workday.start,
        end: workday.end,
      };

      return week;
    }, {});

    this.changeTime.emit(schedule);
  }

  /**
   * Create a work schedule for advanced schedule and emit this to parent.
   * @param workdays Array of days.
   */
  private createWeekScheduleByDay(workdays: Day[]): void {
    const schedule = WEEK_ARR.reduce((week, day) => {
      const { name, start, end, isActive } = workdays.find(workday => workday.name === day);

      if (isActive) {
        week[name] = { start, end };

        return week;
      }
      week[name] = { start: undefined, end: undefined };

      return week;
    }, {});

    this.changeTime.emit(schedule);
  }

  /**
   * Switching a timepicker in basic schedule view.
   * @param time Start or end.
   */
  private handleTimePickerBasic(time: string): void {
    if (time === 'start') {
      this.basicSchedule.end.showTimePicker = false;
    } else {
      this.basicSchedule.start.showTimePicker = false;
    }
  }

  /**
   * Switching a timepicker in advanced schedule view.
   * @param dayName Short day name.
   * @param time Start or end.
   */
  private handleTimePickerAdvanced(dayName: string, time: string): void {
    this.advancedSchedule.forEach((day: WorkDayVm) => {
      if (day.name === dayName) {
        if (time === 'start') {
          day.end.showTimePicker = false;
        } else {
          day.start.showTimePicker = false;
        }
      } else {
        day.end.showTimePicker = false;
        day.start.showTimePicker = false;
      }
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    let targetClass = event.target.className;
    if (targetClass && targetClass !== 'time-range-button'
      && targetClass !== 'select ng-untouched ng-pristine ng-valid') {
      this.advancedSchedule.forEach((day: WorkDayVm) => {
        day.end.showTimePicker = false;
        day.start.showTimePicker = false;
      });
    }
  }

  public selectAllDays(): void {
    this.advancedSchedule.forEach((day: WorkDayVm) => {
      day.activateDay(true);
    });
  }
}
