import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { NewAutoMessageDto } from '../dtos/new-auto-message.dto';
import { NewAutoMessage } from '../../../models/new-auto-message';

/**
 * New message mapper.
 */
@Injectable()
export class NewAutoMessageMapper implements Mapper<NewAutoMessageDto, NewAutoMessage> {
  /**
   * @inheritdoc
   * @param {NewAutoMessageDto} dto NewAutoMessage DTO.
   */
  public mapToModel(dto: NewAutoMessageDto): NewAutoMessage {
    const model = new NewAutoMessage({
      id: dto.id,
      title: dto.title,
      message: dto.message,
      video: dto.video,
      image: dto.image,
      actionText: dto.action_text || '',
      actionURL: dto.action_url || '',
      actionEmail: dto.action_email || '',
      actionPhone: dto.action_phone || '',
      isConcierge: dto.is_concierge,
      created: dto.created,
      auto_time_delta: dto.auto_time_delta,
      is_enabled: dto.is_enabled,
      audienceCount: dto.audience_count
    });

    return model;
  }
}
