import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {
  AuthService, BrandMapper,
  BrandService,
  ErrorHandlerService,
  HttpStatusCode,
  ServerErrorMapper,
  VALIDATION_ERROR
} from '../core';
import {NgForm} from '@angular/forms';
import {Observable} from 'rxjs';
import {PopupService} from '../popup';
import {MerchantApp} from '../core/models/merchant-app';
import {Title} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";

const DELAY_BEFORE_SETTING_FIELDS_IN_INVALID = 1000;
const BRAND_NAME = 'exploreli';

/**
 * Login page component.
 */
@Component({
  selector: 'pb-login-page-combined',
  templateUrl: './landing-page-exploreli.component.html',
  styleUrls: ['./landing-page-exploreli.component.scss']
})
export class LandingPageExploreliComponent implements OnInit {

  @Input()
  public brandName = 'GettinLocal';

  @Output()
  public onFocus: EventEmitter<boolean> = new EventEmitter();
  /**
   * Email input.
   */
  public email: string;
  public email2: string;

  /**
   * Password input.
   */
  public password: string;
  public referral = '';

  /**
   * If a verification message must be shown.
   */
  public showMessage = false;
  public promoCode: string;
  public brand: MerchantApp = new MerchantApp({id: 'exploreli', name: 'Explore LI'});

  /**
   * .ctor
   * @param {Router} router Router.
   * @param {AuthService} authService Authorization service.
   */
  constructor(private router: Router,
              @Inject(DOCUMENT) private _document: HTMLDocument,
              private route: ActivatedRoute,
              private popupService: PopupService,
              private brandMapper: BrandMapper,
              private brandService: BrandService,
              private titleService: Title,
              private errorHandlerService: ErrorHandlerService,
              private serverErrorMapper: ServerErrorMapper,
              private authService: AuthService) {

    this.route.queryParams.subscribe(params => {
      if (params['ref']) {
        this.referral = params['ref'];
      }
      if (params['code']) {
        this.promoCode = params['code'];
      }
    });

    this.brandService.brand.subscribe((b) => {
      this.brand = b;
      this.titleService.setTitle(this.brand.title);
      this._document.getElementById('appFavicon').setAttribute('href', this.brand.favicon);
    });
  }

  public ngOnInit() {
    try {
      this.authService.me().then(u => {
        this.router.navigate(['/dashboard']);
      });
    } catch (e) {
    }

    this.brandService.getBrand(BRAND_NAME)
      .then(result => {
        this.brandService.brand.next(this.brandMapper.mapToModel(result));
      })
      .catch(err => {
        // this.router.navigate(['/login']);
      });

  }

  public errorEmitter = new EventEmitter<any>();

  private createErrorObject(errors: { field: string, errors: string }[]) {
    const validationErrors = [];
    errors.forEach(error => {
      validationErrors.push({field: error.field, errors: [error.errors]});
    });

    return validationErrors;
  }

  /**
   * Setup form as invalid.
   */
  private setFieldsIsNotValid(form: NgForm, emailErrors: any = null, passwordErrors: any = null) {
    setTimeout(() => {
      const email = form.controls['email'];
      email.setValue(this.email);
      email.markAsDirty();
      if (emailErrors) {
        email.setErrors([emailErrors]);
      }

      const password = form.controls['password'];
      password.setValue(this.password);
      password.markAsDirty();
      if (passwordErrors) {
        password.setErrors([passwordErrors]);
      }

    }, DELAY_BEFORE_SETTING_FIELDS_IN_INVALID);
  }

  /**
   * Seeding error object with body which seeded with errors field.
   */
  private createLoginFormError(body: any, error: any, emailMessage: string, passwordMessage: string) {

    body.validation_errors = this.createErrorObject(
      [
        {field: 'email2', errors: emailMessage},
        {field: 'password', errors: body.detail}
      ]
    );
    if (!error.code) {
      error.code = VALIDATION_ERROR;
      error.detail = VALIDATION_ERROR;
    }
    error._body = JSON.stringify(body);
  }

  /**
   * On submit event handler.
   * @param {NgForm} form Angular form.
   */
  public async onSubmitLogin(form: NgForm): Promise<void> {
    const spinner = this.popupService.spinner();
    if (form.valid) {
      try {
        await this.authService.login(this.email2, this.password);
        const sub = this.authService.retrieveCurrentUser()
          .catch(error => {
            const json = error.json();
            this.setFieldsIsNotValid(form, json.detail, json.detail);
            return Observable.of(null);
          })
          .subscribe(user => {
            if (sub) {
              sub.unsubscribe();
            }
            if (user) {
              if (user.emailConfirmed)
                this.router.navigate([this.authService.redirectUrl || '/dashboard']);
              else
                this.popupService.info_sticky('Error: This email address provided must be confirmed by clicking the verify link. Please check your email.');
            }
          });
      } catch (error) {
        const body = error.json();
        // Manual validation.
        if (error.status && error.status === HttpStatusCode.BAD_REQUEST) {
          this.createLoginFormError(body, error, body.detail, body.detail);
          this.setFieldsIsNotValid(form);
        }

        this.errorEmitter.emit(error);
      }
    } else {
      const emailControl = form.controls['email2'];
      if (emailControl.errors) {
        emailControl.setErrors(['This field is required']);
      }
      const passwordControl = form.controls['password'];
      if (passwordControl.errors) {
        passwordControl.setErrors(['This field is required']);
      }
    }
    spinner.close();
  }

  private makepass(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * On submit event handler.
   * @param {NgForm} form Angular form.
   */
  public async onSubmitSignup(form: NgForm): Promise<void> {
    const spinner = this.popupService.spinner();
    if (form.valid) {
      const passwd = this.makepass(16);
      try {
        await this.authService.registerBrandAccount(
          this.email,
          passwd,
          passwd,
          this.brand.id,
          this.referral,
          this.promoCode
        );
        this.router.navigate(['/profile']);
      } catch (error) {
        spinner.close();

        const body = JSON.parse(error._body);
        const serverError = this.serverErrorMapper.mapToModel(body);
        if (this.errorHandlerService.hasValidationErrors(serverError)) {
          const validationFieldsMap = {
            email: 'email',
            password1: 'password1',
            password2: 'password2',
            referral: 'referral',
          };

          this.errorHandlerService.invalidateForm(
            form,
            validationFieldsMap,
            serverError,
          );
        } else {
          const emailControl = form.controls['email'];
          emailControl.setErrors([serverError.detail]);
        }
      }
    } else {
      const emailControl = form.controls['email'];
      if(emailControl.errors.required) {
        emailControl.setErrors(['This field is required']);
      }
    }
    spinner.close();
  }

  public focus() {
    this.onFocus.emit(true);
  }

  public scrollToForm() {
    document.getElementsByClassName('exploreli-form')[0].scrollIntoView({
      behavior: 'smooth'});
  }
}
