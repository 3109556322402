import {Component, OnInit} from '@angular/core';
import {MdDialog} from '@angular/material';

import {AuthService, Category, MerchantsCoupon, PlaceService} from '../../core';

import {ActivatedRoute, Router} from '@angular/router';
import {PopupService} from '../../popup';
import {WebappService} from '../../core/services/webapp/webapp.service';
import {Observable} from "rxjs/Observable";
import {Subscription} from "rxjs/Subscription";
import {WebAppFeaturedCoupon} from "../../core/services/webapp/models/webapp-featured-coupon";
import {Tree} from "@angular/router/src/utils/tree";


/**
 * Locations page component.
 */
@Component({
  selector: 'pb-webapp-coupons-page',
  templateUrl: './featured-coupons-page.component.html',
  styleUrls: ['./featured-coupons-page.component.scss']
})
export class WebAppFeaturedCouponsPageComponent implements OnInit {

  /**
   * .ctor
   * @param {AuthService} authService Auth service.
   * @param activatedRoute
   * @param {PlaceService} placeService Place service.
   * @param webappService
   * @param dialog
   * @param router
   * @param dragulaService
   */
  constructor(private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private placeService: PlaceService,
              private webappService: WebappService,
              private dialog: MdDialog,
              private router: Router,
              private popupService: PopupService) {
  }

  /**
   * Webapps list.
   */
  public coupons: WebAppFeaturedCoupon[] = [];
  public total: number = 0;

  /**
   * Current merchant Id.
   */
  private merchantId: number;
  public webAppId: string;
  private routeSub: Subscription;
  public appFilters: Category[] = [];
  public draggedIndex: number;
  draggingIndex: number | null = null;

  /**
   * @inheritdoc
   */
  public async ngOnInit(): Promise<void> {
    this.routeSub = Observable.combineLatest(this.activatedRoute.paramMap, this.authService.onUserChanged).subscribe(async tuple => {
      const [params, user] = tuple;
      if (user) {
        this.merchantId = user.merchantId;

        this.webAppId = params.get('id');
        if (this.webAppId) {
          this.fetchFilters();
        }
      }
      if (this.routeSub) {
        this.routeSub.unsubscribe();
      }
    });

    const userSubscription = this.authService.onUserChanged.subscribe(async result => {
      const user = result;
      if (user) {
        this.merchantId = user.merchantId;
        if (userSubscription) {
          userSubscription.unsubscribe();
        }
      }
    });
  }

  fetchFilters() {
    this.webappService.getWebAppFilters(this.webAppId).then(data => {
      this.appFilters = data;
      this.fetchCoupons();
    });
  }

  private fetchCoupons(): void {
    const defaultFilter = this.appFilters[0];
    this.webappService.getFeaturedCoupons2(this.webAppId, defaultFilter.id).then(coupons => {
      this.coupons = coupons;
    });
  }

  private updateOrder(no_msg: boolean) {
    this.coupons.forEach((coupon, index) => {
      coupon.order = index + 1;
    });

    this.webappService.updateFeaturedCouponsOrder(this.webAppId, this.coupons).then(() => {
      if (!no_msg) {
        this.popupService.info('Listing updated.');
      }
    });
  }


  public toggleFeatured(coupon: WebAppFeaturedCoupon) {
    this.webappService.toggleFeatured(coupon.id, coupon.coupon, this.webAppId).then(() => {
      this.popupService.info('Listing updated.');

      this.coupons = this.coupons.filter(c => c.id !== coupon.id);
      this.total = this.total - 1;
    });
  }

  filterChanged(event) {
    this.webappService.getFeaturedCoupons2(this.webAppId, event.target.value).then(coupons => {
      this.coupons = coupons;
    });
  }

  dragStart(event: DragEvent, index: number) {
    this.draggedIndex = index;
    event.dataTransfer.setData('text', index.toString());
    setTimeout(() => {
      const row = event.target as HTMLElement;
      row.classList.add('dragging');
    }, 0);
  }

  allowDrop(event: DragEvent) {
    event.preventDefault();
  }

  drop(event: DragEvent, index: number) {
    event.preventDefault();
    const draggedIndex = this.draggedIndex;
    if (draggedIndex !== index) {
      const draggedItem = this.coupons[draggedIndex];
      this.coupons.splice(draggedIndex, 1);
      this.coupons.splice(index, 0, draggedItem);
    }
    this.clearDraggingClasses();
    this.updateOrder(false);
  }

  dragEnter(event: DragEvent, index: number) {
    event.preventDefault();
    if (this.draggingIndex !== null && this.draggingIndex !== index) {
      this.reorderItems(this.draggingIndex, index);
      this.draggingIndex = index;
      const row = event.target as HTMLElement;
      row.classList.add('drag-over');
    }
  }

  dragLeave(event: DragEvent) {
    event.preventDefault();
    const row = event.target as HTMLElement;
    row.classList.remove('drag-over');
  }

  reorderItems(fromIndex: number, toIndex: number) {
    const movedItem = this.coupons[fromIndex];
    this.coupons.splice(fromIndex, 1);
    this.coupons.splice(toIndex, 0, movedItem);
  }

  clearDraggingClasses() {
    const rows = document.querySelectorAll('tr[draggable="true"]');
    Array.from(rows).forEach(row => {
      row.classList.remove('dragging', 'drag-over');
    });
  }

  moveUp(index: number) {
    if (index > 0) {
      [this.coupons[index], this.coupons[index - 1]] = [this.coupons[index - 1], this.coupons[index]];
    }
    this.updateOrder(true);
  }

  moveDown(index: number) {
    if (index < this.coupons.length - 1) {
      [this.coupons[index], this.coupons[index + 1]] = [this.coupons[index + 1], this.coupons[index]];
    }
    this.updateOrder(true);
  }
}

