<form>
  <section pb-section caption="Category selection">
    <div body>
        <div class="row" *ngIf="!isBusy">
            <div class="category-row" *ngFor="let control of controls; let i = index;">
              <pb-select [turnOffListeners]="true" [disableEmptyValuePropagation]="true" name="businessType{{i}}" [label]="getBusinessTypeLabel(i)"
                [required]="i === 0" (ngModelChange)="categoryChanged({event: $event, index: i})" [(ngModel)]="selectedCategories[i]"
                [placeholder]="getBusinessTypePlaceholder(i)" class="element">
                <option [value]="category.id" *ngFor="let category of categoriesChild[i]">{{category.name}}</option>
              </pb-select>
            </div>
          </div>
          <br/>
          <div class="row add-row" *ngIf="!isBusy">
              <button pb-button green type="button" class="button" (click)="addCategory()" [disabled]="isValidSelectedCategories()">Add Category</button>
              <button pb-button white type="button" class="button" (click)="close()">Close</button>
          </div>
    </div>
  </section>
</form>

