import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { CardInfo } from '../../../models/card-info';
import { CardInfoDto } from '../dtos/card-info.dto';


/**
 * Mapper for Card info.
 */
@Injectable()
export class CardInfoMapper implements Mapper<CardInfoDto, CardInfo> {

    public mapToModel(dto: CardInfoDto): CardInfo {
        throw new Error('Not implemented yet.');
    }

    public mapToDto(model: CardInfo): CardInfoDto {
        return {
            payment_method_nonce: model.nonce,
            billing_address: {
                street_address: model.billingAddress.streetAddress,
                locality: model.billingAddress.locality,
                region: model.billingAddress.region,
                country_name: model.billingAddress.country_name
            },
            cardholder_name: model.cardholderName
        } as CardInfoDto;
    }
}
