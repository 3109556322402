import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, } from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {catchError} from 'rxjs/operators';

import {CampaignStyleVm} from '../campaign-style/campaign-style';
import {RedemptionOptionsVm} from '../redemption-options/redemption-options';
import {PreviewCouponVm} from './preview-coupon';
import {AddressDto} from '../../core/services/merchant/dtos/address.dto';
import {getImageDimensions, } from '../../../utils';
import {Plans} from '../../core/models/plans';
import {CampaignDataService} from '../services/campaign-data.service';
import {TargetMarketingVm} from '../target-marketing/target-marketing';
import {BrandService, CovidOptions, Subscription} from '../../core';
import {MerchantApp} from '../../core/models/merchant-app';

@Component({
  selector: 'pb-preview-coupon',
  templateUrl: './preview-coupon.component.html',
  styleUrls: ['./preview-coupon.component.scss', './devices.scss'],
})
export class PreviewCouponComponent implements OnChanges {
  private prieviewCopuponImage$ = new BehaviorSubject<CampaignStyleVm>(
    new CampaignStyleVm()
  );

  public premiumStateCount = 0;
  public premiumEmailStateCount = 0;

  public isLogoRect = false;

  public mainImage = true;

  public days = 'X';

  public cost: { was: string; is: string; show: boolean };

  public price$: Observable<{ was: string; is: string; show: boolean }>;

  @Input()
  public previewCoupon: PreviewCouponVm;

  public campaignPlan: Plans;

  @Output() upgradePlan = new EventEmitter();

  @Input()
  public logo = '';

  @Input()
  public isEdit: number = null;

  @Input()
  public dbaName = '';

  @Input()
  public covidOptions = new CovidOptions();

  @Input()
  public Address: AddressDto;

  @Input()
  public subscription: Subscription;

  @Input()
  public previewCouponImage: CampaignStyleVm;

  @Input()
  public previewCouponVideo: CampaignStyleVm;

  @Input()
  public redemptionOptions: RedemptionOptionsVm;

  @Input()
  public targetMarketing: TargetMarketingVm;

  public badAspectRatio = false;

  public planComparison = [
    {
      text: 'Featured Campaign Placement',
    },
    {
      text: 'Include a e-Commerce Link',
    },
    {
      text: 'Market in Other States/Provinces',
    },
    {
      text: 'Include a Coupon Code',
    },
  ];

  public brand: MerchantApp = new MerchantApp({
    id: 'gettinlocal',
    name: 'GettinLocal',
    show_pricing: true
  });

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    getImageDimensions(this.logo)
      .then((res) => {
        this.isLogoRect = res.w !== res.h;
      })
      .catch((error) => console.error(error));

    this.dataService.premium_state_count.subscribe((val) => {
      this.premiumStateCount = val;
    });
    this.dataService.premium_email_state_count.subscribe((val) => {
      this.premiumEmailStateCount = val;
    });
    this.dataService.campaign_plan.subscribe((plan) => {
      this.campaignPlan = plan;
    });
  }

  /**
   * Show only when is not an online coupon with custom coupon limit.
   */
  public get isStoreCouponLimitCountDisplay() {
    return (
      this.redemptionOptions.storeCouponLimit === 'custom' &&
      this.redemptionOptions.feature !== 'promotion' &&
      this.redemptionOptions.couponType !== 'online'
    );
  }

  public get campaignMainImage(): string {
    const previewImage = this.previewCouponImage.couponImage
      ? this.previewCouponImage.couponImage.logo
      : null;
    if (previewImage) {
      return previewImage;
      // return this.sanitizer.bypassSecurityTrustStyle(
      //   `background-image: url('${previewImage.logo}')`
      // );
    }

    const previewVideoImage = this.previewCouponVideo.couponVideo
      ? this.previewCouponVideo.couponVideo.video_thumb
      : null;
    if (previewVideoImage) {
      return previewVideoImage;
      // return this.sanitizer.bypassSecurityTrustStyle(
      //   `background-image: url('${previewVideoImage}')`
      // );
    }

    return ''; //this.sanitizer.bypassSecurityTrustStyle("background-color: #fff");
  }

  public get campaignLogo(): SafeStyle {
    const couponImage = this.previewCouponImage.couponImage;
    if (couponImage) {
      return this.sanitizer.bypassSecurityTrustStyle(
        `background-image: url('${couponImage.logo}')`
      );
    }

    return this.sanitizer.bypassSecurityTrustStyle('background-color: #fff');
  }

  constructor(
    private sanitizer: DomSanitizer,
    private brandService: BrandService,
    private dataService: CampaignDataService
  ) {
    this.cost = {
      was: '0',
      is: '0',
      show: false,
    };
    this.createPriceStream();
    this.brandService.brand.subscribe((b) => {
      this.brand = b;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.previewCouponImage && changes.previewCouponImage.currentValue) {
      this.prieviewCopuponImage$.next(changes.previewCouponImage.currentValue);
    }
  }

  private createPriceStream() {
    this.price$ = this.prieviewCopuponImage$
      .switchMap((image: CampaignStyleVm) => image.campaignStyleChange)
      .map((image: CampaignStyleVm) => {
        this.cost = {
          was: this.fixedPrice(this.isNaNCheck(image.priceLabelWas) || '0'),
          is: this.fixedPrice(this.isNaNCheck(image.priceLabelIs) || '0'),
          show: image.isShowPrice,
        };
        return this.cost;
      })
      .startWith({
        was: this.fixedPrice(this.isNaNCheck(this.cost.was)),
        is: this.fixedPrice(this.isNaNCheck(this.cost.is)),
        show: true,
      })
      .shareReplay(1)
      .pipe(
        catchError((error, c) => {
          return c;
        })
      );
  }

  /**
   * Fixed the price to two symbols.
   * @param price Price
   */
  public fixedPrice(price: string): string {
    // tslint:disable-next-line:no-magic-numbers
    return Number.parseFloat(price).toFixed(2);
  }

  /**
   * Check price for Nan value.
   * @param val
   */
  public isNaNCheck(val: string): string {
    if (isNaN(+val)) {
      return '0';
    }
    return val;
  }

  /**
   * Change the mode of preview of coupon.
   * @param mode Mode to display
   */
  public onTypeImageChange(mode: 'full' | 'main'): void {
    this.previewCoupon.mode = mode;
  }

  public upgradeToPremium(): void {
    // this.campaignPlanType = 'premium';
    this.upgradePlan.emit();
  }

  public getCustomLinkFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }

    if (this.campaignPlan.name === 'premium') {
      return 0.0;
    }

    if (!this.redemptionOptions.shopOptions.some(so => so.url_type.length > 0)) return 0.0;

    const linkFee = this.subscription ? this.subscription.link_rate : this.campaignPlan.custom_link_fee;

    const fee: number = +linkFee;
    return fee;
  }

  public getSubscriptionFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +this.campaignPlan.subscription_rate;
    return fee;
  }

  public getPremiumStatesFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }

    const stateFee = this.subscription ? this.subscription.state_rate : this.campaignPlan.premium_state_fee;
    if (this.campaignPlan.name === 'premium') {
      const fee: number = +(
        stateFee * (this.premiumStateCount - 1)
      );
      return fee;
    }

    const fee: number = +(
      stateFee * this.premiumStateCount
    );
    return fee;
  }

  public getPremiumEmailStatesFee(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    const fee: number = +(
      this.campaignPlan.premium_email_fee * this.premiumEmailStateCount
    );
    return fee;
  }

  public getTax(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }

    const tax_rate: number = +this.campaignPlan.tax_rate;
    const fee: number = this.getSubTotal();

    return +((tax_rate / 100) * fee).toFixed(2);
  }

  public getSubTotal(): number {
    if (!this.campaignPlan) {
      return 0.0;
    }
    return +(
      this.getSubscriptionFee() +
      this.getPremiumStatesFee() +
      this.getCustomLinkFee() +
      this.getPremiumEmailStatesFee()
    );
  }

  public getPlanCost(): number {
    if (!this.campaignPlan) {
      return 0;
    }
    return +this.getSubTotal().toFixed(2);
  }

  public isFreePlan(): boolean {
    if (!this.campaignPlan) {
      return true;
    }
    return this.campaignPlan.name === 'free';
  }

  public isUpgradable(): boolean {
    return this.brand.plan_switchable;
  }

  public isShopNowEnabled(url_type): boolean {
    const so = this.redemptionOptions.shopOptions.find(
      (s) => s.url_type === url_type
    );
    return so !== undefined;
  }

  public canShowPricing(): boolean {
    return this.brand.show_pricing;
  }
}
