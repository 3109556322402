<header pb-page-header>
  <div left>Coupon Display Settings</div>
  <div right>
    <div class="buttons" *ngIf="hideOnAll === false">
      <button pb-button small red type="button" [(ngModel)]="hideOnAll" (click)="toggleHideOnAll()" class="button">Hide
        on all filters
      </button>
    </div>
    <div class="buttons" *ngIf="hideOnAll === true">
      <button pb-button small green type="button" [(ngModel)]="hideOnAll" (click)="toggleHideOnAll()" class="button">Un
        hide
      </button>
    </div>
  </div>
</header>

<div *ngIf="hideOnAll === false; else empty">
  <p>
    Prioritize or hide the coupon <b>{{ couponName }}</b> in the app filters.
  </p>
  <table>
    <thead>
    <tr>
      <th>Filter</th>
      <th><input type="checkbox" [(ngModel)]="allPrioritized" (change)="toggleAll()"> Prioritize</th>
      <th><input type="checkbox" [(ngModel)]="allHidden" (change)="toggleAllHidden()"> Hide</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of settings">
      <td>{{ item.name }}</td>
      <td><input type="checkbox" [(ngModel)]="item.prioritized" (change)="toggleItem()"
                 [attr.disabled]="item.order === 0 ? true : null"></td>
      <td><input type="checkbox" (change)="toggleHideItem(item)" [checked]="item.order === 0"></td>
    </tr>
    </tbody>
  </table>

  <div class="row mt-50" style="display: flex; justify-content: flex-end; gap: 10px;">
    <button pb-button white small type="button" class="button" (click)="close()">Close</button>
    <button pb-button green small type="button" class="button" (click)="saveChanges()">Save</button>
  </div>
</div>

<ng-template #empty>
  <p>
    This coupon is hidden on all filters.
  </p>
  <div class="row mt-50" style="display: flex; justify-content: flex-end; gap: 10px;">
    <button pb-button white small type="button" class="button" (click)="close()">Close</button>
    <button pb-button green small type="button" class="button" (click)="saveChanges()">Save</button>
  </div>
</ng-template>
