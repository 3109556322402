import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pb-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss']
})
export class ComboboxComponent implements OnInit {
  @Input()
  public items: string[] = [];

  public activeItem: string;

  public isListOpened = false;

  public setActive(item) {
    this.activeItem = item;
    this.isListOpened = false;
  }

  public toggleList() {
    this.isListOpened = !this.isListOpened;
  }

  public ngOnInit() {
    this.activeItem = this.items[0];
  }

}
