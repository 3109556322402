<div class="info">
  <div class="hint">
    <div *ngIf="description">
      {{description}}
    </div>
    <div *ngIf="description && hint">
      <br />
    </div>
    <div *ngIf="hint">
      <strong>Recommended:</strong> {{hint}}
    </div>
    <div *ngIf="note">
      <br>{{note}}
    </div>
  </div>
  <ng-content></ng-content>
</div>
