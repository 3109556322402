
import {Component, EventEmitter, Inject, forwardRef, Output, Input} from '@angular/core';
import { PopupService } from '../../../popup/services/popup.service';
import {BrandService} from '../../../core';


/**
 * Terms of Use component.
 */
@Component({
  selector: 'pb-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent {
  /**
   * State of the agreement.
   */
  public isAgree: boolean;

  @Input()
  public brandName = 'GettinLocal';
  /**
   * Agreed changes emitter.
   */
  @Output()
  public isAgreeChange = new EventEmitter<boolean>();

  /**
   * @constructor
   */
  constructor(
    @Inject(forwardRef(() => PopupService)) private popupService: PopupService,
    private brandService: BrandService
  ) { }

  /**
   * Emit a user agreed.
   * @param value Value of chackbox.
   */
  public agreed(value: boolean) {
    this.isAgreeChange.emit(value);
  }

  public get showTitle(): boolean {
    return !this.brandService.IsWhiteLabelMerchant();
  }

  /**
   * Open popup with Terms of Use.
   */
  public openTermsOfUsePopup(): void {
    // tslint:disable-next-line:no-magic-numbers
    this.popupService.iframe(850, 860, '/assets/custom-pages/terms-of-use/terms-of-use.html');
  }
}
