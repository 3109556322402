
/**
 * Information about card.
 */
export class CardInfo {
    public nonce: string;

    public cardholderName: string;

    public billingAddress: {
        streetAddress: string;
        locality: string;
        region: string;
        country_name: string;
    };
}
