import { Injectable } from '@angular/core';

import { Mapper } from '../../abstract/mapper';
import { StateDto } from '../dtos/state.dto';
import { State } from '../../../models/state';

/**
 * State mapper.
 */
@Injectable()
export class StateMapper implements Mapper<StateDto, State> {
  /**
   * @inheritdoc
   * @param {StateDto} dto State DTO.
   */
  public mapToModel(dto: StateDto): State {
    return {
      id: dto.id,
      code: dto.short,
      name: dto.full
    };
  }
}
