import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AppRoutingModule} from '../app-routing.module';
import {StreetNumberPopupComponent} from '../shared';
import {WebAppsPageComponent} from "./webapps-page/webapps-page.component";
import {WebappViewPageComponent} from "./webapp-view-page/webapp-view-page.component";
import {WebAppCouponsPageComponent} from "./coupons-page/coupons-page.component";
import {WebAppPriorityCouponsPageComponent} from "./priority-coupons-page/priority-coupons-page.component";
import {PopupsSettingsPageComponent} from "./popups-settings-page/popups-settings-page.component";
import {AddLoyaltyPopupComponent} from "./add-loyalty-popup/add-loyalty-popup.component";
import {LoyaltyPopupsComponent} from "./loyalty-popups-page/loyalty-popups.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AddDataPopupPageComponent} from "./add-data-popup-page/add-data-popup-page.component";
import {DataPopupsComponent} from "./data-popups-page/data-popups.component";
import {CouponSettingsPageComponent} from "./coupon-settings-page/coupon-settings-page.component";
import {WebAppHideCouponsPageComponent} from "./hide-coupons-page/hide-coupons-page.component";
import {WebAppFeaturedCouponsPageComponent} from "./featured-coupons-page/featured-coupons-page.component";
import {MdAutocompleteModule, MdInputModule} from "@angular/material";

/**
 * Locations module.
 */
@NgModule({
  imports: [SharedModule, AppRoutingModule, ReactiveFormsModule, MdAutocompleteModule, MdInputModule, FormsModule
  ],
  declarations: [
    WebAppsPageComponent,
    WebappViewPageComponent,
    WebAppCouponsPageComponent,
    WebAppHideCouponsPageComponent,
    WebAppPriorityCouponsPageComponent,
    WebAppFeaturedCouponsPageComponent,
    PopupsSettingsPageComponent,
    AddLoyaltyPopupComponent,
    LoyaltyPopupsComponent,
    DataPopupsComponent,
    AddDataPopupPageComponent,
    CouponSettingsPageComponent
  ],
  exports:
    [WebAppsPageComponent, WebappViewPageComponent, WebAppCouponsPageComponent, WebAppHideCouponsPageComponent, WebAppFeaturedCouponsPageComponent, PopupsSettingsPageComponent, LoyaltyPopupsComponent],
  entryComponents:
    [StreetNumberPopupComponent, CouponSettingsPageComponent],
})

export class WebAppsModule {
}
