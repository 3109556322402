/**
 * Categoty domain model.
 */
export class Category {
  /**
   * .ctor
   * @param {any} initObject Initial object of predefined field values.
   */
  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }

  /**
   * Category identifier.
   */
  public id: string;

  /**
   * Category name.
   */
  public name: string;

  /**
   * Plural name.
   */
  public pluralName: string;

  /**
   * Catrgory image.
   */
  public image: string;

  /**
   * If a category is favorite.
   */
  public isFavorite: boolean;

  /**
   * Children categories.
   */
  public children: Category[] = [];

  /**
   * If a category is selected.
   */
  public selected = false;

  public parentId: string;

  public parent: Category = null;
}
