import * as moment from 'moment';

/**
 * View Model for time.
 */
export class TimeVm {
  /**
   * @constructor
   * @param hour Hour value.
   * @param minutes Minutes value.
   * @param meridiem Meridiem valu.
   */
  constructor(hour: number, minutes: string, meridiem: string) {
    this.hour = hour;
    this.minutes = minutes;
    this.meridiem = meridiem;
  }

  /**
   * Hour.
   */
  public hour: number;

  /**
   * Minutes.
   */
  public minutes: string;

  /**
   * Meridiem.
   */
  public meridiem: string;

  /**
   * Get time in 12-hours format.
   */
  public get time12(): string {
    return `${this.hour}:${this.minutes} ${this.meridiem}`;
  }

  /**
   * Get time in 24-hour format.
   */
  public get time24(): string {
    return moment(this.time12, ['h:mm A']).format('HH:mm');
  }
}
