import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .map((event) => {
        if (event instanceof HttpResponse && ~~(event.status / 100) > 3) {
          console.error(event.status, event);
        }
        return event;
      })
      .catch((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 400) {
            console.error(err.status, JSON.stringify(err.error));
          }
          return Observable.throw(err);
        }
      });
  }
}
