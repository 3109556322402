import {Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import {
  AddressComponent,
  AuthService,
  DataTransferService,
  ErrorHandlerService, GooglePlaceProvider,
  Merchant,
  Place, PlaceSearch,
  PlaceService, ProviderMap, ServerError, State,
  UtilsService
} from '../../core';
import {PopupResult, PopupService} from '../../popup';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import { MdDialog } from '@angular/material';
import {Subscription} from 'rxjs/Subscription';
import {AddressSelectizeComponent, StreetNumberPopupComponent} from '../../shared';
import {emailRegex, nonCodedPhoneMask} from '../../../utils';
import {NgForm} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {ViewLocationComponent} from '../../locations/view-location/view-location.component';
import {COUNTRY_LIST} from '../../../utils/countries';
import {getFlagEmoji} from '../../../utils/texts';
import {PlaceDto} from "../../core/services/place/dtos/place.dto";

/**
 * Target popup component.
 */
@Component({
  selector: 'pb-event-location-popup',
  templateUrl: './event-location-popup.component.html',
  styleUrls: ['./event-location-popup.component.scss'],
})
export class EventLocationPopupComponent implements OnInit {
  public onSuccess: any = null;

  public countryList = COUNTRY_LIST;

  public placeCountries = [
    { value: 'US', text: 'United States', checked: true, class: 'black', font: 12, dial_code: '+1' },
    { value: 'CA', text: 'Canada', checked: false, class: 'black', font: 12, dial_code: '+1' },
    { value: 'UK', text: 'United Kingdom', checked: false, class: 'black', font: 12, dial_code: '+44' },
    { value: 'NL', text: 'Netherlands', checked: false, class: 'black', font: 12, dial_code: '+31' },
    { value: 'DE', text: 'Germany', checked: false, class: 'black', font: 12, dial_code: '+49' },
    { value: 'AT', text: 'Austria', checked: false, class: 'black', font: 12, dial_code: '+43' },
  ];

  public callCountry = 'US';

  public selectedCountryCode = 'us';
  public countryCodes = ['us', 'lu', 'de', 'bs', 'br', 'pt'];
  public placeCountry = ['US'];

  /**
   * Result from popup.
   */
  public popupResult = PopupResult;

  public campaignUrl = `${environment.webAppUrl}`;

  public reference: string = undefined;
  /**
   * Route subscriber.
   */
  private routeSub: Subscription;

  public addressItems: { id: string, text: string }[] = [];

  /**
   * Used for search id by name.
   */
  public addressItemsSnapshot: { id: string, text: string }[] = [];

  /**
   * Provider type which used for last search.
   */
  private provider: string;

  /**
   * A merchant id.
   */
  private merchantId: number;

  @ViewChild('addressSelectizeComponent')
  public addressSelectizeComponent: AddressSelectizeComponent;

  public zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

  public phoneRegex = /\+\([0-9]{3}\)\ ?[0-9]{3}\-[0-9]{4}/g;

  public get emailMask() {
    return emailRegex();
  }

  // @ts-ignore
  /**
   * A place object with information.
   */
  public place: Place = new Place({country_calling_code: '+1'} as Place);


  public states: State[] = [];

  /**
   * Formatted address, which displayed after creation of location.
   */
  public formattedAddress: string;

  /**
   * A forsquare id of place.
   */
  public foursquare: string;

  /**
   * To trigger spinner if data is loading.
   */
  public isLoading = true;

  /**
   * Is store location checkbox.
   */
  public isDisplayed = true;

  /**
   * Error emitter.
   */
  public errorEmitter = new EventEmitter<any>();

  @ViewChild(NgForm)
  public form: NgForm;

  public isInProcessOfSaveAndFinish = false;

  /**
   * String in input in address selectize.
   */
  public currentAddressStreet: string;
  public currentAddressBusiness: string;

  public async saveAndFinish(event) {
    this.isInProcessOfSaveAndFinish = true;
    this.form.onSubmit(event);
  }

  public phoneMask(rawInput: string) {
    return nonCodedPhoneMask();
  }



  constructor(
    private mdDialogRef: MdDialogRef<EventLocationPopupComponent>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dataTransferService: DataTransferService,
    public placeService: PlaceService,
    public popupService: PopupService,
    private utilService: UtilsService,
    private errorHandlerService: ErrorHandlerService,
    private dialog: MdDialog,
    private googlePlaceProvider: GooglePlaceProvider,
  @Inject(MD_DIALOG_DATA) public data: any
  ) {
    this.merchantId = data.merchantId;
    this.onSuccess = data.onSuccess;

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.r) {
        this.reference = params.r; // --> Name must match wanted parameter
      }
    });

    this.routeSub = Observable.combineLatest(this.activatedRoute.paramMap, this.authService.onUserChanged).subscribe(async tuple => {
      const [params, user] = tuple;
      if (user) {
        this.merchantId = user.merchantId;

        this.states = await this.utilService.getStates();

        const foursquareId = params.get('forsquareID');
        if (foursquareId) {
          this.place = await this.placeService.getPlaceInfo(this.merchantId, foursquareId);
          if (this.place) {
            this.foursquare = this.place.forsquareId;
            const { streetNumber, street } = this.place;
            this.currentAddressStreet = `${streetNumber} ${street}`;
            this.currentAddressBusiness = `${this.place.name}`;
            this.addressSelectizeComponent.addressDropdown.setDefaultValue(this.place.address, this.currentAddressStreet);
            this.formattedAddress = this.place.address;

            this.callCountry = this.getCountryForCallingCode(this.place.country_calling_code);
          }
        }

        if (this.place.isDisplayed) {
          this.isDisplayed = this.place.isDisplayed;
        }

        this.isLoading = false;

        if (this.routeSub) {
          this.routeSub.unsubscribe();
        }
      }
    });
  }

  public onSave(id) {
    this.onSuccess(id);
    this.mdDialogRef.close();
  }

  public async ngOnInit(): Promise<void> {
  }

  public close() {
    this.mdDialogRef.close();
  }

  /**
   * Triggers on submit form.
   * @param {NgForm} form
   */
  public async onSubmit(form: NgForm): Promise<void> {
    if (form.valid) {
      try {

        let notificationText = 'Place information was successfully updated';
        let editMode = false;
        let success = true;
        const displayAddress = this.addressSelectizeComponent.addressDropdown.getInputDisplayText();

        this.place.isDisplayed = this.isDisplayed;

        if (displayAddress) {
          this.place.address = displayAddress;
        }

        if (!this.place.id) {
          this.place.provider = this.provider;
          try {
            const response = await this.placeService.addPlace(this.merchantId, this.foursquare, this.place);
            if (response instanceof Place) {
              this.formattedAddress = this.place.address;
              this.place.id = response.id;
              notificationText = 'Place information was successfully added';
            } else if (response instanceof ServerError) {
              await this.handleServerError(response);
              success = false;
            }
          } catch (err) {
            notificationText = 'Error adding place!';
            success = false;
            await this.popupService.info(notificationText);
          }
        } else {
          const response = await this.placeService.updatePlace(this.merchantId, this.place);
          if (response instanceof Place) {
            this.place.id = response.id;
            editMode = true;
          } else if (response instanceof ServerError) {
            await this.handleServerError(response);
            success = false;
          }
        }

        if (form.valid && success) {
          await this.popupService.info(notificationText);
          this.onSave(this.place.id);
        }
      } catch (error) {
        console.error(error);
        this.errorEmitter.emit(error);
      }
    } else {
      if (!this.place.state || this.place.state.length === 0
        || !this.place.city || this.place.city.length === 0) {
        const errorMessage = 'Please select correct address';
        this.popupService.info(errorMessage);
      }
    }

    this.isInProcessOfSaveAndFinish = false;
  }

  private async handleServerError(serverError: ServerError): Promise<void> {
    const map = {
      'name': 'location',
      'contact_name': 'contact_name',
    };

    if (!this.errorHandlerService.invalidateForm(this.form, map, serverError)) {
      if (serverError.validationErrors.some(o => o.field === 'place_id')) {
        this.popupService.info('Please select value from address field dropdown.');
      } else if (serverError.code === 'validation_error') {
        this.popupService.info(serverError.detail);
      } else if (serverError.detail) {
        this.popupService.info(serverError.detail);
      }
    }
  }

  /**
   * Triggers on delete button click.
   */
  public async showPopup(event): Promise<void> {
    event.preventDefault();
    const result = await this.popupService.confirm('Are you sure you want to delete this location?', 'Delete Location');
    if (result) {
      this.deletePlace();
    }
  }

  /**
   * Deletes place from merchant after confirmation popup.
   */
  private async deletePlace(): Promise<void> {
    try {
      await this.placeService.deletePlace(this.merchantId, this.place.id);
      await this.popupService.info('Place was deleted');
      let queryParams = null;
      if (this.reference) {
        queryParams = { r: this.reference.trim() };
      }
      this.router.navigate(['/locations'], { queryParams: queryParams });
    } catch (error) {
      this.errorEmitter.emit(error);
    }
  }

  /**
   * Handling selection of location item from Google.
   * @param event Event on selection.
   */
  public async addressChanged(event) {
    if (event) {
      if (event === this.place.address) {
        return; // Skipping if we just clicked on input.
      }

      const spinner = this.popupService.spinner();
      this.foursquare = event;
      const info = await this.googlePlaceProvider.getPlaceDetails(event);
      if (info) {
        info.id = event;
        if (info) {
          this.mapInfo('google', info);
        }
      }

      spinner.close();
    }
  }

  private mapInfo<P extends keyof ProviderMapInfo>(provider: P, item: ProviderMap[P]) {
    if (item) {
      const providerMapper = new ProviderMapInfo();
      this.provider = provider;
      providerMapper[provider](item, this);
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.dataTransferService.clearData();
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  /**
   * Presenting modal window.
   */
  public openManualStreetNumberPopup(): Promise<string> {
    const dialogRef = this.dialog.open(StreetNumberPopupComponent, {
      width: '400px',
    });
    return dialogRef.afterClosed().toPromise();
  }

  public placeCountrySelected(event): void  {

    this.placeCountry = [event.val];
    this.placeCountries = this.placeCountries.map((c: any) => {
      c.checked = c.value === event.val;
      return c;
    });

    this.place = new Place();
    this.currentAddressBusiness = '';
    this.currentAddressStreet = '';

    const selected = this.placeCountries.find(c => c.value === event.val);
    if (selected) {
      this.place.country_calling_code = selected.dial_code;
    }
  }

  public isEdit(): boolean {
    return false;
  }

  public getFlag(iso: any): string {
    return getFlagEmoji(iso);
  }

  public countryCodeChanged(value: string): void {
    this.place.country_calling_code = value;
  }

  private getCountryForCallingCode(country_calling_code: string): string {
    const country = COUNTRY_LIST.find(c => c.dial_code === country_calling_code);
    if (country) {
      return country.code;
    }
    return '';
  }
}

class ProviderMapInfo {
  public 'google' = async (item: PlaceSearch, viewComponent: EventLocationPopupComponent) => {
    // viewComponent.foursquare = item.id;
    viewComponent.place.address = item.address;

    let streetNumber = item.getAddressComponent('street_number');
    let street = item.getAddressComponent('route');
    if (!street) {
      const addressComponent = new AddressComponent();
      addressComponent.longName = item.name;
      addressComponent.shortName = item.name;
      addressComponent.types = ['route'];
      item.addressComponents.push(addressComponent);

      street = addressComponent;
    }
    if (street) {
      /*if (!streetNumber) {
        const manualStreetNumber = await viewComponent.openManualStreetNumberPopup();

        if (manualStreetNumber) {
          const addressComponent = new AddressComponent();
          addressComponent.longName = manualStreetNumber;
          addressComponent.shortName = manualStreetNumber;
          addressComponent.types = ['street_number'];
          item.addressComponents.push(addressComponent);
        }

        streetNumber = item.getAddressComponent('street_number');
      }*/

      const formattedAddress = streetNumber ?
        `${streetNumber.longName}, ${street.longName}` : street.longName;

      viewComponent.currentAddressStreet = formattedAddress;
      viewComponent.currentAddressBusiness = `${item.name}`;
      // viewComponent.addressSelectizeComponent.addressDropdown.setDefaultValue(item.id, formattedAddress, true);
    } else {
      viewComponent.addressSelectizeComponent.addressDropdown.refreshState();
      viewComponent.popupService.info('Selected result have not street and/or street number.');
    }
    viewComponent.placeService.updatePlaceObject(item, viewComponent.place, viewComponent.states);
  }
}
