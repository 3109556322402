import { Router } from '@angular/router';

import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Rx';
import { AuthService, UtilsService, PaymentService, CustomerInfo, CardInfo } from '../core';
import { BillingInformationComponent } from '../shared';
import { PopupService } from '../popup';

@Component({
  selector: 'pb-billing-information-page',
  templateUrl: './billing-information-page.component.html',
  styleUrls: ['./billing-information-page.component.scss'],
})
export class BillingInformationPageComponent implements OnInit {

  private userSubscription: Subscription;

  private customerInfo: CustomerInfo;

  public editMode = true;

  public merchantId: number;

  public card: any = {
    name: null,
    number: null,
    cvc: null,
    expirationDate: null,
  };

  public address: any = {
    billingAddress: null,
    city: null,
    state: null,
    zip: null,
  };

  public states: string[] = [];
  public usStates: string[] = [];
  public canadaStates: string[] = [];
  public defaultUserCountry: string = "United States of America";
  public userCountry: string = this.defaultUserCountry;

  constructor(
    private authService: AuthService,
    private popupService: PopupService,
    private paymentService: PaymentService,
    private utilsService: UtilsService,
    private router: Router,
  ) { }

  @ViewChild(BillingInformationComponent)
  public billingInformationForm: BillingInformationComponent;

  public ngOnInit() {
    this.seedData();
  }

  private seedData() {
    this.userSubscription = this.authService.onUserChanged.skipWhile(user => !user)
      .subscribe(async (user): Promise<void> => {
        this.merchantId = user.merchantId;
        this.userCountry = user.merchant.address.country || this.defaultUserCountry;

        this.usStates = (await this.utilsService.getUSStates())
        .filter(state => state.code)
        .map(state => state.code);

        this.canadaStates = (await this.utilsService.getCanadaStates())
        .filter(state => state.code)
        .map(state => state.code);

        if(this.userCountry === 'Canada') {
          this.states = this.canadaStates;
        } else {
          this.states = this.usStates;
        }

        this.states.push('Other');

        const customerInfos = await this.paymentService.getCustomerInfo(this.merchantId);
        if (customerInfos && customerInfos.length > 0) {
          this.customerInfo = customerInfos[0];
          if (this.customerInfo && this.customerInfo.creditCards && this.customerInfo.creditCards.length > 0) {
            const creditCard = this.customerInfo.creditCards[0];
            this.card.name = creditCard.cardholderName;

            const regex = /(\d{2})\/(\d{4})/g;
            const matches = regex.exec(creditCard.expirationDate);
            const numberOfMatchesToDate = 3;
            if (matches && matches.length > 0 && matches.length === numberOfMatchesToDate) {
              const [, month, year] = matches;
              const numberOfDigitsInYear = 2;
              this.card.expirationDate = `${month}/${year.substr(numberOfDigitsInYear, numberOfDigitsInYear)}`;
            } else {
              this.card.expirationDate = creditCard.expirationDate;
            }
            this.card.number = `****-****-****-${creditCard.last4}`;
            if (creditCard.expirationDate) {
              const date = moment(creditCard.expirationDate);
              this.card.month = date.month().toString();
              this.card.year = date.year().toString();
            }
            if (creditCard.billingAddress) {
              this.address.billingAddress = creditCard.billingAddress.streetAddress;
              this.address.city = creditCard.billingAddress.locality;
              this.address.country = creditCard.billingAddress.countryName;

              if(this.address.country === 'Canada') {
                this.states = this.canadaStates;
              } else {
                this.states = this.usStates;
              }

              if (!this.states.some(o => o === creditCard.billingAddress.region)) {
                this.address.customState = creditCard.billingAddress.region;
                this.address.state = 'Other';
              } else {
                this.address.state = creditCard.billingAddress.region;
              }

              this.address.zip = creditCard.billingAddress.postalCode;
            }

            this.changeMode(false);
          }
        }

        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }
      });
  }

  public async handleSubmit(event: any): Promise<void> {
  const model = new CardInfo();
    model.nonce = event.nonce;
    model.cardholderName = event.cardholderName;
    model.billingAddress = {
      streetAddress: event.address.billingAddress,
      locality: event.address.city,
      region: event.address.state,
      country_name: event.address.country
    };

    if (event.address.state === 'Other') {
      model.billingAddress.region = event.address.customState;
    }

    const spinner = this.popupService.spinner();
    let paymentAddHandled = false;

    try {
      const result = await this.paymentService.addPayment(this.merchantId, model);
      if (result && !result.ok) {
        const body = result.json();

        spinner.close();
        this.popupService.info(body.error, 'Error');
      } else {
        this.seedData();
        paymentAddHandled = true;
      }
    }
    finally {
      spinner.close();
    }

    if (paymentAddHandled) {
      this.popupService.info('Your card information submitted', 'Success');
      setTimeout(() => {
        this.router.navigateByUrl('/billing');
      // tslint:disable-next-line:no-magic-numbers
      }, 5000);
    }
  }

  public submitForm(event) {
    this.billingInformationForm.submitForm(event);
  }

  public changeMode(editMode: boolean) {
    this.editMode = editMode;
    if (this.customerInfo.creditCards && this.customerInfo.creditCards.length > 0 && !editMode) {
      const creditCard = this.customerInfo.creditCards[0];
      if (this.states.some(s => s === creditCard.billingAddress.region)) {
        this.address.state = creditCard.billingAddress.region;
        this.address.customState = null;
      } else {
        this.address.state = 'Other';
        this.address.customState = creditCard.billingAddress.region;
      }

      this.address.billingAddress = creditCard.billingAddress.streetAddress;
      this.address.city = creditCard.billingAddress.locality;

      this.card.name = creditCard.cardholderName;
    }
  }
  public testPayment(event) {
    event.preventDefault();
    
    this.paymentService.makeTestPayment(this.merchantId).then(res=> alert('success'))
    .catch(err => alert('error'));
  }
}
