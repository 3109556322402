<header pb-page-header>
  <div left>Activities</div>
  <div right>
    <div class="container">
      <div class="sort-wrap">
        <span class="sort-text">
          Location:
        </span>
        <pb-selectize-dropdown class="sort" name="location" (ngModelChange)="locationChanged($event)" [(ngModel)]="selectedLocations" [items]="locations" placeholder="Choose location" [maxItems]="null"
        required dropdownDirection="down"
        [openOnFocus]="true"></pb-selectize-dropdown>
      </div>
      <div class="sort-wrap">
        <span class="sort-text">
          Filter by:
        </span>
        <pb-selectize-dropdown class="sort" name="sortby" (ngModelChange)="sortByChanged($event)" [(ngModel)]="selectedSorting" [items]="sortings" placeholder="Choose filtering"
        [maxItems]="1" required dropdownDirection="down" [openOnFocus]="true"></pb-selectize-dropdown>
      </div>
      <button pb-button white small class="btn" (click)="openPopup($event)" [disabled]="!locations || locations.length === 0">+ New Activity</button>
    </div>
  </div>
</header>

<div class="row">
  <pb-activity [activities]="campaigns" type="Coupons" big></pb-activity>
  <pb-activity [activities]="promotions" type="Promotions" big></pb-activity>
  <pb-activity [activities]="messages" type="Messages" big></pb-activity>
</div>
