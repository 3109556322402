import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LocationsPageComponent } from './locations-page/locations-page.component';
import { AddLocationComponent } from './add-location/add-location.component';
import { AppRoutingModule } from '../app-routing.module';
import { ViewLocationComponent } from './view-location/view-location.component';
import { StreetNumberPopupComponent } from '../shared';

/**
 * Locations module.
 */
@NgModule({
  imports: [SharedModule, AppRoutingModule],
  declarations: [
    LocationsPageComponent,
    AddLocationComponent,
    ViewLocationComponent,
  ],
  exports: [LocationsPageComponent],
  entryComponents: [StreetNumberPopupComponent],
})
export class LocationsModule {}
