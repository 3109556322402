<form #form="ngForm" (ngSubmit)="onSubmit(form)" (keydown.enter)="$event.preventDefault()"
  [class.form-submitted]="form.submitted">
  <section pb-section caption="COVID-19 Safety & Cleaning Information">
    <div body>
      <div class="col">
        <label style="font-weight: bold; margin-bottom: 10px;">Add or Edit information:</label>
        <div #infoContainer style="max-height: 400px; overflow-y: auto; background: white;">
          <div class="category-row" *ngFor="let covidOption of covidOptions ; let i = index; trackBy:trackByFn">
            <div style="display: flex;
          flex-direction: row;
          align-items: center;">
              <b style="width: 30px;">{{(i+1) + ".&nbsp;"}}</b>
              <div *ngIf="!enabledOptions[i]" class="text">{{covidOptions[i]}}</div>
              <input *ngIf="enabledOptions[i]" type="text" class="input" name="covid-{{covidOption}}"
                [value]="covidOptions[i]" [(ngModel)]="covidOptions[i]" (keydown.enter)="saveEdit($event, i)" required>
              <button [disabled]="editMode" *ngIf="!enabledOptions[i]" type="button" class="edit-button"
                (click)="enableEdit($event, i)">
                edit
              </button>
              <button *ngIf="enabledOptions[i]" [disabled]="editMode && covidOptions[i].length === 0" type="button"
                class="remove-button" (click)="saveEdit($event, i)">
                <pb-icon name="#iconSave" class="icon-edit"></pb-icon>
              </button>
              <button *ngIf="enabledOptions[i]" type="button" class="remove-button" (click)="cancelEdit($event, i)">
                <pb-icon name="#iconCancel" class="icon-edit"></pb-icon>
              </button>
              <button [disabled]="editMode" *ngIf="!enabledOptions[i]" type="button" class="remove-button"
                (click)="removeOption($event, i)">
                delete
              </button>
            </div>
          </div>
        </div>
        <div class="form-error">{{formError}}</div>
        <div style="display: flex; margin-top: 15px; justify-content: flex-end;">
          <button [disabled]="editMode" pb-button green type="button" style="width: 100px; height: 30px;"
            (click)="addOption($event)">
            <pb-icon name="#iconAddWhite" class="icon"></pb-icon> Add New
          </button>
        </div>
        <br>
        <pb-textarea name="extraCovidOptions" [(ngModel)]="extraCovidOptions" label="Additional information:"
          placeholder="" note="" maxlength="800" class="full-width" rows="5" pbTooltip customElementQuery=".body">
        </pb-textarea>

      </div>
      <br />
      <div class="row add-row">
        <button [disabled]="editMode" pb-button green type="submit" class="button">Save</button>
        <button pb-button white type="button" class="button" (click)="resetOptions()">Reset to Defaults</button>
        <button pb-button white type="button" class="button" (click)="close()">Cancel</button>
      </div>
    </div>
  </section>
</form>