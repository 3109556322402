import {Component, Inject, OnInit} from '@angular/core';

import {AuthService, BrandMapper, BrandService, MerchantService, WebappService} from './core';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

/**
 * App root component.
 */
@Component({
  selector: 'pb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /**
   * .ctor
   * @param _document
   * @param {AuthService} authService Authentication service.
   * @param webappService
   * @param brandMapper
   * @param merchantService
   * @param brandService
   * @param titleService
   */
  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private authService: AuthService,
    private webappService: WebappService,
    private brandMapper: BrandMapper,
    private merchantService: MerchantService,
    private brandService: BrandService,
    private titleService: Title,
  ) {
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    try {
      this.authService.me().then(u => {
        this.merchantService.getMerchant(u.merchant.id).then(m => {
          this.webappService.getWebApps()
          // this.webappService.getCoupons(m.app.name);
          this.brandService.brand.next(this.brandMapper.mapToModel(m.app));
          this.titleService.setTitle(m.app.title);
          this._document.getElementById('appFavicon').setAttribute('href', m.app.favicon);
        });
      });
    } catch (e) {
    }
  }
}
