/**
 * Paged dto for invoice.
 */
import { PagedDto } from '../../pagination/dtos/paged.dto';

export class InvoicesDto extends PagedDto<InvoiceDto> {

}

/**
 * Dto for invoice.
 */
export interface InvoiceDto {
    campaign: {id: number, name: string, name_dba: string, end: string};
    id: number;

    status: string;

    start: string;

    end: string;

    items: {
        type: string,
        count: number,
        rate: string,
        fees: string
    }[];

    total: number;

    subtotal: number;

    discount: number;

    transaction: {
        status: string,
        reject_reason: string,
        gateway_rejection_reason: string,
        credit_card: string
    };

    merchant: {
        id: number,
        name: string,
        name_dba: string
    };

    tax: number;

    charge_date: string;
}
