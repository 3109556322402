import { Component, EventEmitter, Output } from '@angular/core';
import { Lightbox } from 'angular2-lightbox';

/**
 * Loaded image preview component with ability to delete.
 */
@Component({
  selector: 'pb-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {
  /**
   * @constructor
   */
  constructor(private lightbox: Lightbox) { }

  /**
   * Collection of presented images.
   */
  public images: { url: string, id: number, index: number, name: string }[] = [];

  /**
   * Emitter for deleting image event.
   */
  @Output()
  public deleteImageEmitter = new EventEmitter<number>();

  /**
   * Removing image from presentation.
   * @param index Index of image to remove.
   */
  public deleteImage(index: number): void {
    const image = this.images.find(img => img.index === index);

    this.images = this.images.filter(img => img.index !== index);
    this.deleteImageEmitter.emit(image.id);
  }


  /**
   * Adding image to presentation.
   * @param url Image url.
   * @param id id of uploaded image.
   */
  public addImageAsUrl(url: string, id: number): void {
    this.images.push({
      url: url,
      name: '',
      id: id,
      index: this.images.length + 1
    });
  }

  public clearImages() {
    this.images = [];
  }

  public openLightBox(index: number) {
    this.lightbox.open(
      this.images.map(({ url }) => ({ src: url, thumb: url })),
      index - 1
    );
  }
}
