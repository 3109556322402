import { Component, Inject, OnInit } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import {Merchant, Place, PlaceService} from '../../core';
import { PopupResult } from '../../popup';
import {environment} from '../../../environments/environment';
/**
 * Target popup component.
 */
@Component({
  selector: 'pb-event-publish-popup',
  templateUrl: './campaign-publish-popup.component.html',
  styleUrls: ['./campaign-publish-popup.component.scss'],
})
export class CampaignPublishSuccessPopupComponent implements OnInit {
  public title: string;
  public message: string;
  public subtitle: string;
  public buttonTitle: string;
  public secondaryButtonTitle: string;
  public merchant: Merchant;
  public incompleteProfileItems: any[] = [];
  public onItemClick: any = null;
  public incompleteItemsTitle: string;
  public firstLocation: string;
  public merchantId: number;

  /**
   * Result from popup.
   */
  public popupResult = PopupResult;

  public campaignUrl = `${environment.webAppUrl}`;

  constructor(
    private mdDialogRef: MdDialogRef<CampaignPublishSuccessPopupComponent>,
    private placeService: PlaceService,
    @Inject(MD_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.message = data.message;
    this.subtitle = data.subtitle;
    this.buttonTitle = data.buttonTitle;
    this.secondaryButtonTitle = data.secondaryButtonTitle;
    this.incompleteProfileItems = data.incompleteList;
    this.incompleteItemsTitle = data.incompleteItemsTitle;
    this.onItemClick = data.onItemClick;
    this.firstLocation = data.firstLocation;
    this.merchantId = data.merchantId;
  }

  public incompleItemClicked(item: string) {
    this.mdDialogRef.close();
    this.onItemClick(item);
  }

  public async ngOnInit(): Promise<void> {
    this.loadUrl();
  }

  public close() {
    this.mdDialogRef.close();
  }

  public loadUrl(): void {
    if(!this.firstLocation || !this.merchantId) {
      return;
    }
    this.placeService.getPlaceInfo(this.merchantId, this.firstLocation).then(res => {
      const p = new Place(res);
      this.campaignUrl += `?lat=${p.location.latitude}&lng=${p.location.longitude}`;
    });
  }
}
