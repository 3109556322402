<ng-template [ngIf]="!!currentAddressStreet" [ngIfElse]="addMode">
  <pb-input name="street" (onFocus)="focus(addressDropdown)" [ngModel]="currentAddressStreet" placeholder="Search for Address"
    [required]="required"></pb-input>
  <pb-selectize-dropdown [style.display]="displayAddressDropdown" dropdownDirection="down" #addressDropdown name="selectedAddressName"
    label="Address" [ngModel]="selectedAddressName" [items]="places" [maxItems]="1" [placeholder]="placeholder"
    (ngModelChange)="handleAddressInputChange($event)" (queryHandler)="handleAddressQuery($event);" class="element address-dropdown"
    [create]="false" [persist]="true" [preserveOnBlur]="true" [openOnFocus]="true" [forceDisplayResults]="true"
    (focusout)="hideAddress($event)" id="displayAddressDropdown">
  </pb-selectize-dropdown>
</ng-template>

<ng-template #addMode>
  <pb-selectize-dropdown dropdownDirection="down" #addressDropdown name="address" label="Address" [ngModel]="selectedAddressName"
    [items]="places" [placeholder]="helpText" [maxItems]="1" (ngModelChange)="handleAddressInputChange($event)"
    (queryHandler)="handleAddressQuery($event);" class="element address-dropdown" [create]="false" [persist]="true"
    [preserveOnBlur]="true" [openOnFocus]="true" [forceDisplayResults]="true" [required]="required"></pb-selectize-dropdown>
</ng-template>
