import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {environment} from '../../../../environments/environment';
import {TokenStorageService} from '../token-storage.service';
import {WebappMapper} from './mappers/webapp.mapper';
import {MerchantApp} from '../../models/merchant-app';
import {BehaviorSubject} from 'rxjs';
import {WebApp} from "../../models/web-app";
import {WebappDto} from "./dtos/webapp.dto";
import {CouponMapper} from "../coupons/mappers/coupon.mapper";
import {MerchantsCoupon, PaginatedMerchantsCoupon} from "../coupons/models/merchants-coupon";
import {FilterByVisibility} from "../filterby.enum";
import {WebAppPopup} from "../../models/webapp-popips";
import {WebappPopupDto} from "./dtos/popups.dto";
import {LoyaltyPopupsDto} from "./dtos/loyalty-popups.dto";
import {LoyaltyPopup} from "../../models/loyalty-popup";
import {LoyaltyPopupConfig} from "../../models/loyalty-popup-config";
import {LoyaltyPopupsConfigDto} from "./dtos/loyalty-popups-config.dto";
import {DataPopup} from "../../models/data-popup";
import {DataPopupsDto} from "./dtos/data-popups.dto";
import {Category} from "../../models/category";
import {CategoryDto} from "../categories/dtos/category.dto";
import {CategoryMapper} from "../categories/mappers/category.mapper";
import {WebAppFeaturedCoupon} from "./models/webapp-featured-coupon";
import {WebappMerchantCoupon} from "../../models/webapp-merchant-coupon";
import {WebAppMerchant} from "../../models/webapp-merchant";
import {CouponOverrideSettings} from "../../models/coupon-override-settings";

const API_ENDPOINT = 'webapps';

/**
 * Core http service provides infrastructural methods.
 */
@Injectable()
export class WebappService {

  public brand: BehaviorSubject<MerchantApp>;

  /**
   * .ctor
   * @param http Http
   */
  constructor(
    private http: Http,
    private brandMapper: WebappMapper,
    private couponMapper: CouponMapper,
    private webappMapper: WebappMapper,
    private categoryMapper: CategoryMapper,
    private tokenService: TokenStorageService
  ) {
    this.brand = new BehaviorSubject<MerchantApp>(new MerchantApp({
      id: 'gettinlocal',
      name: 'GettinLocal'
    }));
  }

  public IsWhiteLabelMerchant(): boolean {
    return this.brand.getValue().isWhiteLabel();
  }

  public async getWebApps(): Promise<WebApp[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as WebappDto[])
      .map(v => v.map(a => this.brandMapper.mapToModel(a)))
      .map(v => {
        // this.brand.next(v[0]);
        return v;
      })
      .toPromise();
  }

  public async getMerchants(app: string, search: string, filter: FilterByVisibility, page: number = 1): Promise<WebAppMerchant[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${app}/merchants/?page=${page}&filter=${filter}&search=${search}`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as WebAppMerchant[])
      .map(r => {
        return r;
      })
      .toPromise();
  }

  public async getCoupons(app: string, search: string, filter: FilterByVisibility, page: number = 1): Promise<PaginatedMerchantsCoupon> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${app}/coupons/?page=${page}&filter=${filter}&search=${search}`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as PaginatedMerchantsCoupon)
      .map(r => {
        r.results.map(i => i);
        return r;
      })
      .toPromise();
  }

  public async getFeaturedCoupons(app: string): Promise<CouponOverrideSettings[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${app}/featured/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as CouponOverrideSettings[])
      .toPromise();
  }

  public async getPopupSettings(app: string): Promise<WebAppPopup> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${app}/popups/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as WebappPopupDto)
      .map(v => this.brandMapper.mapToPopupsModel(v))
      .toPromise();
  }

  public async getPopupSetting(app: string, id: string): Promise<WebAppPopup> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${app}/popups/${id}`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as WebappPopupDto)
      .map(v => this.brandMapper.mapToPopupsModel(v))
      .toPromise();
  }

  public async getLoyaltyPopupSettings(webAppId: string, popupSettingId: string): Promise<LoyaltyPopupConfig[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/popups/${popupSettingId}/loyalty_popup_settings/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as LoyaltyPopupsConfigDto[])
      .map(v => v.map(a => this.brandMapper.mapToLoyaltyPopupsConfigModel(a)))
      .toPromise();
  }

  public async getLoyaltyPopupSetting(webAppId: string, popupSettingId: string, loyaltyPopupId: string): Promise<LoyaltyPopup> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/popups/${popupSettingId}/loyalty_popup_settings/${loyaltyPopupId}/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as LoyaltyPopupsDto)
      .map(v => this.brandMapper.mapToLoyaltyPopupsModel(v))
      .toPromise();
  }

  public async showHideCoupon(coupon: MerchantsCoupon, app: string, status: boolean): Promise<void> {
    await this.http
      .post(`${environment.apiEndpoint}/${API_ENDPOINT}/${app}/coupons/${coupon.id}/toggle/`, {status: status}, {
        headers: this.tokenService.baseHeaders
      })
      .toPromise();
  }

  public async toggleFeatured(id: number, coupon_id: string, app: string): Promise<void> {
    await this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${app}/featured/${coupon_id}/remove`, {
        headers: this.tokenService.baseHeaders
      })
      .toPromise();
  }

  public async getPopupPreviewHTML(webAppId: any, id): Promise<string> {
    return await this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/loyalty-popups/${id}/preview/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.text())
      .toPromise();
  }

  public async getDataPopupPreviewHTML(webAppId: any, id): Promise<string> {
    return await this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/data-popups/${id}/preview/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.text())
      .toPromise();
  }

  public async getLoyaltyPopups(webAppId: string): Promise<LoyaltyPopup[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/loyalty-popups/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as LoyaltyPopupsDto[])
      .map(v => v.map(a => this.brandMapper.mapToLoyaltyPopupsModel(a)))
      .toPromise();
  }

  async getLoyaltyPopup(webAppId: string, popupId: any): Promise<LoyaltyPopup> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/loyalty-popups/${popupId}/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as LoyaltyPopupsDto)
      .map(v => this.brandMapper.mapToLoyaltyPopupsModel(v))
      .toPromise();
  }

  public async updateLoyaltyPopup(webAppId: string, popupId: any, values: any, logoFile: File, popupImage: File): Promise<void> {

    const data = this.webappMapper.mapModelToLoyaltyPopupsDto(values);

    const formData = new FormData();

    formData.append('app', webAppId);
    formData.append('name', data.name);
    formData.append('background_color', data.background_color);
    formData.append('content', data.content);
    formData.append('primary_button_text', data.primary_button_text);
    formData.append('primary_button_url', data.primary_button_url);
    formData.append('primary_button_color', data.primary_button_color);
    formData.append('primary_button_text_color', data.primary_button_text_color);
    formData.append('primary_button_border_color', data.primary_button_border_color);
    formData.append('secondary_button_text', data.secondary_button_text);
    formData.append('secondary_button_color', data.secondary_button_color);
    formData.append('secondary_button_text_color', data.secondary_button_text_color);
    formData.append('secondary_button_border_color', data.secondary_button_border_color);
    formData.append('popup_action_url', data.popup_action_url);
    formData.append('popup_type', data.popup_type);

    if (logoFile) {
      formData.append('logo', logoFile);
    } else if (popupImage) {
      formData.append('popup_image', popupImage);
    }

    if (popupId === 'new') {
      return this.http
        .post(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/loyalty-popups/`, formData, {
          headers: this.tokenService.baseHeaders
        })
        .map(r => r.json())
        .toPromise();
    }

    return this.http
      .put(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/loyalty-popups/${popupId}/`, formData, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json())
      .toPromise();
  }

  public async deleteLoyaltyPopup(webAppId: string, popupId: any): Promise<any> {
    return this.http
      .delete(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/loyalty-popups/${popupId}/`, {
        headers: this.tokenService.baseHeaders
      })
      .toPromise();
  }


  public async getDataPopups(webAppId: string): Promise<DataPopup[]> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/data-popups/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as DataPopupsDto[])
      .map(v => v.map(a => this.brandMapper.mapToDataPopupsModel(a)))
      .toPromise();
  }

  async getDataPopup(webAppId: string, popupId: any): Promise<DataPopup> {
    return this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/data-popups/${popupId}/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json() as DataPopupsDto)
      .map(v => this.brandMapper.mapToDataPopupsModel(v))
      .toPromise();
  }

  public updateDataPopup(webAppId: string, popupId: string, values: any, logoFile: File) {

    const data = this.webappMapper.mapModelToDataPopupsDto(values);

    const formData = new FormData();
    formData.append('app', webAppId);
    formData.append('name', data.name);
    formData.append('title', data.title);
    formData.append('subtitle', data.subtitle);
    formData.append('submit_button_text', data.submit_button_text);
    formData.append('ask_phone', data.ask_phone ? 'true' : 'false');
    formData.append('phone_placeholder', data.phone_placeholder);
    formData.append('ask_zip', data.ask_zip ? 'true' : 'false');
    formData.append('zip_placeholder', data.zip_placeholder);
    formData.append('ask_email', data.ask_email ? 'true' : 'false');
    formData.append('email_placeholder', data.email_placeholder);
    formData.append('ask_type', data.ask_type ? 'true' : 'false');
    formData.append('user_type_question', data.user_type_question);
    formData.append('i_agree', data.i_agree ? 'true' : 'false');
    formData.append('i_agree_text', data.i_agree_text);
    formData.append('receive_newsletter', data.receive_newsletter ? 'true' : 'false');
    formData.append('receive_newsletter_text', data.receive_newsletter_text);
    formData.append('is_enabled', data.is_enabled ? 'true' : 'false');

    if (logoFile) {
      formData.append('logo', logoFile);
    }

    if (popupId === 'new') {
      return this.http
        .post(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/data-popups/`, formData, {
          headers: this.tokenService.baseHeaders
        })
        .map(r => r.json())
        .toPromise();
    }

    return this.http
      .put(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/data-popups/${popupId}/`, formData, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json())
      .toPromise();
  }

  public getWebAppFilters(webAppId: string): Promise<Category[]> {
    return this.http.get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/filters/`, {
      headers: this.tokenService.baseHeaders
    })
      .map(r => r.json() as CategoryDto[])
      .map(v => v.map(a => this.categoryMapper.mapToModel(a)))
      .toPromise();
  }

  public getFeaturedCoupons2(webAppId: string, defaultFilter: string) {
    return this.http.get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/featured/?category=${defaultFilter}`, {
      headers: this.tokenService.baseHeaders
    })
      .map(r => r.json() as WebAppFeaturedCoupon[])
      .toPromise();
  }

  public getCouponSettingsForApp(webAppId: number, couponId: number) {
    return this.http.get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/coupons/${couponId}/configuration/`, {
      headers: this.tokenService.baseHeaders
    })
      .map(r => r.json())
      .toPromise();
  }

  public getWebAppCategories(webAppId: string) {

  }

  public updateCouponSettingsForApp(webappId: number, couponId: number, updatedData: any[]) {
    return this.http.post(`${environment.apiEndpoint}/${API_ENDPOINT}/${webappId}/coupons/${couponId}/update_coupon_configuration/`, updatedData, {
      headers: this.tokenService.baseHeaders
    })
      .toPromise();
  }

  /*public updateFeaturedCouponsOrder(webAppId: string, coupons: WebAppFeaturedCoupon[]) {
    return this.http.post(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/featured/update_order/`, coupons, {
      headers: this.tokenService.baseHeaders
    })
      .toPromise();
  }*/

  public updateFeaturedCouponsOrder(webAppId: string, data: any) {
    const _data = data.map((d, i) => {
      return {
        app: webAppId,
        merchant: d.field1.id,
        coupon: d.field2.id,
        show_in_main: d.field4,
        show_in_business: d.field5,
        order: i + 1
      };
    });
    return this.http.post(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/featured/update_priority_ads/`, _data, {
      headers: this.tokenService.baseHeaders
    })
      .toPromise();
  }

  public savePopupSettings(popupSettingId: string, webAppId: string, values: any) {

    if (values.data_popup == 'null') {
      values.data_popup = '';
    }
    const formData = new FormData();
    formData.append('app', webAppId);
    formData.append('data_popup', values.show_data_popup ? values.data_popup : '');
    formData.append('data_popup_delay', values.show_data_popup ? values.data_popup_delay : 0);
    formData.append('show_install_popup', values.show_install_popup);
    formData.append('install_popup_delay', values.install_popup_delay || 0);

    return this.http
      .put(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/popups/${popupSettingId}/`, formData, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json())
      .toPromise();
  }

  public saveLoyaltyPopupSettings(webAppId: string, popupSettingId: string, value: any) {

    const values = value.filter(v => v.selectedPopup !== null && v.startTime != '' && v.enabled === true && v.endTime != '');
    const settingsData = values.map(v => {
      return {
        popup: v.selectedPopup,
        start: v.startTime,
        end: v.endTime,
        delay: v.delay,
        repeat: v.repeat,
        is_enabled: v.enabled
      };
    });

    return this.http
      .post(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/popups/${popupSettingId}/update_loyalty_popup_settings/`, settingsData, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.json())
      .toPromise();
  }

  public hideAllCategoriesForCoupon(webappId: number, couponId: number) {
    return this.http.get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webappId}/coupons/${couponId}/toggle/`, {
      headers: this.tokenService.baseHeaders
    })
      .toPromise();
  }

  public getMerchantCoupons(webappId: string, merchantId: string, search: string) {
    return this.http.get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webappId}/merchants/${merchantId}/coupons/?search=${search}`, {
      headers: this.tokenService.baseHeaders
    })
      .map(r => r.json() as WebappMerchantCoupon[])
      .toPromise();
  }

  async getPopupDefaultPreviewHTML(webAppId: string) {
    return await this.http
      .get(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/loyalty-popups/default_preview/`, {
        headers: this.tokenService.baseHeaders
      })
      .map(r => r.text())
      .toPromise();
  }

  async deleteDataPopup(webAppId: string, id: string) {
    return await this.http
      .delete(`${environment.apiEndpoint}/${API_ENDPOINT}/${webAppId}/data-popups/${id}/`, {
        headers: this.tokenService.baseHeaders
      })
      .toPromise();
  }
}
