<div *ngIf="isBasicView; else advanced">
  <div class="time-range-container" [class.active]="isActive">
    <div class="start-time">
      <button class="time-range-button" type="button" (click)="basicSchedule.toggleSetTime('start')">
        <svg class="icon">
          <use inlineHref="#iconClock"></use>
        </svg>
        {{basicSchedule.startTime}}
      </button>
      <div *ngIf="basicSchedule.start.showTimePicker" class="time-range-control">
        <input class="time" type="number" [value]="basicSchedule.startHour"
          (change)="basicSchedule.changeHour(null, 'start', $event.target.value)" />
        <button type="button" (click)="basicSchedule.changeHour('up', 'start')">&#9650;</button>
        <button type="button" (click)="basicSchedule.changeHour('down', 'start')">&#9660;</button>
        <span>:</span>
        <input class="time" type="number" [value]="basicSchedule.startMinutes"
          (change)="basicSchedule.changeMinutes(null, 'start', $event.target.value)" />
        <button type="button" (click)="basicSchedule.changeMinutes('up', 'start')">&#9650;</button>
        <button type="button" (click)="basicSchedule.changeMinutes('down', 'start')">&#9660;</button>

        <div class="select-wrapper">
          <select class="select" name="startMeridiem" [ngModel]="basicSchedule.startMeridiem"
            (ngModelChange)="basicSchedule.meridiemChange($event, 'start')">
            <option value="am" default>AM</option>
            <option value="pm">PM</option>
          </select>
        </div>
      </div>
    </div>
    to
    <div class="end-time">
      <button class="time-range-button" type="button" (click)="basicSchedule.toggleSetTime('end')">
        <svg class="icon">
          <use inlineHref="#iconClock"></use>
        </svg>
        {{basicSchedule.endTime}}
      </button>
      <div *ngIf="basicSchedule.end.showTimePicker" class="time-range-control">
        <input class="time" type="number" [value]="basicSchedule.endHour"
          (change)="basicSchedule.changeHour(null, 'end', $event.target.value)" />
        <button type="button" (click)="basicSchedule.changeHour('up', 'end')">&#9650;</button>
        <button type="button" (click)="basicSchedule.changeHour('down', 'end')">&#9660;</button>
        <span>:</span>
        <input class="time" type="number" [value]="basicSchedule.endMinutes"
          (change)="basicSchedule.changeMinutes(null, 'end', $event.target.value)" />
        <button type="button" (click)="basicSchedule.changeMinutes('up', 'end')">&#9650;</button>
        <button type="button" (click)="basicSchedule.changeMinutes('down', 'end')">&#9660;</button>

        <div class="select-wrapper">
          <select class="select" name="endMeridiem" [ngModel]="basicSchedule.endMeridiem"
            (ngModelChange)="basicSchedule.meridiemChange($event, 'end')">
            <option value="am" default>AM</option>
            <option value="pm">PM</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #advanced>
  <button type="button" (click)="selectAllDays()">Select All Days</button>
  <div *ngFor="let day of advancedSchedule" class="advanced-time-wrapper">
    <pb-checkbox style="width: 60px;" [name]="day.name" [label]="day.name" [key]="day.name" [checked]="day.isActive"
      (change)="day.activateDay($event.target.checked)"></pb-checkbox>
    <div class="time-range-container" [class.active]="day.isActive">
      <div class="start-time">
        <button class="time-range-button" type="button" (click)="day.toggleSetTime('start')">
          <svg class="icon">
            <use inlineHref="#iconClock"></use>
          </svg>
          {{day.startTime}}
        </button>
        <div *ngIf="day.start.showTimePicker" class="time-range-control">
          <input class="time" type="number" [value]="day.startHour"
            (change)="day.changeHour(null, 'start', $event.target.value)" />
          <button type="button" (click)="day.changeHour('up', 'start')">&#9650;</button>
          <button type="button" (click)="day.changeHour('down', 'start')">&#9660;</button>
          <span>:</span>
          <input class="time" type="number" [value]="day.startMinutes"
            (change)="day.changeMinutes(null, 'start', $event.target.value)" />
          <button type="button" (click)="day.changeMinutes('up', 'start')">&#9650;</button>
          <button type="button" (click)="day.changeMinutes('down', 'start')">&#9660;</button>

          <div class="select-wrapper">
            <select class="select" name="startMeridiem" [ngModel]="day.startMeridiem"
              (ngModelChange)="day.meridiemChange($event, 'start')">
              <option value="am" default>AM</option>
              <option value="pm">PM</option>
            </select>
          </div>
        </div>
      </div>
      to
      <div class="end-time">
        <button class="time-range-button" type="button" (click)="day.toggleSetTime('end')">
          <svg class="icon">
            <use inlineHref="#iconClock"></use>
          </svg>
          {{day.endTime}}
        </button>
        <div *ngIf="day.end.showTimePicker" class="time-range-control">
          <input class="time" type="number" [value]="day.endHour"
            (change)="day.changeHour(null, 'end', $event.target.value)" />
          <button type="button" (click)="day.changeHour('up', 'end')">&#9650;</button>
          <button type="button" (click)="day.changeHour('down', 'end')">&#9660;</button>
          <span>:</span>
          <input class="time" type="number" [value]="day.endMinutes"
            (change)="day.changeMinutes(null, 'end', $event.target.value)" />
          <button type="button" (click)="day.changeMinutes('up', 'end')">&#9650;</button>
          <button type="button" (click)="day.changeMinutes('down', 'end')">&#9660;</button>

          <div class="select-wrapper">
            <select class="select" name="endMeridiem" [ngModel]="day.endMeridiem"
              (ngModelChange)="day.meridiemChange($event, 'end')">
              <option value="am" default>AM</option>
              <option value="pm">PM</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <pb-checkbox [name]="day.name + x" [key]="day.name + x" [label]="'All Day'" [checked]="day.isAllDay()"
      [isDisabled]="!day.isActive" (change)="day.makeAllDay($event.target.checked)"></pb-checkbox>
  </div>
</ng-template>