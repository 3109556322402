import { NgForm } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../../value-accessor-base';
import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import * as moment from 'moment';
import {HttpEvent} from "@angular/common/http";

@Component({
  selector: 'pb-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => (DateRangeComponent)), multi: true }]
})
export class DateRangeComponent extends ValueAccessorBase<{ start: string, end: string }> {
  constructor() {
    super();
    this.innerValue = { start: '', end: '' };
  }

  /**
   * The captain of the date range block.
   */
  @Input()
  public caption: string;

  /**
   * NgForm.
   */
  @Input()
  public ngForm: NgForm;

  /**
   * Is start date required.
   */
  @Input()
  public startDateRequired: boolean;

  /**
   * Is end date required.
   */
  @Input()
  public endDateRequired: boolean;

  /**
   * Is end date required.
   */
  @Input()
  public noEndDate: boolean = false;

  /**
   * Maximal input for start date.
   */
  @Input()
  public maxInputDate: string;

  /**
   * Minimal input for start date.
   */
  @Input()
  public minInputDate: string;

  /**
   * Coupon view to display.
   */
  @Input()
  public isCouponView = true;

  /**
   * Start date change emitter.
   */
  @Output()
  public startDateChange = new EventEmitter<Date>();

  @Output()
  public noEndDateChange = new EventEmitter<boolean>();

  /**
   * Check the start and end date valid.
   */
  public get isValid(): boolean {
    if (this.innerValue != null && this.innerValue.start && this.innerValue.end) {
      return !moment(this.innerValue.start).isAfter(moment(this.innerValue.end)) ||
      !moment(this.innerValue.end).isBefore(moment(this.innerValue.start));
    }

    return false;
  }

  /**
   * Emits start date changes.
   * @param startDate The start date value after change.
   */
  public startDateEmit(startDate: Date): void {
    this.startDateChange.emit(startDate);
  }

  public noEndDateEmit(event: any) {
    this.noEndDateChange.emit(event.target.checked);
  }
}
