import { Location } from './location';

/**
 * Place domain model.
 */
export class Place {
  country_calling_code: string;
  /**
   * .ctor
   */
  constructor(initObject?: Place) {
    Object.assign(this, initObject);
  }

  // TODO may be we can remove it and leave only forsquareId.
  public id?: string;

  /**
   * Place identifier.
   * TODO: Rename to id, because used with Google and Foursquare.
   */
  public forsquareId: string;

  /**
   * Place name.
   */
  public name: string;

  /**
   * Place address.
   */
  public address: string;

  /**
   * Computed field with full address.
   */
  public formattedAddress: string;

  /**
   * Place street.
   */
  public street?: string;

  /**
   * Number of street
   */
  public streetNumber: string;

  /**
   * Place cross street.
   */
  public crossStreet: string;

  /**
   * Place country.
   */
  public country: string;

  /**
   * Place state.
   */
  public state: string;

  /**
   * Place city.
   */
  public city: string;

  /**
   * Place zip.
   */
  public zip: string;

  /**
   * Place contact phone.
   */
  public contactPhone: string;

  /**
   * Place contact name.
   */
  public contactName: string;

  /**
   * Place contact email.
   */
  public contactEmail: string;

  /**
   * Place location.
   */
  public location: Location;

  /**
   * Place's category ids.
   */
  public categoryIds: string[];

  /**
   * Provider type, see provider-map.
   */
  public provider: string;

  /**
   * Created date.
   */
  public created: string;

  /**
   * Place index.
   */
  public placeIndex: string;

  /**
   * Show location.
   */
  public isDisplayed: boolean;

  /**
   * A flag to verify that the location is selected.
   */
  public selected = false;
}
