<div class="s_wrapper">
  <div class="s_title">
    <h3>Create a new GettinLocal Business Account</h3>
  </div>
  <pb-message caption="Account Registered!" class="s_message"
    text="Your account is registered. Please check your email to verify registration." *ngIf="showMessage"></pb-message>
  <div class="s_content">
    <form #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted"
      [pbServerValidation]="errorEmitter">
      <pb-input name="first_name" [(ngModel)]="first_name" placeholder="First Name*" required
        pbTooltip customElementQuery=".body">
      </pb-input>
      <pb-input name="last_name" [(ngModel)]="last_name" placeholder="Last Name*" required pbTooltip
        customElementQuery=".body">
      </pb-input>
      <pb-input name="email" [(ngModel)]="email" placeholder="Email*" email required pbTooltip
        customElementQuery=".body">
      </pb-input>
      <!-- <pb-input name="password1" #password1Ref="ngModel"
                type="password"
                [(ngModel)]="password1"
                placeholder="Password*"
                required
                pbTooltip
                customElementQuery=".body">
      </pb-input>
      <pb-input name="password2"
                type="password" [(ngModel)]="password2"
                placeholder="Confirm Password*"
                required
                [equal]="password1Ref.value"
                pbTooltip
                customElementQuery=".body">
      </pb-input> -->
      <pb-input name="referal" [(ngModel)]="referral" placeholder="Referral Code" pbTooltip hidden
        customElementQuery=".body" [mask]="{mask: referalMaskPattern, guide: false}">
      </pb-input>
      <button pb-button style="margin-top: 68px;" type="submit" id="signup-button">
        <span class="btn-text">Create Account</span>
      </button>
    </form>
  </div>
</div>