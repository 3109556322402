<div class="content">
  <div style="height: 40px;margin-bottom: 10px;">
      <pb-icon name="#iconColoredCampaign" class="icon"></pb-icon>
  </div>
  <form #form="ngForm" (ngSubmit)="onSubmit(form)" [class.form-submitted]="form.submitted" [pbServerValidation]="errorEmitter">
    <span>
      Enter your account email and we will recover your account.
    </span>
    <pb-input name="email" #passwordRef="ngModel" type="text" [(ngModel)]="email" placeholder="Email" big required pbTooltip customElementQuery=".body">
      <div class="icon-container">
        <pb-icon name="#iconEmail" class="icon-email"></pb-icon>
      </div>
    </pb-input>
    <button pb-button type="submit" class="recover-password-button">
      <span class="btn-text">Recover Account</span>
    </button>
    <a [routerLink]="['/login']" class="login-link">Login</a>
  </form>
</div>
