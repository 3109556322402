import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MdDialog } from '@angular/material';
import { b64toBlob } from '../../../../utils';
import { CropperPopupComponent } from '../cropper-popup/cropper-popup.component';

/**
 * Component for image library.
 */
@Component({
  selector: 'pb-image-library',
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.scss']
})
export class ImageLibraryComponent implements OnInit {

  constructor(
    private dialog: MdDialog,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Format of allowed files.
   */
  private formatRegex = /\.(jpg|png|jpeg)$/gi;

  /**
   * Is active flag.
   */
  @Input()
  public isActive = false;

  /**
   * Width of image for crop.
   */
  @Input()
  public imageWidth: number;

  /**
   * Height of image for crop.
   */
  @Input()
  public imageHeight: number;

  /**
   * Dispatcher for uploaded event(after crop).
   */
  @Output()
  public uploaded = new EventEmitter<{ base64String: string, file: Blob, filename: string }>();

  /**
   * Dispatcher for image choiced.
   */
  @Output()
  public imageChoiced = new EventEmitter<{ url: string, name: string }>();

  /**
   * Last image base64 string.
   */
  private lastImageBase64String: string = null;

  /**
   * Last image file.
   */
  private lastImageFile: File;

  /**
   * Last image blob.
   */
  private lastImageBlob: Blob;
  /**
   * List of images.
   */
  @Input()
  public images: { name: string, url: SafeUrl }[] = [];

  /**
   * Handling upload of image.
   * @param event uploading event
   */
  public handleUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0] as File;

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = async (): Promise<void> => {

      if (file.name.match(this.formatRegex)) {

        const b64Image = await this.presentCropper(reader.result);
        if (b64Image) {
          this.lastImageBase64String = b64Image;
          this.lastImageBlob = b64toBlob(this.lastImageBase64String);

          this.uploaded.emit(
            {
              base64String: this.lastImageBase64String,
              file: this.lastImageBlob,
              filename: file.name
            });
        }
      }
    };
  }
  /**
   * @inheritdoc
   */
  public ngOnInit() {
  }

  /**
   * Adding image to library.
   * @param dataUrl url of base64 data url.
   * @param name Name of image.
   */
  public addImage(dataUrl: string, name: string) {
    // tslint:disable-next-line:no-magic-numbers
    name = name.slice(0, 20);

    this.images.push({ name: name, url: this.sanitizer.bypassSecurityTrustUrl(dataUrl) });
  }

  /**
   * Opening cropper for image.
   * @param base64String Base64 string of image for transform.
   */
  private presentCropper(base64String: string): Promise<any> {
    const dialogRef = this.dialog.open(CropperPopupComponent, {
      data: {
        image: base64String,
        croppedWidth: this.imageWidth,
        croppedHeight: this.imageHeight
      }
    });
    return dialogRef.afterClosed().toPromise();
  }

  /**
   * Firing dispatcher on image selection.
   * @param event Fired event at image selection.
   */
  private imageSelected(event: { url: string, name: string }) {
    // Hack for security
    let url = event.url;
    if ((<any>event.url).changingThisBreaksApplicationSecurity) {
      url = <string>(<any>event.url).changingThisBreaksApplicationSecurity;
    }

    this.imageChoiced.emit({ url: url, name: event.name });
  }
}
