import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../../value-accessor-base';
import { Component, forwardRef, Input } from '@angular/core';

@Component({
  selector: 'pb-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => (DatepickerComponent)), multi: true}]
})
export class DatepickerComponent extends ValueAccessorBase<string> {

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public placeholder = '';

  @Input()
  public maxInputDate = '';

  @Input()
  public minInputDate = '';

  @Input()
  public required = false;
}
