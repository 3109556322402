import { Merchant } from "../core";

export function getIncompleteProfileItemList(merchant: Merchant): any {
  let incompleteList: any[] = [];

  if (merchant.socialUrls) {
    const hasSocialUrl = Object.keys(merchant.socialUrls).some(
      (k) => merchant.socialUrls[k] !== ""
    );
    if (!hasSocialUrl) {
      incompleteList.push({
        icon: "social",
        text: "Social Media Links",
        name: "socialSection",
      });
    }
  } else {
    incompleteList.push({
      icon: "social",
      text: "Social Media Links",
      name: "socialSection",
    });
  }

  if (!merchant.logo) {
    incompleteList.push({
      icon: "photo",
      text: "Logo",
      name: "logoSection",
    });
  }

  if (!merchant.reviews_url) {
    incompleteList.push({
      icon: "google",
      text: "Google Reviews",
      name: "reviewLinksSection",
    });
  }

  if (merchant.images) {
    if (merchant.images.length === 0) {
      incompleteList.push({
        icon: "photo",
        text: "Photos",
        name: "photosSection",
      });
    }
  } else {
    incompleteList.push({
      icon: "photo",
      text: "Photos",
      name: "photosSection",
    });
  }

  if (!merchant.covidOptions.enabled) {
    incompleteList.push({
      icon: "mask",
      text: "COVID-19 Safety Plan",
      name: "covidSection",
    });
  }

  if (!merchant.description) {
    incompleteList.push({
      icon: "about",
      text: "About Us",
      name: "aboutSection",
    });
  }

  if (!merchant.url) {
    incompleteList.push({
      icon: "website",
      text: "Website",
      name: "webSiteSection",
    });
  }

  if (merchant.operationHours) {
    const hasOperationHours = Object.keys(merchant.operationHours).some(
      (k: any) =>
        merchant.operationHours[k].start &&
        merchant.operationHours[k].end
    );
    if (!hasOperationHours) {
      incompleteList.push({
        icon: "clock",
        text: "Business Hours",
        name: "hoursSection",
      });
    }
  } else {
    incompleteList.push({
      icon: "clock",
      text: "Business Hours",
      name: "hoursSection",
    });
  }

  return incompleteList;
}
