import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  AutoCompleteComponent,
  AutocompleteDirective,
} from "./autocomplete.component";

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [AutoCompleteComponent, AutocompleteDirective],
  exports: [AutoCompleteComponent, AutocompleteDirective],
  entryComponents: [AutoCompleteComponent],
})
export class AutocompleteModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AutocompleteModule,
    };
  }
}
