/**
 * Address domain model.
 */
export class CovidOptions {
  public enabled: boolean;
  public extra_instructions: string;
  /**
   * .ctor
   * @param {any} initObject Initial object of predefined field values.
   */
  constructor(initObject: any = {}) {
    Object.assign(this, initObject);
  }

  public type: string;

  public url: string;

  public instructions: any;
}
