<label [for]="name" class="label">{{label}}</label>
<div class="inner" [class.hasBigArrow]="hasBigArrow" [class.inner-icon]="icon" [class.no-arrow]="removeArrow">
  <select [(ngModel)]="value" [required]="true" class="select" [class.placeholder]="!value"
    [name]="name"
    [disabled]="disabled" required="true">
    <option [value]="defaultValue" selected disabled *ngIf="placeholder">{{placeholder}}</option>
    <ng-content></ng-content>
  </select>
  <pb-icon *ngIf="icon" [name]="icon" class="icon"></pb-icon>
</div>
